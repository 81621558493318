/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
//* EXTERNAL LIBS
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

//* EXTERNAL LIBS --> MUI
import { Typography, LinearProgress, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

//* EXTERNAL LIBS --> XPAND-UI
import { PageTitle, LoadingOverlay } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { Numeric, InfoField } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { ICollaborationProposal, IOtherBenefit, IPrizeGoal } from 'typings/store/admin/proposals';

//* PROJECT IMPORTS [LIB / PAGES ]
import Download from 'assets/icons/downloadButton.svg';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { DetailsModalHistoryProps } from '.';
import SummaryTable from 'pages/Admin/_ProposalsFormUtils/components/SummaryTable';

//* COMPONENT INTERFACES
interface IDetailsModalHistory extends DetailsModalHistoryProps {
	proposal: ICollaborationProposal;
	handleClose: Dispatch<SetStateAction<ICollaborationProposal | null>>;
	hash: string;
}

const getGoalTypeId = (erpGoal: string): number | null => {
	switch (erpGoal) {
		case 'xphrBuGoal':
			return 2;
		case 'xphrCompanyGoal':
			return 1;
		case 'xphrIndGoal':
			return 4;
		case 'xphrProjectGoal':
			return 3;
		default:
			return null;
	}
};

//* COMPONENT
const DetailsModalHistory: FC<IDetailsModalHistory> = ({
	proposals,
	proposal,
	employeeSalary,
	getExistingProposalSummary,
	clearSummary,
	handleClose,
	hash,
	getCscHistory,
	clearCsCHistory,
	getCscHistoryExportExcel
}) => {
	const classes = useStyles();
	const { loading } = employeeSalary;
	const proposalSummary = employeeSalary.proposalSummary || proposals.proposalSummary;

	useEffect(() => {
		if (proposalSummary === null) {
			if (proposal.token) {
				getExistingProposalSummary(proposal.id);
			} else {
				getCscHistory(hash, proposal.id);
			}
		}

		return () => {
			clearSummary();
		};
	}, []);

	useEffect(() => {
		if (proposalSummary === undefined) {
			clearSummary();
			clearCsCHistory();
			handleClose(null);
		}
	}, [proposalSummary]);

	useEffect(
		() => () => {
			clearSummary();
			clearCsCHistory();
		},
		[]
	);

	const prizeBonusGoals: IPrizeGoal[] = [];
	['xphrBuGoal', 'xphrCompanyGoal', 'xphrIndGoal', 'xphrProjectGoal'].map(goal => {
		[1, 2, 3, 4].map(e => {
			if (proposalSummary?.csc && proposalSummary?.csc[`${goal}${e}Amount`]) {
				prizeBonusGoals.push({
					uniqueId: uuidv4(),
					goalTypeId: getGoalTypeId(goal),
					goalDetails: proposalSummary?.csc[`${goal}${e}`] || '',
					prizePercentage: proposalSummary?.csc[`${goal}${e}Percent`] || 0,
					prizeValue: proposalSummary?.csc[`${goal}${e}Amount`] || 0
				});
			}
			return e;
		});
		return goal;
	});

	const benefits: IOtherBenefit[] = [];
	['xphrMonthlyOtherBen'].map(goal => {
		[1, 2, 3, 4].map(e => {
			if (proposalSummary?.csc && proposalSummary?.csc[`${goal}${e}V`]) {
				benefits.push({
					uniqueId: uuidv4(),
					goalDetails: proposalSummary?.csc[`${goal}${e}D`],
					prizeValue: proposalSummary?.csc[`${goal}${e}V`]
				});
			}
			return e;
		});
		return goal;
	});

	const getProposalMergedObject = () => {
		if (!proposalSummary) return null;

		if (proposal.token) return { ...proposal, ...proposalSummary };

		return {
			...proposal,
			...proposalSummary.csc,
			...proposalSummary.proposalToChange,
			...proposalSummary.summary,
			workScheduleExemptionValue: proposalSummary.csc.xphrWorkExempionValue,
			workScheduleExemptionPercentage: proposalSummary.csc.xphrWorkExempion,
			christmasTwelfthValue: proposalSummary.csc.xphrMonthlyXmasValue,
			vacationsTwelfthValue: proposalSummary.csc.xphrMonthlyHolidayValue,
			monthlyAverageFoodAllowance: proposalSummary.csc.xphrMonthlyAvgFoodValue,
			foodAllowance: proposalSummary.csc.xphrFoodAllowance,
			nationalAllowanceMonthlyValue: proposalSummary.csc.xphrMonthlyNationalValue,
			otherExpenses: proposalSummary.summary.otherExpenses,
			lifeInsuranceValue: proposalSummary.csc.xphrLifeInsuranceValue,
			communicationsPlafond: proposalSummary.csc.xphrMonthlyCommPlCost,
			commsPlafondFlag: proposalSummary.csc.xphrMonthlyStComPlafond != 0 ? true : false,
			commsPlafond: proposalSummary.csc.xphrMonthlyStComPlafond,
			healthInsurance:
				proposalSummary.proposalToChange.numberMembersDep > 0
					? (proposalSummary.proposalToChange.numberMembersDep + 1) *
					  proposalSummary.csc.xphrMonthlyHealthInCost
					: proposalSummary.csc.xphrMonthlyHealthInCost,
			monthlyWorkInsuranceEstimateCost: proposalSummary.csc.xphrMonthlyWorkInECost,
			monthlyStandardWorkInsuranceCostPercentage: proposalSummary.csc.xphrMonthlyStWorkIns,
			monthlyStandardLaptopCost: proposalSummary.csc.xphrMonthlyStLaptopCost,
			laptopMonthlyValue: proposalSummary.csc.xphrMonthlyLaptopCost,
			irsTaxPercentage: proposalSummary.summary.irsTaxPercentage,
			irsTaxTwelfthPercentage: proposalSummary.summary.irsTaxTwelfthPercentage,
			annualGrossValue: proposalSummary.csc.xphrAnnualGrossValue,
			monthlyGrossValue12Months: proposalSummary.csc.xphrMonthlyGrossValPriz,
			monthlyGrossValueWithPrize: proposalSummary.summary.monthlyGrossValueWithPrize,
			annualNetValue: proposalSummary.csc.xphrAnnualNetAmount,
			monthlyNetValue: proposalSummary.csc.xphrMonthlyNetAmount,
			annualPrizeValue: proposalSummary.csc.xphrPrizeValue,
			annualPrizePercentageOfAnnualGrossValue: proposalSummary.proposalToChange.prizeBonusPercentage,
			numberMembersDep: proposalSummary.proposalToChange.numberMembersDep,
			annualGrossValueWithPrize: proposalSummary.csc.xphrAnnualGrossValPrize,
			annualGrossValueWithPrizeAndWithBenefits: proposalSummary.summary.annualGrossValueWithPrizeAndWithBenefits,
			annualNetValueWithPrize: proposalSummary.csc.xPHRAnnualNetValuewithPrize,
			monthlyNetValueWithPrize: proposalSummary.csc.xphrMonthlyNetWithPrize,
			businessUnitName: proposalSummary.csc.xPHRAdOrgBunit$_identifier,
			divisionName: proposalSummary.csc.xphrAdOrgDivision$_identifier,
			departmentName: proposalSummary.csc.xphrAdOrgDepartment$_identifier,
			endDate: proposalSummary.csc.formattedEndDate,
			collaborationRoleName: proposalSummary.csc.collaborationRole,
			primaryOfficeName: proposalSummary.csc.xphrEmployeeOffice,
			workingOfficeName: proposalSummary.csc.workingOffice,
			customerInContractName: proposalSummary.csc.xphrEmployeeWorkOffice,
			annualGrossCostWithPrize: proposalSummary.csc.xphrAnnualGrossCostPriz,
			annualGrossCost: proposalSummary.csc.xphrAnnualGrossAmount,
			annualGrossCostWithPrizeAndNoBenefits: proposalSummary.csc.xphrAnGrossCostPNoBen,
			monthlyGrossCostWithPrize: proposalSummary.csc.xphrMonthlyGrossCostPri,
			monthlyGrossCost: proposalSummary.csc.xphrMonthlyGrossCost,
			dailyCostWithPrize: proposalSummary.csc.xphrDailyCostWithPrize,
			dailyCost: proposalSummary.csc.xphrDailyCostMax,
			baseGrossSSTaxValue: proposalSummary.summary.baseGrossSSTaxValue,
			christmasTwelfthSSTaxValue: proposalSummary.summary.christmasTwelfthSSTaxValue,
			vacationsTwelfthSSTaxValue: proposalSummary.summary.vacationsTwelfthSSTaxValue,
			baseGrossIRSTaxValue: proposalSummary.summary.baseGrossIRSTaxValue,
			christmasTwelfthIRSTaxValue: proposalSummary.summary.christmasTwelfthIRSTaxValue,
			vacationsTwelfthIRSTaxValue: proposalSummary.summary.vacationsTwelfthIRSTaxValue,
			totalBenefitsAnnualValue: proposalSummary.summary.totalBenefitsAnnualValue,
			annualGrossValueWithBenefits: proposalSummary.summary.annualGrossValueWithBenefits,
			monthlyGrossValue14Months: proposalSummary.summary.monthlyGrossValue14Months,
			monthlyGrossValueWithPrize14Months: proposalSummary.summary.monthlyGrossValueWithPrize14Months,
			annualIRSValue: proposalSummary.summary.annualIRSValue,
			annualIRSValueWithPrize: proposalSummary.summary.annualIRSValueWithPrize,
			annualPrizeValueWeight: proposalSummary.summary.annualPrizeValueWeight,
			eligiblePrizeValue: proposalSummary.csc.xphrPrizeValue
				? (Number(proposalSummary.csc.xphrPrizeValue) * 12) / 12
				: 0,
			numberMembersDep: proposalSummary.proposalToChange.numberMembersDep,
			nationalAllowanceDailyValue: proposalSummary.summary.nationalAllowanceDailyValue,
			internationalAllowanceDailyValue: proposalSummary.summary.internationalAllowanceDailyValue,
			internationalAllowanceMonthlyValue: proposalSummary.summary.internationalAllowanceMonthlyValue,
			//otherBenefitsList: benefits,
			//prizeBonusGoals: prizeBonusGoals,
			prizeBonusGoalTypes: proposalSummary.prizeBonusGoalTypes,
			projects: proposal.projects?.map(({ id, ...e }) => e) || []
		};
	};

	const mergedProposal = getProposalMergedObject();

	const pageTitleActions = [
		{
			id: 'excel',
			onClick: () => proposalSummary && getCscHistoryExportExcel(hash, mergedProposal.id),
			disabled: !proposalSummary,
			icon: null,
			label: (
				<>
					<Download />
					<Typography className={classes.downloadButtonText}>EXCEL</Typography>
				</>
			)
		}
	];

	return (
		<div className={classes.root}>
			{loading && <LoadingOverlay />}
			<PageTitle
				title={
					<>
						<Typography gutterBottom color="primary" className={classes.name}>
							{mergedProposal?.name || 'N/A'}
						</Typography>
						<Typography className={classes.date}>
							<span className={classes.dateLabel}>DATE: </span>
							{(mergedProposal?.startingDate && parseDateToShow(mergedProposal?.startingDate)) || ' N/A'}
						</Typography>
					</>
				}
				actions={pageTitleActions}
			/>
			{proposalSummary === null && (
				<div className={classes.w100}>
					<LinearProgress />
				</div>
			)}
			{proposalSummary && (
				<Scrollbars
					style={{ marginTop: '20px', height: 'calc(100vh - 250px)', overflowX: 'hidden' }}
					renderTrackHorizontal={(props: any) => (
						<div {...props} style={{ display: 'none' }} className="track-horizontal" />
					)}>
					<SummaryTable proposal={mergedProposal} formFields={proposalSummary.formFields} />
				</Scrollbars>
			)}
		</div>
	);
};

export default DetailsModalHistory;
