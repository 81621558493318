/* eslint-disable indent */
import { Dispatch } from 'redux';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import {
	fetchBudgetTable,
	fetchBudgetAudit,
	fetchPOSTNewBudget,
	sendPOSTNewBudget,
	sendPUTBudgetBiRefresh,
	fetchBudgetInfo,
	sendPUTNewBudget,
	fetchBudgetDepartmentProjects,
	sendBudgetDelete,
	sendExportBudget,
	postImportBudget
} from 'lib/api/administration/budget';
import { IBudgetInfo, IBudgetPostPayload, INewBudgetModal } from 'typings/store/admin/budget';
import {
	setLoading,
	setError,
	setBudgetTable,
	setNewBudget,
	setBudgetPost,
	setBudgetEditInfo,
	setBudgetDepProjects,
	setBudgetPut,
	setClearBudgetAction,
	setClearBudgetInfo,
	setBudgetAudit,
	setBudgetBiRefresh,
	setDeleteBudget,
	setExportBudget,
	setPostImportBudget,
	setBudgetError,
	setBudgetCompanies
} from './actions';

export const getBudgetTable =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IBudgetInfo[] } | OriginalResponse = await fetchBudgetTable();
			if (response.data) {
				dispatch(setBudgetTable(response.data.budgetList || []));
				dispatch(setBudgetCompanies(response.data.companies || []));
			} else {
				dispatch(setBudgetError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setBudgetError(error as OriginalResponse));
		}
	};

export const getNewBudget =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: INewBudgetModal } | OriginalResponse = await fetchPOSTNewBudget();
			if (response.data) {
				dispatch(setNewBudget(response.data || []));
			} else {
				dispatch(setError(response));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const submitBudgetPost =
	(payload: IBudgetPostPayload) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: unknown } | OriginalResponse = await sendPOSTNewBudget(payload);
			if (response.data) {
				dispatch(setBudgetPost(response.data));
			} else {
				dispatch(setError(response));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const submitBudgetBiRefresh =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: OriginalResponse = await sendPUTBudgetBiRefresh();

			if (response.message) {
				dispatch(setBudgetBiRefresh(response.message));
			} else {
				dispatch(setError(response));
			}
		} catch (error) {
			dispatch(setBudgetBiRefresh(null));
		}
	};

export const getBudgetAudit =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: unknown } | OriginalResponse = await fetchBudgetAudit(id);
			if (response.data) {
				dispatch(setBudgetAudit(response.data.auditLog));
			} else {
				dispatch(setError(response));
			}
		} catch (error) {
			// TODO: remove this comment and leave this: dispatch(setError(error));
			dispatch(setBudgetEditInfo(null));
		}
	};

export const getBudgetEditInfo =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: unknown } | OriginalResponse = await fetchBudgetInfo(id);
			if (response.data) {
				dispatch(setBudgetEditInfo(response.data));
			} else {
				dispatch(setBudgetError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setBudgetError(error as OriginalResponse));
		}
	};

export const getBudgetDepProjects =
	(departmentId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: unknown } | OriginalResponse = await fetchBudgetDepartmentProjects(departmentId);
			if (response.data) {
				dispatch(setBudgetDepProjects(response.data));
			} else {
				dispatch(setError(response));
			}
		} catch (error) {
			// TODO: remove this comment and leave this: dispatch(setError(error));
			dispatch(setBudgetDepProjects(null));
		}
	};

export const submitPUTNewBudget =
	(id: number, payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const res: { message?: unknown } | OriginalResponse = await sendPUTNewBudget(id, payload);
			if (res.message) {
				const response: { data?: unknown } | OriginalResponse = await fetchBudgetInfo(id);
				if (response.data) {
					dispatch(setBudgetEditInfo(response.data));
					dispatch(setBudgetPut(res));
				} else {
					dispatch(setError(response));
				}
			} else {
				dispatch(setError(res));
			}
		} catch (error) {
			dispatch(setBudgetPut(error));
		}
	};

export const submitDeleteBudget =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await sendBudgetDelete(payload);
			const newData: { data?: IBudgetInfo[] } | OriginalResponse = await fetchBudgetTable();
			dispatch(setDeleteBudget(response?.data, newData?.data || null));
			dispatch(setBudgetTable(newData.data.budgetList || []));
		} catch (error) {
			dispatch(setDeleteBudget(null, null));
		}
	};

export const submitExportBudget =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await sendExportBudget(payload);
			dispatch(setExportBudget(response.data));
		} catch (error) {
			dispatch(setExportBudget(null));
		}
	};
export const sendPostImportBudget =
	(payload: Document | FormData | null) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await postImportBudget(payload);
			const newData: { data?: IBudgetInfo[] } | OriginalResponse = await fetchBudgetTable();
			dispatch(setPostImportBudget(data));
			dispatch(setBudgetTable(newData.data.budgetList || []));
		} catch (error) {
			dispatch(setPostImportBudget(null));
		}
	};

export const clearBudgetAction =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setClearBudgetAction());
	};

export const clearBudgetInfo =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setClearBudgetInfo());
	};

export const clearBudgetError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setBudgetError(null));
	};
