import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getAccessControlListAddUser,
	clearEditUser,
	clearAdministrationError
} from 'store/administration/administration/thunks';
import { setAccessControlAddPageFilter } from 'store/filters/actions';
import { AppState } from 'typings/state';

import AccessControlAddUser from './AccessControlAddUser';

const mapStateToProps = ({ administration, filters }: AppState) => ({ administration, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{ getAccessControlListAddUser, clearEditUser, clearAdministrationError, setAccessControlAddPageFilter },
		dispatch
	);

export type AccessControlAddUserProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AccessControlAddUser);
