import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import {
	ProjectsAndClientsActions as Actions,
	INewClientInfo,
	INewClient,
	INewProjectInfo,
	INewProject
} from 'typings/store/admin/projectsAndClients';
import { OriginalResponse } from 'lib/handlers/requestTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);

export const setProjectsAndClientsError = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.SET_ERROR, payload);

export const setNewClient = (payload: INewClientInfo | null): IDispatchType => action(Actions.GET_NEW_CLIENT, payload);

export const setPostNewClient = (payload: INewClient | null | []): IDispatchType =>
	action(Actions.POST_NEW_CLIENT, payload);

export const setNewProjectData = (payload: INewProjectInfo | null): IDispatchType =>
	action(Actions.GET_NEW_PROJECT, payload);

export const setPostNewProject = (payload: INewProject | null | []): IDispatchType =>
	action(Actions.POST_NEW_PROJECT, payload);
