import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		padding: '10px 30px',
		flexGrow: 1,
		backgroundColor: 'transparent',
		marginBottom: '110px'
	},
	benefitsContainer: {
		padding: '30px 0'
	},
	benefitsItem: {
		boxShadow: theme.boxShadow,
		borderRadius: '5px',
		padding: '15px'
	},
	hiddenSubGroup: {
		fontFamily: 'Open Sans',
		fontWeight: 600,
		textTransform: 'uppercase',
		fontSize: '16px',
		color: theme.inactiveText
	},
	listItem: {
		padding: '0px 10px',
		minHeight: '20px',
		height: '30px'
	},
	itemLabel: {
		fontSize: '16px',
		color: theme.palette.secondary.main,
		textTransform: 'uppercase'
	}
}));

export { useStyles };
