import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'typings/state';
import { getProposalSummary, clearSummary, getProposalExport } from 'store/administration/proposals/thunks';

import Summary from './Summary';

const mapStateToProps = ({ proposals }: AppState) => ({ proposals });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getProposalSummary, clearSummary, getProposalExport }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
