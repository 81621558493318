import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	totalHours: {
		width: '-webkit-fill-available',
		background: '#F8F9FC', //
		borderRadius: 10,
		padding: '10px'
	},
	availableCredits: {
		color: theme.accentColor,
		fontSize: '24px',
		fontWeight: 'bold'
	},
	creditNumber: {
		fontSize: '24px',
		fontWeight: 'lighter'
	},
	calendarNavs: { fontSize: '22px' },
	statusBar: {
		// direction: 'row',
		justify: 'center',
		alignItems: 'center',
		display: 'flex'
	},
	statusIcon: {
		marginRight: '10px'
	},
	centerInParent: {
		margin: 'auto',
		display: 'block'
	}
}));

export { useStyles };
