import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { getProposalSendEmail, submitProposalSendEmail } from 'store/administration/proposals/thunks';

import SendProposalEmail from './SendProposalEmail';

const mapStateToProps = ({ proposals }: AppState) => ({ proposals });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getProposalSendEmail, submitProposalSendEmail }, dispatch);

export type SendProposalEmailProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SendProposalEmail);
