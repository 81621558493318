/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

//* EXTERNAL LIBS --> MUI
import { Typography, IconButton } from '@mui/material';
import { Edit, HourglassEmpty, Check, Clear } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { DatePicker } from 'xpand-ui/forms';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import { START_API_PATH } from 'lib/utils/constants';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import ViewSkillsDetails from './ViewSkillsDetails';
import { useStyles } from './styles';
import { SkillsEvaluationProps } from '.';

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Skills Reminder - XPECP.png';
import { getLSField } from 'lib/utils/cookies';

const SkillsEvaluation: FC<SkillsEvaluationProps> = ({
	skillsAndResumes,
	personalInfo,
	filters,
	getSkillsEvaluation,
	getSystemSkills,
	sendPostResendSkillsEvaluation,
	sendPostChangeDueDate,
	clearSkillsAndResumesError,
	setSkillsReminderPageFilter
}) => {
	const classes = useStyles();
	const { listUsersSkills, loading, error } = skillsAndResumes;
	const { systemSkills } = personalInfo;
	const { skillsReminderFilter } = filters;

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(skillsReminderFilter);

	useEffect(() => {
		setSkillsReminderPageFilter(searchValue);
	}, [searchValue]);

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	const [openDetails, setOpenDetails] = useState(null);
	const [openConfirmationSend, setOpenConfirmationSend] = useState<number | boolean | null>(null);

	const [dateInput, setDateInput] = useState({});
	const [editMode, setEditMode] = useState({});
	const [isHovering, setToggleHoverState] = useState({});

	const handleMouseHover = (id: number, value: boolean) => {
		setToggleHoverState({ [id]: value });
	};

	const handleLabelChange = (newVal: string, id: number) => {
		setDateInput(prev => ({ ...prev, [id]: newVal }));
	};

	const resendEmailNotification = (id: number) => {
		sendPostResendSkillsEvaluation(id);
		setOpenConfirmationSend(null);
	};

	useEffect(() => {
		if (!error && (!listUsersSkills || listUsersSkills?.employeeSkills?.length === 0 || !systemSkills)) {
			getSkillsEvaluation();
			getSystemSkills();
		}
	}, []);

	// Showing datefield as an editable one in case the editMode is true
	const showDateField = (row: { id: number }, text: string) => {
		if (Object.keys(editMode).length > 0 && editMode[row.id]) {
			return (
				<DatePicker
					value={dateInput[row.id] || text}
					handleChange={newDate => {
						newDate && handleLabelChange(newDate, row.id);
					}}
				/>
			);
		}
		if (Object.keys(dateInput).length > 0 && dateInput[row.id]) {
			return parseDateToShow(dateInput[row.id]);
		}

		return parseDateToShow(text);
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				format: row => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${row.employee}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{ label: 'User', id: 'employee', accentColumn: true },
			{ label: 'Name', id: 'name' },
			{
				label: 'Last Submitted',
				id: 'lastSubmittedDate',
				type: 'date',
				format: (row, text) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Last Reminder',
				id: 'lastReminderDate',
				type: 'date',
				format: (row, text) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Next Reminder',
				id: 'nextReminderDate',
				type: 'date',
				format: (row, text) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Status',
				id: 'status',
				format: (row, text) =>
					text === 0 ? (
						<div className={classes.dueDateIcons}>
							<HourglassEmpty color="primary" /> Due{' '}
						</div>
					) : (
						<div className={classes.dueDateIcons}>
							<Check color="primary" /> Ok{' '}
						</div>
					)
			},
			{
				label: 'Due Date',
				id: 'nextEvaluationDueDate',
				type: 'date',
				// Making the " Edit " icon for the dueDate only be visible whenever the mouse is hovered over it
				// Allowing the user's date on the table and change the next dueDate
				// Sets EditMode to true whenever the button is visible(hovered) and pressed after.
				// Sets EditMode back to false after sending in the new dueDate
				format: (row, text) => {
					const { id } = row;
					return (
						<div
							className={classes.editableLabel} // add min width
							onMouseEnter={() => handleMouseHover(id, true)}
							onMouseLeave={() => handleMouseHover(id, false)}>
							{showDateField(row, text)}
							{Boolean(!editMode[id]) && !getLSField('impersonate_userInfo') && (
								<IconButton
									className={classes.iconContainer}
									onClick={() => {
										setEditMode({ [id]: true });
									}}>
									<Edit />
								</IconButton>
							)}
							{Boolean(editMode[id]) && (
								<>
									<IconButton
										onClick={() => {
											setEditMode({ [id]: false });
											dateInput[id] = text;
										}}>
										<Clear />
									</IconButton>
									<IconButton
										className={classes.iconContainer}
										disabled={dateInput[id] === 'Invalid date'}
										onClick={() => {
											setEditMode({ [id]: false });
											if (dateInput[id] !== text) {
												sendPostChangeDueDate({
													id,
													dueDate: dateInput && dateInput[id]
												});
											}
										}}>
										<Check />
									</IconButton>
								</>
							)}
						</div>
					);
				}
			}
		],
		[isHovering, editMode, dateInput, listUsersSkills]
	);

	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'skillEvaluationMenu',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'skillEvaluationMenuViewDetails',
						label: 'View Details',
						onClick: row => setOpenDetails(row.id)
					},
					{
						id: 'skillEvaluationMenuResend',
						label: 'Resend',
						disabled: row => row.status !== 0,
						onClick: row => setOpenConfirmationSend(row.id)
					}
				]
			}
		],
		[]
	);
	const tableData = useMemo(
		() =>
			listUsersSkills?.employeeSkills?.map(e => ({
				...e,
				lastSubmittedDateParsed: e.lastSubmittedDate ? parseDateToShow(e.lastSubmittedDate) : '',
				lastReminderDateParsed: e.lastReminderDate ? parseDateToShow(e.lastReminderDate) : '',
				nextReminderDateParsed: e.nextReminderDate ? parseDateToShow(e.nextReminderDate) : '',
				nextEvaluationDueDateParsed: e.nextEvaluationDueDate ? parseDateToShow(e.nextEvaluationDueDate) : '',
				statusParsed: e.status === 0 ? 'Due' : 'Ok'
			})),
		[listUsersSkills, editMode]
	);

	if (error) return handleErrorPage(error, clearSkillsAndResumesError);

	const isLoading = listUsersSkills === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="nextEvaluationDueDate"
				handleSearch={{ searchValue, setSearchValue }}
				handleDiagramModal={{ diagramModal, setDiagramModal }}
			/>
			<Dialog
				fullScreen
				customClasses={{ fullScreenContent: classes.fullScreenContent }}
				modal={{
					open: Boolean(openDetails),
					handleClose: () => setOpenDetails(null),
					content: (
						<>
							{' '}
							<Scrollbars
								style={{ height: 'calc(100vh - 220px)', overflowX: 'hidden' }}
								renderTrackHorizontal={(props: any) => (
									<div {...props} style={{ display: 'none' }} className="track-horizontal" />
								)}>
								<ViewSkillsDetails userId={openDetails} systemSkills={systemSkills} />{' '}
							</Scrollbars>
						</>
					)
				}}
				actions={[]}
			/>
			<Dialog
				modal={{
					open: Boolean(openConfirmationSend),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenConfirmationSend(false);
					},
					content: (
						<>
							<Typography gutterBottom>Are you sure you want to notify this employee?</Typography>
						</>
					)
				}}
				title="Resend Evaluation"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setOpenConfirmationSend(false)
					},
					{
						id: 'confirm',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => resendEmailNotification(openConfirmationSend as number),
						disabled: getLSField('impersonate_userInfo')
					}
				]}
				scroll="body"
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(SkillsEvaluation);
