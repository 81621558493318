import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import { IProjectRepositories, IProjectBudgets, IProjectExternalUsers } from 'typings/store/admin/projectsAndClients';
import {
	string,
	stringRequired,
	boolean,
	booleanRequired,
	dateRequired,
	projectCodeRequired,
	projectNameRequired
} from 'lib/utils/formUtils';

import { store } from '../../../../../store/company/reducer';

const state = store.getState();

export const getProposalReferenceIds = () => {
	return state.proposalReferenceAllowedIDS;
};

export const schema = yup.object().shape({
	company: stringRequired,
	createForPT: boolean.when('company', {
		is: 'PT',
		then: booleanRequired,
		otherwise: boolean.nullable()
	}),
	projectCode: projectCodeRequired.max(25, 'This field exceeds the maximum limit (25)'),
	name: projectNameRequired.max(50, 'This field exceeds the maximum limit (50)'),
	startDate: dateRequired,
	plannedEndDate: dateRequired.min(yup.ref('startDate'), ({ min }) =>
		moment(min).isValid() ? `End date to be after ${parseDateToShow(min as string)}` : 'Invalid Start Date'
	),
	type: stringRequired,
	proposalReference: string.when('type', {
		is: (type: string) => getProposalReferenceIds().includes(type),
		then: stringRequired.max(100, 'This field exceeds the maximum limit (100)'),
		otherwise: string.nullable()
	}),
	businessUnit: stringRequired,
	division: stringRequired,
	department: stringRequired,
	lineOfBusiness: stringRequired,
	costType: stringRequired,
	hubDistributionScheme: stringRequired,
	salesType: string.when('costType', {
		is: 'dir',
		then: stringRequired,
		otherwise: string.nullable()
	}),
	sponsorName: string.when('type', {
		is: (type: string) => getProposalReferenceIds().includes(type),
		then: stringRequired.max(50, 'This field exceeds the maximum limit (50)'),
		otherwise: string.nullable()
	}),
	sponsorEmail: string.when('type', {
		is: (type: string) => getProposalReferenceIds().includes(type),
		then: stringRequired.email('Must be a valid email'),
		otherwise: string.nullable()
	}),
	isTimeAndMaterials: boolean,
	applications: yup
		.array()
		.of(string)
		.min(1, 'Must have at least one application selected')
		.required('Choose the applications')
		.nullable(),
	isMultiUnitProject: booleanRequired,
	teamLead: stringRequired,
	projectManager: stringRequired,
	accountManager: stringRequired,
	timesheetApproval: stringRequired,
	expenseApproval: stringRequired,
	team: yup
		.array()
		.of(string)
		.min(1, 'Must have at least one team member')
		.required('Choose the team members')
		.nullable(),
	teamCompanies: yup.array().of(string).nullable(),
	repositories: yup
		.array()
		.of(
			yup.object().shape({
				type: stringRequired,
				name: stringRequired
			})
		)
		.required('Must have repositories'),
	hasExternalUsers: booleanRequired,
	externalUsers: yup.array().when('hasExternalUsers', {
		is: true,
		then: yup.array().of(
			yup.object().shape({
				name: stringRequired,
				company: stringRequired,
				email: stringRequired.email('Must be a valid email')
			})
		)
	}),
	hasBudget: booleanRequired,
	budget: yup.array().when('hasBudget', {
		is: true,
		then: yup.array().of(
			yup.object().shape({
				profile: stringRequired,
				days: stringRequired,
				dailyRate: stringRequired,
				role: stringRequired,
				level: stringRequired
			})
		)
	}),

	comments: string.max(512, 'This field exceeds the maximum limit (512)')
});

export const defaultValues = {
	company: '',
	createForPT: false,
	projectCode: '',
	name: '',
	startDate: '',
	plannedEndDate: '',
	type: '',
	proposalReference: '',
	businessUnit: '',
	division: '',
	department: '',
	lineOfBusiness: '',
	costType: '',
	hubDistributionScheme: '',
	salesType: '',
	sponsorName: '',
	sponsorEmail: '',
	isTimeAndMaterials: false,
	applications: [],
	isMultiUnitProject: false,
	teamLead: '',
	projectManager: '',
	accountManager: '',
	timesheetApproval: '',
	expenseApproval: '',
	team: [],
	teamCompanies: [],
	repositories: [] as IProjectRepositories[],
	hasExternalUsers: false,
	externalUsers: [] as IProjectExternalUsers[],
	hasBudget: false,
	budget: [] as IProjectBudgets[],
	comments: ''
};

export const tabs = [
	{
		id: 0,
		label: 'Project information'
	},
	{
		id: 1,
		label: 'Project Team'
	},
	{
		id: 2,
		label: 'Source repositories'
	},
	{
		id: 3,
		label: 'External users'
	},
	{
		id: 4,
		label: 'Budgets'
	},
	{
		id: 5,
		label: 'Comments'
	}
];
