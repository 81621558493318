import * as yup from 'yup';

import { stringRequired, dateRequired, dateDefault, string } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	office: string,
	type: string,
	startDate: dateRequired,
	endDate: dateDefault
});

export const defaultValues = {
	office: '',
	type: '',
	startDate: null,
	endDate: ''
};
