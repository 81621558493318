import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		// marginLeft: '-20px'
	},
	tabsRoot: {
		padding: '35px 0'
	},
	careerContainer: {
		padding: '35px 0'
	},
	careerItem: {
		boxShadow: theme.boxShadow,
		borderRadius: '5px',
		padding: '15px'
	},
	nonEditableField: {
		color: '#3D464D '
	},
	infoLabel: {
		fontSize: '16px',
		display: 'flex',
		color: theme.grayedColor
	}
}));

export { useStyles };
