import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: { marginBottom: '25px' },
	appBar: {
		position: 'relative'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
		position: 'relative',
		color: theme.accentColor
	},
	resumeTitle: {
		marginBottom: '50px',
		flex: 1,
		position: 'relative',
		color: theme.accentColor
	},
	groupCardResume: {
		maxWidth: '1100px',
		width: '100%',
		borderBottom: '1px solid lightGrey',
		marginBottom: '30px',
		flexDirection: 'inherit'
	},
	skillsGroupCard: {
		marginBottom: '12px'
	},
	feedbackCard: {
		marginTop: '20px'
	},
	feedbackContainer: {
		padding: '15px 15px'
	},
	feedbackItem: {
		boxShadow: theme.boxShadow,
		borderRadius: '5px',
		padding: '15px'
	},
	mainGrids: {
		margin: '25px 25px 50px 25px',
		flexDirection: 'inherit'
	},

	/** skills tab */
	skillsGroupTitle: {
		fontWeight: 600,
		fontSize: 16,
		width: '100%',
		padding: '20px 5%'
	},
	skillsSubGroupsContainer: {
		padding: '0 12% 50px'
	},
	skillHeaderLabel: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px',
		color: theme.palette.secondary.main,
		textAlign: 'initial',
		minWidth: '330px'
	},
	skillHeaderInputs: {
		fontFamily: 'Open Sans Bold',
		fontSize: '12px',
		color: theme.typographyColor,
		textAlign: 'initial',
		minWidth: '330px'
	},
	skillHeaderLabelLanguages: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px',
		color: theme.palette.secondary.main,
		textAlign: 'initial'
	},
	skillHeaderInputsLanguages: {
		fontFamily: 'Open Sans Bold',
		fontSize: '12px',
		color: theme.typographyColor,
		textAlign: 'initial'
	},
	skillHeaderLine: {
		borderBottom: '1px solid #E4E6E8',
		padding: '0 !important'
	},
	skillLabel: {
		fontSize: '18px',
		textAlign: 'initial',
		color: theme.grayedColor
	}
	/** end skills tab */
}));

export { useStyles };
