import React, { useState, useEffect, useMemo } from 'react';

import { LoadingOverlay, Table } from 'xpand-ui/core';

import withLayout from 'lib/hocs/withLayout';

//* TYPINGS
import { ITableAction, ITableColumn, ITableColumnsFilter } from 'typings/store/ComponentLib';
import { START_API_PATH } from 'lib/utils/constants';
import { IEmployeeInfo, IRenewContractRow } from 'typings/store/admin/employeeSalaryCategories';
import { useStyles } from './styles';
//* LOCAL COMPONENT IMPORTS
import { CollaborationTypesManagementProps } from '.';
import { useHistory } from 'react-router';
import { CheckCircle, List, RadioButtonUnchecked } from '@mui/icons-material';
import Edit from '@mui/icons-material/Edit';
import { allStatus } from 'pages/Admin/_ProposalsFormUtils/utils';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Dialog } from 'xpand-ui/core';
import { getLSField } from 'lib/utils/cookies';
import imageURL from '../../../../assets/images/status diagrams/Diagram - Employee Salary Categories - XPECP.png';

const CollaborationTypesManagement: React.FC<CollaborationTypesManagementProps> = ({
	collaborationTypesManagement,
	filters,
	getEmployeesList,
	setECPageFilter
}) => {
	const classes = useStyles();
	const history = useHistory();
	const goToPage = (path: string) => history.push(path);
	const { loading, error, employeesList } = collaborationTypesManagement;
	const { ecFilter, ecStatusFilter } = filters;
	const [searchValue, setSearchValue] = useState(ecFilter);
	const [statusFilter, setStatusFilter] = useState(ecStatusFilter);
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	useEffect(() => {
		setECPageFilter({
			searchValue,
			statusFilter
		});
	}, [searchValue, statusFilter]);

	useEffect(() => {
		if (!employeesList) {
			getEmployeesList();
		}
	}, [employeesList]);

	useEffect(() => {
		getEmployeesList();
	}, []);

	const statusHelper = useMemo(() => allStatus(classes), []);
	// TABLE CUSTOM FILTER STATUS CHANGER
	const handleStatusFilterPropsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStatusFilter({ ...statusFilter, [event.target.name]: event.target.checked });
	};

	//Organizes the status filter with the respective order:
	const customFilters = [
		{ name: 'none', label: 'NONE', id: 9 },
		{ name: 'new', label: 'NEW', id: 0 },
		{ name: 'awaiting', label: 'AWAITING APPROVAL', id: 7 },
		{ name: 'approved', label: 'APPROVED', id: 1 },
		{ name: 'rejected', label: 'REJECTED', id: 8 }
	];
	// TABLE CUSTOM FILTER CONTENT
	const statusFilterComponent = () => (
		<FormControl component="fieldset" style={{ padding: '25px' }}>
			<FormGroup>
				{customFilters.map((e, i) => {
					if (
						e.name === 'none' ||
						e.name === 'new' ||
						e.name === 'awaiting' ||
						e.name === 'approved' ||
						e.name === 'rejected'
					) {
						return (
							<FormControlLabel
								key={e.name}
								control={
									<Checkbox
										color="primary"
										icon={<RadioButtonUnchecked />}
										checkedIcon={<CheckCircle />}
										checked={statusFilter[e.id]}
										onChange={handleStatusFilterPropsChange}
										name={`${e.id}`}
									/>
								}
								label={e.label}
							/>
						);
					}

					return '';
				})}
			</FormGroup>
		</FormControl>
	);

	const customJobLevel = (jobLevel: string, careerSubLevel: string): string => {
		const jobLevelName = jobLevel.split(' ');
		return (
			jobLevelName[0].charAt(0).toUpperCase() + jobLevelName[0].slice(1).toLowerCase() + ' - ' + careerSubLevel
		);
	};

	// TABLE COMPONENT - columns
	const tableData: any | null = useMemo(
		() =>
			employeesList &&
			employeesList.employees.map((e: any) => ({
				...e,
				username: e.username || '',
				name: e.name || '',
				category: e.businessPartnerCategory || '',
				status: statusHelper[e.lastECInProgressStatus === 99 ? 9 : e.lastECInProgressStatus].label,
				jobRole: e?.erpInfo?.careerLevel?.xphrProfileEligibleRoleData?.name,
				jobRoleLevel:
					e?.erpInfo.careerLevel?.xphrProfileRoleLevelData?.name &&
					e?.erpInfo?.careerLevel?.xphrProfileRoleLevelData?.careerSubLevel
						? customJobLevel(
								e?.erpInfo?.careerLevel?.xphrProfileRoleLevelData?.name,
								e?.erpInfo?.careerLevel?.xphrProfileRoleLevelData?.careerSubLevel
						  )
						: ''
			})),
		[employeesList]
	);
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				isSortable: false,
				format: ({ username }) => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${username}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{ label: 'User', id: 'username', accentColumn: true },
			{ label: 'Name', id: 'name', accentColumn: true },
			{ label: 'Company', id: 'company' },
			{ label: 'Business Unit', id: 'businessUnit' },
			{ label: 'Department', id: 'department' },
			{ label: 'Job Role', id: 'jobRole' },
			{ label: 'Job Role Level', id: 'jobRoleLevel' },
			{ label: 'Employee Category', id: 'category', width: '17%', maxWidth: '17%' },
			{
				label: 'Status',
				id: 'lastECInProgressStatus',
				width: '10%',
				format: ({ lastECInProgressStatus }: IEmployeeInfo) => {
					if (lastECInProgressStatus === 99)
						return (
							<div className={classes.statusBar}>
								{statusHelper[9].icon} {statusHelper[9].label}
							</div>
						);
					if (statusHelper[lastECInProgressStatus])
						return (
							<div className={classes.statusBar}>
								{statusHelper[lastECInProgressStatus].icon} {statusHelper[lastECInProgressStatus].label}
							</div>
						);

					return (
						<div className={classes.statusBar}>
							{statusHelper[9].icon} {lastECInProgressStatus}
						</div>
					);
				}
			}
		],
		[employeesList]
	);
	// TABLE CUSTOM FILTER
	const tableColumnsFilter: ITableColumnsFilter = useMemo(
		() => ({
			lastECInProgressStatus: {
				anyToFilter: Object.values(statusFilter).includes(true),
				filterComponent: statusFilterComponent(),
				handleFilter: info => {
					const payloadToSearch = Object.keys(statusFilter)
						.filter(field => statusFilter[field])
						.map(e => +e)
						.map(e => (e === 9 ? 99 : e));

					return info.filter(item => payloadToSearch.includes(item.lastECInProgressStatus));
				}
			}
		}),
		[statusFilter]
	);

	const tableActions: any = [
		{
			id: 'someAction',
			type: 'button',
			icon: <List />,
			render: () => true,
			disabled: () => (getLSField('impersonate_userInfo') ? true : false),
			to: row => `/cp/admin/salariesAndContracts/collaborationTypes/details/${row.hash}`,
			onClick: row => {
				goToPage(`/admin/salariesAndContracts/collaborationTypes/details/${row.hash}`);
			}
		}
	];

	const isLoading = employeesList === null;
	if (isLoading) return <LoadingOverlay />;
	return (
		<>
			{loading && <LoadingOverlay />}
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				tableColumnsFilter={tableColumnsFilter}
				defaultSortColumn="desc"
				handleSearch={{ searchValue, setSearchValue }}
				handleDiagramModal={{ diagramModal, setDiagramModal }}
			/>
			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(CollaborationTypesManagement);
