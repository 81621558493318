/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';

import { stringRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	declineComent: stringRequired
});

export const defaultValues = {
	declineComent: ''
};
