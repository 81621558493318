import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getAccessControlEditUser,
	sendPutAccessControl,
	clearEditUser
} from 'store/administration/administration/thunks';
import { AppState } from 'typings/state';

import GrantAccess from './GrantAccess';

const mapStateToProps = ({ administration }: AppState) => ({ administration });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getAccessControlEditUser, sendPutAccessControl, clearEditUser }, dispatch);

export type GrantAccessProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(GrantAccess);
