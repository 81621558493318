import { createReducer } from 'typesafe-actions';
import { EmployeeSalaryActions as Actions, EmployeeSalaryState } from 'typings/store/admin/employeeSalaryCategories';

const INITIAL_STATE: EmployeeSalaryState = {
	loading: false,
	error: null,
	employeesList: null,
	exportMultipleCSCs: null,
	employeeSalaryCategoriesList: null,
	newEmployeeSalary: null,
	employeesSalaryActions: null,
	employeeSalarySummary: null,
	cscDownload: null,
	proposalSummary: null,
	irsValue: null,
	cscAuditLog: null,
	contractsList: null,
	downloadDocument: null,
	renewContract: null,
	postRenewContract: null
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CSCActions = (state: any, { payload }: any) => ({
	...INITIAL_STATE,
	employeeSalaryCategoriesList: null, // for any actions we must ensure that a new request must be made the next time we access the ESC by user page
	employeesSalaryActions: payload.payload
});

const EmployeeSalaryCategorysReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.GET_EMPLOYEES_LIST]: (state, { payload }) => ({
		...INITIAL_STATE,
		employeesList: payload,
		employeeSalaryCategoriesList: state.employeeSalaryCategoriesList
	}),
	[Actions.GET_EXPORT_MULTIPLE_CSCS]: (state, { payload }) => ({
		...state,
		loading: false,
		exportMultipleCSCs: payload
	}),
	[Actions.GET_EMPLOYEE_SALARY_CATEGORIES_LIST]: (state, { payload }) => ({
		...INITIAL_STATE,
		employeesList: state.employeesList,
		employeeSalaryCategoriesList: { ...state.employeeSalaryCategoriesList, [payload.hash]: payload.payload }
	}),
	[Actions.SET_EMPLOYEE_SALARY_CATEGORY_CLOSE]: CSCActions,
	[Actions.SET_EMPLOYEE_SALARY_CATEGORY_CANCEL]: CSCActions,
	[Actions.SET_EMPLOYEE_SALARY_CATEGORY_REQUEST_APPROVAL]: CSCActions,
	[Actions.SET_EMPLOYEE_SALARY_CATEGORY_APPROVE]: CSCActions,
	[Actions.SET_EMPLOYEE_SALARY_CATEGORY_REJECT]: CSCActions,
	[Actions.GET_NEW_EMPLOYEE_SALARY]: (state, { payload }) => ({
		...INITIAL_STATE,
		newEmployeeSalary: payload
	}),
	[Actions.GET_EDIT_EMPLOYEE_SALARY]: (state, { payload }) => ({
		...INITIAL_STATE,
		newEmployeeSalary: payload
	}),
	[Actions.SUBMIT_NEW_EMPLOYEE_SALARY]: (state, { payload }) => ({
		...INITIAL_STATE,
		employeesSalaryActions: payload
	}),
	[Actions.SUBMIT_PUT_UPDATE_EMPLOYEE_SALARY]: (state, { payload }) => ({
		...INITIAL_STATE,
		employeesSalaryActions: payload
	}),
	[Actions.SET_IRS_VALUE]: (state, { payload }) => ({ ...state, loading: false, irsValue: payload }),
	[Actions.SET_CSC_HISTORY_DETAILS]: (state, { payload }) => ({
		...state,
		loading: false,
		proposalSummary: payload
	}),
	// RESET REDUCER
	[Actions.RESET_FORM_DATA]: () => ({ ...INITIAL_STATE }),
	[Actions.GET_CSC_AUDIT_LOG]: (state, { payload }) => ({
		...state,
		loading: false,
		cscAuditLog: payload
	}),
	[Actions.SET_CSC_HISTORY_EXPORT_EXCEL]: state => ({
		...state,
		loading: false,
		cscDownload: null
	}),
	[Actions.GET_CONTRACTS_LIST]: (state, { payload }) => ({ ...state, loading: false, contractsList: payload }),
	[Actions.GET_DOWNLOAD_DOCUMENT]: (state, { payload }) => ({
		...state,
		loading: false,
		downloadDocument: payload
	}),
	[Actions.GET_RENEW_CONTRACT]: (state, { payload }) => ({
		...state,
		loading: false,
		renewContract: payload
	}),
	[Actions.POST_RENEW_CONTRACT]: (state, { payload }) => ({
		...state,
		loading: false,
		postRenewContract: payload
	})
});

export default EmployeeSalaryCategorysReducer;
