/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-INTERNAL-APPS-LIB
import { DatePicker, Select } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';
import { Dialog } from 'xpand-ui/core';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { schema } from './yupSchema';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES
interface IExportModal {
	closePopup: any;
	exportBookingsList: any;
	companies: any;
}

//* COMPONENT
const ExportModal: FC<IExportModal> = ({ closePopup, exportBookingsList, companies }) => {
	const classes = useStyles();

	// form
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues: {
			office: null,
			type: null,
			startDate: null
		}
	});

	const exportModalActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => closePopup()
		},
		{
			id: 'submit',
			label: 'Export',
			color: 'primary',
			type: 'submit',
			form: 'form-desk-export-modal',
			variant: 'contained',
			disabled: Object.keys(errors).length !== 0 || getLSField('impersonate_userInfo'),
			onClick: () => null
		}
	];

	const saveClickHandler = async (payload: { office: Number | null; startDate: Date; type: string }) => {
		exportBookingsList(payload);
		closePopup();
	};

	return (
		<Dialog
			title="Export Booking"
			actions={exportModalActions}
			scroll="body"
			modal={{
				open: true,
				handleClose: () => closePopup(null),
				content: (
					<form id="form-desk-export-modal" onSubmit={e => handleSubmit(() => saveClickHandler(watch()))(e)}>
						<Grid container spacing={2} style={{ padding: '10px 5px 40px 5px' }}>
							<Grid item {...sizes[12]}>
								<Select
									name="office"
									label="Office"
									options={companies?.[0].offices?.map((e: any) => ({ id: e.id, label: e.name }))}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[12]}>
								<Select
									name="type"
									label="Type"
									options={[
										{ id: 'SEAT', label: 'SEAT' },
										{ id: 'PARKING', label: 'PARKING' },
										{ id: 'BICYCLE', label: 'BICYCLE' }
									]}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[12]}>
								<DatePicker
									required
									desktopModeMediaQuery={undefined}
									label="Start Date"
									name="startDate"
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[12]}>
								<DatePicker
									desktopModeMediaQuery={undefined}
									label="End Date"
									name="endDate"
									control={control}
									errors={errors}
								/>
							</Grid>
						</Grid>
					</form>
				)
			}}
		/>
	);
};

export default ExportModal;
