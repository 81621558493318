import withLayout from 'lib/hocs/withLayout';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog, PageTitle } from 'xpand-ui/core';
import { AppLinksProps } from '.';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { Match } from 'typings/store/generalTypes';
import { Grid, Typography } from '@mui/material';
import { sizes } from 'xpand-ui/utils/handlers';
import { InfoField } from 'xpand-ui/forms';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import {
	createAppLink,
	deleteAppLink,
	editAppLink,
	editAppLinkNoImage,
	getAppLinks
} from 'store/administration/administration/thunks';
import { Input } from 'xpand-ui/forms';
import { Input as CoreInput } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues, schema } from './yupSchema';
import { addNotification } from 'lib/utils/notifications';
import Adicionar from '../../../../assets/icons/Adicionar.svg';

const MaxFileSize = 5 * 1000 * 1000; // 5Mb
let formData = new FormData();

interface IAppLinks extends AppLinksProps {
	match: Match<{
		path?: string;
		username: string;
	}>;
	goToPage: (path: string) => void;
}
//* COMPONENT
const AppLinks: FC<IAppLinks> = () => {
	const loading = useSelector(state => state.administration.loading);
	const error = useSelector(state => state.administration.error);
	const appLinksData = useSelector(state => state.administration.appLinksData);
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [isAddOpen, setIsAddOpen] = useState(false);
	const [openDeleteAppLinks, setOpenDeleteAppLinks] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [isFileSizeOk, setIsFileSizeOk] = useState<boolean>(true);
	const [infoEdit, setInfoEdit] = useState();
	const [checkFile, setCheckFile] = useState(false);
	const [didChangeFile, setDidChangeFile] = useState(false);
	const [imagePreview, setImagePreview] = useState<string | null>(null);
	const [hasNewImage, setHasNewImage] = useState<boolean>(false);
	const dispatch = useDispatch();

	const {
		handleSubmit,
		control,
		setValue,
		getValues,
		reset,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		defaultValues,
		reValidateMode: 'onChange',
		shouldUnregister: false
	});

	//Gets the necessary data to fill the main table
	useEffect(() => {
		dispatch(getAppLinks());
	}, []);

	//Button to create new App Link
	const pageTitleActions = useMemo(
		() => [
			{
				id: 'newApp',
				type: '',
				onClick: () => {
					reset(defaultValues);
					setIsAddOpen(true);
				},
				icon: <Adicionar />,
				label: 'Add new App'
			}
		],
		[]
	);

	// Columns of the main table
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Title',
				id: 'title',
				width: '50%'
			},
			{
				label: 'Url',
				id: 'url',
				width: '50%'
			}
		],
		[]
	);

	//Actions available for each row
	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'accessControlMenu',
				type: 'menu',
				width: '25%',
				render: () => true,

				options: [
					{
						id: 'edit',
						label: 'Edit',
						onClick: row => {
							setIsEditOpen(true);
							setInfoEdit(row);
						}
					},
					{
						id: 'delete',
						label: 'Delete',
						onClick: row => {
							setOpenDeleteAppLinks(true);
							setInfoEdit(row);
						}
					}
				]
			}
		],
		[appLinksData]
	);

	// Prepare data for the main table
	const tableData = useMemo(
		() =>
			appLinksData?.appLinks.map(e => ({
				id: e.id,
				title: e.title,
				searchKey: e.searchKey,
				url: e.url,
				image: e.image,
				imageName: e.imageName
			})),
		[appLinksData]
	);

	//Actions available in Edit / Create App Links
	const confirmActions = [
		{
			id: 'cancel',
			label: 'Close',
			variant: 'text',
			onClick: () => {
				setIsEditOpen(false);
				setIsAddOpen(false);
			}
		},
		{
			id: 'submit',
			label: 'Submit',
			variant: 'contained',
			onClick: () => {
				handleSubmit(onSubmit)();
				setCheckFile(true);
			}
		}
	];

	//Actions available in delete singles App Link
	const deleteActions = [
		{
			id: 'cancel',
			label: 'Close',
			variant: 'text',
			onClick: () => {
				setOpenDeleteAppLinks(false);
			}
		},
		{
			id: 'confirm',
			label: 'Confirm',
			variant: 'contained',
			onClick: () => {
				dispatch(deleteAppLink(infoEdit?.id));
				setOpenDeleteAppLinks(false);
			}
		}
	];

	//Whenever a file is inserted it adds to the formData and checks if it is a valid file
	//if not it will give a warning
	const changeHandler = event => {
		setDidChangeFile(true);
		formData = new FormData();
		formData.append('image', event.target.files[0]);
		formData.append('imageName', event.target.files[0].name);

		const fileSize: number = event.target.files[0].size;
		const extension = event.target.files[0].type;
		const isSVG = extension == 'image/svg+xml';

		if (!isSVG) {
			addNotification('warning', 'File must be SVG extension.');
		}
		setIsFileSizeOk(fileSize <= MaxFileSize); // 4Mb
		setValue('hasFiles', isFileSizeOk && isSVG ? true : undefined);

		// Update image preview
		const fileURL = URL.createObjectURL(event.target.files[0]);
		setImagePreview(fileURL);
		setHasNewImage(true);
	};

	//Adds the necessary info to the formData and creates/edits a app link
	const onSubmit = e => {
		if (isEditOpen) formData.append('id', infoEdit?.id);
		formData.append('searchKey', e.searchKey);
		formData.append('title', e.title);
		formData.append('url', e.url);

		dispatch(
			isEditOpen
				? formData.get('image')
					? editAppLink(formData)
					: editAppLinkNoImage(formData)
				: createAppLink(formData)
		);

		setIsEditOpen(false);
		setIsAddOpen(false);
	};

	//The user can edit a app link without putting a new image
	useEffect(() => {
		if (!didChangeFile && isEditOpen) {
			setValue('hasFiles', true);
		}
	});

	//Fills the form with the necessary information when editing
	useEffect(() => {
		if (isEditOpen) {
			setValue('searchKey', infoEdit?.searchKey);
			setValue('title', infoEdit?.title);
			setValue('url', infoEdit?.url);
			setImagePreview(infoEdit?.image ? `data:image/svg+xml;base64,${infoEdit.image}` : null);
			setHasNewImage(false);
		}
	}, [isEditOpen]);

	//warns the user when he has no file or wrong format or file to big
	useEffect(() => {
		if (checkFile) {
			if (getValues('hasFiles') == false || getValues('hasFiles') == undefined) {
				addNotification('warning', 'Please select insert a image for the App Link with SVG extension.');
			}
			setCheckFile(false);
		}
	}, [checkFile]);

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle title="" actions={pageTitleActions} />
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="desc"
				handleSearch={{ searchValue, setSearchValue }}
			/>
			<Dialog
				maxWidth="lg"
				title={isEditOpen ? 'Edit App Link' : 'Add App Link'}
				actions={confirmActions}
				modal={{
					open: isEditOpen || isAddOpen,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setIsEditOpen(false);
					},
					content: (
						<>
							<form id="form-add-app-link" onSubmit={handleSubmit(onSubmit)}>
								<Grid
									container
									direction="row"
									alignItems="center"
									justifyContent="space-evenly"
									spacing={3}>
									<Grid item {...sizes[12]}>
										<Input
											name="searchKey"
											label="Search Key"
											minRows={4}
											maxRows={8}
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item {...sizes[12]}>
										<Input
											name="title"
											label="Title"
											minRows={4}
											maxRows={8}
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item {...sizes[12]}>
										<Input name="url" label="URL" control={control} errors={errors} />
									</Grid>
									<Grid item {...sizes[12]}>
										<CoreInput
											onChange={changeHandler}
											name="health_files"
											type="file"
											inputProps={{ accept: 'image/*' }}
										/>
										{!hasNewImage && infoEdit?.image && (
											<Grid item {...sizes[12]}>
												<Typography>{infoEdit.imageName}</Typography>
											</Grid>
										)}
										{imagePreview && (
											<Grid item {...sizes[12]}>
												<img
													src={imagePreview}
													alt="App Link"
													style={{ marginTop: '10px', maxWidth: '100%', maxHeight: '200px' }}
												/>
											</Grid>
										)}
									</Grid>
									{!isFileSizeOk && (
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<InfoField
												label=""
												value="The selected image exceeds the maximum file size"
											/>
										</Grid>
									)}
								</Grid>
							</form>
						</>
					)
				}}
			/>
			<Dialog
				maxWidth="lg"
				title="Delete App Link"
				actions={deleteActions}
				modal={{
					open: openDeleteAppLinks,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenDeleteAppLinks(false);
					},
					content: (
						<>
							<Typography>Are you sure you want to delete this App Link?</Typography>
						</>
					)
				}}
			/>
		</>
	);
};

export default withLayout(AppLinks);
