import { IAccountEmployeeHolidayType } from 'typings/store/personalInfoTypes';

// Check if it is a overlap of date ranges
export function isOverlap(newRange: { start: any; end: any; cellCssClass?: string }, existingRanges: any[]) {
	const normalizeDate = (date: any) => {
		const d = new Date(date);
		d.setHours(0, 0, 0, 0);
		return d.getTime();
	};

	const newStart = normalizeDate(newRange.start);
	const newEnd = normalizeDate(newRange.end);

	return existingRanges
		.filter((range: { approvalStatus: string }) => range.approvalStatus !== 'Holiday')
		.some((range: { start: number; end: number; date: number }) => {
			if (range.start && range.end) {
				const rangeStart = normalizeDate(range.start);
				const rangeEnd = normalizeDate(range.end);
				// Both start and end dates are defined, check for range overlap
				return (
					(newStart >= rangeStart && newStart <= rangeEnd) ||
					(newEnd >= rangeStart && newEnd <= rangeEnd) ||
					(newStart <= rangeStart && newEnd >= rangeEnd)
				);
			} else if (range.date) {
				const rangeDate = normalizeDate(range.date);
				// Single date defined, check if newRange overlaps with this date
				return newStart <= rangeDate && newEnd >= rangeDate;
			}
			return false;
		});
}
// Get Days Of Difference between two dates
export function getDaysOfDifference(firstDate: Date, secondDate: Date): number {
	const millisecondsPerDay = 1000 * 60 * 60 * 24;
	const differenceInDays = Math.round((secondDate.getTime() - firstDate.getTime()) / millisecondsPerDay);
	return differenceInDays;
}

// Check if has Two Days Of Difference
export function hasTwoDaysOfDifference(firstDate: Date, secondDate: Date): boolean {
	const millisecondsPerDay = 1000 * 60 * 60 * 24;
	const differenceInDays = Math.round((secondDate.getTime() - firstDate.getTime()) / millisecondsPerDay);
	return differenceInDays >= 2;
}

// Function to convert hex color to RGBA format with specified opacity
export function hexToRGBA(hex, opacity) {
	hex = hex?.replace('#', '');
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// Function that returns default color for each status
export function statusColor(status: string) {
	return status === 'Approved'
		? '#21BA45'
		: status === 'Rejected'
		? '#D1345E'
		: status === 'Submitted'
		? '#2BAAFF'
		: '#FAC15F';
}

// Convert date format to YYYY-MM-DD
export function convertDateToYYYYMMDD(dateStr: any) {
	const date = new Date(dateStr);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
}

// Convert date format to DD/MM/YYYY
export function convertDateToDDMMYYYY(dateStr: any) {
	const date = new Date(dateStr);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
	const day = String(date.getDate()).padStart(2, '0');
	return `${day}/${month}/${year}`;
}

// Convert date format to DD/MM/YY
export function convertDateToDDMMYY(dateStr: any) {
	const date = new Date(dateStr);
	const year = String(date.getFullYear()).slice(-2);
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
	const day = String(date.getDate()).padStart(2, '0');
	return `${day}/${month}/${year}`;
}

// Check if there is weekend days between two dates
export function checkWeekendDays(startDate: Date, endDate: Date): boolean {
	// Set the time part of the dates to 00:00:00
	const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
	const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

	// Create a copy of the start date to iterate through the date range
	let currentDate = new Date(start);

	while (currentDate <= end) {
		const dayOfWeek = currentDate.getDay();
		if (dayOfWeek === 0 || dayOfWeek === 6) {
			return true; // It's a weekend
		}
		currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
	}

	return false; // No weekend days found
}

// Check if there is holidays between two dates
export function checkHolidays(startDate: Date, endDate: Date, holidays: IAccountEmployeeHolidayType[]): boolean {
	// Set the time part of the dates to 00:00:00
	const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
	const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

	for (const holiday of holidays) {
		const holidayDate = new Date(holiday.holidayDate);
		const holidayOnlyDate = new Date(holidayDate.getFullYear(), holidayDate.getMonth(), holidayDate.getDate());

		if (holidayOnlyDate >= start && holidayOnlyDate <= end) {
			return true; // Holiday found within the range
		}
	}
	return false; // No holidays found within the range
}

// Calculates days and hours off
export const calculateTimeOff = (
	startDate: Date,
	endDate: Date,
	selectedTimeOffType: { id?: any; color: any; title: any; scheduleWeekends: boolean },
	holidays: any
) => {
	let daysOff = 0;
	let hoursOff = 0;

	// If timeoff type is available on weekends
	if (selectedTimeOffType.scheduleWeekends) {
		// By default it is 8 hours per day off
		daysOff = getDaysOfDifference(startDate, endDate) + 1;
		hoursOff = 8 * (getDaysOfDifference(startDate, endDate) + 1);
	} else {
		const totalDays = getDaysOfDifference(startDate, endDate) + 1;
		daysOff = totalDays;
		hoursOff = 8 * totalDays;

		// Count weekends within the date range
		let weekendDays = 0;
		let weekendHolidays = 0;
		let weekdayHolidays = 0;
		let currentDate = new Date(startDate);

		while (currentDate <= endDate) {
			if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
				weekendDays++;
			}
			currentDate.setDate(currentDate.getDate() + 1);
		}

		for (const holiday of holidays) {
			const holidayDate = new Date(holiday.holidayDate);
			const holidayOnlyDate = new Date(holidayDate.getFullYear(), holidayDate.getMonth(), holidayDate.getDate());
			if (holidayOnlyDate >= startDate && holidayOnlyDate <= endDate) {
				if (holidayOnlyDate.getDay() === 6 || holidayOnlyDate.getDay() === 0) {
					weekendHolidays++;
				} else {
					weekdayHolidays++;
				}
			}
		}

		// Adjust daysOff and hoursOff based on weekends and holidays
		daysOff -= weekendDays;
		hoursOff -= weekendDays * 8;

		// Subtract weekday holidays
		daysOff -= weekdayHolidays;
		hoursOff -= weekdayHolidays * 8;
	}

	return { daysOff, hoursOff };
};

export const splitDateRangesByWeekendAndHoliday = (
	startDate: Date,
	endDate: Date,
	selectedTimeOffType: { id?: any; color: any; title: any; scheduleWeekends: boolean },
	holidays: any,
	hoursOffParam: number
) => {
	const splitRanges = [];

	if (selectedTimeOffType.scheduleWeekends) {
		const { daysOff, hoursOff } = calculateTimeOff(startDate, endDate, selectedTimeOffType, holidays);
		splitRanges.push({
			startDate: new Date(startDate),
			endDate: new Date(endDate),
			daysOff: daysOff,
			hoursOff: hoursOffParam == 4 ? hoursOffParam : hoursOff // if it is half-day, it will be always 4
		});
	} else {
		let currentRangeStart = new Date(startDate);
		let currentRangeEnd = new Date(startDate);
		let currentDate = new Date(startDate);

		while (currentDate <= endDate) {
			const isWeekend = currentDate.getDay() === 6 || currentDate.getDay() === 0;
			const isHoliday = holidays.some((holiday: { holidayDate: string }) => {
				const holidayDate = new Date(holiday.holidayDate);
				const holidayOnlyDate = new Date(
					holidayDate.getFullYear(),
					holidayDate.getMonth(),
					holidayDate.getDate()
				);
				return holidayOnlyDate.getTime() === currentDate.getTime();
			});

			if (isWeekend || isHoliday) {
				if (currentRangeStart <= currentRangeEnd) {
					const { daysOff, hoursOff } = calculateTimeOff(
						currentRangeStart,
						currentRangeEnd,
						selectedTimeOffType,
						holidays
					);
					splitRanges.push({
						startDate: new Date(currentRangeStart),
						endDate: new Date(currentRangeEnd),
						daysOff: daysOff,
						hoursOff: hoursOffParam == 4 ? hoursOffParam : hoursOff // if it is half-day, it will be always 4
					});
				}
				// Move to the next valid day
				do {
					currentDate.setDate(currentDate.getDate() + 1);
				} while (
					currentDate <= endDate &&
					(currentDate.getDay() === 6 ||
						currentDate.getDay() === 0 ||
						holidays.some((holiday: { holidayDate: string }) => {
							const holidayDate = new Date(holiday.holidayDate);
							const holidayOnlyDate = new Date(
								holidayDate.getFullYear(),
								holidayDate.getMonth(),
								holidayDate.getDate()
							);
							return holidayOnlyDate.getTime() === currentDate.getTime();
						}))
				);

				currentRangeStart = new Date(currentDate);
				currentRangeEnd = new Date(currentDate);
			} else {
				currentRangeEnd = new Date(currentDate);
				currentDate.setDate(currentDate.getDate() + 1);
			}
		}

		// Push the last range if it wasn't ended by a weekend or holiday
		if (currentRangeStart <= currentRangeEnd && currentRangeStart <= endDate) {
			const { daysOff, hoursOff } = calculateTimeOff(
				currentRangeStart,
				currentRangeEnd,
				selectedTimeOffType,
				holidays
			);
			splitRanges.push({
				startDate: new Date(currentRangeStart),
				endDate: new Date(currentRangeEnd),
				daysOff: daysOff,
				hoursOff: hoursOffParam == 4 ? hoursOffParam : hoursOff // if it is half-day, it will be always 4
			});
		}
	}

	return splitRanges;
};

// Check if date is already a TimeoffRequest
export const dateIsTimeoffRequest = (date: Date, timeoffRequests: any) => {
	const normalizedDate = new Date(new Date(date).setHours(0, 0, 0, 0));

	for (const timeoffRequest of timeoffRequests) {
		if (timeoffRequest?.date) {
			const timeoffRequestDate = new Date(new Date(timeoffRequest?.date).setHours(0, 0, 0, 0));
			if (
				timeoffRequest?.approvalStatus !== 'Holiday' &&
				normalizedDate.getTime() == timeoffRequestDate.getTime()
			) {
				return true;
			}
		} else {
			const timeoffRequestStartDate = new Date(new Date(timeoffRequest?.start).setHours(0, 0, 0, 0));
			const timeoffRequestEndDate = new Date(new Date(timeoffRequest?.end).setHours(0, 0, 0, 0));
			if (normalizedDate >= timeoffRequestStartDate && normalizedDate <= timeoffRequestEndDate) {
				return true;
			}
		}
	}
	return false;
};

// Scrolls To Card by Date
export const scrollToCard = (date: Date, cards: any, scrollableElement: any, pageContentElement: any) => {
	const normalizedDate = new Date(new Date(date).setHours(0, 0, 0, 0));

	if (cards) {
		let targetCard = null;
		let numberOfCards = 0;
		let cardIPosition = 0;
		for (const card of cards) {
			card.style.backgroundColor = '#FFFFFF';
			const strongElements = card.querySelectorAll('strong');
			if (strongElements.length > 0) {
				numberOfCards++;
			}
		}

		for (const card of cards) {
			const strongElements = card.querySelectorAll('strong');

			if (strongElements.length > 0) {
				cardIPosition++;
				let cardStartDateStr = strongElements[0].innerText.split('/');
				const timeoffRequestStartDate = new Date(
					2000 + Number(cardStartDateStr[2]),
					Number(cardStartDateStr[1]) - 1,
					cardStartDateStr[0],
					0,
					0,
					0,
					0
				);

				if (strongElements[1]) {
					let cardEndDateStr = strongElements[1].innerText.split('/');
					const timeoffRequestEndDate = new Date(
						2000 + Number(cardEndDateStr[2]),
						Number(cardEndDateStr[1]) - 1,
						cardEndDateStr[0],
						0,
						0,
						0,
						0
					);

					if (normalizedDate >= timeoffRequestStartDate && normalizedDate <= timeoffRequestEndDate) {
						targetCard = card;
						break;
					}
				} else {
					if (normalizedDate.getTime() === timeoffRequestStartDate.getTime()) {
						targetCard = card;
						break;
					}
				}
			}
		}

		if (targetCard && scrollableElement && pageContentElement) {
			// Change the background color of the targetCard
			// Scroll to the top of the page content element
			scrollableElement.scrollTo({
				top: 0,
				behavior: 'instant'
			});
			pageContentElement.scrollTo({
				top: 0,
				behavior: 'instant'
			});

			// Get the position of the target card relative to the scrollable element
			const cardPosition = targetCard.offsetTop;

			// Get the height of the page content element
			const pageContentHeight = pageContentElement.clientHeight;

			const minOffset = 250;
			const maxOffset = 750;
			const minHeight = 600;
			const maxHeight = 1200;

			// Normalize the offset based on the page content height
			const adjustedOffset =
				minOffset + ((maxOffset - minOffset) * (pageContentHeight - minHeight)) / (maxHeight - minHeight);

			setTimeout(() => {
				// Scroll to the specific position on the scrollable element
				targetCard.style.backgroundColor = '#E9EAEB';

				pageContentElement.scrollTo({
					top: pageContentHeight * ((cardIPosition - 1) / numberOfCards),
					behavior: 'smooth'
				});
				// Adjust the scroll position by the calculated offset
				scrollableElement.scrollTo({
					top: cardPosition - adjustedOffset,
					behavior: 'smooth'
				});
			}, 500);
		}
	}
};
