import withLayout from 'lib/hocs/withLayout';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog, PageTitle } from 'xpand-ui/core';
import { JobExecutionsProps } from '.';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { Match } from 'typings/store/generalTypes';
import { Grid, Typography } from '@mui/material';
import { sizes } from 'xpand-ui/utils/handlers';
import { InfoField, Select, Checkbox } from 'xpand-ui/forms';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValues, schema } from './yupSchema';
import { getValue } from '@mui/system';
import { addNotification } from 'lib/utils/notifications';

interface IJobExecutions extends JobExecutionsProps {
	match: Match<{
		path?: string;
		username: string;
	}>;
	goToPage: (path: string) => void;
}
//* COMPONENT
const JobExecution: FC<IJobExecutions> = ({
	administration,
	filters,
	getJobExecutions,
	deleteJobExecution,
	deleteAllUnfinishedJobExecutions,
	executeJob,
	getAllJobs
}) => {
	const { loading, error, jobExecutionsData, jobsList } = administration;
	const {} = filters;
	const [viewDetails, setViewDetails] = useState(false);
	const [openDeleteJobExecution, setOpenDeleteJobExecution] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [jobToShowDetails, setJobToShowDetails] = useState();
	const [openDeleteUnfishedJobExecutions, setOpenDeleteUnfinishedJobExecutions] = useState(false);
	const [openExecuteJobModal, setOpenExecuteJobModal] = useState(false);
	const [executeWithError, setExecuteWithError] = useState(false);
	const [jobToExecute, setJobToExecute] = useState('');

	const {
		handleSubmit,
		control,
		reset,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	//Gets the necessary data to fill the main table
	useEffect(() => {
		getJobExecutions();
	}, []);

	//Button that deletes all unfinished job executions
	const pageTitleActions = useMemo(
		() => [
			{
				id: 'uploadFileTemplate',
				type: 'file',
				onClick: () => {
					setOpenDeleteUnfinishedJobExecutions(true);
				},
				icon: <DeleteIcon />,
				label: 'Delete all unfinished executions'
			},
			{
				id: 'executeJob',
				type: 'file',
				onClick: () => {
					getAllJobs();
					setOpenExecuteJobModal(true);
				},
				icon: <PlayArrowIcon />,
				label: 'Execute job'
			}
		],
		[]
	);
	// Columns of the main table
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Name',
				id: 'name',
				width: '25%'
			},
			{
				label: 'Start Date',
				id: 'startDate',
				width: '25%',
				format: (row, text: string) => (text == '0000-00-00 00:00:00' ? ' ' : text)
			},
			{
				label: 'End Date',
				id: 'endDate',
				width: '25%',
				format: (row, text: string) => (text == '0000-00-00 00:00:00' ? ' ' : text)
			},
			{
				label: 'Result',
				id: 'result',
				width: '25%',
				format: (row, text: string) => (
					<Typography style={{ color: row.result == 'ERROR' ? 'red' : 'green', fontWeight: 'bold' }}>
						{text}
					</Typography>
				)
			}
		],
		[]
	);

	//Actions available for each row
	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'accessControlMenu',
				type: 'menu',
				width: '25%',
				render: () => true,

				options: [
					{
						id: 'viewDetails',
						label: 'View Details',
						onClick: row => {
							setViewDetails(true);
							setJobToShowDetails(row);
						},
						//Since Job Execution without error dont have message and throwable filled, its not necessary to have this button enabled
						disabled: row => row.result == 'SUCCESS' || row.result == ''
					},
					{
						id: 'delete',
						label: 'Delete',
						onClick: row => {
							setOpenDeleteJobExecution(true);
							setJobToShowDetails(row);
						}
					}
				]
			}
		],
		[jobExecutionsData]
	);

	// Prepare data for the main table
	const tableData = useMemo(
		() =>
			jobExecutionsData?.jobsExecutions.map(e => ({
				id: e.id,
				name: e.name,
				startDate: e.startDate == undefined || e.startDate == null ? '0000-00-00 00:00:00' : e.startDate,
				endDate: e.endDate == undefined || e.endDate == null ? '0000-00-00 00:00:00' : e.endDate,
				result: e.result == undefined || e.endDate == null ? ' ' : e.result,
				message: e.message,
				throwable: e.throwable
			})),
		[jobExecutionsData]
	);

	// Prepare data for the main table
	const selectData = useMemo(
		() =>
			jobsList?.jobs.map(e => ({
				id: e.jobKey,
				label: e.jobName
			})),
		[jobsList]
	);

	//Actions available in the View Details popup
	const confirmActions = [
		{
			id: 'cancel',
			label: 'Close',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setViewDetails(false);
			}
		}
	];

	//Actions available in delete singles job execution modal
	const deleteActions = [
		{
			id: 'cancel',
			label: 'Close',
			variant: 'text',
			onClick: () => {
				setOpenDeleteJobExecution(false);
			}
		},
		{
			id: 'confirm',
			label: 'Confirm',
			variant: 'contained',
			onClick: () => {
				deleteJobExecution(jobToShowDetails?.id);
				setOpenDeleteJobExecution(false);
			}
		}
	];

	//Actions available in delete all unfinished job executions modal
	const deleteUnfinishedActions = [
		{
			id: 'cancel',
			label: 'Close',
			color: 'primary',
			variant: 'text',
			onClick: () => {
				setOpenDeleteUnfinishedJobExecutions(false);
			}
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				deleteAllUnfinishedJobExecutions();
				setOpenDeleteUnfinishedJobExecutions(false);
			}
		}
	];

	//Actions available in delete all unfinished job executions modal
	const executeJobActions = [
		{
			id: 'cancel',
			label: 'Close',
			color: 'primary',
			variant: 'text',
			onClick: () => {
				setOpenExecuteJobModal(false);
			}
		},
		{
			id: 'submit',
			label: 'Execute',
			onClick: () => {
				if (watch('jobToExecute') == undefined || watch('jobToExecute') == '') {
					addNotification('danger', 'Please select a job to execute.');
				}
			},
			type: 'submit',
			form: 'form-feedback',
			variant: 'contained',
			disabled: false
		}
	];

	const onExecute = (payload: unknown) => {
		executeJob(watch('jobToExecute'), watch('withError'));
		setOpenExecuteJobModal(false);
		reset(defaultValues);
		getJobExecutions();
	};

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle title="" actions={pageTitleActions} />
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="desc"
				handleSearch={{ searchValue, setSearchValue }}
			/>
			<Dialog
				maxWidth="lg"
				title="View Details"
				actions={confirmActions}
				modal={{
					open: viewDetails,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setViewDetails(false);
					},
					content: (
						<>
							<Grid
								container
								direction="row"
								alignItems="center"
								justifyContent="space-evenly"
								spacing={3}>
								<Grid item {...sizes[12]}>
									<InfoField label="Message" value={jobToShowDetails?.message || ''} />
								</Grid>
								<Grid item {...sizes[12]}>
									<InfoField label="Throwable" value={jobToShowDetails?.throwable || ''} />
								</Grid>
							</Grid>
						</>
					)
				}}
			/>
			<Dialog
				maxWidth="lg"
				title="Delete Job Execution"
				actions={deleteActions}
				modal={{
					open: openDeleteJobExecution,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenDeleteJobExecution(false);
					},
					content: (
						<>
							<Typography>Are you sure you want to delete this Job Execution?</Typography>
						</>
					)
				}}
			/>
			<Dialog
				maxWidth="lg"
				title="Delete Unfinished Job Executions"
				actions={deleteUnfinishedActions}
				modal={{
					open: openDeleteUnfishedJobExecutions,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenDeleteUnfinishedJobExecutions(false);
					},
					content: (
						<>
							<Typography>Are you sure you want to delete all unfinished Job Executions?</Typography>
						</>
					)
				}}
			/>
			<Dialog
				maxWidth="md"
				title="Execute job"
				actions={executeJobActions}
				modal={{
					open: openExecuteJobModal,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenExecuteJobModal(false);
					},
					content: (
						<>
							<form id="form-feedback" onSubmit={handleSubmit(onExecute)}>
								<Select
									required
									label="Job"
									name="jobToExecute"
									options={selectData}
									additionalOnChange={e => {}}
									control={control}
									error={error}
								/>
								<Checkbox
									name="withError"
									label="Execute Job With Error"
									control={control}
									error={error}
								/>
							</form>
						</>
					)
				}}
			/>
		</>
	);
};

export default withLayout(JobExecution);
