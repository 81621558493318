import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	leftCardSection: {
		textAlign: 'left',
		marginLeft: '15px'
	},
	iconGrayed: {
		color: theme.inactiveText
	},
	phoneLine: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingBottom: '15px'
	},
	phoneLineAction: {
		margin: '0 -10px 0 -10px',
		padding: '15px',
		color: theme.palette.secondary.main
	},
	phoneLineActionDelete: {
		margin: '0 0 0 -10px',
		padding: '10px',
		alignSelf: 'flex-start'
	},
	submitButton: {}
}));

export { useStyles };
