// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			display: 'flex',
			padding: '50px 175px',
			minWidth: '100%',
			alignItems: 'center',
			flexDirection: 'column',
			margin: 'auto'
		},
		statusBar: {
			// direction: 'row',
			justify: 'center',
			alignItems: 'center',
			display: 'flex'
		},
		statusIcon: {
			marginRight: '10px'
		},
		centerInParent: {
			margin: 'auto',
			display: 'block'
		}
	})
);

export { useStyles };
