import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getReleaseNotesEmail, sendPostReleaseNotesEmail } from 'store/administration/administration/thunks';
import { setClearReleaseNotesEmail } from 'store/administration/administration/actions';
import { AppState } from 'typings/state';

import SendReleaseNotesEmail from './SendReleaseNotesEmail';

const mapStateToProps = ({ administration }: AppState) => ({ administration });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getReleaseNotesEmail, sendPostReleaseNotesEmail, setClearReleaseNotesEmail }, dispatch);

export type SendReleaseNoteEmailProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SendReleaseNotesEmail);
