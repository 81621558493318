import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	expandedEventDetails: {
		marginTop: '20px'
	},
	expandedEventLine: {
		height: '5px',
		width: '100%',
		position: 'absolute',
		top: '0',
		left: '0'
	}
}));

export { useStyles };
