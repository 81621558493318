import * as yup from 'yup';
import moment from 'moment';

import { stringRequired, string, numberRequired, dateRequired, booleanRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	timeOffType: stringRequired,
	daysOff: numberRequired,
	hoursOff: numberRequired.typeError('You can only insert the value 4 or 8'),
	description: string.max(2000)
});

export const defaultValues = {
	timeOffType: 'ACDEAC2C-8C30-42B4-826C-2ACFBC97BA66',
	daysOff: '0',
	hoursOff: '0',
	description: ''
};
