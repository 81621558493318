/* eslint-disable @typescript-eslint/no-explicit-any */
export const GET = 1;
export const POST = 2;
export const PUT = 3;
export const DELETE = 4;
export const EXPORTFILE = 5;
export const EXPORTFILEPOST = 6;

export type OriginalResponse = {
	code: number;
	data?: any;
	payload: Success;
	message: string;
	response?: {
		status: number;
		statusText: string;
	};
};

export type Success = {
	message?: string;
	data?: any;
};
