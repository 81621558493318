import * as yup from 'yup';

import { stringRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	rejectionReason: stringRequired.max(512, 'This field exceeds the maximum limit (512)')
});

export const defaultValues = {
	rejectionReason: ''
};
