import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getHealthLocations,
	sendDeleteUser,
	sendPostImpersonateEmail,
	submitHealthConvocation
} from 'store/administration/users/thunks';
import { setNewNotification } from 'store/notifications/thunks';
import { getHealthConvocationMessage, getUserProfile } from 'store/personalInfo/thunks';
import { clearCache } from 'store/system/thunks';
import { AppState } from 'typings/state';

import AdminActions from './AdminActions';

const mapStateToProps = ({ users }: AppState) => ({ users });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			sendDeleteUser,
			getUserProfile,
			clearCache,
			sendPostImpersonateEmail
		},
		dispatch
	);

export type AdminActionsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AdminActions);
