import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, PUT, POST } from 'lib/handlers/requestTypes';

export const fetchUserNotifications = async (userId: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/user/${userId}/notifications`);

export const fetchUserNotificationsTimesheets = async (userId: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/user/${userId}/notifications/timesheets`);

export const fetchUserNotificationsApprovals = async (userId: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/user/${userId}/notifications/approvals`);

export const setMarkNotificationAsRead = async (
	userId: string,
	payload: unknown
): Promise<OriginalResponse | Success> =>
	restCall(PUT, `/user/${userId}/notifications/markAsRead`, payload, '', false, true);

export const setMarkNotificationAsReadProposals = async (
	proposalId: string,
	payload: unknown
): Promise<OriginalResponse | Success> =>
	restCall(PUT, `/admin/proposal/${proposalId}/notifications/markAsRead`, payload, '', false, true);

export const setCreateNotification = async (userId: string, payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, `/user/${userId}/notifications/create`, payload, '', false, true);

export const setCreateNotificationProposals = async (
	proposalId: string,
	payload: unknown
): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/proposal/${proposalId}/notifications/create`, payload, '', false, true);

export const deleteAllUserNotifications = async (username: string): Promise<OriginalResponse | Success> =>
	restCall(POST, `/user/${username}/notifications/delete`);
