import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import {
	EmployeeSalaryActions as Actions,
	IGetCSCList,
	IGetCSCHistory,
	IGetNewCSC,
	IGetEditCSC,
	IPostPutCSC,
	IGetCSCHistoryDetails,
	ICscAuditLog,
	IPostRenewContract,
	IRenewContract,
	IContractsList
} from 'typings/store/admin/employeeSalaryCategories';
import { IIrsValuePost } from 'typings/store/admin/proposals';
import { OriginalResponse } from 'lib/handlers/requestTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);

export const setEmployeeSalaryCategoriesError = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.SET_ERROR, payload);

//! GET INFO TABLES
// GET CSC FOR ALL USERS
export const setEmployeesList = (payload: IGetCSCList | null): IDispatchType =>
	action(Actions.GET_EMPLOYEES_LIST, payload);

// EXPORT MULTIPLE CSCs
export const setExportMultipleCSCs = (payload: unknown): IDispatchType =>
	action(Actions.GET_EXPORT_MULTIPLE_CSCS, payload);

// GET CSC FOR SPECIFIC USER AND ITS HISTORY
export const setEmployeeSalaryCategoriesList = (payload: IGetCSCHistory | null, hash: string | null): IDispatchType =>
	action(Actions.GET_EMPLOYEE_SALARY_CATEGORIES_LIST, { payload, hash });

//! CSC ACTIONS
// CLOSE CSC
export const setEmployeeSalaryCategoryClose = (payload: [] | null, hash: string | null): IDispatchType =>
	action(Actions.SET_EMPLOYEE_SALARY_CATEGORY_CLOSE, { payload, hash });

// CANCEL CSC
export const setEmployeeSalaryCategoryCancel = (payload: [] | null, hash: string | null): IDispatchType =>
	action(Actions.SET_EMPLOYEE_SALARY_CATEGORY_CANCEL, { payload, hash });

// REQUEST APPROVAL CSC
export const setEmployeeSalaryCategoryRequestApproval = (payload: [] | null, hash: string | null): IDispatchType =>
	action(Actions.SET_EMPLOYEE_SALARY_CATEGORY_REQUEST_APPROVAL, { payload, hash });

// APPROVE CSC
export const setEmployeeSalaryCategoryApprove = (
	payload: OriginalResponse | null,
	hash: string | null
): IDispatchType => action(Actions.SET_EMPLOYEE_SALARY_CATEGORY_APPROVE, { payload, hash });

// REJECT CSC
export const setEmployeeSalaryCategoryReject = (payload: string | null, hash: string | null): IDispatchType =>
	action(Actions.SET_EMPLOYEE_SALARY_CATEGORY_REJECT, { payload, hash });

//! FORM GETS and POSTS
// NEW CSC FORM - GET
export const setNewEmployeeSalary = (payload: IGetNewCSC | null): IDispatchType =>
	action(Actions.GET_NEW_EMPLOYEE_SALARY, payload);
// EDIT CSC FORM - GET
export const setEditEmployeeSalary = (payload: IGetEditCSC | null): IDispatchType =>
	action(Actions.GET_EDIT_EMPLOYEE_SALARY, payload);

// NEW CSC FORM - POST
export const setPostNewEmployeeSalary = (payload: IPostPutCSC | null): IDispatchType =>
	action(Actions.SUBMIT_NEW_EMPLOYEE_SALARY, payload);
// EDIT CSC FORM - PUT
export const setPutUpdateEmployeeSalary = (payload: IPostPutCSC | null): IDispatchType =>
	action(Actions.SUBMIT_PUT_UPDATE_EMPLOYEE_SALARY, payload);
//! HELPERS
// GET IRS VALUES
export const setIrsValue = (payload: IIrsValuePost | null): IDispatchType => action(Actions.SET_IRS_VALUE, payload);

// GET CSC HISTORY DETAILS FOR MODAL
export const setCscHistory = (payload: IGetCSCHistoryDetails | null): IDispatchType =>
	action(Actions.SET_CSC_HISTORY_DETAILS, payload);

// RESET REDUCER
export const resetFormData = (): IDispatchType => action(Actions.RESET_FORM_DATA);

// AUDIT LOG

export const setCscAuditLog = (payload: ICscAuditLog | null): IDispatchType =>
	action(Actions.GET_CSC_AUDIT_LOG, payload);

// EXCEL CSC HISTORY EXPORT
export const setCscHistoryExportExcel = (): IDispatchType => action(Actions.SET_CSC_HISTORY_EXPORT_EXCEL);

// GET USERS LIST
export const setContractsList = (payload: IContractsList): IDispatchType => action(Actions.GET_CONTRACTS_LIST, payload);

// DOWNLOAD DOCUMENT
export const setDownloadDocument = (username: string | null, payload: unknown | null): IDispatchType =>
	action(Actions.GET_DOWNLOAD_DOCUMENT, username, payload);

// GET RENEW CONTRACT
export const setRenewContract = (payload: IRenewContract): IDispatchType => action(Actions.GET_RENEW_CONTRACT, payload);

// GET RENEW CONTRACT
export const setPostRenewContract = (payload: IPostRenewContract): IDispatchType =>
	action(Actions.POST_RENEW_CONTRACT, payload);
