/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect, useState } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select } from 'xpand-ui/forms';

//* TYPINGS
import { HelperProps } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

//* LOCAL COMPONENT IMPORTS
import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';

//* COMPONENT INTERFACES
interface IProjectTeam extends IProjectTabs {
	setNewProjectHelper: React.Dispatch<React.SetStateAction<HelperProps>>;
	projectRelatedOptions: any;
}

// Extra Label to be printed below the team field
let extraCompaniesPrint = '';
//* COMPONENT
const ProjectTeam: FC<IProjectTeam> = ({
	control,
	errors,
	watch,
	setValue,
	setNewProjectHelper,
	selectedCompanyLabel,
	projectRelatedOptions
}) => {
	const classes = useStyles();

	const selectedUsers = watch('team');
	const [hasExtraCompanies, setHasExtraCompanies] = useState(false);

	let extraCompanies: string[] = [];

	// Filter the list of users and map it to get the "extraCompanies" of the user's that belong to a
	// different company than the one selected for the project

	projectRelatedOptions.users
		.filter((item: { id: string }) => selectedUsers.includes(item.id))
		.map((filteredItem: { company: string }) => {
			if (filteredItem.company !== selectedCompanyLabel && !extraCompanies.includes(filteredItem.company)) {
				extraCompanies.push(filteredItem.company);
			}
			return filteredItem.company;
		});

	// Set the warning and populate the form fields accordingly
	useEffect(() => {
		for (let entry of extraCompanies) {
			if (entry !== selectedCompanyLabel) {
				setHasExtraCompanies(true);
			}
		}
		setValue('teamCompanies', extraCompanies);
		extraCompaniesPrint = extraCompanies.join(', ');
		if (extraCompanies.length === 0) {
			setHasExtraCompanies(false);
		}
	}, [watch('team')]);

	return (
		<Grid container spacing={4} className={classes.root}>
			{/* **************** Project Team ****************** */}
			<Grid item {...sizes[6]}>
				<Select
					name="teamLead"
					label="Team Lead"
					options={projectRelatedOptions.users}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							teamLead: item.value
						}));
					}}
					helperText="Used for timesheet approval purposes"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="projectManager"
					label="Project Manager"
					options={projectRelatedOptions.users}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							projectManager: item.value
						}));
					}}
					helperText="Project Manager of this project"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="accountManager"
					label="Account Manager"
					options={projectRelatedOptions.users}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							accountManager: item.value
						}));
					}}
					helperText="Account Manager of this project"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="timesheetApproval"
					label="TIMESHEET APPROVAL"
					options={projectRelatedOptions.timesheetApprovalTypes}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							timesheetApproval: item.value
						}));
					}}
					helperText="Who will approve the timesheets for this project"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="expenseApproval"
					label="EXPENSE APPROVAL"
					options={projectRelatedOptions.users}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							expenseApproval: item.value
						}));
					}}
					helperText="Who will approve the expenses of this project"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="team"
					label="Team"
					multiple
					options={projectRelatedOptions.users}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							team: item.value
						}));
					}}
					helperText="The people that will work on this project and will have access to its applications"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}></Grid>
			<Grid item {...sizes[6]}>
				{hasExtraCompanies && (
					<Typography className={classes.stateTitle}>
						<span style={{ alignContent: 'center', backgroundColor: '#FFF8E5' }}>
							<PriorityHighIcon color="warning" style={{ verticalAlign: 'middle' }} />
							<span style={{ paddingTop: '2px' }}>
								The project has to be created in the following companies: {extraCompaniesPrint}
							</span>
						</span>
					</Typography>
				)}
			</Grid>
		</Grid>
	);
};

export default ProjectTeam;
