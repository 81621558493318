//* EXTERNAL LIBS
import React, { FC, useEffect, useMemo, useState } from 'react';

//* EXTERNAL LIBS --> MUI

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { IUserResignationList } from 'typings/store/admin/admissions';

//* PROJECT IMPORTS [LIB / PAGES ]
import { START_API_PATH } from 'lib/utils/constants';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { ResignationMngtProps } from '.';
import { useStyles } from './styles';

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Resignation Management - XPECP.png';

//* COMPONENT INTERFACES
interface IResignationMngt extends ResignationMngtProps {
	goToPage: (path: string) => void;
}

//* COMPONENT
const ResignationMngt: FC<IResignationMngt> = ({
	admissions,
	filters,
	goToPage,
	getResignationList,
	clearAdmissionsError,
	exportCertificate,
	exportReceipt,
	setResignationManagementPageFilter
}) => {
	const classes = useStyles();
	const { loading, resignationList, error } = admissions;
	const { resignationManagementFilter } = filters;

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(resignationManagementFilter);

	// Update filters whenever necessary
	useEffect(() => {
		setResignationManagementPageFilter(searchValue);
	}, [searchValue]);

	useEffect(() => {
		if (!error && !resignationList) getResignationList();
	}, [resignationList]);

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	const buttonExportCertificate = (username: string) => {
		exportCertificate(username);
	};

	const buttonExportReceipt = (username: string) => {
		exportReceipt(username);
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<IUserResignationList>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				isSortable: false,
				format: ({ username }) => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${username}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{ label: 'User', id: 'username', accentColumn: true },
			{ label: 'Name', id: 'name', width: '17%', maxWidth: '17%', accentColumn: true },
			{ label: 'Company', id: 'company' },
			{ label: 'Business Unit', id: 'businessUnit', maxWidth: '10%' },
			{
				label: 'Contract Start Date',
				type: 'date',
				id: 'contractStartDate',
				format: ({ contractStartDate }) => (contractStartDate ? parseDateToShow(contractStartDate) : '')
			},
			{ label: 'Status', id: 'status' }
		],
		[]
	);

	const tableActions: ITableAction<IUserResignationList>[] = useMemo(
		() => [
			{
				id: 'someMenu',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'resign',
						label: 'Resign Employee',
						render: ({ status }) => status === 'Active',
						to: row => `/cp/admin/admissions/resignations/resign/${row.id}`,
						onClick: row => goToPage(`/admin/admissions/resignations/resign/${row.id}`)
					},
					{
						id: 'deactivate',
						label: 'Deactivate Employee',
						render: ({ status }) => status === 'Resigned',
						to: row => `/cp/admin/admissions/resignations/deactivate/${row.id}`,
						onClick: row => goToPage(`/admin/admissions/resignations/deactivate/${row.id}`)
					},
					{
						id: 'profilePage',
						label: 'Employee Profile',
						render: ({ status }) => status === 'Active' || status === 'Resigned',
						to: row => `/cp/admin/employees/profile/resignation/${row.username}`,
						onClick: row => goToPage(`/admin/employees/profile/resignation/${row.username}`)
					},
					{
						id: 'generateWorkCertificate',
						label: 'Generate Work Certificate',
						render: ({ status }) => status === 'Resigned',
						onClick: row => buttonExportCertificate(row.username)
					},
					{
						id: 'generateResignReceipt',
						label: 'Generate Resign Receipt',
						render: ({ status }) => status === 'Resigned',
						onClick: row => buttonExportReceipt(row.username)
					}
				]
			}
		],
		[resignationList]
	);

	const tableData: IUserResignationList[] | null = useMemo(
		() =>
			resignationList &&
			resignationList.users.map(e => ({
				...e,
				username: e.username || '',
				name: e.name || '',
				company: (e.summaryData && JSON.parse(e.summaryData)?.company) || '',
				businessUnit: (e.summaryData && JSON.parse(e.summaryData)?.businessUnit) || '',
				contractStartDate: (e.summaryData && JSON.parse(e.summaryData)?.contractStartDate) || '',
				contractStartDateFormated:
					(e.summaryData && parseDateToShow(JSON.parse(e.summaryData)?.contractStartDate)) || '',
				status:
					(e.status === 1 && 'Active') ||
					(e.status === 2 && 'Resigned') ||
					(e.status === 3 && 'Deactivated') ||
					'NA'
			})),
		[resignationList]
	);

	if (error) return handleErrorPage(error, clearAdmissionsError);

	const isLoading = resignationList === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			{tableData && (
				<Table
					tableData={tableData}
					columns={tableColumns}
					actions={tableActions}
					defaultSortColumn="desc"
					handleSearch={{ searchValue, setSearchValue }}
					handleDiagramModal={{ diagramModal, setDiagramModal }}
				/>
			)}

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(ResignationMngt);
