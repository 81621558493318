import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	deleteAllUnfinishedJobExecutions,
	deleteJobExecution,
	getJobExecutions,
	executeJob,
	getAllJobs
} from 'store/administration/administration/thunks';
import { AppState } from 'typings/state';

import JobExecutions from './JobExecutions';

const mapStateToProps = ({ administration, filters }: AppState) => ({ administration, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getJobExecutions,
			deleteJobExecution,
			deleteAllUnfinishedJobExecutions,
			executeJob,
			getAllJobs
		},
		dispatch
	);

export type JobExecutionsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(JobExecutions);
