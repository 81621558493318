import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import {
	// new
	getEditEmployee,
	getEditCollaborationType,
	submitNewCollaborationType,
	submitEditCollaborationType
} from 'store/administration/collaborationTypesManagement/thunks';

import {
	// HELPERS %%%%%%%%%%%%%%%%
	// calculate annual gross
	getProposalAnnualGrossValue,
	// calculate  irs
	getIrsValue
} from 'store/administration/proposals/thunks';

import EditCollaborationType from './EditCollaborationType';
import { resetEmployee } from 'store/administration/collaborationTypesManagement/actions';

const mapStateToProps = ({ proposals, collaborationTypesManagement, company }: AppState) => ({
	proposals,
	collaborationTypesManagement,
	company
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			// new
			getEditEmployee,
			getEditCollaborationType,
			submitNewCollaborationType,
			submitEditCollaborationType,
			// HELPERS %%%%%%%%%%%%%%%%
			// calculate annual gross
			getProposalAnnualGrossValue,
			// calculate  irs
			getIrsValue,
			resetEmployee
		},
		dispatch
	);

export type EditCollaborationTypeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(EditCollaborationType);
