/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useState, useMemo, FC, useEffect } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Input, Select } from 'xpand-ui/forms';
import { parseDateToManageSaf } from 'xpand-ui/utils/dates';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';

import { addNotification } from 'lib/utils/notifications';
import { ICompanyInfo, IProposalFormCareerHelper } from 'typings/store/admin/proposals';
import { IChoosableBaseInfo, ICareerProfiles } from 'typings/store/generalTypes';

export const OwnershipRadioOptions = [
	{ id: 'PT', label: 'PT' },
	{ id: 'UK', label: 'UK' }
];

export const TemplateOptions = [
	{ id: 'Xpagile FTL', label: 'Xpagile FTL' },
	{ id: 'Xpagile FTTM', label: 'Xpagile FTTM' }
];

export const ApplicationOptions = [
	{ id: 'ERP', label: 'ERP' },
	{ id: 'JIRA', label: 'JIRA' },
	{ id: 'Confluence', label: 'Confluence' },
	{ id: 'Timesheets', label: 'Timesheets' },
	{ id: 'Git', label: 'Git' },
	{ id: 'Bamboo', label: 'Bamboo' },
	{ id: 'Azure DevOps', label: 'Azure DevOps' },
	{ id: 'Distribution List', label: 'Distribution List' }
];

export const ApplicationOptionsBalwurk = [
	{ id: 'ERP', label: 'ERP' },
	{ id: 'JIRA', label: 'JIRA' },
	{ id: 'Timesheets', label: 'Timesheets' },
	{ id: 'Teams', label: 'Teams' }
];

// COMPONENTS
// EXTERNAL USER ***************************************************************
export const ExternalUsersTitle: FC<any> = ({ classes }) => (
	<>
		<Grid item xs={4} className={classes.gridItemNoSpacing}>
			<Typography component="span" className={classes.infoField}>
				NAME
			</Typography>
		</Grid>
		<Grid item xs={3} className={classes.gridItemNoSpacing}>
			<Typography component="span" className={classes.infoField}>
				COMPANY
			</Typography>
		</Grid>
		<Grid item xs={4} className={classes.gridItemNoSpacing}>
			<Typography component="span" className={classes.infoField}>
				EMAIL
			</Typography>
		</Grid>
		<Grid item xs={1} className={classes.gridItemNoSpacing}>
			<Typography component="span" className={classes.infoField}>
				ACTION
			</Typography>
		</Grid>
	</>
);

export const ExternalUsersLine: FC<any> = ({ index, handleDelete, control, errors, classes }) => {
	const externalUser = `externalUsers.${index}`;
	return (
		<>
			<Grid item xs={4}>
				<Input name={`${externalUser}.name`} control={control} errors={errors} />
			</Grid>
			<Grid item xs={3}>
				<Input name={`${externalUser}.company`} control={control} errors={errors} />
			</Grid>
			<Grid item xs={4}>
				<Input name={`${externalUser}.email`} control={control} errors={errors} />
			</Grid>
			<Grid item xs={1}>
				<IconButton
					className={classes.iconContainer}
					onClick={() => {
						handleDelete('externalUsers', index);
					}}>
					<Delete className={classes.clearIcon} />
				</IconButton>
			</Grid>
		</>
	);
};

// SOURCE REPOSITORY ***************************************************************
export const SourceReposLineTitle: FC<any> = ({ classes }) => (
	<>
		<Grid item xs={5} className={classes.gridItemNoSpacing}>
			<Typography component="span" className={classes.infoField}>
				TYPE
			</Typography>
		</Grid>
		<Grid item xs={5} className={classes.gridItemNoSpacing}>
			<Typography component="span" className={classes.infoField}>
				NAME
			</Typography>
		</Grid>
		<Grid item xs={2} className={classes.gridItemNoSpacing}>
			<Typography component="span" className={classes.infoField}>
				ACTION
			</Typography>
		</Grid>
	</>
);

export const SourceReposLine: FC<any> = ({ index, handleDelete, control, errors, classes }) => {
	const name = `repositories.${index}`;
	return (
		<>
			<Grid item xs={5}>
				<Select
					name={`${name}.type`}
					control={control}
					errors={errors}
					options={[
						{ id: 'Git', label: 'Git' },
						{ id: 'Azure DevOps', label: 'Azure DevOps' },
						{ id: 'SubVersion', label: 'SubVersion' }
					]}
				/>
			</Grid>
			<Grid item xs={5}>
				<Input name={`${name}.name`} control={control} errors={errors} />
			</Grid>
			<Grid item xs={2}>
				<IconButton
					className={classes.iconContainer}
					onClick={() => {
						handleDelete('repositories', index);
					}}>
					<Delete className={classes.clearIcon} />
				</IconButton>
			</Grid>
		</>
	);
};

// BUDGET ***************************************************************
export const BudgetTitle: FC<any> = ({ classes }) => {
	const { width } = useWindowDimensions();

	const isTablet = useMemo(() => width <= 900, [width]); // default is 900 tablet
	return (
		<>
			<Grid item xs={isTablet ? 2 : 3} className={classes.gridItemNoSpacing}>
				<Typography component="span" className={classes.infoField}>
					CAREER NAME
				</Typography>
			</Grid>
			<Grid item xs={isTablet ? 2 : 3} className={classes.gridItemNoSpacing}>
				<Typography component="span" className={classes.infoField}>
					CAREER JOB ROLE
				</Typography>
			</Grid>
			<Grid item xs={isTablet ? 2 : 3} className={classes.gridItemNoSpacing}>
				<Typography component="span" className={classes.infoField}>
					CAREER JOB ROLE LEVEL
				</Typography>
			</Grid>
			<Grid item xs={isTablet ? 2 : 1} className={classes.gridItemNoSpacing}>
				<Typography component="span" className={classes.infoField}>
					DAYS
				</Typography>
			</Grid>
			<Grid item xs={isTablet ? 2 : 1} className={classes.gridItemNoSpacing}>
				<Typography component="span" className={classes.infoField}>
					DAILY RATE
				</Typography>
			</Grid>
			<Grid item xs={1} className={classes.gridItemNoSpacing}>
				<Typography component="span" className={classes.infoField}>
					ACTION
				</Typography>
			</Grid>
		</>
	);
};

/** Generate select fields for:
 * @field careerPosition
 * @field careerJobRole
 * @field careerJobRoleLevel
 *
 * @param company
 * @param careerHelper
 */
const getCareerFields = (company: ICompanyInfo, careerHelper: IProposalFormCareerHelper) => {
	const initialState = {
		careerPosition: [],
		careerJobRole: [],
		careerJobRoleLevel: []
	};

	const hasBus =
		careerHelper.businessUnit && careerHelper.businessUnit.length > 0 ? careerHelper.businessUnit : false;
	const hasDiv = careerHelper.division && careerHelper.division.length > 0 ? careerHelper.division : false;
	const hasDep = careerHelper.department && careerHelper.department.length > 0 ? careerHelper.department : false;

	// if a department is not set, there will be no career name / job role
	if (!company || !(hasBus && hasDiv && hasDep)) {
		return initialState;
	}

	// get available CAREER NAMES
	const newCareerPosition =
		(company.careerProfiles &&
			company.careerProfiles
				.map((profiles: ICareerProfiles) => ({
					...profiles,
					eligibleDepartments: profiles.eligibleDepartments.filter(
						e => e.orgBunit === hasBus && e.orgDivision === hasDiv && e.orgDepartment === hasDep
					)
				}))
				.filter(e => e.eligibleDepartments.length !== 0)
				.filter(
					e =>
						!e.endDate ||
						(careerHelper.startDate &&
							new Date(parseDateToManageSaf(e.endDate)).getTime() >=
								new Date(parseDateToManageSaf(careerHelper.startDate)).getTime())
				)) ||
		[];

	// GET CAREER NAME selected
	const hasCareerPosition = careerHelper.careerPosition;

	const getCareerJobRole = () => {
		if (hasCareerPosition) {
			const payload = newCareerPosition?.find(e => e.id === hasCareerPosition);
			if (payload) {
				const payloadDep = payload.eligibleDepartments?.find(e => e.orgDepartment === hasDep);
				if (payloadDep) {
					return payloadDep.eligibleJobRoles;
				}
			}
		}
		return undefined;
	};

	// if CAREER NAME selected, GET CAREER JOB ROLE
	const careerJobRolePayload = getCareerJobRole();

	// GET CAREER JOB ROLE selected
	const hasCareerJobRole = careerHelper.careerJobRole;

	const getCareerJobRoleLevel = () => {
		if (hasCareerJobRole) {
			const payload = careerJobRolePayload?.find(e => e.id === hasCareerJobRole);
			if (payload) {
				return payload.levels.filter(e => e.availableOnProjRequest === true);
			}
		}
		return undefined;
	};
	// if CAREER JOB ROLE selected, GET CAREER JOB ROLE LEVEL
	const careerJobRoleLevelPayload = getCareerJobRoleLevel();

	return {
		careerPosition: newCareerPosition?.map(e => ({ id: e.id, label: e.name })),
		careerJobRole: careerJobRolePayload?.map(e => ({ id: e.id, label: e.name })) || [],
		careerJobRoleLevel:
			careerJobRoleLevelPayload?.map((e: IChoosableBaseInfo) => ({ id: e.id, label: e.name })) || []
	};
};

// SELECT FIELDS DEPENDENT OF COMPANY - CAREER NAMES
const careerFields = (selectedCompany: ICompanyInfo, newProjectHelper: IProposalFormCareerHelper) => {
	const payload = getCareerFields(selectedCompany, newProjectHelper);

	if (newProjectHelper.department && payload.careerPosition?.length === 0) {
		addNotification('warning', 'There are no Careers for this department', 5);
	}
	return payload;
};

export const BudgetLine: FC<any> = ({
	index,
	handleDelete,
	control,
	errors,
	classes,
	// options,
	setValue,
	getValues,
	selectedCompany,
	helper
}) => {
	const budg = `budget.${index}`;

	const [budgetLineHelper, setBudgetLineHelper] = useState({
		...helper
	});

	const { width } = useWindowDimensions();

	const isTablet = useMemo(() => width <= 900, [width]); // default is 900 tablet

	const options = careerFields(selectedCompany, budgetLineHelper);

	//Populate the fields with the specific data everytime the user goes to budget tab
	useEffect(() => {
		setBudgetLineHelper((prev: any) => ({
			...prev,
			careerPosition: getValues(`${budg}.profile`),
			careerJobRole: getValues(`${budg}.role`),
			careerJobRoleLevel: getValues(`${budg}.level`)
		}));
	}, []);

	useMemo(() => {
		if (!options) {
			return {
				careerPosition: [],
				careerJobRole: [],
				careerJobRoleLevel: []
			};
		}
		const selectedProfile = budgetLineHelper.careerPosition
			? options.careerPosition.find(profile => profile.id === budgetLineHelper.careerPosition)
			: undefined;

		const selectedjobRole =
			selectedProfile && budgetLineHelper.careerJobRole
				? options.careerJobRole.find(jobRole => jobRole.id === budgetLineHelper.careerJobRole)
				: undefined;

		return {
			careerJobRole:
				selectedProfile && (selectedProfile as any).eligibleJobRoles
					? (selectedProfile as any).eligibleJobRoles.map((roles: IChoosableBaseInfo) => ({
							id: roles.id,
							label: roles.name
					  }))
					: [],
			careerJobRoleLevel:
				selectedjobRole && (selectedjobRole as any).levels
					? (selectedjobRole as any).levels.map((level: IChoosableBaseInfo) => ({
							id: level.id,
							label: level.name
					  }))
					: []
		};
	}, [options, budgetLineHelper.careerPosition, budgetLineHelper.careerJobRole]);

	return (
		<>
			<Grid item xs={isTablet ? 2 : 3} className={classes.gridItemNoPadding}>
				<Select
					name={`${budg}.profile`}
					control={control}
					errors={errors}
					options={options.careerPosition}
					additionalOnChange={(item: { value: string }) => {
						const selectOption = options.careerPosition.find(e => e.id === item.value);
						if (selectOption) {
							setValue(`${budg}.role`, '');
							setValue(`${budg}.level`, '');
							setBudgetLineHelper((prev: any) => ({
								...prev,
								careerPosition: item.value,
								careerJobRole: [],
								careerJobRoleLevel: []
							}));
						}
					}}
				/>
			</Grid>
			<Grid item xs={isTablet ? 2 : 3} className={classes.gridItemNoPadding}>
				<Select
					name={`${budg}.role`}
					control={control}
					errors={errors}
					options={options.careerJobRole}
					additionalOnChange={(item: { value: string }) => {
						const selectOption = options.careerJobRole.find(e => e.id === item.value);
						if (selectOption) {
							setValue(`${budg}.level`, '');
							setBudgetLineHelper((prev: any) => ({
								...prev,
								careerJobRole: item.value,
								careerJobRoleLevel: []
							}));
						}
					}}
				/>
			</Grid>
			<Grid item xs={isTablet ? 2 : 3} className={classes.gridItemNoPadding}>
				<Select name={`${budg}.level`} control={control} errors={errors} options={options.careerJobRoleLevel} />
			</Grid>
			<Grid item xs={isTablet ? 2 : 1} className={classes.gridItemNoPadding}>
				<Input name={`${budg}.days`} control={control} errors={errors} />
			</Grid>
			<Grid item xs={isTablet ? 2 : 1} className={classes.gridItemNoPadding}>
				<Input name={`${budg}.dailyRate`} control={control} errors={errors} />
			</Grid>
			<Grid item xs={1} className={classes.gridItemNoPadding}>
				<IconButton
					className={classes.iconContainer}
					onClick={() => {
						handleDelete('budget', index);
					}}>
					<Delete className={classes.clearIcon} />
				</IconButton>
			</Grid>
		</>
	);
};
