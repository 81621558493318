/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { ChangeEventHandler, FC, useState } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography, Input as CoreInput, IconButton } from '@mui/material';
import { Edit, Close } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { InfoField } from 'xpand-ui/forms';
import { Dialog } from 'xpand-ui/core';

//* TYPINGS
import { IUserProfile } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { START_API_PATH } from 'lib/utils/constants';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { UserPhotoProps } from '.';
import imageURL from '../../../../../../../assets/images/myProfile/uploadImage.png';
import { getLSField } from 'lib/utils/cookies';

const MaxFileSize = 5 * 1000 * 1000; // 5Mb

let formData = new FormData();
interface IUserPhoto extends UserPhotoProps {
	isAdmin: string;
	userProfile: IUserProfile;
}

interface INewPhoto {
	formData: FormData;
	url: string;
}

const UserPhoto: FC<IUserPhoto> = ({ sendNewProfilePic, isAdmin, userProfile }) => {
	const classes = useStyles();
	const [newPhoto, setNewPhoto] = useState<INewPhoto | null>(null);
	const [isFileSizeOk, setIsFileSizeOk] = useState<boolean>();
	const [modalPhoto, setModalPhoto] = useState<boolean | null>(null);

	const onSubmit = () => {
		sendNewProfilePic(userProfile.user.ldapData.username, newPhoto?.formData);
		setModalPhoto(false);
		setNewPhoto(null);
		formData = new FormData();
	};

	const changeHandler = (event: { target: { files: any[] } }) => {
		formData = new FormData();
		formData.append('file', event.target.files[0]);
		const fileSize: number = event.target.files[0].size;
		setIsFileSizeOk(fileSize <= MaxFileSize); // 4Mb
		setNewPhoto({ formData, url: URL.createObjectURL(event.target.files[0]) });
	};

	if (!userProfile || !!userProfile?.code) return null;

	const newTemplateActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setNewPhoto(null);
				setModalPhoto(null);
			}
		},
		{
			id: 'submit',
			label: 'Submit',
			color: 'primary',
			variant: 'contained',
			disabled: newPhoto === null || !isFileSizeOk ? true : newPhoto.formData.getAll('file').length === 0,
			onClick: onSubmit
		}
	];

	const getPhoto = () => {
		const userInfoStorage = getLSField('impersonate_userInfo');
		let userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
		if (isAdmin) {
			return `${START_API_PATH}/admin/users/${isAdmin}/photo`;
		} else if (userInfo?.username) {
			return `${START_API_PATH}/admin/users/${userInfo.username}/photo`;
		} else {
			return `${START_API_PATH}/user/photo`;
		}
	};

	return (
		<Grid item {...sizes[12]}>
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				spacing={2}
				style={{ paddingTop: '20px' }}>
				<Grid item>
					<div className={classes.avatarContainer}>
						<img src={getPhoto()} alt="" className={classes.avatarImage} />
					</div>
					{isAdmin && !getLSField('impersonate_userInfo') && (
						<IconButton color="yale" className={classes.editButton} onClick={() => setModalPhoto(true)}>
							<Edit />
						</IconButton>
					)}
				</Grid>
				<Grid item>
					<Typography variant="h1" color="yale" style={{ fontSize: '30px' }}>
						{userProfile?.user?.ldapData?.firstName} {userProfile?.user?.ldapData?.lastName}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h6" color="primary" style={{ fontSize: '16px' }}>
						{userProfile?.user?.erpData?.searchKey}
					</Typography>
				</Grid>
			</Grid>
			<Dialog
				modal={{
					open: Boolean(modalPhoto),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setModalPhoto(null);
							formData = new FormData();
						}
					},
					content: (
						<Grid container style={{ padding: '10px 5px 40px 5px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} style={{ textAlign: 'center' }}>
									<InfoField fontSize="lg" label="Select a new profile picture" value="" />
									{!newPhoto && (
										<Grid container style={{ textAlign: 'center' }}>
											<label htmlFor="iconInput" className={classes.box}>
												<img src={imageURL} alt="" className={classes.uploadImage} />
												<p className={classes.uploadImageText}>Upload Image</p>
											</label>
											<CoreInput
												onChange={changeHandler as unknown as ChangeEventHandler}
												name="file"
												id="iconInput"
												type="file"
												inputProps={{ accept: 'image/*' }}
												className={classes.hideInput}
											/>
										</Grid>
									)}
								</Grid>
								{newPhoto && isFileSizeOk && (
									<Grid item xs={12} style={{ textAlign: 'center' }}>
										<IconButton color="primary" className={classes.closeButton}>
											<Close className={classes.closeIcon} onClick={() => setNewPhoto(null)} />
										</IconButton>
										<img src={newPhoto.url} className={classes.box} alt="" />
									</Grid>
								)}
								{!isFileSizeOk && newPhoto && (
									<Grid item xs={12} style={{ textAlign: 'center' }}>
										<InfoField label="" value="The selected image exceeds the maximum file size" />
									</Grid>
								)}
							</Grid>
						</Grid>
					)
				}}
				title="Change profile picture"
				actions={newTemplateActions}
				scroll="body"
			/>
		</Grid>
	);
};

UserPhoto.defaultProps = {
	isAdmin: undefined
};

export default UserPhoto;
