import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import { stringRequired, dateRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	newSheetStartDate: stringRequired,
	newSheetDescription: stringRequired
});

export const defaultValues = {
	newSheetStartDate: '',
	newSheetDescription: ''
};
