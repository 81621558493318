import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	filterButtonSelected: {
		borderRadius: 10,
		background: 'white',
		color: 'black',
		alignSelf: 'center',
		'&:hover': {
			color: 'white',
			background: theme.accentColor
		}
	},
	displayFlex: {
		display: 'flex'
	},
	alignCenter: {
		alignSelf: 'center'
	},
	gridRow: {
		marginBottom: '5px'
	},
	totalHours: {
		width: '-webkit-fill-available',
		background: '#F8F9FC', //
		borderRadius: 10,
		color: theme.accentColor,
		padding: '10px',
		textAlign: 'center',
		fontSize: 'large',
		fontWeight: 'bold'
	},
	todayButton: {
		color: 'black',
		fontSize: '20',
		marginRight: '10px'
	},
	previousWeekButton: {
		marginRight: '20px',
		height: '30px',
		width: '30px'
	},
	gridRowFilters: {
		marginBottom: '25px'
	},
	gridRowFilters2: {
		marginBottom: '30px'
	},
	closeButton: {
		float: 'right'
	},
	closeIcon: {
		fontSize: '40px',
		color: theme.grayedColor
	},
	closeIconWithResults: {
		fontSize: '40px',
		color: '#FFFFFF'
	},
	infoField: {
		color: '#718FA2'
	},
	filtersWithResult: {
		color: '#FFFFFF'
	},
	cancelBtn: {
		color: '#1D3580',
		float: 'right',
		borderRadius: '10px'
	},
	cancelBtnWithResult: {
		color: '#FFFFFF',
		float: 'right',
		borderRadius: '10px'
	},
	searchBtn: {
		color: '#FFFFFF',
		backgroundColor: theme.accentColor,
		float: 'right',
		borderRadius: '10px'
	},
	searchBtnWithResult: {
		color: '#1D3580',
		backgroundColor: '#FFFFFF',
		float: 'right',
		borderRadius: '10px'
	},
	'div.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
		background: '#ffffff'
	},
	borderButtons: {
		borderRadius: '10px'
	},
	bodyRootWithResultsAndCollpseClosed: {
		padding: '0px 300px 10px 300px',
		backgroundColor: '#1D3580', //theme.accentColor
		overflow: 'visible'
	},

	bodyRootWithResultsAndCollpseOpened: {
		padding: '0px 300px',
		backgroundColor: '#1D3580', //theme.accentColor
		overflow: 'visible'
	},
	//* WEB
	[theme.breakpoints.up('md')]: {
		titleRoot: {
			padding: '100px 100px 0px 100px'
		},

		bodyRoot: {
			padding: '300px 300px 0px 300px'
		},
		titleRootWithResults: {
			padding: '0px 100px',
			backgroundColor: '#1D3580' //theme.accentColor
		},
		tableResults: {
			padding: '0px 20px'
		}
	},
	//* TABLET
	[theme.breakpoints.down('md')]: {
		titleRoot: {
			padding: '0 10px'
		},

		bodyRoot: {
			padding: '10px 20px',
			minHeight: 'calc(100vh - 140px)',
			overflow: 'visible'
		},
		titleRootWithResults: {
			padding: '0 10px',
			backgroundColor: '#1D3580' //theme.accentColor
		},
		tableResults: {
			padding: '0 10px'
		}
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		titleRoot: {
			padding: '0 10px'
		},

		bodyRoot: {
			padding: '10px 20px',
			minHeight: 'calc(100vh - 140px)'
		},
		titleRootWithResults: {
			padding: '0 10px',
			backgroundColor: '#1D3580' //theme.accentColor
		},

		tableResults: {
			padding: '0 10px'
		}
	}
}));

export { useStyles };
