/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';

import { booleanRequired, stringRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	searchKey: stringRequired,
	title: stringRequired,
	url: stringRequired,
	hasFiles: booleanRequired
});

export const defaultValues = {
	searchKey: '',
	title: '',
	url: '',
	hasFiles: undefined
};
