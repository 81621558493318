/* eslint-disable indent */
import {
	fetchUserNotifications,
	setMarkNotificationAsRead,
	setCreateNotification,
	setCreateNotificationProposals,
	setMarkNotificationAsReadProposals,
	deleteAllUserNotifications,
	fetchUserNotificationsTimesheets,
	fetchUserNotificationsApprovals
} from 'lib/api/system/notifications';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import { Dispatch } from 'redux';
import { NotificationsState } from 'typings/store/notificationsTypes';
import {
	getNotifications,
	markNotificationAsRead,
	setNotification,
	setPostDeleteAllUserNotifications
} from './actions';

export const getUserNotifications =
	(userId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request

			const response = await fetchUserNotifications(userId);
			// set api results on the redux state
			if (response.data) {
				dispatch(getNotifications(response.data.notifications));
			} else {
				dispatch(getNotifications(response as OriginalResponse));
			}
		} catch (error) {
			// No particular errors are set, as the notification system should not break existing pages
		}
	};

export const getUserNotificationsTimesheets =
	(userId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request
			const response = await fetchUserNotificationsTimesheets(userId);
			// set api results on the redux state
			if (response.data) {
				dispatch(getNotifications(response.data.notifications));
			} else {
				dispatch(getNotifications(response as OriginalResponse));
			}
		} catch (error) {
			// No particular errors are set, as the notification system should not break existing pages
		}
	};

export const getUserNotificationsApprovals =
	(userId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request
			const response = await fetchUserNotificationsApprovals(userId);
			// set api results on the redux state
			if (response.data) {
				dispatch(getNotifications(response.data.notifications));
			} else {
				dispatch(getNotifications(response as OriginalResponse));
			}
		} catch (error) {
			// No particular errors are set, as the notification system should not break existing pages
		}
	};

export const setNotificationAsRead =
	(userId: string, payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request
			const response = await setMarkNotificationAsRead(userId, payload);

			// set api results on the redux state
			if (response) {
				dispatch(markNotificationAsRead(response.data));
			} else {
				dispatch(markNotificationAsRead(response as OriginalResponse));
			}

			const responseNotificationsReload = await fetchUserNotifications(userId);

			if (responseNotificationsReload) {
				dispatch(getNotifications(responseNotificationsReload.data.notifications));
			} else {
				dispatch(getNotifications(responseNotificationsReload as OriginalResponse));
			}
		} catch (error) {
			// No particular errors are set, as the notification system should not break existing pages
		}
	};

export const setNotificationAsReadProposals =
	(proposalId: string, payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request
			const response = await setMarkNotificationAsReadProposals(proposalId, payload);

			// set api results on the redux state
			if (response) {
				dispatch(markNotificationAsRead(response.data));
			} else {
				dispatch(markNotificationAsRead(response as OriginalResponse));
			}
		} catch (error) {
			// No particular errors are set, as the notification system should not break existing pages
		}
	};

export const setNewNotification =
	(userId: string, payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request
			const response = await setCreateNotification(userId, payload);

			// set api results on the redux state
			if (response) {
				dispatch(setNotification(response.data));
			} else {
				dispatch(setNotification(response as OriginalResponse));
			}

			const responseNotificationsReload = await fetchUserNotifications(userId);

			if (responseNotificationsReload) {
				dispatch(getNotifications(responseNotificationsReload.data.notifications));
			} else {
				dispatch(getNotifications(responseNotificationsReload as OriginalResponse));
			}
		} catch (error) {
			// No particular errors are set, as the notification system should not break existing pages
		}
	};

export const setNewNotificationProposals =
	(proposalId: string, payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request
			const response = await setCreateNotificationProposals(proposalId, payload);

			// set api results on the redux state
			if (response) {
				dispatch(setNotification(response.data));
			} else {
				dispatch(setNotification(response as OriginalResponse));
			}
		} catch (error) {
			// No particular errors are set, as the notification system should not break existing pages
		}
	};

export const sendDeleteAllUserNotifications =
	(username: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const response: Success = await deleteAllUserNotifications(username);
			dispatch(setPostDeleteAllUserNotifications(response || null));
		} catch (error) {
			// No particular errors are set, as the notification system should not break existing pages
		}
	};
