import { createReducer } from 'typesafe-actions';
import { FormFieldsActions as Actions, FormFieldsState } from 'typings/store/admin/formFields';

const INITIAL_STATE: FormFieldsState = {
	formFieldsConfig: null,
	loading: false,
	error: null
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CSCActions = (state: any, { payload }: any) => ({
	...INITIAL_STATE,
	// for any actions we must ensure that a new request must be made the next time we access the ESC by user page
	employeeCategoryActions: payload.payload
});

const FormFieldsReducer = createReducer(INITIAL_STATE, {
	[Actions.GET_FORM_FIELDS_CONFIG]: (state, { payload }) => ({
		...state,
		loading: false,
		formFieldsConfig: payload
	})
});

export default FormFieldsReducer;
