/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
import React, { FC, useMemo, Fragment } from 'react';
import withLayout from 'lib/hocs/withLayout';

import { Grid, Typography } from '@mui/material';
import { Numeric, InfoField } from 'xpand-ui/forms';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';

import { IPrizeGoal, IProposalProject } from 'typings/store/admin/proposals';
import { IChoosableBaseInfo } from 'typings/store/generalTypes';
import { useStyles } from './styles';

const positiveValue = (val: unknown): string => ((Number(val) <= 100 && Number(val)) >= 0 ? `${val}` : '0');

const parseNumber = (number: number) => (`${number}` === 'NaN' ? '0' : `${positiveValue(number)}`);

const parsePercentage = (val: unknown): string =>
	// eslint-disable-next-line no-nested-ternary
	val ? (Number(val) <= 100 && Number(val) >= 0 ? `${val}` : '0') : '0';

const SummaryTable: FC<any> = ({ proposal, isContractor, referenceData, isCollabType, formFields }) => {
	const classes = useStyles();
	const { width } = useWindowDimensions();
	const isTablet = useMemo(() => width <= 900, [width]); // default is 900 tablet
	const toShow = isCollabType && isContractor === 'Employee';

	const createLine = useMemo(
		() =>
			(
				name?: string,
				value?: string | number,
				percent?: string | number,
				info?: string | number,
				overrideSizes = [4, 1, 1, 6]
			) =>
				(
					<>
						{overrideSizes[0] !== 0 && (
							<Grid item xs={overrideSizes[0]}>
								{name || ''}
							</Grid>
						)}
						{overrideSizes[1] !== 0 && (
							<Grid item xs={overrideSizes[1]}>
								{value || ''}
							</Grid>
						)}
						{overrideSizes[2] !== 0 && (
							<Grid item xs={overrideSizes[2]}>
								{percent || ''}
							</Grid>
						)}
						{overrideSizes[3] !== 0 && (
							<Grid item xs={overrideSizes[3]} style={{ paddingLeft: '30px' }}>
								{info || ''}
							</Grid>
						)}
					</>
				),
		[]
	);

	const createTitle = useMemo(
		() => (name: string) =>
			(
				<>
					<Grid item {...sizes[12]} className={classes.title}>
						<Typography variant="h3" gutterBottom>
							{name}
						</Typography>
					</Grid>
				</>
			),
		[]
	);

	const createBigTitle = useMemo(
		() => (name: string) =>
			(
				<>
					<Grid item {...sizes[12]} className={classes.title}>
						<Typography variant="h3" gutterBottom className={classes.bigTitle}>
							{name}
						</Typography>
					</Grid>
				</>
			),
		[]
	);

	const getPrizeGoals = () => {
		const emptyGoals = (
			name: string,
			values: { money: string | number; percent: string | number } = { money: '0', percent: '0' }
		) => ({
			overrideSizes: isTablet ? [4, 4, 4, 0] : [6, 3, 3, 0],
			name,
			value: <Numeric readOnly percentage value={parsePercentage(values.percent)} textAlign="right" />,
			percent: <Numeric readOnly money value={values.money} textAlign="right" />,
			info: ''
		});

		if (typeof proposal?.prizeBonusGoals === 'undefined' || proposal?.prizeBonusGoals.length === 0)
			return proposal?.prizeBonusGoalTypes?.map((type: IChoosableBaseInfo) => emptyGoals(type.name as string));

		return proposal?.prizeBonusGoalTypes?.map((type: IChoosableBaseInfo) => {
			const payload = proposal?.prizeBonusGoals.filter((e: IPrizeGoal) => e.goalTypeId === type.id);
			// eslint-disable-next-line no-return-assign, no-param-reassign
			const totalPrizePercent: number = payload.reduce((total: number, current: IPrizeGoal) => {
				// eslint-disable-next-line no-param-reassign
				const newTotal = total + Number(current.prizePercentage);
				return newTotal;
			}, 0);

			if (totalPrizePercent === 0) return emptyGoals(type.name as string);

			return emptyGoals(type.name as string, {
				money: parseFloat(`${totalPrizePercent / 100}`) * proposal?.prizeBonus,
				percent: totalPrizePercent
			});
		});
	};

	// PT Employee
	let summaryValues = [];
	if (formFields.country == 'PT') {
		if (proposal?.budget === 0) {
			summaryValues = useMemo(
				() =>
					[
						{
							title: 'MONTHLY INCOME',
							fields: [
								formFields?.baseValue && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Base Value',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.baseValue) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},

								formFields?.christmasTwelfth && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Christmas Allowance Twelfth',
									value: (
										<Numeric
											readOnly
											money
											value={(proposal?.hasTwelfths && proposal?.christmasTwelfthValue) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: '(Base Value + WSE) / 12'
								},
								formFields?.vacationsTwelfth && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Vacations Allowance Twelfth',
									value: (
										<Numeric
											readOnly
											money
											value={(proposal?.hasTwelfths && proposal?.vacationsTwelfthValue) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: '(Base Value + WSE) / 12'
								},
								formFields?.foodAllowance && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Food Allowance',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.monthlyAverageFoodAllowance) || '0'}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.foodAllowance) || '0'}
											textAlign="right"
										/>
									),
									info: '€/day'
								},
								formFields?.nationalAllowance &&
									Number(positiveValue(proposal?.nationalAllowanceMonthlyValue)) > 0 && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'National Allowance',
										value: (
											<Numeric
												readOnly
												money
												value={positiveValue(proposal?.nationalAllowanceMonthlyValue) || '0'}
												textAlign="right"
											/>
										),
										percent: (
											<Numeric
												readOnly
												money
												value={positiveValue(proposal?.nationalAllowanceDailyValue) || '0'}
												textAlign="right"
											/>
										),
										info: '€/day'
									},
								formFields?.internationalAllowance &&
									Number(positiveValue(proposal?.internationalAllowanceMonthlyValue)) > 0 && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'International Allowance',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(proposal?.internationalAllowanceMonthlyValue) || '0'
												}
												textAlign="right"
											/>
										),
										percent: (
											<Numeric
												readOnly
												money
												value={positiveValue(proposal?.internationalAllowanceDailyValue) || '0'}
												textAlign="right"
											/>
										),
										info: '€/day'
									},
								formFields?.travelPass &&
									Number(positiveValue(proposal?.travelPass)) > 0 && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Travel Pass',
										value: (
											<Numeric
												readOnly
												money
												value={positiveValue(proposal?.travelPass) || '0'}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									},
								formFields?.nurseryCheck && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Nursery Check',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.nurseryCheck) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								formFields?.otherExpenses && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Other Expenses',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.otherExpenses) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Monthly, 12 months of the year, in the form of KMs or other'
								},
								formFields?.lifeInsurance && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Life Insurance',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.lifeInsuranceValue) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								}
							]
						},
						{
							title: 'OTHER BENEFITS',
							fields: [
								formFields?.communicationsPlafondValue && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Mobile Phone',
									value: (
										<Numeric
											readOnly
											money
											value={
												(proposal?.commsPlafondFlag && proposal?.communicationsPlafond) || '0'
											}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Communications value'
								},
								formFields?.healthInsurance && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Health Insurance',
									value: (
										<Numeric
											readOnly
											money
											value={(proposal?.healthInsuranceCheck && proposal?.healthInsurance) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Estimate value of package used at company level'
								},
								formFields?.numberDependents && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Number of Descendents',
									value: (
										<Numeric
											readOnly
											value={positiveValue(proposal?.numberMembersDep) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Work Insurance Estimate Cost',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.monthlyWorkInsuranceEstimateCost) || '0'}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											percentage
											value={
												parsePercentage(proposal?.monthlyStandardWorkInsuranceCostPercentage) ||
												'0'
											}
											textAlign="right"
										/>
									),
									info: ''
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Laptop',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.monthlyStandardLaptopCost) || '0'}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.laptopMonthlyValue) || '0'}
											textAlign="right"
										/>
									),
									info: 'Monthly value'
								},
								...(proposal?.otherBenefitsList?.length > 0
									? proposal?.otherBenefitsList.map((ben: any, index: number) => ({
											overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
											name: index === 0 ? 'Other Benefits' : '',
											value: (
												<Numeric
													readOnly
													money
													value={parseNumber(ben.prizeValue) || '0'}
													textAlign="right"
												/>
											),
											percent: '',
											info: ben.goalDetails || ''
									  }))
									: [
											{
												overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
												name: 'Other Benefits',
												value: <Numeric readOnly money value="0" textAlign="right" />,
												percent: '',
												info: ''
											}
									  ])
							]
						},
						{
							title: 'DEDUCTIONS ',
							fields: [
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Base Value Social Security',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.baseGrossSSTaxValue) || '0'}
											textAlign="right"
										/>
									),
									percent: <Numeric readOnly percentage value={11} textAlign="right" />,
									info: ''
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Christmas Allowance Twelfth Social Security',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.christmasTwelfthSSTaxValue) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Social Security tax rate applied to twelfth'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Vacations Allowance Twelfth Social Security',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.vacationsTwelfthSSTaxValue) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Social Security tax rate applied to twelfth'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Base Value IRS',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.baseGrossIRSTaxValue) || '0'}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											percentage
											value={parsePercentage(proposal?.irsTaxPercentage) || '0'}
											textAlign="right"
										/>
									),
									info: 'IRS tax rate applied to base value. Please check IRS tables for current year.'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Christmas Allowance Twelfth IRS',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.christmasTwelfthIRSTaxValue) || '0'}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											percentage
											value={parsePercentage(proposal?.irsTaxTwelfthPercentage) || '0'}
											textAlign="right"
										/>
									),
									info: 'IRS tax rate applied to twelfth'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Vacations Allowance Twelfth IRS',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.vacationsTwelfthIRSTaxValue) || '0'}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											percentage
											value={parsePercentage(proposal?.irsTaxTwelfthPercentage) || '0'}
											textAlign="right"
										/>
									),
									info: 'IRS tax rate applied to twelfth'
								}
							]
						},
						{
							title: 'SUMMARY',
							fields: [
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Annual Gross Value',
									value: (
										<Numeric
											readOnly
											money
											value={
												(proposal?.annualPrizeValue > 0
													? proposal?.annualGrossValueWithPrize
													: proposal?.annualGrossValue) || '0'
											}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Includes Christmas and Vacation Allowances twelfths'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Annual Gross Value (Other Benefits)',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.totalBenefitsAnnualValue) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Health Insurance, communications plafond, etc'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Total Annual Gross Value',
									value: (
										<Numeric
											readOnly
											money
											value={
												(proposal?.annualPrizeValue > 0
													? proposal?.annualGrossValueWithPrizeAndWithBenefits
													: proposal?.annualGrossValueWithBenefits) || '0'
											}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Sum between "Annual Gross Value" and "Other Benefits"'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Monthly Gross Value (14 months)',
									value: (
										<Numeric
											readOnly
											money
											value={
												(proposal?.annualPrizeValue > 0
													? proposal?.monthlyGrossValueWithPrize14Months
													: proposal?.monthlyGrossValue14Months) || '0'
											}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Annual Gross Value divided by 14 months'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Monthly Gross Value (12 months)',
									value: (
										<Numeric
											readOnly
											money
											value={
												(proposal?.annualPrizeValue > 0
													? proposal?.monthlyGrossValueWithPrize
													: proposal?.monthlyGrossValue) || '0'
											}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Annual Gross Value divided by 12 months'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Annual Net Value',
									value: (
										<Numeric
											readOnly
											money
											value={
												(proposal?.annualPrizeValue > 0
													? proposal?.annualNetValueWithPrize
													: proposal?.annualNetValue) || '0'
											}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Monthly Net Value',
									value: (
										<Numeric
											readOnly
											money
											value={
												(proposal?.annualPrizeValue > 0
													? proposal?.monthlyNetValueWithPrize
													: proposal?.monthlyNetValue) || '0'
											}
											textAlign="right"
										/>
									),
									percent: '',
									info: '20 work days are considered'
								}
							]
						},
						{
							title: 'NOTES:',
							fields: [
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Annual IRS',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(
												(proposal?.annualPrizeValue > 0
													? proposal?.annualIRSValueWithPrize
													: proposal?.annualIRSValue) || '0'
											)}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Total deducted IRS, available for tax abatement on current declaration'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Indicators for calculations',
									value: '',
									percent: <Numeric readOnly value={20} textAlign="right" />,
									info: 'Work Days'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: '',
									value: '',
									percent: (
										<Numeric
											readOnly
											value={positiveValue(proposal?.nationalAllowanceDays) || '0'}
											textAlign="right"
										/>
									),
									info: 'Days with national allowance'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: '',
									value: '',
									percent: (
										<Numeric
											readOnly
											value={positiveValue(proposal?.internationalAllowanceDays) || '0'}
											textAlign="right"
										/>
									),
									info: 'Days with international allowance'
								}
							]
						},
						{
							title: 'PERFORMANCE PRIZE',
							fields: [
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Annual Prize Bonus (maximum value)',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.annualPrizeValue) || '0'}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											percentage
											value={
												parsePercentage(proposal?.annualPrizePercentageOfAnnualGrossValue) ||
												'0'
											}
											textAlign="right"
										/>
									),
									info: 'VBM = Monthly Gross Value (gross value, subject to IRS)'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: '',
									value: '',
									percent: (
										<Numeric
											readOnly
											percentage
											value={parsePercentage(proposal?.annualPrizeValueWeight) || '0'}
											textAlign="right"
										/>
									),
									info: 'Variable component (prize weight on total Annual Gross Value)'
								},
								formFields?.monthsApplicable && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: '',
									value: '',
									percent: (
										<Numeric
											readOnly
											value={positiveValue(proposal?.monthsApplicable) || '0'}
											textAlign="right"
										/>
									),
									info: 'Months covered by prize bonus value'
								},
								formFields?.eligiblePrizeValue && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: '',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.eligiblePrizeValue) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Gross value, subject to IRS (applied to prize bonus covered period)'
								},
								formFields?.eligiblePrizeValue &&
									formFields?.monthsApplicable && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: '',
										value: '',
										percent: (
											<Numeric
												readOnly
												money
												value={parseNumber(
													Number(proposal?.eligiblePrizeValue) /
														Number(proposal?.monthsApplicable)
												)}
												textAlign="right"
											/>
										),
										info: 'Monthly prize value on covered months'
									}
							]
						},
						{
							title: 'PRIZE BONUS COMPONENTS',
							fields: getPrizeGoals() || []
						},
						{
							title: 'NOTES',
							fields: [
								{
									name: 'Annual Prize Bonus is paid until May 31st of next year, in proportion to worked period / eligible months.',
									overrideSizes: [12, 0, 0, 0, 0],
									value: '',
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [4, 3, 0, 5] : [4, 2, 2, 4],
									name: '"XLife - Bring an Xpert" Prize Bonus',
									value: <Numeric readOnly money value="1000" textAlign="right" />,
									percent: '',
									info: 'Prize bonus for resources referral, if collaboration period is higher than 6 months.'
								},
								{
									overrideSizes: isTablet ? [4, 3, 0, 5] : [4, 2, 2, 4],
									name: 'IRS',
									value: (
										<Numeric
											readOnly
											percentage
											value={parsePercentage(proposal?.annualPrizeIRS) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'IRS Tax rate to apply in Prize Bonus month, applied to sum of Base Value + Work Exemption + Prize'
								}
							]
						},
						{
							title: 'TOTAL RETRIBUTION',
							fields: [
								{
									overrideSizes: isTablet ? [4, 3, 0, 5] : [4, 2, 2, 4],
									name: 'Annual Gross',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.annualGrossValueWithPrize) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Includes benefits, twelfths and full prize'
								},
								{
									overrideSizes: isTablet ? [4, 3, 0, 5] : [4, 2, 2, 4],
									name: 'Annual Net',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.annualNetValueWithPrize) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [4, 3, 0, 5] : [4, 2, 2, 4],
									name: 'Monthly Net',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.monthlyNetValueWithPrize) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: '12 months, with full prize bonus included'
								},
								{
									overrideSizes: isTablet ? [4, 3, 0, 5] : [4, 2, 2, 4],
									name: 'Annual IRS',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(
												(proposal?.annualPrizeValue > 0
													? proposal?.annualIRSValueWithPrize
													: proposal?.annualIRSValue) || '0'
											)}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Total deducted IRS, available for tax abatement on current declaration'
								}
							]
						},
						{
							bigTitle: 'Candidate specific data for Admission Process',
							fields: [
								formFields?.company && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Company',
									value: <InfoField value={positiveValue(proposal?.companyName)} />
								},
								formFields?.businessUnit && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Business Unit',
									value: (
										<InfoField label="" value={positiveValue(proposal?.businessUnitName) || ''} />
									)
								},
								formFields?.division && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Division',
									value: <InfoField label="" value={positiveValue(proposal?.divisionName) || ''} />
								},
								formFields?.department && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Department',
									value: <InfoField label="" value={positiveValue(proposal?.departmentName) || ''} />
								},
								formFields?.contractType && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Contract Type',
									value: (
										<InfoField label="" value={positiveValue(proposal?.contractTypeName) || ''} />
									)
								},
								formFields?.daysOfProbationPeriod && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Days Of Probation Period',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.daysOfProbationPeriod) || ''}
										/>
									)
								},
								formFields?.probationPeriodEndDate && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Probation Period End Date',
									value: (
										<InfoField
											label=""
											value={
												(proposal?.probationPeriodEndDate &&
													parseDateToShow(proposal?.probationPeriodEndDate)) ||
												''
											}
										/>
									)
								},
								formFields?.collabStartDate && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Start Date',
									value: (
										<InfoField
											label=""
											value={(proposal?.startDate && parseDateToShow(proposal?.startDate)) || ''}
										/>
									),
									percent: 'End Date',
									info: (
										<InfoField
											label=""
											value={(proposal?.endDate && parseDateToShow(proposal?.endDate)) || ''}
										/>
									)
								},
								formFields?.collabRole && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Role',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.collaborationRoleName) || ''}
										/>
									)
								},
								formFields?.contractPosition && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Contract Position',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.contractPositionName) || ''}
										/>
									)
								},
								formFields?.primaryOffice && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Primary Office Location',
									value: (
										<InfoField label="" value={positiveValue(proposal?.primaryOfficeName) || ''} />
									)
								},
								formFields?.workingOffice && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Working Office Location',
									value: (
										<InfoField label="" value={positiveValue(proposal?.workingOfficeName) || ''} />
									)
								},
								formFields?.customerInContract && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Customer in Contract',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.customerInContractName) || ''}
										/>
									)
								},
								(toShow || !isCollabType) && {
									overrideSizes: [4, 8, 0, 0],
									name: 'TSU Exemption ?',
									value: <InfoField label="" value={proposal?.tsuExemption ? 'Yes' : 'No'} />
								},
								formFields?.employeeManager && {
									overrideSizes: [4, 2, 6, 0],
									name: 'Employee Manager',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.employeeManagerName) || ''}
										/>
									),
									percent:
										"Who will approve employee's absences. If internal internship (Summer, etc), not applicable."
								},
								formFields?.careerManager && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Manager',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerManagerName) || ''} />
									)
								},
								formFields?.careerName && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Name',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerPositionName) || ''} />
									)
								},
								formFields?.careerJobRole && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Job Role',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerJobRoleName) || ''} />
									)
								},
								formFields?.careerJobRoleLevel && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Job Role Level',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.careerJobRoleLevelName) || ''}
										/>
									)
								},
								!isCollabType &&
									(proposal?.projects?.length > 0
										? proposal?.projects.map((proj: IProposalProject, index: number) => ({
												overrideSizes: index === 0 ? [4, 2, 6, 0] : [4, 8, 0, 0],
												name: index === 0 ? 'Project in timesheet' : '',
												value: <InfoField label="" value={proj.projectName || ''} />,
												percent:
													index === 0
														? 'Project where the employee will work on, to claim on timesheet.'
														: ''
										  }))
										: [
												{
													overrideSizes: [4, 2, 6, 0],
													name: 'Project in timesheet',
													value: '',
													percent:
														'Project where the employee will work on, to claim on timesheet.'
												}
										  ])
							]
						},
						{
							bigTitle: 'Proposal Costs Summary Data',
							fields: [
								{
									overrideSizes: isTablet ? [6, 6, 0, 0] : [4, 2, 2, 4],
									name: 'Annual Cost w/ Prize',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.annualGrossCostWithPrize) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [6, 6, 0, 0] : [4, 2, 2, 4],
									name: 'Annual Cost',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.annualGrossCost) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [6, 6, 0, 0] : [4, 2, 2, 4],
									name: 'Annual Cost w/ Prize and no Benefits',
									value: (
										<Numeric
											readOnly
											money
											value={
												positiveValue(proposal?.annualGrossCostWithPrizeAndNoBenefits) || '0'
											}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [6, 6, 0, 0] : [4, 2, 2, 4],
									name: 'Monthly Cost w/ Prize',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.monthlyGrossCostWithPrize) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [6, 6, 0, 0] : [4, 2, 2, 4],
									name: 'Monthly Cost',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.monthlyGrossCost) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [6, 6, 0, 0] : [4, 2, 2, 4],
									name: 'Daily Cost w/ Prize',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.dailyCostWithPrize) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [6, 6, 0, 0] : [4, 2, 2, 4],
									name: 'Daily Cost',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.dailyCost) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								}
							]
						}
					].filter(e => {
						if (proposal?.collaborationType === 'Cost Salary Category' && e.bigTitle) {
							return e.bigTitle !== 'Candidate specific data for Admission Process';
						}
						return true;
					}),
				[proposal, formFields]
			);
		} else {
			// PT Contractor
			summaryValues = useMemo(
				() =>
					[
						{
							title: 'SUMMARY',
							fields: [
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Hourly Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate
															: proposal?.budget / 18 / 8
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									},
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Daily Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate * 8
															: proposal?.budget / 18
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									},
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Monthly Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate * 8 * 18
															: proposal?.budget
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									}
							]
						},
						{
							title: 'NOTES',
							fields: [
								{
									overrideSizes: isTablet ? [4, 3, 0, 5] : [4, 2, 2, 4],
									name: '"XLife - Bring an Xpert" Prize Bonus',
									value: <Numeric readOnly money value="1000" textAlign="right" />,
									percent: '',
									info: 'Prize bonus for resources referral, if collaboration period is higher than 6 months.'
								}
							]
						},
						{
							bigTitle: 'Candidate specific data for Admission Process',
							fields: [
								formFields?.company && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Company',
									value: <InfoField value={positiveValue(proposal?.companyName)} />
								},
								formFields?.businessUnit && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Business Unit',
									value: (
										<InfoField label="" value={positiveValue(proposal?.businessUnitName) || ''} />
									)
								},
								formFields?.division && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Division',
									value: <InfoField label="" value={positiveValue(proposal?.divisionName) || ''} />
								},
								formFields?.department && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Department',
									value: <InfoField label="" value={positiveValue(proposal?.departmentName) || ''} />
								},
								formFields?.collabStartDate && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Start Date',
									value: (
										<InfoField
											label=""
											value={(proposal?.startDate && parseDateToShow(proposal?.startDate)) || ''}
										/>
									),
									percent: 'End Date',
									info: (
										<InfoField
											label=""
											value={(proposal?.endDate && parseDateToShow(proposal?.endDate)) || ''}
										/>
									)
								},
								formFields?.collabRole && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Role',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.collaborationRoleName) || ''}
										/>
									)
								},
								formFields?.primaryOffice && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Primary Office Location',
									value: (
										<InfoField label="" value={positiveValue(proposal?.primaryOfficeName) || ''} />
									)
								},
								formFields?.workingOffice && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Working Office Location',
									value: (
										<InfoField label="" value={positiveValue(proposal?.workingOfficeName) || ''} />
									)
								},
								(toShow || !isCollabType) && {
									overrideSizes: [4, 8, 0, 0],
									name: 'TSU Exemption ?',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.tsuExemption) ? 'Yes' : 'No'}
										/>
									)
								},
								formFields?.employeeManager && {
									overrideSizes: [4, 2, 6, 0],
									name: 'Employee Manager',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.employeeManagerName) || ''}
										/>
									),
									percent:
										"Who will approve employee's absences. If internal internship (Summer, etc), not applicable."
								},
								formFields?.careerManager && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Manager',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerManagerName) || ''} />
									)
								},
								formFields?.careerName && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Name',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerPositionName) || ''} />
									)
								},
								formFields?.careerJobRole && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Job Role',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerJobRoleName) || ''} />
									)
								},
								formFields?.careerJobRoleLevel && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Job Role Level',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.careerJobRoleLevelName) || ''}
										/>
									)
								},
								!isCollabType &&
									(proposal?.projects?.length > 0
										? proposal?.projects.map((proj: IProposalProject, index: number) => ({
												overrideSizes: index === 0 ? [4, 2, 6, 0] : [4, 8, 0, 0],
												name: index === 0 ? 'Project in timesheet' : '',
												value: <InfoField label="" value={proj.projectName || ''} />,
												percent:
													index === 0
														? 'Project where the employee will work on, to claim on timesheet.'
														: ''
										  }))
										: [
												{
													overrideSizes: [4, 2, 6, 0],
													name: 'Project in timesheet',
													value: '',
													percent:
														'Project where the employee will work on, to claim on timesheet.'
												}
										  ])
							]
						},
						{
							bigTitle: 'Proposal Costs Summary Data',
							fields: [
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Hourly Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate
															: proposal?.budget / 18 / 8
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									},
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Daily Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate * 8
															: proposal?.budget / 18
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									},
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Monthly Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate * 8 * 18
															: proposal?.budget
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									}
							]
						}
					].filter(e => {
						if (proposal?.collaborationType === 'Cost Salary Category' && e.bigTitle) {
							return e.bigTitle !== 'Candidate specific data for Admission Process';
						}
						return true;
					}),
				[proposal, formFields]
			);
		}
		// HR Employee
	} else if (formFields.country == 'HR') {
		if (proposal?.budget === 0) {
			summaryValues = useMemo(
				() =>
					[
						{
							title: 'MONTHLY INCOME',
							fields: [
								formFields?.baseValue && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Base Value',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.baseValue) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								}
							]
						},
						{
							title: 'OTHER BENEFITS',
							fields: [
								...(proposal?.otherBenefitsList?.length > 0
									? proposal?.otherBenefitsList.map((ben: any, index: number) => ({
											overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
											name: index === 0 ? 'Other Benefits' : '',
											value: (
												<Numeric
													readOnly
													money
													value={parseNumber(ben.prizeValue) || '0'}
													textAlign="right"
												/>
											),
											percent: '',
											info: ben.goalDetails || ''
									  }))
									: [
											{
												overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
												name: 'Other Benefits',
												value: <Numeric readOnly money value="0" textAlign="right" />,
												percent: '',
												info: ''
											}
									  ]),
								formFields?.communicationsPlafondValue && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Communications - Internet + Electricity',
									value: (
										<Numeric
											readOnly
											money
											value={
												(proposal?.commsPlafondFlag && proposal?.communicationsPlafond) || '0'
											}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Communications value'
								},
								formFields?.healthInsurance && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Health Insurance',
									value: (
										<Numeric
											readOnly
											money
											value={(proposal?.healthInsuranceCheck && proposal?.healthInsurance) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Estimate value of package used at company level'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Laptop',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.monthlyStandardLaptopCost) || '0'}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.laptopMonthlyValue) || '0'}
											textAlign="right"
										/>
									),
									info: 'Monthly value'
								}
							]
						},
						{
							title: 'DEDUCTIONS ',
							fields: [
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Pension Fund',
									value: (
										<Numeric
											readOnly
											money
											value={
												proposal?.pensionFundTax ? positiveValue(proposal?.pensionFundTax) : '0'
											}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											percentage
											value={
												proposal?.pensionFundTaxPercentage
													? positiveValue(proposal?.pensionFundTaxPercentage)
													: '0'
											}
											textAlign="right"
										/>
									),
									info: ''
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Personal Tax Exemption',
									value: (
										<Numeric
											readOnly
											money
											value={
												proposal?.personalIncomeTaxExemption
													? positiveValue(proposal?.personalIncomeTaxExemption)
													: '0'
											}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Personal Basic Deduction'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Personal income tax base(<' + proposal?.personalIncomeTaxBase + 'EUR)',
									value: (
										<Numeric
											readOnly
											money
											value={
												proposal?.personalIncomeTaxUntilTaxBase
													? positiveValue(proposal?.personalIncomeTaxUntilTaxBase)
													: '0'
											}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											percentage
											value={
												proposal?.personalIncomeTaxPercentageUntilTaxBase
													? positiveValue(proposal?.personalIncomeTaxPercentageUntilTaxBase)
													: '0'
											}
											textAlign="right"
										/>
									),
									info: ''
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Personal income tax base(>' + proposal?.personalIncomeTaxBase + 'EUR)',
									value: (
										<Numeric
											readOnly
											money
											value={
												proposal?.personalIncomeTaxGreaterThanTaxBase
													? positiveValue(proposal?.personalIncomeTaxGreaterThanTaxBase)
													: '0'
											}
											textAlign="right"
										/>
									),
									percent: (
										<Numeric
											readOnly
											percentage
											value={
												proposal?.personalIncomeTaxPercentageGreaterThanTaxBase
													? positiveValue(
															proposal?.personalIncomeTaxPercentageGreaterThanTaxBase
													  )
													: '0'
											}
											textAlign="right"
										/>
									),
									info: ''
								}
							]
						},
						{
							title: 'SUMMARY',
							fields: [
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Annual Gross Value',
									value: (
										<Numeric
											readOnly
											money
											value={proposal?.annualGrossValue || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Includes bonus salary'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Monthly Gross Value',
									value: (
										<Numeric
											readOnly
											money
											value={proposal?.monthlyGrossValue || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Without bonus salary'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Annual Net Value',
									value: (
										<Numeric
											readOnly
											money
											value={proposal?.annualNetValue || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Includes bonus salary'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Monthly Net Value',
									value: (
										<Numeric
											readOnly
											money
											value={proposal?.monthlyNetValue || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: 'Without bonus salary'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Annual Tax Value',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.annualTaxPlusSurtax) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: '12 months'
								},
								{
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Annual Pension Fund',
									value: (
										<Numeric
											readOnly
											money
											value={proposal?.annualPensionFundTax || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: '12 months'
								}
							]
						},
						,
						{
							title: 'NOTES',
							fields: [
								{
									overrideSizes: isTablet ? [4, 3, 0, 5] : [4, 2, 2, 4],
									name: '"XLife - Bring an Xpert" Prize Bonus',
									value: <Numeric readOnly money value="1000" textAlign="right" />,
									percent: '',
									info: 'Prize bonus for resources referral, if collaboration period is higher than 6 months.'
								}
							]
						},
						{
							bigTitle: 'Candidate specific data for Admission Process',
							fields: [
								formFields?.company && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Company',
									value: <InfoField value={positiveValue(proposal?.companyName)} />
								},
								formFields?.businessUnit && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Business Unit',
									value: (
										<InfoField label="" value={positiveValue(proposal?.businessUnitName) || ''} />
									)
								},
								formFields?.division && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Division',
									value: <InfoField label="" value={positiveValue(proposal?.divisionName) || ''} />
								},
								formFields?.department && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Department',
									value: <InfoField label="" value={positiveValue(proposal?.departmentName) || ''} />
								},
								formFields?.contractType && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Contract Type',
									value: (
										<InfoField label="" value={positiveValue(proposal?.contractTypeName) || ''} />
									)
								},
								formFields?.daysOfProbationPeriod && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Days Of Probation Period',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.daysOfProbationPeriod) || ''}
										/>
									)
								},
								formFields?.probationPeriodEndDate && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Probation Period End Date',
									value: (
										<InfoField
											label=""
											value={
												(proposal?.probationPeriodEndDate &&
													parseDateToShow(proposal?.probationPeriodEndDate)) ||
												''
											}
										/>
									)
								},
								formFields?.collabStartDate && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Start Date',
									value: (
										<InfoField
											label=""
											value={(proposal?.startDate && parseDateToShow(proposal?.startDate)) || ''}
										/>
									),
									percent: 'End Date',
									info: (
										<InfoField
											label=""
											value={(proposal?.endDate && parseDateToShow(proposal?.endDate)) || ''}
										/>
									)
								},
								formFields?.collabRole && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Role',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.collaborationRoleName) || ''}
										/>
									)
								},
								formFields?.contractPosition && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Contract Position',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.contractPositionName) || ''}
										/>
									)
								},
								formFields?.primaryOffice && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Primary Office Location',
									value: (
										<InfoField label="" value={positiveValue(proposal?.primaryOfficeName) || ''} />
									)
								},
								formFields?.workingOffice && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Working Office Location',
									value: (
										<InfoField label="" value={positiveValue(proposal?.workingOfficeName) || ''} />
									)
								},

								formFields?.employeeManager && {
									overrideSizes: [4, 2, 6, 0],
									name: 'Employee Manager',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.employeeManagerName) || ''}
										/>
									),
									percent:
										"Who will approve employee's absences. If internal internship (Summer, etc), not applicable."
								},
								formFields?.careerManager && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Manager',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerManagerName) || ''} />
									)
								},
								formFields?.careerName && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Name',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerPositionName) || ''} />
									)
								},
								formFields?.careerJobRole && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Job Role',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerJobRoleName) || ''} />
									)
								},
								!isCollabType &&
									(proposal?.projects?.length > 0
										? proposal?.projects.map((proj: IProposalProject, index: number) => ({
												overrideSizes: index === 0 ? [4, 2, 6, 0] : [4, 8, 0, 0],
												name: index === 0 ? 'Project in timesheet' : '',
												value: <InfoField label="" value={proj.projectName || ''} />,
												percent:
													index === 0
														? 'Project where the employee will work on, to claim on timesheet.'
														: ''
										  }))
										: [
												{
													overrideSizes: [4, 2, 6, 0],
													name: 'Project in timesheet',
													value: '',
													percent:
														'Project where the employee will work on, to claim on timesheet.'
												}
										  ])
							]
						},
						{
							bigTitle: 'Proposal Costs Summary Data',
							fields: [
								{
									overrideSizes: isTablet ? [6, 6, 0, 0] : [4, 2, 2, 4],
									name: 'Annual Cost',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.annualGrossCost) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [6, 6, 0, 0] : [4, 2, 2, 4],
									name: 'Monthly Cost',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.monthlyGrossCost) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								},
								{
									overrideSizes: isTablet ? [6, 6, 0, 0] : [4, 2, 2, 4],
									name: 'Daily Cost',
									value: (
										<Numeric
											readOnly
											money
											value={positiveValue(proposal?.dailyCost) || '0'}
											textAlign="right"
										/>
									),
									percent: '',
									info: ''
								}
							]
						}
					].filter(e => {
						if (proposal?.collaborationType === 'Cost Salary Category' && e.bigTitle) {
							return e.bigTitle !== 'Candidate specific data for Admission Process';
						}
						return true;
					}),
				[proposal, formFields]
			);
		} else {
			// PT Contractor
			summaryValues = useMemo(
				() =>
					[
						{
							title: 'SUMMARY',
							fields: [
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Hourly Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate
															: proposal?.budget / 18 / 8
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									},
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Daily Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate * 8
															: proposal?.budget / 18
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									},
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Monthly Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate * 8 * 18
															: proposal?.budget
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									}
							]
						},
						{
							title: 'NOTES',
							fields: [
								{
									overrideSizes: isTablet ? [4, 3, 0, 5] : [4, 2, 2, 4],
									name: '"XLife - Bring an Xpert" Prize Bonus',
									value: <Numeric readOnly money value="1000" textAlign="right" />,
									percent: '',
									info: 'Prize bonus for resources referral, if collaboration period is higher than 6 months.'
								}
							]
						},
						{
							bigTitle: 'Candidate specific data for Admission Process',
							fields: [
								formFields?.company && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Company',
									value: <InfoField value={positiveValue(proposal?.companyName)} />
								},
								formFields?.businessUnit && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Business Unit',
									value: (
										<InfoField label="" value={positiveValue(proposal?.businessUnitName) || ''} />
									)
								},
								formFields?.division && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Division',
									value: <InfoField label="" value={positiveValue(proposal?.divisionName) || ''} />
								},
								formFields?.department && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Department',
									value: <InfoField label="" value={positiveValue(proposal?.departmentName) || ''} />
								},
								formFields?.collabStartDate && {
									overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
									name: 'Start Date',
									value: (
										<InfoField
											label=""
											value={(proposal?.startDate && parseDateToShow(proposal?.startDate)) || ''}
										/>
									),
									percent: 'End Date',
									info: (
										<InfoField
											label=""
											value={(proposal?.endDate && parseDateToShow(proposal?.endDate)) || ''}
										/>
									)
								},
								formFields?.collabRole && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Role',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.collaborationRoleName) || ''}
										/>
									)
								},
								formFields?.primaryOffice && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Primary Office Location',
									value: (
										<InfoField label="" value={positiveValue(proposal?.primaryOfficeName) || ''} />
									)
								},
								formFields?.workingOffice && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Working Office Location',
									value: (
										<InfoField label="" value={positiveValue(proposal?.workingOfficeName) || ''} />
									)
								},
								(toShow || !isCollabType) && {
									overrideSizes: [4, 8, 0, 0],
									name: 'TSU Exemption ?',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.tsuExemption) ? 'Yes' : 'No'}
										/>
									)
								},
								formFields?.employeeManager && {
									overrideSizes: [4, 2, 6, 0],
									name: 'Employee Manager',
									value: (
										<InfoField
											label=""
											value={positiveValue(proposal?.employeeManagerName) || ''}
										/>
									),
									percent:
										"Who will approve employee's absences. If internal internship (Summer, etc), not applicable."
								},
								formFields?.careerManager && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Manager',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerManagerName) || ''} />
									)
								},
								formFields?.careerName && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Name',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerPositionName) || ''} />
									)
								},
								formFields?.careerJobRole && {
									overrideSizes: [4, 8, 0, 0],
									name: 'Career Job Role',
									value: (
										<InfoField label="" value={positiveValue(proposal?.careerJobRoleName) || ''} />
									)
								},
								!isCollabType &&
									(proposal?.projects?.length > 0
										? proposal?.projects.map((proj: IProposalProject, index: number) => ({
												overrideSizes: index === 0 ? [4, 2, 6, 0] : [4, 8, 0, 0],
												name: index === 0 ? 'Project in timesheet' : '',
												value: <InfoField label="" value={proj.projectName || ''} />,
												percent:
													index === 0
														? 'Project where the employee will work on, to claim on timesheet.'
														: ''
										  }))
										: [
												{
													overrideSizes: [4, 2, 6, 0],
													name: 'Project in timesheet',
													value: '',
													percent:
														'Project where the employee will work on, to claim on timesheet.'
												}
										  ])
							]
						},
						{
							bigTitle: 'Proposal Costs Summary Data',
							fields: [
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Hourly Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate
															: proposal?.budget / 18 / 8
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									},
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Daily Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate * 8
															: proposal?.budget / 18
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									},
								formFields?.hourlyRate &&
									formFields?.monthlyBudget && {
										overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
										name: 'Monthly Contractor Budget',
										value: (
											<Numeric
												readOnly
												money
												value={
													positiveValue(
														proposal?.collaborationType !== 'Collaboration Type'
															? proposal?.hourlyRate * 8 * 18
															: proposal?.budget
													) || '0'
												}
												textAlign="right"
											/>
										),
										percent: '',
										info: ''
									}
							]
						}
					].filter(e => {
						if (proposal?.collaborationType === 'Cost Salary Category' && e.bigTitle) {
							return e.bigTitle !== 'Candidate specific data for Admission Process';
						}
						return true;
					}),
				[proposal, formFields]
			);
		}
	}

	return (
		<Grid container direction="row" justifyContent="flex-start" spacing={2}>
			{summaryValues.map(group => (
				<Fragment key={group.title || group.bigTitle}>
					{group.title ? createTitle(group.title) : createBigTitle(group.bigTitle as string)}
					{group.fields.map((field: any, index: number) => {
						return (
							<Fragment key={`${group.title || group.bigTitle}_${field?.name}_${index}`}>
								{createLine(
									field?.name,
									field?.value,
									field?.percent,
									field?.info,
									field?.overrideSizes
								)}
							</Fragment>
						);
					})}
				</Fragment>
			))}
		</Grid>
	);
};

export default withLayout(SummaryTable);
