import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import { UserActions as Actions, IEmployeeVPNList } from 'typings/store/admin/users';
import { OriginalResponse } from 'lib/handlers/requestTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setUsersError = (payload: OriginalResponse | null): IDispatchType => action(Actions.SET_ERROR, payload);

export const setUsersListVPN = (payload: IEmployeeVPNList | null): IDispatchType =>
	action(Actions.GET_USERS_LIST_VPN, payload);

export const setDeleteUser = (payload: unknown): IDispatchType => action(Actions.SEND_DELETE_USER, payload);

export const setDeleteUserFromDB = (payload: unknown): IDispatchType =>
	action(Actions.SEND_DELETE_USER_FROM_DB, payload);

export const setWelcomeEmail = (payload: unknown): IDispatchType => action(Actions.GET_WELCOME_EMAIL, payload);

export const setClearWelcomeEmail = (): IDispatchType => action(Actions.CLEAR_WELCOME_EMAIL);

export const setPostWelcomeEmail = (payload: unknown): IDispatchType => action(Actions.SEND_WELCOME_EMAIL, payload);

export const setPostUsersListVPN = (payload: unknown): IDispatchType => action(Actions.POST_USERS_LIST_VPN, payload);

export const setUsersAdd = (payload: unknown): IDispatchType => action(Actions.GET_USERS_ADD, payload);

export const setPostUsersAdd = (payload: unknown): IDispatchType => action(Actions.POST_USERS_ADD, payload);

export const setUsersList = (payload: unknown): IDispatchType => action(Actions.GET_USERS_LIST, payload);

export const setUsersListFromDB = (payload: unknown): IDispatchType => action(Actions.GET_USERS_LIST_FROM_DB, payload);

export const setResponseCode = (payload: unknown): IDispatchType => action(Actions.GET_RESPONSE_CODE, payload);

export const setHealthLocations = (payload: unknown): IDispatchType => action(Actions.GET_HEALTH_LOCATIONS, payload);

export const setPostHealthConvocation = (): IDispatchType => action(Actions.GET_HEALTH_LOCATIONS);

export const setPostImpersonateEmail = (payload: unknown): IDispatchType => action(Actions.SEND_WELCOME_EMAIL, payload);
