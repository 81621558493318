/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-plusplus */
import React, { FC, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import { Divider, Grid, InputAdornment } from '@mui/material';
import { Loop } from '@mui/icons-material';

import { Checkbox, DatePicker, InfoField, Input, Numeric, Radio, Select } from 'xpand-ui/forms';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { addDays, addMonths, format } from 'date-fns';
import moment from 'moment';

import withLayout from 'lib/hocs/withLayout';
import { calculateVacationDays } from 'lib/utils/calcFormulasUtils';

import GroupCollapse from '../components/GroupCollapse';
import { CSC, COLLABTYPE, EMPLOYEE, PROPOSAL } from '../constants';
import { useStyles } from './styles';

const withFixedTerm = '20';
const trainee = '80';

const ContractCompany: FC<any> = ({
	type,
	control,
	errors,
	setValue,
	watch,
	selectOptions,
	genPassword,
	initialFormData,
	isContractor,
	resetField,
	formFields
}) => {
	const classes = useStyles();

	const { isCSC, isEmp, isCollabType, isProposal } = useMemo(
		() => ({
			isCSC: type === CSC,
			isEmp: type === EMPLOYEE,
			isCollabType: type === COLLABTYPE,
			isProposal: type === PROPOSAL
		}),
		[type]
	);

	const FormDivider = useMemo(
		() => () =>
			(
				<Grid item {...sizes[12]}>
					<Divider />
				</Grid>
			),
		[]
	);

	const setVacationDays = () => {
		const date = watch('startDate');
		const vacationDays = calculateVacationDays(date);
		setValue('vacationDays', vacationDays, { shouldValidate: true });
	};

	useEffect(() => {
		setVacationDays();
	}, []);

	//Order xtracker employees by alphabetical order
	useEffect(() => {
		if (selectOptions.xtrackerEmployees) {
			selectOptions.xtrackerEmployees = selectOptions.xtrackerEmployees.sort((a, b) => {
				if (a.label.toLowerCase() < b.label.toLowerCase()) {
					return -1;
				}
				if (a.label.toLowerCase() > b.label.toLowerCase()) {
					return 1;
				}
				return 0;
			});
		}
	}, []);

	useEffect(() => {
		let startDate = watch('startDate');
		let daysToAdd = watch('daysOfProbationPeriod');
		if (startDate && daysToAdd) {
			const endDate = addDays(new Date(startDate), daysToAdd - 1);
			setValue('probationPeriodEndDate', moment(endDate).format('YYYY-MM-DD HH:mm:ss').toString());
			setValue('startDateInfo', moment(startDate).format('YYYY-MM-DD HH:mm:ss').toString());
		}
	}, [watch('startDate'), watch('daysOfProbationPeriod')]);

	// set Contract EndDate by fixedTermContractDuration
	useEffect(() => {
		// If fixedTermContractDuration has a value
		if (watch('fixedTermContractDuration')) {
			const startDate = watch('startDate') ? new Date(watch('startDate')) : new Date();
			let endDate = addMonths(new Date(startDate), Number(watch('fixedTermContractDuration')));
			endDate = addDays(endDate, -1);

			const year = endDate.getFullYear();
			const month = String(endDate.getMonth() + 1).padStart(2, '0');
			const day = String(endDate.getDate()).padStart(2, '0');
			const newDate = `${year}-${month}-${day} 00:00:00`;
			setValue('endDate', newDate);
		}
	}, [watch('fixedTermContractDuration')]);

	//Concat employee's first and last name with his id
	const employeesReferral = selectOptions.referralUsers.map(e => ({
		id: e.id,
		label: e.label.split(' ')[0] + ' ' + e.label.split(' ')[e.label.split(' ').length - 1] + ' - ' + e.id
	}));

	const referralType = watch('referralType');
	const contractType = watch('contractType');
	const noTimeOffPolicy = watch('noTimeOffPolicy');

	const cscContractCompany = (
		<>
			<GroupCollapse
				collapse={{
					id: 'contactsCompany',
					label: 'Contract and Company Information'
				}}>
				{' '}
				{formFields?.formFieldsConfig?.contractType && (
					<>
						{isCollabType || isEmp || isProposal ? (
							<>
								<Grid item {...sizes[6]}>
									<Select
										required
										name="contractType"
										label="Contract Type"
										options={selectOptions.contractTypes || []}
										additionalOnChange={() => {
											resetField('customerInContract');
											resetField('endDate');
											resetField('internshipDescription');
											resetField('traineeType');
											resetField('fixedTermContractDuration');
										}}
										control={control}
										errors={errors}
									/>
								</Grid>
							</>
						) : (
							<Grid item {...sizes[6]}>
								<InfoField
									readOnly
									label="Contract Type"
									value={initialFormData?.employeeCurrentContract?.contractTypeAString}
								/>
							</Grid>
						)}
					</>
				)}
				{/* Contract Position */}
				{formFields?.formFieldsConfig?.contractPosition && (
					<Grid item {...sizes[6]}>
						<Select
							required
							name="contractPosition"
							label="Contract Position"
							options={selectOptions.contractPositions || []}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Days of Probation Period */}
				{formFields?.formFieldsConfig?.daysOfProbationPeriod && contractType == 10 && (
					<Grid item {...sizes[6]}>
						<Numeric
							required
							name="daysOfProbationPeriod"
							label="Days of Probation Period"
							minimumValue="0"
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Start Date(Read Only) */}
				{formFields?.formFIeldsConfig?.startDate && isEmp && contractType == 10 && (
					<Grid item {...sizes[6]}>
						<DatePicker
							required
							name="startDateInfo"
							format="DD/MM/YYYY"
							label="Start Date Info"
							disabled
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Probation Period End Date */}
				{formFields?.formFieldsConfig?.probationPeriodEndDate && contractType == 10 && (
					<Grid item {...sizes[6]}>
						<DatePicker
							required
							name="probationPeriodEndDate"
							label="Probation Period End Date"
							format="DD/MM/YYYY"
							disabled
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Customer in Contract */}
				{formFields.formFieldsConfig.customerInContract && (
					<>
						<Grid
							item
							{...sizes[6]}
							className={clsx(classes.animationNone, {
								[classes.show]: contractType === withFixedTerm
							})}>
							<Select
								required
								name="customerInContract"
								label="Customer in Contract"
								options={selectOptions.customers || []}
								control={control}
								errors={errors}
							/>
						</Grid>
					</>
				)}
				{formFields.formFieldsConfig.traineeType && (
					<>
						<Grid
							item
							{...sizes[6]}
							className={clsx(classes.animationNone, {
								[classes.show]: contractType === trainee
							})}>
							<Radio
								required
								row
								name="traineeType"
								label="IEFP Levels"
								options={[
									{ id: 'L3', label: 'L3' },
									{ id: 'L4', label: 'L4' },
									{ id: 'L5', label: 'L5' },
									{ id: 'L6', label: 'L6' },
									{ id: 'L7', label: 'L7' }
								]}
								control={control}
								errors={errors}
							/>
						</Grid>
					</>
				)}
				{formFields.formFieldsConfig.fixedTermContractDuration && (
					<Grid
						item
						{...sizes[6]}
						className={clsx(classes.animation, {
							[classes.show]: contractType === withFixedTerm || contractType === trainee
						})}>
						<Select
							required
							name="fixedTermContractDuration"
							label="Fixed Term Contract Duration"
							options={[
								{ id: '6', label: '6 months' },
								{ id: '9', label: '9 months' },
								{ id: '12', label: '12 months' }
							]}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{formFields.formFieldsConfig.endDate && (
					<Grid
						item
						{...sizes[6]}
						className={clsx(classes.animation, {
							[classes.show]: contractType === withFixedTerm || contractType === trainee
						})}>
						<DatePicker
							required={!formFields.formFieldsConfig.fixedTermContractDuration}
							disabled={formFields.formFieldsConfig.fixedTermContractDuration}
							name="endDate"
							label="End Date"
							helperText="Contract end date"
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{formFields.formFieldsConfig.startDate && (
					<Grid item {...sizes[6]}>
						<InfoField
							readOnly
							label="Start Date"
							value={parseDateToShow(initialFormData?.employeeCurrentContract?.startDate)}
						/>
					</Grid>
				)}
				{formFields.formFieldsConfig.salaryCategory && (
					<Grid item {...sizes[6]}>
						<InfoField
							readOnly
							label="Salary Category"
							value={initialFormData?.employeeCurrentContract?.salaryCategory$_identifier}
						/>
					</Grid>
				)}
				{initialFormData?.employeeCurrentContract?.validUntil && formFields.formFieldsConfig.endDate && (
					<Grid item {...sizes[6]}>
						<InfoField
							readOnly
							label="End Date"
							value={parseDateToShow(initialFormData?.employeeCurrentContract?.validUntil)}
						/>
					</Grid>
				)}
				<FormDivider />
				{/* Primary Office */}
				{formFields.formFieldsConfig.primaryOffice && (
					<Grid item {...sizes[6]}>
						<Select
							required
							name="primaryOffice"
							label="Primary Office"
							helperText="Office where employee will have his/her Social Security registration."
							additionalOnChange={({ value }: { value: string }) => {
								setValue('workingOffice', value);
							}}
							options={selectOptions.primaryWorkPlaces || []}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Working Office */}
				{formFields.formFieldsConfig.workingOffice && (
					<Grid item {...sizes[6]}>
						<Select
							required
							name="workingOffice"
							label="Working Office"
							helperText="Office where employee will work or the closest one of his/her working place."
							options={selectOptions.workPlaces || []}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Employee Manager */}
				{formFields.formFieldsConfig.employeeManager && (
					<Grid item {...sizes[6]}>
						<Select
							required
							name="employeeManager"
							label="Employee Manager"
							helperText="Person that will approve employee's planned absences (Vacations, Parental Leaves, etc)."
							options={selectOptions.xtrackerEmployees || []}
							control={control}
							errors={errors}
							hasSearchOption
						/>
					</Grid>
				)}
				{/* Holiday Type */}
				{formFields.formFieldsConfig.holidayType && (
					<Grid item {...sizes[6]}>
						<Select
							required
							name="holidayType"
							label="Holiday Type"
							helperText="Holidays that will be available to Employee's absence map that is directly related to his/her working place."
							options={selectOptions.holidayTypes || []}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				<FormDivider />
				{/* Referral Type */}
				{formFields.formFieldsConfig.referralType && (
					<Grid item {...sizes[6]}>
						<Select
							required
							name="referralType"
							label="Referral Type"
							options={selectOptions.referralTypes || []}
							additionalOnChange={() => resetField('reference')}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Referral ID */}
				{formFields.formFieldsConfig.referralId && (
					<Grid item {...sizes[6]}>
						<Input name="referralId" label="Referral ID" control={control} errors={errors} />
					</Grid>
				)}
				{/* Referenced By - SELECT VARIANT */}
				{formFields.formFieldsConfig.referralId && (
					<Grid
						item
						{...sizes[6]}
						className={clsx(
							classes.animationNone,
							{
								[classes.show]: referralType === 'Employee'
							},
							classes.referenceSelect
						)}>
						<Select
							required
							name="reference"
							label="Referenced By"
							options={employeesReferral || []}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
			</GroupCollapse>

			{formFields.formFieldsConfig.xtrackerInformation && (
				<GroupCollapse
					collapse={{
						id: 'xtrackerInfo',
						label: 'Xtracker Information'
					}}>
					{/* No Time Off Policy? */}
					{formFields.formFieldsConfig.noTimeoffPolicy && (
						<Grid item {...sizes[6]}>
							<Checkbox
								color="primary"
								name="noTimeOffPolicy"
								label="NO TIME OFF POLICY"
								additionalOnChange={(val: boolean) => {
									if (!val) {
										setVacationDays();
									} else {
										resetField('vacationDays');
									}

									resetField('timeOffApprovalType');
									resetField('timeoffPolicy');
								}}
								control={control}
								errors={errors}
							/>
						</Grid>
					)}
					{!noTimeOffPolicy && (
						<>
							{/* Nr Of Vacation Days */}
							{formFields.formFieldsConfig.nrVacationDays && (
								<Grid item {...sizes[6]}>
									<Numeric
										required
										name="vacationDays"
										label="Nr Of Vacation Days"
										placeholder="Default: 22 Vacation Days"
										minimumValue="0"
										maximumValue="25"
										control={control}
										errors={errors}
									/>
								</Grid>
							)}

							{/* Time Off Approval Type */}
							{formFields.formFieldsConfig.timeoffApprovalType && (
								<Grid item {...sizes[6]}>
									<Select
										required
										name="timeOffApprovalType"
										label="Time Off Approval Type"
										options={selectOptions.timeOffApprovalTypes || []}
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{/* Time Off Policy */}
							{formFields.formFieldsConfig.timeoffPolicy && (
								<Grid item {...sizes[6]}>
									<Select
										required
										name="timeoffPolicy"
										label="Time Off Policy"
										options={selectOptions.timeOffPolicies || []}
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
						</>
					)}
				</GroupCollapse>
			)}
			{formFields.formFieldsConfig.otherInformation && (
				<GroupCollapse
					collapse={{
						id: 'otherInfo',
						label: 'Other Information'
					}}>
					{formFields.formFieldsConfig.contactListLevel && (
						<Grid item {...sizes[6]}>
							<Select
								required
								name="contactListLevel"
								label="Contact List Level"
								options={selectOptions.contactListLevels || []}
								control={control}
								errors={errors}
							/>
						</Grid>
					)}
					{/* Notes */}
					{formFields.formFieldsConfig.notes && (
						<Grid item {...sizes[12]}>
							<Input
								name="notes"
								label="Notes"
								multiline
								placeholder="Notes"
								minRows={3}
								maxRows={8}
								control={control}
								errors={errors}
							/>
						</Grid>
					)}
					{/* Password */}
					{formFields.formFieldsConfig.password && (
						<Grid item {...sizes[6]}>
							<Input
								required
								name="password"
								label="Password"
								helperText="Initial password for this user"
								control={control}
								errors={errors}
								InputProps={{
									endAdornment: (
										<InputAdornment
											className={classes.genPass}
											onClick={genPassword}
											position="end">
											<Loop />
										</InputAdornment>
									)
								}}
							/>
						</Grid>
					)}
				</GroupCollapse>
			)}
		</>
	);

	return (
		<Grid
			container
			justifyContent="center"
			spacing={2}
			style={{
				paddingBottom: '20px'
			}}>
			{cscContractCompany}
		</Grid>
	);
};

export default withLayout(ContractCompany);
