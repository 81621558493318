import { createReducer } from 'typesafe-actions';
import { CompanyActions as Actions, CompanyState } from 'typings/store/companyTypes';
import CompanyLogo from '../../assets/images/logo_Xpand_IT_white.png';
import BigCompanyLogo from '../../assets/images/xpandit-logo-transparent-full.png';
import CompanyChart from '../../assets/images/myCompany/orgChart.png';
import BalwurkLogo from '../../assets/images/balwurk-logo-grey-02.png';
import BalwurkBigLogo from '../../assets/images/balwurk-logo-blue-01.png';
import BalwurkCompanyChart from '../../assets/images/myCompany/orgChart_balwurk.png';
import { legacy_createStore as createStore } from 'redux';

const INITIAL_STATE: CompanyState = {
	//RESPONSABLE KEYS TO DYNAMIZE LOGOS AND TEXT OF DIFFERENT COMPANIES
	companyLogo: CompanyLogo,
	bigCompanyLogo: BigCompanyLogo,
	smallCompanyLogo: 'XP',
	companyChart: CompanyChart,
	companyText: 'Xpand IT',
	companyDomain: 'xpand-it.com',
	companyAbbreviation: 'XP',
	companySite: 'https://www.xpand-it.com',

	//!URLs Page Benefits
	urlBenefitis: 'https://apps.xpand-it.com/confluence/display/XPHR/Benefits',

	//!URLs Page MyProfile

	//Other Information link
	urlServiceDeskOIProfile: 'https://apps.xpand-it.com/jirasd/servicedesk/customer/portal/2/create/63',

	//Consent Information
	urlServiceDeskCIProfile: 'https://apps.xpand-it.com/jirasd/servicedesk/customer/portal/7/create/104',
	urlConfluenceProfile:
		'https://apps.xpand-it.com/confluence/pages/viewpage.action?pageId=226213065#information_security',

	//!URLs Booking
	urlServiceBooking: 'https://apps.xpand-it.com/confluence/display/XPOL/DESK+BOOKING+-+OFFICE+FLOOR+PLAN',

	//!URLs MyCareer
	urlServiceDeskCareer: 'https://apps.xpand-it.com/jirasd/servicedesk/customer/portal/3/create/152',
	urlConfluenceCareer: 'https://apps.xpand-it.com/confluence/pages/viewpage.action?pageId=305296311',

	//!URLs New Project
	urlNameStandardProject:
		'https://apps.xpand-it.com/confluence/pages/viewpage.action?spaceKey=XPAGILEV2&title=Project+Naming+Standard',
	urlConfluenceProject: 'https://apps.xpand-it.com/confluence/display/OHS/New+Project+-+XPECP',

	//!yupSchema NewProject
	proposalReferenceAllowedIDS: [
		'AFCF2FE02AB84D7FB37A783A22D2DDD9',
		'E6A945A0742C4DCAB96FEB2E6AEEDCCA',
		'846DE36CB0B14875AC250C04F658321D'
	],
	defaultCompanyKey: 'PT'
};

const CompanyReducer = createReducer(INITIAL_STATE, {
	//ACTIONS RESPONSABLE FOR DYNAMIZING THE CP TO DIFFERENT COMPANIE

	[Actions.COMPANY_INFORMATION]: () => ({
		companyLogo: BalwurkLogo,
		companyText: 'Balwurk',
		bigCompanyLogo: BalwurkBigLogo,
		smallCompanyLogo: 'BWK',
		companyDomain: 'balwurk.com',
		companyAbbreviation: 'BWK',
		companySite: 'https://www.balwurk.com',
		urlConfluenceProfile: null,
		urlServiceDeskCIProfile: null,
		urlServiceDeskOIProfile: null,
		urlBenefitis: null,
		urlConfluenceCareer: null,
		urlServiceDeskCareer: null,
		urlServiceBooking: null,
		urlNameStandardProject: null,
		companyChart: BalwurkCompanyChart,
		urlConfluenceProject: null,
		//!yupSchema NewProject
		proposalReferenceAllowedIDS: [
			'AFCF2FE02AB84D7FB37A783A22D2DDD9',
			'E6A945A0742C4DCAB96FEB2E6AEEDCCA',
			'846DE36CB0B14875AC250C04F658321D'
		],
		defaultCompanyKey: 'BW'
	})
});

export const store = createStore(CompanyReducer);

export default CompanyReducer;
