import React, { useState, useEffect, useMemo } from 'react';

import clsx from 'clsx';
import { LoadingOverlay, Table, Dialog } from 'xpand-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, InfoField, Radio, Select } from 'xpand-ui/forms';
import { Numeric } from 'xpand-ui/forms';

import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { Grid } from '@mui/material';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { START_API_PATH } from 'lib/utils/constants';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { IPostRenewContract, IRenewContractRow } from 'typings/store/admin/employeeSalaryCategories';
import { useStyles } from './styles';
//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import { ContractManagementProps } from '.';
import { getLSField } from 'lib/utils/cookies';
import { addDays } from 'date-fns';
import moment from 'moment';

const withFixedTerm = '20';
const trainee = '80';

const ContractManagement: React.FC<ContractManagementProps> = ({
	employeeSalary,
	filters,
	getContractsList,
	downloadDocument,
	getRenewContract,
	submitRenewContractPost,
	clearCscError,
	setContractManagementPageFilter
}) => {
	const classes = useStyles();

	const { contractsList, renewContract, loading, error } = employeeSalary;
	const { contractManagementFilter } = filters;
	const [renewContractModal, setRenewContractModal] = useState<IRenewContractRow | null>(null);

	const [searchValue, setSearchValue] = useState(contractManagementFilter);

	useEffect(() => {
		setContractManagementPageFilter(searchValue);
	}, [searchValue]);

	useEffect(() => {
		if (!contractsList) {
			getContractsList();
		}
	}, [contractsList]);

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<IRenewContractRow>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				isSortable: false,
				format: ({ username }) => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${username}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{ label: 'User', id: 'username', accentColumn: true },
			{ label: 'Name', id: 'name', accentColumn: true },
			{ label: 'Contract Type', id: 'contractType', width: '17%', maxWidth: '17%', accentColumn: true },
			{
				label: 'Contract Start Date',
				type: 'date',
				id: 'contractStartDate',
				format: ({ contractStartDate }) => (contractStartDate ? parseDateToShow(contractStartDate) : '')
			},
			{
				label: 'Contract Valid Until',
				id: 'contractValidUntil',
				maxWidth: '10%',
				format: ({ contractValidUntil }) => (contractValidUntil ? parseDateToShow(contractValidUntil) : '')
			}
		],
		[]
	);

	const tableData: IRenewContractRow[] | null = useMemo(
		() =>
			contractsList &&
			contractsList?.data?.employees?.map((e: IRenewContractRow) => ({
				...e,
				username: e.username || '',
				name: e.name || '',
				contractType: (e.summaryData && JSON.parse(e.summaryData)?.contractType) || '',
				contractStartDate: (e.summaryData && JSON.parse(e.summaryData)?.contractStartDate) || '',
				contractStartDateFormated:
					(e.summaryData && parseDateToShow(JSON.parse(e.summaryData)?.contractStartDate)) || '',
				contractValidUntil: (e.summaryData && JSON.parse(e.summaryData)?.contractEndDate) || '',
				contractValidUntilFormatted:
					(e.summaryData && parseDateToShow(JSON.parse(e.summaryData)?.contractEndDate)) || '',
				customerInContract: (e.summaryData && JSON.parse(e.summaryData)?.reportsTo) || ''
			})),
		[contractsList]
	);

	const downloadTest = (username: string, exportType: string) => {
		const payload = { exportType };
		downloadDocument(username, payload);
	};

	const {
		handleSubmit,
		control,
		reset,
		watch,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});
	const contractType = watch('contractType');

	useEffect(() => {
		let startDate = watch('contractStartDate');
		let daysToAdd = watch('daysProbationPeriod');
		if (startDate && daysToAdd) {
			const endDate = addDays(new Date(startDate), Number(daysToAdd) - 1);
			setValue('probationPeriodEndDate', moment(endDate).format('YYYY-MM-DD HH:mm:ss').toString());
		}
	}, [watch('contractStartDate'), watch('daysProbationPeriod')]);

	const tableActions: ITableAction<IRenewContractRow>[] = useMemo(
		() => [
			{
				id: 'menu',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'generateDocument',
						label: 'Generate Document',
						onClick: (row: IRenewContractRow) => {
							downloadTest(row.username, 'contract');
							downloadTest(row.username, 'workExemption');
						},
						disabled: () => (getLSField('impersonate_userInfo') ? true : false)
					},
					{
						id: 'renewContract',
						label: 'Renew Contract',
						render: row => row?.contractType === 'Trainee' || row.contractType === 'With Fixed-Term',
						onClick: (row: IRenewContractRow) => {
							reset();
							getRenewContract(row.username);
							switch (row.contractType) {
								case 'Without Term':
									setValue('contractType', '10');
									break;
								case 'With Fixed-Term':
									setValue('contractType', '20');
									break;
								case 'Uncertain Term':
									setValue('contractType', '30');
									break;
								case 'Trainee':
									setValue('contractType', '80');
									break;
								default:
									setValue('contractType', '');
							}
							setRenewContractModal(row);
						}
					}
				]
			}
		],
		[contractsList]
	);

	const onSubmitTest = () => {
		const payload: IPostRenewContract = watch();
		submitRenewContractPost(renewContractModal?.username as string, payload);
		setRenewContractModal(null);
		reset();
	};

	const contractTypeWatch = watch('contractType');

	const contractTypeOptions = renewContract?.contractTypes.map(row => ({
		id: row.id,
		label: row.name
	}));

	const contractPositionsOptions = renewContract?.company?.contractPositions?.map(row => ({
		id: row.id,
		label: row.name
	}));

	const customerInContractOptions = renewContract?.company?.customers?.map(row => ({
		id: row.id,
		label: row.name
	}));

	if (error) return handleErrorPage(error, clearCscError);
	const isLoading = contractsList === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="desc"
				handleSearch={{ searchValue, setSearchValue }}
			/>
			<Dialog
				title={`Renew ${renewContractModal?.username}'s Contract`}
				scroll="body"
				modal={{
					open: Boolean(renewContractModal),
					handleClose: (reason: string) => {
						if (reason !== 'backdropClick') {
							setRenewContractModal(null);
						}
					},
					content: (
						<>
							{loading && <LoadingOverlay />}
							<form id="form-renew-contract" onSubmit={handleSubmit(onSubmitTest)}>
								<Grid item {...sizes[12]} style={{ display: 'flex', paddingBottom: '12px' }}>
									<Grid item {...sizes[6]}>
										<InfoField name="Company" label="Company" value={renewContract?.company.name} />
									</Grid>
								</Grid>

								<Grid item {...sizes[12]} style={{ paddingBottom: '12px' }}>
									{/* Contract Type */}
									<Grid item {...sizes[6]} style={{ paddingBottom: '12px' }}>
										<Select
											required
											name="contractType"
											label="Contract Type"
											additionalOnChange={() => {
												setValue('customerInContract', '');
												setValue('contractEndDate', '');
												setValue('traineeType', '');
											}}
											options={contractTypeOptions || []}
											control={control}
											errors={errors}
										/>
									</Grid>
									{/* Contract Position */}
									<Grid item {...sizes[6]} style={{ paddingBottom: '12px' }}>
										{' '}
										<Select
											required
											name="contractPosition"
											label="Contract Position"
											options={contractPositionsOptions || []}
											control={control}
											errors={errors}
										/>
									</Grid>
								</Grid>

								<Grid item {...sizes[12]} style={{ paddingBottom: '12px' }}>
									{/* Start Date */}
									<Grid item {...sizes[6]} style={{ paddingBottom: '12px' }}>
										<DatePicker
											name="contractStartDate"
											label="Contract Start Date"
											control={control}
											errors={errors}
											required
										/>
									</Grid>
									{/* End Date */}
									<Grid
										item
										{...sizes[6]}
										className={clsx(classes.animation, {
											[classes.show]:
												(contractTypeWatch as unknown as string) === withFixedTerm ||
												(contractTypeWatch as unknown as string) === trainee
										})}>
										<DatePicker
											name="contractEndDate"
											label="Contract End Date"
											control={control}
											errors={errors}
											required
										/>
									</Grid>
									{/* Days of Probation Period */}
									{contractType == '10' && (
										<Grid item {...sizes[6]} style={{ paddingBottom: '12px' }}>
											<Numeric
												name="daysProbationPeriod"
												label="Days of Probation Period"
												required
												control={control}
												errors={errors}
											/>
										</Grid>
									)}
									{/* Probation Period End Date */}
									{contractType == '10' && (
										<Grid item {...sizes[6]} style={{ paddingBottom: '12px' }}>
											<DatePicker
												disabled={true}
												name="probationPeriodEndDate"
												label="Probation Period End Date"
												control={control}
												errors={errors}
												required
											/>
										</Grid>
									)}
								</Grid>

								<Grid
									item
									{...sizes[12]}
									className={clsx(classes.animationNone, {
										[classes.show]: (contractTypeWatch as unknown as string) === withFixedTerm
									})}>
									{/* Customer in Contract */}
									<Select
										required
										name="customerInContract"
										label="Customer in Contract"
										options={customerInContractOptions || []}
										control={control}
										errors={errors}
									/>
								</Grid>

								<Grid
									item
									{...sizes[12]}
									className={clsx(classes.animationNone, {
										[classes.show]: (contractTypeWatch as unknown as string) === trainee
									})}>
									<Radio
										required
										row
										name="traineeType"
										label="IEFP Levels"
										options={[
											{ id: 'L3', label: 'L3' },
											{ id: 'L4', label: 'L4' },
											{ id: 'L5', label: 'L5' },
											{ id: 'L6', label: 'L6' },
											{ id: 'L7', label: 'L7' }
										]}
										control={control}
										errors={errors}
									/>
								</Grid>
							</form>
						</>
					)
				}}
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => {
							setRenewContractModal(null);
							reset();
						}
					},
					{
						id: 'submit',
						label: 'Submit',
						onClick: () => ({}),
						type: 'submit',
						form: 'form-renew-contract',
						variant: 'contained',
						disabled: getLSField('impersonate_userInfo')
					}
				]}
			/>
		</>
	);
};

export default withLayout(ContractManagement);
