/* eslint-disable @typescript-eslint/no-explicit-any, indent, react/no-array-index-key */
import React, { FC, useState, useMemo, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import withLayout from 'lib/hocs/withLayout';

import { Grid, Typography } from '@mui/material';

import { parseDateToShow } from 'xpand-ui/utils/dates';
import { Numeric, InfoField } from 'xpand-ui/forms';
import { LoadingOverlay } from 'xpand-ui/core';
import { sizes } from 'xpand-ui/utils/handlers';

import { IChoosableBaseInfo } from 'typings/store/generalTypes';
import { IPrizeGoal, IProposalProject } from 'typings/store/admin/proposals';

import { useStyles } from './styles';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';

const EmployeeDataTable: FC<any> = ({ employeeData, formFields }) => {
	const { width } = useWindowDimensions();
	const isTablet = useMemo(() => width <= 900, [width]); // default is 900 tablet
	const isMobile = useMemo(() => width <= 600, [width]); // default is 600 mobile

	const classes = useStyles();

	const positiveValue = (val: unknown): string => ((Number(val) <= 100 && Number(val)) >= 0 ? `${val}` : '0');

	const createLine = useMemo(
		() =>
			(
				name?: string,
				value?: string | number,
				percent?: string | number,
				info?: string | number,
				overrideSizes = [4, 1, 1, 6]
			) =>
				(
					<>
						{overrideSizes[0] !== 0 && (
							<Grid item xs={overrideSizes[0]}>
								{name || ''}
							</Grid>
						)}
						{overrideSizes[1] !== 0 && (
							<Grid item xs={overrideSizes[1]}>
								{value || ''}
							</Grid>
						)}
						{overrideSizes[2] !== 0 && (
							<Grid item xs={overrideSizes[2]}>
								{percent || ''}
							</Grid>
						)}
						{overrideSizes[3] !== 0 && (
							<Grid item xs={overrideSizes[3]} style={!isMobile ? { paddingLeft: '30px' } : {}}>
								{info || ''}
							</Grid>
						)}
					</>
				),
		[]
	);

	const createTitle = useMemo(
		() => (name: string) =>
			(
				<>
					<Grid item {...sizes[12]} className={classes.title}>
						<Typography variant="h3" gutterBottom>
							{name}
						</Typography>
					</Grid>
				</>
			),
		[]
	);

	const createBigTitle = useMemo(
		() =>
			(name: string, removeBottomPadding = false) =>
				(
					<>
						<Grid
							item
							{...sizes[12]}
							className={clsx(classes.title, { [classes.removeBottomPadding]: removeBottomPadding })}>
							<Typography variant="h3" gutterBottom className={classes.bigTitle}>
								{name}
							</Typography>
						</Grid>
					</>
				),
		[]
	);

	const getPrizeGoals = () => {
		const emptyGoals = (
			name: string,
			values: { money: string | number; percent: string | number } = { money: '0', percent: '0' }
		) => ({
			overrideSizes: isTablet ? [4, 4, 4, 0] : [6, 3, 3, 0],
			name,
			value: <Numeric readOnly percentage value={values.percent} />,
			percent: <Numeric readOnly money value={values.money} />,
			info: '',
			show: formFields?.formFieldsConfig?.country != 'HR'
		});

		if (
			typeof employeeData?.proposal?.prizeBonusGoals === 'undefined' ||
			employeeData?.proposal?.prizeBonusGoals.length === 0
		)
			return employeeData?.prizeBonusGoalTypes?.map((type: IChoosableBaseInfo) =>
				emptyGoals(type.name as string)
			);

		return employeeData?.prizeBonusGoalTypes?.map((type: IChoosableBaseInfo) => {
			const payload = employeeData?.proposal?.prizeBonusGoals.filter((e: IPrizeGoal) => e.goalTypeId === type.id);
			// eslint-disable-next-line no-return-assign, no-param-reassign
			const totalPrizePercent: number = payload.reduce((total: number, current: IPrizeGoal) => {
				// eslint-disable-next-line no-param-reassign
				const newTotal = total + Number(current.prizePercentage);
				return newTotal;
			}, 0);

			if (totalPrizePercent === 0) return emptyGoals(type.name as string);

			return emptyGoals(type.name as string, {
				money: parseFloat(`${totalPrizePercent / 100}`) * employeeData?.proposal?.prizeBonus,
				percent: totalPrizePercent
			});
		});
	};

	const [employeeDataValues, setEmployeeDataValues] = useState<any>([]);

	useEffect(() => {
		let newEmployeeDataValues;
		if (employeeData?.proposal?.budget === 0) {
			newEmployeeDataValues = [
				{
					bigTitle: 'General',
					show: true,
					fields: [
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Full Name',
							value: <InfoField readOnly value={employeeData?.proposal?.name} />,
							show: formFields?.formFieldsConfig?.fullName
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Username',
							value: <InfoField readOnly value={employeeData?.proposal?.username} />,
							show: formFields?.formFieldsConfig?.username
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Email',
							value: <InfoField readOnly value={employeeData?.proposal?.email} />,
							show: formFields?.formFieldsConfig?.email
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Start Date',
							value: <InfoField readOnly value={parseDateToShow(employeeData?.proposal?.startDate)} />,
							show: formFields?.formFieldsConfig?.startDate
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Company',
							value: <InfoField readOnly value={employeeData?.summary?.companyName} />,
							show: formFields?.formFieldsConfig?.company
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Business Unit',
							value: <InfoField readOnly value={employeeData?.summary?.businessUnitName} />,
							show: formFields?.formFieldsConfig?.businessUnit
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Division',
							value: <InfoField readOnly value={employeeData?.summary?.divisionName} />,
							show: formFields?.formFieldsConfig?.division
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Department',
							value: <InfoField readOnly value={employeeData?.summary?.departmentName} />,
							show: formFields?.formFieldsConfig?.department
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Role',
							value: <InfoField readOnly value={employeeData?.summary?.collaborationRoleName} />,
							show: formFields?.formFieldsConfig?.careerJobRole
						}
					]
				},
				{
					bigTitle: 'Career and Financial',
					removeBottomPadding: true,
					show: true,
					fields: []
				},
				{
					title: 'Career Information',
					show: true,
					fields: [
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Career Name',
							value: <InfoField readOnly value={employeeData?.summary?.careerPositionName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.careerName
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Job Role',
							value: <InfoField readOnly value={employeeData?.summary?.careerJobRoleName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.careerJobRole
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Level',
							value: (
								<InfoField readOnly value={employeeData?.summary?.careerJobRoleLevelName || 'N/A'} />
							),
							show: formFields?.formFieldsConfig?.careerJobRoleLevel
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Career Manager',
							value: <InfoField readOnly value={employeeData?.summary?.careerManagerName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.careerManager
						}
					]
				},
				{
					title: 'General Amounts',
					show: true,
					fields: [
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Base Value',
							value: <Numeric readOnly money value={employeeData?.proposal?.baseValue || '0'} />,
							show: formFields?.formFieldsConfig?.baseValue
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Work Schedule Exemption',
							value: <Numeric readOnly money value={employeeData?.proposal?.ihtValue || '0'} />,
							percent: (
								<Numeric readOnly percentage value={employeeData?.proposal?.ihtPercentage || '0'} />
							),
							info: '',
							show: formFields?.formFieldsConfig?.workScheduleExemptionValue
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Christmas Allowance Twelfth',
							value: (
								<Numeric
									readOnly
									money
									value={
										(employeeData?.proposal?.hasTwelfths &&
											employeeData?.proposal?.christhmasTwelfth) ||
										'0'
									}
								/>
							),
							percent: '',
							info: '(Base Value + WSE) / 12',
							show: formFields?.formFieldsConfig?.christmasTwelfth
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Vacations Allowance Twelfth',
							value: (
								<Numeric
									readOnly
									money
									value={
										(employeeData?.proposal?.hasTwelfths &&
											employeeData?.proposal?.vacationsTwelfth) ||
										'0'
									}
								/>
							),
							percent: '',
							info: '(Base Value + WSE) / 12',
							show: formFields?.formFieldsConfig?.vacationsTwelfth
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Food Allowance',
							value: (
								<Numeric
									readOnly
									money
									value={
										employeeData?.proposal?.foodAllowance *
											employeeData?.proposal?.foodAllowanceDays || '0'
									}
								/>
							),
							percent: <Numeric readOnly money value={employeeData?.proposal?.foodAllowance || '0'} />,
							info: '€/day',
							show: formFields?.formFieldsConfig?.foodAllowance
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'National Allowance',
							value: (
								<Numeric
									readOnly
									money
									value={
										employeeData?.proposal?.nationalAllowance *
											employeeData?.proposal?.nationalAllowanceDays || '0'
									}
								/>
							),
							percent: (
								<Numeric readOnly money value={employeeData?.proposal?.nationalAllowance || '0'} />
							),
							info: '€/day',
							show: formFields?.formFieldsConfig?.nationalAllowance
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'International Allowance',
							value: (
								<Numeric
									readOnly
									money
									value={
										employeeData?.proposal?.internationalAllowance *
											employeeData?.proposal?.internationalAllowanceDays || '0'
									}
								/>
							),
							percent: (
								<Numeric readOnly money value={employeeData?.proposal?.internationalAllowance || '0'} />
							),
							info: '€/day',
							show: formFields?.formFieldsConfig?.internationalAllowance
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Travel Pass',
							value: <Numeric readOnly money value={employeeData?.proposal?.travelPass || '0'} />,
							show: formFields?.formFieldsConfig?.travelPass
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Nursery Check',
							value: <Numeric readOnly money value={employeeData?.proposal?.nurseryCheck || '0'} />,
							show: formFields?.formFieldsConfig?.nurseryCheck
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Other Expenses',
							value: <Numeric readOnly money value={employeeData?.summary?.otherExpenses || '0'} />,
							percent: '',
							info: 'Monthly, 12 months of the year, in the form of KMs or other',
							show: formFields?.formFieldsConfig?.otherExpenses
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Life Insurance',
							value: <Numeric readOnly money value={employeeData?.summary?.lifeInsuranceValue || '0'} />,
							show: formFields?.formFieldsConfig?.lifeInsurance
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Annual Prize Bonus (maximum value)',
							value: <Numeric readOnly money value={employeeData?.summary?.annualPrizeValue || '0'} />,
							show: formFields?.formFieldsConfig?.maximumPrizeBonus
						}
					]
				},
				{
					title: 'Prize Goals',
					show: formFields?.formFieldsConfig?.country != 'HR',
					fields: getPrizeGoals() || []
				},
				{
					bigTitle: 'Other benefits',
					show: true,
					fields: [
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Mobile Phone',
							value: (
								<Numeric
									readOnly
									money
									value={
										(employeeData?.proposal?.commsPlafondFlag &&
											employeeData?.proposal?.commsPlafond) ||
										'0'
									}
								/>
							),
							percent: '',
							info: 'Communications value',
							show: formFields?.formFieldsConfig?.communicationsPlafond
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Health Insurance',
							value: (
								<Numeric
									readOnly
									money
									value={
										(employeeData?.proposal?.healthInsuranceCheck &&
											employeeData?.proposal?.healthInsuranceValue) ||
										'0'
									}
								/>
							),
							percent: '',
							info: 'Estimate value of package used at company level',
							show: formFields?.formFieldsConfig?.healthInsurance
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Number of Descendents',
							value: <Numeric readOnly value={employeeData?.proposal?.numberMembersDep || '0'} />,
							show: formFields?.formFieldsConfig?.numberDependents
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Work Insurance Estimate Cost',
							value: (
								<Numeric
									readOnly
									money
									value={employeeData?.summary?.monthlyWorkInsuranceEstimateCost || '0'}
								/>
							),
							percent: (
								<Numeric
									readOnly
									percentage
									value={employeeData?.summary?.monthlyStandardWorkInsuranceCostPercentage || '0'}
								/>
							),
							info: '',
							show: formFields?.formFieldsConfig?.country != 'HR'
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Laptop',
							value: (
								<Numeric
									readOnly
									money
									value={employeeData?.summary?.monthlyStandardLaptopCost || '0'}
								/>
							),
							percent: (
								<Numeric readOnly money value={employeeData?.summary?.laptopMonthlyValue || '0'} />
							),
							info: 'Monthly value',
							show: true
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Other Benefits',
							value: (
								<Numeric
									readOnly
									money
									value={
										employeeData?.summary?.otherBenefits1Value +
											employeeData?.summary?.otherBenefits2Value +
											employeeData?.summary?.otherBenefits3Value || '0'
									}
								/>
							),
							show: formFields?.formFieldsConfig?.hasOtherBenefits
						}
						// ...(employeeData?.proposal?.otherBenefitsList?.length > 0
						// 	? employeeData?.proposal?.otherBenefitsList.map((ben, index) => {
						// 			return {
						// 				name: index === 0 ? 'Other Benefits' : '',
						// 				value: (
						// 					<Numeric
						// 						readOnly
						// 						money
						// 						value={parseNumber(ben.prizeValue) || '0'}
						// 						textAlign="right"
						// 					/>
						// 				),
						// 				percent: '',
						// 				info: ben.goalDetails || ''
						// 			};
						// 	  })
						// 	: [
						// 			{
						// 				name: 'Other Benefits',
						// 				value: <Numeric readOnly money value="0" textAlign="right" />,
						// 				percent: '',
						// 				info: ''
						// 			}
						// 	  ])
					]
				},
				{
					bigTitle: 'Contract & Company',
					show: true,
					fields: [
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Contract Type',
							value: <InfoField readOnly value={employeeData?.summary?.contractTypeName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.contractType
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Contract Position',
							value: <InfoField readOnly value={employeeData?.summary?.contractPositionName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.contractPosition
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Primary Office',
							value: <InfoField readOnly value={employeeData?.summary?.primaryOfficeName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.primaryOffice
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Working Office',
							value: <InfoField readOnly value={employeeData?.summary?.workingOfficeName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.workingOffice
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Employee Manager',
							value: <InfoField readOnly value={employeeData?.summary?.employeeManagerName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.employeeManager
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Holiday Type',
							value: <InfoField readOnly value={employeeData?.summary?.holidayTypeName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.holidayType
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Referral Type',
							value: <InfoField readOnly value={employeeData?.proposal?.referralType || 'N/A'} />,
							show: formFields?.formFieldsConfig?.referralType
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Referenced By',
							value: <InfoField readOnly value={employeeData?.proposal?.reference || 'N/A'} />,
							show: true
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Referral Id',
							value: <InfoField readOnly value={employeeData?.proposal?.referralId || 'N/A'} />,
							show: formFields?.formFieldsConfig?.referralId
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'TSU Exemption',
							value: (
								<InfoField
									readOnly
									value={employeeData?.proposal?.tsuExemption.toString() === 'true' ? 'Yes' : 'No'}
								/>
							),
							show: formFields?.formFieldsConfig?.socialSecurityExemption
						},
						{
							name: 'Employment Stimulus Measure',
							value: (
								<InfoField
									readOnly
									value={employeeData?.proposal?.estimuloMedida.toString() === 'true' ? 'Yes' : 'No'}
								/>
							),
							show: formFields?.formFieldsConfig?.employmentStimulusMeasure
						}
					]
				},
				{
					bigTitle: 'Projects',
					show: true,
					fields: [
						...(employeeData?.proposal?.projects?.length > 0
							? employeeData?.proposal?.projects.map((proj: IProposalProject, index: number) => ({
									overrideSizes: [4, 8, 0, 0],
									name: index === 0 ? 'Project in timesheet' : '',
									value: <InfoField label="" value={proj.projectName || ''} />,
									percent: '',
									show: true
							  }))
							: [
									{
										overrideSizes: [4, 8, 0, 0],
										name: 'Project in timesheet',
										value: '',
										percent: '',
										show: true
									}
							  ])
					]
				},
				{
					bigTitle: 'Other Information',
					show: true,
					fields: [
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'No Time Off Policy',
							value: (
								<InfoField
									readOnly
									value={employeeData?.proposal?.noTimeOffPolicy.toString() === 'true' ? 'Yes' : 'No'}
								/>
							),
							show: formFields?.formFieldsConfig?.noTimeoffPolicy
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Time Off Approval Type',
							value: <InfoField readOnly value={employeeData?.summary?.timeOffApprovalTypeName} />,
							show: formFields?.formFieldsConfig?.timeoffApprovalType
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Nr Of Vacation Days',
							value: <Numeric readOnly value={employeeData?.proposal?.vacationDays || '0'} />,
							show: formFields?.formFieldsConfig?.nrVacationDays
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Time Off Policy',
							value: <InfoField readOnly value={employeeData?.summary?.timeOffPolicyName} />,
							show: formFields?.formFieldsConfig?.timeoffPolicy
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Contact List Level',
							value: <InfoField readOnly value={employeeData?.proposal?.contactListLevel || 'Team'} />,
							show: formFields?.formFieldsConfig?.contactListLevel
						}
					]
				}
			];
		} else {
			newEmployeeDataValues = [
				{
					bigTitle: 'General',
					show: true,
					fields: [
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Full Name',
							value: <InfoField readOnly value={employeeData?.proposal?.name} />,
							show: formFields?.formFieldsConfig?.fullName
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Username',
							value: <InfoField readOnly value={employeeData?.proposal?.username} />,
							show: formFields?.formFieldsConfig?.username
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Email',
							value: <InfoField readOnly value={employeeData?.proposal?.email} />,
							show: formFields?.formFieldsConfig?.email
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Start Date',
							value: <InfoField readOnly value={parseDateToShow(employeeData?.proposal?.startDate)} />,
							show: formFields?.formFieldsConfig?.startDate
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Company',
							value: <InfoField readOnly value={employeeData?.summary?.companyName} />,
							show: formFields?.formFieldsConfig?.company
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Business Unit',
							value: <InfoField readOnly value={employeeData?.summary?.businessUnitName} />,
							show: formFields?.formFieldsConfig?.businessUnit
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Division',
							value: <InfoField readOnly value={employeeData?.summary?.divisionName} />,
							show: formFields?.formFieldsConfig?.division
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Department',
							value: <InfoField readOnly value={employeeData?.summary?.departmentName} />,
							show: formFields?.formFieldsConfig?.department
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Role',
							value: <InfoField readOnly value={employeeData?.summary?.collaborationRoleName} />,
							show: formFields?.formFieldsConfig?.careerJobRole
						}
					]
				},
				{
					bigTitle: 'Career and Financial',
					removeBottomPadding: true,
					show: true,
					fields: []
				},
				{
					title: 'Career Information',
					show: true,
					fields: [
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Career Name',
							value: <InfoField readOnly value={employeeData?.summary?.careerPositionName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.careerName
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Job Role',
							value: <InfoField readOnly value={employeeData?.summary?.careerJobRoleName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.careerJobRole
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Level',
							value: (
								<InfoField readOnly value={employeeData?.summary?.careerJobRoleLevelName || 'N/A'} />
							),
							show: formFields?.formFieldsConfig?.careerJobRoleLevel
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Career Manager',
							value: <InfoField readOnly value={employeeData?.summary?.careerManagerName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.careerManager
						}
					]
				},
				{
					title: 'General Amounts',
					show: true,
					fields: [
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Hourly Contractor Budget',
							value: (
								<Numeric
									readOnly
									money
									value={positiveValue(employeeData?.proposal?.budget / 18 / 8) || '0'}
								/>
							),
							show: formFields?.formFieldsConfig?.hourlyRate
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Daily Contractor Budget',
							value: (
								<Numeric
									readOnly
									money
									value={positiveValue(employeeData?.proposal?.budget / 18) || '0'}
								/>
							),
							info: '',
							show: formFields?.formFieldsConfig?.hourlyRate
						},
						{
							overrideSizes: isTablet ? [3, 3, 3, 3] : [4, 2, 2, 4],
							name: 'Monthly Contractor Budget',
							value: (
								<Numeric readOnly money value={positiveValue(employeeData?.proposal?.budget) || '0'} />
							),
							percent: '',
							info: '',
							show: formFields?.formFieldsConfig?.hourlyRate
						}
					]
				},
				{
					bigTitle: 'Contract & Company',
					show: true,
					fields: [
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Primary Office',
							value: <InfoField readOnly value={employeeData?.summary?.primaryOfficeName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.primaryOffice
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Working Office',
							value: <InfoField readOnly value={employeeData?.summary?.workingOfficeName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.workingOffice
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Employee Manager',
							value: <InfoField readOnly value={employeeData?.summary?.employeeManagerName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.employeeManager
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Holiday Type',
							value: <InfoField readOnly value={employeeData?.summary?.holidayTypeName || 'N/A'} />,
							show: formFields?.formFieldsConfig?.holidayType
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Referral Type',
							value: <InfoField readOnly value={employeeData?.proposal?.referralType || 'N/A'} />,
							show: formFields?.formFieldsConfig?.referralType
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Referenced By',
							value: <InfoField readOnly value={employeeData?.proposal?.reference || 'N/A'} />,
							show: true
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Referral Id',
							value: <InfoField readOnly value={employeeData?.proposal?.referralId || 'N/A'} />,
							show: formFields?.formFieldsConfig?.referralId
						}
					]
				},
				{
					bigTitle: 'Projects',
					show: true,
					fields: [
						...(employeeData?.proposal?.projects?.length > 0
							? employeeData?.proposal?.projects.map((proj: IProposalProject, index: number) => ({
									overrideSizes: [4, 8, 0, 0],
									name: index === 0 ? 'Project in timesheet' : '',
									value: <InfoField label="" value={proj.projectName || ''} />,
									percent: '',
									show: true
							  }))
							: [
									{
										overrideSizes: [4, 8, 0, 0],
										name: 'Project in timesheet',
										value: '',
										percent: '',
										show: true
									}
							  ])
					]
				},
				{
					bigTitle: 'Other Information',
					show: true,
					fields: [
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'No Time Off Policy',
							value: (
								<InfoField
									readOnly
									value={employeeData?.proposal?.noTimeOffPolicy.toString() === 'true' ? 'Yes' : 'No'}
								/>
							),
							show: formFields?.formFieldsConfig?.noTimeoffPolicy
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Time Off Approval Type',
							value: <InfoField readOnly value={employeeData?.summary?.timeOffApprovalTypeName} />,
							show: formFields?.formFieldsConfig?.timeoffApprovalType
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Nr Of Vacation Days',
							value: <Numeric readOnly value={employeeData?.proposal?.vacationDays || '0'} />,
							show: formFields?.formFieldsConfig?.nrVacationDays
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Time Off Policy',
							value: <InfoField readOnly value={employeeData?.summary?.timeOffPolicyName} />,
							show: formFields?.formFieldsConfig?.timeoffPolicy
						},
						{
							overrideSizes: [4, 8, 0, 0],
							name: 'Contact List Level',
							value: <InfoField readOnly value={employeeData?.proposal?.contactListLevel || 'Team'} />,
							show: formFields?.formFieldsConfig?.contactListLevel
						}
					]
				}
			];
		}
		setEmployeeDataValues(newEmployeeDataValues);
	}, [employeeData, formFields]);

	const isLoading = employeeData === false;
	if (isLoading) return <LoadingOverlay />;

	return (
		<div className={isTablet ? classes.rootTablet : classes.root}>
			<Grid container direction="row" justifyContent="flex-start" spacing={isMobile ? 1 : 2}>
				{employeeDataValues?.map(group => (
					<Fragment key={group?.title || group?.bigTitle}>
						{group.show && group.title
							? createTitle(group.title)
							: createBigTitle(group.bigTitle as string, group.removeBottomPadding)}
						{group.fields.map(
							(field: any, index: number) =>
								field.show && (
									<Fragment key={`${group.title || group.bigTitle}_${field.name}_${index}`}>
										{createLine(
											field.name,
											field.value,
											field.percent,
											field.info,
											field.overrideSizes
										)}
									</Fragment>
								)
						)}
					</Fragment>
				))}
			</Grid>
		</div>
	);
};

export default withLayout(EmployeeDataTable);
