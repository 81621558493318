/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/extensions

import { OriginalResponse } from 'lib/handlers/requestTypes';
import { Container, EventModel, Field, Model } from '@bryntum/scheduler/scheduler.umd.js';
import { IChoosableBaseInfo } from '../generalTypes';

export enum PlannerActions {
	SET_ERROR = '@@personalInfo/SET_ERROR',
	SET_LOADING = '@@administration/planner/SET_LOADING',
	GET_PLANNER_DATA = '@@administration/planner/GET_PLANNER_DATA',
	GET_FILTERS_DATA = '@@administration/planner/GET_FILTERS_DATA',
	POST_PLANNER_DATA = '@@administration/planner/POST_PLANNER_DATA',
	POST_FILTERS = '@@administration/planner/POST_FILTERS',
	CLEAR_PLANNER_DATA = '@@administration/planner/CLEAR_PLANNER_DATA',
	CLEAR_ALL_DATA = '@@administration/planner/CLEAR_ALL_DATA',
	SET_PLANNER_ACTION = '@@administration/planner/SET_PLANNER_ACTION',
	POST_PLANNER_DATA_CREATE = '@@administration/planner/POST_PLANNER_DATA_CREATE',
	POST_DELETE_PLANNER_EVENT = '@@administration/planner/POST_DELETE_PLANNER_EVENT'
}

export enum OperationsType {
	CREATE = 'Create',
	EDIT = 'Edit',
	DELETE = 'Delete'
}

export type PlannerState = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly plannerData: IPlannerData | null;
	readonly plannerFilteredData: any | null;
	readonly filtersData: any | null;
	readonly postDataResult: boolean;
	readonly plannerActions: string;
};

export interface IPlannerData {
	endDate: string;
	startDate: string;
	events: IEvent[];
	projects: IProject[];
	resources: IResource[];
	timeOffs: ITimeOff[];
}

// Project object as we received from BE
export interface IProjectChoosable extends IChoosableBaseInfo {
	xpalDepartment: string;
}

export interface IEvent {
	eventId: string;
	resourceId: string;
	projectID: string;
	startDate: string | Date;
	endDate: string | Date;
	projectLabel: string;
	eventColor?: string;
	allocationPercentage: number;
	lastUpdateDate?: string;
	operation?: string;
	locked?: boolean;
	name?: string;
	alternativeProjLabel?: string;
	resource?: any;
}
export interface ITimeOff {
	id: number;
	duration: number;
	name: string;
	resourceId: string;
	startDate: string;
	timeRangeColor: string;
	type: string;
}
export interface IResource {
	id: string;
	group: string;
	name: string;
	role: string;
}

export interface IProject {
	id: string;
	resourceId: string;
	projectID: string;
	startDate: string;
	endDate: string;
	projectLabel: string;
	eventColor?: string;
	allocationPercentage: number;
	lastUpdateDate?: string;
	operation?: OperationsType;
	name?: string;
}

export interface IFilters {
	startDate?: string;
	endDate?: string;
	offices: string[];
	projects: string[];
	users: string[];
	skills: number[];
	showAvailableOnly: boolean;
	showTimeOff: boolean;
}
export interface IEventListenerContext {
	finalize: (fin: boolean) => void;
}

export interface CustomEventModel extends EventModel {
	id: number | string;
	data: IEvent;
}
export interface CustomField extends Field {
	max: number;
}
export interface CustomWidgetMap extends Object {
	allocation: CustomField;
	projectLabel: Field;
	projects: any;
	color: any;
}
export interface CustomContainer extends Container {
	widgetMap: CustomWidgetMap;
}
export interface BryntumEventChangesStruct {
	added: PlannerBryntumModel[];
	modified: PlannerBryntumModel[];
	removed: PlannerBryntumModel[];
}

export interface PlannerBryntumModel extends Model {
	id: string;
	resourceId: string;
	data: IEvent;
}

export interface EventDateVerification {
	resourceId: string;
	startDate: string | Date;
	endDate: string | Date;
}
