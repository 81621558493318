/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, useRef, useCallback, FC } from 'react';
import { BryntumScheduler } from '@bryntum/scheduler-react';
import moment, { Moment } from 'moment';

//* EXTERNAL LIBS --> MUI
import { Chip, Stack, Badge, Typography } from '@mui/material';
import { Add, List, Block } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { Dialog, LoadingOverlay, PageTitle } from 'xpand-ui/core';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';
import { removeTimezone } from 'xpand-ui/utils/dates';
import { InfoField, InputLabel } from 'xpand-ui/forms';

//* TYPINGS
import { IEventListenerContext, OperationsType } from 'typings/store/admin/planner';
import { IBookingItem, IBooking } from 'typings/store/personalInfoTypes';
import { IUserToken } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import ToggleButtonGroup, { IToggleButtonOption } from 'components/ToggleButtonGroup';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import DownloadIcon from 'assets/icons/downloadButton.svg';

//* LOCAL COMPONENT IMPORTS
import {
	beforeDragCreateFinalize,
	dragCreateEnd,
	beforeEventAdd,
	beforeEventEdit,
	parseEventData,
	EventColors
} from './utils';
import Adicionar from '../../../assets/icons/Adicionar.svg';
import { schedulerConfig } from './utils/DeskSchedulerConfig';
import { IDeskEvent, IDeskResources, IDeskResourcesSeat } from './@types';
import EventModal from './utils/EventModal';
import { useStyles } from './styles';
import { BookingProps } from '.';
import './utils/override.css';
import ReservationModal from './utils/ReservationModal';
import ExportModal from './utils/ExportModal';
import BlockSeatsModal from './utils/BlockSeatsModal';
import { getLSField } from 'lib/utils/cookies';
import { Roles } from 'lib/roles';

const parseDateToManage = (date: Date | Moment | string, clearHour = false): string =>
	clearHour
		? `${moment(removeTimezone(date)).format('YYYY/MM/DD')} 00:00:00`
		: moment(removeTimezone(date)).format('YYYY/MM/DD HH:mm:ss');

//* COMPONENT INTERFACES
interface IBookingInterface extends BookingProps {
	loggedUser: IUserToken;
}

/**
 * "It returns an object with the user's company and office, given a booking object, a logged user
 * object and an optional office string."
 *
 * @param {IBooking} deskBooking - IBooking
 * @param {IUserToken} loggedUser - IUserToken
 * @param {string} [office] - this is the office that the user wants to book a desk in.
 * @returns An object with the following properties:
 * userDBInfo: IUserDBInfo
 * userOfficeKey: string
 * userCompany: ICompany
 * userOffice: IOffice
 */
const getUserCompanyAndOffice = (deskBooking: IBooking, loggedUser: IUserToken, office?: string) => {
	const { companies, users } = deskBooking;
	const userDBInfo = users.find(e => e.username === loggedUser.username);
	if (!userDBInfo) return null; // TODO: toast -> user info not found

	// TODO: This should be dynamic
	const userOfficeKey =
		(userDBInfo.officeName === 'Viana do Castelo' && 'VC') ||
		(userDBInfo.officeName === 'Braga' && 'BR') ||
		(userDBInfo.officeName === 'Porto' && 'PT') ||
		(userDBInfo.officeName === 'London' && 'LN') ||
		(userDBInfo.officeName === 'Zagreb' && 'ZG') ||
		(userDBInfo.officeName === 'Lisboa' && 'LX') ||
		'LX';

	// Process company. If user company does not have an office for bookings, tries to provide first one in list
	let userCompany = companies.find(e => userDBInfo.companyCode === e.name);
	if (!userCompany) userCompany = companies.find(e => e.id === 1);

	// TODO: toast -> no companies found
	if (!userCompany) return null;

	// Process office. If user company does not have an office for bookings, tries to provide first one in list
	let userOffice = userCompany.offices.find(e => (office ? e.searchKey === office : e.searchKey === userOfficeKey));
	if (!userOffice) userOffice = userCompany.offices.find(e => e.id === 1);

	if (!userOffice) return null; // TODO: toast -> user office not found

	return {
		userDBInfo,
		userOfficeKey,
		userCompany,
		userOffice
	};
};

const notificationPayload = {
	area: 'Bookings',
	section: 'Pending Bookings'
};

//* COMPONENT
const Booking: FC<IBookingInterface> = ({
	loggedUser,
	personalInfo,
	company,
	clearBooking,
	getBooking,
	getBookingsList,
	submitNewBookings,
	submitEditBookings,
	submitNewBlockedSeats,
	clearBookingsList,
	clearNewBookingsList,
	clearPersonalInfoError,
	clearEmployeeBookingsList,
	confirmBooking,
	deleteBooking,
	getEmployeeBookingsCounter,
	exportBookings,
	setNotificationAsRead
}) => {
	const classes = useStyles();
	const deletedIds = useRef<number[]>([]);
	const lastCreated = useRef<IBookingItem | undefined>();
	const { loading, error, deskBooking, countPending, deskBookingsList, deskBookingActions } = personalInfo;
	const { width } = useWindowDimensions();

	const isMobile = useMemo(() => width <= 600, [width]); // default is 600 mobile
	const isTablet = useMemo(() => width <= 900, [width]); // default is 900 tablet
	//* tollbar
	const [toolbar, setToolbar] = useState<{
		companies: IToggleButtonOption[] | null;
		offices: IToggleButtonOption[] | null;
	}>({
		companies: null,
		offices: null
	});
	const [toolbarSelected, setToolSelected] = useState<{
		currentUser: string | null;
		company: string | null;
		companyName: string | null;
		office: string | null;
		officeId: number | null;
		officeName: string | null;
	}>({
		currentUser: null,
		company: null,
		office: null,
		officeId: null,
		companyName: null,
		officeName: null
	});

	//* scheduler
	const scheduler = useRef<typeof BryntumScheduler | null>(null);
	const [events, setEvents] = useState<IDeskEvent[] | null>(null);
	const [resources, setResources] = useState<IDeskResources[] | null>(null);
	const [popupShown, showPopup] = useState(false);
	const [reservModal, openReservModal] = useState(false);
	/* eslint-disable @typescript-eslint/no-explicit-any */
	const [eventAction, setEventAction] = useState<any>(null);
	const [eventRecord, setEventRecord] = useState<any>(null);
	const [eventStore, setEventStore] = useState<any>(null);
	// const [resourceStore, setResourceStore] = useState<any>(null);
	const [deleteModalOpenState, setDeleteModalOpenState] = useState<unknown | null>(null);
	const [deleteEventContext, setDeleteEventContext] = useState<IEventListenerContext | null>(null);
	const [exportModal, openExportModal] = useState(false);
	const [eventTypeBookingForDelete, setEventTypeBookingForDelete] = useState();
	const [isEdit, setIsEdit] = useState(false);
	const [blockSeatsModal, openBlockSeatsModal] = useState(false);
	const [isBlockSeat, setIsBlockSeat] = useState(false);
	/* eslint-enable @typescript-eslint/no-explicit-any */

	/* A useEffect hook that is being used to clear the state of the component. */
	useEffect(
		() => () => {
			clearBookingsList();
			clearNewBookingsList();
			clearPersonalInfoError();
			clearEmployeeBookingsList();
			lastCreated.current = undefined;
			deletedIds.current = [];
		},
		[]
	);

	//* BRYNTUM RESOURCES TREE
	//* TOOLBAR INFO FOR COMPANY AND OFFICE TOOGLE BUTTONS
	//* TOOGLE BUTTONS DEFAULT SELECTED VALUES
	useEffect(() => {
		if (!deskBooking && !error) {
			getBooking();
		} else if (deskBooking) {
			const userCompanyAndOffice = getUserCompanyAndOffice(deskBooking, loggedUser);
			if (!userCompanyAndOffice) return;

			const { userDBInfo, userOfficeKey, userCompany, userOffice } = userCompanyAndOffice;
			//* BRYNTUM RESOURCES TREE
			let allResources: IDeskResources[] = [];
			userCompany.offices.forEach(office => {
				allResources = [
					...allResources,
					...office.zones.map(({ seats, id, ...ofc }) => ({
						...ofc,
						id: id + 50000,
						officeId: office.id,
						officeSearchKey: office.searchKey,
						expanded: true,
						children: seats.map(seat => ({
							...seat,
							zoneName: ofc.name,
							zoneId: id + 50000,
							officeSearchKey: office.searchKey,
							officeId: office.id
						}))
					}))
				];
			});
			setResources(allResources);

			//* TOOLBAR INFO FOR COMPANY AND OFFICE TOOGLE BUTTONS
			setToolbar({
				companies: deskBooking.companies.map(e => ({ label: e.name, value: e.searchKey })) || [],
				offices: userCompany?.offices?.map(e => ({ label: e.name, value: e.searchKey })) || []
			});

			//* TOOGLE BUTTONS DEFAULT SELECTED VALUES
			setToolSelected({
				currentUser: loggedUser.username,
				company: userCompany.searchKey,
				companyName: userCompany.name,
				office: `${userOfficeKey}`,
				officeId: userOffice.id,
				officeName: userDBInfo.officeName
			});
		}
	}, [deskBooking]);

	//* BRYNTUM RESOURCES TREE update when office changed
	useEffect(() => {
		if (!scheduler?.current?.instance) return;
		const { resourceStore: rsStore } = scheduler?.current?.instance;

		if (toolbarSelected.officeId && rsStore && resources) {
			rsStore.filter({
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				filters: (resource: any) => resource.officeId === toolbarSelected.officeId,
				replace: true
			});

			scheduler.current.instance.scrollToDate(new Date(new Date().setHours(0, 0, 0, 0)), {
				highlight: true,
				block: 'start'
			});
		}
	}, [toolbarSelected.officeId, deskBookingsList]);

	/* Checking if the countPending is false and the officeId is not null. If both are true, then it will
	call the getEmployeeBookingsCounter function. */
	useEffect(() => {
		if (!countPending && toolbarSelected.officeId) {
			getEmployeeBookingsCounter(toolbarSelected.officeId);
		}
	}, [countPending, toolbarSelected.officeId]);

	//* BRYNTUM EVENTS (BOOKINGS)
	useEffect(() => {
		if (!deskBooking || !toolbarSelected.officeId) return;

		if (!deskBookingsList) {
			getBookingsList({ office: toolbarSelected.officeId });
		} else {
			//* BRYNTUM EVENTS (BOOKINGS)
			setEvents(
				deskBookingsList.bookings.map(({ creationDate, dragabble, lastUpdateDate, lastUpdatedBy, ...e }) =>
					parseEventData(
						{ ...e, username: e.employee, isAdmin: deskBookingsList.isAdmin },
						loggedUser.username,
						true
					)
				)
			);
		}
	}, [toolbarSelected.officeId, deskBookingsList, deskBooking]);

	/* Handle deskBookingActions behaviours on the booking CRUD */
	useEffect(() => {
		if (deskBookingActions && !error) {
			const store = scheduler?.current?.instance.eventStore;
			if (!store) return;
			const { changes } = store;

			if (deskBookingActions.type === 'CREATED' && deskBookingActions?.data?.created) {
				const { created } = deskBookingActions?.data;

				// eslint-disable-next-line
				if (!changes?.added) return;

				// eslint-disable-next-line
				changes.added = changes.added.map((event: any) => {
					const newId = created.find(e => `${e.customId}` === `${event.customId}`);
					if (!newId) return event;
					event.customId = newId.id;
					return event;
				});
			}
			if (deskBookingActions.type === 'CONFIRMED') {
				// eventAction.eventStyle = EventStyle.Validated;
				eventAction.validated = true;
				eventAction.eventColor =
					eventAction.username === loggedUser.username ? EventColors.UserValid : EventColors.NotUserValid;

				setEventAction(null);
			}
			if (deskBookingActions.type === 'DELETED') {
				eventAction && scheduler.current.instance.eventStore.remove(eventAction);
				eventAction && setEventAction(null);
			}

			scheduler.current.instance.eventStore.commit();
			clearNewBookingsList();
			getEmployeeBookingsCounter();
		}
	}, [deskBookingActions]);

	const showEditor = useCallback((payload: unknown) => {
		if (scheduler?.current) {
			const {
				eventStore: currentEventStore
				//  resourceStore: currentResourceStore
			} = scheduler.current.instance;
			currentEventStore && setEventStore(currentEventStore);
			// setResourceStore(currentResourceStore);
		}
		setEventRecord(payload);
		showPopup(true);
	}, []);

	const showBlockSeatsPopup = useCallback((payload: unknown) => {
		if (scheduler?.current) {
			const {
				eventStore: currentEventStore
				//  resourceStore: currentResourceStore
			} = scheduler.current.instance;
			currentEventStore && setEventStore(currentEventStore);
			// setResourceStore(currentResourceStore);
		}
		setEventRecord(payload);
		openBlockSeatsModal(true);
	}, []);

	const hideEditor = useCallback(
		// eslint-disable-next-line
		async (changes: any) => {
			// If isCreating is still true, user clicked cancel
			if (eventRecord && eventRecord.isCreating) {
				eventStore.remove(eventRecord);
				eventRecord.isCreating = false;
			}
			showPopup(false);
			setEventRecord(null);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			changes && (await submitNewBookings(changes.map((e: any) => ({ ...e, operation: OperationsType.CREATE }))));
			await eventStore?.remove(eventRecord);
			//setEventAction(null);
			if (changes) {
				clearBooking();
				getBookingsList({ office: toolbarSelected.officeId });
			}
		},
		[eventRecord, eventStore]
	);

	const hideEditEditor = useCallback(
		// eslint-disable-next-line
		async (changes: any) => {
			//If isCreating is still true, user clicked cancel
			if (eventAction && eventAction.isCreating) {
				eventStore.remove(eventAction);
				eventAction.isCreating = false;
			}
			setIsEdit(false);
			await submitEditBookings(changes.map((e: any) => ({ ...e, operation: OperationsType.EDIT })));
			await eventStore?.remove(eventAction);
			setEventAction(null);
			if (changes) {
				clearBooking();
				getBookingsList({ office: toolbarSelected.officeId });
			}
		},
		[eventAction, eventStore]
	);

	const hideBlockSeatsEditor = useCallback(
		// eslint-disable-next-line
		async (changes: any) => {
			// If isCreating is still true, user clicked cancel
			if (eventRecord && eventRecord.isCreating) {
				eventStore.remove(eventRecord);
				eventRecord.isCreating = false;
			}
			openBlockSeatsModal(false);

			setEventRecord(null);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			changes &&
				(await submitNewBlockedSeats(changes.map((e: any) => ({ ...e, operation: OperationsType.CREATE }))));
			await eventStore?.remove(eventRecord);
			//setEventAction(null);
			if (changes) {
				clearBooking();
				getBookingsList({ office: toolbarSelected.officeId });
			}
		},
		[eventRecord, eventStore]
	);

	/* eslint-disable @typescript-eslint/no-explicit-any */
	const [listeners] = useState({
		// beforeDragCreate: () => false,
		eventMenuBeforeShow: ({ eventRecord: newEvent, items }: any) => {
			if (!newEvent.isAdmin) {
				if (newEvent.validated || (!newEvent.validated && loggedUser.username !== newEvent.username)) {
					delete items.validateBooking;
				}
				if (!newEvent.canEdit) {
					delete items.deleteEvent;
				}
			} else {
				// eslint-disable-next-line no-lonely-if
				if (newEvent.validated) {
					delete items.validateBooking;
				}
			}
		},
		// ? chamado no fim do Drag do create event
		beforeDragCreateFinalize,
		// ? workaround para fazer override do endDate e do duration
		// ? isto é chamado a partir do beforeDragCreateFinalize
		dragCreateEnd,
		// ? chamado no INICIO do DragCreate
		// ? chamado ao criar evento com duploClick
		beforeEventAdd: (props: any) => beforeEventAdd(props, loggedUser),
		beforeEventEdit: (props: any) => {
			const { eventRecord } = props;
			// Check if the eventRecord is not null (you may need additional checks based on your logic)
			if (typeof eventRecord.data.id === 'number' && !isNaN(eventRecord.data.id)) {
				// Prevent the default behavior of the event (double-click) by returning false
				return false;
			}

			// Continue with the default behavior for other cases
			beforeEventEdit(props, showEditor, loggedUser.username);
		},
		beforeEventDelete: ({ eventRecords, context }: any) => {
			const originalData = eventRecords[0].data;

			const payload = {
				...originalData,
				operation: OperationsType.DELETE
			};
			setEventAction(eventRecords[0]);
			setDeleteEventContext(context);
			setDeleteModalOpenState([payload]);
			return false;
		}
	});

	useEffect(() => {
		if (deleteModalOpenState) {
			resources?.forEach(r => {
				r.children.forEach(s => {
					if (s.id === deleteModalOpenState![0].resourceId) setEventTypeBookingForDelete(s.type);
				});
			});
		}
	}, [deleteModalOpenState]);

	/**
	 * I'm going to set the state of the eventAction to the event record, then I'm going to call the
	 * confirmBooking function with the customId of the event record, and then I'm going to clear the
	 * employeeBookingsList.
	 * @param {any} evRecord - any - this is the event record that is passed to the function.
	 */
	const handlePendingBooking = (evRecord: any, type: string) => {
		setEventAction(evRecord);
		if (type === 'Deleted') {
			if (evRecord.data.blockedSeat) {
				setIsBlockSeat(true);
			} else {
				setIsBlockSeat(false);
			}
			setDeleteModalOpenState([evRecord]);

			//deleteBooking(evRecord.customId);
		} else if (type === 'Confirmed') {
			confirmBooking(evRecord.customId);
		} else {
			setIsEdit(true);
			//editBooking(evRecord.customId)
		}
		setNotificationAsRead(loggedUser?.username, notificationPayload);
		clearEmployeeBookingsList();
	};

	const exportBookingsList = (payload: {
		office: Number | null;
		startDate: Date;
		type: string;
		endDate: Date | null;
	}) => {
		exportBookings(payload);
	};
	// PAGE HEADER ACTIONS
	const pageTitleActions = useMemo(
		() => [
			{
				id: 'reservations',
				color: 'secondary',
				variant: 'outlined',
				onClick: () => openReservModal(true),
				icon:
					!countPending || countPending === 0 ? (
						<List />
					) : (
						<Badge badgeContent={countPending} color="primary">
							<List />
						</Badge>
					),
				label: 'Bookings',
				disabled: getLSField('impersonate_userInfo')
			},
			{
				id: 'bookSeat',
				variant: 'outlined',
				onClick: () => showEditor(null),
				icon: <Adicionar />,
				label: 'Create Booking',
				disabled: getLSField('impersonate_userInfo')
			},
			...(deskBookingsList?.isAdmin
				? [
						{
							id: 'exportBookings',
							label: 'Export Bookings',
							// left: true,
							onClick: () => openExportModal(true),
							variant: 'outlined',
							icon: <DownloadIcon />,
							disabled: getLSField('impersonate_userInfo')
						},
						{
							id: 'blockSeat',
							label: 'Block Seats',
							onClick: () => showBlockSeatsPopup(null),
							variant: 'outlined',
							icon: <Block />,
							disabled: getLSField('impersonate_userInfo')
						}
				  ]
				: [])
		],
		[deskBookingsList, countPending]
	);

	const deleteEventActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				deleteEventContext?.finalize(false);
				setEventAction(null);
				setDeleteModalOpenState(false);
			}
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			autoFocus: true,
			variant: 'contained',
			onClick: () => {
				deleteEventContext?.finalize(true);
				setDeleteModalOpenState(false);
				deleteBooking(deleteModalOpenState![0].customId);
				setNotificationAsRead(loggedUser?.username, notificationPayload);
			},
			disabled: getLSField('impersonate_userInfo')
		}
	];

	const getTodaysBooking = (type: string) => {
		let booking: IBookingItem | undefined;
		let validResources: number[];

		if (!deskBookingsList) return null;

		// Get seats with given type
		validResources = [];
		if (resources) {
			resources.forEach(r => {
				r.children.forEach(s => {
					if (s.type === type) validResources.push(s.id);
				});
			});
		}

		const today = new Date();
		today.setHours(0, 0, 0, 0);

		// if the current booking was created only on bryntum and its on the lastCreated.current auxiliary
		if (lastCreated.current && validResources.includes(lastCreated.current.resourceId)) {
			booking = lastCreated.current;
		}

		// if the current booking was created only on bryntum and its NOT on the lastCreated.current
		// search it on the deskBookingActions where the copy of the object is located, and set it to
		// the the lastCreated.current auxiliary
		if (deskBookingActions?.type === 'CREATED' && !lastCreated.current) {
			booking = deskBookingActions.payload?.find(
				e =>
					parseDateToManage(e.startDate, true) === parseDateToManage(today, true) &&
					e.username === loggedUser.username &&
					validResources.includes(e.resourceId)
			);
			lastCreated.current = booking;
		}

		// if the current booking is already on the database
		if (!booking) {
			booking = deskBookingsList.bookings.find(
				e =>
					parseDateToManage(e.startDate, true) === parseDateToManage(today, true) &&
					e.employee === loggedUser.username &&
					validResources.includes(e.resourceId)
			);
		}

		// if no booking object was found return null
		if (!booking || !resources) return null;
		// object exists on the inicial database load object, but was deleted, its ID will be inside
		// of deletedIds.current, and then, return null to not sho anything
		if (deletedIds.current.includes(booking.id)) return null;

		// get the booking resource information to get its name
		resources.forEach(r => {
			const seat = r.children.find(e => e.id === (booking as IBookingItem).resourceId);
			if (seat) {
				(booking as IBookingItem).seat = seat;
			}
		});

		// if the booking object is from the database, return it now
		if (!deskBookingActions) return booking;

		// if the booking object VALIDATED state has changed, change it on its properties
		if (deskBookingActions.type === 'CONFIRMED') {
			if (eventAction && eventAction.data?.customId === booking.id) {
				booking.validated = true;
			}
		}
		// if the booking object was deleted, then return null
		if (deskBookingActions.type === 'DELETED') {
			// case if it was deleted from the modal
			if (eventAction && eventAction.data?.customId === booking.id) {
				deletedIds.current.push(booking.id);
				return null;
			}
			// case if it was deleted from the bryntum directly
			if (deskBookingActions.payload?.some(e => e.customId === (booking as IBookingItem).id)) {
				deletedIds.current.push(booking.id);
				return null;
			}
		}

		return booking;
	};

	/* A React hook that is used to get the current booking for the user. */
	const todaysBooking = useMemo(() => {
		return getTodaysBooking('SEAT');
	}, [deskBookingsList, deskBookingActions]);

	const todaysParking = useMemo(() => {
		return getTodaysBooking('PARKING');
	}, [deskBookingsList, deskBookingActions]);

	/* Checking if there is an error, and if there is, it will return the handleErrorPage function. */
	if (error) return handleErrorPage(error, clearPersonalInfoError);

	/* Checking if the deskBooking is null, if it is, it will return the LoadingOverlay component. */
	const isLoading = deskBooking === null;
	if (isLoading) return <LoadingOverlay />;

	const isAdmin = deskBookingsList?.isAdmin;

	const userPermissionsStorage = getLSField('impersonate_userPermissions');
	const userImpersonatePermission = (userPermissionsStorage && JSON.parse(userPermissionsStorage)) || null;

	const isAdminBookings = () => {
		if (!isAdmin) {
			return false;
		}
		if (isAdmin) {
			return true;
		}

		if (!userImpersonatePermission) {
			return true;
		}

		return userImpersonatePermission[0]?.name === Roles.CP_ROLE_SYSTEM_ADMIN || userImpersonatePermission[0]?.name === Roles.CP_ROLE_LOGISTICS;
	};
	const configs = schedulerConfig(classes, toolbarSelected, handlePendingBooking, isAdminBookings());

	return (
		<div className={classes.root}>
			{loading && !reservModal && <LoadingOverlay />}
			{deskBookingsList == null && <LoadingOverlay />}
			<div style={{ width: '100%' }}>
				<InfoField
					fontSize="lg"
					label={
						<>
							{isAdminBookings() && (
								<Chip
									label="Admin mode"
									color="primary"
									variant="outlined"
									style={{ margin: '10px', marginLeft: 0 }}
								/>
							)}
							{todaysBooking && todaysBooking.seat && (
								<Chip
									label={`Today's seat: ${todaysBooking.seat.name}`}
									color="primary"
									variant="filled"
									style={{
										backgroundColor: todaysBooking.validated
											? EventColors.UserValid
											: EventColors.UserNotValid,
										opacity: 0.65,
										color: 'black',
										margin: '10px'
									}}
								/>
							)}
							{todaysParking && todaysParking.seat && (
								<Chip
									label={`Today's parking: ${todaysParking.seat.name}`}
									color="primary"
									variant="filled"
									style={{
										backgroundColor: todaysParking.validated
											? EventColors.UserValid
											: EventColors.UserNotValid,
										opacity: 0.65,
										color: 'black',
										margin: '10px'
									}}
								/>
							)}
							{company.urlServiceBooking && (
								<Typography gutterBottom>
									To check the office seat distribution and other information please consult&nbsp;
									<a
										style={{ fontSize: '14px' }}
										href={company.urlServiceBooking}
										rel="noopener noreferrer"
										target="_blank">
										this confluence page
									</a>
								</Typography>
							)}
						</>
					}
					value=""
				/>
			</div>
			<PageTitle
				asItem={false}
				title={
					<Stack direction={isTablet ? 'column' : 'row'} spacing={5}>
						{toolbar.companies && toolbarSelected.company && (
							<span>
								<InputLabel style={{ marginLeft: '7px', marginBottom: '-2px' }}>Company</InputLabel>
								<ToggleButtonGroup
									buttons={toolbar.companies}
									selectedValue={toolbarSelected.company}
									selectedBtnHandler={company => {
										lastCreated.current = undefined;
										deletedIds.current = [];
										setToolSelected(prev => ({ ...prev, company, office: null }));
										setToolbar(prev => ({ ...prev, offices: null }));
										getEmployeeBookingsCounter();
									}}
								/>
							</span>
						)}
						{toolbar.offices && toolbarSelected.office && (
							<span>
								<InputLabel style={{ marginLeft: '10px', marginBottom: '-2px' }}>Office</InputLabel>
								<ToggleButtonGroup
									buttons={toolbar.offices}
									selectedValue={toolbarSelected.office}
									selectedBtnHandler={office => {
										if (deskBooking?.companies) {
											const userCompanyAndOffice = getUserCompanyAndOffice(
												deskBooking,
												loggedUser,
												office
											);
											if (!userCompanyAndOffice) return;
											const { userOfficeKey, userOffice } = userCompanyAndOffice;

											lastCreated.current = undefined;
											deletedIds.current = [];

											clearBookingsList();
											getEmployeeBookingsCounter();

											setEvents([]);

											//* BRYNTUM RESOURCES TREE
											setToolSelected(prev => ({
												...prev,
												office: `${userOfficeKey}`,
												officeId: userOffice.id,
												officeName: userOffice.name
											}));
										}
									}}
								/>
							</span>
						)}
					</Stack>
				}
				actions={pageTitleActions}
			/>
			{resources && (
				<span style={{ maxHeight: '650px', height: '100%', width: '100%' }}>
					<BryntumScheduler
						ref={scheduler}
						{...configs}
						events={events || []}
						resources={resources}
						listeners={listeners}
					/>
				</span>
			)}
			{popupShown && resources && deskBooking?.users && deskBookingsList ? (
				<EventModal
					{...{
						eventRecord,
						eventStore,
						toolbarSelected,
						loggedUser,
						resources: resources.filter((resource: any) => resource.officeId === toolbarSelected.officeId),
						parkingBookingsLimit: deskBookingsList?.parkingBookingsLimit,
						userParkingBookingsCount: deskBookingsList?.userParkingBookingsCount,
						seatRequiredToBookParking: deskBookingsList?.seatRequiredToBookParking
					}}
					isAdmin={deskBookingsList.isAdmin}
					users={deskBooking.users}
					closePopup={hideEditor}
					schedulerRef={scheduler?.current?.instance}
					setIsEdit={setIsEdit}
					isEdit={isEdit}
					// resourceStore={resourceStore}
				/>
			) : null}
			{isEdit && (
				<EventModal
					{...{
						eventAction,
						eventRecord,
						eventStore,
						toolbarSelected,
						loggedUser,
						resources: resources?.filter((resource: any) => resource.officeId === toolbarSelected.officeId),
						parkingBookingsLimit: deskBookingsList?.parkingBookingsLimit,
						userParkingBookingsCount: deskBookingsList?.userParkingBookingsCount,
						seatRequiredToBookParking: deskBookingsList?.seatRequiredToBookParking
					}}
					isAdmin={deskBookingsList?.isAdmin}
					users={deskBooking.users}
					closePopup={hideEditEditor}
					schedulerRef={scheduler?.current?.instance}
					isEdit={isEdit}
					setIsEdit={setIsEdit}
					// resourceStore={resourceStore}
				/>
			)}
			{reservModal && toolbarSelected.officeId && (
				<ReservationModal
					selectionInfo={toolbarSelected}
					isMobile={isMobile}
					closePopup={changes => {
						openReservModal(false);
						if (changes) {
							getBookingsList({ office: toolbarSelected.officeId });
						}
					}}
					action={(type, id) => {
						handlePendingBooking(scheduler?.current?.instance.eventStore.getById(id), type);
					}}
					companies={deskBooking.companies}
				/>
			)}
			{exportModal && (
				<ExportModal
					exportBookingsList={exportBookingsList}
					closePopup={() => {
						openExportModal(false);
					}}
					companies={deskBooking.companies}
				/>
			)}
			{blockSeatsModal && resources && deskBooking?.users && deskBookingsList ? (
				<BlockSeatsModal
					{...{
						eventAction,
						eventRecord,
						eventStore,
						toolbarSelected,
						loggedUser,
						resources: resources.filter(
							resource =>
								!resource.name.includes('PARKING') && resource.officeId === toolbarSelected.officeId
						),
						parkingBookingsLimit: deskBookingsList?.parkingBookingsLimit,
						userParkingBookingsCount: deskBookingsList?.userParkingBookingsCount,
						seatRequiredToBookParking: deskBookingsList?.seatRequiredToBookParking
					}}
					isAdmin={deskBookingsList?.isAdmin}
					users={deskBooking.users}
					schedulerRef={scheduler?.current?.instance}
					exportBookingsList={exportBookingsList}
					closePopup={hideBlockSeatsEditor}
					companies={deskBooking.companies}
				/>
			) : null}
			<Dialog
				title={isBlockSeat ? 'Unblock Seat' : 'Delete Booking'}
				actions={deleteEventActions}
				scroll="body"
				modal={{
					open: Boolean(deleteModalOpenState),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setDeleteModalOpenState(false);
					},
					content: (
						<>
							{isBlockSeat ? (
								<Typography gutterBottom>Are you sure you want to unblock this seat? </Typography>
							) : (
								<Typography gutterBottom>Are you sure you want to delete this booking? </Typography>
							)}
							{eventTypeBookingForDelete == 'SEAT' && isEdit && (
								<Typography
									gutterBottom
									style={{ marginTop: '20px', color: 'red', fontWeight: 'bold' }}>
									Warning: If there is a parking booking for same user on same day, it'll be deleted
									as well.
								</Typography>
							)}
						</>
					)
				}}
			/>

			<Stack direction="row" spacing={2} style={{ opacity: '0.65' }}>
				<Chip label="Own Booking (Validated)" style={{ background: EventColors.UserValid }} />
				<Chip label="Own Booking (Not Validated)" style={{ background: EventColors.UserNotValid }} />
				<Chip label="Other Xpander Booking (Validated)" style={{ background: EventColors.NotUserValid }} />
				<Chip
					label="Other Xpander Booking (Not Validated)"
					style={{ background: EventColors.NotUserNotValid }}
				/>
				<Chip label="Blocked Seats" style={{ background: EventColors.BlockedSeat }} />
			</Stack>
		</div>
	);
};

export default withLayout(Booking);
