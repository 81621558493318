/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { Dispatch } from 'redux';
import {
	// LISTS
	fetchProposalsList,
	fetchUserProposalsList,
	// NEW/Edit
	fetchNewProposal,
	fetchNewProposalWToken,
	postNewProposal,
	fetchEditProposal,
	putEditProposal,
	// ACTIONS
	proposalSendEmail,
	sendProposalSendEmail,
	proposalAccept,
	proposalRefuse,
	proposalClose,
	proposalCancel,
	proposalRequestApproval,
	proposalApprove,
	proposalReject,
	proposalChangeStartDate,
	// HELPERS
	fetchProposalSummary,
	fetchExistingProposalSummary,
	fetchProposalAnnualGrossValue,
	fetchIrsValue,
	fetchProposalExport,
	fetchProposalExportWithID,
	fetchProposalAuditLog
} from 'lib/api/administration/proposals';

import {
	IGetNewProposal,
	ISendEmail,
	IProposalsList,
	IUserProposalList,
	IProposalSummary,
	IIrsValuePost,
	IAnualGrossValuePost,
	IDownloadProposal,
	IIrsValueGet,
	IAnualGrossValueGet,
	IProposalPayload,
	IRejectProposalPost,
	IProposalAuditLog
} from 'typings/store/admin/proposals';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import {
	setLoading,
	// LISTS
	setProposalsList,
	setUserProposalsList,
	// NEW/Edit
	setNewProposal,
	setNewProposalWToken,
	setPostNewProposal,
	setEditProposal,
	setPutUpdateProposal,
	// ACTIONS
	setPostProposalSendEmail,
	setProposalSendEmail,
	setProposalAccept,
	setProposalRefuse,
	setProposalClose,
	setProposalCancel,
	setProposalRequestApproval,
	setProposalApprove,
	setProposalReject,
	// HELPERS
	setProposalAnnualGrossValue,
	setIrsValue,
	setProposalSummary,
	setExistingProposalSummary,
	setClearSummary,
	setProposalExport,
	setProposalExportWithID,
	resetFormData,
	setProposalAuditLog,
	setProposalChangeStartDate,
	setProposalsError,
	setProposalSummaryLoading
} from './actions';

export const getProposalsList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IProposalsList } = await fetchProposalsList();
			if (response.data) {
				dispatch(setProposalsList(response?.data || null));
			} else {
				dispatch(setProposalsError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setProposalsError(error as OriginalResponse));
		}
	};
export const getUserProposalsList =
	(token: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IUserProposalList } = await fetchUserProposalsList(token);
			if (response.data) {
				dispatch(setUserProposalsList(response?.data || null, token));
			} else {
				dispatch(setProposalsError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setProposalsError(error as OriginalResponse));
		}
	};

// PROPOSAL ACTIONS
export const getProposalSendEmail =
	(token: string, proposalId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: ISendEmail } = await proposalSendEmail(token, proposalId);
			dispatch(setProposalSendEmail(response?.data || null));
		} catch (error) {
			dispatch(setProposalSendEmail(null));
		}
	};
export const submitProposalSendEmail =
	(token: string, proposalId: number, payload: ISendEmail) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await sendProposalSendEmail(token, proposalId, payload);
			dispatch(setPostProposalSendEmail(response, token));
			dispatch(setUserProposalsList(null, token));
			dispatch(setProposalsList(null));
		} catch (error) {
			dispatch(setPostProposalSendEmail(null));
		}
	};
export const submitProposalAccept =
	(token: string, proposalId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await proposalAccept(token, proposalId);
			dispatch(setProposalAccept(response.data));
			dispatch(setUserProposalsList(null, token));
			dispatch(setProposalsList(null));
		} catch (error) {
			dispatch(setProposalAccept(null));
		}
	};
export const submitProposalRefuse =
	(token: string, proposalId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await proposalRefuse(token, proposalId);
			dispatch(setProposalRefuse(response.data));
			dispatch(setUserProposalsList(null, token));
			dispatch(setProposalsList(null));
		} catch (error) {
			dispatch(setProposalRefuse(null));
		}
	};
export const submitProposalClose =
	(token: string, proposalId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await proposalClose(token, proposalId);
			dispatch(setProposalClose(response.data));
			dispatch(setUserProposalsList(null, token));
			dispatch(setProposalsList(null));
		} catch (error) {
			dispatch(setProposalClose(null));
		}
	};
export const submitProposalCancel =
	(token: string, proposalId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await proposalCancel(token, proposalId);
			dispatch(setProposalCancel(response.data));
			dispatch(setUserProposalsList(null, token));
			dispatch(setProposalsList(null));
		} catch (error) {
			dispatch(setProposalCancel(null));
		}
	};
export const submitProposalRequestApproval =
	(token: string, proposalId: number, payload: { manager: string }) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await proposalRequestApproval(token, proposalId, payload);
			dispatch(setProposalRequestApproval(response.data));
			dispatch(setUserProposalsList(null, token));
			dispatch(setProposalsList(null));
		} catch (error) {
			dispatch(setProposalRequestApproval(null));
		}
	};
export const submitProposalChangeStartDate =
	(token: string, proposalId: number, payload: { startDate: string }) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await proposalChangeStartDate(token, proposalId, payload);
			dispatch(setProposalChangeStartDate(response.data));
			dispatch(setUserProposalsList(null, token));
			dispatch(setProposalsList(null));
		} catch (error) {
			dispatch(setProposalChangeStartDate(null));
		}
	};

export const submitProposalApprove =
	(token: string, proposalId: number, manager: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await proposalApprove(token, proposalId, manager);
			dispatch(setProposalApprove(response));
			dispatch(setUserProposalsList(null, token));
			dispatch(setProposalsList(null));
		} catch (error) {
			dispatch(setProposalApprove(null));
		}
	};

export const submitProposalReject =
	(token: string, proposalId: number, manager: string, payload: IRejectProposalPost) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await proposalReject(token, proposalId, manager, payload);
			dispatch(setProposalReject(response));
			dispatch(setUserProposalsList(null, token));
			dispatch(setProposalsList(null));
		} catch (error) {
			dispatch(setProposalReject(null));
		}
	};

// PROPOSAL FORM GETS
export const getNewProposal =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IGetNewProposal } = await fetchNewProposal();
			if (response.data) {
				dispatch(setNewProposal(response?.data || null));
			} else {
				dispatch(setProposalsError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setProposalsError(error as OriginalResponse));
		}
	};

export const getNewProposalWToken =
	(token: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IGetNewProposal } = await fetchNewProposalWToken(token);
			if (response.data) {
				dispatch(setNewProposalWToken(response?.data || null));
			} else {
				dispatch(setProposalsError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setProposalsError(error as OriginalResponse));
		}
	};
export const getEditProposal =
	(token: string, id: number | string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IGetNewProposal } = await fetchEditProposal(token, id);
			if (response.data) {
				dispatch(setEditProposal(response?.data || null));
			} else {
				dispatch(setProposalsError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setProposalsError(error as OriginalResponse));
		}
	};
// PROPOSAL FORM SUBMITS
export const submitNewProposal =
	(payload: IProposalPayload, token: string | boolean = false) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await postNewProposal(payload, token);
			dispatch(setPostNewProposal(response));
		} catch (error) {
			dispatch(setPostNewProposal(null));
		}
	};
export const submitUpdateProposal =
	(payload: IProposalPayload, token: string, id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await putEditProposal(payload, token, id);
			dispatch(setPutUpdateProposal(response));
		} catch (error) {
			dispatch(setPutUpdateProposal(null));
		}
	};

// proposals helpers
export const getProposalAnnualGrossValue =
	(payload: IAnualGrossValuePost) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IAnualGrossValueGet } = await fetchProposalAnnualGrossValue(payload);
			dispatch(setProposalAnnualGrossValue(response.data || null));
		} catch (error) {
			dispatch(setProposalAnnualGrossValue(null));
		}
	};
export const getIrsValue =
	(payload: IIrsValuePost) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IIrsValueGet } = await fetchIrsValue(payload);
			dispatch(setIrsValue(response.data || null));
		} catch (error) {
			dispatch(setIrsValue(null));
		}
	};

export const getProposalSummary =
	(payload: IProposalSummary, formFields: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setProposalSummaryLoading(true));
			const { data } = await fetchProposalSummary(payload);
			dispatch(
				setProposalSummary(
					{
						...data?.summary,
						prizeBonusGoalTypes: data?.prizeBonusGoalTypes,
						exportConfig: data?.exportConfig
					} || null
				)
			);
			dispatch(setProposalSummaryLoading(false));
		} catch (error) {
			dispatch(setProposalSummary(null));
		}
	};
export const getExistingProposalSummary =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await fetchExistingProposalSummary(payload);
			dispatch(
				setExistingProposalSummary(
					data
						? {
								...data?.summary,
								formFields: data?.formFields,
								prizeBonusGoalTypes: data?.prizeBonusGoalTypes,
								exportConfig: data?.exportConfig
						  }
						: undefined
				)
			);
		} catch (error) {
			dispatch(setExistingProposalSummary(null));
		}
	};
export const clearSummary =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setClearSummary());
	};
export const clearProposals =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setUserProposalsList(null));
		dispatch(setProposalsList(null));
	};
export const getProposalExport =
	(exportType: string, payload: IDownloadProposal) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await fetchProposalExport(exportType, payload);
			dispatch(setProposalExport());
		} catch (error) {
			dispatch(setProposalExport());
		}
	};
export const getProposalExportWithID =
	(exportType: string, token: string, id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await fetchProposalExportWithID(exportType, token, id);
			dispatch(setProposalExportWithID());
		} catch (error) {
			dispatch(setProposalExportWithID());
		}
	};

export const clearProposalsFormsData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(resetFormData());
	};

export const getProposalAuditLog =
	(token: string, id: number | string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IProposalAuditLog } = await fetchProposalAuditLog(token, id);
			dispatch(setProposalAuditLog(response?.data || null));
		} catch (error) {
			dispatch(setProposalAuditLog(null));
		}
	};

export const clearProposalAuditLog =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setProposalAuditLog(null));
	};

export const clearProposalsError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setProposalsError(null));
	};
