import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {},
	fullScreenContent: {
		padding: '0px 0px 0 0px'
	},
	editableLabel: {
		minWidth: '25px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start'
	},
	iconContainer: {
		margin: '0 0 0 5px',
		padding: '0',
		color: theme.accentColor
	},
	dueDateIcons: {
		// direction: 'row',
		justify: 'center',
		alignItems: 'center',
		display: 'flex'
	},
	avatarContainer: {
		width: '50px',
		height: '50px',
		margin: 'auto',
		position: 'relative',
		overflow: 'hidden',
		borderRadius: '50%'
	},
	avatarImage: {
		display: 'inline',
		margin: '0 auto',
		height: '50px',
		width: 'auto'
	},
	centerInParent: {
		margin: 'auto',
		display: 'block'
	}
}));

export { useStyles };
