import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	rows: {
		width: '100%'
	},
	gridForm: {
		width: '100%',
		height: '100%'
	},
	tab: {
		fontWeight: 600,
		letterSpacing: '2px',
		color: theme.palette.secondary.main,
		padding: theme.spacing(1, 3),
		whiteSpace: 'nowrap',
		maxWidth: 'none'
	},
	tabsContainer: {
		padding: '30px 30px 0 30px',
		height: 'calc(100vh - 320px)',
		minHeight: '640px'
	},
	loadFromUserStatus: {
		minWidth: '150px',
		display: 'flex'
	},
	loadFromUser: {
		display: 'flex'
	},
	pageForm: {
		height: '100%',
		width: '100%'
	},
	tabsRoot: {
		height: '100%',
		overflowX: 'hidden'
	},
	responseMessageLine: {
		fontSize: '14px',
		color: '#718FA2',
		textTransform: 'uppercase',
		marginBottom: '30px',
		letterSpacing: '1.2px'
	},
	backButton: {
		color: theme.grayedColor,
		fontSize: '16px',
		marginBottom: '20px',
		backgroundColor: 'transparent'
	}
}));

export { useStyles };
