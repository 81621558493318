/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, Input } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS

// import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';
import { INewClientTabs } from '../../NewClient';
import { useSelector } from 'react-redux';

//* COMPONENT INTERFACES
// interface IProjectTeam extends IProjectTabs {}

//* COMPONENT
const Client: FC<INewClientTabs> = ({ control, errors, actions, companySelectOptions, setCompanyHelper }) => {
	const classes = useStyles();
	//@ts-ignore
	const companyName = useSelector(state => state.company.companyText)
	return (
		<Grid container spacing={4} className={classes.root}>
			{/* ********* Client *********** */}
			<Grid item {...sizes[6]}>
				<Select
					name="company"
					label="Company"
					options={companySelectOptions.company}
					additionalOnChange={(item: { name: string; value: string }) => {
						setCompanyHelper({
							company: item.value
						});
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="action"
					label="Action"
					options={actions}
					helperText="Action to perform over the client"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="name"
					label="Name"
					helperText="Client full name"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="searchKey"
					label="Search Key"
					helperText="Text to use when searching for this client"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="fiscalCode"
					label="Fiscal Code"
					helperText="Client's Fiscal Identity Number. Format may vary according to its country"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="group"
					label="Client Group"
					helperText="Group where client belongs for allocation purposes"
					options={companySelectOptions.clientGroups}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="salesRepresentative"
					label="Sales Representative"
					helperText={`${companyName} Sales Representative for this client`}
					options={companySelectOptions.salesRepresentatives}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="priceList"
					label="Price List"
					helperText="The Sales Price List applicable to this client"
					options={companySelectOptions.salePriceLists}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="paymentTerm"
					label="Payment Term"
					helperText="The Payment Term applicable to this client"
					options={companySelectOptions.paymentTerms}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
		</Grid>
	);
};

export default Client;
