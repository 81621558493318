//* EXTERNAL LIBS
import React, { FC, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';
import { Send } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, BackPage, ActionFooter } from 'xpand-ui/core';
import { Input, TextEditor } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { ISendEmail } from 'typings/store/admin/proposals';
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { SendProposalEmailProps } from '.';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES
interface ISendProposalEmail extends SendProposalEmailProps {
	match: Match<{
		token: string;
		proposalId: string | number;
	}>;
	goToPage: (path: string) => void;
}

//* COMPONENT
const SendProposalEmail: FC<ISendProposalEmail> = ({
	match,
	goToPage,
	proposals,
	getProposalSendEmail,
	submitProposalSendEmail
}) => {
	const classes = useStyles();
	const { params } = match;
	const { sendEmailPayload, proposalsActions, loading } = proposals;

	const token = params?.token;
	const proposalId = params?.proposalId;

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		if (proposalsActions && !proposalsActions.code) {
			goToPage(`/admin/recruitment/proposals/${token}`);
		}
	}, [proposalsActions]);

	useEffect(() => {
		if (!sendEmailPayload && token && proposalId && proposalsActions === null) {
			getProposalSendEmail(token, proposalId as number);
		} else if (sendEmailPayload && token && proposalId) {
			reset(sendEmailPayload);
		}
	}, [sendEmailPayload, token]);

	const onSubmit = (data: ISendEmail) => {
		submitProposalSendEmail(token, proposalId as number, data);
	};
	// PAGE FOOTER ACTIONS
	const footerActions = useMemo(
		() => [
			{
				id: 'submit',
				endIcon: <Send />,
				label: 'Send Email',
				onClick: () => ({}),
				type: 'submit',
				form: 'form-send-proposal-email',
				variant: 'contained',
				disabled: getLSField('impersonate_userInfo')
			}
		],
		[]
	);

	const isLoading = sendEmailPayload === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<BackPage path={`/admin/recruitment/proposals/${token}`} action={goToPage} />
			<div className={classes.root}>
				<form id="form-send-proposal-email" onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={4}>
						<Grid item {...sizes[8]}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextEditor
										name="mailBody"
										label="Message"
										height={700}
										required
										control={control}
										errors={errors}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item {...sizes[4]}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Input name="from" label="From" required control={control} errors={errors} />
								</Grid>
								<Grid item xs={12}>
									<Input name="to" label="To" required control={control} errors={errors} />
								</Grid>
								<Grid item xs={12}>
									<Input name="cc" label="CC" required control={control} errors={errors} />
								</Grid>
								<Grid item xs={12}>
									<Input name="subject" label="Subject" required control={control} errors={errors} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</div>
			<ActionFooter actions={footerActions} />
		</>
	);
};

export default withLayout(SendProposalEmail);
