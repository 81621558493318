/* eslint-disable jsx-a11y/anchor-is-valid */
//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import { Grid, Button } from '@mui/material';
import { Send } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, BackPage } from 'xpand-ui/core';
import { Input, TextEditor } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { Match } from 'typings/store/generalTypes';
import { IWelcomeEmail } from 'typings/store/admin/users';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { IPageTitle } from 'components/App/TitleProvider';
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { SendWelcomeEmailProps } from '.';

interface ISendWelcomeEmail extends SendWelcomeEmailProps, IPageTitle {
	goToPage: (path: string) => void;
	match: Match<{
		username: string;
	}>;
}

const SendWelcomeEmail: FC<ISendWelcomeEmail> = ({ goToPage, match, users, getWelcomeEmail, sendPostWelcomeEmail }) => {
	const classes = useStyles();
	const { params } = match;
	const { welcomeEmail, welcomeEmailAction, loading } = users;

	const username = params?.username;

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		if (welcomeEmailAction && !welcomeEmailAction?.code) {
			goToPage(`/admin/employees/profile/employees/${username}`);
		}
	}, [welcomeEmailAction]);

	useEffect(() => {
		if (!welcomeEmail && username) {
			getWelcomeEmail(username);
		} else {
			reset(welcomeEmail as IWelcomeEmail);
		}
	}, [welcomeEmail, username]);

	const onSubmit = (data: IWelcomeEmail) => {
		sendPostWelcomeEmail(username, data);
		goToPage(`/admin/employees/profile/employees/${username}`);
	};

	const isLoading = welcomeEmail === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			{/* Forced window reload is necessary due to limitations with the text editor component */}
			<BackPage path={`/admin/employees/profile/employees/${username}`} action={goToPage} />
			<div className={classes.root}>
				<form id="form-send-welcome-email" onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={4}>
						<Grid item {...sizes[8]}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextEditor
										name="mailBody"
										label="Message"
										height={700}
										required
										control={control}
										errors={errors}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item {...sizes[4]}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Input name="to" label="To" required control={control} errors={errors} />
								</Grid>
								<Grid item xs={12}>
									<Input name="cc" label="CC" required control={control} errors={errors} />
								</Grid>
								<Grid item xs={12}>
									<Input name="subject" label="Subject" required control={control} errors={errors} />
								</Grid>
								<Grid item xs={12} style={{ textAlign: 'center' }}>
									<Button
										color="primary"
										style={{ margin: '100px auto' }}
										variant="contained"
										type="submit"
										endIcon={<Send />}>
										Send Welcome Email
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</div>
		</>
	);
};

export default withLayout(SendWelcomeEmail);
