import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from 'typings/state';

import {
	getNewClient,
	sendNewClient,
	clearProjectAndClientsError
} from 'store/administration/projectsAndClients/thunks';

import NewClient from './NewClient';

const mapStateToProps = ({ projectsAndClients }: AppState) => ({ projectsAndClients });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getNewClient, sendNewClient, clearProjectAndClientsError }, dispatch);

export type NewClientProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NewClient);
