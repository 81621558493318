/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import React, { FC } from 'react';

import { Grid } from '@mui/material';
import { GroupCollapse as XPGroupCollapse } from 'xpand-ui/core';
import { useStyles } from './styles';

const GroupCollapse: FC<any> = ({ grid, collapse, removePaddingBottom, children, ...others }) => {
	const classes = useStyles();

	return (
		<Grid item xs={12} sm={12} md={12} lg={10} xl={9} {...grid} className={classes.groupCardProposals}>
			<XPGroupCollapse
				showContent
				maxWidth="100%"
				customClasses={{ items: classes.items }}
				{...collapse}
				{...others}
				component={
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={5}
						className={removePaddingBottom ? classes.formContainerNoPaddingBotton : classes.formContainer}>
						{children}
					</Grid>
				}
			/>
		</Grid>
	);
};

export default GroupCollapse;
