// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			gap: '20px',
			height: 'calc(100vh - 200px)'
		}
	})
);

export { useStyles };
