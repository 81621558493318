import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { deepOrange, deepPurple } from '@mui/material/colors';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {},
	appBar: {
		position: 'relative'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	orange: {
		color: theme.palette.getContrastText(deepOrange[500]),
		backgroundColor: deepOrange[500]
	},
	purple: {
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500]
	},
	fullScreenContent: {
		padding: '25px 75px 0 75px'
	},
	editableLabel: {
		minWidth: '25px'
	},
	iconContainer: {
		margin: '0 0 0 5px',
		padding: '0'
	},
	dueDateIcons: {
		// direction: 'row',
		justify: 'center',
		alignItems: 'center',
		display: 'flex'
	},
	centerInParent: {
		margin: 'auto',
		display: 'block'
	}
}));

export { useStyles };
