import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getReleaseNotesEmail,
	getReleasesList,
	getReleasesNotesList,
	sendResetAllNewReleasePopups
} from 'store/administration/administration/thunks';
import { setReleaseHistoryPageFilter } from 'store/filters/actions';
import { AppState } from 'typings/state';

import ReleaseNotes from './ReleaseNotes';

const mapStateToProps = ({ administration, filters }: AppState) => ({ administration, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getReleasesList,
			getReleasesNotesList,
			getReleaseNotesEmail,
			setReleaseHistoryPageFilter,
			sendResetAllNewReleasePopups
		},
		dispatch
	);

export type ReleaseNotesProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);
