/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { FC, useState } from 'react';
import { Grid, Popover, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { sizes, ISizes } from 'xpand-ui/utils/handlers';
import { StepsBar } from 'xpand-ui/forms';
import { ISkill, ISkillGroup, ISkillSubGroup, IUserSkills } from 'typings/store/personalInfoTypes';

/** Select Field Options */
export const knowledgeNamesArray = ['None', 'Basic', 'Intermediate', 'Advanced'];
export const experiencedNamesArray = [
	'None',
	'Less than 1 Year',
	'Between 1 and 2 Years',
	'Between 2 and 5 Years',
	'More than 5 Years'
];
export const languagesNamesArray = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

/** Components struct */
export const GroupCard: FC<any> = props => {
	const { classes } = props;
	return <Grid {...props} classes={{}} className={classes.groupCardSkills} item {...sizes[12]} />;
};

const normalCardColumnSizes = {
	first: {
		item: true,
		...(sizes[4] as ISizes)
	},
	second: {
		item: true,
		...(sizes[4] as ISizes)
	},
	third: {
		item: true,
		...(sizes[4] as ISizes)
	}
};
export const NormalSkillCardHeader: FC<any> = props => {
	const newProps = (({ classes, subgroup, isMobile, isTablet, ...o }) => o)(props);
	const { classes, subgroup, isMobile, isTablet } = props;

	const groupTitle = subgroup.section && subgroup.name;

	return (
		<>
			{!isMobile && !isTablet && (
				<Grid {...newProps} {...normalCardColumnSizes.first} className={classes.skillHeaderLabel}>
					{groupTitle}
				</Grid>
			)}
			{!isMobile && (
				<Grid {...newProps} {...normalCardColumnSizes.second} className={classes.skillHeaderInputs}>
					{isTablet ? groupTitle : 'Experience level'}
				</Grid>
			)}
			<Grid {...newProps} {...normalCardColumnSizes.third} className={classes.skillHeaderInputs}>
				{!isTablet && !isMobile && 'Knowledge level'}
				{/* {isTablet && !isMobile && ''} */}
				{isTablet && isMobile && groupTitle}
			</Grid>
			<Grid item xs={12} className={classes.skillHeaderLine} />
		</>
	);
};
export const NormalSkillCardRow: FC<any> = props => {
	const newProps = (({ classes, label, imp1, imp2, isMobile, isTablet, ...o }) => o)(props);
	const { classes, label, imp1, imp2, isMobile, isTablet } = props;

	return (
		<>
			{!isMobile && !isTablet && (
				<Grid {...newProps} {...normalCardColumnSizes.first} className={classes.skillLabel}>
					{label}
				</Grid>
			)}
			{!isMobile && (
				<Grid {...newProps} {...normalCardColumnSizes.second} className={classes.skillLabel}>
					{isTablet && label}
					{imp1}
				</Grid>
			)}
			<Grid {...newProps} {...normalCardColumnSizes.third} className={classes.skillLabel}>
				{isMobile && label}
				{isMobile && imp1}
				{imp2}
			</Grid>
			<div style={{ width: '100%' }} />
		</>
	);
};

const langCardColumnSizes = {
	first: {
		item: true,
		...(sizes[4] as ISizes)
	},
	second: {
		item: true,
		...(sizes[4] as ISizes)
	},
	third: {
		item: true,
		...(sizes[3] as ISizes)
	}
};
export const LanguageSkillCardHeader: FC<any> = props => {
	const newProps = (({ classes, subgroup, isMobile, isTablet, ...o }) => o)(props);
	const { classes, subgroup, isMobile, isTablet } = props;

	const [anchorEl, setAnchorEl] = useState<any>(null);

	const handleClick = (event: any) => setAnchorEl(event.currentTarget);

	const langTitle = (
		<>
			{subgroup.section && subgroup.name}
			{/* FIXME */}
			{/* this needs to be fixed, the iconButton does not have the onClick function */}
			{/* but changing this right now will consume time and we need it to change it */}
			{/* correctly to not break anything visually. */}
			<IconButton color="primary" onClick={handleClick}>
				<InfoOutlinedIcon />
			</IconButton>
			<Popover
				id="LanguageCard"
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}>
				<div style={{ padding: '15px' }}>
					<table>
						<tbody>
							<tr>
								<td>
									<b>A1</b>
								</td>
								<td>
									Basic User - Language introduction and discovery. Can read a very short and
									rehearsed statement.
								</td>
							</tr>
							<tr>
								<td>
									<b>A2</b>
								</td>
								<td>
									Basic User - Survival and comprehension. Can give a short, rehearsed and basic
									presentation on a familiar topic.
								</td>
							</tr>
							<tr>
								<td>
									<b>B1</b>
								</td>
								<td>
									Independent User - Can access a more complex communication. Can give a straight
									forward presentation within his/her field of expertise or on a familiar topic.
								</td>
							</tr>
							<tr>
								<td>
									<b>B2</b>
								</td>
								<td>
									Independent User - Elaborate verbal expression and capable of communicating with
									natives. Can give a clear, prepared presentation.
								</td>
							</tr>
							<tr>
								<td>
									<b>C1</b>
								</td>
								<td>
									Proficient User - Can give a clear, well-structured presentation of a complex
									subject.
								</td>
							</tr>
							<tr>
								<td>
									<b>C2</b>
								</td>
								<td>
									Proficient User - Language domain. Can present a complex topic, confidently and
									articulately, to an audience unfamiliar with it.{' '}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Popover>
		</>
	);

	return (
		<>
			{!isMobile && !isTablet && (
				<Grid {...newProps} {...langCardColumnSizes.first} className={classes.skillHeaderLabelLanguages}>
					{langTitle}
				</Grid>
			)}
			{!isMobile && (
				<Grid {...newProps} {...langCardColumnSizes.second} className={classes.skillHeaderInputsLanguages}>
					{isTablet ? langTitle : 'Skill name'}
				</Grid>
			)}
			<Grid {...newProps} {...langCardColumnSizes.third} className={classes.skillHeaderInputsLanguages}>
				{!isTablet && !isMobile && 'Level'}
				{isTablet && !isMobile && 'Skill name & level'}
				{isTablet && isMobile && langTitle}
			</Grid>
			<Grid item {...sizes[12]} className={classes.skillHeaderLine} />
		</>
	);
};
export const LanguageSkillCardRow: FC<any> = props => {
	const newProps = (({ classes, label, imp1, imp2, isMobile, isTablet, ...o }) => o)(props);
	const { classes, label, imp1, imp2, isMobile, isTablet } = props;
	return (
		<>
			{!isMobile && !isTablet && (
				<Grid {...newProps} {...langCardColumnSizes.first} className={classes.skillLabel}>
					{imp2}
				</Grid>
			)}
			{!isMobile && (
				<Grid {...newProps} {...langCardColumnSizes.second} className={classes.skillLabel}>
					{label}
				</Grid>
			)}
			<Grid {...newProps} {...langCardColumnSizes.third} className={classes.skillLabel}>
				{imp1}
			</Grid>
			<div style={{ width: '100%' }} />
		</>
	);
};

interface IAddStructSkills {
	[key: number]: {
		knowledgeLevel: number;
		experienceLevel: number;
	};
}

/** Components struct */
/** FormData helpers */
export const addSkillsToStruct = (skills: ISkill[], employeeSkills?: IUserSkills): IAddStructSkills => {
	if (!skills) return {};

	const payload = {};

	// loop through the skills
	skills?.forEach(sk => {
		if (employeeSkills) {
			// validate user saves skills to return only existing skills
			const existingSkill = employeeSkills.evaluations.find(e => e.skillId === sk.id);

			if (existingSkill) {
				payload[sk.id] = {
					knowledgeLevel: existingSkill.knowledgeLevel,
					experienceLevel: existingSkill.experienceLevel
				};
			}
		} else {
			// add skills payload to object
			payload[sk.id] = {
				knowledgeLevel: 0,
				experienceLevel: 0
			};
		}
	});
	return payload;
};

interface IAddStructSubGroup {
	toAdd?: {
		[key: number]: IAddStructSkills;
	};
	toRender?: {
		[key: number]: IAddStructSkills;
	};
}
export const addSubGroupsToStruct = (
	subGroups: ISkillSubGroup[],
	employeeSkills: IUserSkills,
	addSkills: boolean
): IAddStructSubGroup => {
	if (!subGroups) return {};

	const userSubGroups = employeeSkills?.subGroups; // FIXME: 'groups' should be 'subGroups'
	const toRender = {};
	const toAdd = {};

	// loop through the subGroups
	subGroups?.forEach(sub => {
		// validate user saves skills to return only existing skills
		if (userSubGroups && sub.id) {
			if (userSubGroups.some(e => e.skillSubGroupId === sub.id)) {
				// add skills to object
				if (addSkills && sub.skills) {
					const skillsPayload = addSkillsToStruct(sub.skills, employeeSkills);
					if (skillsPayload) {
						toRender[sub.id] = { ...skillsPayload };
					}
				} else {
					// add empty object
					toRender[sub.id] = {};
				}
			} else {
				// add skills to object
				if (addSkills && sub.skills) {
					toAdd[sub.id] = { ...addSkillsToStruct(sub.skills) };
				} else {
					// add empty object
					toAdd[sub.id] = {};
				}
			}
		} else {
			// add skills to object
			if (addSkills && sub.skills && sub.id) {
				toRender[sub.id] = { ...addSkillsToStruct(sub.skills) };
			} else {
				// add empty object
				if (sub.id) toRender[sub.id] = {};
			}
		}
	});

	return { toRender, toAdd };
};

interface IAddStructGroupSkills {
	toAdd?: {
		[key: number]: {
			[key: number]: IAddStructSkills;
		};
	};
	toRender?: {
		[key: number]: {
			[key: number]: IAddStructSkills;
		};
	};
}
export const addGroupsToStruct = (
	groups: ISkillGroup[],
	employeeSkills: IUserSkills,
	addSubGroups: boolean,
	addSkills: boolean
): IAddStructGroupSkills => {
	if (!groups) return {};

	const toRender = {};
	const toAdd = {};

	// loop through the groups
	groups?.forEach(group => {
		// add subgroups to object
		if (addSubGroups && group.skillSubGroups) {
			const subPayload = addSubGroupsToStruct(group.skillSubGroups, employeeSkills, addSubGroups && addSkills);

			// validate if subgroup is to render
			if (subPayload.toRender && Object.keys(subPayload.toRender).length > 0 && group.id)
				// add subGroup object
				toRender[group.id] = {
					...subPayload.toRender
				};

			if (subPayload.toAdd && Object.keys(subPayload.toAdd).length > 0 && group.id)
				// add subGroup object
				toAdd[group.id] = {
					...subPayload.toAdd
				};
		} else {
			// add empty object
			if (group.id) toRender[group.id] = {};
		}
	});
	return { toRender, toAdd };
};

interface IAddStructFormData {
	[key: number]: {
		[key: number]: {
			[key: number]: IAddStructSkills;
		};
	};
}

export const filterOnlyUserSkills = (
	formData: IAddStructFormData,
	toAddForm: {
		[key: number]: {
			[key: number]: IAddStructSkills;
		};
	},
	skills: ISkillGroup[]
) => {
	let skillsRender: ISkillGroup[] = [];
	let skillsToAdd: ISkillGroup[] = [];

	if (formData && toAddForm && skills && skills.length > 0) {
		//
		// FILTER SKILLS TO RENDER / REMOVE MODAL
		skillsRender = skills
			.filter(e => (e.id ? formData[e.id] : null)) // filter by skill GROUPS that will show in screen
			.sort((a, b) => (a.weight as number) - (b.weight as number)) // sort by db field weight
			.map(e => {
				// filter by skill SUB GROUPS that will show in screen
				const newSkillSubGroups = e.skillSubGroups?.filter(sub => formData[e.id as number][sub.id as number]);
				return {
					...e,
					skillSubGroups: newSkillSubGroups,
					hasSubGroupsToAdd: newSkillSubGroups?.length !== 0,
					section: newSkillSubGroups?.length
						? newSkillSubGroups?.length > 1 ||
						  (newSkillSubGroups.length === 1 && newSkillSubGroups[0].section)
						: false
				};
			})
			.filter(e => e.hasSubGroupsToAdd); // remove groups without subgroups in this list

		// FILTER SKILLS TO ADD MODAL
		skillsToAdd = skills
			.filter(e => (e.id ? toAddForm[e.id] : null)) // filter by skill GROUPS that are not shown on the screen
			.sort((a, b) => (a.weight as number) - (b.weight as number)) // sort by db field weight
			.map(e => {
				// filter by skill SUB GROUPS that will show in screen
				const newSkillSubGroups =
					e.skillSubGroups &&
					e.skillSubGroups
						.filter((sub: ISkillSubGroup) => {
							if (e.id && sub.id) {
								return toAddForm[e.id][sub.id]; // remove groups with no subgroups
							}
							return false;
						})
						.filter((sub: ISkillSubGroup) => {
							if (sub && sub.skills?.length) {
								return sub.skills.length > 0;
							}
							return false;
						}); // remove empty subgroups

				return {
					...e,
					skillSubGroups: newSkillSubGroups,
					hasSubGroupsToAdd: newSkillSubGroups?.length !== 0,
					section: newSkillSubGroups?.length
						? newSkillSubGroups?.length > 1 ||
						  (newSkillSubGroups.length === 1 && newSkillSubGroups[0].section)
						: false
				};
			});
	}

	return { skillsRender, skillsToAdd };
};

// HELPER TO CREATE EACH SKILL ROW WITH CORRESPONDENT INPUTS
export const normalGroup = (
	classes: Record<string, string>,
	formData: IAddStructFormData,
	areaId: number,
	updateFormTree: (
		area: number,
		group: number,
		sub: number,
		skill: number,
		experienceLevel: number | null,
		knowledgeLevel: number | null
	) => void,
	group: ISkillGroup,
	sub: ISkillSubGroup,
	skill: ISkill,
	readOnly = false,
	isMobile: boolean,
	isTablet: boolean
) => {
	const key = `${group.id}_${sub.id}_${skill.id}_skill`;

	return (
		group.id &&
		sub.id && (
			<NormalSkillCardRow
				key={key}
				name={key}
				classes={classes}
				label={skill.name}
				isMobile={isMobile}
				isTablet={isTablet}
				imp1={
					<StepsBar
						id={key}
						readOnly={readOnly}
						showLabelOnHelper={isTablet}
						clearAlwaysVisible={readOnly ? false : isMobile}
						label={isTablet && 'Experience level'}
						labels={experiencedNamesArray}
						numButtons={experiencedNamesArray.length - 1}
						color="#00b0ff"
						value={formData[areaId][group.id][sub.id][skill.id]?.experienceLevel || 0}
						handleChange={(newVal: number) =>
							updateFormTree(areaId, group.id as number, sub.id as number, skill.id, newVal, null)
						}
					/>
				}
				imp2={
					<StepsBar
						id={key}
						readOnly={readOnly}
						showLabelOnHelper={isTablet}
						clearAlwaysVisible={readOnly ? false : isMobile}
						label={isTablet && 'Knowledge level'}
						labels={knowledgeNamesArray}
						numButtons={knowledgeNamesArray.length - 1}
						value={formData[areaId][group.id][sub.id][skill.id]?.knowledgeLevel || 0}
						handleChange={(newVal: number) =>
							updateFormTree(areaId, group.id as number, sub.id as number, skill.id, null, newVal)
						}
					/>
				}
			/>
		)
	);
};

// HELPER TO CREATE EACH SKILL ROW WITH CORRESPONDENT INPUTS
export const languagesGroup = (
	classes: Record<string, string>,
	formData: IAddStructFormData,
	areaId: number,
	updateFormTree: (
		area: number,
		group: number,
		sub: number,
		skill: number,
		experienceLevel: number | null,
		knowledgeLevel: number | null
	) => void,
	group: ISkillGroup,
	sub: ISkillSubGroup,
	skill: ISkill,
	readOnly = false,
	isMobile: boolean,
	isTablet: boolean
) => {
	const key = `${group.id}_${sub.id}_${skill.id}_skill`;
	return (
		group.id &&
		sub.id && (
			<LanguageSkillCardRow
				key={`${group.id}_${sub.id}_${skill.id}_skill`}
				name={`${group.id}_${sub.id}_${skill.id}_skill`}
				classes={classes}
				label={!isMobile && skill.name}
				isMobile={isMobile}
				isTablet={isTablet}
				imp1={
					<StepsBar
						id={key}
						readOnly={readOnly}
						label={isMobile && skill.name}
						labels={languagesNamesArray}
						showLabelOnHelper={isMobile}
						clearAlwaysVisible={readOnly ? false : isMobile}
						color="#43a047"
						numButtons={languagesNamesArray.length - 1}
						value={formData[areaId][group.id as number][sub.id as number][skill.id].experienceLevel || 0}
						handleChange={(newVal: number) =>
							updateFormTree(areaId, group.id as number, sub.id as number, skill.id, newVal, null)
						}
					/>
				}
				imp2={null}
			/>
		)
	);
};
/** FormData helpers */
