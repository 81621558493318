/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/extensions */
import { EventModel, Field, Scheduler } from '@bryntum/scheduler/scheduler.umd.js';
import { parseDateToManage } from 'xpand-ui/utils/dates';
import { IChoosableBaseInfo, ISchedulerSelectOption } from 'typings/store/generalTypes';
import { IUserLDAP } from 'typings/store/admin/users';
import {
	BryntumEventChangesStruct,
	CustomEventModel,
	EventDateVerification,
	IEvent,
	IProject,
	OperationsType,
	PlannerBryntumModel
} from 'typings/store/admin/planner';
import moment from 'moment';
import { getLSField } from 'lib/utils/cookies';

const ALLOCATION_LIMIT = 100;

export const toggleButtons = [
	{ label: 'day', value: 'day' },
	{ label: 'week', value: 'week' },
	{ label: 'month', value: 'month' }
];

// remove all visible errors from edit event modal
export const clearWidgetErrors = (widget: Field): void => {
	const errors = widget.getErrors();
	if (errors) {
		errors.map((error: string) => widget.clearError(error));
	}
};

export const isNewEventOverlaping = (
	resouceEventStartDate: Date,
	resouceEventEndDate: Date,
	newEventStartDate: Date,
	newEventEndDate: Date
): boolean => resouceEventStartDate <= newEventEndDate && newEventStartDate <= resouceEventEndDate;

export const checkIfNewEventDateOverlap = (
	scheduler: Scheduler,
	eventRecord: CustomEventModel,
	values: EventDateVerification
): CustomEventModel[] => {
	const engine = scheduler;
	const result = engine.eventStore.query(
		(record: any) => record.data.resourceId === values.resourceId && eventRecord.id !== record.id
	);
	const overlappedEvents: CustomEventModel[] = [];
	result.map((resourceEvent: any) => {
		if (
			isNewEventOverlaping(
				resourceEvent.data.startDate,
				resourceEvent.data.endDate,
				values.startDate as Date,
				values.endDate as Date
			)
		) {
			overlappedEvents.push(resourceEvent);
		}
		return resourceEvent;
	});
	return overlappedEvents;
};

export const validateAllocation = (overlappedEvents: EventModel[], allocationPercentage: number): boolean => {
	let allocatedPercentageTotal = 0;
	// eslint-disable-next-line no-return-assign
	overlappedEvents.map((event: any) => (allocatedPercentageTotal += event.allocationPercentage));
	return allocatedPercentageTotal + allocationPercentage <= ALLOCATION_LIMIT;
};

export const getCenteredDate = (date: Date = new Date()): Date => {
	const currentDate = new Date(date);
	currentDate.setDate(currentDate.getDate() + 10); // center current date
	return currentDate;
};
// Since we are using the default drodown from bryntum we must follow the data struture {value, text}
export const getSchedulerProjectDropdownData = (serverProjects: IProject[]): ISchedulerSelectOption[] =>
	serverProjects.map(project => ({ value: project.id.toString(), text: project.name ? project.name : 'Unnamed' }));

// Filters
export const getFilterProjectDropdownData = (serverProjects: IProject[]): IChoosableBaseInfo[] => {
	const formatedProjects: IChoosableBaseInfo[] = [];
	serverProjects.map(project => formatedProjects.push({ id: project.id.toString(), label: project.name }));
	return formatedProjects;
};
export const getFilterOfficesDropdownData = (offices: IChoosableBaseInfo[]): IChoosableBaseInfo[] => {
	const formatedUsers: IChoosableBaseInfo[] = [];
	offices.map(office => formatedUsers.push({ id: office.name as string, label: office.name }));
	return formatedUsers;
};
export const getFilterSkillsDropdownData = (skills: IChoosableBaseInfo[]): IChoosableBaseInfo[] => {
	const formatedSkills: IChoosableBaseInfo[] = [];
	skills.map(skill => formatedSkills.push({ id: skill.id, label: skill.name }));
	return formatedSkills;
};
export const getFilterUsersDropdownData = (users: IUserLDAP[]): IChoosableBaseInfo[] => {
	const formatedUsers: IChoosableBaseInfo[] = [];
	users.map(user =>
		formatedUsers.push({
			id: user.username,
			label: (user.lastName && user.firstName.concat(' ', user.lastName)) || user.firstName || 'N/A'
		})
	);
	return formatedUsers;
};

const transformDate = (date: string | Date): string => moment(date).format('YYYY-MM-DD HH:mm:ss');

export const createPostEventsPayload = (changes: BryntumEventChangesStruct, action: string): IEvent[] => {
	const createdEvents: IEvent[] = [];
	const editedEvents: IEvent[] = [];
	const deletedEvents: IEvent[] = [];

	if (changes && changes.added.length && action === OperationsType.CREATE) {
		changes.added.map((addedEvent: PlannerBryntumModel) => {
			const parsedEvent: IEvent = {
				id: addedEvent.id,
				resourceId: addedEvent.resourceId,
				startDate: transformDate(addedEvent.data.startDate.toString()),
				projectID: addedEvent.data.projectID,
				endDate: transformDate(addedEvent.data.endDate.toString()),
				eventColor: addedEvent.data.eventColor,
				projectLabel: addedEvent.data.projectLabel,
				allocationPercentage: addedEvent.data.allocationPercentage,
				operation: OperationsType.CREATE
			};
			createdEvents.push(parsedEvent);
			return addedEvent;
		});
	}

	if (changes && changes.modified.length && action === OperationsType.CREATE) {
		changes.modified.map((modifiedEvent: PlannerBryntumModel) => {
			const parsedEvent: IEvent = {
				id: modifiedEvent.id,
				resourceId: modifiedEvent.resourceId,
				startDate: transformDate(modifiedEvent.data.startDate.toString()),
				projectID: modifiedEvent.data.projectID,
				endDate: transformDate(modifiedEvent.data.endDate.toString()),
				eventColor: modifiedEvent.data.eventColor,
				projectLabel: modifiedEvent.data.projectLabel,
				allocationPercentage: modifiedEvent.data.allocationPercentage,
				operation: OperationsType.EDIT
			};
			editedEvents.push(parsedEvent);
			return modifiedEvent;
		});
	}

	if (changes && changes.removed.length && action === OperationsType.DELETE) {
		changes.removed.map((removedEvent: PlannerBryntumModel) => {
			const parsedEvent: IEvent = {
				id: removedEvent.id,
				resourceId: removedEvent.resourceId,
				startDate: transformDate(removedEvent.data.startDate.toString()),
				projectID: removedEvent.data.projectID,
				endDate: transformDate(removedEvent.data.endDate.toString()),
				eventColor: removedEvent.data.eventColor,
				projectLabel: removedEvent.data.projectLabel,
				allocationPercentage: removedEvent.data.allocationPercentage,
				operation: OperationsType.DELETE
			};
			deletedEvents.push(parsedEvent);
			return removedEvent;
		});
	}
	return [...createdEvents, ...editedEvents, ...deletedEvents];
};

export const updateFilterCount = (filterHolder: {
	startDate?: string;
	endDate?: string;
	offices: string[];
	projects: string[];
	users: string[];
	skills: number[];
	showTimeOff: boolean;
}): number => {
	const internalConter =
		Number(!!filterHolder.startDate?.length) +
		Number(!!filterHolder.endDate?.length) +
		Number(!!filterHolder.offices?.length) +
		Number(!!filterHolder.projects?.length) +
		Number(!!filterHolder.users?.length) +
		Number(!!filterHolder.skills?.length) +
		Number(!!filterHolder.showTimeOff);

	return internalConter;
};
