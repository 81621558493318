import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getFileTemplates,
	sendPostChangeFileTemplateStatus,
	downloadFileTemplate,
	sendPostUploadFileTemplate,
	clearAdministrationError
} from 'store/administration/administration/thunks';
import { setFileTemplatesPageFilter } from 'store/filters/actions';
import { AppState } from 'typings/state';

import FileTemplates from './FileTemplates';

const mapStateToProps = ({ administration, filters }: AppState) => ({ administration, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getFileTemplates,
			sendPostChangeFileTemplateStatus,
			downloadFileTemplate,
			sendPostUploadFileTemplate,
			clearAdministrationError,
			setFileTemplatesPageFilter
		},
		dispatch
	);

export type FileTemplatesProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FileTemplates);
