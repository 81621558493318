import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { clearBooking } from 'store/personalInfo/actions';

import {
	getBooking,
	getBookingsList,
	submitNewBookings,
	submitNewBlockedSeats,
	clearBookingsList,
	clearNewBookingsList,
	clearPersonalInfoError,
	getEmployeeBookings,
	clearEmployeeBookingsList,
	confirmBooking,
	deleteBooking,
	getEmployeeBookingsCounter,
	exportBookings,
	submitEditBookings
} from 'store/personalInfo/thunks';
import { AppState } from 'typings/state';

import Booking from './Bookings';
import { setNotificationAsRead } from 'store/notifications/thunks';

const mapStateToProps = ({ personalInfo, company }: AppState) => ({ personalInfo, company });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getBooking,
			getBookingsList,
			submitNewBookings,
			submitEditBookings,
			submitNewBlockedSeats,
			clearBookingsList,
			clearNewBookingsList,
			clearPersonalInfoError,
			getEmployeeBookings,
			clearEmployeeBookingsList,
			confirmBooking,
			deleteBooking,
			getEmployeeBookingsCounter,
			exportBookings,
			clearBooking,
			setNotificationAsRead
		},
		dispatch
	);

export type BookingProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
