/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { Input, Checkbox, Numeric } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import GroupCollapse from '../components/GroupCollapse';
import { PROPOSAL, CSC, EMPLOYEE, COLLABTYPE } from '../constants';
import CustomBenefits from './CustomBenefits';
import { useStyles } from './styles';

//* COMPONENT INTERFACES
//* COMPONENT
const OtherBenefits: FC<any> = ({
	referenceData,
	updateIRSTax,
	defaultValues,
	control,
	clearErrors,
	errors,
	watch,
	reset,
	type,
	getValues,
	setValue,
	resetField,
	formFields
}) => {
	const classes = useStyles();

	const { isProp, isCSC, isEmp, isCollabType } = useMemo(
		() => ({
			isProp: type === PROPOSAL,
			isCSC: type === CSC,
			isEmp: type === EMPLOYEE,
			isCollabType: type === COLLABTYPE
		}),
		[type]
	);

	const typeName =
		(isProp && 'collaboration proposal') ||
		(isCSC && 'employee salary category') ||
		(isEmp && 'collaboration proposal') ||
		(isCollabType && 'edit collaboration type');

	const commsPlafondFlag = watch('commsPlafondFlag');
	const healthInsuranceCheck = watch('healthInsuranceCheck');
	const otherbenefits = watch('otherbenefits');
	const includeFamilyMembers = watch('includeFamilyMembers');

	return (
		<Grid
			container
			spacing={2}
			justifyContent="center"
			style={{
				paddingTop: '30px',
				paddingBottom: '20px'
			}}>
			<Grid item {...sizes[12]}>
				<Typography className={classes.tabInfo}>
					<PriorityHigh color="primary" /> Health Insurance and Communication Plafond are default checked,
					because it's part of company policy on new admissions. If you uncheck one of these benefits, please
					justify this on Comments field.
				</Typography>
			</Grid>
			<GroupCollapse
				collapse={{
					id: ' OtherBenefits',
					label: 'Other Benefits Information'
				}}>
				{/* COMMUNICATION BENEFITS */}
				{formFields.formFieldsConfig.communicationsPlafond && (
					<Grid item {...sizes[6]}>
						<Checkbox
							disabled={isCollabType}
							color="primary"
							name="commsPlafondFlag"
							label={
								formFields.formFieldsConfig.country == 'PT'
									? 'COMMUNICATIONS PLAFOND?'
									: 'COMMUNICATIONS (INTERNET + ELECTRICITY)'
							}
							additionalOnChange={(val: boolean) => {
								if (val) {
									setValue('commsPlafond', referenceData?.xphrMonStdComPlafond || 25);
								} else {
									setValue('commsPlafond', '0');
								}
							}}
							control={control}
							errors={errors}
						/>
						<div
							className={clsx(
								classes.animation,
								{
									[classes.show]: watch('commsPlafondFlag')
								},
								classes.inputBelowCheck
							)}>
							<Numeric
								required
								disabled={isCollabType}
								money
								name="commsPlafond"
								label={
									formFields.formFieldsConfig.country == 'PT'
										? 'Communications Plafond Value'
										: 'Communications (Internet + Electricity) Value'
								}
								control={control}
								errors={errors}
							/>
						</div>
					</Grid>
				)}
				{/* HEALTH INSURANCE BENEFITS */}
				{formFields.formFieldsConfig.healthInsurance && (
					<Grid item {...sizes[6]}>
						<Checkbox
							disabled={isCollabType}
							color="primary"
							name="healthInsuranceCheck"
							label="HEALTH INSURANCE?"
							additionalOnChange={(val: boolean) => {
								if (val) {
									setValue('healthInsuranceValue', referenceData?.xphrMonStdHeaInsCost);
								} else {
									setValue('healthInsuranceValue', '0');
									setValue('includeFamilyMembers', false);
									setValue('numberMembersDep', '0');
									setValue('familyMembersComments', '');
								}
							}}
							control={control}
							errors={errors}
						/>
						<div
							className={clsx(
								classes.animation,
								{
									[classes.show]: healthInsuranceCheck
								},
								classes.inputBelowCheck
							)}>
							<Numeric
								disabled={isCollabType}
								required
								money
								name="healthInsuranceValue"
								label="Health Insurance Value"
								control={control}
								errors={errors}
							/>
						</div>
					</Grid>
				)}
				{/* OTHER BENEFITS AREA */}
				{formFields.formFieldsConfig.hasOtherBenefits && (
					<Grid item {...sizes[6]}>
						<Checkbox
							disabled={isCollabType}
							color="primary"
							name="otherbenefits"
							label="HAS OTHER BENEFITS? "
							additionalOnChange={(val: boolean) => {
								// resetField('otherBenefitsList');
								if (!val) {
									setValue('otherBenefitsList', []);
								}
							}}
							control={control}
							errors={errors}
						/>
						{/* Custom Benefits  */}
						<div
							className={clsx(
								classes.animation,
								{
									[classes.show]: otherbenefits
								},
								classes.hiddenArea
							)}>
							<CustomBenefits
								referenceData={referenceData}
								updateIRSTax={updateIRSTax}
								control={control}
								clearErrors={clearErrors}
								errors={errors}
								watch={watch}
								reset={reset}
								getValues={getValues}
								setValue={setValue}
								isCollabType={isCollabType}
							/>
						</div>
					</Grid>
				)}
				{/* HEALTH INSURANCE FAMILY MEMBERS */}
				{formFields.formFieldsConfig.includeFamilyMembers && (
					<Grid
						item
						{...sizes[6]}
						className={clsx(classes.animation, {
							[classes.show]: healthInsuranceCheck
						})}>
						<Checkbox
							color="primary"
							name="includeFamilyMembers"
							label="INCLUDE FAMILY MEMBERS ?"
							additionalOnChange={(val: boolean) => {
								if (!val) {
									setValue('healthInsuranceValue', referenceData?.xphrMonStdHeaInsCost || 29.33);
									setValue('numberMembersDep', '0');
									setValue('familyMembersComments', '');
								}
							}}
							control={control}
							errors={errors}
						/>
						{/* HEALTH INSURANCE BENEFITS */}
						<div
							className={clsx(
								classes.animation,
								{
									[classes.show]: includeFamilyMembers
								},
								classes.hiddenArea
							)}>
							<Numeric
								required
								name="numberMembersDep"
								label="Number of Family Members"
								minimumValue="0"
								maximumValue="10"
								additionalOnBlur={val => {
									updateIRSTax(watch());
									if (referenceData.xphrMonStdHeaInsCost) {
										setValue(
											'healthInsuranceValue',
											referenceData.xphrMonStdHeaInsCost +
												referenceData.xphrMonStdHeaInsCost * Number(val)
										);
									}
								}}
								control={control}
								errors={errors}
							/>
							<p />
							{/* HEALTH INSURANCE FAMILY MEMBERS NUMBER */}
							<Input
								name="familyMembersComments"
								label="Include Family in Insurance Details"
								multiline
								placeholder={`Check with employee after ${typeName} process has ended.`}
								helperText={`Check with employee after ${typeName} process has ended.`}
								minRows={4}
								maxRows={8}
								control={control}
								errors={errors}
							/>
						</div>
					</Grid>
				)}
				{/* OTHER COMMENTS */}
				{formFields.formFieldsConfig.otherComments && (
					<Grid item {...sizes[12]}>
						<Input
							required={!commsPlafondFlag || !healthInsuranceCheck}
							name="benefitsComments"
							label="Other Comments"
							multiline
							placeholder="Comments"
							minRows={4}
							maxRows={8}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
			</GroupCollapse>
		</Grid>
	);
};

export default withLayout(OtherBenefits);
