//* EXTERNAL LIBS
import React, { FC, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter } from 'xpand-ui/core';
import { Input } from 'xpand-ui/forms';

//* TYPINGS
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { RejectCostSalaryCategoryProps } from '.';

//* COMPONENT INTERFACES
interface IRejectCostSalaryCategory extends RejectCostSalaryCategoryProps {
	match: Match<{
		token: string;
		proposalId: string | number;
		manager: string;
	}>;
	goToPage: (path: string) => void;
}

const notificationPayload = {
	area: 'Salaries & Contracts',
	section: 'Salary Categories'
};

const RejectCostSalaryCategory: FC<IRejectCostSalaryCategory> = ({
	goToPage,
	match,
	employeeSalary,
	submitEmployeeSalaryCategoryReject,
	setNotificationAsReadProposals
}) => {
	const classes = useStyles();
	const { loading } = employeeSalary;
	const { params } = match;
	const token = params?.token;
	const proposalId = params?.proposalId;
	const manager = params?.manager;

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const onSubmit = (payload: unknown) => {
		submitEmployeeSalaryCategoryReject(token, Number(proposalId), manager, payload);
		goToPage('/admin/salariesAndContracts/employeeSalaryCategories');
		setNotificationAsReadProposals(proposalId.toString(), notificationPayload);
	};

	// PAGE FOOTER ACTIONS
	const footerActions = useMemo(
		() => [
			{
				id: 'back',
				label: 'Back',
				variant: 'text',
				left: true,
				startIcon: 'back',
				onClick: () => goToPage('/admin/salariesAndContracts/employeeSalaryCategories')
			},
			{
				id: 'submit',
				label: 'Submit',
				onClick: () => ({}),
				type: 'submit',
				form: 'form-feedback',
				variant: 'contained',
				disabled: false
			}
		],
		[]
	);

	const isLoading = false;

	if (isLoading) return <LoadingOverlay />;

	// if sheet is already submitted, a different screen must be shown
	return (
		<>
			{loading && <LoadingOverlay />}
			<div className={classes.feedbackCard}>
				<form id="form-feedback" onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Input
								label="Reasons for rejection"
								name="rejectionReason"
								multiline
								required
								minRows={5}
								maxRows={8}
								control={control}
								errors={errors}
							/>
						</Grid>
					</Grid>
				</form>
			</div>
			<ActionFooter actions={footerActions} />
		</>
	);
};

export default withLayout(RejectCostSalaryCategory);
