import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { clearPersonalInfoError, getUserResume, sendUserResume } from 'store/personalInfo/thunks';

import MyResume from './Resume';
import { setNotificationAsRead } from 'store/notifications/thunks';

const mapStateToProps = ({ personalInfo, company }: AppState) => ({ personalInfo, company });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ clearPersonalInfoError, getUserResume, sendUserResume, setNotificationAsRead }, dispatch);

export type MyResumeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyResume);
