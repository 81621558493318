/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect, useState, useMemo } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, PageTitle } from 'xpand-ui/core';
import { compareValues } from 'xpand-ui/utils/sort';

//* TYPINGS
import { IResume, IPostResumePayload, ISystemSkills, ISkillGroup } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';
// *
import { addGroupsToStruct, filterOnlyUserSkills } from 'pages/PersonalInfo/MyProfile/Skills/utils';
import { SkillsInfoCard } from 'pages/PersonalInfo/MyProfile/Resume/utils';

//* LOCAL COMPONENT IMPORTS
import {
	PersonalInfoCardView,
	EducationInfoCardView,
	WorkExperienceInfoCardView,
	WorkshopsInfoCardView,
	OtherProjectsInfoCardView,
	TrainingInfoCardView
} from './utils';
import { useStyles } from './styles';
import { ResumeDetailsProps } from '.';

//* COMPONENT INTERFACES
interface IResumeDetails extends ResumeDetailsProps {
	resumeId: number;
	systemSkills: ISystemSkills[];
}

//* COMPONENT
const ResumeDetails: FC<IResumeDetails> = ({
	skillsAndResumes,
	resumeId,
	getResumeDetails,
	systemSkills,
	clearResumesList
}) => {
	const classes = useStyles();
	const { resumeDetails } = skillsAndResumes;

	const [formData, setFormData] = useState<IPostResumePayload | null>(null);
	const [userSkillsForm, setSkillsFormData] = useState<any>({});
	const [systemSkillsData, setSystemSkillsData] = useState<any>(null);

	useEffect(() => {
		if (!resumeDetails) {
			getResumeDetails(resumeId);
		}
	}, [resumeDetails, resumeId]);
	// Use effect necessary to clear data after closing the details page, otherwise the details listed will always be from the first one opened
	useEffect(
		() => () => {
			clearResumesList();
		},
		[]
	);

	const getDefaultValueCountry = useMemo(
		() => (payload: IResume) => {
			if (payload.nativeLanguage === 'PORTUGUESE') return 'PORTUGUESE (PORTUGAL)';
			if (payload.nativeLanguage === 'FRENCH') return 'FRENCH (FRANCE)';
			if (payload.nativeLanguage === 'SPANISH') return 'SPANISH (SPAIN)';

			return payload.nativeLanguage;
		},
		[]
	);

	useEffect(() => {
		if (resumeDetails) {
			const newFormData = {
				personalInfo: [
					{
						...(({ education, otherProjects, training, workExperience, workshops, ...o }) => o)(
							resumeDetails.resume
						),
						nativeLanguage: getDefaultValueCountry(resumeDetails.resume)
					}
				],
				education: resumeDetails.resume.education.sort(compareValues('order')),
				workExperience: resumeDetails.resume.workExperience.sort(compareValues('order')),
				workshops: resumeDetails.resume.workshops.sort(compareValues('order')),
				otherProjects: resumeDetails.resume.otherProjects.sort(compareValues('order')),
				training: resumeDetails.resume.training.sort(compareValues('order'))
			};

			if (systemSkills) {
				const payload = {};
				const sysPayload = {};
				systemSkills.map(a => {
					const { toRender } = addGroupsToStruct(
						a.skillGroups as ISkillGroup[],
						resumeDetails?.employeeSkills,
						true,
						true
					);
					if (a.id) payload[a.id] = { ...toRender };
					if (a.name) sysPayload[a.name] = a;
					return a;
				});

				setSkillsFormData((prev: any) => ({ ...prev, ...payload }));
				setSystemSkillsData((prev: any) => ({ ...prev, ...sysPayload }));
			}

			setFormData(newFormData as unknown as IPostResumePayload);
		}
	}, [resumeDetails]);

	//Get all skills to an object
	let skills = {};
	for (let skill in userSkillsForm) {
		for (let skillName in systemSkillsData) {
			if (systemSkillsData[skillName].id == skill) {
				let skillArea = filterOnlyUserSkills(
					userSkillsForm[skill]!,
					{},
					systemSkillsData[skillName].skillGroups
				);
				skills = {
					...skills,
					[skill]: skillArea
				};
			}
		}
	}
	const isLoading = resumeDetails === null || !skills;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			<div>
				<Typography className={classes.resumeTitle} variant="h1" component="h2">
					{resumeDetails?.employeeSkills?.name} ( {resumeDetails.employeeSkills?.position} )
				</Typography>

				<PageTitle title="Personal Information" />
				<Grid
					container
					direction="column"
					justifyContent="space-evenly"
					spacing={5}
					className={classes.mainGrids}>
					{formData?.personalInfo.map(payload => (
						<PersonalInfoCardView key={`personalInfo_${payload.id}`} classes={classes} payload={payload} />
					))}
				</Grid>

				<PageTitle title="Education" />
				<Grid
					container
					direction="column"
					justifyContent="space-evenly"
					className={classes.mainGrids}
					spacing={5}>
					{formData?.education.map((item, i) => (
						<EducationInfoCardView key={`education_${item.id || i}`} classes={classes} payload={item} />
					))}
				</Grid>

				<PageTitle title="Work Experience" />

				<Grid
					container
					direction="column"
					justifyContent="space-evenly"
					className={classes.mainGrids}
					spacing={5}>
					{formData?.workExperience.map((item, i) => (
						<WorkExperienceInfoCardView
							key={`workExperience_${item.id || i}`}
							classes={classes}
							payload={item}
						/>
					))}
				</Grid>

				<PageTitle title="Workshops" />

				<Grid
					container
					direction="column"
					justifyContent="space-evenly"
					className={classes.mainGrids}
					spacing={5}>
					{formData?.workshops.map((item, i) => (
						<WorkshopsInfoCardView key={`workshops_${item.id || i}`} classes={classes} payload={item} />
					))}
				</Grid>

				<PageTitle title="Other Projects" />

				<Grid
					container
					direction="column"
					justifyContent="space-evenly"
					className={classes.mainGrids}
					spacing={5}>
					{formData?.otherProjects.map((item, i) => (
						<OtherProjectsInfoCardView
							key={`otherProjects_${item.id || i}`}
							classes={classes}
							payload={item}
						/>
					))}
				</Grid>

				<PageTitle title="Training" />

				<Grid
					container
					direction="column"
					justifyContent="space-evenly"
					className={classes.mainGrids}
					spacing={5}>
					{formData?.training.map((item, i) => (
						<TrainingInfoCardView key={`training_${item.id || i}`} classes={classes} payload={item} />
					))}
				</Grid>

				<PageTitle title="Skills" />

				<Grid>
					<SkillsInfoCard classes={classes} formData={userSkillsForm} allSkills={skills} />
				</Grid>
			</div>
		</>
	);
};

export default withLayout(ResumeDetails);
