import * as yup from 'yup';

import { number, string, boolean, numberRequired, stringRequired } from 'lib/utils/formUtils';
import { IBudgetPostPayload } from 'typings/store/admin/budget';

export const schema = yup.object().shape({
	id: number,
	name: stringRequired,
	customName: stringRequired,
	year: numberRequired,
	approvedFlag: boolean,
	version: numberRequired,
	statusId: numberRequired,
	creationDate: stringRequired,
	updatedDate: stringRequired,
	approvalDate: string.when('approvedFlag', {
		is: true,
		then: stringRequired,
		otherwise: string
	}),
	type: stringRequired,
	// eslint-disable-next-line react/forbid-prop-types
	lines: yup.object()
});

export const defaultValues: IBudgetPostPayload = {
	id: 23,
	name: '',
	customName: '',
	year: 1900,
	approvedFlag: false,
	version: 0,
	statusId: 0,
	creationDate: '',
	updatedDate: '',
	approvalDate: '',
	type: '',
	lines: {}
};
