// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		statusBar: {
			// direction: 'row',
			justify: 'center',
			alignItems: 'center',
			display: 'flex'
		},
		statusIcon: {
			marginRight: '10px'
		},
		fullScreenContent: {
			padding: '25px 75px 0 75px'
		},
		fullScreenContentTablet: {
			padding: '25px 0 0 25px'
		},
		centerInParent: {
			margin: 'auto',
			display: 'block'
		}
	})
);

export { useStyles };
