import { createReducer } from 'typesafe-actions';
import { AppPageActions as Actions, AppPageState } from 'typings/store/appPageTypes';

const INITIAL_STATE: AppPageState = {
	loading: false,
	error: null,
	appsList: null
};

const AuthReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.GET_APPS_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		appsList: payload
	})
});

export default AuthReducer;
