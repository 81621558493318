// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		animation: {
			visibility: 'hidden',
			opacity: '0',
			height: 0,
			padding: '0 !important',
			transition: 'all 0.3s ease'
		},
		show: {
			visibility: 'visible',
			height: 'auto',
			padding: '40px !important',
			opacity: 1
		},
		group: {
			maxWidth: '600px',
			display: 'flex'
		},
		inputsGroup: {
			maxWidth: '640px'
		},
		textAreaHeader: {
			width: '60%'
		},
		percentageHeader: {
			width: '20%',
			marginTop: '12px'
		},
		valueHeader: {
			width: '20%',
			marginTop: '12px'
		},
		textArea: {
			width: '60%'
		},
		percentage: {
			marginTop: '8px'
		},
		value: {
			marginTop: '8px'
		},
		errorPrizeSum: {
			marginLeft: '15px'
		}
	})
);

export { useStyles };
