/* eslint-disable func-names */
//* EXTERNAL LIBS
import React, { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter } from 'xpand-ui/core';
import { Input } from 'xpand-ui/forms';

//* TYPINGS
import { ISkillsFeedback } from 'typings/store/admin/skillsAndResumes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { SkillsFeedbackProps } from '.';
import { getLSField } from 'lib/utils/cookies';

const spacing = 3;

const footerActions = [
	{
		id: 'submit',
		label: 'Submit',
		onClick: () => ({}),
		type: 'submit',
		form: 'form-feedback',
		variant: 'contained',
		disabled: false
	}
];

interface ISkillsFeedbackLocal extends SkillsFeedbackProps {
	goToPage: (path: string) => void;
}

const notificationPayload = {
	area: 'My Profile',
	section: 'Skills'
};

const SkillsFeedback: FC<ISkillsFeedbackLocal> = ({
	skillsAndResumes,
	goToPage,
	sendPostSkillsFeedback,
	setNotificationAsRead
}) => {
	const classes = useStyles();
	const { loading } = skillsAndResumes;
	const userInfoStorage = getLSField('userInfo');
	let userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const onSubmit = (payload: ISkillsFeedback) => {
		sendPostSkillsFeedback(payload);
		setNotificationAsRead(userInfo?.username, notificationPayload);
		goToPage('/skills/soft_2');
	};

	const isLoading = null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<div className={classes.feedbackCard}>
				<form id="form-feedback" onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={spacing}>
						<Grid item xs={12}>
							<Input
								label="Feedback"
								name="feedback"
								multiline
								required
								minRows={5}
								maxRows={8}
								control={control}
								errors={errors}
							/>
						</Grid>
					</Grid>

					{errors.feedback && <span>This field is required</span>}
				</form>
			</div>
			<ActionFooter actions={footerActions} />
		</>
	);
};

export default withLayout(SkillsFeedback);
