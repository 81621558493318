//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay } from 'xpand-ui/core';
import { ErrorPage } from 'xpand-ui/lab';

//* TYPINGS
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { ApproveCostSalaryCategoryProps } from '.';
// import { useStyles  } from './styles';

//* COMPONENT INTERFACES
interface IApproveCostSalaryCategory extends ApproveCostSalaryCategoryProps {
	match: Match<{
		token: string;
		proposalId: string | number;
		manager: string;
	}>;
	goToPage: (path: string) => void;
}

const notificationPayload = {
	area: 'Salaries & Contracts',
	section: 'Salary Categories'
};

//* COMPONENT

const ApproveCostSalaryCategory: FC<IApproveCostSalaryCategory> = ({
	goToPage,
	match,
	employeeSalary,
	submitEmployeeSalaryCategoryApprove,
	clearProposalsFormsData,
	setNotificationAsReadProposals
}) => {
	// const classes = useStyles();
	const { params } = match;
	const { employeesSalaryActions } = employeeSalary;
	const token = params?.token;
	const proposalId = params?.proposalId;
	const manager = params?.manager;

	useEffect(() => {
		submitEmployeeSalaryCategoryApprove(token, Number(proposalId), manager);
	}, []);

	useEffect(() => {
		if (employeesSalaryActions && !employeesSalaryActions.code) {
			goToPage('/admin/salariesAndContracts/employeeSalaryCategories');
		}
		setNotificationAsReadProposals(proposalId.toString(), notificationPayload);
	}, [employeesSalaryActions]);

	if (employeesSalaryActions && employeesSalaryActions?.code && employeesSalaryActions?.code !== 200) {
		return (
			<ErrorPage
				code={employeesSalaryActions?.code}
				message={employeesSalaryActions?.payload.message}
				exit={() => clearProposalsFormsData()}
			/>
		);
	}

	return <LoadingOverlay />;
};

export default withLayout(ApproveCostSalaryCategory);
