/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';

import { string, stringRequired, phoneNumberRequired, zipCodeRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	emergencyContactName: stringRequired,
	emergencyContact: phoneNumberRequired,
	personalEmail: stringRequired.email('Must be a valid email'),
	addressLine1: stringRequired,
	addressLine2: string,
	postalCode: zipCodeRequired,
	city: stringRequired,
	countryId: stringRequired,
	citizenCardExpirationDateString: stringRequired
});
export const defaultValues = {
	emergencyContactName: '',
	emergencyContact: '',
	personalEmail: '',
	addressLine1: '',
	addressLine2: '',
	postalCode: '',
	city: '',
	countryId: '',
	citizenCardExpirationDateString: ''
};
