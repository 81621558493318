import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getCachesList,
	sendDeleteSingleCache,
	sendDeleteAllCaches,
	sendDeleteAllUserCaches,
	sendDeleteAllCompaniesCaches,
	clearAdministrationError
} from 'store/administration/administration/thunks';
import { setCacheManagementPageFilter } from 'store/filters/actions';
import { AppState } from 'typings/state';

import CacheManagement from './CacheManagement';

const mapStateToProps = ({ administration, filters }: AppState) => ({ administration, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getCachesList,
			sendDeleteSingleCache,
			sendDeleteAllCaches,
			sendDeleteAllUserCaches,
			sendDeleteAllCompaniesCaches,
			clearAdministrationError,
			setCacheManagementPageFilter
		},
		dispatch
	);

export type CacheManagementProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CacheManagement);
