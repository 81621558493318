/* eslint-disable no-return-assign */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
//* EXTERNAL LIBS
import React, { useEffect, useState, useRef, FC } from 'react';
import { Field } from '@bryntum/scheduler/scheduler.umd.js';
import { BryntumScheduler } from '@bryntum/scheduler-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router';

//* EXTERNAL LIBS --> MUI
import { Button, Grid, Switch, Typography } from '@mui/material';
import { Fullscreen } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-INTERNAL-APPS-LIB
import { Select, InputLabel, DatePicker } from 'xpand-ui/forms';
import { parseDateToManage, parseDateToManageSaf, parseDateToShow } from 'xpand-ui/utils/dates';
import { LoadingOverlay, Dialog } from 'xpand-ui/core';

//* TYPINGS
import { IChoosableBaseInfo, ISchedulerSelectOption, ISelectedOption } from 'typings/store/generalTypes';
import {
	CustomContainer,
	CustomEventModel,
	CustomField,
	IEvent,
	IEventListenerContext,
	IFilters,
	IPlannerData,
	IProject,
	IResource,
	ITimeOff,
	OperationsType
} from 'typings/store/admin/planner';

//* PROJECT IMPORTS [LIB / PAGES ]
import ToggleButtonGroup from 'components/ToggleButtonGroup';
import { transformEndDateToMidnight } from 'store/administration/planner/thunks';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { schedulerConfig } from './schedulerConfig';
import { schema, defaultValues } from './yupSchema';
import {
	checkIfNewEventDateOverlap,
	clearWidgetErrors,
	createPostEventsPayload,
	getFilterOfficesDropdownData,
	getFilterProjectDropdownData,
	getFilterSkillsDropdownData,
	getFilterUsersDropdownData,
	getSchedulerProjectDropdownData,
	validateAllocation,
	updateFilterCount,
	toggleButtons
} from './utils/helper';
import { PlannerProps } from '.';

const MAX_ALLOCATION = 100;

const COLORS = [
	{ value: 'red', text: 'Red', rgb: '#EF5350' },
	{ value: 'pink', text: 'Pink', rgb: '#EC407A' },
	{ value: 'purple', text: 'Purple', rgb: '#AB47BC' },
	{ value: 'violet', text: 'Violet', rgb: '#7E57C2' },
	{ value: 'indigo', text: 'Indigo', rgb: '#5C6BC0' },
	{ value: 'blue', text: 'Blue', rgb: '#42A5F5' },
	{ value: 'cyan', text: 'Cyan', rgb: '#26C6DA' },
	{ value: 'teal', text: 'Teal', rgb: '#26A69A' },
	{ value: 'green', text: 'Green', rgb: '#66BB6A' },
	{ value: 'gantt-green', text: 'Gantt-green', rgb: '#A5D8A7' },
	{ value: 'lime', text: 'Lime', rgb: '#D4E157' },
	{ value: 'yellow', text: 'Yellow', rgb: '#FDD835' },
	{ value: 'orange', text: 'Orange', rgb: '#FFA726' },
	{ value: 'deep-orange', text: 'Deep-orange', rgb: '#FF7043' },
	{ value: 'gray', text: 'Gray', rgb: '#A0A0A0' }
];

//* COMPONENT
const Planner: FC<PlannerProps> = ({
	planner,
	filters,
	getPlannerData,
	getFiltersData,
	savePlannerNewData,
	getFilteredPlannerData,
	clearAllStoredData,
	clearPlannerError,
	setPlannerAction,
	setPlannerFilters
}) => {
	const classes = useStyles();
	const { plannerData, plannerFilteredData, filtersData, loading, postDataResult, error, plannerActions } = planner;
	const { plannerFilters } = filters;
	// form
	const {
		handleSubmit,
		control,
		reset,
		setError,
		clearErrors,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const schedulerConfigs = schedulerConfig(classes);

	const schedulerRef = useRef<typeof BryntumScheduler | null>(null);
	const rootElement = useRef<HTMLDivElement>(null);

	const [filterCount, setFilterCount] = useState(0);
	const [pov, setPov] = useState('day');

	const [events, setEvents] = useState<IEvent[]>([]);
	const [areChangesMade, setChangesMade] = useState(false);
	const [isPlannerFirtLoaded, setPlannerFirstLoaded] = useState(false);

	const [resources, setResources] = useState<IResource[]>([]);
	const [projects, setProjects] = useState<ISchedulerSelectOption[]>([]);
	const [timesOff, settimesOff] = useState<ITimeOff[]>([]);

	const [filtersProjects, setFiltersProjects] = useState<IChoosableBaseInfo[]>([]);
	const [filtersOffices, setFiltersOffices] = useState<IChoosableBaseInfo[]>([]);
	const [filtersSkills, setFiltersSkills] = useState<IChoosableBaseInfo[]>([]);
	const [filtersUsers, setFiltersUsers] = useState<IChoosableBaseInfo[]>([]);

	const [filtersHolder, setFiltersHolder] = useState<IFilters>(plannerFilters);
	const [lastfiltersAppliedHolder, setLastfiltersAppliedHolder] = useState<IFilters | null>(plannerFilters);
	const [editFilters, setEditFilters] = useState(false);

	useEffect(() => {
		setPlannerFilters(lastfiltersAppliedHolder);
	}, [lastfiltersAppliedHolder]);

	const [schedulerDates, setSchedulerDates] = useState<{ startDate: Date; endDate: Date } | null>(null);

	const [openConfirmationSubmit, setOpenConfirmationSubmit] = useState<boolean | null>(null);
	const [openConfirmationDeleteEvent, setOpenConfirmationDeleteEvent] = useState<boolean | null>(null);
	const [deleteEventContext, setDeleteEventContext] = useState<IEventListenerContext | null>(null);

	const getProjectIdLabel = (projectID: string): string => {
		const projectC = filtersData?.projects?.find((project: IProject) => project.id === projectID);
		return (projectC && projectC.name) || '';
	};

	const scrollToDate = () => {
		const scheduler = schedulerRef?.current?.instance;
		if (!scheduler) return;

		scheduler.scrollToDate(new Date(new Date().setHours(0, 0, 0, 0)), {
			highlight: true,
			block: 'start'
		});
	};

	/**  updates:
	 * @field Scheduler Dates
	 * @field Projects
	 * @field Events
	 * @field TimeOffs
	 */
	const updatePlannerData = (payload: IPlannerData, first = false) => {
		first &&
			setSchedulerDates({
				startDate: new Date(parseDateToManageSaf(payload.startDate)),
				endDate: new Date(parseDateToManageSaf(payload.endDate))
			});

		first && setProjects(getSchedulerProjectDropdownData(payload.projects));

		setEvents(
			payload.events.map((e: IEvent) => ({
				...e,
				locked: true,
				alternativeProjLabel: getProjectIdLabel(e.projectID),
				name: e.projectLabel ? e.projectLabel : getProjectIdLabel(e.projectID)
			}))
		);
		settimesOff(payload?.timeOffs?.map(e => ({ ...e, resourceId: e.resourceId.toUpperCase() })));
	};

	useEffect(() => {
		const engine = schedulerRef.current?.instance;
		if (engine && postDataResult) {
			engine.eventStore.commit();
		}
	}, [postDataResult]);

	useEffect(() => {
		if (!plannerData && !error) {
			getPlannerData();
		} else {
			if (!plannerData) return;
			updatePlannerData(plannerData, true);
			setResources(plannerData.resources?.map(e => ({ ...e, id: e.id.toUpperCase() })));
		}
	}, [plannerData]);

	useEffect(() => {
		//Scrolls to the current Date in the first loading
		if (resources && isPlannerFirtLoaded === false) {
			const isFirstLoaded = plannerData !== null;
			setPlannerFirstLoaded(isFirstLoaded);
			scrollToDate();
		}
	}, [resources]);

	useEffect(() => {
		if (!plannerFilteredData && !error) return;

		updatePlannerData(plannerFilteredData);

		if (schedulerRef.current !== null) {
			const scheduler = schedulerRef?.current?.instance;
			const { resourceStore } = scheduler;

			resourceStore.filter({
				filters: (resource: any) =>
					plannerFilteredData.resources.find(
						(fiteredResources: any) => fiteredResources.id.toUpperCase() === resource.data.id.toUpperCase()
					)
						? resource
						: false,
				replace: true
			});
		}
	}, [plannerFilteredData, schedulerRef]);

	useEffect(() => {
		if (!filtersData && !error) {
			getFiltersData();
		} else {
			setFiltersProjects(getFilterProjectDropdownData(filtersData.projects));
			setFiltersOffices(getFilterOfficesDropdownData(filtersData.offices));
			setFiltersSkills(getFilterSkillsDropdownData(filtersData.skills));
			setFiltersUsers(getFilterUsersDropdownData(filtersData.users));
		}
	}, [filtersData]);

	// Filter Dates validation functions
	const areDateValid = (startDate: Date, endDate: Date): boolean => startDate.getTime() <= endDate.getTime();
	const filterDateValidation = () => {
		if (filtersHolder.startDate && filtersHolder.endDate) {
			const areDatesValid = areDateValid(
				new Date(parseDateToManageSaf(filtersHolder.startDate)),
				new Date(parseDateToManageSaf(filtersHolder.endDate))
			);
			if (!areDatesValid) {
				setError(
					'endDate',
					{
						type: 'manual',
						message: 'End Date must be after the start Date'
					},
					{ shouldFocus: true }
				);
			} else {
				clearErrors('endDate');
			}
		}
	};

	// Toggle Button Group Handler / Action
	const applyToggleChange = (chosenPov: string, updatePOV = true) => {
		const scheduler = schedulerRef?.current?.instance;
		if (!scheduler) return;

		switch (chosenPov) {
			case 'day':
				scheduler.zoomLevel = 0;
				break;
			case 'week':
				scheduler.zoomLevel = 1;
				break;
			case 'month':
				scheduler.zoomLevel = 2;
				break;
			default:
				scheduler.zoomLevel = 0;
		}
		updatePOV && setPov(chosenPov);
	};

	useEffect(() => {
		setFilterCount(updateFilterCount(filtersHolder));
		filterDateValidation();
	}, [filtersHolder]);

	// Create filter Data payload and request
	const makeFilterPlannerDataRequest = (values: IFilters) => {
		const { startDate, endDate, ...otherFilters } = values;

		const startDateFinal = new Date(parseDateToManageSaf(startDate));

		const endDateAux = new Date(parseDateToManageSaf(endDate));
		const endDateFinal = new Date(endDateAux.getFullYear(), endDateAux.getMonth() + 1, 0);

		getFilteredPlannerData({
			...otherFilters,
			startDate: startDate && parseDateToManage(startDateFinal, true),
			endDate: endDate && parseDateToManage(endDateFinal, true)
		});
		setEditFilters(false);
	};

	const resetStateFiltersValues = (inPageReset = false) => {
		reset(defaultValues);
		setFiltersHolder(defaultValues);
		setLastfiltersAppliedHolder(defaultValues);
		if (inPageReset) {
			makeFilterPlannerDataRequest(defaultValues);
		}
	};

	// cleanup when component unmount
	useEffect(
		() => () => {
			// check if in fullscreen mode
			if (document && !!document.fullscreenElement) {
				document.exitFullscreen();
			}
			resetStateFiltersValues(false);
			clearAllStoredData();
		},
		[]
	);

	//!Responsable to save the changes
	useEffect(() => {
		if (areChangesMade === true && plannerActions !== '') {
			saveEventChanges();
			setPlannerAction('');
		}
		setChangesMade(false);
	}, [areChangesMade, plannerActions]);

	// Save Changes handler
	const saveEventChanges = () => {
		const engine = schedulerRef.current.instance;
		const result = createPostEventsPayload(engine.eventStore.changes, plannerActions);

		//If it is created it will get the latest info to avoid conflits with edit option
		if (engine.eventStore.changes && engine.eventStore.changes.added.length && plannerActions === 'Create') {
			savePlannerNewData(result, true);
			getPlannerData();
		} else {
			savePlannerNewData(result);
		}
		engine.eventStore.commit();
		setOpenConfirmationSubmit(false);
	};

	const submitActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenConfirmationSubmit(false)
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				setOpenConfirmationSubmit(false);
			}
		}
	];

	const deleteEventActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				deleteEventContext?.finalize(false);
				setOpenConfirmationDeleteEvent(false);
			}
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				deleteEventContext?.finalize(true);
				setOpenConfirmationDeleteEvent(false);
				setChangesMade(true);
				setPlannerAction('Delete');
			}
		}
	];

	// Fullscreen handler
	const setFullscreen = () => {
		if (!document.fullscreenElement && rootElement.current != null) {
			document.documentElement.requestFullscreen();
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	};

	// Handle for Show time off option in filters
	const handleShowTimeOffSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFiltersHolder(prev => ({
			...prev,
			showTimeOff: event.target.checked
		}));
	};

	// Handle for Show time off option in filters
	const handleShowAvailableOnlySwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
		// Show Time off must be true if show available is true
		setFiltersHolder(prev => ({
			...prev,
			showAvailableOnly: event.target.checked,
			showTimeOff: event.target.checked ? true : prev.showTimeOff
		}));
	};

	const resetFiltersValues = () => {
		reset({
			...defaultValues,
			startDate: lastfiltersAppliedHolder?.startDate || '',
			endDate: lastfiltersAppliedHolder?.endDate || '',
			offices: lastfiltersAppliedHolder?.offices || [],
			projects: lastfiltersAppliedHolder?.projects || [],
			users: lastfiltersAppliedHolder?.users || [],
			skills: lastfiltersAppliedHolder?.skills || [],
			showAvailableOnly: lastfiltersAppliedHolder?.showAvailableOnly || false,
			showTimeOff: lastfiltersAppliedHolder?.showTimeOff || true
		});
	};

	const filtersModalActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setEditFilters(false);
				setFiltersHolder(lastfiltersAppliedHolder ? (lastfiltersAppliedHolder as IFilters) : defaultValues);
				resetFiltersValues();
			}
		},
		{
			id: 'reset',
			label: 'Reset',
			color: 'secondary',
			variant: 'outlined',
			onClick: () => {
				resetStateFiltersValues(true);
			}
		},
		{
			id: 'submit',
			label: 'Apply',
			color: 'primary',
			type: 'submit',
			form: 'form-filters-planner',
			variant: 'contained',
			onClick: () => null
		}
	];

	const onSubmit = () => {
		// save last filters applied
		setLastfiltersAppliedHolder(filtersHolder);
		// request
		makeFilterPlannerDataRequest(filtersHolder);
	};

	const showDateFilterIfActive = () => {
		const hasStartDate = !!filtersHolder.startDate?.length;
		const hasEndtDate = !!filtersHolder.endDate?.length;

		if (hasStartDate && hasEndtDate) {
			return (
				<div className={classes.dateContainer}>
					<InputLabel className={classes.dateTitleLabel}>DATE</InputLabel>{' '}
					<InputLabel className={classes.dateTitleLabel}>
						{`${parseDateToShow(filtersHolder.startDate as string)} - ${parseDateToShow(
							filtersHolder.endDate as string
						)}`}
					</InputLabel>
				</div>
			);
		}

		return hasStartDate ? (
			<div className={classes.dateContainer}>
				<InputLabel className={classes.dateTitleLabel}>Start Date</InputLabel>{' '}
				<InputLabel className={classes.dateLabel}>
					{parseDateToShow(filtersHolder.startDate as string)}
				</InputLabel>
			</div>
		) : (
			<div className={classes.dateContainer}>
				<InputLabel className={classes.dateTitleLabel}>End Date</InputLabel>{' '}
				<InputLabel className={classes.dateLabel}>
					{parseDateToShow(filtersHolder.endDate as string)}
				</InputLabel>
			</div>
		);
	};

	const selectedBtnHandler = (selectedValue: string) => {
		applyToggleChange(selectedValue);
	};

	// Calculate de Maximum Limit range and current value
	const calculateAllocationMaxAndValue = (
		overlappedEvents: CustomEventModel[],
		allocationWidget: CustomField
	): { max: number; value: number } => {
		let previousAllocationSum = 0;

		overlappedEvents.forEach(overEvent => (previousAllocationSum += overEvent.data.allocationPercentage));

		if (previousAllocationSum === MAX_ALLOCATION) {
			allocationWidget.setError('Maximum allocation percentage already reached');
			return { max: 0, value: 0 };
		}
		const diff = MAX_ALLOCATION - previousAllocationSum;
		return { max: diff, value: diff };
	};

	// Update planner filters based on user saved filters
	useEffect(() => {
		makeFilterPlannerDataRequest(lastfiltersAppliedHolder as IFilters);
		setLastfiltersAppliedHolder(lastfiltersAppliedHolder);
		setFiltersHolder(lastfiltersAppliedHolder ? (lastfiltersAppliedHolder as IFilters) : defaultValues);
		resetFiltersValues();
	}, [lastfiltersAppliedHolder]);

	if (error) return handleErrorPage(error, clearPlannerError);
	const isLoading = plannerData === null;
	if (isLoading) return <LoadingOverlay />;

	let allocationWidget: CustomField;
	let projectLabelWidget: Field;
	let projectWidget: any;
	let colorWidget: any;

	return (
		<div className={classes.root} ref={rootElement}>
			{loading && <LoadingOverlay />}
			<div className={classes.actionsHeader}>
				<div className={classes.leftActions}>
					<ToggleButtonGroup
						buttons={toggleButtons}
						selectedValue={pov}
						selectedBtnHandler={selectedBtnHandler}
					/>
					<div className={classes.separator} />
					<Button
						className={classes.fullscreenButton}
						color="secondary"
						onClick={setFullscreen}
						endIcon={<Fullscreen />}>
						Fullscreen
					</Button>
					<Button
						className={filterCount ? classes.filterButtonSelected : classes.filterButton}
						variant="outlined"
						color="primary"
						style={{
							padding: filterCount > 0 ? '7px 30px 7px 30px' : '7px 35px 7px 35px'
						}}
						onClick={() => setEditFilters(true)}>
						filters {filterCount > 0 ? `+${filterCount}` : ''}
					</Button>
					{(!!filtersHolder?.startDate?.length || !!filtersHolder?.endDate?.length) &&
						showDateFilterIfActive()}
				</div>
				<div></div>
			</div>
			{schedulerDates?.startDate && (
				<BryntumScheduler
					ref={schedulerRef}
					{...schedulerConfigs}
					startDate={schedulerDates?.startDate}
					endDate={schedulerDates?.endDate}
					events={events}
					resources={resources}
					autoAdjust={false}
					projects={projects}
					barMargin={15}
					resourceTimeRanges={timesOff}
					listeners={{
						beforeEventEditShow({
							editor,
							eventRecord
						}: {
							editor: CustomContainer;
							eventRecord: CustomEventModel;
						}) {
							allocationWidget = editor.widgetMap.allocation as CustomField;
							projectLabelWidget = editor.widgetMap.projectLabel;
							projectWidget = editor.widgetMap.projects;
							colorWidget = editor.widgetMap.color;
							// update data in combo list
							projectWidget.items = projects;
							colorWidget.items = COLORS;
							colorWidget.listItemTpl = (element: { value: any; rgb: any; text: any }) =>
								`<div style="background-color:${element.rgb};height: 15px; width: 15px; margin-right: 10px; border-radius: 2px"></div> <h1>${element.text}</h1>`;
							clearWidgetErrors(allocationWidget);
							allocationWidget.max = MAX_ALLOCATION;
							if (!allocationWidget.value) allocationWidget.value = MAX_ALLOCATION;
							// assure that the selected project label is shown in the combo
							if (eventRecord.data.allocationPercentage) {
								projectWidget.value = getProjectIdLabel(eventRecord.data.projectID);
							} else {
								const overlappedEvents = checkIfNewEventDateOverlap(
									schedulerRef.current.instance,
									eventRecord,
									{
										resourceId: eventRecord.resourceId as string,
										startDate: eventRecord.startDate,
										endDate: eventRecord.endDate
									}
								);
								if (overlappedEvents.length > 0) {
									const { max, value } = calculateAllocationMaxAndValue(
										overlappedEvents,
										allocationWidget
									);
									allocationWidget.max = max;
									allocationWidget.value = value;
								}
							}
						},
						beforeEventResizeFinalize({ context }: { context: any }) {
							const overlappedEvents = checkIfNewEventDateOverlap(
								schedulerRef.current.instance,
								context.eventRecord,
								{
									resourceId: context.resourceRecord.data.id,
									startDate: context.startDate,
									endDate: context.endDate
								}
							);
							if (overlappedEvents.length === 0) {
								context.finalize(true);
								return true;
							}
							if (!validateAllocation(overlappedEvents, context.eventRecord.data.allocationPercentage)) {
								context.async = true;
								context.finalize(false);
								return false;
							}
							context.finalize(true);
							return true;
						},
						afterEventEdit(values: IEvent, eventRecord: CustomEventModel) {
							values.endDate = transformEndDateToMidnight(values.endDate);
							eventRecord.data.endDate = transformEndDateToMidnight(eventRecord.data.endDate);
						},
						beforeEventSave({
							eventRecord,
							values,
							context
						}: {
							eventRecord: CustomEventModel;
							values: IEvent;
							context: any;
						}): boolean {
							if (
								!values.allocationPercentage ||
								!values.eventColor ||
								(!values.projectLabel.length && !values.projectID)
							) {
								if (!values.allocationPercentage) {
									allocationWidget.setError('A percentage must be added');
									context.finalize(false);

									return false;
								}
								if (!values.projectLabel.length && !values.projectID) {
									projectLabelWidget.setError(
										'Either a project must be selected or a project label provided'
									);
									projectWidget.setError(
										'Either a project must be selected or a project label provided'
									);
									context.finalize(false);

									return false;
								}
								if (!values.eventColor) {
									colorWidget.setError('A color must be selected');
									context.finalize(false);

									return false;
								}

								return false;
							}
							values.endDate = transformEndDateToMidnight(values.endDate);
							eventRecord.data.endDate = transformEndDateToMidnight(eventRecord.data.endDate);
							eventRecord.data.projectLabel = values.projectLabel.length
								? values.projectLabel
								: getProjectIdLabel(values.projectID);
							eventRecord.data.name = eventRecord.data.projectLabel;
							eventRecord.data = {
								...eventRecord.data,
								alternativeProjLabel: getProjectIdLabel(values.projectID)
							};

							const overlappedEvents = checkIfNewEventDateOverlap(
								schedulerRef.current.instance,
								eventRecord,
								{
									resourceId: values.resource.data.id,
									startDate: values.startDate,
									endDate: values.endDate
								}
							);
							if (overlappedEvents.length === 0) {
								context.finalize(true);
								return true;
							}
							if (!validateAllocation(overlappedEvents, values.allocationPercentage)) {
								allocationWidget.setError(
									'Allocations that intercept each other in the same time range cannot have a combined allocation percentage higher than 100%'
								);
								context.finalize(false);
								return false;
							}
							context.finalize(true);
							return true;
						},
						//The more indicated listener to change the values that will be saved and used
						afterEventSave({ eventRecord }: { eventRecord: CustomEventModel }): boolean {
							eventRecord.data.endDate = transformEndDateToMidnight(eventRecord.data.endDate);
							setPlannerAction('Create');
							setChangesMade(true);
							return true;
						},
						beforeEventDelete({ context }: unknown): boolean {
							setDeleteEventContext(context);
							setOpenConfirmationDeleteEvent(true);
							return false;
						}
					}}
				/>
			)}
			<Dialog
				title="Select Filters"
				actions={filtersModalActions}
				scroll="body"
				modal={{
					// Sending Edit or Add mode
					open: Boolean(editFilters),
					handleClose: () => setEditFilters(false),
					content: (
						<form id="form-filters-planner" onSubmit={handleSubmit(onSubmit)}>
							<Grid container spacing={2} style={{ padding: '10px 5px 40px 5px' }}>
								<Grid item xs={12}>
									<Select
										name="offices"
										label="Offices"
										multiple
										options={filtersOffices}
										additionalOnChange={(item: ISelectedOption) => {
											setFiltersHolder(prev => ({
												...prev,
												offices: item.value
											}));
										}}
										helperText="Select one or more offices"
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item sm={6}>
									<DatePicker
										label="Start Date"
										name="startDate"
										placeholder="MM-YYYY"
										inputFormat="MM-YYYY"
										openTo="month"
										views={['year', 'month']}
										additionalOnChange={date => {
											setFiltersHolder(prev => ({
												...prev,
												startDate: date || undefined
											}));
										}}
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item sm={6}>
									<DatePicker
										label="End Date"
										name="endDate"
										placeholder="MM-YYYY"
										inputFormat="MM-YYYY"
										openTo="month"
										views={['year', 'month']}
										additionalOnChange={date => {
											setFiltersHolder(prev => ({
												...prev,
												endDate: date || undefined
											}));
										}}
										control={control}
										errors={errors}
									/>
								</Grid>
								<div className={classes.switchContainer}>
									<InputLabel className={classes.switchLabel}>SHOW ONLY AVAILABLE</InputLabel>{' '}
									<Switch
										color="primary"
										checked={filtersHolder.showAvailableOnly}
										onChange={handleShowAvailableOnlySwitch}
									/>
								</div>
								<Grid item xs={12}>
									<Select
										name="projects"
										label="Projects"
										multiple
										options={filtersProjects}
										additionalOnChange={(item: ISelectedOption) => {
											setFiltersHolder(prev => ({
												...prev,
												projects: item.value
											}));
										}}
										helperText="Select one or more projects"
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="users"
										label="Users"
										multiple
										options={filtersUsers}
										additionalOnChange={(item: ISelectedOption) => {
											setFiltersHolder(prev => ({
												...prev,
												users: item.value
											}));
										}}
										helperText="Select one or more users"
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="skills"
										label="Skills"
										multiple
										options={filtersSkills}
										additionalOnChange={(item: { name: string; value: number[] }) => {
											setFiltersHolder(prev => ({
												...prev,
												skills: item.value
											}));
										}}
										helperText="Select one or more skills"
										control={control}
										errors={errors}
									/>
								</Grid>
								<div className={classes.switchContainer}>
									<InputLabel className={classes.switchLabel}>SHOW VACATIONS</InputLabel>{' '}
									<Switch
										color="primary"
										disabled={filtersHolder.showAvailableOnly}
										checked={filtersHolder.showAvailableOnly || filtersHolder.showTimeOff}
										onChange={handleShowTimeOffSwitch}
									/>
								</div>
							</Grid>
						</form>
					)
				}}
			/>
			<Dialog
				modal={{
					open: Boolean(openConfirmationSubmit),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenConfirmationSubmit(false);
					},
					content: (
						<>
							<Typography gutterBottom>Are you sure you want to leave without saving?</Typography>
						</>
					)
				}}
				title="Leaving without saving"
				actions={submitActions}
				scroll="body"
			/>
			<Dialog
				modal={{
					open: Boolean(openConfirmationDeleteEvent),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenConfirmationDeleteEvent(false);
					},
					content: (
						<>
							<Typography gutterBottom>Are you sure you want to delete this allocation?</Typography>
						</>
					)
				}}
				title="Delete Allocation"
				actions={deleteEventActions}
				scroll="body"
			/>
		</div>
	);
};

export default withLayout(Planner);
