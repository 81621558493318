import * as yup from 'yup';

import { string, stringRequired, boolean } from 'lib/utils/formUtils';

export const createYupSchema = (country: string) => {
	if (country == 'HR') {
		return yup.object().shape({
			company: stringRequired,
			email: stringRequired
				.email('Must be a valid email')
				// eslint-disable-next-line func-names
				.test('duplicatedEmail', 'This email address is already in use.', function (payload) {
					return !this.parent.emails.includes(payload);
				}),
			name: stringRequired,
			fromEmploymentCenter: boolean,
			collaborationProposalId: string,
			employeeCategory: stringRequired
		});
	} else {
		return yup.object().shape({
			company: stringRequired,
			email: stringRequired
				.email('Must be a valid email')
				// eslint-disable-next-line func-names
				.test('duplicatedEmail', 'This email address is already in use.', function (payload) {
					return !this.parent.emails.includes(payload);
				}),
			name: stringRequired,
			collaborationProposalId: string,
			employeeCategory: stringRequired
		});
	}
};

export const createDefaultValues = (country: string) => {
	if (country == 'HR') {
		return {
			company: 'HR',
			email: '',
			name: '',
			fromEmploymentCenter: false,
			collaborationProposalId: null,
			emails: [''],
			employeeCategory: ''
		};
	} else {
		return {
			company: 'PT',
			email: '',
			name: '',
			fromEmploymentCenter: false,
			collaborationProposalId: null,
			emails: [''],
			employeeCategory: ''
		};
	}
};
