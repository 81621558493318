import React, { FC, useState } from 'react';
import { ToggleButton, ToggleButtonGroup as MuiToggleGroup } from '@mui/material';
import { useStyles } from './styles';

export interface IToggleButtonOption {
	label: string;
	value: string;
}
export interface IXPToggleButtonGroupProps {
	buttons: IToggleButtonOption[];
	selectedValue: string;
	selectedBtnHandler: (selectedValue: string) => void;
}

const ToggleButtonGroup: FC<IXPToggleButtonGroupProps> = ({ buttons, selectedValue, selectedBtnHandler }) => {
	const classes = useStyles();
	const [selectedButton, setSelectedButton] = useState(selectedValue);

	const handleChange = (event: React.MouseEvent<HTMLElement>, selectedBtnValue: string) => {
		if (!selectedBtnValue) return;
		setSelectedButton(selectedBtnValue);
		selectedBtnHandler(selectedBtnValue);
	};

	return (
		<MuiToggleGroup className={classes.toggleButtongroup} value={selectedButton} exclusive onChange={handleChange}>
			{buttons.map(buttonStruct => (
				<ToggleButton
					key={`${buttonStruct.value}_id`}
					className={classes.toggleButton}
					classes={{ selected: classes.toggleButtonOverrides }}
					value={buttonStruct.value}>
					{buttonStruct.label}
				</ToggleButton>
			))}
		</MuiToggleGroup>
	);
};

export default ToggleButtonGroup;
