/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';

import {
	booleanRequired,
	string,
	stringRequired,
	dateRequired,
	dateDefault,
	passwordSecure
} from 'lib/utils/formUtils';
import { IConvocationLocation } from 'typings/store/admin/users';

export const defaultValues = {
	terminateOnXtracker: false,
	terminationDate: null,
	erpCollaborationPeriodCloseDate: '',
	erpContractCareerCloseDate: null
};

export const passwordSchema = yup.object().shape({
	isAdmin: string,
	oldPassword: string.when('isAdmin', {
		is: (password: string) => password === null,
		then: stringRequired,
		otherwise: string
	}),
	newPassword: passwordSecure,
	confirmNewPassword: stringRequired.oneOf([yup.ref('newPassword'), null], "Passwords don't match!")
});

export const passwordDefaultValues = {
	isAdmin: '',
	oldPassword: '',
	newPassword: '',
	confirmNewPassword: ''
};
