import withLayout from 'lib/hocs/withLayout';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog } from 'xpand-ui/core';
import { RolesProps } from '.';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { Match } from 'typings/store/generalTypes';
import { Typography } from '@mui/material';
import { useStyles } from './styles';
import { START_API_PATH } from 'lib/utils/constants';
import { addNotification } from 'lib/utils/notifications';

interface Roles extends RolesProps {
	match: Match<{
		path?: string;
		username: string;
	}>;
	goToPage: (path: string) => void;
}
//* COMPONENT
const Roles: FC<Roles> = ({ administration, getRolesList }) => {
	const classes = useStyles();
	const { loading, error, rolesListData } = administration;
	const [viewAssignedUsers, setViewAssignedUsers] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [selectedRole, setSelectedRole] = useState();

	//Gets the necessary data to fill the main table
	useEffect(() => {
		getRolesList();
	}, []);

	// Columns of the main table
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Name',
				id: 'name',
				width: '50%'
			},
			{
				label: 'Assigned Users',
				id: 'assignedUsers',
				width: '50%'
			}
		],
		[]
	);

	//Actions available for each row
	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'accessControlMenu',
				type: 'menu',
				width: '25%',
				render: () => true,

				options: [
					{
						id: 'viewAssignedUsers',
						label: 'View Assigned Users',
						onClick: row => {
							setViewAssignedUsers(true);
							setSelectedRole(row);
						},
						//Since Job Execution without error dont have message and throwable filled, its not necessary to have this button enabled
						disabled: row => row.result == 'SUCCESS' || row.result == ''
					}
				]
			}
		],
		[rolesListData]
	);

	// Prepare data for the main table
	const tableData = useMemo(
		() =>
			rolesListData?.rolesList?.map(e => ({
				id: e.role.id,
				name: e.role.displayName,
				users: e.roleMembers.map(member => member.displayName).join(', '),
				usersObject: e.roleMembers,
				assignedUsers: e.roleAmount
			})),
		[rolesListData]
	);

	//Actions available in the View Details popup
	const confirmActions = [
		{
			id: 'cancel',
			label: 'Close',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setViewAssignedUsers(false);
			}
		}
	];

	// Columns of the assignedUsers table
	const assignedUsersTableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				isSortable: false,
				format: row => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${row.photo}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{
				label: 'Name',
				id: 'name'
			}
		],
		[]
	);

	// Prepare data for the assignedUsers table
	const assignedUsersTableData = useMemo(
		() =>
			selectedRole?.usersObject.map(e => ({
				id: e.id,
				name: e.displayName,
				photo: e.username
			})),
		[selectedRole]
	);

	const isLoading = rolesListData === null;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="desc"
				handleSearch={{ searchValue, setSearchValue }}
			/>
			<Dialog
				maxWidth="lg"
				title="View Assigned Users"
				actions={confirmActions}
				modal={{
					open: viewAssignedUsers,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setViewAssignedUsers(false);
					},
					content: (
						<Table
							tableData={assignedUsersTableData}
							columns={assignedUsersTableColumns}
							defaultSortColumn="desc"
						/>
					)
				}}
			/>
		</>
	);
};

export default withLayout(Roles);
