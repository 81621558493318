//* EXTERNAL LIBS
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { v4 as uuidv4 } from 'uuid';

//* EXTERNAL LIBS --> MUI
import { Typography, LinearProgress } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { PageTitle, LoadingOverlay } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { ICollaborationProposal } from 'typings/store/admin/proposals';

//* PROJECT IMPORTS [LIB / PAGES ]
import Download from 'assets/icons/downloadButton.svg';
import SummaryTable from 'pages/Admin/_ProposalsFormUtils/components/SummaryTable';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { ProposalDetailsModalProps } from '.';

//* COMPONENT INTERFACES
interface IProposalDetailsModal extends ProposalDetailsModalProps {
	proposal: ICollaborationProposal;
	handleClose: Dispatch<SetStateAction<ICollaborationProposal | null>>;
}

//* COMPONENT
const DetailsModal: FC<IProposalDetailsModal> = ({
	proposals,
	proposal,
	getExistingProposalSummary,
	clearSummary,
	handleClose,
	getProposalExportWithID
}) => {
	const classes = useStyles();
	const { loading, proposalSummary } = proposals;

	useEffect(() => {
		if (proposalSummary === null) getExistingProposalSummary(proposal.id);
		return () => {
			clearSummary();
		};
	}, []);
	const [isContractor, setIsContractor] = useState('Employee');

	useEffect(() => {
		if (proposalSummary === undefined) {
			clearSummary();
			handleClose(null);
		}
	}, [proposalSummary]);

	const getProposalMergedObject = () => {
		if (!proposalSummary) return null;

		let mergedProposal = { ...proposal, ...proposalSummary };

		// manually fill benefits
		const benefits = [];
		if (mergedProposal.benefitsValue1) {
			benefits.push({
				uniqueId: uuidv4(),
				goalDetails: mergedProposal.benefitsDescription1,
				prizeValue: mergedProposal.benefitsValue1
			});
		}
		if (mergedProposal.benefitsValue2) {
			benefits.push({
				uniqueId: uuidv4(),
				goalDetails: mergedProposal.benefitsDescription2,
				prizeValue: mergedProposal.benefitsValue2
			});
		}
		if (mergedProposal.benefitsValue3) {
			benefits.push({
				uniqueId: uuidv4(),
				goalDetails: mergedProposal.benefitsDescription3,
				prizeValue: mergedProposal.benefitsValue1
			});
		}

		const prizeBonus = mergedProposal.prizeBonus ? Number(mergedProposal.prizeBonus) : 0;
		const monthsApplicable = mergedProposal.monthsApplicable ? Number(mergedProposal.monthsApplicable) : 12;
		let hourlyRate = 0;
		if (mergedProposal.budget !== 0) {
			hourlyRate = mergedProposal.budget / 8 / 18;
		}

		mergedProposal = {
			...mergedProposal,
			otherBenefitsList: benefits,
			eligiblePrizeValue: (prizeBonus * monthsApplicable) / 12,
			hourlyRate: hourlyRate
		};

		return mergedProposal;
	};

	const mergedProposal = getProposalMergedObject();

	useEffect(() => {
		if (mergedProposal?.budget !== 0 && mergedProposal?.budget !== undefined) {
			setIsContractor('Contractor');
		}
	}, []);

	const submitOnClick = proposalSummary && mergedProposal && mergedProposal.token && mergedProposal.id;
	const pageTitleActions = [
		{
			id: 'pdf',
			onClick: () =>
				submitOnClick && getProposalExportWithID('pdf', mergedProposal.token as string, mergedProposal.id),
			disabled: !proposalSummary || !proposalSummary?.exportConfig?.find(e => e.exportType == 'PDF'),
			icon: null,
			label: (
				<>
					<Download />
					<Typography className={classes.downloadButtonText}>PDF</Typography>
				</>
			)
		},
		{
			id: 'excel',
			onClick: () =>
				submitOnClick && getProposalExportWithID('excel', mergedProposal.token as string, mergedProposal.id),
			disabled: !proposalSummary || !proposalSummary?.exportConfig?.find(e => e.exportType == 'EXCEL'),
			icon: null,
			label: (
				<>
					<Download />
					<Typography className={classes.downloadButtonText}>EXCEL</Typography>
				</>
			)
		}
	];

	return (
		<div className={classes.root}>
			{loading && <LoadingOverlay />}
			<PageTitle
				title={
					<>
						<Typography gutterBottom color="primary" className={classes.name}>
							{mergedProposal?.name || 'N/A'}
						</Typography>
						<Typography className={classes.date}>
							<span className={classes.dateLabel}>DATE: </span>
							{(mergedProposal?.startDate && parseDateToShow(mergedProposal?.startDate)) || ' N/A'}
						</Typography>
					</>
				}
				actions={mergedProposal?.budget === 0 ? pageTitleActions : []}
			/>
			{proposalSummary === null && (
				<div className={classes.w100}>
					<LinearProgress />
				</div>
			)}
			{proposalSummary && (
				<Scrollbars
					style={{ marginTop: '20px', height: 'calc(100vh - 250px)', overflowX: 'hidden' }}
					renderTrackHorizontal={(props: any) => (
						<div {...props} style={{ display: 'none' }} className="track-horizontal" />
					)}>
					<SummaryTable
						proposal={mergedProposal}
						isContractor={isContractor}
						formFields={proposalSummary?.formFields}
					/>
				</Scrollbars>
			)}
		</div>
	);
};

export default DetailsModal;
