import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'typings/state';
import { checkUserPermissions, clearCache } from 'store/system/thunks';

import { setCompanyInformation } from 'store/company/actions';
import { setClearAllFilters } from 'store/filters/actions';
import {
	getUserNotifications,
	getUserNotificationsApprovals,
	getUserNotificationsTimesheets
} from 'store/notifications/thunks';
import { sendPostImpersonateEmail } from 'store/administration/users/thunks';
import App from './App';

const mapStateToProps = ({ system, company, router, users, notifications }: AppState) => ({
	system,
	company,
	router,
	users,
	notifications
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			checkUserPermissions,
			clearCache,
			setCompanyInformation,
			setClearAllFilters,
			getUserNotifications,
			sendPostImpersonateEmail,
			getUserNotificationsTimesheets,
			getUserNotificationsApprovals
		},
		dispatch
	);

export type FCProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(App);
