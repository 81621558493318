//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo } from 'react';

//* EXTERNAL LIBS --> MUI
import { Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, PageTitle, Table } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { IAuditLogEntry } from 'typings/store/admin/proposals';
import { ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import { allStatus } from 'pages/Admin/_ProposalsFormUtils/utils';
import { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS

import { getStatusIdFromString } from 'pages/Admin/Recruitment/UserProposalsList/ProposalAuditLog/utils';
import { useStyles } from './styles';
import { CscAuditLogProps } from '.';

//* COMPONENT INTERFACES
interface ICscAuditLog extends CscAuditLogProps {
	hash: string;
	token: string;
	ESCName: string;
	id: string | number;
}

//* COMPONENT
const CscAuditLog: FC<ICscAuditLog> = ({
	hash,
	token,
	id,
	ESCName,
	employeeSalary,
	getCscAuditLog,
	clearCscAuditLog,
	clearCscError
}) => {
	const classes = useStyles();
	const { loading, cscAuditLog, error } = employeeSalary;

	useEffect(() => {
		if (!cscAuditLog && hash && token && id && !error) {
			getCscAuditLog(hash, token, id);
		}
	}, [cscAuditLog, hash, token, id]);

	useEffect(
		() => () => {
			clearCscAuditLog();
		},
		[]
	);

	const statusHelper = useMemo(() => allStatus(classes), []);

	// TABLE SEARCH HELPER
	const [searchValue, setSearchValue] = useState('');

	// TABLE COLUMNS
	const tableColumns: ITableColumn<IAuditLogEntry>[] = useMemo(
		() => [
			{
				label: 'Name',
				id: 'username',
				accentColumn: true,
				align: 'left',
				width: '15%'
			},
			{
				label: 'Date',
				id: 'date',
				type: 'date',
				width: '20%'
			},
			{
				label: 'New Status',
				id: 'newStatus',
				width: '15%',
				format: row => {
					if (statusHelper[row?.newStatusId])
						return (
							<div className={classes.statusBar}>
								{statusHelper[row?.newStatusId]?.icon} {statusHelper[row?.newStatusId]?.label}
							</div>
						);

					return <div className={classes.statusBar}>{row?.newStatusId}</div>;
				}
			},
			{
				label: 'Previous Status',
				id: 'previousStatus',
				width: '15%',
				format: row => {
					if (statusHelper[row?.previousStatusId])
						return (
							<div className={classes.statusBar}>
								{statusHelper[row?.previousStatusId]?.icon} {statusHelper[row?.previousStatusId]?.label}
							</div>
						);

					return <div className={classes.statusBar}>{row?.previousStatusId}</div>;
				}
			}
		],
		[]
	);

	// TABLE DATA PARSED
	const tableData = useMemo(
		() =>
			cscAuditLog?.auditLog?.map(e => ({
				...e,
				date: parseDateToShow(e.date),
				newStatus: e.newStatus == 'WAITING_APPROVAL' ? 'AWAITING APPROVAL' : e.newStatus,
				previousStatus: e.previousStatus == 'WAITING_APPROVAL' ? 'AWAITING APPROVAL' : e.previousStatus,
				newStatusId: getStatusIdFromString(
					e.newStatus == 'WAITING_APPROVAL' ? 'AWAITING APPROVAL' : e.newStatus
				),
				previousStatusId: getStatusIdFromString(
					e.previousStatus == 'WAITING_APPROVAL' ? 'AWAITING APPROVAL' : (e.previousStatus as string)
				),
				dateWithMinSecMil: new Date(e.date).toISOString()
			})),
		[cscAuditLog]
	);
	if (error) return handleErrorPage(error, clearCscError);
	const isLoading = !cscAuditLog;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle
				title={
					<>
						<Typography gutterBottom color="primary" className={classes.name}>
							{ESCName || 'N/A'}
						</Typography>
					</>
				}
				actions={[]}
			/>
			<Table
				tableData={tableData}
				columns={tableColumns}
				// actions={}
				defaultSortColumn="date"
				sortDateWithMinSecMil={true}
				defaultOrder="desc"
				handleSearch={{ searchValue, setSearchValue }}
			/>
		</>
	);
};

export default CscAuditLog;
