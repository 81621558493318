import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getWelcomeEmail, sendPostWelcomeEmail, clearWelcomeEmail } from 'store/administration/users/thunks';
import { AppState } from 'typings/state';

import SendWelcomeEmail from './SendWelcomeEmail';

const mapStateToProps = ({ users }: AppState) => ({ users });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getWelcomeEmail, sendPostWelcomeEmail, clearWelcomeEmail }, dispatch);

export type SendWelcomeEmailProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SendWelcomeEmail);
