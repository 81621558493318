import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import {
	downloadDocument,
	getContractsList,
	getRenewContract,
	submitRenewContractPost,
	clearCscError
} from 'store/administration/employeeSalaryCategories/thunks';

import ContractManagement from './ContractManagement';
import { setContractManagementPageFilter } from 'store/filters/actions';

const mapStateToProps = ({ general, employeeSalary, filters }: AppState) => ({ general, employeeSalary, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getContractsList,
			downloadDocument,
			getRenewContract,
			submitRenewContractPost,
			clearCscError,
			setContractManagementPageFilter
		},
		dispatch
	);

export type ContractManagementProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ContractManagement);
