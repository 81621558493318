//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo, ChangeEvent } from 'react';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* EXTERNAL LIBS --> MUI
import { FormControl, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { RadioButtonUnchecked, CheckCircle } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { IAdmission, IAdmissionAction, IAdmissionFoundStatus } from 'typings/store/admin/admissions';
import { ITableAction, ITableColumn, ITableColumnsFilter } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { admissionStatus, AVAILABLE_STATUS, AVAILABLE_ACTIONS, CHECK_ADMISSION_DATA_TYPES } from '../utils';
import DetailsModal from './DetailsModal';
import { useStyles } from './styles';
import { AdmissionManagementProps } from '.';

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Manage Admissions - XPECP.png';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACE
interface IAdmissionManagement extends AdmissionManagementProps {
	goToPage: (path: string) => void;
}

//* COMPONENT
const AdmissionManagement: FC<IAdmissionManagement> = ({
	admissions,
	filters,
	goToPage,
	getAdmissionManagementData,
	sendAdmission,
	resendAdmission,
	cancelAdmission,
	closeAdmission,
	clearAdmissionsError,
	setManageAdmissionsPageFilter
}) => {
	const classes = useStyles();
	const { admissionManagementData, loading, error } = admissions;
	const { manageAdmissionsFilter, manageAdmissionStatusFilter } = filters;

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(manageAdmissionsFilter);

	// TABLE COMPONENT - custom filter state field List
	const [statusFilter, setStatusFilter] = useState(manageAdmissionStatusFilter);

	// 0: false, PENDING
	// 1: false, NEW
	// 2: false, WAITING FOR APPROVAL
	// 3: false, ACCEPTED
	// 4: false, REJECTED
	// 6: false, CLOSED
	// 7: false  CANCELED

	// Update filters whenever necessary
	useEffect(() => {
		setManageAdmissionsPageFilter({ searchValue, statusFilter });
	}, [searchValue, statusFilter]);

	// ADMISSIONS STATUS HELPER
	const statusHelper = useMemo(() => admissionStatus(classes), []);

	// CONFIRMATION DIALOG HELPER
	const [openConfirmationModal, setOpenConfirmationModal] = useState<{
		action: IAdmissionAction | null;
		isOpen: boolean;
		row: IAdmission | null;
	}>({
		action: null,
		isOpen: false,
		row: null
	});
	const [detailsModal, setDetailsModal] = useState<IAdmission | null>(null);

	// REFRESH DATA HELPER
	const [refreshData, setRefreshData] = useState(false);

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	useEffect(() => {
		getAdmissionManagementData();
	}, []);

	// Used for refresh data after an action occurs
	useEffect(() => {
		if (!error && refreshData) {
			getAdmissionManagementData();
			setRefreshData(false);
		}
	}, [refreshData]);

	// TABLE ROW ACTIONS
	const tableActions: ITableAction<IAdmission>[] = [
		{
			id: 'admissionActions',
			type: 'menu',
			render: () => true,
			disabled: () => false,
			options: [
				{
					id: 'send',
					label: 'Send',
					render: (row: IAdmission) => row.foundStatus?.id === AVAILABLE_STATUS.PENDING.id,
					onClick: (row: IAdmission) =>
						setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.SEND, isOpen: true, row })
				},
				{
					id: 'resend',
					label: 'Resend',
					render: (row: IAdmission) => row.foundStatus?.id === AVAILABLE_STATUS.NEW.id,
					onClick: (row: IAdmission) =>
						setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.RESEND, isOpen: true, row })
				},
				{
					id: 'submissionInfo',
					label: 'Submission Info',
					render: (row: IAdmission) => {
						if (row.foundStatus)
							return [AVAILABLE_STATUS.APPROVED.id, AVAILABLE_STATUS.CLOSED.id].includes(
								row.foundStatus?.id
							);

						return false;
					},
					to: row =>
						`/cp/admin/admissions/admissionDetails/${CHECK_ADMISSION_DATA_TYPES.SUBMISSION_INFO}/${row.id}`,
					onClick: (row: IAdmission) =>
						goToPage(
							`/admin/admissions/admissionDetails/${CHECK_ADMISSION_DATA_TYPES.SUBMISSION_INFO}/${row.id}`
						)
				},
				{
					id: 'createEmployee',
					label: 'Create Employee',
					render: (row: IAdmission) => row.foundStatus?.id === AVAILABLE_STATUS.APPROVED.id && !row.created,
					to: row => `/cp/admin/createEmployee/${row.id}`,
					onClick: (row: IAdmission) => goToPage(`/admin/createEmployee/${row.id}`)
				},
				{
					id: 'closeAdmission',
					label: 'Close Admission',
					render: (row: IAdmission) => row.foundStatus?.id === AVAILABLE_STATUS.APPROVED.id && !row.created,
					onClick: (row: IAdmission) =>
						setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.CLOSE, isOpen: true, row })
				},
				{
					id: 'validateSubmission',
					label: 'Validate Submission',
					render: (row: IAdmission) => row.foundStatus?.id === AVAILABLE_STATUS.WAITING_APPROVAL.id,
					to: row =>
						`/cp/admin/admissions/admissionDetails/${CHECK_ADMISSION_DATA_TYPES.VALIDATE_SUBMISSION}/${row.id}`,
					onClick: (row: IAdmission) =>
						goToPage(
							`/admin/admissions/admissionDetails/${CHECK_ADMISSION_DATA_TYPES.VALIDATE_SUBMISSION}/${row.id}`
						)
				},
				{
					id: 'cancel',
					label: 'Cancel',
					render: (row: IAdmission) => {
						if (row.foundStatus)
							return [
								AVAILABLE_STATUS.WAITING_APPROVAL.id,
								AVAILABLE_STATUS.NEW.id,
								AVAILABLE_STATUS.REJECTED.id,
								AVAILABLE_STATUS.PENDING.id,
								AVAILABLE_STATUS.APPROVED.id
							].includes(row.foundStatus?.id);
						return false;
					},

					onClick: (row: IAdmission) =>
						setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.CANCEL, isOpen: true, row })
				},
				{
					id: 'employeeData',
					label: 'Employee data',
					render: (row: IAdmission) =>
						Boolean(row.foundStatus && row.foundStatus.id === AVAILABLE_STATUS.CLOSED.id),
					onClick: (row: IAdmission) => setDetailsModal(row)
				}
			]
		}
	];

	// TABLE COLUMNS
	const tableColumns: ITableColumn<IAdmission>[] = useMemo(
		() => [
			{
				label: 'Name',
				id: 'employeeName',
				accentColumn: true,
				align: 'left',
				width: '20%'
			},
			{ label: 'Email Address', id: 'email', width: '20%' },
			{
				label: 'Creation Date',
				id: 'admissionStartDate',
				type: 'date',
				width: '15%',
				format: (row, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Last Update',
				id: 'admissionLastUpdateDate',
				type: 'date',
				width: '15%',
				format: (row, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Admission Date',
				id: 'admissionDate',
				type: 'date',
				width: '15%',
				format: (row, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Status',
				id: 'admissionStatus',
				width: '25%',
				format: (row: IAdmission) => {
					if (row.foundStatus) {
						return (
							<div className={classes.statusBar}>
								{row.foundStatus.icon} {row.foundStatus.label}
							</div>
						);
					}

					return <div className={classes.statusBar}>N/A</div>;
				}
			}
		],
		[admissionManagementData]
	);

	// TABLE DATA
	const tableData = useMemo(
		() =>
			admissionManagementData?.admissions?.map(e => {
				const foundStatus = statusHelper.find(st => st.id === e.admissionStatus);
				return {
					...e,
					status: statusHelper.find(st => st.id === e.admissionStatus).label,
					admissionStatus: foundStatus.label,
					admissionStartDateFormated: parseDateToShow(e.admissionStartDate),
					admissionLastUpdateDateFormated: parseDateToShow(e.admissionLastUpdateDate),
					admissionDateFormated: parseDateToShow(e.admissionDate),
					foundStatus
				};
			}),
		[admissionManagementData]
	);

	// TABLE CUSTOM FILTER STATUS CHANGER
	const handleStatusFilterPropsChange = (event: ChangeEvent<HTMLInputElement>) => {
		setStatusFilter({ ...statusFilter, [event.target.name]: event.target.checked });
	};
	// eslint-disable-next-line no-console

	// TABLE CUSTOM FILTER CONTENT
	const statusFilterComponent = () => (
		<FormControl component="fieldset" style={{ padding: '25px' }}>
			<FormGroup>
				{statusHelper
					.sort((a: IAdmissionFoundStatus, b: IAdmissionFoundStatus) => b.listPosition - a.listPosition)
					.map(e => (
						<FormControlLabel
							key={e.name}
							control={
								<Checkbox
									color="primary"
									icon={<RadioButtonUnchecked />}
									checkedIcon={<CheckCircle />}
									checked={statusFilter[e.id]}
									onChange={handleStatusFilterPropsChange}
									name={`${e.id}`}
								/>
							}
							label={e.label}
						/>
					))}
			</FormGroup>
		</FormControl>
	);

	const getStatusIdByListPosition = (position: string) => {
		// With the list position, we're able to get the status id that will be used by the filter
		const result = statusHelper.find(status => status.id === parseInt(position));
		return result.label;
	};

	// TABLE CUSTOM FILTER
	const tableColumnsFilter: ITableColumnsFilter = useMemo(
		() => ({
			admissionStatus: {
				anyToFilter: Object.values(statusFilter).includes(true),
				filterComponent: statusFilterComponent(),
				handleFilter: (info: IAdmission[]) => {
					const payloadToSearch = Object.keys(statusFilter)
						.filter(field => statusFilter[field])
						.map(e => getStatusIdByListPosition(e));

					return info.filter(item => payloadToSearch.includes(item.admissionStatus));
				}
			}
		}),
		[statusFilter]
	);

	const requestAction = async (action: IAdmissionAction | null) => {
		if (!action || !action.label) {
			return;
		}

		switch (action.id) {
			case AVAILABLE_ACTIONS.SEND.id:
				if (openConfirmationModal) {
					await sendAdmission(openConfirmationModal?.row?.id ? openConfirmationModal?.row?.id : null);
					setRefreshData(true);
				}

				break;
			case AVAILABLE_ACTIONS.RESEND.id:
				if (openConfirmationModal) {
					await resendAdmission(openConfirmationModal?.row?.id ? openConfirmationModal?.row?.id : null);
					setRefreshData(true);
				}

				break;
			case AVAILABLE_ACTIONS.CANCEL.id:
				if (openConfirmationModal) {
					await cancelAdmission(openConfirmationModal?.row?.id ? openConfirmationModal?.row?.id : null);
					setRefreshData(true);
				}

				break;
			case AVAILABLE_ACTIONS.CLOSE.id:
				if (openConfirmationModal) {
					await closeAdmission(openConfirmationModal?.row?.id ? openConfirmationModal?.row?.id : null);
					setRefreshData(true);
				}

				break;
			default:
		}
	};

	const getModalMessage = (action: IAdmissionAction | null) =>
		action ? `Are you sure you want to  ${action.label} this admission?` : '';

	const confirmActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenConfirmationModal({ action: openConfirmationModal.action, isOpen: false, row: null })
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				setOpenConfirmationModal({ action: openConfirmationModal.action, isOpen: false, row: null });
				requestAction(openConfirmationModal.action);
			},
			disabled: getLSField('impersonate_userInfo')
		}
	];

	if (error) return handleErrorPage(error, clearAdmissionsError);

	const isLoading = admissionManagementData === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				tableColumnsFilter={tableColumnsFilter}
				defaultSortColumn="creationDate"
				handleSearch={{ searchValue, setSearchValue }}
				handleDiagramModal={{ diagramModal, setDiagramModal }}
			/>
			<Dialog
				modal={{
					open: Boolean(openConfirmationModal.isOpen),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick')
							setOpenConfirmationModal({
								action: openConfirmationModal.action,
								isOpen: false,
								row: null
							});
					},
					content: (
						<>
							<Typography gutterBottom>{getModalMessage(openConfirmationModal.action)}</Typography>
						</>
					)
				}}
				title="Confirm action"
				actions={confirmActions}
				scroll="body"
			/>
			<Dialog
				fullScreen
				// customClasses={{ fullScreenContent: classes.fullScreenContent }}
				title=""
				scroll="body"
				modal={{
					open: Boolean(detailsModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setDetailsModal(null);
					},
					content: (Boolean(detailsModal) && <DetailsModal proposal={detailsModal} />) || ''
				}}
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(AdmissionManagement);
