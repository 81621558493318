import { restCall } from 'lib/handlers/restHandler';

import { OriginalResponse, Success, GET, POST } from 'lib/handlers/requestTypes';
import { IProposalPayload } from 'typings/store/admin/proposals';

//	CREATE EMPLOYEE
// Admission Management - Create Employee GET
export const fetchCreateEmployee = async (admissionId: string): Promise<OriginalResponse> =>
	restCall(GET, `/admin/admissions/manage/${admissionId}/createEmployee`) as unknown as OriginalResponse;

// Admission Management - Create Employee POST
export const sendCreateEmployee = async (
	admissionId: string,
	payload: IProposalPayload,
	token: string
): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/admissions/manage/${admissionId}/createEmployee${token ? `/${token}` : ''}`, payload);
