import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { AppState } from 'typings/state';

import Profile from './Profile';

const mapStateToProps = ({ personalInfo, company }: AppState) => ({ personalInfo, company });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export type ProfileProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
