/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISystemSkills } from 'typings/store/personalInfoTypes';

export enum SkillsTreeBranches {
	AREA = 'AREA',
	GROUP = 'GROUP',
	SUBGROUP = 'SUBGROUP',
	SKILLS = 'SKILLS'
}

/** REMOVE AREA */
export const removeSkillAreaFromList = (payload: ISystemSkills[], areasId: string[]): ISystemSkills[] | null => {
	let newState = [...payload];

	areasId.map(areaId => {
		const areaToDelete = newState.find(e => `${e.customId}` === `${areaId}`);

		if (areaToDelete?.id) {
			newState = newState.map(skill =>
				`${skill.customId}` === `${areaId}` ? { ...skill, id: skill.id, toDelete: true } : skill
			);
		} else {
			newState = newState.filter(skill => `${skill.customId}` !== `${areaId}`);
		}

		return areaId;
	});

	return newState;
};

/** REMOVE GROUP */
export const removeGroupFromList = (payload: ISystemSkills[] | null, groupId: number): ISystemSkills[] | null => {
	if (!payload) return null;
	const newState = payload.map(area => ({
		...area,
		data: area.data?.filter(skill => skill.customId !== groupId)
	}));
	return newState;
};

/** REMOVE SUBGROUP */
export const removeSubGroupFromList = (payload: ISystemSkills[] | null, subGroupId: number): ISystemSkills[] | null => {
	if (!payload) return null;
	const newState = payload.map(area => ({
		...area,
		data: area.data?.map(group => ({
			...group,
			data: group.data?.filter(skill => skill.customId !== subGroupId)
		}))
	}));
	return newState;
};

/** REMOVE SKILL */
export const removeSkillFromList = (payload: ISystemSkills[] | null, skillsId: number): ISystemSkills[] | null => {
	if (!payload) return null;
	const newState = payload.map(area => ({
		...area,
		data: area.data?.map(group => ({
			...group,
			data: group.data?.map(sub => ({
				...sub,
				data: sub.data?.filter(skill => skill.customId !== skillsId)
			}))
		}))
	}));
	return newState;
};

/** ADD NEW AREA */
export const AddSkillArea = (payload: ISystemSkills[] | null, name: string | null): ISystemSkills[] | null => {
	if (!payload) return null;
	const newState = [
		...payload,
		{
			customId: Math.floor(Math.random() * 5000) + 300,
			name: name || 'New Area',
			actions: [],
			data: []
		}
	];
	return newState;
};

/** ADD NEW GROUP */
export const AddGroupToSkillArea = (
	payload: ISystemSkills[] | null,
	areaId: number | undefined
): ISystemSkills[] | null => {
	if (!payload) return null;
	const newState = payload.map(area => {
		if (area.customId === areaId && area.data) {
			return {
				...area,
				data: [
					...area.data,
					{
						customId: Math.floor(Math.random() * 5000) + 300,
						name: 'New Group',
						actions: [],
						data: []
					}
				]
			};
		}
		return area;
	});
	return newState;
};

/** ADD NEW SUBGROUP */
export const AddSubGroupToGroup = (
	payload: ISystemSkills[] | null,
	groupId: number,
	isSection = false
): ISystemSkills[] | null => {
	if (!payload) return null;
	const newState = payload.map(area => ({
		...area,
		data: area.data?.map(group => {
			if (group.customId === groupId && group.data) {
				return {
					...group,
					data: [
						...group.data,
						{
							customId: Math.floor(Math.random() * 5000) + 300,
							name: 'New Sub Group',
							section: isSection,
							actions: [],
							data: []
						}
					]
				};
			}
			return group;
		})
	}));
	return newState;
};

/** ADD SKILL TO SUBGROUP */
export const AddSkillToSubGroup = (payload: ISystemSkills[] | null, subGroupId: number): any[] | null => {
	if (!payload) return null;
	const newState = payload.map(area => ({
		...area,
		data: area.data?.map(group => ({
			...group,
			data: group.data?.map(sub => {
				if (sub.customId === subGroupId) {
					return {
						...sub,
						data: [
							...(sub.data || []),
							{
								customId: Math.floor(Math.random() * 5000) + 300,
								name: 'New Skill',
								actions: [],
								data: []
							}
						]
					};
				}
				return sub;
			})
		}))
	}));
	return newState;
};

/** CHANGE AREA NAME */
export const getNodeIdBranch = (tree: ISystemSkills[] | null, nodeId: number): string => {
	let foundBranch = '';

	tree?.map(area => {
		if (area.customId === nodeId) {
			foundBranch = SkillsTreeBranches.AREA;
		}
		const areaArray: ISystemSkills[] = [];
		!foundBranch.length && areaArray.push(area);
		!foundBranch.length &&
			area.data?.map(group => {
				if (group.customId === nodeId) {
					foundBranch = SkillsTreeBranches.GROUP;
				}
				const groupArray: ISystemSkills[] = [];
				!foundBranch.length && groupArray.push(group);
				!foundBranch.length &&
					group.data?.map(subgroup => {
						if (subgroup.customId === nodeId) {
							foundBranch = SkillsTreeBranches.SUBGROUP;
						}
						const subGroupArray: ISystemSkills[] = [];
						!foundBranch.length && subGroupArray.push(subgroup);
						!foundBranch.length &&
							subgroup.data?.map(skill => {
								if (skill.customId === nodeId) {
									foundBranch = SkillsTreeBranches.SKILLS;
								}
								return skill;
							});
						return subgroup;
					});
				return group;
			});
		return area;
	});

	return foundBranch;
};

/** CHANGE AREA NAME */
export const changeSkillAreaName = (
	payload: ISystemSkills[] | null,
	areaId: number | string,
	newLabel: string
): ISystemSkills[] | null => {
	if (!payload) return null;
	const newState = payload.map(area => {
		if (area.customId === areaId) {
			return {
				...area,
				name: newLabel
			};
		}
		return area;
	});
	return newState;
};

/** CHANGE GROUP NAME */
export const changeGroupName = (
	payload: ISystemSkills[] | null,
	groupId: number,
	newLabel: string
): ISystemSkills[] | null => {
	if (!payload) return null;
	const newState = payload.map(area => ({
		...area,
		data: area.data?.map(group => {
			if (group.customId === groupId && newLabel !== '') {
				return {
					...group,
					name: newLabel
				};
			}
			return group;
		})
	}));

	return newState;
};

/** CHANGE SUBGROUP NAME */
export const changeSubGroupName = (
	payload: ISystemSkills[] | null,
	subGroupId: number,
	newLabel: string
): ISystemSkills[] | null => {
	if (!payload) return null;
	const newState = payload.map(area => ({
		...area,
		data: area.data?.map(group => ({
			...group,
			data: group.data?.map(sub => {
				if (sub.customId === subGroupId && newLabel !== '') {
					return {
						...sub,
						name: newLabel
					};
				}
				return sub;
			})
		}))
	}));
	return newState;
};

/** CHANGE SKILL NAME */
export const changeSkillName = (
	payload: ISystemSkills[] | null,
	skillId: number,
	newLabel: string
): ISystemSkills[] | null => {
	if (!payload) return null;
	const newState = payload.map(area => ({
		...area,
		data: area.data?.map(group => ({
			...group,
			data: group.data?.map(sub => ({
				...sub,
				data: sub.data?.map(sk => {
					if (sk.customId === skillId && newLabel !== '') {
						return { ...sk, name: newLabel };
					}
					return sk;
				})
			}))
		}))
	}));
	return newState;
};
