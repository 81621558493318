import { AZURE_CLIENT, AZURE_TENENT } from 'lib/utils/constants';

import { PublicClientApplication } from '@azure/msal-browser';

// Protocol Support
export type AuthOptions = {
	clientId: string;
	authority?: string;
	validateAuthority?: boolean;
	redirectUri?: string | (() => string);
	postLogoutRedirectUri?: string | (() => string);
	navigateToLoginRequestUrl?: boolean;
};

// Msal Configurations
const config = {
	auth: {
		clientId: AZURE_CLIENT,
		authority: `https://login.microsoftonline.com/${AZURE_TENENT}/`,
		postLogoutRedirectUri: `${window.location.origin}/cp/`,
		redirectUri: `${window.location.origin}/cp/`,
		navigateToLoginRequestUrl: true
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: true
	}
};

export const pca = new PublicClientApplication(config);
