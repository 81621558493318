/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
//* EXTERNAL LIBS
import React, { FC, useEffect, Fragment, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

//* EXTERNAL LIBS --> MUI
import { Typography, Button, Divider } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { useOnScreen } from 'xpand-ui/utils/hooks';
import { LoadingOverlay } from 'xpand-ui/core';

//* TYPINGS
import { IUserToken } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { CardsContainer, UserCards } from '../utils';
import { useStyles } from './styles';
import { MyTeamProps } from '.';

//* COMPONENT INTERFACES
interface IMyTeam extends MyTeamProps {
	loggedUser: IUserToken;
}

const levels = ['MANAGEMENT', 'MIDDLE MANAGEMENT', 'TEAM'];

const MyTeam: FC<IMyTeam> = ({ personalInfo, getUserTeam, loggedUser, clearPersonalInfoError }) => {
	const classes = useStyles();
	const { loading, error, userTeam } = personalInfo;

	const refScroll = {
		MANAGEMENT: useRef(null),
		'MIDDLE MANAGEMENT': useRef(null),
		TEAM: useRef(null)
	};
	const refVisible = {
		MANAGEMENT: useOnScreen(refScroll.MANAGEMENT),
		'MIDDLE MANAGEMENT': useOnScreen(refScroll['MIDDLE MANAGEMENT']),
		TEAM: useOnScreen(refScroll.TEAM)
	};

	useEffect(() => {
		if (!userTeam && !error) getUserTeam();
	}, [userTeam]);

	if (error) return handleErrorPage(error, clearPersonalInfoError);

	const isLoading = userTeam === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<div>
				{levels.map(
					level =>
						userTeam &&
						userTeam[level] && (
							<Button
								key={level}
								color={refVisible[level] ? 'primary' : 'inherit'}
								style={{ margin: '10px 20px 20px 20px' }}
								variant="outlined"
								onClick={() =>
									refScroll[level].current.scrollIntoView({
										behavior: 'smooth',
										block: 'start'
									})
								}>
								{level}
							</Button>
						)
				)}
			</div>
			<Scrollbars
				style={{ height: 'calc(100vh - 180px)', overflowX: 'hidden' }}
				renderTrackHorizontal={(props: any) => (
					<div {...props} style={{ display: 'none' }} className="track-horizontal" />
				)}>
				{levels.map(
					level =>
						userTeam &&
						userTeam[level] &&
						userTeam[level]?.length > 0 && (
							<Fragment key={level}>
								<Typography
									ref={refScroll[level]}
									className={classes.title}
									variant="h6"
									component="h2"
									color="primary">
									{level}
								</Typography>
								<CardsContainer>
									{userTeam[level].map((user: any) => (
										<UserCards key={user.id} user={user} accentCard={loggedUser.username} />
									))}
								</CardsContainer>
								{levels[levels.length - 1] !== level && <Divider className={classes.divider} />}
							</Fragment>
						)
				)}
			</Scrollbars>
		</>
	);
};

export default withLayout(MyTeam);
