// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	theme => ({
		statusBar: {
			// direction: 'row',
			justify: 'center',
			alignItems: 'center',
			display: 'flex'
		},
		statusIcon: {
			marginRight: '10px'
		},
		statusHidden: {
			color: 'transparent',
			marginRight: '10px'
		},
		companySelect: {
			width: '45%'
		},
		companyContainer: {
			display: 'flex',
			flexGrow: 1,
			alignItems: 'baseline'
		},
		companyLabel: {
			fontSize: '12px',
			minWidth: '150px',
			marginRight: '5px'
		},
		exportMethodLabel: {
			fontSize: '12px',
			minWidth: '150px',
			marginRight: '5px',
			marginTop: '15px'
		},
		avatarContainer: {
			margin: 'auto',
			width: '50px',
			height: '50px',
			position: 'relative',
			overflow: 'hidden',
			borderRadius: '50%'
		},
		avatarImage: {
			display: 'inline',
			margin: '0 auto',
			height: '50px',
			width: 'auto'
		},
		stateTitle: {
			fontSize: '16px',
			color: 'grey',
			textTransform: 'none'
		},
		headerRows: {
			marginBottom: '20px',
			textTransform: 'capitalize'
		},
		hereButton: {
			'&:hover': {
				fontWeight: 700,
				cursor: 'pointer'
			}
		},
		centerInParent: {
			margin: 'auto',
			display: 'block'
		}
	})
);

export { useStyles };
