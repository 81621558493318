import React, { useEffect, useState } from 'react';
import withLayout from 'lib/hocs/withLayout';

import { LoginPage } from 'xpand-ui/lab';

import { useIdToken } from './utils';
import { INSTANCE } from 'lib/utils/constants';
interface ILogin {
	goToPage: (path: string) => void;
	message: string;
	sendLogin: () => void;
}

const Login: React.FC<ILogin> = ({ goToPage, message, sendLogin }) => {
	const isAuthed = useIdToken();
	const [logo, setlogo] = useState<string>('XP')
	useEffect(()=>{
		if(!process.env.INSTANCE?.toLowerCase().includes('xpand')){
			setlogo('BWK')
		}
	}, [])

	useEffect(() => {
		if (isAuthed) {
			goToPage('/apps');
		}
	}, [isAuthed, goToPage]);

	return (
		<span>
			<LoginPage handleSubmit={sendLogin} message={message} logo={logo}/>
		</span>
	);
};

export default withLayout(Login);
