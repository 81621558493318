import * as yup from 'yup';

import { string, boolean } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	clothingSize: string,
	disabilities: string,
	foodAllergiesIntolerances: string,
	healthConditions: string,
	// concents
	biometricSysConsent: boolean,
	intranetSysConsent: boolean,
	promoMediaConsent: boolean,
	demogInfoEngConsent: boolean,
	demogInfoTraConsent: boolean,
	familyInfoConsent: boolean,
	lifeHistoryConsent: boolean,
	clotheSizeConsent: boolean,
	sensitiveInfoConsent: boolean,
	contactInfoConsent: boolean
});

export const defaultValues = {
	clothingSize: '',
	disabilities: '',
	foodAllergiesIntolerances: '',
	healthConditions: '',
	// concents
	biometricSysConsent: false,
	intranetSysConsent: false,
	promoMediaConsent: false,
	demogInfoEngConsent: false,
	demogInfoTraConsent: false,
	familyInfoConsent: false,
	lifeHistoryConsent: false,
	clotheSizeConsent: false,
	sensitiveInfoConsent: false,
	contactInfoConsent: false
};
