import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { sendNewProfilePic } from 'store/personalInfo/thunks';
import { AppState } from 'typings/state';

import UserPhoto from './UserPhoto';

const mapStateToProps = ({ personalInfo }: AppState) => ({ personalInfo });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ sendNewProfilePic }, dispatch);

export type UserPhotoProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserPhoto);
