import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { submitProposalApprove } from 'store/administration/proposals/thunks';
import { setNotificationAsRead } from 'store/notifications/thunks';

import ApproveProposal from './ApproveProposal';

const mapStateToProps = ({ proposals }: AppState) => ({ proposals });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			submitProposalApprove,
			setNotificationAsRead
		},
		dispatch
	);

export type ApproveProposalProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ApproveProposal);
