//* EXTERNAL LIBS
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

//* EXTERNAL LIBS --> MUI
import { Grid, Badge } from '@mui/material';
import { FilterList } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, NoData, Dialog, NoDataSearch } from 'xpand-ui/core';
import TableToolbar from 'xpand-ui/core/Table/TableToolbar';
import { Select } from 'xpand-ui/forms';

//* TYPINGS
import { HelperProps } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { prepareCompanyData } from 'lib/utils/erpUtils';

//* LOCAL COMPONENT IMPORTS
import { filterData, CardsContainer, UserCards } from '../utils';
import { useStyles } from './styles';

//* COMPONENT INTERFACES
import { AllEmployeesProps } from '.';

//* COMPONENT
const AllEmployees: FC<AllEmployeesProps> = ({
	personalInfo,
	filters,
	getAllEmployees,
	clearPersonalInfoError,
	setAllEmployeesPageFilter
}) => {
	const classes = useStyles();
	const { loading, error, allEmployees } = personalInfo;
	const { allEmployeesFilter, allEmployeesAbcFilter, allEmployeesExtraFilter } = filters;
	const [currentLetter, setCurrentLetter] = useState<string>(allEmployeesAbcFilter);
	const [filterModal, openFilterModal] = useState<HelperProps | null>(null);
	const [filter, setFilter] = useState<HelperProps>(allEmployeesExtraFilter);

	const [searchValue, setSearchValue] = useState(allEmployeesFilter);

	useEffect(() => {
		setAllEmployeesPageFilter({ searchValue, currentLetter, filter });
	}, [searchValue, currentLetter, filter]);

	useEffect(() => {
		if (!allEmployees && !error) getAllEmployees();
	}, [allEmployees]);

	// Data related to the Company option
	const companySelectOptions = useMemo(() => {
		if (!allEmployees) {
			return {
				company: [],
				businessUnit: [],
				division: [],
				department: [],
				location: []
			};
		}
		const selectCompanyOptions = {
			...prepareCompanyData(filter, allEmployees),
			location: allEmployees.offices.map(({ name }: { name: string }) => ({ id: name, label: name }))
		};
		return {
			...selectCompanyOptions,
			// Add the specific option for all companies
			company: [{ id: '*', label: 'All Companies' }, ...selectCompanyOptions.company]
		};
	}, [allEmployees, filter?.company, filter?.businessUnit, filter?.division]);

	const companyModalActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setFilter(filterModal as HelperProps);
				openFilterModal(null);
			}
		},
		{
			id: 'reset',
			label: 'Reset',
			color: 'secondary',
			variant: 'outlined',
			onClick: () => {
				setFilter({
					company: '*',
					businessUnit: null,
					division: null,
					department: null,
					location: null
				});
			}
		},
		{
			id: 'submit',
			label: 'Filter',
			color: 'primary',
			variant: 'contained',
			onClick: () => openFilterModal(null)
		}
	];
	const parsedData = useMemo(
		() => filterData(allEmployees, filter, currentLetter, searchValue),
		[allEmployees, filterModal, currentLetter, searchValue]
	);

	// PAGE HEADER ACTIONS
	const pageTitleActions = useMemo(
		() =>
			allEmployees && [
				{
					id: 'filterEmployees',
					onClick: () => openFilterModal(filter),
					icon:
						parsedData.length === allEmployees.users.length ? (
							<FilterList />
						) : (
							<Badge badgeContent={parsedData.length} color="primary">
								<FilterList />
							</Badge>
						),
					label: 'Filter'
				}
			],
		[parsedData]
	);

	const noAPIData = !allEmployees || allEmployees.users?.length === 0;
	const noFilteredData = allEmployees && allEmployees.users?.length > 0 && parsedData && parsedData?.length === 0;
	const hasDataToRender = allEmployees && allEmployees.users?.length > 0 && parsedData && parsedData?.length > 0;

	if (error) return handleErrorPage(error, clearPersonalInfoError);

	const isLoading = allEmployees === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}

			<TableToolbar
				abc
				currentLetter={currentLetter}
				headerActions={pageTitleActions}
				setCurrentLetter={setCurrentLetter}
				handleSearch={{ searchValue, setSearchValue }}
			/>
			{noAPIData && (
				<Grid>
					<NoData />
				</Grid>
			)}
			{noFilteredData && (
				<Grid
					container
					className={classes.centerNoResults}
					spacing={4}
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start">
					<NoDataSearch />
				</Grid>
			)}
			<Scrollbars
				style={{
					height: 'calc(100vh - 220px)',
					overflowX: 'hidden',
					...(!hasDataToRender && { display: 'none' })
				}}
				renderTrackHorizontal={(props: any) => (
					<div {...props} style={{ display: 'none' }} className="track-horizontal" />
				)}>
				{hasDataToRender && (
					<CardsContainer>
						{parsedData.map(user => (
							<UserCards key={user.id} user={user} />
						))}
					</CardsContainer>
				)}
			</Scrollbars>
			<Dialog
				title="Filter Xpanders"
				actions={companyModalActions}
				scroll="body"
				modal={{
					// Sending Edit or Add mode
					open: Boolean(filterModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') openFilterModal(null);
					},
					content:
						(allEmployees && (
							<Grid container style={{ padding: '10px 5px 40px 5px' }} spacing={2}>
								<Grid item xs={12}>
									<Select
										name="company"
										label="Company"
										options={companySelectOptions.company}
										value={filter?.company || '*'}
										onChange={(item: string) => {
											setFilter(prev => ({
												...prev,
												company: item,
												businessUnit: null,
												division: null,
												department: null
											}));
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="businessUnit"
										label="Business Unit"
										options={companySelectOptions.businessUnit}
										value={filter.businessUnit || ''}
										onChange={(item: string) => {
											setFilter(prev => ({
												...prev,
												businessUnit: item,
												division: null,
												department: null
											}));
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="division"
										label="Division"
										options={companySelectOptions.division}
										value={filter.division || ''}
										onChange={(item: string) => {
											setFilter(prev => ({
												...prev,
												division: item,
												department: null
											}));
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="department"
										label="Department"
										options={companySelectOptions.department}
										value={filter.department || ''}
										onChange={(item: string) => {
											setFilter(prev => ({
												...prev,
												department: item
											}));
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="location"
										label="Location"
										options={companySelectOptions.location}
										value={filter.location || ''}
										onChange={(item: string) => {
											setFilter(prev => ({
												...prev,
												location: item
											}));
										}}
									/>
								</Grid>
							</Grid>
						)) ||
						''
				}}
			/>
		</>
	);
};

export default withLayout(AllEmployees);
