/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { FC, useMemo } from 'react';
import { Dialog, LoadingOverlay, Table } from 'xpand-ui/core';
import { sizes } from 'xpand-ui/utils/handlers';
import { Grid } from '@mui/material';
import { InfoField } from 'xpand-ui/forms';
import { useStyles } from './styles';
import { ITableColumn } from 'typings/store/ComponentLib';
import { ITimeOffRequestApprovalDetail } from 'typings/store/personalInfoTypes';
import { convertDateToDDMMYYYY } from '../utils';

//* COMPONENT INTERFACES

// Calendar event Categories
const getCategory = (state: any) => {
	switch (state) {
		case 'Not Submitted':
			return {
				name: 'Not Submitted',
				color: '#FAC15F'
			};
		case 'Submitted':
			return {
				name: 'Submitted',
				color: '#2BAAFF'
			};
		case 'Approved':
			return {
				name: 'Approved',
				color: '#21BA45'
			};
		case 'Rejected':
			return {
				name: 'Rejected',
				color: '#D1345E'
			};
		default:
			return {
				name: 'Not Submitted',
				color: '#FAC15F'
			};
	}
};

const ViewDetailsModal: FC<any> = ({
	selectedTimeOffRequest,
	timeOffRequestApprovalDetailData,
	setSelectedTimeOffRequest,
	openViewDetails,
	setOpenViewDetails
}) => {
	const classes = useStyles();
	const cat = getCategory(selectedTimeOffRequest?.title);

	// TABLE COMPONENT - timeOff Request Approval Detail columns and data
	const tableColumns: ITableColumn<any>[] =
		cat.name == 'Rejected'
			? [
					{ label: 'Approver Name', id: 'approverName', width: '30%' },
					{ label: 'Rejected On', id: 'rejectedOn', width: '20%' },
					{ label: 'Status', id: 'status', width: '10%' },
					{ label: 'Comments', id: 'comments', width: '40%' }
			  ]
			: [
					{ label: 'Approver Name', id: 'approverName', width: '30%' },
					{ label: 'Approved On', id: 'approvedOn', width: '20%' },
					{ label: 'Status', id: 'status', width: '10%' },
					{ label: 'Comments', id: 'comments', width: '40%' }
			  ];

	const tableData: ITimeOffRequestApprovalDetail[] | null = useMemo(
		() =>
			timeOffRequestApprovalDetailData &&
			timeOffRequestApprovalDetailData.map((e: ITimeOffRequestApprovalDetail) => ({
				...e,
				approverName: e.approverName + ' - ' + e.systemApproverType,
				approvedOn: convertDateToDDMMYYYY(e.approvedOn),
				rejectedOn: convertDateToDDMMYYYY(e.modifiedOn),
				status: cat.name == 'Rejected' ? 'Rejected' : 'Approved'
			})),
		[timeOffRequestApprovalDetailData]
	);

	const isLoading = timeOffRequestApprovalDetailData === null || undefined;
	if (isLoading) return <LoadingOverlay />;
	return (
		<>
			{isLoading && <LoadingOverlay />}
			<Dialog
				maxWidth="md"
				modal={{
					open: Boolean(openViewDetails),
					handleClose: (event, reason) => {
						if (reason === 'backdropClick') {
							setOpenViewDetails(false);
							setSelectedTimeOffRequest(null);
						}
					},
					content: (
						<>
							<div
								className={classes.expandedEventLine}
								style={{
									backgroundColor: cat.color
								}}></div>
							<div style={{ marginBottom: '20px' }}></div>
							<Grid
								container
								direction="row"
								alignItems="center"
								justifyContent="space-evenly"
								spacing={3}
								className={classes.expandedEventDetails}>
								<Grid item {...sizes[6]}>
									<InfoField
										label="Time Off Type"
										value={selectedTimeOffRequest?.accountTimeOffType}
									/>
								</Grid>
								<Grid item {...sizes[6]}>
									<InfoField
										label="Created On"
										value={convertDateToDDMMYYYY(selectedTimeOffRequest?.createdOn)}
									/>
								</Grid>
								<Grid item {...sizes[6]}>
									<InfoField
										label="Submitted On"
										value={
											selectedTimeOffRequest?.requestSubmitDate
												? convertDateToDDMMYYYY(selectedTimeOffRequest?.requestSubmitDate)
												: '--'
										}
									/>
								</Grid>
								<Grid item {...sizes[6]}>
									<InfoField label="Hours Off" value={selectedTimeOffRequest?.hoursOff} />
								</Grid>
								<Grid item {...sizes[6]}>
									<InfoField label="Days Off" value={selectedTimeOffRequest?.dayOff} />
								</Grid>
								<Grid item {...sizes[6]}>
									<InfoField
										label="Time Off Start Date"
										value={convertDateToDDMMYYYY(selectedTimeOffRequest?.startDate)}
									/>
								</Grid>
								<Grid item {...sizes[6]}>
									<InfoField
										label="Time Off End Date"
										value={convertDateToDDMMYYYY(selectedTimeOffRequest?.endDate)}
									/>
								</Grid>
								<Grid item {...sizes[6]}>
									<InfoField
										label={cat.name == 'Rejected' ? 'Rejected On' : 'Approved On'}
										value={
											cat.name == 'Rejected'
												? convertDateToDDMMYYYY(selectedTimeOffRequest?.modifiedOn)
												: selectedTimeOffRequest?.approvedOn
												? convertDateToDDMMYYYY(selectedTimeOffRequest?.approvedOn)
												: '--'
										}
									/>
								</Grid>
							</Grid>
							{tableData && tableData.length > 0 && (
								<Table tableData={tableData} columns={tableColumns} />
							)}
						</>
					)
				}}
				title={'Time Off Request Approval Detail'}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							setOpenViewDetails(false);
							setSelectedTimeOffRequest(null);
						}
					}
				]}
				scroll="body"
			/>
		</>
	);
};
export default ViewDetailsModal;
