/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';

import { numberRequired, stringRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	maritalStatus: stringRequired,
	holdersNumber: numberRequired,
	numberOfDependents: numberRequired
});
export const defaultValues = {
	maritalStatus: '',
	holdersNumber: 0,
	numberOfDependents: 0
};
