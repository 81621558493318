import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from 'typings/state';

import { getResignationForm, submitResignationForm, resetResginAction } from 'store/administration/admissions/thunks';

import Resign from './Resign';

const mapStateToProps = ({ admissions }: AppState) => ({ admissions });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getResignationForm, submitResignationForm, resetResginAction }, dispatch);

export type NewAdmissionProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Resign);
