/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable indent */
import { Dispatch } from 'redux';
import { compareValues } from 'xpand-ui/utils/sort';
import { removeTimezone, parseDateToManage } from 'xpand-ui/utils/dates';
import moment, { Moment } from 'moment';

import { OperationsType } from 'typings/store/admin/planner';
import { IEmployeesSkills } from 'typings/store/admin/skillsAndResumes';

import {
	setClearSkillsEvaluation,
	setSearchResumes,
	setSkillsEvaluation
} from 'store/administration/skillsAndResumes/actions';
import { setInvalidDocuments } from 'store/administration/admissions/actions';
import {
	IUpdateBankInfo,
	IUpdateCompanyAllocation,
	IUpdateContacts,
	IUpdateFiscalInfo,
	IUpdatePassword,
	IUpdatePersonalInfo,
	IUpdatePrivateData,
	ISystemSkills,
	ISkillsUpdate,
	IPostResumePayload,
	ITrainings,
	IBenefits
} from 'typings/store/personalInfoTypes';
import {
	// Bookings
	fetchBooking,
	fetchBookingsList,
	fetchEmployeeBookings,
	sendConfirmBooking,
	sendDeleteBooking,
	// Profile
	fetchPendingHealthConvocation,
	fetchUserProfile,
	sendUserUpdateProfile,
	postNewProfilePic,
	postAcceptHealthCareConvocation,
	postDeclineHealthCareConvocation,
	// Company
	fetchAllEmployees,
	fetchUserTeam,
	// Skills
	fetchSystemSkills,
	fetchUserSkills,
	sendUpdateUserSkills,
	// Resume
	fetchUserResume,
	postUserResume,
	// Trainings
	fetchUserTrainings,
	// Benefits
	fetchUserBenefits,
	// Career
	fetchUserCareer,
	// FinancialDocuments
	fetchUserFinancialDocuments,
	downloadUserFinancialDocument,
	sendNewBookings,
	sendNewBlockedSeats,
	fetchEmployeeCountPending,
	fetchExportBookings,
	// Xtracker - MyTimesheets
	fetchWeekViewData,
	fetchDayViewData,
	fetchPrintData,
	fetchTimesheetsPeriodListFiltersData,
	fetchMoveTimeEntry,
	fetchCopySave,
	sendEditBookings,
	fetchExportTimesheet,
	fetchSaveTimesheet,
	fetchAddAttachment,
	fetchListAttachment,
	fetchDeleteAttachment,
	fetchAttachmentDownload,
	fetchImportTemplateDownload,
	fetchWeekViewDataForCopy,
	// Xtracker - MyExpenses
	fetchExpensesData,
	fetchExpenseSheetData,
	fetchExpensesAuditData,
	fetchNewExpenseLineData,
	copyExpenseSheetData,
	sendExpenseSheet,
	deleteExpensesData,
	deleteExpenseEntryData,
	updateExpenseSheet,
	sendNewExpenseLine,
	fetchPrintExpenseSheetData,
	fetchExpenseAddAttachment,
	fetchExpenseDeleteAttachment,
	fetchExpenseListAttachment,
	fetchExpenseDownloadAttachment,
	fetchImportTimesheet,
	fetchSubmitExpenseSheet,
	fetchTimeoffData,
	fetchSaveTimeoff,
	sendSubmitTimeoff,
	sendDeleteTimeoff,
	sendUnsubmitTimeoff,
	fetchTimeoffRequestApprovalDetailsData,
	fetchExportTimeoffICAL,
	fetchExportTimeoffPDF,
	fetchApprovalsTimesheet,
	fetchApprovalsTimesheetPeriodDetails,
	fetchPostApprovalsTimesheetWithoutTimeOffReject,
	fetchPostApprovalsTimesheetWithoutTimeOffApprove,
	fetchPostApprovalsTimesheetWithTimeOffReject,
	fetchPostApprovalsTimesheetWithTimeOffApprove,
	fetchApprovalsExpenses,
	fetchApprovalsExpenseDownloadAttachment,
	fetchOutlookEvents,
	fetchPostApprovalsExpenses,
	fetchApprovalsTimeOff,
	fetchPostApprovalsTimeOff,
	fetchApprovalsTimeOffDetails
} from 'lib/api/general/personalInfo';
import { OriginalResponse } from 'lib/handlers/requestTypes';
import { postDocumentsValidation } from 'lib/api/general/apps';
import { fetchSkillsEvaluation } from 'lib/api/administration/skillsAndResumes';
import {
	setLoading,
	setError,
	// Bookings
	setBooking,
	setBookingsList,
	setEmployeeBookings,
	setConfirmBooking,
	setDeleteBooking,
	setEmployeeBookingsCounter,
	// Profile
	setUserProfile,
	setSendUserUpdate,
	setNewProfilePic,
	setClearUserInfo,
	getUsersPendingConvocationMessage,
	// Comapny
	setAllEmployees,
	setUserTeam,
	// Skills
	setUserSkills,
	setSystemSkills,
	// Resume
	setUserResume,
	setPostUserResume,
	// Trainings
	setUserTrainings,
	// Benefits
	setUserBenefits,
	// Career
	setUserCareer,
	// FinancialDocuments
	setUserFinancialDocuments,
	setUserDocumentDownloadFinished,
	setUpdateBookings,
	setExportBookingsList,
	clearConvocationMessageConfig,
	// Xtracker - MyTimesheets
	setWeekViewData,
	setDayViewData,
	setExportTimesheetList,
	setTimesheetsPeriodListFiltersData,
	setMoveTimeEntryMessage,
	setCopySave,
	setImportTimesheet,
	setExportTimesheet,
	setSaveTimesheet,
	setAttachmentList,
	setLoadingToFalse,
	setWeekViewDataForCopy,
	// Xtracker - MyEspenses
	setExpensesData,
	setExpenseSheetData,
	setNewExpenseLineData,
	setAddNewExpenseSheet,
	setExpensesAuditData,
	setUpdateExpenseSheet,
	setAddNewExpenseLine,
	setExpensesAttachmentList,
	setSubmitExpenseSheetMessage,
	setCopiedExpenseSheetId,
	setTimeoffData,
	setSaveTimeoff,
	setTimeoffRequestApprovalDetailsData,
	setExportTimeoffICAL,
	setExportTimeoffPDF,
	setApprovalsTimesheet,
	setApprovalsTimesheetDetails,
	setUpdateTimesheet,
	setApprovalsExpenses,
	setOutlookEvents,
	setUpdateExpenses,
	setApprovalsTimeoff,
	setUpdateTimeoff,
	setApprovalsTimeOffDetails
} from './actions';
import { getLSField } from 'lib/utils/cookies';

const parseDateToManageInSafari = (date: Date | Moment | string, clearHour = false): string =>
	clearHour
		? `${moment(removeTimezone(date)).format('YYYY/MM/DD')} 00:00:00`
		: moment(removeTimezone(date)).format('YYYY/MM/DD HH:mm:ss');

//* Bookings */
export const getBooking =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchBooking();
			if (response.data) {
				dispatch(setBooking(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};
export const getBookingsList =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setBookingsList(null));
			const response = await fetchBookingsList(payload);
			if (response.data) {
				dispatch(setBookingsList(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getEmployeeBookingsCounter =
	(payload?: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		if (payload) {
			try {
				dispatch(setLoading());
				const response = await fetchEmployeeCountPending(payload);
				if (response.data) {
					dispatch(setEmployeeBookingsCounter(response.data.pendingBookingsCounter));
				} else {
					dispatch(setError(response as OriginalResponse));
				}
			} catch (error) {
				dispatch(setError(error));
			}
		} else {
			dispatch(setEmployeeBookingsCounter(null));
		}
	};

export const clearBookingsList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setBookingsList(null));
	};

export const submitNewBookings =
	(payload: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await sendNewBookings({
				events: payload.map((e: any) => ({
					...e,
					id: e.customId,
					startDate: parseDateToManage(e.startDate),
					endDate: parseDateToManage(e.endDate),
					licensePlate: e.licensePlate
				}))
			});
			if (response.message) {
				let type = 'CREATED';
				if (payload[0].operation === OperationsType.DELETE) type = 'DELETED';

				dispatch(setUpdateBookings({ ...response, type, payload }));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const submitNewBlockedSeats =
	(payload: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await sendNewBlockedSeats({
				events: payload.map((e: any) => ({
					...e,
					id: e.customId,
					startDate: parseDateToManage(e.startDate),
					endDate: parseDateToManage(e.endDate)
				}))
			});
			if (response.message) {
				let type = 'CREATED';
				if (payload[0].operation === OperationsType.DELETE) type = 'DELETED';

				dispatch(setUpdateBookings({ ...response, type, payload }));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const submitEditBookings =
	(payload: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await sendEditBookings({
				events: payload.map((e: any) => ({
					...e,
					id: e.customId,
					startDate: parseDateToManage(e.startDate),
					endDate: parseDateToManage(e.endDate),
					licensePlate: e.licensePlate
				}))
			});
			if (response.message) {
				let type = 'EDITED';
				if (payload[0].operation === OperationsType.DELETE) type = 'DELETED';

				dispatch(setUpdateBookings({ ...response, type, payload }));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const clearNewBookingsList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setUpdateBookings(null));
	};
export const clearEmployeeBookingsList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setEmployeeBookings(null));
	};

export const getEmployeeBookings =
	(officeId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchEmployeeBookings(officeId);
			if (response.data) {
				dispatch(setEmployeeBookings(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};
export const confirmBooking =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await sendConfirmBooking(id);
			if (response.message) {
				dispatch(setConfirmBooking({ ...response, type: 'CONFIRMED' }));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};
export const deleteBooking =
	(id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await sendDeleteBooking(id);
			if (response.message) {
				dispatch(setDeleteBooking({ ...response, type: 'DELETED' }));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const exportBookings =
	(payload: { office: Number | null; startDate: Date; type: string; endDate: Date | null }) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const notUsed: unknown = await fetchExportBookings(payload);
			dispatch(setExportBookingsList(notUsed));
		} catch (error) {
			dispatch(setExportBookingsList([]));
		}
	};

//* Profile */
export const getUserProfile =
	(username: string | '') =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const userInfoStorage = getLSField('impersonate_userInfo');
			const userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
			let response;
			if (userInfo?.username && username.length == 0) {
				// If Impersonate in ON
				response = await fetchUserProfile(userInfo?.username);
			} else {
				response = await fetchUserProfile(username);
			}

			if (response.data) {
				dispatch(setUserProfile(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getHealthConvocationMessage =
	(username: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchPendingHealthConvocation(username);

			if (response.data?.message) {
				dispatch(getUsersPendingConvocationMessage(response.data.message));
			} else {
				dispatch(getUsersPendingConvocationMessage(null));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const sendUserUpdate =
	(
		payload:
			| IUpdateContacts
			| IUpdateCompanyAllocation
			| IUpdatePassword
			| IUpdatePersonalInfo
			| IUpdatePrivateData
			| IUpdateBankInfo
			| IUpdateFiscalInfo,
		isAdmin: string
	) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			if (payload[0].type === 'bankInfo') {
				dispatch(setLoading());
				const ibanTest = [
					{
						name: 'iban',
						value: payload[0].data.iban
					}
				];
				const resIbanCheck = await postDocumentsValidation(ibanTest);
				if (resIbanCheck?.payload?.data) {
					dispatch(setInvalidDocuments(resIbanCheck.payload.data.invalidFields));
				} else {
					await sendUserUpdateProfile(payload, isAdmin);
					const newData = await fetchUserProfile(isAdmin);
					dispatch(setSendUserUpdate(newData.data));
				}
			} else {
				dispatch(setLoading());
				await sendUserUpdateProfile(payload, isAdmin);
				const newData = await fetchUserProfile(isAdmin);
				dispatch(setSendUserUpdate(newData.data));
			}
		} catch (error) {
			dispatch(setSendUserUpdate(null));
			dispatch(setError(error));
		}
	};

export const sendNewProfilePic =
	(username: string, payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await postNewProfilePic(username, payload);
			setTimeout(() => window.location.reload(), 2000);
		} catch (error) {
			dispatch(setNewProfilePic(null));
			dispatch(setError(error));
		}
	};
export const acceptHealthCareConvocation =
	(id: number, email: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await postAcceptHealthCareConvocation(id, email);
			setTimeout(() => window.location.reload(), 2000);
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const declineHealthCareConvocation =
	(id: number, payload: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await postDeclineHealthCareConvocation(id, payload);
			setTimeout(() => window.location.reload(), 2000);
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const clearUserInfo =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setClearUserInfo());
	};

//* Company */
export const getAllEmployees =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchAllEmployees();
			if (response.data) {
				const { data } = response;
				if (data.users) {
					dispatch(
						setAllEmployees({
							...data,
							users: data.users.map((e: any) => {
								const jsonObj = e.summaryData && JSON.parse(e.summaryData);
								return {
									...e,
									summaryData: jsonObj || e.summaryData
								};
							})
						})
					);
				} else {
					dispatch(setAllEmployees(data));
				}
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getUserTeam =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchUserTeam();

			if (response.data?.team) {
				const MANAGEMENT =
					response.data.team.MANAGEMENT &&
					response.data.team.MANAGEMENT.map((e: any) => ({
						...e,
						summaryData: (e.summaryData && JSON.parse(e.summaryData)) || e.summaryData
					}));
				const MIDDLEMANAGEMENT =
					response.data.team['MIDDLE MANAGEMENT'] &&
					response.data.team['MIDDLE MANAGEMENT'].map((e: any) => ({
						...e,
						summaryData: (e.summaryData && JSON.parse(e.summaryData)) || e.summaryData
					}));
				const TEAM =
					response.data.team.TEAM &&
					response.data.team.TEAM.map((e: any) => ({
						...e,
						summaryData: (e.summaryData && JSON.parse(e.summaryData)) || e.summaryData
					}));
				dispatch(
					setUserTeam({
						...(MANAGEMENT && { MANAGEMENT }),
						...(MIDDLEMANAGEMENT && { 'MIDDLE MANAGEMENT': MIDDLEMANAGEMENT }),
						...(TEAM && { TEAM })
					})
				);
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

//* Skills */
export const getUserSkills =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const userInfoStorage = getLSField('impersonate_userInfo');
			const userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
			let response;
			if (userInfo?.username) {
				// If Impersonate in ON
				response = await fetchUserSkills(userInfo?.username);
			} else {
				response = await fetchUserSkills('');
			}
			if (response?.data?.employeeSkills) {
				dispatch(setUserSkills(response.data.employeeSkills));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getSystemSkills =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchSystemSkills();
			if (response?.data) {
				// sort all nodes levels by weight
				dispatch(
					setSystemSkills(
						response.data.tree.sort(compareValues('weight')).map((systemSkill: ISystemSkills) => ({
							...systemSkill,
							data: systemSkill.skillGroups?.sort(compareValues('weight')).map(g => ({
								...g,
								data:
									g.skillSubGroups &&
									g.skillSubGroups
										.sort(compareValues('weight'))
										.map(s => ({ ...s, data: s.skills?.sort(compareValues('weight')) }))
							}))
						}))
					)
				);
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const updateUserSkills =
	(payload: ISkillsUpdate) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await sendUpdateUserSkills(payload);
			if (response.data) {
				dispatch(setClearSkillsEvaluation());
				dispatch(setUserResume(null));
			}
			const response2: { data?: IEmployeesSkills } = await fetchSkillsEvaluation();
			dispatch(setSkillsEvaluation(response2.data || null));
		} catch (error) {
			dispatch(setError(error));
		}
	};

//* Resume */
export const getUserResume =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const userInfoStorage = getLSField('impersonate_userInfo');
			const userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
			let response;
			if (userInfo?.username) {
				// If Impersonate in ON
				response = await fetchUserResume(userInfo?.username);
			} else {
				response = await fetchUserResume('');
			}
			if (response.data) {
				dispatch(setUserResume(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const sendUserResume =
	(payload: IPostResumePayload) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: OriginalResponse = await postUserResume(payload);
			const { data } = await fetchUserResume('');
			dispatch(setPostUserResume(data, response));
			dispatch(setSearchResumes(null));
		} catch (error) {
			dispatch(setError(error));
		}
	};

//* Trainings */
export const getUserTrainings =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const userInfoStorage = getLSField('impersonate_userInfo');
			const userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
			let response;
			if (userInfo?.username) {
				// If Impersonate in ON
				response = await fetchUserTrainings(userInfo?.username);
			} else {
				response = await fetchUserTrainings('');
			}
			if (response.data) {
				dispatch(setUserTrainings(response.data as ITrainings));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

//* Benefits */
export const getUserBenefits =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const userInfoStorage = getLSField('impersonate_userInfo');
			const userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
			let response;
			if (userInfo?.username) {
				// If Impersonate in ON
				response = await fetchUserBenefits(userInfo?.username);
			} else {
				response = await fetchUserBenefits('');
			}
			if (response.data) {
				dispatch(setUserBenefits(response.data as IBenefits));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

//* Career */
export const getUserCareer =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const userInfoStorage = getLSField('impersonate_userInfo');
			const userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
			let response;
			if (userInfo?.username) {
				// If Impersonate in ON
				response = await fetchUserCareer(userInfo?.username);
			} else {
				response = await fetchUserCareer('');
			}
			if (response?.data?.career) {
				dispatch(setUserCareer(response.data.career));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

//* Financial Documents */
export const getUserFinancialDocuments =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const userInfoStorage = getLSField('impersonate_userInfo');
			const userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
			let response;
			if (userInfo?.username) {
				// If Impersonate in ON
				response = await fetchUserFinancialDocuments(userInfo?.username);
			} else {
				response = await fetchUserFinancialDocuments('');
			}
			if (response.data) {
				dispatch(setUserFinancialDocuments(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

//* Career */
export const getUserDocumentByName =
	(documentName: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const userInfoStorage = getLSField('impersonate_userInfo');
			const userInfo = (userInfoStorage && JSON.parse(userInfoStorage)) || null;
			let response;
			if (userInfo?.username) {
				// If Impersonate in ON
				response = await downloadUserFinancialDocument(documentName, userInfo?.username);
			} else {
				response = await downloadUserFinancialDocument(documentName, '');
			}
			if (response) {
				dispatch(setUserDocumentDownloadFinished());
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const clearPersonalInfoError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setError(null));
	};

//* Xtracker - MyTimesheets */
export const getWeekViewData =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setWeekViewData(null));
			const response = await fetchWeekViewData(payload);
			if (response.data) {
				dispatch(setWeekViewData(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getWeekViewDataForCopy =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setWeekViewDataForCopy(null));
			const response = await fetchWeekViewDataForCopy(payload);
			if (response.data) {
				dispatch(setWeekViewDataForCopy(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getDayViewData =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchDayViewData(payload);
			if (response.data) {
				dispatch(setDayViewData(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const printTimesheet =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await fetchPrintData(payload);
			dispatch(setExportTimesheetList());
		} catch (error) {
			dispatch(setExportTimesheetList());
		}
	};

export const importTimesheet =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchImportTimesheet(payload);
			if (response) dispatch(setImportTimesheet(response));
		} catch (error) {
			dispatch(setExportTimesheetList());
		}
	};

export const getPeriodListFiltersData =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const response = await fetchTimesheetsPeriodListFiltersData(payload);
			if (response.data) {
				dispatch(setTimesheetsPeriodListFiltersData(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const moveTimeEntry =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchMoveTimeEntry(payload);
			dispatch(setMoveTimeEntryMessage(response.message || response.payload.message));
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const copySave =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchCopySave(payload);
			if (response.message) {
				dispatch(setCopySave(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const exportTimesheet =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await fetchExportTimesheet(payload);
			dispatch(setExportTimesheet());
		} catch (error) {
			dispatch(setExportTimesheet());
		}
	};

export const saveTimesheet =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchSaveTimesheet(payload);
			if (response.message) {
				dispatch(setSaveTimesheet(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const addAttach =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchAddAttachment(payload);
			if (response.data) {
				dispatch(setAttachmentList(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const deleteAttachment =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchDeleteAttachment(payload);
			if (response) {
				dispatch(setAttachmentList(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const attachmentList =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchListAttachment(payload);
			if (response.data) {
				dispatch(setAttachmentList(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const downloadAttachment =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchAttachmentDownload(payload);
			dispatch(setLoadingToFalse());
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const downloadImportTemplate =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchImportTemplateDownload();
			dispatch(setLoadingToFalse());
		} catch (error) {
			dispatch(setError(error));
		}
	};

//* Xtracker - MyExpenses */

export const getExpensesData =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setExpensesData(null));
			const response = await fetchExpensesData(payload);
			if (response.data) {
				dispatch(setExpensesData(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getExpenseSheetData =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchExpenseSheetData(payload);
			if (response.data) {
				dispatch(setExpenseSheetData(response.data));
				dispatch(setSubmitExpenseSheetMessage(null));
				dispatch(setCopiedExpenseSheetId(null));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getNewExpenseLineData =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// dispatch(setLoading());
			const response = await fetchNewExpenseLineData(payload);
			if (response.data) {
				dispatch(setNewExpenseLineData(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const submitNewExpenseLine =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await sendNewExpenseLine(payload);
			if (response.data) {
				dispatch(setAddNewExpenseLine(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const deleteExpenseData =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await deleteExpensesData(payload);
			if (response) {
				dispatch(setExpensesData(null));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const submitNewExpenseSheet =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await sendExpenseSheet(payload);
			if (response.data) {
				dispatch(setAddNewExpenseSheet(response.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getExpensesAuditData =
	(payload: { expenseSheetId: string }) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setExpensesAuditData(null));
			const response = await fetchExpensesAuditData(payload);
			if (response.data) {
				dispatch(setExpensesAuditData(response?.data));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const copyExpenseSheet =
	(payload: { expenseSheetId: string; username: string; expenseSheetDate: string; description: string }) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await copyExpenseSheetData(payload);
			if (response) {
				dispatch(setExpensesData(null));
				dispatch(setCopiedExpenseSheetId(response.data.accountEmployeeExpenseSheetId));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const addAttachExpenses =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchExpenseAddAttachment(payload);
			if (response) {
				dispatch(setExpensesAttachmentList(null));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const deleteAttachmentExpenses =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchExpenseDeleteAttachment(payload);
			if (response) {
				dispatch(setExpensesAttachmentList(null));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const attachmentListExpenses =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchExpenseListAttachment(payload);
			if (response.data.results) {
				dispatch(setExpensesAttachmentList(response.data.results));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const downloadAttachmentExpenses =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchExpenseDownloadAttachment(payload);
			dispatch(setLoadingToFalse());
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const deleteExpenseEntry =
	(payload: { accountEmployeeExpenseEntryId: number }) =>
	async (dispatch: Dispatch): Promise<boolean> => {
		try {
			dispatch(setLoading());
			const response = await deleteExpenseEntryData(payload);
			if (response) {
				dispatch(setExpenseSheetData(null));
				return true;
			} else {
				dispatch(setError(response as OriginalResponse));
				return false;
			}
		} catch (error) {
			dispatch(setError(error));
			return false;
		}
	};

export const submitUpdateExpenseSheet =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await updateExpenseSheet(payload);
			if (response) {
				dispatch(setUpdateExpenseSheet(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const printExpenseSheet =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await fetchPrintExpenseSheetData(payload);
			dispatch(setExportTimesheetList());
		} catch (error) {
			dispatch(setExportTimesheetList());
		}
	};

export const submitExpenseSheet =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const response = await fetchSubmitExpenseSheet(payload);
			if (response) {
				dispatch(setSubmitExpenseSheetMessage(response.message));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

//* Xtracker - MyTimeoff */
export const getTimeoffData =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setTimeoffData(null));
			const response = await fetchTimeoffData(payload);
			if (response.data) {
				dispatch(setTimeoffData(response.data.results));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const deleteTimeoff =
	(payload: { accountEmployeeId: number; timeOffRequestId: string }) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setTimeoffData(null));
			const response = await sendDeleteTimeoff(payload);
			if (response.message) {
				dispatch(setSaveTimeoff(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const submitTimeoff =
	(payload: { accountEmployeeId: number; timeOffRequestsId: { id: string }[] }) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setTimeoffData(null));
			const response = await sendSubmitTimeoff(payload);
			if (response.message) {
				dispatch(setSaveTimeoff(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const unsubmitTimeoff =
	(payload: { accountEmployeeId: number; timeOffRequestId: string }) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setTimeoffData(null));
			const response = await sendUnsubmitTimeoff(payload);
			if (response.message) {
				dispatch(setSaveTimeoff(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const saveTimeoff =
	(payload: {
		timeoffRequests: {
			accountEmployeeId: any;
			hoursOff: number;
			startDate: string;
			endDate: string;
			description: string;
			daysOff: number;
			projectId: string;
			availableTimeOffBalance: any;
			actionType: string;
			timeOffRequestId: any;
			timeOffTypeId: any;
			isSubmit: boolean;
		}[];
	}) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setTimeoffData(null));
			const response = await fetchSaveTimeoff(payload);
			if (response.message) {
				dispatch(setSaveTimeoff(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getTimeoffRequestApprovalDetailsData =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setTimeoffRequestApprovalDetailsData(null));
			const response = await fetchTimeoffRequestApprovalDetailsData(payload);
			if (response.data) {
				dispatch(setTimeoffRequestApprovalDetailsData(response.data.results));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const exportICALTimeOff =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchExportTimeoffICAL(payload);
			if (response) {
				dispatch(setExportTimeoffICAL(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const exportPDFTimeOff =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchExportTimeoffPDF(payload);
			if (response) {
				dispatch(setExportTimeoffPDF(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getOutlookEvents =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setOutlookEvents(null));
			const response = await fetchOutlookEvents(payload);
			if (response) {
				dispatch(setOutlookEvents(response?.data?.results?.events));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};
