import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { getProposalsList, clearProposalsError } from 'store/administration/proposals/thunks';

import ProposalsList from './ProposalsList';
import { setProposalsPageFilter } from 'store/filters/actions';

const mapStateToProps = ({ proposals, filters }: AppState) => ({ proposals, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getProposalsList, clearProposalsError, setProposalsPageFilter }, dispatch);

export type ProposalsListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ProposalsList);
