import { restCall } from 'lib/handlers/restHandler';
import { GET, PUT, POST, OriginalResponse, Success, EXPORTFILE, EXPORTFILEPOST } from 'lib/handlers/requestTypes';
import { IIrsValuePost } from 'typings/store/admin/proposals';
import { IExportCSCsList, IPostPutCSC } from 'typings/store/admin/employeeSalaryCategories';

// EMPLOYEE SALARY CATEGORIES
export const fetchEmployeesList = async (company?: string): Promise<OriginalResponse | Success> => {
	if (company) return restCall(GET, `/admin/employeeSalaryCategories/${company}`);
	return restCall(GET, '/admin/employeeSalaryCategories');
};

export const fetchExportMultipleCSCs = async (payload: IExportCSCsList): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/employeeSalaryCategories/export', payload);

export const postNewEmployeeSalary = async (payload: IPostPutCSC, token: string): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/employeeSalaryCategories/new${token ? `/${token}` : ''}`, {
		...payload,
		workScheduleExemptionValue: payload.ihtValue,
		prizeValue: payload.prizeBonus
	});
export const putEditEmployeeSalary = async (
	payload: IPostPutCSC,
	token: string,
	id: string | number
): Promise<OriginalResponse | Success> =>
	restCall(PUT, `/admin/employeeSalaryCategories/edit/${token}/${id}`, {
		...payload,
		workScheduleExemptionValue: payload.ihtValue,
		prizeValue: payload.prizeBonus
	});

export const fetchEmployeeSalaryCategoriesUser = async (hash: string): Promise<OriginalResponse> =>
	restCall(GET, `/admin/employeeSalaryCategories/details/${hash}`) as unknown as OriginalResponse;

export const fetchNewEmployeeSalary = async (hash: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/employeeSalaryCategories/new/${hash}`);

export const fetchEditEmployeeSalary = async (
	hash: string,
	token: string,
	proposalId: number
): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/employeeSalaryCategories/edit/${hash}/${token}/${proposalId}`);

export const employeeSalaryCategoryRequestApproval = async (
	token: string,
	proposalId: number
): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/employeeSalaryCategories/requestApproval/${token}/${proposalId}`);

export const employeeSalaryCategoryApprove = async (
	token: string,
	proposalId: number,
	manager: string
): Promise<OriginalResponse> =>
	restCall(
		POST,
		`/admin/employeeSalaryCategories/approve/${token}/${proposalId}/${manager}`
	) as unknown as OriginalResponse;

export const employeeSalaryCategoryReject = async (
	token: string,
	proposalId: number,
	manager: string,
	payload: unknown
): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/employeeSalaryCategories/reject/${token}/${proposalId}/${manager}`, payload);

export const employeeSalaryCategoryClose = async (
	token: string,
	proposalId: number
): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/employeeSalaryCategories/close/${token}/${proposalId}`, {});

export const employeeSalaryCategoryCancel = async (
	token: string,
	proposalId: number
): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/employeeSalaryCategories/cancel/${token}/${proposalId}`);

export const fetchIrsValue = async (payload: IIrsValuePost): Promise<OriginalResponse | Success> =>
	restCall(POST, '/public/util/financial/irs', payload);

export const fetchCscHistoryDetails = async (hash: string, cscErpId: number): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/employeeSalaryCategories/history/${hash}/summary/${cscErpId}`);

export const fetchCscAuditLog = async (
	hash: string,
	token: string,
	id: number | string
): Promise<OriginalResponse | Success> => restCall(GET, `/admin/employeeSalaryCategories/audit/${hash}/${token}/${id}`);

export const fetchCscHistoryExportExcel = async (hash: string, cscErpId: string): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILE, `/admin/employeeSalaryCategories/history/${hash}/download/${cscErpId}`);

// CONTRACT MANAGEMENT
export const fetchContractsList = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/contracts/list');

export const fetchDownloadDocument = async (username: string, payload: string): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILEPOST, `/admin/contracts/${username}/export`, payload);

export const fetchRenewContract = async (username: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/contracts/${username}/renew`);

export const postRenewContract = async (username: string, payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/contracts/${username}/renew`, payload);
