import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import {
	getEmployeesList,
	clearCscError,
	exportMultipleCSCs
} from 'store/administration/employeeSalaryCategories/thunks';
import { setCSCPageFilter } from 'store/filters/actions';

import EmployeesList from './EmployeesList';

const mapStateToProps = ({ employeeSalary, filters }: AppState) => ({ employeeSalary, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getEmployeesList, clearCscError, exportMultipleCSCs, setCSCPageFilter }, dispatch);

export type EmployeesListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesList);
