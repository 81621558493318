/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay } from 'xpand-ui/core';
//* TYPINGS
//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS

//* COMPONENT INTERFACES
//* COMPONENT
const BackPage: FC<any> = ({ goToPage, location, administration, submitAuthorizationCodeRenewal }) => {
	const { codeRenewalActions } = administration;
	const code = location.search.substring(6);

	useEffect(() => {
		submitAuthorizationCodeRenewal(code);
	}, []);

	useEffect(() => {
		if (codeRenewalActions) {
			goToPage('/apps');
		}
	}, [codeRenewalActions]);

	return <LoadingOverlay />;
};

export default withLayout(BackPage);
