import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from 'typings/state';

import { getNewAdmission, sendNewAdmission, clearAdmissionsError } from 'store/administration/admissions/thunks';

import NewAdmission from './NewAdmission';

const mapStateToProps = ({ admissions }: AppState) => ({ admissions });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getNewAdmission, sendNewAdmission, clearAdmissionsError }, dispatch);

export type NewAdmissionProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NewAdmission);
