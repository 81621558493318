import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { AppState } from 'typings/state';

import {
	addAttachExpenses,
	attachmentListExpenses,
	deleteAttachmentExpenses,
	downloadAttachmentExpenses,
	getExpenseSheetData,
	deleteExpenseEntry,
	printExpenseSheet,
	submitUpdateExpenseSheet,
	submitExpenseSheet
} from 'store/personalInfo/thunks';
import { getGuideData, getUserGuideData, setUserGuideAsRead } from 'store/guides/thunks';

import ExpensesNew from './ExpensesNew';
import { setClearExpenseSheetData } from 'store/personalInfo/actions';

const mapStateToProps = ({ personalInfo, filters, guides }: AppState) => ({ personalInfo, filters, guides });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getExpenseSheetData,
			addAttachExpenses,
			deleteAttachmentExpenses,
			downloadAttachmentExpenses,
			attachmentListExpenses,
			deleteExpenseEntry,
			printExpenseSheet,
			submitUpdateExpenseSheet,
			submitExpenseSheet,
			getGuideData,
			getUserGuideData,
			setUserGuideAsRead,
			setClearExpenseSheetData
		},
		dispatch
	);

export type MyExpensesNewProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesNew);
