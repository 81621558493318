//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC, ChangeEventHandler } from 'react';

//* EXTERNAL LIBS --> MUI
import { Typography, Grid, Input } from '@mui/material';
import { Publish, Clear, Check } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog, PageTitle } from 'xpand-ui/core';
import { InfoField } from 'xpand-ui/forms';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { FileTemplatesProps } from '.';
import { useStyles } from './styles';

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Email Templates - XPECP.png';
import { getLSField } from 'lib/utils/cookies';

let formData: FormData = new FormData();

// interface IFileTemplates extends FileTemplatesProps {
// }

interface IRow {
	active: boolean;
	activeParsed: string;
	created: string;
	createdBy: string;
	fileName: string;
	id: number;
}

const FileTemplates: FC<FileTemplatesProps> = ({
	administration,
	filters,
	getFileTemplates,
	sendPostChangeFileTemplateStatus,
	downloadFileTemplate,
	sendPostUploadFileTemplate,
	clearAdministrationError,
	setFileTemplatesPageFilter
}) => {
	const classes = useStyles();
	const { loading, listFileTemplates, error } = administration;
	const { fileTemplatesFilter } = filters;

	const [confirmModal, setConfirmModal] = useState<number | boolean>();
	const [addModalOpen, setAddModalOpen] = useState<boolean | number | null>();

	const [selectedFile, setSelectedFile] = useState<Document | FormData | null>(null);

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(fileTemplatesFilter);

	useEffect(() => {
		setFileTemplatesPageFilter(searchValue);
	}, [searchValue]);

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	useEffect(() => {
		if (!listFileTemplates && !error) {
			getFileTemplates();
		}
	}, [listFileTemplates]);

	// Changes the status of the file Template to it's opposite(Enable/Disable)
	const changeFileTemplateStatus = (id: number) => {
		sendPostChangeFileTemplateStatus(id);
		setConfirmModal(false);
	};

	const buttonDownloadFile = (id: number) => {
		downloadFileTemplate(id);
	};

	const onSubmit = () => {
		sendPostUploadFileTemplate(selectedFile as FormData);
		setAddModalOpen(false);
		setSelectedFile(new FormData());
		formData = new FormData();
	};

	const changeHandler = (event: { target: { files: (string | Blob)[] } }) => {
		formData.append('newDoc', event.target.files[0]);
		setSelectedFile(formData);
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<IRow>[] = useMemo(
		() => [
			{ label: 'File Name', id: 'fileName', accentColumn: true },
			{ label: 'Uploaded', id: 'created' },
			{ label: 'Uploaded By', id: 'createdBy' },
			{
				label: 'Status',
				id: 'active',
				format: (row, text: boolean) =>
					text === false ? (
						<div className={classes.dueDateIcons}>
							<Clear color="primary" /> Disabled{' '}
						</div>
					) : (
						<div className={classes.dueDateIcons}>
							<Check color="primary" /> Enabled{' '}
						</div>
					)
			}
		],
		[]
	);

	const tableActions: ITableAction<IRow>[] = useMemo(
		() => [
			{
				id: 'fileTemplatesMenu',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'fileTemplatesMenuDownload',
						label: 'Download',
						onClick: (row: IRow) => {
							buttonDownloadFile(row.id);
						},
						disabled: () => (getLSField('impersonate_userInfo') ? true : false)
					},
					{
						id: 'fileTemplatesMenuChange',
						label: (row: IRow) => (row.active ? 'Disable' : 'Enable'),
						onClick: (row: IRow) => {
							setConfirmModal(row.id);
						}
					}
				]
			}
		],
		[listFileTemplates]
	);

	const pageTitleActions = useMemo(
		() => [
			{
				id: 'uploadFileTemplate',
				type: 'file',
				onClick: () => {
					setAddModalOpen(true);
				},
				icon: <Publish />,
				label: 'Upload File Template'
			}
		],
		[]
	);

	const newTemplateActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setAddModalOpen(false)
		},
		{
			id: 'submit',
			label: 'Submit',
			color: 'primary',
			type: 'submit',
			form: 'form-new-file-template',
			variant: 'contained',
			disabled:
				selectedFile === null
					? true
					: ((selectedFile as FormData)?.getAll('newDoc').length as number) === 0 ||
					  getLSField('impersonate_userInfo'),
			onClick: onSubmit
		}
	];

	const tableData = useMemo(
		() =>
			listFileTemplates?.fileTemplates?.map(e => ({
				...e,
				activeParsed: e.active === false ? 'Disabled' : 'Enabled'
			})),
		[listFileTemplates]
	);

	if (error) return handleErrorPage(error, clearAdministrationError);

	const isLoading = listFileTemplates === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle actions={pageTitleActions} />
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="fileName"
				handleSearch={{ searchValue, setSearchValue }}
				handleDiagramModal={{ diagramModal, setDiagramModal }}
			/>
			<Dialog
				modal={{
					open: Boolean(confirmModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmModal(false);
					},
					content: (
						<Typography gutterBottom>
							Are you sure you want to change the status of this template?
						</Typography>
					)
				}}
				title="Change File Status"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setConfirmModal(false)
					},
					{
						id: 'changeStatus',
						label: 'Change Status',
						color: 'primary',
						variant: 'contained',
						onClick: () => changeFileTemplateStatus(confirmModal as number),
						disabled: getLSField('impersonate_userInfo')
					}
				]}
				scroll="body"
			/>

			<Dialog
				modal={{
					open: Boolean(addModalOpen),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setAddModalOpen(false);
					},
					content: (
						<form encType="multipart/form-data" id="form-new-file-template">
							<Grid container style={{ padding: '10px 5px 40px 5px' }}>
								<Grid item xs={12}>
									<InfoField label="" value="Select a file to upload" />
									<Input
										onChange={changeHandler as unknown as ChangeEventHandler}
										name="file"
										type="file"
									/>
								</Grid>
							</Grid>
						</form>
					)
				}}
				title="Uploading File"
				actions={newTemplateActions}
				scroll="body"
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(FileTemplates);
