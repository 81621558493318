//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';
import { Add, Subject } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, PageTitle, Dialog, Table, BackPage } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { Select, DatePicker } from 'xpand-ui/forms';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';

//* TYPINGS
import { ICompanyTree } from 'typings/store/admin/administration';
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { IBuManager, ICollaborationProposal, ICompanyInfo } from 'typings/store/admin/proposals';
import { IUserToken, Match } from 'typings/store/generalTypes';
import { IPageTitle } from 'components/App/TitleProvider';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { allStatus } from 'pages/Admin/_ProposalsFormUtils/utils';

//* LOCAL COMPONENT IMPORTS

import ProposalAuditLog from './ProposalAuditLog';
import DetailsModal from './DetailsModal';
import { useStyles } from './styles';
import { UserProposalsListProps } from '.';

//* LOCAL IMPORTS

import { schema, defaultValues } from './yupSchema';

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Collaboration Proposals - XPECP.png';
import Adicionar from '../../../../assets/icons/Adicionar.svg';
import { getLSField } from 'lib/utils/cookies';
import { Roles } from 'lib/roles';
import { IUserPermission } from 'typings/store/systemTypes';

//* COMPONENT INTERFACES
interface IProposalsList extends UserProposalsListProps, IPageTitle {
	match: Match<{
		token: string;
		proposalId: string | number;
	}>;
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
}

const STATUS_NEW = 0;
const STATUS_APPROVED = 1;
const STATUS_SENT = 2;
const STATUS_CANCELED = 3;
const STATUS_REFUSED = 4;
const STATUS_ACCEPTED = 5;
// const STATUS_CLOSED = 6;
const STATUS_WAITING_APPROVAL = 7;
const STATUS_REJECTED = 8;

interface Action {
	id: number;
	label: string;
}
export const AVAILABLE_ACTIONS = {
	REQUEST_APPROVAL: { id: 0, label: 'Request Approval' },
	ACCEPT: { id: 1, label: 'Accept Proposal' },
	REFUSE: { id: 2, label: 'Refuse Proposal' },
	CLOSE: { id: 3, label: 'Close Proposal' },
	CANCEL: { id: 4, label: 'Cancel Proposal' },
	CHANGE_START_DATE: { id: 5, label: 'Change Start Date' },
	APPROVE: { id: 6, label: 'Approve Proposal' },
	REJECT: { id: 7, label: 'Reject Proposal' }
};

const notificationPayload = {
	area: 'Recruitment',
	section: 'Collaboration Proposals'
};

//* COMPONENT
const ProposalsList: FC<IProposalsList> = ({
	proposals,
	filters,
	match,
	system,
	goToPage,
	title,
	setTitle,
	getUserProposalsList,
	submitProposalAccept,
	submitProposalRefuse,
	submitProposalClose,
	submitProposalCancel,
	submitProposalRequestApproval,
	submitProposalChangeStartDate,
	clearProposalsError,
	setNotificationAsRead,
	loggedUser
}) => {
	const classes = useStyles();
	const { userPermissions } = system;
	const { loading, userProposalsList, error } = proposals;
	const { params } = match;
	const pageToken = params?.token;
	const [isProposalAdmin, setIsProposalAdmin] = useState<Boolean>(false);
	useEffect(() => {
		setIsProposalAdmin(
			userPermissions?.some(
				(e: IUserPermission) =>
					e?.displayName === Roles.CP_ROLE_TALENT_ACQUISITION_ADMIN ||
					e?.displayName === Roles.CP_ROLE_SYSTEM_ADMIN ||
					e?.displayName === Roles.CP_ROLE_BUSINESS_UNIT_MANAGERS
			)
		);
	}, [userPermissions]);

	const { width } = useWindowDimensions();

	const isTablet = useMemo(() => width <= 900, [width]); // default is 900 tablet

	// TABLE SEARCH HELPER
	const [searchValue, setSearchValue] = useState('');

	const {
		control,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		if (!error && pageToken && userProposalsList && userProposalsList[pageToken]) {
			const lastProposalIndex = userProposalsList[pageToken].userProposals.length - 1;
			const lastProposal = userProposalsList[pageToken].userProposals[lastProposalIndex];

			const newTitleString = `${lastProposal.name} collaboration proposals`;

			newTitleString !== title.title &&
				setTitle({
					...title,
					title: `${lastProposal.name} collaboration proposals`
				});
		}
	}, [userProposalsList, pageToken, title]);

	useEffect(() => {
		if (!error && pageToken && (!userProposalsList || !userProposalsList[pageToken]))
			getUserProposalsList(pageToken);
	}, [userProposalsList, pageToken]);

	const statusHelper = useMemo(() => allStatus(classes), []);

	const [buSelected, setBuSelected] = useState('');
	const [changeStartDateSelected, setChangeStartDateSelected] = useState<Date | string | null>();
	const [requestApproval, setRequestApproval] = useState<ICollaborationProposal | null>(null);
	const [detailsModal, setDetailsModal] = useState<ICollaborationProposal | null>(null);
	const [auditModal, setAuditModalModal] = useState<ICollaborationProposal | null>(null);
	const [changeStartDateModal, setChangeStartDateModal] = useState<ICollaborationProposal | null>(null);

	// CONFIRMATION DIALOG HELPER
	const [openConfirmationModal, setOpenConfirmationModal] = useState<{
		row: ICollaborationProposal | null;
		isOpen: boolean;
		action: Action | null;
	}>({
		action: null,
		isOpen: false,
		row: null
	});

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	// TABLE ROW ACTIONS
	const tableActions: ITableAction<ICollaborationProposal>[] = useMemo(
		() => [
			{
				id: 'detailsAction',
				type: 'button',
				icon: <Subject />,
				render: () => true,
				disabled: () => false,
				onClick: row => setDetailsModal(row)
			},
			{
				id: 'proposalActions',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'edit',
						label: 'Edit',
						render: row =>
							row.proposalStatus === STATUS_NEW ||
							row.proposalStatus === STATUS_REJECTED ||
							row.proposalStatus === STATUS_ACCEPTED,
						to: row => `/cp/admin/recruitment/editProposal/${row.token}/${row.id}`,
						onClick: row => goToPage(`/admin/recruitment/editProposal/${row.token}/${row.id}`)
					},
					{
						id: 'auditLog',
						label: 'Audit Log',
						render: () => true,
						onClick: row => setAuditModalModal(row)
					},
					{
						id: 'requestApproval',
						label: 'Request Approval',
						render: row => row.proposalStatus === STATUS_NEW || row.proposalStatus === STATUS_REJECTED,
						onClick: row => setRequestApproval(row)
					},
					{
						id: 'sendEmail',
						label: 'Send Email',
						render: row => row.proposalStatus === STATUS_APPROVED,
						to: row => `/cp/admin/recruitment/sendProposalEmail/${row.token}/${row.id}`,
						onClick: row => goToPage(`/admin/recruitment/sendProposalEmail/${row.token}/${row.id}`)
					},
					{
						id: 'accept',
						label: 'Accept',
						render: row => row.proposalStatus === STATUS_SENT || row.proposalStatus === STATUS_REFUSED,
						onClick: row =>
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.ACCEPT, isOpen: true, row }) // submitProposalAccept(row.token, row.id)
					},
					{
						id: 'refuse',
						label: 'Refuse',
						render: row => row.proposalStatus === STATUS_SENT || row.proposalStatus === STATUS_ACCEPTED,
						onClick: row =>
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.REFUSE, isOpen: true, row }) // submitProposalRefuse(row.token, row.id)
					},
					{
						id: 'approve',
						label: 'Approve',
						render: row => row.proposalStatus === STATUS_WAITING_APPROVAL && isProposalAdmin,
						onClick: row =>
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.APPROVE, isOpen: true, row })
					},
					{
						id: 'reject',
						label: 'Reject',
						render: row => row.proposalStatus === STATUS_WAITING_APPROVAL && isProposalAdmin,
						onClick: row =>
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.REJECT, isOpen: true, row })
					},
					{
						id: 'close',
						label: 'Close',
						render: row => row.proposalStatus === STATUS_ACCEPTED,
						onClick: row => setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.CLOSE, isOpen: true, row }) // submitProposalClose(row.token, row.id)
					},
					{
						id: 'cancel',
						label: 'Cancel',
						render: row => row.proposalStatus !== STATUS_CANCELED,
						onClick: row =>
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.CANCEL, isOpen: true, row }) // submitProposalCancel(row.token, row.id)
					},
					{
						id: 'changeStartDate',
						label: 'Change Start Date',
						render: row =>
							row.proposalStatus !== STATUS_NEW &&
							row.proposalStatus !== STATUS_WAITING_APPROVAL &&
							row.proposalStatus !== STATUS_CANCELED,
						onClick: row => {
							setChangeStartDateModal(row);
							setValue('newStartDate', row.startDate);
						} // submitProposalCancel(row.token, row.id)
					}
				]
			}
		],
		[isProposalAdmin]
	);

	// TABLE COLUMNS
	const tableColumns: ITableColumn<ICollaborationProposal>[] = useMemo(
		() => [
			{
				label: 'Type',
				id: 'collaborationType',
				accentColumn: true,
				align: 'left',
				width: '25%'
			},
			{
				label: 'Proposal Date',
				id: 'proposalDate',
				type: 'date',
				format: (row, date) => parseDateToShow(date, true),
				width: '20%'
			},
			{ label: 'Business Unit', id: 'businessUnitLabel', width: '30%' },
			{
				label: 'Proposal Status',
				id: 'proposalStatus',
				width: '15%',
				format: (row, status) => {
					if (statusHelper[status])
						return (
							<div className={classes.statusBar}>
								{statusHelper[status].icon} {statusHelper[status].label}
							</div>
						);

					return <div className={classes.statusBar}>{status}</div>;
				}
			}
		],
		[userProposalsList]
	);

	// TABLE DATA PARSED
	const tableData = useMemo(() => {
		if (!userProposalsList || !userProposalsList[pageToken]) return [];

		return userProposalsList[pageToken].userProposals.map((e: ICollaborationProposal) => {
			const companyBusinessUnits = userProposalsList[pageToken].companies.find(
				(comp: ICompanyInfo) => comp.searchKey === e.company
			).companyTree.childNodes;

			const businessUnit = companyBusinessUnits.find((b: ICompanyTree) => b.organization === e.businessUnit) || {
				organization$_identifier: 'N/A'
			};

			return { ...e, businessUnitLabel: businessUnit.organization$_identifier };
		});
	}, [userProposalsList]);

	// PAGE HEADER ACTIONS
	const pageTitleActions = useMemo(
		() => [
			{
				id: 'addProposal',
				to: `/admin/recruitment/newProposal/${pageToken}`,
				onClick: () => goToPage(`/admin/recruitment/newProposal/${pageToken}`),
				icon: <Adicionar />,
				label: 'New Proposal',
				disabled: getLSField('impersonate_userInfo')
			}
		],
		[]
	);

	const requestAction = (action: Action) => {
		if (!action || !action.label) {
			return;
		}

		switch (action.id) {
			case AVAILABLE_ACTIONS.REQUEST_APPROVAL.id:
				if (requestApproval) {
					submitProposalRequestApproval(requestApproval.token as string, requestApproval.id, {
						manager: buSelected
					});
					setRequestApproval(null);
					setBuSelected('');
				}
				break;
			case AVAILABLE_ACTIONS.ACCEPT.id:
				openConfirmationModal.row &&
					submitProposalAccept(openConfirmationModal.row.token as string, openConfirmationModal.row.id);
				break;
			case AVAILABLE_ACTIONS.REFUSE.id:
				openConfirmationModal.row &&
					submitProposalRefuse(openConfirmationModal.row.token as string, openConfirmationModal.row.id);
				break;
			case AVAILABLE_ACTIONS.APPROVE.id:
				openConfirmationModal.row &&
					goToPage(
						`/admin/recruitment/proposals/approve/${openConfirmationModal.row.token}/${openConfirmationModal.row.id}/${loggedUser.username}`
					);

				break;
			case AVAILABLE_ACTIONS.REJECT.id:
				openConfirmationModal.row &&
					goToPage(
						`/admin/recruitment/proposals/reject/${openConfirmationModal.row.token}/${openConfirmationModal.row.id}/${loggedUser.username}`
					);
				break;
			case AVAILABLE_ACTIONS.CLOSE.id:
				openConfirmationModal.row &&
					submitProposalClose(openConfirmationModal.row.token as string, openConfirmationModal.row.id);
				break;
			case AVAILABLE_ACTIONS.CANCEL.id:
				openConfirmationModal.row &&
					submitProposalCancel(openConfirmationModal.row.token as string, openConfirmationModal.row.id);
				break;
			case AVAILABLE_ACTIONS.CHANGE_START_DATE.id:
				if (changeStartDateModal) {
					submitProposalChangeStartDate(changeStartDateModal.token as string, changeStartDateModal.id, {
						startDate: changeStartDateSelected as string
					});
					setChangeStartDateModal(null);
					setChangeStartDateSelected(null);
				}
				break;
			default:
		}
	};

	const getModalMessage = (action: Action) =>
		action ? `Are you sure you want to execute the following action: ${action.label} ` : '';

	const confirmActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenConfirmationModal({ action: openConfirmationModal.action, isOpen: false, row: null })
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				setOpenConfirmationModal({ action: openConfirmationModal.action, isOpen: false, row: null });
				requestAction(openConfirmationModal.action as Action);
				if (openConfirmationModal.action == AVAILABLE_ACTIONS.CANCEL) {
					setNotificationAsRead(loggedUser?.username, notificationPayload);
				}
			},
			disabled: getLSField('impersonate_userInfo')
		}
	];

	if (error) return handleErrorPage(error, clearProposalsError);
	const isLoading = !userProposalsList || !userProposalsList[pageToken];
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<BackPage path="/admin/recruitment/proposals" action={goToPage} />
			<PageTitle title="" actions={pageTitleActions} />
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="proposalDate"
				defaultOrder="desc"
				handleSearch={{ searchValue, setSearchValue }}
				handleDiagramModal={{ diagramModal, setDiagramModal }}
			/>
			<Dialog
				title="Request Approval"
				scroll="body"
				modal={{
					open: Boolean(requestApproval),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setRequestApproval(null);
							setBuSelected('');
						}
					},
					content: (
						<Grid container style={{ padding: '10px 5px 40px 5px' }} spacing={2}>
							<Grid item xs={12}>
								<Select
									label="Business Unit Leader"
									name="buSelected"
									required
									onChange={(newVal: string) => setBuSelected(newVal)}
									value={buSelected}
									options={
										userProposalsList
											? userProposalsList[pageToken].buManagers.map((e: IBuManager) => ({
													id: e.username,
													label: `${e.displayName}`
											  }))
											: []
									}
								/>
							</Grid>
						</Grid>
					)
				}}
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => {
							setRequestApproval(null);
							setBuSelected('');
						}
					},
					{
						id: 'submit',
						label: 'Submit',
						color: 'primary',
						variant: 'contained',
						disabled: Boolean(buSelected === '' || !buSelected || getLSField('impersonate_userInfo')),
						onClick: (row: ICollaborationProposal) => {
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.REQUEST_APPROVAL, isOpen: true, row });
						}
					}
				]}
			/>
			<Dialog
				fullScreen
				customClasses={{
					fullScreenContent: isTablet ? classes.fullScreenContentTablet : classes.fullScreenContent
				}}
				title=""
				scroll="body"
				modal={{
					open: Boolean(detailsModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setDetailsModal(null);
					},
					content:
						(Boolean(detailsModal) && (
							<DetailsModal
								proposal={detailsModal as ICollaborationProposal}
								handleClose={setDetailsModal}
							/>
						)) ||
						''
				}}
			/>
			<Dialog
				fullScreen
				customClasses={{
					fullScreenContent: isTablet ? classes.fullScreenContentTablet : classes.fullScreenContent
				}}
				title=""
				scroll="body"
				modal={{
					open: Boolean(auditModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setAuditModalModal(null);
					},
					content:
						(auditModal && (
							<ProposalAuditLog
								id={auditModal.id}
								token={auditModal.token as string}
								name={`${title.title} - audit log`}
								handleClose={setAuditModalModal}
							/>
						)) ||
						''
				}}
			/>
			<Dialog
				title="Change Start Date"
				scroll="body"
				modal={{
					open: Boolean(changeStartDateModal),
					handleClose: (reason: string) => {
						if (reason !== 'backdropClick') {
							setChangeStartDateModal(null);
							setChangeStartDateSelected(null);
						}
					},
					content: (
						<Grid container style={{ padding: '10px 5px 40px 5px' }} spacing={2}>
							<Grid item xs={12}>
								<DatePicker
									name="newStartDate"
									label="Collaboration Start Date"
									additionalOnChange={item => {
										item && setChangeStartDateSelected(item);
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
						</Grid>
					)
				}}
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => {
							setChangeStartDateModal(null);
							setChangeStartDateSelected(null);
						}
					},
					{
						id: 'submit',
						label: 'Submit',
						color: 'primary',
						variant: 'contained',
						disabled: !changeStartDateSelected || getLSField('impersonate_userInfo'),
						onClick: (row: ICollaborationProposal) => {
							setOpenConfirmationModal({
								action: AVAILABLE_ACTIONS.CHANGE_START_DATE,
								isOpen: true,
								row
							});
						}
					}
				]}
			/>
			<Dialog
				modal={{
					open: Boolean(openConfirmationModal.isOpen),
					handleClose: (reason: string) => {
						if (reason !== 'backdropClick')
							setOpenConfirmationModal({
								action: openConfirmationModal.action,
								isOpen: false,
								row: null
							});
					},
					content: (
						<>
							<Typography gutterBottom>
								{getModalMessage(openConfirmationModal.action as Action)}
							</Typography>
						</>
					)
				}}
				title="Confirm action"
				actions={confirmActions}
				scroll="body"
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(ProposalsList);
