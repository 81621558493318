import * as yup from 'yup';
import { string, boolean } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	content: string.max(500, 'This field exceeds the maximum limit (500)'),
	placement: string,
	target: string,
	title: string,
	disableScrolling: boolean,
	spotlightClicks: boolean
});

export const defaultValues = {
	content: '',
	placement: '',
	target: '',
	title: '',
	disableScrolling: false,
	spotlightClicks: false
};
