// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		iconContainer: {
			width: '50px',
			height: '50px',
			backgroundColor: 'white'
		},
		icon: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}
	})
);

export { useStyles };
