/* eslint-disable no-plusplus */
export const calculateVacationDays = (date: string | undefined = undefined): number => {
	let vacationDays = 0;
	if (date) {
		const parse = date.split(' ')[0].split('-').reverse();
		const day = parseInt(parse[0]);
		let month = parseInt(parse[1]);
		if (day > 4)
			// if the person is hired after day 5, it will use the next month's vacations
			month++;
		if (month <= 3)
			// Jan, Feb or Mar
			vacationDays = 20;
		else vacationDays = 26 - month * 2;
	}
	return vacationDays;
};
