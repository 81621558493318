import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { getExistingProposalSummary, clearSummary } from 'store/administration/proposals/thunks';

import {
	getCscHistory,
	clearCsCHistory,
	getCscHistoryExportExcel
} from 'store/administration/employeeSalaryCategories/thunks';
// eslint-disable-next-line import/no-named-as-default-member
import DetailsModalHistory from './DetailsModalHistory';

const mapStateToProps = ({ proposals, employeeSalary }: AppState) => ({ proposals, employeeSalary });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getExistingProposalSummary,
			clearSummary,
			getCscHistoryExportExcel,
			getCscHistory,
			clearCsCHistory
		},
		dispatch
	);

export type DetailsModalHistoryProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DetailsModalHistory);
