//* EXTERNAL LIBS
import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { v4 as uuidv4 } from 'uuid';

//* EXTERNAL LIBS --> MUI
import { Typography, LinearProgress } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { PageTitle, LoadingOverlay } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { ICollaborationProposal } from 'typings/store/admin/proposals';

//* PROJECT IMPORTS [LIB / PAGES ]
import SummaryTable from 'pages/Admin/_ProposalsFormUtils/components/SummaryTable';
import Download from 'assets/icons/downloadButton.svg';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { CSCDetailsModalProps } from '.';

//* COMPONENT INTERFACES
interface ICSCDetailsModal extends CSCDetailsModalProps {
	proposal: ICollaborationProposal;
	handleClose: Dispatch<SetStateAction<ICollaborationProposal | null>>;
	hash: string;
}

//* COMPONENT
const DetailsModal: FC<ICSCDetailsModal> = ({
	proposals,
	proposal,
	employeeSalary,
	getExistingProposalSummary,
	clearSummary,
	handleClose,
	hash,
	getCscHistory,
	getProposalExportWithID
}) => {
	const classes = useStyles();
	const { loading } = proposals;
	const proposalSummary = employeeSalary.proposalSummary || proposals.proposalSummary;
	useEffect(() => {
		if (proposalSummary === null) {
			if (proposal.token) {
				getExistingProposalSummary(proposal.id);
			} else {
				getCscHistory(hash, proposal.id);
			}
		}

		return () => {
			clearSummary();
		};
	}, []);

	useEffect(() => {
		if (proposalSummary === undefined) {
			clearSummary();
			handleClose(null);
		}
	}, [proposalSummary]);

	const getProposalMergedObject = () => {
		if (!proposalSummary) return null;

		let mergedProposal = proposal.token
			? { ...proposal, ...proposalSummary }
			: {
					...proposal,
					...proposalSummary.summary,
					hasTwelfths: proposalSummary.summary.christmasTwelfthValue !== 0,
					commsPlafondFlag: proposalSummary.summary.monthlyStandardCommunicationsPlafond !== 0,
					communicationsPlafond: proposalSummary.summary.monthlyStandardCommunicationsPlafond,
					healthInsuranceCheck: proposalSummary.summary.monthlyStandardHealthInsuranceCost !== 0,
					healthInsurance: proposalSummary.summary.monthlyStandardHealthInsuranceCost,
					prizeBonusGoalTypes: proposalSummary.prizeBonusGoalTypes
			  };

		// manually fill benefits
		const benefits = [];
		if (mergedProposal.benefitsValue1) {
			benefits.push({
				uniqueId: uuidv4(),
				goalDetails: mergedProposal.benefitsDescription1,
				prizeValue: mergedProposal.benefitsValue1
			});
		}
		if (mergedProposal.benefitsValue2) {
			benefits.push({
				uniqueId: uuidv4(),
				goalDetails: mergedProposal.benefitsDescription2,
				prizeValue: mergedProposal.benefitsValue2
			});
		}
		if (mergedProposal.benefitsValue3) {
			benefits.push({
				uniqueId: uuidv4(),
				goalDetails: mergedProposal.benefitsDescription3,
				prizeValue: mergedProposal.benefitsValue1
			});
		}

		const prizeBonus = mergedProposal.prizeBonus ? Number(mergedProposal.prizeBonus) : 0;
		const monthsApplicable = mergedProposal.monthsApplicable ? Number(mergedProposal.monthsApplicable) : 12;

		mergedProposal = {
			...mergedProposal,
			otherBenefitsList: benefits,
			eligiblePrizeValue: (prizeBonus * monthsApplicable) / 12
		};

		return mergedProposal;
	};

	const mergedProposal = getProposalMergedObject();
	const pageTitleActions =
		(proposal?.collaborationType === 'Collaboration Type' &&
			proposal?.employeeCategory !== 'Contractor' &&
			mergedProposal?.token) ||
		(proposal?.collaborationType !== 'Collaboration Type' &&
			proposal?.employeeCategory !== 'Contractor' &&
			mergedProposal?.token)
			? [
					{
						id: 'pdf',
						onClick: () =>
							proposalSummary && getProposalExportWithID('pdf', mergedProposal.token, mergedProposal.id),
						disabled: !proposalSummary || !proposalSummary?.exportConfig?.find(e => e.exportType == 'PDF'),
						icon: null,
						label: (
							<>
								<Download />
								<Typography className={classes.downloadButtonText}>PDF</Typography>
							</>
						)
					},
					{
						id: 'excel',
						onClick: () =>
							proposalSummary &&
							getProposalExportWithID('excel', mergedProposal.token, mergedProposal.id),
						disabled:
							!proposalSummary || !proposalSummary?.exportConfig?.find(e => e.exportType == 'EXCEL'),
						icon: null,
						label: (
							<>
								<Download />
								<Typography className={classes.downloadButtonText}>EXCEL</Typography>
							</>
						)
					}
			  ]
			: [];
	return (
		<div className={classes.root}>
			{loading && <LoadingOverlay />}
			<PageTitle
				title={
					<>
						<Typography gutterBottom color="primary" className={classes.name}>
							{mergedProposal?.name || 'N/A'}
						</Typography>
						<Typography className={classes.date}>
							<span className={classes.dateLabel}>DATE: </span>
							{(mergedProposal?.startDate && parseDateToShow(mergedProposal?.startDate)) || ' N/A'}
						</Typography>
					</>
				}
				actions={pageTitleActions}
			/>
			{proposalSummary === null && (
				<div className={classes.w100}>
					<LinearProgress />
				</div>
			)}
			{proposalSummary && (
				<Scrollbars
					style={{ marginTop: '20px', height: 'calc(100vh - 250px)', overflowX: 'hidden' }}
					renderTrackHorizontal={(props: any) => (
						<div {...props} style={{ display: 'none' }} className="track-horizontal" />
					)}>
					<SummaryTable
						proposal={mergedProposal}
						isContractor={proposal?.collaborationType}
						isCollabType={proposal?.employeeCategory}
						formFields={proposalSummary?.formFields}
					/>
				</Scrollbars>
			)}
		</div>
	);
};

export default DetailsModal;
