import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import { NotificationsActions as Actions } from 'typings/store/notificationsTypes';

// Actions to set filters whenever needed
export const getNotifications = (payload: unknown): IDispatchType => action(Actions.GET_NOTIFICATIONS, payload);

export const setNotification = (payload: unknown): IDispatchType => action(Actions.SET_NOTIFICATION, payload);

export const markNotificationAsRead = (payload: unknown): IDispatchType =>
	action(Actions.MARK_NOTIFICATION_AS_READ, payload);

export const deleteNotification = (payload: unknown): IDispatchType => action(Actions.DELETE_NOTIFICATION, payload);

export const setPostDeleteAllUserNotifications = (payload: unknown): IDispatchType =>
	action(Actions.POST_DELETE_ALL_USER_NOTIFICATIONS, payload);
