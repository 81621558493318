/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect, useMemo, useState } from 'react';

//* EXTERNAL LIBS --> MUI
import { Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { Table, PageTitle, LoadingOverlay, Dialog } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { BudgetAuditProps } from '.';

//* LOCAL ASSETS
import imageURL from '../../../../../assets/images/status diagrams/Diagram - Budget - XPECP.png';

//* COMPONENT INTERFACES
interface IBudgetAudit extends BudgetAuditProps {
	budgetId: number;
	name: string;
	// handleClose: Dispatch<SetStateAction<unknown | null>>;
}

//* COMPONENT
const BudgetAudit: FC<IBudgetAudit> = ({ budgetId, name, budget, getBudgetAudit, clearBudgetAction }) => {
	const classes = useStyles();
	const { loading, budgetAudit } = budget;

	useEffect(() => {
		if (budgetAudit === null) getBudgetAudit(budgetId);

		return () => {
			clearBudgetAction();
		};
	}, []);

	// TABLE SEARCH HELPER
	const [searchValue, setSearchValue] = useState('');

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	// TABLE COLUMNS
	const tableColumns: ITableColumn<unknown>[] = useMemo(
		() => [
			{
				label: 'Updated By',
				id: 'username',
				accentColumn: true,
				align: 'left',
				width: '35%'
			},
			{
				label: 'Status',
				id: 'status',
				width: '30%'
			},
			{
				label: 'Date',
				id: 'date',
				type: 'date',
				format: (row, date) => parseDateToShow(date, true),
				width: '35%'
			}
		],
		[]
	);

	// TABLE DATA PARSED
	const tableData = useMemo(
		() =>
			(budgetAudit as any)?.map((e: any) => ({
				...e,
				dateFormated: parseDateToShow(e.date)
			})),
		[budgetAudit]
	);

	const isLoading = !budgetAudit;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle
				title={
					<>
						<Typography gutterBottom color="primary" className={classes.name}>
							{name || 'N/A'}
						</Typography>
					</>
				}
				actions={[]}
			/>
			<Table
				tableData={tableData}
				columns={tableColumns}
				defaultSortColumn="date"
				defaultOrder="desc"
				handleSearch={{ searchValue, setSearchValue }}
				handleDiagramModal={{ diagramModal, setDiagramModal }}
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {setDiagramModal(false)},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default BudgetAudit;
