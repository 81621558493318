// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		avatarContainer: {
			width: '50px',
			height: '50px',
			margin: 'auto',
			position: 'relative',
			overflow: 'hidden',
			borderRadius: '50%'
		},
		avatarImage: {
			display: 'inline',
			margin: '0 auto',
			height: '50px',
			width: 'auto'
		}
	})
);

export { useStyles };
