//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Table, TableBody, TableRow, TableCell } from '@mui/material/';

//* EXTERNAL LIBS --> XPAND-UI

//* TYPINGS
import { ICollaborationProposal } from 'typings/store/admin/proposals';
import { ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';

//* COMPONENT INTERFACES
interface IProposalsListExpander {
	payload: ICollaborationProposal[];
	tableColumns: ITableColumn<ICollaborationProposal>[];
	ignoreColumn: string;
	actionsWidth: string;
}

//* COMPONENT
const ProposalsListExpander: FC<IProposalsListExpander> = ({ payload, tableColumns, ignoreColumn, actionsWidth }) => {
	const classes = useStyles();
	return (
		<Table className={classes.root}>
			<TableBody>
				{payload.map(proposal => (
					<TableRow key={`${proposal.id}_expandable`}>
						{tableColumns.map(column => {
							const tableProps = {
								key: column.id,
								className: classes.tableRegularColumn,
								...(column.width && { style: { width: column.width } })
							};

							const label = () => {
								if (column.id === ignoreColumn) return '';
								if (column.format) return column.format(proposal, proposal[column.id]);
								return proposal[column.id];
							};
							return <TableCell {...tableProps}>{label()}</TableCell>;
						})}
						{actionsWidth && <TableCell style={{ width: actionsWidth }} />}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

export default ProposalsListExpander;
