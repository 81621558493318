import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import {
	getBudgetTable,
	getNewBudget,
	submitBudgetPost,
	submitBudgetBiRefresh,
	submitDeleteBudget,
	submitExportBudget,
	sendPostImportBudget,
	clearBudgetError
} from 'store/administration/budget/thunks';
import { setBudgetPageFilter } from 'store/filters/actions';

import Budget from './Budget';

const mapStateToProps = ({ budget, filters }: AppState) => ({ budget, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getBudgetTable,
			getNewBudget,
			submitBudgetPost,
			submitBudgetBiRefresh,
			submitDeleteBudget,
			submitExportBudget,
			sendPostImportBudget,
			clearBudgetError,
			setBudgetPageFilter
		},
		dispatch
	);

export type BudgetProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
