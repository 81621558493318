// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		emailFieldLink: {
			padding: '0px',
			position: 'relative',
			top: '-8px'
		}
	})
);

export { useStyles };
