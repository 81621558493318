import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		marginBottom: '50px',
		boxSizing: 'border-box',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'stretch'
	},
	footer: {
		height: '90px',
		background: 'white',
		position: 'fixed',
		left: '0',
		bottom: '0',
		right: '0'
	},
	stateTitle: {
		fontSize: '16px',
		color: theme.grayedColor,
		textTransform: 'none'
	},
	careerPicker: {
		maxWidth: '200px'
	},
	careerContainer: {
		display: 'flex',
		flexGrow: 1,
		gap: '10px',
		flexWrap: 'wrap',
		alignItems: 'baseline',
		'& > div:nth-child(2)': { maxWidth: '200px' }
	},
	careerLabel: {
		fontSize: '12px',
		minWidth: '150px',
		marginRight: '30px',
		marginBottom: '0'
	},
	feedbackContainer: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'baseline'
	},
	feedbackLabel: {
		marginRight: '35px',
		alignItems: 'center',
		marginBottom: '0'
	},
	feedbackShowMore: {
		float: 'right',
		marginRight: '250px'
	},
	rows: {
		marginTop: '0px',
		marginBottom: '0px',
		width: '100%',
		textTransform: 'capitalize'
	},
	headerRows: {
		marginBottom: '20px',
		textTransform: 'capitalize'
	},
	title: {
		fontSize: '28px',
		fontFamily: 'Open Sans SemiBold'
	},
	titleButtonsContainer: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	titleButton: {
		minWidth: '150px',
		margin: '0 15px'
	},
	groupCardSkills: {
		textAlign: 'center',
		// marginTop: '75px',
		maxWidth: '1100px',
		marginBottom: '25px'
	},
	skillHeaderLabel: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px',
		color: theme.palette.secondary.main,
		textAlign: 'initial'
	},
	skillHeaderInputs: {
		fontFamily: 'Open Sans Bold',
		fontSize: '12px',
		color: theme.typographyColor,
		textAlign: 'initial',
		minWidth: '330px'
	},
	skillHeaderLabelLanguages: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px',
		color: theme.palette.secondary.main,
		textAlign: 'initial'
	},
	skillHeaderInputsLanguages: {
		fontFamily: 'Open Sans Bold',
		fontSize: '12px',
		color: theme.typographyColor,
		textAlign: 'initial'
	},
	skillHeaderLine: {
		borderBottom: '1px solid #E4E6E8',
		padding: '0 !important'
	},
	skillLabel: {
		fontSize: '18px',
		textAlign: 'initial',
		color: theme.grayedColor
	},
	skillCounterLabel: {
		fontFamily: 'Open Sans Bold',
		fontSize: '12px',
		color: theme.accentColor,
		marginBottom: '15px	'
	},
	//* TABLET
	[theme.breakpoints.down('md')]: {
		skillLabel: {
			fontSize: '16px',
			display: 'grid',
			gap: '6px'
		}
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		skillLabel: {
			fontSize: '14px',
			gap: '8px'
		}
	}
}));

export { useStyles };
