//* EXTERNAL LIBS
import React, { useEffect, FC, useMemo, useState } from 'react';

//* EXTERNAL LIBS --> MUI
import { Button, Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, CollapseTable, TabHeader, Tab, Dialog, Table } from 'xpand-ui/core';
import { Checkbox } from 'xpand-ui/forms';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { TimeOffProps } from '.';

import { useDispatch, useSelector } from 'react-redux';
import { getApprovalsTimeOff, postApprovalsTimeOff, getApprovalsTimeOffDetails } from 'store/approvals/thunks';
import { deepEqual, tabsTimeoff } from '../utils';
import { IUserToken } from 'typings/store/generalTypes';
import { ITableColumn } from 'typings/store/ComponentLib';
import { format } from 'date-fns';
import Filters from '../Components/Filters/Filters';
import { setRemoveUpdateTimeoff } from 'store/approvals/actions';
import { addNotification } from 'lib/utils/notifications';

//* COMPONENT INTERFACES
interface ITimeOff extends TimeOffProps {
	isAdmin: string;
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
}

//* COMPONENT
const TimeOff: FC<ITimeOff> = ({ loggedUser }) => {
	const classes = useStyles();
	const approvalsTimeoff: any = useSelector(state => state.approvals.approvalsTimeoff);
	const approvalsTimeOffDetails: any = useSelector(state => state.approvals.approvalsTimeOffDetails);
	const updateTimeoff: any = useSelector(state => state.approvals.updateTimeoff);
	const loading = useSelector(state => state.approvals.loading);
	const [approvalsTimeoffFinal, setApprovalsTimeoffFinal] = useState<any>();
	const [firstLoading, setFirstLoading] = useState<any>(true);
	const [auxApprovalsTimeoff, setAuxApprovalsTimeoff] = useState<any>();
	//Approval Details
	const [openDetailsModal, setOpenDetailsModal] = useState(false);
	const [entryIdToDetails, setEntryIdToDetails] = useState<any>();
	const [otherInfoDetails, setOtherInfoDetails] = useState<any>(null);

	const dispatch = useDispatch();
	const [tabSelected, setTabSelected] = useState(0);
	const [tabsTimeoffFinal, setTabsTimeoffFinal] = useState(tabsTimeoff);

	//Approvals - Employee Manager Time Off Approvals
	const [groupedDataEmployeeManagerGridAttachments, setGroupedDataEmployeeManagerGridAttachments] = useState<any>();
	//Approvals - Team Lead Time Off Approvals
	const [groupedDataTeamLeadGrid, setGroupedDataTeamLeadGrid] = useState<any>();
	//Approvals - Project Manager Time Off Approvals
	const [groupedDataProjectManagerGrid, setGroupedDataAccountProjectManagerGrid] = useState<any>();

	//Approvals - Specific Employee Time Off Approvals
	const [groupedDataSpecifcEmployeeGrid, setGroupedDataSpecifcEmployeeGrid] = useState<any>();

	const rowsHeaderApprovalEntries = useMemo(
		() => ['Time Off Type', 'Description', 'Day Off', 'Hours Off', 'Start Date', 'End Date'],
		[]
	);

	//! ------------------------ TIMEOFF APPROVALS --------------------------------------//
	//Gets all time offs approvals of the logged user
	useEffect(() => {
		dispatch(
			getApprovalsTimeOff({
				isShowTimeOffInDays: false,
				sessionEmployeeUsername: loggedUser.username,
				includeDateRange: false,
				timeOffEmployeeId: 0,
				accountTimeOffTypeId: '',
				startDate: '',
				endDate: ''
			})
		);
	}, []);

	//Main data is copied to a variable to be manipulated
	useEffect(() => {
		setFirstLoading(true);
		setAuxApprovalsTimeoff(approvalsTimeoff);
	}, [approvalsTimeoff]);

	//When the first loading is completed or a new loading after update is completed reset the final variable **Final variable has all the changed information (status, comments) **
	useEffect(() => {
		if (firstLoading && approvalsTimeoff) {
			setApprovalsTimeoffFinal(auxApprovalsTimeoff);
			setFirstLoading(false);
		}
		if (updateTimeoff && !firstLoading && auxApprovalsTimeoff) {
			setApprovalsTimeoffFinal(auxApprovalsTimeoff);
			setFirstLoading(false);
			dispatch(setRemoveUpdateTimeoff());
		}
	}, [auxApprovalsTimeoff]);

	//Used to update the final variable with the changes that the user did (status, commentss)
	useEffect(() => {
		//Check if the aux variable is equal to the final variable if not updates with the changes that the user did
		if (!deepEqual(auxApprovalsTimeoff, approvalsTimeoffFinal) && auxApprovalsTimeoff) {
			setAuxApprovalsTimeoff(
				Object.fromEntries(
					// Use Object.fromEntries to transform the entries back to an object
					Object.entries(auxApprovalsTimeoff).map(([key, array]) => {
						// Check if the value is an array
						if (Array.isArray(array)) {
							// Return the updated array after filtering and mapping
							return [
								key, // Keep the original key
								array.map(item => {
									const updatedEntry = approvalsTimeoffFinal[key].find(
										entry =>
											entry.accountEmployeeTimeOffRequestId ===
											item.accountEmployeeTimeOffRequestId
									);

									// If an updated entry is found and there are changes, update the original entry
									if (updatedEntry) {
										// Compare and update only the fields that have changed
										const hasChanges = Object.keys(updatedEntry).some(
											key2 => updatedEntry[key2] !== item[key2]
										);

										if (hasChanges) {
											// Merge the updated entry into the original entry (only changed fields)
											return { ...item, ...updatedEntry };
										}
									}

									// If no updates are found, return the original entry unchanged
									return item;
								})
							];
						}
						// If the value is not an array, return it unchanged (or handle it as needed)
						return [key, array];
					})
				)
			);
		}
	}, [approvalsTimeoffFinal]);

	//Fill every table with the respective information
	useEffect(() => {
		//Approvals - Project Manager Time Off Approvals
		if (approvalsTimeoffFinal?.projectManagerGrid) {
			setGroupedDataAccountProjectManagerGrid(
				Object.values(
					approvalsTimeoffFinal?.projectManagerGrid?.reduce((acc, item) => {
						if (!acc[item.fullName]) {
							acc[item.fullName] = [];
						}

						acc[item.fullName].push({
							key: 'projectManagerGrid',
							employeeName: item.fullName,
							id: item.accountEmployeeTimeOffRequestId,
							status: item.status,
							comment: item.comment || null,
							accountTimeOffType: item.accountTimeOffType,
							description: item.description,
							dayOff: item.dayOff,
							hoursOff: item.hoursOff,
							startDate: format(new Date(item.startDate), 'dd/MM/yyyy'),
							endDate: format(new Date(item.endDate), 'dd/MM/yyyy')
						});
						return acc;
					}, {})
				)
			);
			//Approvals - My Team Lead Timeoff approvals
			if (approvalsTimeoffFinal?.teamLeadGrid) {
				setGroupedDataTeamLeadGrid(
					Object.values(
						approvalsTimeoffFinal?.teamLeadGrid?.reduce((acc, item) => {
							if (!acc[item.fullName]) {
								acc[item.fullName] = [];
							}

							const period =
								format(new Date(item.timeEntryStartDate), 'dd/MM/yyyy') +
								' - ' +
								format(new Date(item.timeEntryEndDate), 'dd/MM/yyyy');
							acc[item.fullName].push({
								key: 'teamLeadGrid',
								employeeName: item.fullName,
								id: item.accountEmployeeTimeOffRequestId,
								status: item.status,
								comment: item.comment || null,
								accountTimeOffType: item.accountTimeOffType,
								description: item.description,
								dayOff: item.dayOff,
								hoursOff: item.hoursOff,
								startDate: format(new Date(item.startDate), 'dd/MM/yyyy'),
								endDate: format(new Date(item.endDate), 'dd/MM/yyyy')
							});
							return acc;
						}, {})
					)
				);
			}
			//Approvals - Employee Manager time off approvals
			if (approvalsTimeoffFinal?.employeeManagerGridAttachments) {
				setGroupedDataEmployeeManagerGridAttachments(
					Object.values(
						approvalsTimeoffFinal?.employeeManagerGridAttachments?.reduce((acc, item) => {
							if (!acc[item.fullName]) {
								acc[item.fullName] = [];
							}
							acc[item.fullName].push({
								key: 'employeeManagerGridAttachments',
								employeeName: item.fullName,
								id: item.accountEmployeeTimeOffRequestId,
								status: item.status,
								comment: item.comment || null,
								accountTimeOffType: item.accountTimeOffType,
								description: item.description,
								dayOff: item.dayOff,
								hoursOff: item.hoursOff,
								startDate: format(new Date(item.startDate), 'dd/MM/yyyy'),
								endDate: format(new Date(item.endDate), 'dd/MM/yyyy')
							});
							return acc;
						}, {})
					)
				);
			}
			//Approvals - Employee Manager time off approvals
			if (approvalsTimeoffFinal?.specificEmployeeGrid) {
				setGroupedDataSpecifcEmployeeGrid(
					Object.values(
						approvalsTimeoffFinal?.specificEmployeeGrid?.reduce((acc, item) => {
							if (!acc[item.fullName]) {
								acc[item.fullName] = [];
							}
							acc[item.fullName].push({
								key: 'specificEmployeeGrid',
								employeeName: item.fullName,
								id: item.accountEmployeeTimeOffRequestId,
								status: item.status,
								comment: item.comment || null,
								accountTimeOffType: item.accountTimeOffType,
								description: item.description,
								dayOff: item.dayOff,
								hoursOff: item.hoursOff,
								startDate: format(new Date(item.startDate), 'dd/MM/yyyy'),
								endDate: format(new Date(item.endDate), 'dd/MM/yyyy')
							});
							return acc;
						}, {})
					)
				);
			}
		}
	}, [approvalsTimeoffFinal]);

	//Removes uncessary tabs that the user don't have nothing to approve
	useEffect(() => {
		if (approvalsTimeoffFinal) {
			let auxTabsTimeoff = tabsTimeoff;
			//Approvals -My Projects Time Entry Approvals
			if (approvalsTimeoffFinal?.employeeManagerGridAttachments.length == 0) {
				auxTabsTimeoff = auxTabsTimeoff.filter(item => item.id !== 'employeeManagerTimeOffApprovals');
			}
			//Approvals - My Team Time Entry Approvals
			if (approvalsTimeoffFinal?.projectManagerGrid.length == 0) {
				auxTabsTimeoff = auxTabsTimeoff.filter(item => item.id !== 'projectManagerTimeOffApprovals');
			}
			//Approvals - Project Manager Time Off Approvals
			if (approvalsTimeoffFinal?.specifcEmployeeGrid.length == 0) {
				auxTabsTimeoff = auxTabsTimeoff.filter(item => item.id !== 'specificEmployeeTimeOffApprovals');
			}
			//Approvals - Project Manager Time Off Approvals
			if (approvalsTimeoffFinal?.teamLeadGrid.length == 0) {
				auxTabsTimeoff = auxTabsTimeoff.filter(item => item.id !== 'teamLeadTimeOffApprovals');
			}

			setTabsTimeoffFinal(auxTabsTimeoff);
		}
	}, [approvalsTimeoffFinal]);

	//!------------------------------------------UPDATE APPROVALS-------------------------------------------//
	//Whenever an update is finish refresh tables with new data
	useEffect(() => {
		if (updateTimeoff) {
			dispatch(
				getApprovalsTimeOff({
					isShowTimeOffInDays: false,
					sessionEmployeeUsername: loggedUser.username,
					includeDateRange: false,
					timeOffEmployeeId: 0,
					accountTimeOffTypeId: '',
					startDate: '',
					endDate: ''
				})
			);
		}
	}, [updateTimeoff]);

	//Method responsible to create the payload and send it to API
	const updateTimeOffApprovals = () => {
		let formToUpdate: any = {};
		let specificEmployeeGrid: any = [];
		let employeeManagerGrid: any = [];
		let projectManagerGrid: any = [];
		let teamLeadGrid: any = [];

		//Checks if there is an entry with rejected status and with no comment, if so sends notification
		for (let entries in auxApprovalsTimeoff) {
			for (let i = 0; i < auxApprovalsTimeoff[entries].length; i++) {
				const e = auxApprovalsTimeoff[entries][i];
				if (e.status === 'rejected' && (e.comment === undefined || e.comment === '')) {
					addNotification(
						'danger',
						'One or more entries are rejected without comment. Please insert a comment in all rejected entries.'
					);
					// Stop further execution by returning early
					return;
				}
			}
		}

		// Creates the payload to send to API deppendig on the status
		for (let entries in auxApprovalsTimeoff) {
			auxApprovalsTimeoff[entries].map(e => {
				if (e.status === 'approved' || e.status === 'rejected') {
					if (entries === 'specifcEmployeeGrid') {
						specificEmployeeGrid = [
							...specificEmployeeGrid,
							{
								commentsTextBox: e.comment ? e.comment : '',
								isApproved: e.status === 'approved' ? true : false,
								accountEmployeeTimeOffRequest: e.accountEmployeeTimeOffRequestId,
								accountApprovalTypeId: e.accountApprovalTypeId,
								accountApprovalPathId: e.accountApprovalPathId,
								accountEmployeeId: e.accountEmployeeId,
								approvalPathSequence: e.approvalPathSequence,
								maxApprovalPathSequence: e.maxApprovalPathSequence,
								timeOffAccountEmployeeId: e.timeOffAccountEmployeeId,
								accountTimeOffTypeId: e.accountTimeOffTypeId,
								hoursOff: e.hoursOff,
								startDate: e.startDate,
								createdOn: e.createdOn
							}
						];
					}
					if (entries === 'teamLeadGrid') {
						teamLeadGrid = [
							...teamLeadGrid,
							{
								commentsTextBox: e.comment ? e.comment : '',
								isApproved: e.status === 'approved' ? true : false,
								accountEmployeeTimeOffRequest: e.accountEmployeeTimeOffRequestId,
								accountApprovalTypeId: e.accountApprovalTypeId,
								accountApprovalPathId: e.accountApprovalPathId,
								accountEmployeeId: e.accountEmployeeId,
								approvalPathSequence: e.approvalPathSequence,
								maxApprovalPathSequence: e.maxApprovalPathSequence,
								timeOffAccountEmployeeId: e.timeOffAccountEmployeeId,
								accountTimeOffTypeId: e.accountTimeOffTypeId,
								hoursOff: e.hoursOff,
								startDate: e.startDate,
								createdOn: e.createdOn
							}
						];
					}
					if (entries === 'employeeManagerGridAttachments') {
						employeeManagerGrid = [
							...employeeManagerGrid,
							{
								commentsTextBox: e.comment ? e.comment : '',
								isApproved: e.status === 'approved' ? true : false,
								accountEmployeeTimeOffRequest: e.accountEmployeeTimeOffRequestId,
								accountApprovalTypeId: e.accountApprovalTypeId,
								accountApprovalPathId: e.accountApprovalPathId,
								accountEmployeeId: e.accountEmployeeId,
								approvalPathSequence: e.approvalPathSequence,
								maxApprovalPathSequence: e.maxApprovalPathSequence,
								timeOffAccountEmployeeId: e.timeOffAccountEmployeeId,
								accountTimeOffTypeId: e.accountTimeOffTypeId,
								hoursOff: e.hoursOff,
								startDate: e.startDate,
								createdOn: e.createdOn
							}
						];
					}
					if (entries === 'projectManagerGrid') {
						projectManagerGrid = [
							...projectManagerGrid,
							{
								commentsTextBox: e.comment ? e.comment : '',
								isApproved: e.status === 'approved' ? true : false,
								accountEmployeeTimeOffRequest: e.accountEmployeeTimeOffRequestId,
								accountApprovalTypeId: e.accountApprovalTypeId,
								accountApprovalPathId: e.accountApprovalPathId,
								accountEmployeeId: e.accountEmployeeId,
								approvalPathSequence: e.approvalPathSequence,
								maxApprovalPathSequence: e.maxApprovalPathSequence,
								timeOffAccountEmployeeId: e.timeOffAccountEmployeeId,
								accountTimeOffTypeId: e.accountTimeOffTypeId,
								hoursOff: e.hoursOff,
								startDate: e.startDate,
								createdOn: e.createdOn
							}
						];
					}
				}
			});
		}

		if (
			specificEmployeeGrid.length > 0 ||
			employeeManagerGrid.length > 0 ||
			projectManagerGrid.length > 0 ||
			teamLeadGrid.length > 0
		) {
			formToUpdate = {
				employeeNameWithCode: loggedUser.username,
				specificEmployeeGrid: specificEmployeeGrid,
				employeeManagerGrid: employeeManagerGrid,
				projectManagerGrid: projectManagerGrid,
				teamLeadGridRow: teamLeadGrid
			};
			dispatch(postApprovalsTimeOff(formToUpdate));
		}
	};

	//Disable Update button if there is no status selected in the entries
	const disableUpdateButton = () => {
		for (let entries in auxApprovalsTimeoff) {
			for (let e of auxApprovalsTimeoff[entries]) {
				if ((e.status === 'approved' || e.status === 'rejected') && !e.isTimeOff) {
					return false;
				} else if ((e.status === 'approved' || e.status === 'rejected') && e.isTimeOff) {
					return false;
				}
			}
		}
		return true;
	};

	//Every entry in the table will be approved, giving the status approved to the entries of the main data
	const approveAllProjectTimeEntryApprovals = group => {
		const updatedData = Object.keys(approvalsTimeoffFinal).reduce((acc, key) => {
			const allEntries = approvalsTimeoffFinal[key].filter(entry => entry.fullName === group[0].employeeName);
			const allApproved = allEntries.every(e => e.status == 'approved');
			// Map over the entries and update the status accordingly
			acc[key] = approvalsTimeoffFinal[key].map(entry => {
				const matchingItem = group.find(
					item => item.id === entry.accountEmployeeTimeOffRequestId && item.key === key
				);

				if (matchingItem) {
					// If all are approved, set the status to null
					if (allApproved) {
						return {
							...entry,
							status: null
						};
					}
					// If not all are approved, approve all (set status to 'approved')
					else {
						return {
							...entry,
							status: 'approved'
						};
					}
				}
				// If no match found, return the entry as is
				return entry;
			});

			return acc;
		}, {});

		// Update the state with the modified data
		setApprovalsTimeoffFinal(updatedData);
	};

	//Every entry in the table will be rejected, giving the status rejected to the entries of the main data
	const rejectAllProjectTimeEntryApprovals = group => {
		const updatedData = Object.keys(approvalsTimeoffFinal).reduce((acc, key) => {
			const allEntries = approvalsTimeoffFinal[key].filter(entry => entry.fullName === group[0].employeeName);

			const allRejected = allEntries.every(e => e.status == 'rejected');
			// Map over the entries and update the status accordingly
			acc[key] = approvalsTimeoffFinal[key].map(entry => {
				const matchingItem = group.find(
					item => item.id === entry.accountEmployeeTimeOffRequestId && item.key === key
				);

				if (matchingItem) {
					// If all are rejected, set the status to null
					if (allRejected) {
						return {
							...entry,
							status: null
						};
					}
					// If not all are rejected, approve all (set status to 'rejected')
					else {
						return {
							...entry,
							status: 'rejected'
						};
					}
				}
				// If no match found, return the entry as is
				return entry;
			});

			return acc;
		}, {});

		// Update the state with the modified data
		setApprovalsTimeoffFinal(updatedData);
	};

	//Approve a single entry in the table giving a status approved to the entry
	const approveProjectTimeEntryApprovals = item => {
		// Iterate through all arrays
		const updatedData: any = Object.keys(approvalsTimeoffFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsTimeoffFinal[key].map(entry =>
				entry.accountEmployeeTimeOffRequestId === item.id && item.key === key
					? { ...entry, status: entry.status && entry.status === 'approved' ? null : 'approved' }
					: entry
			);
			return acc;
		}, {});

		// Update state with new data
		setApprovalsTimeoffFinal(updatedData);
	};

	//Reject a single entry in the table giving a status rejected to the entry
	const rejectProjectTimeEntryApprovals = item => {
		// Iterate through all arrays
		const updatedData = Object.keys(approvalsTimeoffFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsTimeoffFinal[key].map(entry =>
				entry.accountEmployeeTimeOffRequestId === item.id && item.key === key
					? { ...entry, status: entry.status && entry.status === 'rejected' ? null : 'rejected' }
					: entry
			);
			return acc;
		}, {});
		// Update state with new data
		setApprovalsTimeoffFinal(updatedData);
	};

	//Gives comment to the entry
	const commentEntryApproval = (item, comment) => {
		// Iterate through all arrays
		const updatedData = Object.keys(approvalsTimeoffFinal).reduce((acc, key) => {
			// Map over the array to find and update the entry
			acc[key] = approvalsTimeoffFinal[key].map(entry =>
				entry.accountEmployeeTimeOffRequestId === item.id && item.key === key
					? { ...entry, comment: comment }
					: entry
			);
			return acc;
		}, {});
		// Update state with new data
		setApprovalsTimeoffFinal(updatedData);
	};

	//! ------------------------ DETAILS APPROVALS --------------------------------------//
	//Gets all details of the respective approval of a user
	useEffect(() => {
		if (openDetailsModal) {
			Object.keys(approvalsTimeoffFinal!).reduce((acc, key) => {
				approvalsTimeoffFinal![key].map(entry => {
					// Find the item in the group that matches the entry
					const matchingItem = entry.accountEmployeeTimeOffRequestId === entryIdToDetails.id;
					// If there's a match, update the entry; otherwise, return it as is
					if (matchingItem) {
						const year = new Date(entry.startDate).getFullYear();
						setOtherInfoDetails({
							employeeName: entry.fullName,
							startDate: entry.startDate,
							endDate: entry.endDate
						});
						dispatch(
							getApprovalsTimeOffDetails({
								accountEmployeeId: entry!.timeOffAccountEmployeeId,
								year: year
							})
						);
					}
				});
			}, {});
		}
	}, [openDetailsModal]);

	//Prepares data for table time entry week view
	const tableTimeOffDetailsPayload = useMemo(
		() =>
			approvalsTimeOffDetails &&
			approvalsTimeOffDetails?.accountEmployeeTimeOffRequest?.map(e => {
				return {
					timeOffType: e.accountTimeOffType,
					noDays: e.dayOff,
					startDate: format(new Date(e.startDate), 'dd/MM/yyyy'),
					startDate2: new Date(e.startDate),
					endDate: format(new Date(e.endDate), 'dd/MM/yyyy'),
					endDate2: new Date(e.endDate),
					status: e.approvalStatus
				};
			}),
		[approvalsTimeOffDetails]
	);

	// TABLE COMPONENT - columns
	const tableTimeOffYearColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Time Off Type',
				id: 'timeOffType'
			},
			{ label: 'No Days', id: 'noDays' },
			{ label: 'Start Date', id: 'startDate2', format: row => row.startDate },
			{ label: 'End Date', id: 'endDate2', format: row => row.endDate },
			{ label: 'Status', id: 'status' }
		],
		[approvalsTimeOffDetails]
	);

	const isUpdateButtonDisabled = disableUpdateButton();
	const isLoading = approvalsTimeoffFinal == null || loading;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{' '}
			<Grid
				container
				direction={'row'}
				sx={{
					justifyContent: 'space-evenly',
					alignItems: 'center'
				}}
				style={{ marginBottom: '30px' }}>
				<Grid item xs={10}>
					<Filters
						originalData={auxApprovalsTimeoff}
						data={approvalsTimeoffFinal}
						setData={setApprovalsTimeoffFinal}
						setTabSelected={setTabSelected}
						isTimeOff={true}
						typeTimeOff={approvalsTimeoff?.accountTimeOffTypes}></Filters>
				</Grid>
			</Grid>
			<TabHeader
				selected={tabSelected}
				className={classes.tabheader}
				handleSelected={(event: React.SyntheticEvent<Element, Event>, newValue: number) =>
					setTabSelected(newValue)
				}
				variant="scrollable"
				scrollButtons="auto">
				{tabsTimeoffFinal.map((tab, index) => (
					<Tab key={`Resume_${tab.id}_tab_button`} label={tab.label} index={index} />
				))}
			</TabHeader>
			<Grid
				container
				direction="column"
				style={{
					display: 'flex',
					marginBottom: '100px'
				}}>
				{/* My Projects Time Entry Approvals */}
				{approvalsTimeoffFinal?.employeeManagerGridAttachments?.length > 0 &&
					tabsTimeoffFinal[tabSelected]?.id === 'employeeManagerTimeOffApprovals' && (
						<>
							{groupedDataEmployeeManagerGridAttachments && (
								<Grid item xs={12}>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntries}
										data={groupedDataEmployeeManagerGridAttachments}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* My Team Time Entry Approvals */}
				{approvalsTimeoffFinal?.teamLeadGrid?.length > 0 &&
					tabsTimeoffFinal[tabSelected].id === 'teamLeadTimeOffApprovals' && (
						<>
							{groupedDataTeamLeadGrid && (
								<Grid item>
									<CollapseTable
										rowsHeader={rowsHeaderApprovalEntries}
										data={groupedDataTeamLeadGrid}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{/* Project Manager Time Off Approvals */}
				{approvalsTimeoffFinal?.projectManagerGrid?.length > 0 &&
					tabsTimeoffFinal[tabSelected].id === 'projectManagerTimeOffApprovals' && (
						<>
							{groupedDataProjectManagerGrid && (
								<Grid item>
									<CollapseTable
										isTimeOff={true}
										rowsHeader={rowsHeaderApprovalEntries}
										data={groupedDataProjectManagerGrid}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
				{approvalsTimeoffFinal?.specificEmployeeGrid?.length > 0 &&
					tabsTimeoffFinal[tabSelected].id === 'specificEmployeeTimeOffApprovals' && (
						<>
							{groupedDataSpecifcEmployeeGrid && (
								<Grid item>
									<CollapseTable
										isTimeOff={true}
										rowsHeader={rowsHeaderApprovalEntries}
										data={groupedDataSpecifcEmployeeGrid}
										mainActions={{
											approveAll: approveAllProjectTimeEntryApprovals,
											rejectAll: rejectAllProjectTimeEntryApprovals
										}}
										secondaryActions={{
											approve: approveProjectTimeEntryApprovals,
											reject: rejectProjectTimeEntryApprovals,
											comment: commentEntryApproval,
											details: setOpenDetailsModal,
											entryToDetails: setEntryIdToDetails
										}}></CollapseTable>
								</Grid>
							)}
						</>
					)}
			</Grid>
			<Grid
				container
				direction="row"
				id="footerButtonsID"
				style={{
					position: 'fixed',
					bottom: 0,
					left: 0,
					right: 0,
					backgroundColor: 'white',
					paddingLeft: '30px',
					paddingRight: '30px',
					paddingBottom: '20px',
					paddingTop: '20px',
					boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
					zIndex: 1000
				}}
				sx={{
					justifyContent: 'flex-end',
					alignItems: 'center'
				}}>
				<Grid item id={'footerLegendID'}>
					<Button
						disabled={isUpdateButtonDisabled}
						color="primary"
						variant="contained"
						onClick={() => {
							updateTimeOffApprovals();
						}}>
						Update Time Off Approvals
					</Button>
				</Grid>
			</Grid>
			<Dialog
				title={
					<div>
						Time Off Details of{' '}
						<span style={{ marginRight: '150px' }}>{otherInfoDetails?.employeeName}</span>
					</div>
				}
				maxWidth="xl"
				modal={{
					open: Boolean(openDetailsModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setOpenDetailsModal(false);
						}
					},
					content: (
						<>
							{loading && <LoadingOverlay />}
							<Grid container>
								<Grid item xs={12}>
									{tableTimeOffDetailsPayload?.length > 0 && (
										<>
											<Table
												tableData={tableTimeOffDetailsPayload}
												columns={tableTimeOffYearColumns}></Table>
										</>
									)}
								</Grid>
							</Grid>
						</>
					)
				}}
				actions={[
					{
						id: 'cancel',
						label: 'Close',
						color: 'primary',
						variant: 'text',
						onClick: () => {
							setOpenDetailsModal(false);
							setOtherInfoDetails(false);
						}
					}
				]}
			/>
		</>
	);
};
export default withLayout(TimeOff);
