//* EXTERNAL LIBS
import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Control, useForm, UseFormWatch, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography, Button, Input as CoreInput, InputLabel } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter, Dialog, PageTitle, TabPanel, FormSteps } from 'xpand-ui/core';
import { InfoField } from 'xpand-ui/forms';
//* TYPINGS
import { IAdmissionSheetPayload, IBankInfo, IUploadFile } from 'typings/store/admin/admissions';
import { IChoosableBaseInfo, Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';
import AdmissionSubmittedIcon from 'assets/images/admissionSheet/AdmissionSubmitted.svg';

//* LOCAL COMPONENT IMPORTS
import { DOCTYPES } from '../AdmissionManagement/AdmissionDetails/utils';

import { defaultValues, tabs, createYupSchema, createYupSchemaHR, defaultValuesHR } from './yupSchema';
import { AdmissionSheetProps } from '.';
import { useStyles } from './styles';
import { getFormFieldsConfig } from 'store/administration/formFields/thunks';

import Qualifications from './tabs/Qualifications';
import GeneralInfo from './tabs/GeneralInfo';
import ContactInfo from './tabs/ContactInfo';
import FamilyInfo from './tabs/FamilyInfo';
import Banking from './tabs/Banking';
import IDInfo from './tabs/IDInfo';
import GDPR from './tabs/GDPR';

import {
	INSTANCE,
	XPAND_EMPLOYEE_ID,
	XPAND_EMPLOYEE_ID_DE,
	XPAND_EMPLOYEE_ID_HR,
	XPAND_EMPLOYEE_ID_SE,
	XPAND_EMPLOYEE_ID_UK
} from 'lib/utils/constants';

//* COMPONENT TABS INTERFACES
export interface IAdmissionSheetTabs {
	// eslint-disable-next-line
	[x: string]: any;
	control: Control<IAdmissionSheetPayload>;
	setValue: UseFormSetValue<IAdmissionSheetPayload>;
	errors: FieldErrors<IAdmissionSheetPayload>;
	watch: UseFormWatch<IAdmissionSheetPayload>;
	// eslint-disable-next-line
	AdmissionSheetDataOptions: {
		banks: IChoosableBaseInfo[];
		courseQualifications: IChoosableBaseInfo[];
		educationCodes: IChoosableBaseInfo[];
		educationStatus: IChoosableBaseInfo[];
		idDocumentTypes: IChoosableBaseInfo[];
		maritalStatus: IChoosableBaseInfo[];
		nationalities: IChoosableBaseInfo[];
	};
	FileUploadOptions: {
		IDDOC: string;
		QUALIFCERTIFICATE: string;
		IBAN: string;
	};
}

//* COMPONENT INTERFACE
interface IAdmissionSheet extends AdmissionSheetProps {
	match: Match<{
		token: string;
		proposalId: string;
	}>;
}

const FileUploadOptions = {
	IDDOC: 'idFile',
	QUALIFCERTIFICATE: 'qcFile',
	IBAN: 'nibFile'
};

const MaxFileSize = 4200000;

// form data for payload
let selectedFilesFormData = new FormData();
// holder to the chosen file before its confrimed and saved in the form data
let temporaryFileHolder: IUploadFile = { name: null, file: null };
// flags to show missing files errors
let showIdDocError = false;
let showIBANDocError = false;

const AdmissionSheet: FC<IAdmissionSheet> = ({
	admissions,
	match,
	company,
	getAdmissionSheetData,
	sendAdmissionSheet,
	setCompanyInformation
}) => {
	const classes = useStyles();
	const { admissionSheetData, invalidDocs, blockAdmissionSheet, loading } = admissions;
	const { params } = match;
	const pageToken = params?.token;
	const scrollToTopRef = useRef<HTMLDivElement | any>(null);
	const scrollbarRef = React.createRef();
	const [submitClick, setSubmitClick] = useState(false);
	const [tabSelected, setTabSelected] = useState(0);
	const companyInfo = useSelector(state => state.company);
	const formFields = useSelector(state => state.formFields.formFieldsConfig);
	const dispatch = useDispatch();
	const [isEmployee, setIsEmployee] = useState(false);
	const [modifiedTabs, setModifiedTabs] = useState();

	useEffect(() => {
		if (!INSTANCE.toLowerCase().includes('xpand')) {
			setCompanyInformation();
		}
		if (admissionSheetData) {
			setIsEmployee(
				admissionSheetData[pageToken]?.process?.employeeCategory == XPAND_EMPLOYEE_ID ||
					admissionSheetData[pageToken]?.process?.employeeCategory == XPAND_EMPLOYEE_ID_DE ||
					admissionSheetData[pageToken]?.process?.employeeCategory == XPAND_EMPLOYEE_ID_HR ||
					admissionSheetData[pageToken]?.process?.employeeCategory == XPAND_EMPLOYEE_ID_SE ||
					admissionSheetData[pageToken]?.process?.employeeCategory == XPAND_EMPLOYEE_ID_UK
			);
		}
	}, [admissionSheetData]);

	useEffect(() => {
		if (admissionSheetData) {
			dispatch(
				getFormFieldsConfig(
					isEmployee ? 'Employee' : 'Contractor',
					admissionSheetData[pageToken]?.process?.company,
					'Admission Sheet'
				)
			);
		}
	}, [isEmployee, companyInfo, admissionSheetData]);

	useEffect(() => {
		if (!isEmployee && formFields?.formFieldsConfig?.country !== 'HR') {
			setModifiedTabs([
				{
					id: 0,
					label: 'General info'
				},
				{
					id: 1,
					label: 'Contact info'
				},
				{
					id: 2,
					label: 'ID info'
				},
				{
					id: 3,
					label: 'Qualification info'
				},
				{
					id: 4,
					label: 'Banking info'
				},
				{
					id: 5,
					label: 'GDPR Consents'
				}
			]);
		} else {
			setModifiedTabs(tabs);
		}
	}, [isEmployee, formFields]);

	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	}, [tabSelected]);

	useEffect(() => {
		scrollToTopRef?.current?.scrollIntoView(0, 0);
	});

	const [formWarnings, setFormWarnings] = useState<Array<number>>([]);
	const [addModalOpen, setAddModalOpen] = useState<{
		docType: string | undefined;
		modalState: boolean | null;
	}>({ docType: undefined, modalState: null });
	const [isIDMissing, setisIDMissing] = useState<boolean | null>(null);
	const [isIBANMissing, setisIBANMissing] = useState<boolean | null>(null);
	const [openConfirmationSubmit, setOpenConfirmationSubmit] = useState<boolean | null>(null);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isFileSizeOk, setIsFileSizeOk] = useState<boolean | null>(null);
	const [isFileTypeOk, setIsFileTypeOk] = useState<boolean | null>(null);
	const [showUploadIdFile, setShowUploadIdFile] = useState(true);
	const [showUploadQualificationsCertificateFile, setShowUploadQualificationsCertificateFile] = useState(true);
	const [showUploadIbanProofFile, setShowUploadIbanProofFile] = useState(true);
	const [socialSecurityNumberRequired, setSocialSecurityNumberRequired] = useState(false);
	const [isCompanyLoaded, setCompanyLoaded] = useState(false);
	const {
		handleSubmit,
		control,
		watch,
		reset,
		formState: { errors },
		setError,
		getValues,
		setValue
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(createYupSchema(formFields?.formFieldsConfig, isEmployee)),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		document.title = 'Control Panel - Admission Sheet';
	}, []);

	useEffect(() => {
		if (admissionSheetData) {
			if (admissionSheetData[pageToken]?.process.company == 'BWK' && !isCompanyLoaded) {
				setCompanyInformation();
				setCompanyLoaded(true);
			}
		}
	});

	useEffect(() => {
		if (pageToken && !admissionSheetData) {
			getAdmissionSheetData(pageToken);
		} else if (
			admissionSheetData !== null &&
			admissionSheetData[pageToken].sheet &&
			admissionSheetData[pageToken].sheet !== null
		) {
			// There is already submitted data on admission sheet. Fill values
			reset({
				...defaultValues,
				// General Info
				fullName: admissionSheetData[pageToken].sheet.fullName,
				placeOfBirth: admissionSheetData[pageToken].sheet.placeOfBirth,
				gender: admissionSheetData[pageToken].sheet.gender ? 'M' : 'F',
				dateOfBirth: admissionSheetData[pageToken].sheet.dateOfBirth,
				licensePlate: admissionSheetData[pageToken].sheet.licensePlate,
				// Contact Info
				address: admissionSheetData[pageToken].sheet.address,
				mobilePhone: admissionSheetData[pageToken].sheet.mobilePhone,
				city: admissionSheetData[pageToken].sheet.city,
				emergencyContact: admissionSheetData[pageToken].sheet.emergencyContact,
				zipCode: admissionSheetData[pageToken].sheet.zipCode,
				emergencyContactName: admissionSheetData[pageToken].sheet.emergencyContactName,
				email: admissionSheetData[pageToken].sheet.email,
				nationality: admissionSheetData[pageToken].sheet.nationality,
				// ID Info
				docType: admissionSheetData[pageToken].sheet.idDocumentType,
				fiscalNumber: admissionSheetData[pageToken].sheet.fiscalNumber,
				allFiscalNumber: admissionSheetData[pageToken].allNifs,
				citizenCardNumber: admissionSheetData[pageToken].sheet.citizenCardNumber,
				allCitizenCardNumber: admissionSheetData[pageToken].allCC,
				socialSecurityNumber: admissionSheetData[pageToken].sheet.socialSecurityNumber,
				allSocialSecurityNumber: admissionSheetData[pageToken].allSSN,
				pensionFundNumber: admissionSheetData[pageToken].sheet.pensionFundNumber,
				allPensionFundNumber: admissionSheetData[pageToken].allPFN,
				citizenCardValidityDate: admissionSheetData[pageToken].sheet.citizenCardValidityDate,
				hasIDDoc: admissionSheetData[pageToken].sheet.hasIDDoc,
				hasHadWithoutTermContract: admissionSheetData[pageToken].sheet.hasHadWithoutTermContract,
				showContractCheckbox:
					admissionSheetData[pageToken].sheet.dateOfBirth >=
					admissionSheetData[pageToken].contractCheckboxMaximumDate,

				// Family aggregate info
				maritalStatus: admissionSheetData[pageToken].sheet.maritalStatus,
				holdersNumber: admissionSheetData[pageToken].sheet.holdersNumber.toString(),
				dependentsNumber: admissionSheetData[pageToken].sheet.dependentsNumber.toString() || '0',
				taxZone: admissionSheetData[pageToken].sheet.taxZone,
				// Qualification info
				courseQualificationId: admissionSheetData[pageToken].sheet.courseQualificationId,
				courseInstitution: admissionSheetData[pageToken].sheet.courseInstitution,
				educationCode: admissionSheetData[pageToken].sheet.educationCode,
				courseStartDate: admissionSheetData[pageToken].sheet.courseStartDate,
				educationStatus: admissionSheetData[pageToken].sheet.educationStatus,
				courseEndDate: admissionSheetData[pageToken].sheet.courseEndDate,
				coursePostBologna: admissionSheetData[pageToken].sheet.coursePostBologna,
				courseName: admissionSheetData[pageToken].sheet.courseName,
				careerStartDate: admissionSheetData[pageToken].sheet.careerStartDate,
				professionalExperience: admissionSheetData[pageToken].sheet.professionalExperience,
				// Banking Info
				bankId: admissionSheetData[pageToken].sheet.bankId,
				bankNib: admissionSheetData[pageToken].sheet.bankNib,
				hasBankDoc: admissionSheetData[pageToken].sheet.hasBankDoc
			});

			// Show upload buttons only for file types not already submitted.
			if (
				admissionSheetData[pageToken].process !== null &&
				admissionSheetData[pageToken].process.files !== null
			) {
				const citizenCard = admissionSheetData[pageToken].process.files?.find(
					e =>
						e.fileType === DOCTYPES.CC ||
						e.fileType === DOCTYPES.FID ||
						e.fileType === DOCTYPES.PP ||
						e.fileType === DOCTYPES.RP
				);
				if (citizenCard || !formFields?.formFieldsConfig?.hasIdDoc) {
					setValue('hasIDDoc', 'TRUE');
					setShowUploadIdFile(false);
				}

				const qualificationsCertificate = admissionSheetData[pageToken].process.files?.find(
					e => e.fileType === 'QUAL_CERT'
				);
				if (qualificationsCertificate || !formFields?.formFieldsConfig?.hasQcDoc) {
					setShowUploadQualificationsCertificateFile(false);
				}

				const ibanProof = admissionSheetData[pageToken].process.files?.find(e => e.fileType === 'IBAN');

				if (ibanProof || !formFields?.formFieldsConfig?.hasBankDoc) {
					setValue('hasBankDoc', 'TRUE');
					setShowUploadIbanProofFile(false);
				}
			}
		} else {
			// If there's an associated proposal, some fields can be pre-populated
			if (
				admissionSheetData !== null &&
				admissionSheetData[pageToken].associatedProposal &&
				admissionSheetData[pageToken].associatedProposal !== null
			) {
				reset({
					...defaultValues,
					// Family aggregate info
					maritalStatus: admissionSheetData[pageToken].associatedProposal?.maritalStatus,
					holdersNumber: admissionSheetData[pageToken].associatedProposal?.holdersNumber.toString(),
					dependentsNumber: admissionSheetData[pageToken].associatedProposal?.dependentsNumber.toString()
				});
			}

			setValue('allCitizenCardNumber', admissionSheetData![pageToken].allCC);
			setValue('allFiscalNumber', admissionSheetData![pageToken].allNifs);
			setValue('allSocialSecurityNumber', admissionSheetData![pageToken].allSSN);
			setValue('allPensionFundNumber', admissionSheetData![pageToken].allPFN);
			// If the admission shet data has additional Expenses, the plate input must be shown and validated
			setValue(
				'validatePlate',
				!!admissionSheetData &&
					!!admissionSheetData[pageToken].associatedProposal &&
					!!admissionSheetData[pageToken].associatedProposal?.additionalExpenses
			);
		}
	}, [admissionSheetData]);

	useEffect(() => {
		// used to control if a requied file is missing
		showIdDocError = isIDMissing || false;
		showIBANDocError = isIBANMissing || false;
	}, [isIDMissing, isIBANMissing]);

	// Method create to manually display the error in respective inputs
	const enableViewError = (doc: string) => {
		switch (doc) {
			case 'ID Document Number':
				if (formFields?.formFieldsConfig?.country !== 'HR') {
					setError(
						'citizenCardNumber',
						{
							type: 'manual',
							message: 'The ID Document Number is invalid'
						},
						{ shouldFocus: true }
					);
				}
				break;
			case 'Fiscal Number':
				setError(
					'fiscalNumber',
					{
						type: 'manual',
						message: 'The Fiscal Number Number is invalid'
					},
					{ shouldFocus: true }
				);
				break;
			case 'Social Security Number':
				setError(
					'socialSecurityNumber',
					{
						type: 'manual',
						message: 'The Social Security Number is invalid'
					},
					{ shouldFocus: true }
				);
				break;
			default:
		}
	};

	useEffect(() => {
		if (invalidDocs) {
			// invalidDocss represents an array of documents that were returned by the BE as invalid.
			// As a result, an error must be shown for each invalid document
			invalidDocs.map(doc => enableViewError(doc));
		}
	}, [invalidDocs]);

	// Data related to the Admission Sheet
	const AdmissionSheetDataOptions = useMemo(() => {
		if (!admissionSheetData || !admissionSheetData[pageToken]) {
			return {
				banks: [],
				courseQualifications: [],
				educationCodes: [],
				educationStatus: [],
				idDocumentTypes: [],
				maritalStatus: [],
				nationalities: []
			};
		}

		return {
			banks: admissionSheetData[pageToken].banks.map((bank: IBankInfo) => ({
				id: bank.id,
				label: bank.name
			})),
			courseQualifications: admissionSheetData[pageToken].courseQualifications.map(
				(curseQualification: IChoosableBaseInfo) => ({
					id: curseQualification.id,
					label: curseQualification.name
				})
			),
			educationCodes: admissionSheetData[pageToken].educationCodes.map((educationCode: IChoosableBaseInfo) => ({
				id: educationCode.id,
				label: educationCode.name
			})),
			educationStatus: admissionSheetData[pageToken].educationStatus.map(
				(educationStatus: IChoosableBaseInfo) => ({
					id: educationStatus.id,
					label: educationStatus.name
				})
			),
			idDocumentTypes: admissionSheetData[pageToken].idDocumentTypes.map(
				(idDocumentType: IChoosableBaseInfo) => ({
					id: idDocumentType.id,
					label: idDocumentType.name
				})
			),
			maritalStatus: admissionSheetData[pageToken].maritalStatus.map((maritalStatus: IChoosableBaseInfo) => ({
				id: maritalStatus.id,
				label: maritalStatus.name
			})),
			nationalities: admissionSheetData[pageToken].nationalities.map((nationality: IChoosableBaseInfo) => ({
				id: nationality.id,
				label: nationality.name
			}))
		};
	}, [admissionSheetData]);

	const verifyIfRequiredDocsArePresent = (formFieldsConfig: any, isEmployee: boolean) => {
		interface IFileHolder {
			idFile: FormDataEntryValue | null;
			qcFile: FormDataEntryValue | null;
			nibFile: FormDataEntryValue | null;
		}
		const holder: IFileHolder = {
			idFile: null,
			qcFile: null,
			nibFile: null
		};

		// add files to holder
		holder.idFile = selectedFilesFormData && selectedFilesFormData.get(FileUploadOptions.IDDOC);
		if (selectedFilesFormData && selectedFilesFormData.get(FileUploadOptions.QUALIFCERTIFICATE))
			holder.qcFile = selectedFilesFormData && selectedFilesFormData.get(FileUploadOptions.QUALIFCERTIFICATE);
		holder.nibFile = selectedFilesFormData && selectedFilesFormData.get(FileUploadOptions.IBAN);
		if (
			(!holder.idFile && showUploadIdFile && formFieldsConfig.hasIdDoc) ||
			(!holder.nibFile && showUploadIbanProofFile && formFieldsConfig.hasBankDoc)
		) {
			if (isEmployee && formFields?.formFieldsConfig.country == 'PT') {
				// if files are missing, and we're not editing a previously sheet where files were submitted show visual error
				setisIDMissing(!holder.idFile && showUploadIdFile);
				setisIBANMissing(!holder.nibFile && showUploadIbanProofFile);
				return false;
			} else {
				return true;
			}
		}
		return true;
	};

	const onSubmit = async (data: IAdmissionSheetPayload) => {
		// close confirm modal
		setOpenConfirmationSubmit(false);
		let payload = data;

		// add company value to payload
		payload.company = admissionSheetData && admissionSheetData[pageToken].process.company;
		// add files to payload
		if (showUploadIdFile && formFields?.formFieldsConfig.hasIdDoc) {
			payload.idFile =
				selectedFilesFormData && (selectedFilesFormData.get(FileUploadOptions.IDDOC) as unknown as File);
		}

		if (
			showUploadQualificationsCertificateFile &&
			selectedFilesFormData &&
			selectedFilesFormData.get(FileUploadOptions.QUALIFCERTIFICATE) &&
			formFields?.formFieldsConfig.hasQcDoc
		)
			payload.qcFile =
				selectedFilesFormData &&
				(selectedFilesFormData.get(FileUploadOptions.QUALIFCERTIFICATE) as unknown as File);

		if (showUploadIbanProofFile && formFields?.formFieldsConfig.hasBankDoc) {
			payload.nibFile =
				selectedFilesFormData && (selectedFilesFormData.get(FileUploadOptions.IBAN) as unknown as File);
		}

		if (!payload.courseEndDate) payload.courseEndDate = '';
		if (
			(!!payload.idFile || !showUploadIdFile || !formFields?.formFieldsConfig?.hasIdDoc) &&
			(!!payload.nibFile || !showUploadIbanProofFile || !formFields?.formFieldsConfig?.hasBankDoc)
		) {
			// create formdata payload to accomodate files
			const payloadFormData = new FormData();
			Object.keys(payload).map(key => payloadFormData.append(key, payload[key]));

			// prepare array with docs to be validated by BE if the doc type chosen is 'CC'
			const docs = [];
			if (
				payload.docType?.toLowerCase() === 'CC'.toLowerCase() &&
				!!payload.citizenCardNumber &&
				formFields?.formFieldsConfig?.country !== 'HR'
			) {
				// remove spaces
				docs.push({ name: 'cc', value: payload.citizenCardNumber.replace(/\s/g, '') });
			}

			if (payload.fiscalNumber) {
				docs.push({ name: 'nif', value: payload.fiscalNumber });
			}

			if (payload.socialSecurityNumber) {
				docs.push({ name: 'niss', value: payload.socialSecurityNumber });
			}
			if (payload.bankNib) {
				docs.push({ name: 'iban', value: payload.bankNib });
			}

			const result = await sendAdmissionSheet(pageToken, docs, payloadFormData);
			// if result is true, the admission was submitted with sucess and the form data can be reseted
			if (result) {
				setIsSubmitted(result as unknown as boolean);
				reset(defaultValues);
				selectedFilesFormData = new FormData();
			}
		} else if (!isEmployee && formFields.formFieldsConfig.country == 'PT') {
			// create formdata payload to accomodate files
			if (payload.nibFile == null) delete payload.nibFile;
			if (payload.idFile == null) delete payload.idFile;
			if (payload.holdersNumber == null || payload.holdersNumber == '') delete payload.holdersNumber;
			const payloadFormData = new FormData();
			Object.keys(payload).map(key => payloadFormData.append(key, payload[key]));

			// prepare array with docs to be validated by BE if the doc type chosen is 'CC'
			const docs = [];
			if (payload.docType?.toLowerCase() === 'CC'.toLowerCase() && !!payload.citizenCardNumber) {
				// remove spaces
				docs.push({ name: 'cc', value: payload.citizenCardNumber.replace(/\s/g, '') });
			}

			if (payload.fiscalNumber) {
				docs.push({ name: 'nif', value: payload.fiscalNumber });
			}

			if (payload.socialSecurityNumber) {
				docs.push({ name: 'niss', value: payload.socialSecurityNumber });
			}
			if (payload.bankNib) {
				docs.push({ name: 'iban', value: payload.bankNib });
			}

			const result = await sendAdmissionSheet(pageToken, docs, payloadFormData);
			// if result is true, the admission was submitted with sucess and the form data can be reseted
			if (result) {
				setIsSubmitted(result as unknown as boolean);
				reset(defaultValues);
				selectedFilesFormData = new FormData();
			}
		}
	};

	const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		temporaryFileHolder = {
			name: event.target.name,
			file: (!!event.target.files && event.target.files[0]) || null
		};
		const fileSize = !!event.target.files && event.target.files[0].size;
		const fileType = !!event.target.files && event.target.files[0].type;
		setIsFileSizeOk(fileSize <= MaxFileSize); // 4Mb
		setIsFileTypeOk(fileType == 'application/pdf');
	};

	const saveDocument = (doc: { file: File | null; name: string | null }, fileType: string | undefined) => {
		if (doc.name !== null && doc.file !== null) {
			const fileArray = selectedFilesFormData.getAll(doc.name);
			// If a document already exist, it's replaced by the new one, else, it's added
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions
			!!fileArray && fileArray.length > 0
				? selectedFilesFormData.set(doc.name, doc.file)
				: selectedFilesFormData.append(doc.name, doc.file);

			confirmValidDocuments(fileType);
		}
	};

	//Allows the validation of the files iban proof and id proof
	const confirmValidDocuments = (fileType: string | undefined) => {
		if (fileType === 'nibFile') setValue('hasBankDoc', 'TRUE');
		if (fileType === 'idFile') setValue('hasIDDoc', 'TRUE');
	};

	const getFileName = (fileType: string) => {
		const file = selectedFilesFormData && (selectedFilesFormData.get(fileType) as unknown as File);
		return file ? file.name : 'N/A';
	};

	const uploadIDDocs = () => {
		setAddModalOpen({ docType: FileUploadOptions.IDDOC, modalState: true });
	};
	const uploadQualificationCertificate = () => {
		setAddModalOpen({ docType: FileUploadOptions.QUALIFCERTIFICATE, modalState: true });
	};
	const uploadIBANProof = () => {
		setAddModalOpen({ docType: FileUploadOptions.IBAN, modalState: true });
	};

	const newFileActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				temporaryFileHolder = { name: null, file: null };
				setAddModalOpen({ docType: addModalOpen.docType, modalState: false });
			}
		},
		{
			id: 'ok',
			label: 'Ok',
			color: 'secondary',
			variant: 'text',
			disabled: !isFileSizeOk || !isFileTypeOk,
			onClick: () => {
				saveDocument(temporaryFileHolder, addModalOpen.docType);
				setAddModalOpen({ docType: addModalOpen.docType, modalState: false });
			}
		}
	];
	const submitActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenConfirmationSubmit(false)
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				setOpenConfirmationSubmit(false);
				onSubmit(watch() as unknown as IAdmissionSheetPayload);
			}
		}
	];

	const footerActions = [
		...(tabSelected !== 0
			? [
					{
						id: 'previous',
						label: 'Previous',
						// left: true,
						onClick: () => {
							setTabSelected(tabSelected - 1);
							scrollToTopRef.current.scrollIntoView({
								behavior: 'smooth',
								block: 'start'
							});
						},
						variant: 'outlined',
						disabled: false
					}
			  ]
			: []),
		...(tabSelected !== modifiedTabs?.length - 1
			? [
					{
						id: 'next',
						label: 'Next',
						onClick: () => {
							setTabSelected(tabSelected + 1);
							/* Checking if the tabSelected is in the formWarnings array. If it is, it is removing it from
							the array. */
							if (formWarnings.includes(tabSelected)) {
								setFormWarnings(formWarnings.filter(e => e !== tabSelected));
							}
							scrollToTopRef.current.scrollIntoView({
								behavior: 'smooth',
								block: 'start'
							});
						},
						variant: 'contained',
						disabled: false
					}
			  ]
			: []),
		...(tabSelected === modifiedTabs?.length - 1
			? [
					{
						id: 'submit',
						endIcon: 'submit',
						label: 'Submit',
						onClick: () => {
							setSubmitClick(true);
						},
						type: 'submit',
						form: 'form-admission-sheet',
						variant: 'contained',
						disabled: false
					}
			  ]
			: [])
	];

	const isLoading = blockAdmissionSheet
		? false
		: admissionSheetData === null || admissionSheetData[pageToken] === null;

	if (isLoading) return <LoadingOverlay />;

	// if sheet is already submitted, a different screen must be shown
	if (blockAdmissionSheet || isSubmitted) {
		return (
			<>
				<div className={classes.root}>
					<div className={classes.blockedScreenBox}>
						<img
							className={
								INSTANCE.toLowerCase().includes('xpand')
									? classes.blockedScreenLogo
									: classes.blockedScreenLogoBalwurk
							}
							src={INSTANCE.toLowerCase().includes('xpand') ? '/cp/logo.svg' : company.bigCompanyLogo}
							alt="logo"
						/>
						{isSubmitted ? (
							<>
								<p className={classes.blockedScreenTitle}>
									Your Admission Sheet was successfully submitted!
								</p>
								<div className={classes.admissionSubmittedImage}>
									<AdmissionSubmittedIcon />
								</div>
								<br />
								<div className={classes.admissionText}>
									Data is now going to be validated by our Human Resources team.
								</div>
								<br />
								<div className={classes.admissionText}>
									We will get back to you as soon as possible.
								</div>
								<br />
								<div className={classes.admissionText}>Thank You!</div>
								<br />
								<br />
								<div className={classes.admissionText}>
									Don't go without checking out what {company.companyText} has to offer.
								</div>
								<br />
								<Button
									color="primary"
									variant="outlined"
									onClick={() =>
										window.open(companyInfo.companySite, '_blank', 'noopener,noreferrer')
									}>
									Visit {company.companyText}
								</Button>
							</>
						) : (
							<div className={classes.blockedScreenMessage}>
								It is not possible to access this admission process.
							</div>
						)}
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			{loading && <LoadingOverlay />}
			<div className={classes.pageContainer}>
				{admissionSheetData && admissionSheetData[pageToken].process.employeeName && (
					<PageTitle
						title={
							<Typography gutterBottom style={{ fontSize: '18px', fontWeight: 'bold', color: 'grey' }}>
								{admissionSheetData[pageToken]?.process.employeeName} - Admission Form
							</Typography>
						}
					/>
				)}
				<form
					ref={scrollToTopRef}
					className={classes.formStyle}
					id="form-admission-sheet"
					encType="multipart/form-data"
					onSubmit={e => {
						e.preventDefault();
						if (
							verifyIfRequiredDocsArePresent(formFields.formFieldsConfig, isEmployee) ||
							Object.keys(errors).length === 0
						) {
							return handleSubmit(() => setOpenConfirmationSubmit(true))(e);
						}
						// eslint-disable-next-line
						console.error({
							docs: verifyIfRequiredDocsArePresent(formFields.formFieldsConfig, isEmployee),
							errors
						});
						addNotification('danger', 'Please attach all the required documents');
						return null;
					}}>
					<FormSteps steps={modifiedTabs} selected={tabSelected} errors={formWarnings} />
					<Scrollbars
						ref={scrollbarRef}
						style={{ height: 'calc(100vh - 270px)', overflowX: 'hidden' }}
						// eslint-disable-next-line
						renderTrackHorizontal={(props: any) => (
							<div {...props} style={{ display: 'none' }} className="track-horizontal" />
						)}>
						{modifiedTabs.map(tab => {
							const commonProps = {
								// react-hook-form helpers
								control,
								errors,
								setValue,
								reset,
								watch,
								// component fields
								AdmissionSheetDataOptions,
								FileUploadOptions,
								showUploadQualificationsCertificateFile,
								selectedFilesFormData,
								getFileName,
								uploadQualificationCertificate,
								formFields,
								isEmployee
							};
							// {...{}}

							return (
								<>
									{isEmployee || formFields?.formFieldsConfig?.country == 'HR' ? (
										<TabPanel
											key={`proposal_tab_container_${tab.id}`}
											value={tabSelected}
											index={tab.id}>
											{tab.id === 0 && (
												<GeneralInfo {...{ admissionSheetData, pageToken }} {...commonProps} />
											)}
											{tab.id === 1 && <ContactInfo {...commonProps} />}
											{tab.id === 2 && (
												<IDInfo
													{...{
														showUploadIdFile,
														showIdDocError,
														uploadIDDocs,
														socialSecurityNumberRequired,
														setSocialSecurityNumberRequired,
														admissionSheetData,
														pageToken
													}}
													{...commonProps}
												/>
											)}
											{tab.id === 3 && <FamilyInfo {...commonProps} />}
											{tab.id === 4 && <Qualifications {...commonProps} />}
											{tab.id === 5 && (
												<Banking
													{...{ showUploadIbanProofFile, showIBANDocError, uploadIBANProof }}
													{...commonProps}
												/>
											)}
											{tab.id === 6 && (
												<GDPR
													additionalErrors={errors}
													handleWarning={(names: unknown, tabsWithWarn: number[]) =>
														setFormWarnings(tabsWithWarn)
													}
													submitClick={submitClick}
													setSubmitClick={setSubmitClick}
													{...commonProps}
												/>
											)}
										</TabPanel>
									) : (
										<TabPanel
											key={`proposal_tab_container_${tab.id}`}
											value={tabSelected}
											index={tab.id}>
											{tab.id === 0 && (
												<GeneralInfo {...{ admissionSheetData, pageToken }} {...commonProps} />
											)}
											{tab.id === 1 && <ContactInfo {...commonProps} />}
											{tab.id === 2 && (
												<IDInfo
													{...{
														showUploadIdFile,
														showIdDocError,
														uploadIDDocs,
														socialSecurityNumberRequired,
														setSocialSecurityNumberRequired,
														admissionSheetData,
														pageToken
													}}
													{...commonProps}
												/>
											)}
											{tab.id === 3 && <Qualifications {...commonProps} />}
											{tab.id === 4 && (
												<Banking
													{...{ showUploadIbanProofFile, showIBANDocError, uploadIBANProof }}
													{...commonProps}
												/>
											)}
											{tab.id === 5 && (
												<GDPR
													additionalErrors={errors}
													handleWarning={(names: unknown, tabsWithWarn: number[]) =>
														setFormWarnings(tabsWithWarn)
													}
													submitClick={submitClick}
													setSubmitClick={setSubmitClick}
													{...commonProps}
												/>
											)}
										</TabPanel>
									)}
								</>
							);
						})}
					</Scrollbars>
				</form>
				<ActionFooter actions={footerActions} />
			</div>

			<Dialog
				modal={{
					open: Boolean(addModalOpen && addModalOpen.modalState),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick')
							setAddModalOpen({ docType: addModalOpen.docType, modalState: false });
					},
					content: (
						<form encType="multipart/form-data" id="form-admission-sheet">
							<Grid container style={{ padding: '10px 5px 40px 5px' }}>
								<Grid item xs={12}>
									<InfoField label="" value="Select a file to upload" />
									<CoreInput
										onChange={changeHandler}
										name={addModalOpen.docType}
										type="file"
										inputProps={{ accept: 'application/pdf' }}
									/>
									{isFileSizeOk === false && (
										<InputLabel className={classes.errorInputLabel}>
											File size exceeds the maximum limit (4Mb).
										</InputLabel>
									)}
									{isFileTypeOk === false && (
										<InputLabel className={classes.errorInputLabel}>
											File type is not valid.
										</InputLabel>
									)}
								</Grid>
							</Grid>
						</form>
					)
				}}
				title="Uploading File"
				actions={newFileActions}
				scroll="body"
			/>
			<Dialog
				modal={{
					open: Boolean(openConfirmationSubmit),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenConfirmationSubmit(false);
					},
					content: (
						<>
							<Typography gutterBottom>Are you sure you want to submit this information?</Typography>
						</>
					)
				}}
				title="Submit Admission"
				actions={submitActions}
				scroll="body"
			/>
		</>
	);
};

export default AdmissionSheet;
