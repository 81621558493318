import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	/** ADD_SKILLS MODAL BUTTONS */
	buttonOutlined: {
		border: '1px solid #AAC3D166'
	},
	buttonOutlinedSelected: {
		border: '2px solid #3F80EF'
	},
	buttonRoot: {
		padding: '30px',
		minHeight: '130px',
		display: 'block'
	},
	selectedIcon: {
		position: 'absolute',
		top: '10px',
		right: '10px',
		fontWeight: 600
	},
	selectedBadge: {
		position: 'absolute',
		top: '20px',
		right: '20px'
	},
	ASMButtonTitle: {
		fontSize: '15px',
		fontFamily: 'Open Sans SemiBold',
		color: theme.typographyColor
	},
	ASMButtonTitleSel: {
		fontSize: '15px',
		fontFamily: 'Open Sans SemiBold',
		color: theme.accentColor
	},
	ASMButtonSubTitle: {
		fontSize: '12px',
		fontFamily: 'Open Sans',
		marginTop: '5px',
		color: theme.typographyColor
	},
	ASMSubGroupCollapse: {
		width: '60%'
	},
	ASMSubGroupCollapsePaper: {
		width: '100%',
		background: '#FAFAFA',
		border: '1px solid #AAC3D13B'
	},
	ASMSubGroupCollapseContainer: {
		padding: '60px'
	},
	ASMBlurBG: {
		width: '100%',
		height: ' calc(100% - 290px)',
		position: 'absolute',
		top: '290px',
		backdropFilter: 'blur(6px)',
		zIndex: 10,
		left: '0'
	},
	ASMButtonExpanded: {
		zIndex: 11
	}
	/** ADD_SKILLS MODAL BUTTONS */
}));

export { useStyles };
