import { OriginalResponse } from 'lib/handlers/requestTypes';
import { IResume, ISkill, ISkillEvaluation, ISkillGroup, ISkillSubGroup } from '../personalInfoTypes';

export enum SkillsAndResumesActions {
	SET_LOADING = '@@administration/skillsAndResumes/SET_LOADING',
	SET_ERROR = '@@personalInfo/SET_ERROR',
	POST_NEW_SKILLS_TREE = '@@administration/skillsAndResumes/POST_NEW_SKILLS_TREE',
	GET_SKILLS_EVALUATION = '@@administration/skillsAndResumes/GET_SKILLS_EVALUATION',

	GET_USER_SKILLS_EVALUATION = '@@administration/skillsAndResumes/GET_USER_SKILLS_EVALUATION',
	CLEAR_USER_SKILLS_EVALUATION = '@@administration/skillsAndResumes/CLEAR_USER_SKILLS_EVALUATION',
	POST_RESEND_SKILLS_EVALUATION = '@@administration/skillsAndResumes/POST_RESEND_SKILLS_EVALUATION',
	POST_CHANGE_DUE_DATE = '@@administration/skillsAndResumes/POST_CHANGE_DUE_DATE',
	CLEAR_SKILLS_EVALUATION = '@@administration/skillsAndResumes/CLEAR_SKILLS_EVALUATION',
	POST_SKILLS_FEEDBACK = '@@administration/skillsAndResumes/POST_SKILLS_FEEDBACK',
	GET_SEARCH_RESUMES = '@@administration/skillsAndResumes/GET_SEARCH_RESUMES',
	GET_EXPORT_RESUME = '@@administration/skillsAndResumes/GET_EXPORT_MULTIPLE_RESUMES',
	GET_EXPORT_MULTIPLE_RESUMES = '@@administration/skillsAndResumes/GET_EXPORT_MULTIPLE_RESUMES',
	POST_REMIND_RESUME = '@@administration/skillsAndResumes/POST_REMIND_RESUME',
	GET_RESUME_DETAILS = '@@administration/skillsAndResumes/GET_RESUME_DETAILS',
	CLEAR_RESUMES_LIST = '@@administration/skillsAndResumes/CLEAR_RESUMES_LIST'
}

export type SkillsAndResumesState = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly newSkillsTreeAction: unknown | null;
	readonly listUsersSkills: IEmployeesSkillsList | null;
	readonly userSkillsEvaluation: IEmployeesSkills | null;
	readonly userResendSkillsEvaluation: OriginalResponse | null;
	readonly userChangeDueDate: OriginalResponse | null;
	readonly skillsFeedback: unknown | null;
	readonly listSearchResumes: IListResumeUsers | null;
	readonly exportResume: unknown | null;
	readonly exportMultipleResumes: unknown | null;
	readonly remindResume: unknown | null;
	readonly resumeDetails: IResumeDetails | null;
};

export interface IEmplSkillStatus {
	id: number;
	employee: string;
	name: string;
	position: string;
	lastUpdateDate: string;
	lastReminderDate: string;
	nextReminderDate: string;
	nextEvaluationDueDate: string;
	status: number;
	firstSubmittedDate: string;
	lastSubmittedDate: string;
}

export interface ISkillsFeedback {
	id?: number;
	feedback: string;
	feedbackDate?: string;
}

export interface ISubGroupSkill {
	id: number;
	skillSubGroupId: number;
}

export interface IEmployeesSkills extends IEmplSkillStatus {
	careerStartDate: string;
	subGroups: ISubGroupSkill[] | [];
	evaluations: ISkillEvaluation[] | [];
	feedbacks: ISkillsFeedback[] | [];
}

export interface IUserSkillEval {
	userSkills?: IEmployeesSkills;
}

export interface IChangeDueDate {
	id: string | number;
	dueDate: string;
}

export interface IEmployeesSkillsList {
	employeeSkills?: IEmplSkillStatus[];
}

export interface IUserSkills {
	data: ISkill;
	id: number;
	name: string;
	section: boolean;
	skillGroupId: number;
	skills: ISkill[];
}

export interface IUserSubGroups {
	data: IUserSkills[];
	id: number;
	name: string;
	skillAreaId: number;
	skillSubGroups: ISkillSubGroup[];
	weight: number;
}

export interface ISystemSkillsWithUserData {
	data: IUserSubGroups[];
	id: number;
	name: string;
	skillGroups: ISkillGroup[];
	weight: number;
}

//* RESUMES

export interface IListResumeUsers {
	users: IResumeUserDetail[];
}

export interface IResumeUserDetail {
	name: string;
	username: string;
	resumeId?: number;
	updateDate?: string;
}

export interface IResumeReminder {
	username: string;
}

export interface IResumeDetails {
	resume: IResume;
	employeeSkills: IEmployeesSkills;
}
