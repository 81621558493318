// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		leftCardSection: {
			textAlign: 'left',
			marginLeft: '15px'
		},
		phoneLine: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start'
		},
		editButton: {
			margin: '-10px 0',
			padding: '10px'
		}
	})
);

export { useStyles };
