/* eslint-disable @typescript-eslint/no-explicit-any */
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import { IUsersResignationList, IResignFormGet, IDeactivateFormGet } from './resignations';

export * from './resignations';

export enum FormFieldsActions {
	SET_LOADING = '@@formFields/SET_LOADING',
	SET_ERROR = '@@formFields/SET_ERROR',
	GET_FORM_FIELDS_CONFIG = '@@formFields/GET_FORM_FIELDS_CONFIG'
}

export type FormFieldsState = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly formFieldsConfig: unknown | null;
};
