/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable indent, @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';

import { setFormFieldsConfig, setLoading } from './actions';
import { fetchFormFieldsConfig } from 'lib/api/administration/formFieldsConfig';

//! GET INFO TABLES
// GET CSC FOR ALL USERS
export const getFormFieldsConfig =
	(employeeType, country, formType) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: unknown } = await fetchFormFieldsConfig(employeeType, country, formType);
			if (response.data) {
				dispatch(setFormFieldsConfig(response?.data || null));
			} else {
			}
		} catch (error) {}
	};
