/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';

import { booleanRequired, stringRequired, dateRequired } from 'lib/utils/formUtils';
import { IConvocationLocation } from 'typings/store/admin/users';

export const schema = yup.object().shape({
	hasLocations: booleanRequired,
	locations: yup.array().when('hasLocations', {
		is: true,
		then: yup.array().of(
			yup.object().shape({
				startDate: dateRequired.min(new Date(), 'Date must be after todays date!'),
				location: stringRequired
			})
		)
	}),
	hasFiles: booleanRequired
});

export const defaultValuesConvocations = {
	hasLocations: true,
	locations: [{ startDate: null, location: null }] as unknown as IConvocationLocation[],
	hasFiles: undefined
};
