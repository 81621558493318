import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from 'typings/state';

import {
	getAdmissionDetailsToValidate,
	invalidateAdmission,
	approveAdmission,
	getAdmissionFile,
	deleteAdmissionFile,
	clearAdmissionDetails,
	getSubmissionInfo,
	clearAdmissionsError
} from 'store/administration/admissions/thunks';

import AdmissionDetails from './AdmissionDetails';

const mapStateToProps = ({ admissions }: AppState) => ({ admissions });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getAdmissionDetailsToValidate,
			invalidateAdmission,
			approveAdmission,
			getAdmissionFile,
			deleteAdmissionFile,
			clearAdmissionDetails,
			getSubmissionInfo,
			clearAdmissionsError
		},
		dispatch
	);

export type AdmissionDetailsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionDetails);
