// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			display: 'flex',
			padding: '0 175px 50px 175px',
			minWidth: '100%',
			alignItems: 'center',
			flexDirection: 'column',
			margin: 'auto'
		},
		userInfo: {
			textAlign: 'left',
			width: '100%',
			marginTop: '40px',
			marginBottom: '80px'
		},
		name: {
			color: '#536B7A',
			fontSize: '28px',
			fontFamily: 'Open Sans SemiBold'
		},
		contract: {
			color: '#536B7A',
			fontSize: '18px',
			fontFamily: 'Open Sans'
		},
		date: {
			color: '#718FA2',
			fontSize: '12px',
			fontFamily: 'Open Sans',
			letterSpacing: '1.2px',
			textTransform: 'uppercase'
		},
		dateLabel: {
			color: '#3D464D',
			fontSize: '16px',
			fontFamily: 'Open Sans'
		}
	})
);

export { useStyles };
