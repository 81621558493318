//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo } from 'react';

//* EXTERNAL LIBS --> MUI
import { Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, PageTitle, Table } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { IAuditLogEntry } from 'typings/store/admin/proposals';
import { ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import { allStatus } from 'pages/Admin/_ProposalsFormUtils/utils';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { ProposalAuditLogProps } from '.';
import { getStatusIdFromString } from './utils';

//* COMPONENT INTERFACES
interface IProposalAuditLog extends ProposalAuditLogProps {
	token: string;
	name: string;
	id: string | number;
	// handleClose: Dispatch<SetStateAction<ICollaborationProposal | null>>;
}

//* COMPONENT
const ProposalAuditLog: FC<IProposalAuditLog> = ({
	proposals,
	token,
	id,
	name,
	getProposalAuditLog,
	clearProposalAuditLog
}) => {
	const classes = useStyles();
	const { loading, proposalAuditLog } = proposals;

	useEffect(() => {
		if (token && id && !proposalAuditLog) getProposalAuditLog(token, id);
	}, [token, id]);

	useEffect(
		() => () => {
			clearProposalAuditLog();
		},
		[]
	);

	const statusHelper = useMemo(() => allStatus(classes), []);

	// TABLE SEARCH HELPER
	const [searchValue, setSearchValue] = useState('');

	// TABLE COLUMNS
	const tableColumns: ITableColumn<IAuditLogEntry>[] = useMemo(
		() => [
			{
				label: 'Name',
				id: 'username',
				accentColumn: true,
				align: 'left',
				width: '15%'
			},
			{
				label: 'Date',
				id: 'date',
				type: 'date',
				width: '20%'
			},
			{
				label: 'New Status',
				id: 'newStatus',
				width: '15%',
				format: row => {
					if (statusHelper[row.newStatusId])
						return (
							<div className={classes.statusBar}>
								{statusHelper[row.newStatusId].icon} {statusHelper[row.newStatusId].label}
							</div>
						);

					return <div className={classes.statusBar}>{row.newStatusId}</div>;
				}
			},
			{
				label: 'Previous Status',
				id: 'previousStatus',
				width: '15%',
				format: row => {
					if (statusHelper[row.previousStatusId])
						return (
							<div className={classes.statusBar}>
								{statusHelper[row.previousStatusId].icon} {statusHelper[row.previousStatusId].label}
							</div>
						);

					return <div className={classes.statusBar}>{row.previousStatusId}</div>;
				}
			}
		],
		[]
	);

	// TABLE DATA PARSED
	const tableData = useMemo(
		() =>
			proposalAuditLog?.auditLog?.map(e => ({
				...e,
				date: parseDateToShow(e.date),
				newStatus: e.newStatus == 'WAITING_APPROVAL' ? 'AWAITING APPROVAL' : e.newStatus,
				previousStatus: e.previousStatus == 'WAITING_APPROVAL' ? 'AWAITING APPROVAL' : e.previousStatus,
				newStatusId: getStatusIdFromString(
					e.newStatus == 'WAITING_APPROVAL' ? 'AWAITING APPROVAL' : e.newStatus
				),
				previousStatusId: getStatusIdFromString(
					e.previousStatus == 'WAITING_APPROVAL' ? 'AWAITING APPROVAL' : (e.previousStatus as string)
				),
				dateWithMinSecMil: new Date(e.date).toISOString()
			})),
		[proposalAuditLog]
	);
	const isLoading = !proposalAuditLog;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle
				title={
					<>
						<Typography gutterBottom color="primary" className={classes.name}>
							{name || 'N/A'}
						</Typography>
					</>
				}
				actions={[]}
			/>
			<Table
				tableData={tableData}
				columns={tableColumns}
				defaultSortColumn="date"
				sortDateWithMinSecMil={true}
				defaultOrder="desc"
				handleSearch={{ searchValue, setSearchValue }}
			/>
		</>
	);
};

export default ProposalAuditLog;
