/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import {
	AdministrationActions as Actions,
	IEmailTemplates,
	INewEmailTemplate,
	IFileTemplates,
	ICachesListInfo,
	IAccessControl,
	IGrantAccessControlInfo,
	IGrantAccessControl
} from 'typings/store/admin/administration';
import { OriginalResponse } from 'lib/handlers/requestTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setAdministrationError = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.SET_ERROR, payload);

export const setEmailTemplates = (payload: IEmailTemplates | null): IDispatchType =>
	action(Actions.GET_EMAIL_TEMPLATES, payload);

export const setPostChangeEmailTemplateStatus = (
	payload: number | null,
	newData: IEmailTemplates | null
): IDispatchType =>
	action(Actions.POST_CHANGE_EMAIL_TEMPLATE_STATUS, {
		payload,
		newData
	});

export const setPostTestEmailTemplate = (payload: any | null): IDispatchType =>
	action(Actions.POST_TEST_EMAIL_TEMPLATE, payload);

export const setPostNewEmailTemplate = (
	payload: INewEmailTemplate | null,
	newData: IEmailTemplates | null
): IDispatchType =>
	action(Actions.POST_NEW_EMAIL_TEMPLATE, {
		payload,
		newData
	});

export const setFileTemplates = (payload: IFileTemplates | null): IDispatchType =>
	action(Actions.GET_FILE_TEMPLATES, payload);

export const setPostChangeFileTemplateStatus = (
	payload: number | null,
	newData: IFileTemplates | null
): IDispatchType =>
	action(Actions.POST_CHANGE_FILE_TEMPLATE_STATUS, {
		payload,
		newData
	});

export const setDownloadFileTemplate = (payload: number | null): IDispatchType =>
	action(Actions.GET_DOWNLOAD_FILE_TEMPLATE, payload);

export const setPostUploadFileTemplate = (
	payload: Document | FormData | null,
	newData: IFileTemplates | null
): IDispatchType =>
	action(Actions.POST_UPLOAD_FILE_TEMPLATE, {
		payload,
		newData
	});

export const setCachesList = (payload: ICachesListInfo | null): IDispatchType =>
	action(Actions.GET_CACHES_LIST, payload);

export const setSendDeleteSingleCache = (payload: number | null, newData: ICachesListInfo | null): IDispatchType =>
	action(Actions.DELETE_SINGLE_CACHE, {
		payload,
		newData
	});

export const setSendDeleteAllCaches = (payload: [] | null, newData: ICachesListInfo | null): IDispatchType =>
	action(Actions.DELETE_ALL_CACHES, {
		payload,
		newData
	});
export const setSendDeleteAllUserCaches = (payload: [] | null, newData: ICachesListInfo | null): IDispatchType =>
	action(Actions.DELETE_ALL_USER_CACHES, {
		payload,
		newData
	});

export const setSendDeleteAllSingleUserCaches = (payload: [] | null): IDispatchType =>
	action(Actions.DELETE_ALL_SINGLE_USER_CACHES, {
		payload
	});

export const setSendDeleteAllCompaniesCaches = (payload: [] | null, newData: ICachesListInfo | null): IDispatchType =>
	action(Actions.DELETE_ALL_COMPANY_CACHES, {
		payload,
		newData
	});
export const setAccessControlList = (payload: IAccessControl | null): IDispatchType =>
	action(Actions.GET_ACCESS_CONTROL_LIST, payload);

export const setSendRemoveAllAccesses = (
	payload: number | null,
	newData: IAccessControl | null,
	newData2: IAccessControl | null
): IDispatchType =>
	action(Actions.REMOVE_ALL_ACCESSES, {
		payload,
		newData,
		newData2
	});

export const setAccessControlListAddUser = (payload: IAccessControl | null): IDispatchType =>
	action(Actions.GET_ACCESS_CONTROL_LIST_ADD_USER, payload);

export const setAccessControlEditUser = (payload: IGrantAccessControlInfo | null): IDispatchType =>
	action(Actions.GET_ACCESS_CONTROL_EDIT_USER, payload);

export const setPutAccessControl = (
	payload: IGrantAccessControl | null,
	newData: IAccessControl | null,
	newData2: IAccessControl | null
): IDispatchType =>
	action(Actions.PUT_ACCESS_CONTROL, {
		payload,
		newData,
		newData2
	});

export const setClearEditUser = () => action(Actions.CLEAR_EDIT_USER);

export const setAuthorizationCodeRenewal = (payload: any): IDispatchType => ({
	type: Actions.SET_AUTHORIZATION_CODE_RENEWAL,
	payload
});

export const setReleasesList = (payload: any | null): IDispatchType => action(Actions.GET_ALL_RELEASES_LIST, payload);

export const setReleaseNotesList = (payload: any | null): IDispatchType =>
	action(Actions.GET_RELEASE_NOTE_LIST, payload);

export const setReleaseNotesEmail = (payload: unknown): IDispatchType =>
	action(Actions.GET_RELEASENOTES_EMAIL, payload);

export const setClearReleaseNotesEmail = (): IDispatchType => action(Actions.CLEAR_RELEASENOTES_EMAIL);

export const setPostReleaseNotesEmail = (payload: unknown): IDispatchType =>
	action(Actions.SEND_RELEASENOTES_EMAIL, payload);

export const setPostResetAllNewReleasePopups = (): IDispatchType => action(Actions.SEND_RESET_ALL_NEW_RELEASE_POPUPS);

export const setJobExecutions = (payload: unknown): IDispatchType => action(Actions.GET_JOB_EXECUTIONS_LIST, payload);

export const setAllJobs = (payload: unknown): IDispatchType => action(Actions.GET_JOBS_LIST, payload);

export const setDeleteJobExecution = (payload: unknown): IDispatchType => action(Actions.DELETE_JOB_EXECUTION, payload);

export const setRolesList = (payload: unknown): IDispatchType => action(Actions.GET_ROLES_LIST, payload);

export const setAppLinks = (payload: unknown): IDispatchType => action(Actions.GET_APP_LINKS_LIST, payload);

export const setAllGuides = (payload: unknown): IDispatchType => action(Actions.GET_APP_GUIDES_LIST, payload);

export const setPostUploadGuides = (payload: unknown): IDispatchType =>
	action(Actions.POST_UPLOAD_GUIDES, {
		payload
	});
