/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { OriginalResponse } from 'lib/handlers/requestTypes';

export enum CollaborationTypeActions {
	SET_LOADING = '@@administration/collaborationType/SET_LOADING',
	SET_ERROR = '@@administration/collaborationType/SET_ERROR',
	//! GET INFO TABLES
	// GET CSC FOR ALL USERS
	GET_EMPLOYEES_LIST = '@@administration/collaborationType/GET_EMPLOYEES_LIST',
	GET_EMPLOYEE_INFO = '@@administration/collaborationType/GET_EMPLOYEE_INFO',
	GET_COLLABORATION_TYPE_LIST = '@@administration/collaborationType/GET_COLLABORATION_TYPE_LIST',
	SUBMIT_NEW_COLLABORATION_TYPE = '@@administration/collaborationType/SUBMIT_NEW_COLLABORATION_TYPE',
	GET_EDIT_COLLABORATION_TYPE = '@@administration/collaborationType/GET_EDIT_COLLABORATION_TYPE',
	SET_COLLABORATION_TYPE_CANCEL = '@@administration/collaborationType/SET_COLLABORATION_TYPE_CANCEL',
	SET_COLLABORATION_TYPE_APPROVE = '@@administration/collaborationType/SET_COLLABORATION_TYPE_APPROVE',
	SET_COLLABORATION_TYPE_REJECT = '@@administration/collaborationType/SET_COLLABORATION_TYPE_REJECT',
	RESET_FORM_DATA = '@@administration/collaborationType/RESET_FORM_DATA',
	SET_COLLABORATION_TYPE_REQUEST_APPROVAL = '@@administration/collaborationType/SET_COLLABORATION_TYPE_REQUEST_APPROVAL',
	SET_COLLABORATION_TYPE_CLOSE = '@@administration/collaborationType/SET_COLLABORATION_TYPE_CLOSE',
	RESET_EMPLOYEE = '@@administration/collaborationType/RESET_EMPLOYEE',
	RESET_EMPLOYEE_ACTIONS = '@@administration/collaborationType/RESET_EMPLOYEE',
	SET_INVALID_DOCS = '@@administration/collaborationType/SET_INVALID_DOCS'
}

export interface CollaborationTypesState {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly employeesList: IGetEmployeeList | null;
	readonly employee: any | null;
	readonly employeeCategoryList: any | null;
	readonly employeeCategoryActions: any | null;
	readonly invalidDocs: string[] | null;
}

export interface IGetEmployee {
	name: string;
	username: string;
	businessPartnerCategory: string;
}

export interface IGetEmployeeList {
	employees: IGetEmployeeList[];
}
