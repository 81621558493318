import withLayout from 'lib/hocs/withLayout';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog, PageTitle } from 'xpand-ui/core';
import { Select, InputLabel } from 'xpand-ui/forms';
import { ReleaseNotesProps } from '.';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { Match } from 'typings/store/generalTypes';

interface IReleaseNotes extends ReleaseNotesProps {
	match: Match<{
		path?: string;
		username: string;
	}>;
	goToPage: (path: string) => void;
}
//* COMPONENT
const ReleaseNotes: FC<IReleaseNotes> = ({
	administration,
	filters,
	getReleasesList,
	getReleasesNotesList,
	goToPage,
	setReleaseHistoryPageFilter,
	sendResetAllNewReleasePopups
}) => {
	const { loading, error, listReleases, listReleaseNotes } = administration;
	const { releaseNotesFilter, releaseNotesProjectFilter } = filters;

	const [releaseNotesModal, setReleaseNotesModal] = useState<boolean>(false);
	const projectList = [
		{ id: 'XPECP', label: 'Control Panel' },
		{ id: 'XPHUB_API', label: 'Xpand API' },
		{ id: 'XPPROJECTESTIMATION', label: 'Project Estimation' },
		{ id: 'XPUILIB', label: 'Xpand LIB' }
	];

	const [searchValue, setSearchValue] = useState(releaseNotesFilter);
	const [currentSelectedProject, setCurrentSelectedProject] = useState(releaseNotesProjectFilter);

	useEffect(() => {
		setReleaseHistoryPageFilter({ searchValue, currentSelectedProject });
	}, [searchValue, currentSelectedProject]);

	useEffect(() => {
		if (!listReleases && !error) {
			getReleasesList('XPECP');
		}
		if (listReleases) {
			getReleasesList(currentSelectedProject);
		}
	}, [currentSelectedProject]);

	const currentSelectedProjectName = projectList.find(e => e.id === currentSelectedProject);
	// TABLE COMPONENT - columns
	const tableColumnsReleaseNotes: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Key',
				id: 'name'
			},
			{
				label: 'Type',
				id: 'type'
			},
			{
				label: 'Summary',
				id: 'summary'
			}
		],
		[]
	);

	// TABLE DATA
	const tableDataReleaseNotes = useMemo(
		() =>
			listReleaseNotes?.payload?.data?.results.map(e => ({
				id: e.id,
				name: e.key,
				type: e.type,
				summary: e.summary
			})),
		[listReleaseNotes]
	);

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: 'Name',
				id: 'name',
				width: '25%'
			},
			{
				label: 'Start Date',
				id: 'startDate',
				width: '25%'
			},
			{
				label: 'Release Date',
				id: 'releaseDate',
				width: '25%'
			}
		],
		[]
	);

	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'accessControlMenu',
				type: 'menu',
				width: '25%',
				render: () => true,
				options: [
					{
						id: 'releaseNotes',
						label: 'See Release Notes',
						onClick: row => {
							getReleasesNotesList(row.id);
							setReleaseNotesModal(true);
						}
					},
					{
						id: 'sendEmail',
						label: 'Send E-mail',
						onClick: row => {
							goToPage(
								`/admin/administration/releaseNotes/sendEmail/${row.id}/${row.name}/${currentSelectedProjectName.label}`
							);
						}
					},
					{
						id: 'resetNewRelease',
						label: 'Reset New Release Popup',
						onClick: sendResetAllNewReleasePopups
					}
				]
			}
		],
		[currentSelectedProjectName]
	);

	// TABLE DATA
	const tableData = useMemo(
		() =>
			listReleases?.payload?.data?.results
				.filter(e => e.archived == false)
				.map(e => ({
					id: e.id,
					name: e.name,
					startDate: e.startDate,
					releaseDate: e.releaseDate
				})),
		[listReleases]
	);

	const confirmActions = [
		{
			id: 'cancel',
			label: 'Close',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setReleaseNotesModal(false);
			}
		}
	];

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle title="" actions={[]} />
			<InputLabel>Projects</InputLabel>{' '}
			<Select
				name="projects"
				label=""
				value={currentSelectedProject}
				onChange={setCurrentSelectedProject}
				options={projectList}
			/>
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="desc"
				handleSearch={{ searchValue, setSearchValue }}
			/>
			<Dialog
				maxWidth="lg"
				title="Release Notes"
				actions={confirmActions}
				modal={{
					open: releaseNotesModal,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setReleaseNotesModal(false);
					},
					content: (
						<>
							{loading && <LoadingOverlay />}
							<Table
								tableData={tableDataReleaseNotes}
								columns={tableColumnsReleaseNotes}
								defaultSortColumn="type"
							/>
						</>
					)
				}}
			/>
		</>
	);
};

export default withLayout(ReleaseNotes);
