/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC, ReactNode } from 'react';

//* EXTERNAL LIBS --> MUI
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table } from 'xpand-ui/core';
import { PageTitle, Dialog } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import { START_API_PATH } from 'lib/utils/constants';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { UsersProps } from '.';
import { IUserResignationList } from 'typings/store/admin/resignations';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES
interface IUsers extends UsersProps {
	goToPage: (path: string) => void;
}

//* COMPONENT
const Users: FC<IUsers> = ({
	users,
	administration,
	admissions,
	filters,
	goToPage,
	getResignationList,
	clearUsersError,
	setUsersPageFilter,
	sendDeleteAllUserNotifications,
	sendDeleteAllSingleUserCaches,
	sendDeleteUserFromDB
}) => {
	const classes = useStyles();
	const { error } = users;
	const { resignationList, loading } = admissions;
	const { usersFilter } = filters;

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(usersFilter);
	const [userWasDeleted, setUserWasDeleted] = useState(false);
	const [userToDelete, setUserToDelete] = useState('');
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

	// Update filters whenever necessary
	useEffect(() => {
		setUsersPageFilter(searchValue);
	}, [searchValue]);

	useEffect(() => {
		if (!error && !resignationList) getResignationList();
	}, [resignationList]);

	// Load After Delete
	useEffect(() => {
		if (userWasDeleted) {
			getResignationList();
			setUserWasDeleted(false);
		}
	}, [userWasDeleted]);

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<IUserResignationList>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				isSortable: false,
				format: ({ username }) => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${username}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{ label: 'User', id: 'username', accentColumn: true },
			{ label: 'Name', id: 'name', width: '17%', maxWidth: '17%', accentColumn: true },
			{ label: 'Company', id: 'company' },
			{ label: 'Business Unit', id: 'businessUnit', maxWidth: '10%' },
			{ label: 'Status', id: 'status' }
		],
		[]
	);

	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'someMenu',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'clearCachedData',
						label: 'Clear cached data',
						onClick: ({ username }) => sendDeleteAllSingleUserCaches(username)
					},
					{
						id: 'deleteUser',
						label: 'Delete user',
						onClick: ({ username }) => {
							setConfirmDeleteModal(true);
							setUserToDelete(username);
						}
					},
					{
						id: 'clearUserNotification',
						label: 'Clear user notifications',
						onClick: ({ username }) => sendDeleteAllUserNotifications(username)
					}
				]
			}
		],
		[]
	);

	const confirmDeleteUser = () => {
		setConfirmDeleteModal(false);
		setUserToDelete('');
		sendDeleteUserFromDB(JSON.stringify({ username: userToDelete }));
		setUserWasDeleted(true);
	};

	const tableData: IUserResignationList[] | null = useMemo(
		() =>
			resignationList &&
			resignationList.users.map(e => ({
				...e,
				username: e.username || '',
				name: e.name || '',
				company: (e.summaryData && JSON.parse(e.summaryData)?.company) || '',
				businessUnit: (e.summaryData && JSON.parse(e.summaryData)?.businessUnit) || '',
				status:
					(e.status === 1 && 'Active') ||
					(e.status === 2 && 'Resigned') ||
					(e.status === 3 && 'Deactivated') ||
					'NA'
			})),
		[resignationList]
	);
	if (error) return handleErrorPage(error, clearUsersError);

	const isLoading = resignationList === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="desc"
				handleSearch={{ searchValue, setSearchValue }}
			/>
			<Dialog
				modal={{
					open: Boolean(confirmDeleteModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmDeleteModal(false);
					},
					content: <Typography gutterBottom>Are you sure you want to delete this user?</Typography>
				}}
				title="Delete User"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setConfirmDeleteModal(false)
					},
					{
						id: 'confirmDeleteUser',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => confirmDeleteUser(),
						disabled: getLSField('impersonate_userInfo')
					}
				]}
				scroll="body"
			/>
		</>
	);
};

export default withLayout(Users);
