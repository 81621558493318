/* eslint-disable no-empty-pattern */
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { AppState } from 'typings/state';

import OrgChart from './OrgChart';

const mapStateToProps = ({company}: AppState) => ({company});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export type OrgChartProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OrgChart);
