import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	defaultOption: {
		maxHeight: '32px',
		padding: '8px 16px',
		opacity: '1 !important',
		color: '#ffffff'
	},
	defaultOptionText: {
		fontSize: '14px',
		color: '#9c9b99'
	}
}));

export { useStyles };
