// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		centerNoResults: {
			display: 'block',
			marginTop: '20px'
		}
	})
);

export { useStyles };
