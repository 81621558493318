import { restCall } from 'lib/handlers/restHandler';
import { ISystemSkills } from 'typings/store/personalInfoTypes';
import { OriginalResponse, Success, GET, POST, EXPORTFILE, EXPORTFILEPOST } from 'lib/handlers/requestTypes';
import { IChangeDueDate, IResumeReminder, ISkillsFeedback } from 'typings/store/admin/skillsAndResumes';

export const postNewSkillsTree = async (payload: ISystemSkills[]): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/skills/tree', payload);

export const fetchSkillsEvaluation = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/skills/evaluations');

export const fetchUserSkillsEvaluation = async (payload: number): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/skills/evaluations/${payload}/details`);

export const postResendSkillsEvaluation = async (payload: number): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/skills/evaluations/${payload}/resend`, payload);

export const postChangeDueDate = async (payload: IChangeDueDate): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/skills/evaluations/${payload.id}/changeDueDate`, payload);

export const postSkillsFeedback = async (payload: ISkillsFeedback): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/skillsFeedback', payload);

export const fetchSearchResumes = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/resumes');

export const fetchExportResume = async (payload: number): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILE, `/admin/resumes/${payload}/export`);

export const fetchExportMultipleResumes = async (payload: number[]): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILEPOST, '/admin/resumes/export', payload);

export const postRemindResume = async (payload: IResumeReminder): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/resumes/remind', payload);

export const fetchResumeDetails = async (payload: number): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/resumes/${payload}/details`);
