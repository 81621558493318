import { createReducer } from 'typesafe-actions';
import { PersonalInfoActions as Actions, PersonalInfoSate } from 'typings/store/personalInfoTypes';

const INITIAL_STATE: PersonalInfoSate = {
	//	Approvals - Timesheets
	approvalsTimesheet: null,
	approvalsTimesheetPeriodDetails: null,
	updateTimesheet: null,

	// Approvals - Expenses
	approvalsExpenses: null,
	updateExpenses: null,

	//Approvals - Timeoff
	approvalsTimeoff: null,
	approvalsTimeOffDetails: null,
	updateTimeoff: null
};

const ApprovalsReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({
		...state,
		loading: true,
		convocationMessageHasChanged: false
	}),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.SET_LOADING_FALSE]: (state, { payload }) => ({
		...state,
		loading: false
	}),
	[Actions.GET_APPROVALS_TIMESHEET]: (state, { payload }) => ({
		...state,
		loading: false,
		approvalsTimesheet: payload
	}),
	[Actions.GET_APPROVALS_TIMESHEET_PERIOD_DETAILS]: (state, { payload }) => ({
		...state,
		loading: false,
		approvalsTimesheetPeriodDetails: payload
	}),
	[Actions.GET_OUTLOOK_EVENTS]: (state, { payload }) => ({
		...state,
		loading: false,
		outlookEvents: payload
	}),
	[Actions.UPDATE_TIMESHEET]: (state, { payload }) => ({
		...state,
		loading: false,
		updateTimesheet: payload
	}),
	[Actions.REMOVE_UPDATE_TIMESHEET]: state => ({
		...state,
		loading: false,
		updateTimesheet: null
	}),
	[Actions.GET_APPROVALS_EXPENSES]: (state, { payload }) => ({
		...state,
		loading: false,
		approvalsExpenses: payload
	}),
	[Actions.UPDATE_EXPENSES]: (state, { payload }) => ({
		...state,
		loading: false,
		updateExpenses: payload
	}),
	[Actions.REMOVE_UPDATE_EXPENSES]: state => ({
		...state,
		loading: false,
		updateExpenses: null
	}),
	[Actions.GET_APPROVALS_TIMEOFF]: (state, { payload }) => ({
		...state,
		loading: false,
		approvalsTimeoff: payload
	}),
	[Actions.UPDATE_TIMEOFF]: (state, { payload }) => ({
		...state,
		loading: false,
		updateTimeoff: payload
	}),
	[Actions.REMOVE_UPDATE_TIMEOFF]: state => ({
		...state,
		loading: false,
		updateTimeoff: null
	}),
	[Actions.GET_APPROVALS_TIMEOFF_DETAILS]: (state, { payload }) => ({
		...state,
		loading: false,
		approvalsTimeOffDetails: payload
	})
});

export default ApprovalsReducer;
