import { restCall } from 'lib/handlers/restHandler';

import { OriginalResponse, Success, GET, POST, EXPORTFILE, DELETE } from 'lib/handlers/requestTypes';
import { IInvalidateAdmission } from 'typings/store/admin/admissions';

// Admission Management
export const fetchAdmissionManagementData = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/admissions/manage/list');

// Admission Management - Employee Data
export const fetchEmployeeData = async (admissionId: number): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/admissions/manage/${admissionId}/employeeData`);

// Admission Management - Validate
export const fetchAdmissionDataToValidate = async (admissionId: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/admissions/manage/${admissionId}/validate`);

// Admission Management - Submission Info
export const fetchSubmissionInfo = async (admissionId: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/admissions/manage/${admissionId}/submissionInfo`);

//	Admission Management - Send Pending Admission
export const sendPendingAdmission = async (admissionId: number): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/admissions/manage/${admissionId}/send`);

//	Admission Management - Resend Admission
export const resendPendingAdmission = async (admissionId: number): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/admissions/manage/${admissionId}/resend`);

//	Admission Management - Cancel Admission
export const postCancelAdmission = async (admissionId: number): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/admissions/manage/${admissionId}/cancel`);

//	Admission Management - Close Admission
export const postCloseAdmission = async (admissionId: number): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/admissions/manage/${admissionId}/close`);

//	Admission Management - Approve Admission
export const postApproveAdmission = async (admissionId: string): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/admissions/manage/${admissionId}/approve`);

//	Admission Management - invalidate Admission
export const postInvalidateAdmission = async (
	admissionId: string,
	payload: IInvalidateAdmission
): Promise<OriginalResponse | Success> => restCall(POST, `/admin/admissions/manage/${admissionId}/invalidate`, payload);

//	Files
//	Admission Management - Get File
export const fetchFileById = async (admissionId: string, fileId: number): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILE, `/admin/admissions/manage/${admissionId}/files/${fileId}`);

//	Admission Management - Delete File
export const deleteFileById = async (admissionId: string, fileId: number): Promise<OriginalResponse | Success> =>
	restCall(DELETE, `/admin/admissions/manage/${admissionId}/files/${fileId}/delete`);
