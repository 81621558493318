// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		avatarContainer: {
			width: '50px',
			height: '50px',
			margin: 'auto',
			position: 'relative',
			overflow: 'hidden',
			borderRadius: '50%'
		},
		avatarImage: {
			display: 'inline',
			margin: '0 auto',
			height: '50px',
			width: 'auto'
		},
		animationNone: {
			display: 'none',
			visibility: 'hidden',
			opacity: '0',
			height: 0,
			padding: '0 !important',
			transition: 'all 0.3s ease'
		},
		animation: {
			visibility: 'hidden',
			opacity: '0',
			height: 0,
			padding: '0 !important',
			transition: 'all 0.3s ease'
		},
		show: {
			display: 'block',
			visibility: 'visible',
			height: 'auto',
			// padding: '40px !important',
			opacity: 1
		},
		referenceInput: {
			marginTop: '4px'
		},
		referenceSelect: {
			marginTop: '2px'
		},
		genPass: {
			cursor: 'pointer'
		}
	})
);

export { useStyles };
