import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import { stringRequired, dateRequired, numberRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	employee: yup.string(),
	approvalStatus: yup.number(),
	startDate: yup.string(),
	endDate: yup.string(),
	newSheetEmployee: stringRequired,
	newSheetStartDate: stringRequired,
	newSheetDescription: stringRequired
});

export const defaultValues = {
	employee: '',
	approvalStatus: 6,
	startDate: '',
	endDate: '',
	newSheetEmployee: '',
	newSheetStartDate: '',
	newSheetDescription: ''
};
