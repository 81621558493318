// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		animationNone: {
			display: 'none',
			visibility: 'hidden',
			opacity: '0',
			height: 0,
			padding: '0 !important',
			transition: 'all 0.3s ease'
		},
		animation: {
			visibility: 'hidden',
			opacity: '0',
			height: 0,
			padding: '0 !important',
			transition: 'all 0.3s ease'
		},
		show: {
			display: 'block',
			visibility: 'visible',
			height: 'auto',
			padding: '40px 0px 40px 40px !important',
			opacity: 1
		},
		referenceInput: {
			marginTop: '4px'
		},
		referenceSelect: {
			marginTop: '2px'
		},
		genPass: {
			cursor: 'pointer'
		}
	})
);

export { useStyles };
