/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useRef, useLayoutEffect, FC, Dispatch, SetStateAction } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, BackPage, Dialog } from 'xpand-ui/core';
import { sizes } from 'xpand-ui/utils/handlers';
import { Checkbox } from 'xpand-ui/forms';

//* TYPINGS
import { Match } from 'typings/store/generalTypes';
import { IUserInfo } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { ITitle } from 'components/App/TitleProvider';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import CompanyAllocation from './forms/LeftCard/CompanyAllocation';
import AdminActions from './forms/LeftCard/AdminActions';
import Contacts from './forms/LeftCard/Contacts';
import UserPhoto from './forms/LeftCard/UserPhoto';
import PersonalInfo from './forms/RightCard/PersonalInfo';
import FiscalInfo from './forms/RightCard/FiscalInfo';
import BankInfo from './forms/RightCard/BankInfo';
import OtherInfo from './forms/RightCard/OtherInfo';
import { useStyles } from './styles';
import { ProfileProps } from '.';
import HealthCareConvocation from './forms/RightCard/HealthCareConvocation';
import { getLSField } from 'lib/utils/cookies';
import { useDispatch, useSelector } from 'react-redux';
import {
	acceptHealthCareConvocation,
	clearUserInfo,
	declineHealthCareConvocation,
	getUserProfile,
	sendUserUpdate
} from 'store/personalInfo/thunks';
import { setNotificationAsRead } from 'store/notifications/thunks';
import TermsAndConditions from './forms/RightCard/TermsAndConditions';

//* COMPONENT INTERFACES
interface IProfileProps extends ProfileProps {
	match: Match<{
		path?: string;
		username: string;
	}>;
	goToPage: (path: string) => void;
	title: ITitle;
	setTitle: Dispatch<SetStateAction<ITitle>>;
}
let prevProfile: any = null;

//* COMPONENT
const Profile: FC<IProfileProps> = ({ match, goToPage, title, setTitle }) => {
	const classes = useStyles();
	const { loading, error, userProfile, userPendingConvocationMessage, convocationMessageHasChanged } = useSelector(
		state => state.personalInfo
	);
	const company = useSelector(state => state.company);
	const dispatch = useDispatch();
	const consentsContainerRef = useRef<HTMLDivElement | any>(null);
	const { params } = match;

	const path = params?.path;
	const isAdmin = params?.username;

	const [formData, setFormData] = useState<IUserInfo>();
	const [acknowledgeModal, setAcknowledgeModal] = useState(false);
	const [hasUpdatedConsents, setHasUpdatedConsents] = useState(false);
	const [acknowledgedPS, setAcknowlegedPS] = useState<boolean>(false);
	const [openHealthConfirmationModal, setOpenHealthConfirmationModal] = useState(false);

	const clearUserInfoHandler = () => {
		if (isAdmin) {
			dispatch(clearUserInfo());
		}
		consentsContainerRef.current = null;
	};

	useEffect(
		() => () => {
			clearUserInfoHandler();
		},
		[isAdmin]
	);

	useEffect(() => {
		const initialFetch = !userProfile;
		const differentAdminUSer =
			userProfile?.user?.erpData?.searchKey.toLowerCase() || userProfile?.user?.ldapData?.username.toLowerCase();

		if (initialFetch || (isAdmin && isAdmin.toLowerCase() !== differentAdminUSer)) {
			if (isAdmin) {
				dispatch(getUserProfile(isAdmin));
			} else {
				dispatch(getUserProfile(''));
			}
		} else {
			setFormData(userProfile?.user);
			if (isAdmin === prevProfile) {
				setAcknowledgeModal(
					isAdmin || getLSField('impersonate_userInfo') ? false : !userProfile?.acknowlegedPrivacyStatement
				);
				setHasUpdatedConsents(isAdmin ? false : !userProfile?.hasUpdatedConsents);
				setOpenHealthConfirmationModal(isAdmin ? false : userProfile?.pendingConvocation != null);
			}
		}

		prevProfile = isAdmin;
	}, [userProfile, isAdmin]);

	useEffect(() => {
		if (isAdmin && userProfile) {
			const newTitleString = `${userProfile?.user?.ldapData?.firstName} ${userProfile?.user?.ldapData?.lastName}'s Profile`;

			newTitleString !== title.title &&
				setTitle({
					...title,
					title: newTitleString
				});
		}
	}, [userProfile, isAdmin, title]);

	useLayoutEffect(() => {
		if (
			!userProfile?.hasUpdatedConsents && // user did not update consents
			!hasUpdatedConsents && // user close the modal
			consentsContainerRef && // -----------
			consentsContainerRef.current // ------ the reference exists, then do the scroll
		) {
			consentsContainerRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}
		return () => {
			consentsContainerRef.current = null;
		};
	}, [hasUpdatedConsents]);

	const handleApiSubmit = (
		/* | IUpdateContacts
			| IUpdateCompanyAllocation
			| IUpdatePersonalInfo
			| IUpdatePrivateData
			| IUpdateBankInfo
			| IUpdateFiscalInfo
			| IUpdateReadConsents */
		payload: any
	) => {
		dispatch(sendUserUpdate(payload, isAdmin));
	};

	const submitAcknowlegedPrivacyStatement = () => {
		setAcknowledgeModal(false);
		handleApiSubmit([
			{
				type: 'privacyStatement',
				data: {
					status: acknowledgedPS
				}
			}
		]);
	};

	if (error) return handleErrorPage(error, clearUserInfoHandler);

	const isLoading = !userProfile || !formData;
	if (isLoading) return <LoadingOverlay />;
	return (
		<>
			{loading && <LoadingOverlay />}

			{isAdmin !== undefined && (
				<BackPage
					path={path && path === 'employees' ? '/admin/employees/list' : '/admin/admissions/resignations'}
					label={
						path && path === 'employees' ? 'Back to List of Employees' : 'Back to Resignation Management'
					}
					action={goToPage}
				/>
			)}
			<Grid container spacing={4} direction="row" justifyContent="space-between" alignItems="center">
				{(isAdmin !== undefined || match.path?.includes('personalFiscal')) && (
					<Grid item {...sizes[4]}>
						<Scrollbars
							autoHide
							style={{ height: 'calc(100vh - 130px)', overflowX: 'hidden' }}
							renderTrackHorizontal={(props: any) => (
								<div {...props} style={{ display: 'none' }} className="track-horizontal" />
							)}>
							<div className={classes.leftContainer}>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									spacing={4}
									className={classes.card}>
									{/* PHOTO && NAME && USERNAME */}
									<UserPhoto isAdmin={isAdmin} userProfile={userProfile} />
									{/* SEND WELCOME EMAIL & DELETE USER */}
									<AdminActions
										goToPage={goToPage}
										isAdmin={isAdmin}
										handleApiSubmit={handleApiSubmit}
										convocationsMessage={userPendingConvocationMessage}
										messageHasChanged={convocationMessageHasChanged}
										userProfile={userProfile}
										company={company}
									/>
									{/* EMAIL && PHONE's NUMBER */}
									<Contacts userProfile={userProfile?.user} handleApiSubmit={handleApiSubmit} />
									{/* BUSINESS UNIT && COMPANY */}
									<CompanyAllocation
										isAdmin={isAdmin}
										userProfile={userProfile}
										handleApiSubmit={handleApiSubmit}
									/>
								</Grid>
							</div>
						</Scrollbars>
					</Grid>
				)}
				{/* PERSONAL INFO PAGE */}
				{(isAdmin !== undefined || match.path?.includes('personalFiscal')) && (
					<Grid item {...sizes[8]}>
						<Scrollbars
							autoHide
							style={{ height: 'calc(100vh - 130px)', overflowX: 'hidden' }}
							renderTrackHorizontal={(props: any) => (
								<div {...props} style={{ display: 'none' }} className="track-horizontal" />
							)}>
							{userProfile?.user?.erpData && (
								<div className={classes.rightContainer}>
									{!isAdmin &&
										userProfile?.pendingConvocation &&
										match.path?.includes('personalFiscal') && (
											<HealthCareConvocation
												userProfile={userProfile}
												openHealthConfirmationModal={openHealthConfirmationModal}
												setOpenHealthConfirmationModal={setOpenHealthConfirmationModal}
												acceptHealthCareConvocation={acceptHealthCareConvocation}
												setNotificationAsRead={setNotificationAsRead}
												declineHealthCareConvocation={declineHealthCareConvocation}
											/>
										)}
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={3}>
										<>
											<PersonalInfo
												userProfile={userProfile}
												handleApiSubmit={handleApiSubmit}
												formData={formData}
											/>
											<FiscalInfo
												userProfile={userProfile}
												handleApiSubmit={handleApiSubmit}
												isAdmin={isAdmin}
											/>
											<BankInfo userProfile={userProfile} handleApiSubmit={handleApiSubmit} />
											<OtherInfo
												isAdmin={isAdmin}
												userProfile={userProfile}
												handleApiSubmit={handleApiSubmit}
											/>
										</>
										<div ref={consentsContainerRef} />
									</Grid>
								</div>
							)}
						</Scrollbars>
					</Grid>
				)}
				{/* CONSENT INFORMATION PAGE */}
				{(isAdmin !== undefined || !match.path?.includes('personalFiscal')) && (
					<Grid item {...sizes[12]}>
						<Scrollbars
							autoHide
							style={{ height: 'calc(100vh - 130px)', overflowX: 'hidden' }}
							renderTrackHorizontal={(props: any) => (
								<div {...props} style={{ display: 'none' }} className="track-horizontal" />
							)}>
							{userProfile?.user?.erpData && (
								<div className={classes.rightContainer}>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="flex-start"
										spacing={3}>
										<TermsAndConditions
											isAdmin={isAdmin}
											userProfile={userProfile}
											handleApiSubmit={handleApiSubmit}
										/>

										<div ref={consentsContainerRef} />
									</Grid>
								</div>
							)}
						</Scrollbars>
					</Grid>
				)}
			</Grid>
			<Dialog
				title="Acknowledge Privacy Statement"
				modal={{
					open: acknowledgeModal,
					handleClose: () => {},
					content: (
						<>
							<Typography gutterBottom align="justify">
								We ask you to carefully read the Employee Privacy Statement and to confirm your
								acknowledgement and comprehension in “My Profile” page. Afterwards you can give your
								consent about all the information you feel comfortable sharing.
							</Typography>
							<br />
							{company.urlConfluenceProfile && (
								<Typography gutterBottom align="justify">
									You can access the Employee Privacy Statement{' '}
									<a href={company.urlConfluenceProfile} rel="noreferrer" target="_blank">
										here.
									</a>
								</Typography>
							)}
							<br />
							<Checkbox
								label="I read and understood everything that was outlined in the Employee Privacy Statement."
								value={acknowledgedPS}
								additionalOnChange={setAcknowlegedPS}
							/>
						</>
					)
				}}
				actions={[
					{
						id: 'confirmDeleteAllCaches',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: submitAcknowlegedPrivacyStatement
					}
				]}
			/>
			<Dialog
				title="Update Consents"
				modal={{
					open: userProfile?.user?.erpData && hasUpdatedConsents && !acknowledgeModal,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setHasUpdatedConsents(false);
					},
					content: (
						<>
							<Typography gutterBottom align="justify">
								Hello! Before proceeding with your navigation, please update your consents so that we
								can keep giving you the full experience of {company.companyText}. Thank you.
							</Typography>
							<Typography gutterBottom align="justify">
								Note: An unchecked item means that you are not giving consent to the use of related
								information.
							</Typography>
							<br />
						</>
					)
				}}
				actions={[
					{
						id: 'confirmConsentsModal',
						label: 'Ok',
						color: 'primary',
						variant: 'contained',
						onClick: () => setHasUpdatedConsents(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(Profile);
