/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { OriginalResponse } from 'lib/handlers/requestTypes';
import { IChoosableBaseInfo, ICareerProfiles } from '../generalTypes';
import { IOrgAccesses } from './administration';
import { ISalesRepresentativesInfo } from './projectsAndClients';

export enum ProposalActions {
	SET_LOADING = '@@administration/proposals/SET_LOADING',
	SET_ERROR = '@@personalInfo/SET_ERROR',
	GET_PROPOSALS_LIST = '@@administration/proposals/GET_PROPOSALS_LIST',
	GET_USER_PROPOSALS_LIST = '@@administration/proposals/GET_USER_PROPOSALS_LIST',
	// PROPOSAL ACTIONS
	SET_PROPOSAL_SEND_EMAIL = '@@administration/proposals/SET_PROPOSAL_SEND_EMAIL',
	SET_POST_PROPOSAL_SEND_EMAIL = '@@administration/proposals/SET_POST_PROPOSAL_SEND_EMAIL',
	SET_PROPOSAL_ACCEPT = '@@administration/proposals/SET_PROPOSAL_ACCEPT',
	SET_PROPOSAL_REFUSE = '@@administration/proposals/SET_PROPOSAL_REFUSE',
	SET_PROPOSAL_CLOSE = '@@administration/proposals/SET_PROPOSAL_CLOSE',
	SET_PROPOSAL_CANCEL = '@@administration/proposals/SET_PROPOSAL_CANCEL',
	SET_PROPOSAL_REQUEST_APPROVAL = '@@administration/proposals/SET_PROPOSAL_REQUEST_APPROVAL',
	SET_PROPOSAL_APPROVE = '@@administration/proposals/SET_PROPOSAL_APPROVE',
	SET_PROPOSAL_REJECT = '@@administration/proposals/SET_PROPOSAL_REJECT',
	SET_PROPOSAL_START_CHANGE_DATE = '@@administration/proposals/SET_PROPOSAL_START_CHANGE_DATE',
	// PROPOSAL FORM GETS
	GET_NEW_PROPOSAL = '@@administration/proposals/GET_NEW_PROPOSAL',
	GET_NEW_PROPOSAL_WITH_TOKEN = '@@administration/proposals/GET_NEW_PROPOSAL_WITH_TOKEN',
	GET_EDIT_PROPOSAL = '@@administration/proposals/GET_EDIT_PROPOSAL',
	// PROPOSAL FORM SUBMITS
	SUBMIT_NEW_PROPOSAL = '@@administration/proposals/SUBMIT_NEW_PROPOSAL',
	SUBMIT_PUT_UPDATE_PROPOSAL = '@@administration/proposals/SUBMIT_PUT_UPDATE_PROPOSAL',
	// HELPERS
	SET_PROPOSAL_ANNUAL_GROSS_VALUE = '@@administration/proposals/SET_PROPOSAL_ANNUAL_GROSS_VALUE',
	SET_IRS_VALUE = '@@administration/proposals/SET_IRS_VALUE',
	SET_PROPOSAL_SUMMARY = '@@administration/proposals/SET_PROPOSAL_SUMMARY',
	SET_EXISTING_PROPOSAL_SUMMARY = '@@administration/proposals/SET_EXISTING_PROPOSAL_SUMMARY',
	CLEAR_PROPOSAL_SUMMARY = '@@administration/proposals/CLEAR_PROPOSAL_SUMMARY',
	SET_PROPOSAL_EXPORT = '@@administration/proposals/SET_PROPOSAL_EXPORT',
	SET_PROPOSAL_EXPORT_WITH_ID = '@@administration/proposals/SET_PROPOSAL_EXPORT_WITH_ID',
	RESET_FORM_DATA = '@@administration/proposals/RESET_FORM_DATA',
	SET_PROPOSAL_SUMMARY_LOADING = '@@administration/proposals/SET_PROPOSAL_SUMMARY_LOADING',
	// AUDIT LOG
	GET_PROPOSAL_AUDIT_LOG = '@@administration/proposals/GET_PROPOSAL_AUDIT_LOG'
}

export interface ProposalState {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;

	readonly proposalsList: IProposalsList | null;
	readonly userProposalsList: IUserProposalList | null;

	readonly newProposal: IGetNewProposal | null;

	readonly proposalsActions: OriginalResponse | null;

	readonly sendEmailPayload: ISendEmail | null;
	readonly proposalDownload: unknown | null;
	readonly proposalAnnualGross: IAnualGrossValueGet | null;
	readonly proposalSummary: IProposalSummaryPayload | null;
	readonly irsValue: IIrsValueGet | null;

	readonly proposalAuditLog: IProposalAuditLog | null;
	readonly isProposalSummaryloading: boolean;
}

export interface ICollaborationProposal {
	[index: string]: any;
	id: number;
	name?: string;
	additionalExpenses?: string | number;
	annualPrizeIRS: string | number;
	baseValue: string | number;
	budget: string | number;
	benefitsComments?: string;
	benefitsDescription1: string;
	benefitsDescription2: string;
	benefitsDescription3: string;
	benefitsValue1: string | number;
	benefitsValue2: string | number;
	benefitsValue3: string | number;
	businessUnit: string;
	careerAndFinancialNotes: string;
	careerJobRole: string;
	careerJobRoleLevel: string;
	careerManager: string;
	careerPosition: string;
	christhmasTwelfth: string | number;
	collaborationRole: string;
	collaborationType: string;
	collaborationTypeDescription: string;
	annualGrossValue: string | number;
	commsPlafond?: string | number;
	commsPlafondFlag: boolean;
	company: string;
	contractPosition?: string;
	contractType?: string;
	createPrizeBonusErp?: boolean;
	customerInContract?: string;
	department: string;
	dependentsNumber: string | number;
	disabled: boolean;
	division: string;
	eligiblePrizeValue?: string | number;
	email: string;
	employeeManager?: string;
	endDate?: string;
	estimuloMedida: boolean;
	employeeCategory: string;
	familyMembersComments?: string;
	foodAllowance: string | number;
	foodAllowanceDays: string | number;
	foodAllowanceType: string;
	formattedCollaborationStartDate: string;
	formattedEndDate: string;
	formattedProposalDate: string;
	grossBaseValue?: string | number;
	hasTwelfths: boolean;
	healthInsuranceCheck: boolean;
	healthInsuranceValue?: string | number;
	holdersNumber: string | number;
	holidayType: string;
	ihtPercentage?: string | number;
	ihtValue: string | number;
	includeFamilyMembers: boolean;
	internationalAllowance?: string | number;
	internationalAllowanceDays?: string | number;
	irsToApply: string | number;
	jiraCandidateApplication?: string;
	lifeInsurance?: string | number;
	maritalStatus: string;
	monthsApplicable?: string | number;
	nationalAllowance?: string | number;
	nationalAllowanceDays?: string | number;
	noTimeOffPolicy: boolean;
	numberMembersDep?: string | number;
	nurseryCheck?: string | number;
	otherbenefits?: boolean;
	otherBenefitsList?: IOtherBenefit[];
	primaryOffice?: string;
	prizeBonus?: string | number;
	prizeBonusCreateERP: boolean;
	prizeBonusGoals?: IPrizeGoal[];
	prizeBonusPercentage?: string | number;
	projects?: IProposalProject[];
	proposalDate: string;
	proposalOwner: string;
	proposalOwnerFirstName: string;
	proposalStatus: string | number;
	proposalStatusAsString: string;
	reference?: string;
	referralId?: string;
	referralType?: string;
	startDate: string;
	softwareDevelopmentCenter?: boolean;
	token: string | null | undefined;
	totalBenefitsList: string | number;
	totalComponentsPrizePercentage?: string;
	totalComponentsPrizeValue?: string;
	travelPass?: string | number;
	traineeType?: string;
	tsuExemption: boolean;
	vacationsTwelfth: string | number;
	workingOffice?: string;
	xtracker: boolean;
	bonusSalary: String | number;
	payBonusSalary: boolean;
}
export interface IReferenceData {
	id: string;
	startDate: string;
	xpastImpairmentvalue: string | number;
	xpastLowCostLimit: string | number;
	xphrAutonomousTaxPenalty: string | number;
	xphrCrFoodAllowLimit: string | number;
	xphrCrFoodAllowValue: number;
	xphrFctPercentage: string | number;
	xphrFgctPercentage: string | number;
	xphrFoodAllowLimit: string | number;
	xphrFoodAllowType: string;
	xphrFoodAllowValue: string;
	xphrFoodAllowanceDays: string | number;
	xphrInsuranceCompany: string;
	xphrInsuranceNumber: string;
	xphrLifeInsRedPercent: string | number;
	xphrMonStdComPlafond: string | number;
	xphrMonStdHeaInsCost: string | number;
	xphrMonStdLaptopCost: string | number;
	xphrMonStdWorkInsCost: string | number;
	xphrOccupHealthcareCost: string | number;
	xphrSocialSecurity: string;
	xphrSsTax: string;
	xpkmsKmamount: string | number;
}
export interface IChildNode {
	childNodes: IChildNode[];
	node: string;
	organization: string;
	organization$_identifier: string;
	reportSet: string;
}

export interface ICareerManagers extends IChoosableBaseInfo {
	xPHRAdOrgBunit: string;
}
export interface ICompanyInfo extends IChoosableBaseInfo {
	erpClientId: string;
	businessPartnerCategories?: IChoosableBaseInfo[];
	careerManagers?: ICareerManagers[];
	careerProfiles?: ICareerProfiles[];
	collaborationProposals?: ICollaborationProposal[];
	companyTree?: IChildNode;
	contractPositions?: IChoosableBaseInfo[];
	customers?: IChoosableBaseInfo[];
	referenceData?: IReferenceData;
	referralUsers?: IChoosableBaseInfo[];
	salePriceLists?: IChoosableBaseInfo[];
	salesRepresentatives?: ISalesRepresentativesInfo[];
	paymentTerms?: IChoosableBaseInfo[];
	projectTypes?: IChoosableBaseInfo[];
	linesOfBusiness?: IChoosableBaseInfo[];
}

export interface IProposalPayload
	extends IProposalGeneralPayload,
		IProposalCareerFinancialPayload,
		IProposalOtherBenefitsPayload,
		IProposalContractCompanyPayload {
	usersList?: any[];
	emailsList?: any[];
	admissionProcessSheetId?: string | number;
	annualGrossValue: string | number;
	careerAndFinancialNotes: string;
	companyName: string;
	companySocSec: string;
	employeeCategory: string;
	estimuloMedida: boolean;
	fullName: string;
	ihtValue: string | number;
	importFromAnotherProposal: boolean;
	internshipDescription: string;
	irsTwelfth: string | number;
	noTimeOffPolicy: boolean;
	password: string;
	projects: IProposalProject[] | null;
	proposalId: string;
	timeOffApprovalType: string;
	timeoffPolicy: string;
	token: string;
	username: string;
	vacationDays: string | number;
	formFields: any;
}

export interface IPostProposalPayload
	extends IProposalGeneralPayload,
		IProposalCareerFinancialPayload,
		IProposalOtherBenefitsPayload,
		IProposalContractCompanyPayload {
	annualGrossValue: string | number;
	careerAndFinancialNotes: string;
	company: string;
	ihtValue: string | number;
	importFromAnotherProposal: boolean;
	internshipDescription: string;
	irsTwelfth: string | number;
	projects: IProposalProject[];
	proposalId: string;
	token: string;
	vacationDays: string | number;
}

export interface IProposalGeneralPayload {
	collaborationType: string;
	name: string;
	email: string;
	jiraCandidateApplication?: string;
	startDate: string;
	company: string;
	department: string;
	division: string;
	businessUnit: string;
	collaborationRole: string;
}

export interface IProposalCareerFinancialPayload {
	// ------------------------------------------- CAREER & FINANCIAL
	// CAREER & FINANCIAL - CAREER INFO
	softwareDevelopmentCenter: boolean;
	careerPosition: string;
	careerJobRole: string;
	careerManager: string;
	careerJobRoleLevel: string;
	// CAREER & FINANCIAL - CAREER INFO
	holdersNumber: string | number;
	disabled: boolean;
	maritalStatus: string;
	tsuExemption: boolean;
	dependentsNumber: string | number;
	// CAREER & FINANCIAL - GENERAL AMOUNTS
	baseValue: string | number;
	budget: string | number;
	hourlyRate: string | number;
	irsToApply: string | number;
	ihtValue?: string | number;
	ihtPercentage: string | number;
	hasTwelfths: boolean;
	vacationsTwelfth?: string | number;
	christmasTwelfth: string | number;
	grossBaseValue: string | number;
	totalTaxableValue: string | number;
	foodAllowanceType: string;
	foodAllowance: string | number;
	foodAllowanceDays: string | number;
	nationalAllowance: string | number | null;
	nationalAllowanceDays: string | number;
	internationalAllowance: string | number | null;
	internationalAllowanceDays: string | number;
	travelPass: string | number;
	additionalExpenses: string | number;
	nurseryCheck: string | number;
	lifeInsurance: string | number;
	// CAREER & FINANCIAL - PRIZE BONUS
	createPrizeBonusErp: boolean;
	prizeBonus: string | number;
	prizeBonusPercentage: string | number;
	monthsApplicable: string | number;
	eligiblePrizeValue: string | number;
	prizeBonusGoals: IPrizeGoal[] | null;
	annualPrizeIRS: number | string;
	totalComponentsPrizePercentage: string;
	totalComponentsPrizeValue: string;
}

export interface IProposalOtherBenefitsPayload {
	commsPlafondFlag: boolean;
	commsPlafond: string | number | null;
	healthInsuranceCheck: boolean;
	healthInsuranceValue: string | number | null;
	includeFamilyMembers: boolean;
	numberMembersDep: string | number | null;
	familyMembersComments: string;
	otherbenefits: boolean;
	otherBenefitsList: IOtherBenefit[];
	benefitsComments: string | null;
}

export interface IProposalContractCompanyPayload {
	contractType: string;
	contractPosition: string;
	customerInContract: string | null;
	traineeType: string | null;
	endDate: string;
	primaryOffice: string;
	workingOffice: string;
	employeeManager: string;
	holidayType: string;
	referralType: string;
	reference: string | null;
	referralId: string;
}
export interface IProposalProject {
	id?: string | number;
	name?: string;
	projectId: string | number;
	projectName: string;
}

export interface IPrizeGoal {
	id?: string | number;
	uniqueId?: string;
	goalTypeId: string | number | null;
	goalDetails: string;
	prizePercentage: string | number;
	prizeValue: string | number;
}
export interface IOtherBenefit {
	id?: string | number;
	uniqueId?: string;
	goalDetails: string;
	prizeValue: string | number;
}

export interface IProposalSummaryPayload {
	summary: IProposalSummary;
	proposalStatus: number;
	prizeBonusGoalTypes: IChoosableBaseInfo;
	startDate: string; // FIXME!
	endDate: string; // FIXME!
	diffPercentageMontlyNet: string;
	diffPercentageTotalCost: String;
}

export interface IProposalPerToken {
	[token: string]: ICollaborationProposal[];
}

export interface IProposalsList {
	companies: ICompanyInfo[];
	proposalsPerToken: IProposalPerToken[];
}

export interface IBuManager {
	acknowlegedPrivacyStatement: boolean;
	acknowlegedNewRelease: boolean;
	hasUpdatedConsents: boolean;
	id: string | number;
	lastUpdateDate: string;
	displayName: string;
	orgAccesses: IOrgAccesses[];
	username: string;
}
export interface IUserProposalList {
	buManagers?: IBuManager[];
	companies?: ICompanyInfo[];
	userProposals?: ICollaborationProposal[];
}
export interface IProposalSummary {
	additionalHealthInsurance: string | number;
	annualGrossCost: string | number;
	annualGrossCostWithPrize: string | number;
	annualGrossCostWithPrizeAndNoBenefits: string | number;
	annualGrossValue: string | number;
	annualGrossValueWithBenefits: string | number;
	annualGrossValueWithPrize: string | number;
	annualGrossValueWithPrizeAndWithBenefits: string | number;
	annualIRSValue: string | number;
	annualIRSValueWithPrize: string | number;
	annualNetValue: string | number;
	annualNetValueWithPrize: string | number;
	annualPrizePercentageOfAnnualGrossValue: string | number;
	annualPrizeValue: string | number;
	annualPrizeValueWeight: string | number;
	autonomousTaxPenalty: string | number;
	baseAmountWithTwelfths: string | number;
	baseGrossIRSTaxValue: string | number;
	baseGrossSSTaxValue: string | number;
	baseValue: string | number;
	businessUnitName: string;
	budget: string | number;
	careerJobRoleLevelName: string;
	careerJobRoleName: string;
	careerManagerName: string;
	careerPositionName: string;
	christmasTwelfthIRSTaxValue: string | number;
	christmasTwelfthSSTaxValue: string | number;
	christmasTwelfthValue: string | number;
	collaborationRoleName: string;
	communicationsPlafond: string | number;
	companyName: string;
	companySocialSecurityMonthlyValue: string | number;
	companySocialSecurityPercentage: string | number;
	contractPositionName: string;
	contractTypeName: string;
	customerInContractName: string;
	dailyCost: string | number;
	dailyCostWithPrize: string | number;
	departmentName: string;
	dependentsNumber: string | number;
	divisionName: string;
	employeeManagerName: string;
	fctPercentage: string | number;
	fctValue: string | number;
	fgctPercentage: string | number;
	fgctValue: string | number;
	foodAllowance: string | number;
	foodAllowanceDays: string | number;
	foodAllowanceType: string;
	healthInsurance: string | number;
	holdersNumber: string | number;
	holidayTypeName: string;
	internationalAllowanceDailyValue: string | number;
	internationalAllowanceDays: string | number;
	internationalAllowanceMonthlyValue: string | number;
	irsTaxPercentage: string | number;
	irsTaxPrizeMonthPercentage: string | number;
	irsTaxTwelfthPercentage: string | number;
	laptopMonthlyValue: string | number;
	lifeInsuranceRedemptionTax: string | number;
	lifeInsuranceValue: string | number;
	monthlyAverageFoodAllowance: string | number;
	monthlyFoodAllowance: string | number;
	monthlyFoodAllowanceWorkInsuranceEstimateCost: string | number;
	monthlyGrossCost: string | number;
	monthlyGrossCostAnnualized: string | number;
	monthlyGrossCostWithPrize: string | number;
	monthlyGrossValue: string | number;
	monthlyGrossValue14Months: string | number;
	monthlyGrossValue12Months: string | number;
	monthlyGrossValueWithPrize14Months: string | number;
	monthlyGrossValueWithPrize: string | number;
	monthlyNetValue: string | number;
	monthlyNetValueWithPrize: string | number;
	monthlyStandardCardFoodAllowance: string | number;
	monthlyStandardCommunicationsPlafond: string | number;
	monthlyStandardFoodAllowance: string | number;
	monthlyStandardHealthInsuranceCost: string | number;
	monthlyStandardLaptopCost: string | number;
	monthlyStandardWorkInsuranceCostPercentage: string | number;
	monthlyWorkInsuranceEstimateCost: string | number;
	nationalAllowanceDailyValue: string | number;
	nationalAllowanceDays: string | number;
	nationalAllowanceMonthlyValue: string | number;
	nurseryCheck: string | number;
	occupationalHealthcareCost: string | number;
	otherBenefits1Description: string;
	otherBenefits1Value: string | number;
	otherBenefits2Value: string | number;
	otherBenefits3Value: string | number;
	otherExpenses: string | number;
	otherExpensesAutonomousTaxation: string | number;
	primaryOfficeName: string;
	socialSecurityPercentage: string | number;
	startDate: string;
	taxableBaseAmount: string | number;
	timeOffApprovalTypeName: string;
	timeOffPolicyName: string;
	totalBenefits: string | number;
	totalBenefitsAnnualValue: string | number;
	travelPass: string | number;
	tsuExemption: boolean;
	vacationsTwelfthIRSTaxValue: string | number;
	vacationsTwelfthSSTaxValue: string | number;
	vacationsTwelfthValue: string | number;
	workScheduleExemptionPercentage: string | number;
	workScheduleExemptionValue: string | number;
	workingOfficeName: string;
}

export interface IGetNewProposal {
	code?: string | number;
	proposal?: ICollaborationProposal;
	collaborationRoles: IChoosableBaseInfo[];
	companies: ICompanyInfo[];
	contractTypes: IChoosableBaseInfo[];
	foodAllowanceTypes: IChoosableBaseInfo[];
	holidayTypes: IChoosableBaseInfo[];
	maritalStatus: IChoosableBaseInfo[];
	previousFreeReferences: string[];
	primaryWorkPlaces: IChoosableBaseInfo[];
	timeOffApprovalTypes: IChoosableBaseInfo[];
	timeOffPolicies: IChoosableBaseInfo[];
	prizeBonusGoalTypes: IChoosableBaseInfo[];
	projects: IChoosableBaseInfo[];
	referralTypes: IChoosableBaseInfo[];
	workPlaces: IChoosableBaseInfo[];
	xtrackerEmployees: IChoosableBaseInfo[];
	contactListLevels: IChoosableBaseInfo[];
	defaultProjects: Record<string, string[]>;
	defaultDivisionProjects: Record<string, string[]>;
}
export interface ISendEmail {
	cc: string;
	from: string;
	mailBody: string;
	subject: string;
	to: string;
}
export interface IAnualGrossValuePost {
	additionalExpenses: string | number;
	baseValue: string | number;
	foodAllowance: string | number;
	foodAllowanceDays: string | number;
	foodAllowanceType: string;
	hasTwelfths: boolean;
	ihtValue: string | number;
	internationalAllowance: string | number;
	internationalAllowanceDays: string | number;
	lifeInsurance: string | number;
	nationalAllowance: string | number;
	nationalAllowanceDays: string | number;
	nurseryCheck: string | number;
	travelPass: string | number;
}
export interface IAnualGrossValueGet {
	annualGrossValue: string | number;
}

export interface IIrsValuePost {
	baseValue: string | number;
	company: string;
	dependentsNumber: string | number;
	disabled: boolean;
	foodAllowance: string | number;
	foodAllowanceType: string;
	grossBaseValue: string | number;
	holdersNumber: string | number;
	lifeInsurance: string | number;
	maritalStatus: string;
	prizeValue: string | number;
	workScheduleExemptionValue: string | number;
	taxZone: string | undefined;
	startDate: string | undefined;
}
export interface IIrsValueGet {
	irs: string | number;
	irsPrize: string | number;
	irsTwelfth: string | number;
}

export interface IDownloadProposal
	extends IProposalGeneralPayload,
		IProposalCareerFinancialPayload,
		IProposalOtherBenefitsPayload,
		IProposalOtherBenefitsPayload,
		IProposalContractCompanyPayload,
		IProposalSummary {
	benefitsValue1: string | number;
	benefitsValue2: string | number;
	benefitsValue3: string | number;
	careerAndFinancialNotes: string;
	christhmasTwelfth: string | number;
	christmasTwelfthValue: string | number;
	collaborationTypeDescription: string;
	estimuloMedida: boolean;
	formattedCollaborationStartDate: string;
	formattedEndDate: string;
	formattedProposalDate: string;
	id: string | number;
	importFromAnotherProposal: boolean;
	irsTwelfth: string | number;
	noTimeOffPolicy: boolean;
	prizeBonusCreateERP: false;
	prizeBonusGoalTypes?: IChoosableBaseInfo[];
	projects: IProposalProject[];
	proposalDate: string;
	proposalId: string;
	proposalOwner: string;
	proposalOwnerFirstName: string;
	proposalStatus: string | number;
	proposalStatusAsString: string;
	token: string;
	xtracker: boolean;
}

export interface IRejectProposalPost {
	rejectionReason: string;
}

export interface IProposalFormCareerHelper {
	company?: string | null;
	businessUnit?: string | null;
	division?: string | null;
	department?: string | null;
	startDate?: string | null;
	careerPosition?: string | null;
	careerJobRole?: string | null;
	careerJobRoleLevel?: string | null;
	holdersNumber?: string | null;
}

export interface IAuditLogEntry {
	date: string;
	id: number;
	newStatus: string;
	newStatusId: number;
	previousStatus?: string;
	previousStatusId: number;
	proposalId: number;
	username: string;
}

export interface IProposalAuditLog {
	auditLog: IAuditLogEntry[];
}
