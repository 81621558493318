// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		animation: {
			visibility: 'hidden',
			opacity: '0',
			height: 0,
			padding: '0 !important',
			transition: 'all 0.3s ease'
		},
		show: {
			visibility: 'visible',
			height: 'auto',
			padding: '40px 0px 40px 40px !important',
			opacity: 1
		},
		tabInfo: {
			padding: '0 7% 0 5%',
			textAlign: 'left',
			marginTop: '-40px',
			marginBottom: '40px'
		},
		inputBelowCheck: {
			height: '70px',
			margin: '-16px'
		},
		hiddenArea: {
			margin: '0 -16px -16px -16px',
			display: 'flex',
			flexDirection: 'column'
		}
	})
);

export { useStyles };
