/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';

//* EXTERNAL LIBS --> MUI
import { Add } from '@mui/icons-material';
import { Grid, Typography, Button, TextField, Input as CoreInput, FormHelperText } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Adicionar from '../../../../assets/icons/Adicionar.svg';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table } from 'xpand-ui/core';
import { PageTitle, Dialog } from 'xpand-ui/core';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import { START_API_PATH } from 'lib/utils/constants';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { ListEmployeesProps } from '.';
import { Refresh } from '@mui/icons-material';
import { getLSField } from 'lib/utils/cookies';
import { schema, defaultValuesConvocations } from './yupSchema';
import { Select, InfoField, InputLabel } from 'xpand-ui/forms';
import { IUserProfile } from 'typings/store/personalInfoTypes';
import { clearConvocationMessageConfig } from 'store/personalInfo/actions';
import { addNotification } from 'lib/utils/notifications';
import { Roles } from 'lib/roles/index';

//* COMPONENT INTERFACES
interface IListEmployees extends ListEmployeesProps {
	isAdmin: string;
	goToPage: (path: string) => void;
	userProfile: IUserProfile;
}
interface IConfirmModal {
	type: string;
	title: string;
	message?: string | ReactNode;
	payload?: any;
}
interface IUserPermissions {
	id: number;
	displayName: string;
}

const notificationPayload = {
	area: 'My Profile',
	section: 'Personal & Fiscal Information'
};

//* COMPONENT
const ListEmployees: FC<IListEmployees> = ({
	users,
	filters,
	goToPage,
	getUsersList,
	clearUsersError,
	setListEmployeesPageFilter,
	sendDeleteSingleCacheByName,
	clearCache,
	submitHealthConvocation,
	setNewNotification,
	getHealthLocations,
	getHealthConvocationMessage,
	isAdmin,
	userProfile,
	personalInfo
}) => {
	const classes = useStyles();
	const { usersList, loading, error } = users;
	const { userPendingConvocationMessage, convocationMessageHasChanged } = personalInfo;
	const { listEmployeesFilter } = filters;

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(listEmployeesFilter);
	const [usernameConvocation, setUsernameConvocation] = useState('');
	const [confirmModal, setConfirmModal] = useState<IConfirmModal | null>(null);
	const [userToRefresh, setUserToRefresh] = useState<any>(null);
	const [openHealthModal, setOpenHealthModal] = useState(false); //
	const [convocationLocations, setConvocationLocations] = useState<
		{
			id: string;
			startDate: string;
			location: string;
			address: string;
			link: string;
			linkEmbed: string;
			dateIsValid: boolean;
		}[]
	>([
		{
			id: '',
			startDate: '',
			location: '',
			address: '',
			link: '',
			linkEmbed: '',
			dateIsValid: false
		}
	]);
	const [temporaryFileHolder, setTemporaryFileHolder] = useState([]);
	const [hasChanged, setHasChanged] = useState(false);
	let formData: FormData = new FormData();
	const [isFileSizeOk, setIsFileSizeOk] = useState<boolean>(true);
	const [hasFilesChanged, setHasFileChanged] = useState(false);
	const [hasDoneFirstLoading, setHasDoneFirstLoading] = useState(false);
	const MaxFileSize = 5 * 1000 * 1000; // 5Mb

	// Update filters whenever necessary
	useEffect(() => {
		setListEmployeesPageFilter(searchValue);
	}, [searchValue]);

	useEffect(() => {
		if (!error && !usersList) getUsersList();
	}, []);

	useEffect(() => {
		if (!usersList) getUsersList();
	}, [usersList]);

	//Forces the update of the components using location information
	let locationInfo = convocationLocations;
	useEffect(() => {
		if (hasChanged) {
			locationInfo = convocationLocations;
			setHasChanged(false);
		}
	}, [hasChanged]);

	//Forces the update of the components using files information
	let allFiles = temporaryFileHolder;
	useEffect(() => {
		if (hasFilesChanged) {
			allFiles = temporaryFileHolder;
			setHasFileChanged(false);
		}
	}, [hasFilesChanged]);

	useEffect(() => {
		if (hasDoneFirstLoading && convocationMessageHasChanged) {
			if (userPendingConvocationMessage == null) {
				getHealthLocations(); //
				reset(defaultValuesConvocations);
				setOpenHealthModal(true); //
				setHasDoneFirstLoading(false);
				clearConvocationMessageConfig();
			} else {
				addNotification('info', userPendingConvocationMessage);
				setHasDoneFirstLoading(false);
				clearConvocationMessageConfig();
			}
		}
	}, [convocationMessageHasChanged]);

	const hasPermission = (permission: string) => {
		const userPermissionsStorage = getLSField('userPermissions');
		const userPermissions: IUserPermissions[] =
			(userPermissionsStorage && JSON.parse(userPermissionsStorage)) || null;
		let result: boolean = false;
		if (userPermissions != null) {
			userPermissions.forEach(userPermission => {
				if (userPermission.displayName === permission) {
					result = true;
				}
			});
		}
		return result;
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				isSortable: false,
				format: ({ username }) => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${username}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{
				label: 'User',
				id: 'username',
				accentColumn: true,
				format: (row, text: string) => (text ? text.toUpperCase() : '')
			},
			{ label: 'Name', id: 'fullName', width: '17%', maxWidth: '17%', accentColumn: true },
			{ label: 'Company', id: 'companyAzureDescription' },
			{ label: 'Business Unit', id: 'department', maxWidth: '10%' },
			{ label: 'Email', id: 'emailLink', isSortable: false }
		],
		[]
	);

	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'someMenu',
				type: 'menu',
				render: () => true,
				options: hasPermission(Roles.CP_ROLE_SYSTEM_ADMIN)
					? [
							{
								id: 'profilePage',
								label: 'Employee Profile',
								to: ({ username }) => `/cp/admin/employees/profile/employees/${username}`,
								onClick: ({ username }) => goToPage(`/admin/employees/profile/employees/${username}`)
							},
							{
								id: 'userRefresh',
								label: 'Refresh User Data',
								onClick: ({ username }) => {
									setUserToRefresh(username);
									setConfirmModal({
										type: 'REFRESH_USER_DATA',
										title: 'Refresh User Data',
										message: <>Are you sure you want to refresh this user's data?</>
									});
								}
							},
							{
								id: 'sendHealthEmail',
								label: 'Send Health Care E-mail',
								onClick: ({ username }) => {
									setUsernameConvocation(username);
									getHealthConvocationMessage(username);
									setHasDoneFirstLoading(true);
								}
							}
					  ]
					: hasPermission(Roles.CP_ROLE_LOGISTICS)
					? [
							{
								id: 'sendHealthEmail',
								label: 'Send Health Care E-mail',
								onClick: ({ username }) => {
									setUsernameConvocation(username);
									getHealthConvocationMessage(username);
									setHasDoneFirstLoading(true);
								}
							}
					  ]
					: [
							{
								id: 'profilePage',
								label: 'Employee Profile',
								to: ({ username }) => `/cp/admin/employees/profile/employees/${username}`,
								onClick: ({ username }) => goToPage(`/admin/employees/profile/employees/${username}`)
							},
							{
								id: 'userRefresh',
								label: 'Refresh User Data',
								onClick: ({ username }) => {
									setUserToRefresh(username);
									setConfirmModal({
										type: 'REFRESH_USER_DATA',
										title: 'Refresh User Data',
										message: <>Are you sure you want to refresh this user's data?</>
									});
								}
							}
					  ]
			}
		],
		[]
	);

	const tablePayload = useMemo(
		() =>
			usersList &&
			usersList.map(e => ({
				...e,
				id: e.username,
				fullName: `${e.firstName} ${e.lastName}`,
				emailLink: (
					<Button
						size="large"
						variant="text"
						color="primary"
						target="_top"
						rel="noopener noreferrer"
						href={`mailto:${e.email}`}>
						<Typography variant="button">{e.email}</Typography>
					</Button>
				)
			})),
		[usersList]
	);

	const pageTitleActions = useMemo(
		() => [
			{
				id: 'deleteAllUsersCaches',
				onClick: async () => {
					sendDeleteSingleCacheByName('USERS_Employees');
				},
				icon: <Refresh />,
				label: 'Force Users List Cache Refresh',
				disabled: getLSField('impersonate_userInfo')
			}
		],
		[]
	);

	const {
		handleSubmit,
		control,
		reset,
		setValue,
		getValues,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues: defaultValuesConvocations,
		shouldUnregister: false
	});

	//Actions used in health convocation modal  //
	const healthCareActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setOpenHealthModal(false);
				reset(defaultValuesConvocations);
				setConvocationLocations([
					{ id: '', startDate: '', location: '', address: '', link: '', linkEmbed: '' }
				]);
				setTemporaryFileHolder([]);
			}
		},
		{
			id: 'submit',
			label: 'Submit',
			color: 'primary',
			type: 'submit',
			form: 'form-health-care',
			variant: 'contained',
			onClick: () => ({})
		}
	];

	//Creates a formData with the files, the username and the locations to create a health convocation
	//After that it will clear the form
	const onSubmit = () => {
		for (let file of temporaryFileHolder) {
			formData.append('files', file.file);
		}
		formData.append('username', usernameConvocation);
		formData.append('locations', JSON.stringify(convocationLocations));
		submitHealthConvocation(formData);
		setNewNotification(usernameConvocation, notificationPayload);
		//Closes modal and resets data
		setOpenHealthModal(false);
		reset(defaultValuesConvocations);
		setConvocationLocations([{ id: '', startDate: '', location: '', address: '', link: '', linkEmbed: '' }]);
		setTemporaryFileHolder([]);
		setUsernameConvocation('');
	};

	//Adds ordinal suffix to number of the moment
	const ordinalSuffix = (i: number) => {
		let j = i % 10,
			k = i % 100;
		if (j == 1 && k != 11) {
			return i + 'st';
		}
		if (j == 2 && k != 12) {
			return i + 'nd';
		}
		if (j == 3 && k != 13) {
			return i + 'rd';
		}
		return i + 'th';
	};

	//Removes a moment from the form
	const removeMoment = (index: number) => {
		setConvocationLocations(convocationLocations.filter((e, i) => i !== index));
		let formLocations = getValues('locations');
		setValue(
			`locations`,
			formLocations.filter((e, i) => i !== index)
		);
	};

	//Add a location to the form
	const addLocation = () => {
		// add new item on the list with an default value
		const locationsPayload = [...getValues('locations'), { startDate: null, location: null }];

		// updates the array of the form
		setValue('locations', locationsPayload);

		//updates the main array with all the locations
		setConvocationLocations([
			...convocationLocations,
			{ id: '', startDate: '', location: '', address: '', link: '', linkEmbed: '' }
		]);
	};

	//Adds the file to the respective array and checks if it has more than 4mb
	const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileSize = !!event.target.files && event.target.files[0].size;
		setIsFileSizeOk(fileSize <= MaxFileSize); // 4Mb
		if (fileSize <= MaxFileSize) {
			setValue(`hasFiles`, true);
			setTemporaryFileHolder([
				...temporaryFileHolder,
				{ name: event.target.files[0].name, file: event.target.files[0] }
			]);
			setHasFileChanged(true);
		}
	};

	//Removes a file from the form
	const removeFile = (index: number) => {
		setTemporaryFileHolder(temporaryFileHolder.filter((e, i) => i !== index));
		if (temporaryFileHolder.filter((e, i) => i !== index).length <= 0) {
			setValue('hasFiles', undefined);
		}
	};

	if (error) return handleErrorPage(error, clearUsersError);

	const isLoading = usersList === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle actions={pageTitleActions} />
			<Table
				tableData={tablePayload}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="username"
				handleSearch={{ searchValue, setSearchValue }}
			/>

			<Dialog
				maxWidth="xs"
				modal={{
					open: Boolean(confirmModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmModal(null);
					},
					content: <Typography gutterBottom>{confirmModal?.message}</Typography>
				}}
				title={confirmModal?.title}
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setConfirmModal(null)
					},
					{
						id: 'confirmDeleteAllCaches',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							setConfirmModal(null);
							if (userToRefresh !== null) {
								clearCache(userToRefresh);
							}
						}
					}
				]}
				scroll="body"
			/>

			<Dialog //
				title="Send Health Care E-mail"
				actions={healthCareActions}
				scroll="body"
				modal={{
					open: Boolean(openHealthModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setOpenHealthModal(false);
							reset(defaultValuesConvocations);
							setConvocationLocations([
								{
									id: '',
									startDate: '',
									location: '',
									address: '',
									link: '',
									linkEmbed: ''
								}
							]);
							setTemporaryFileHolder([]);
						}
					},
					content: (
						<form id="form-health-care" onSubmit={handleSubmit(onSubmit)}>
							<Grid container spacing={2}>
								{locationInfo.length > 0 && (
									<>
										{locationInfo.map(
											(e, index) =>
												e !== null && (
													<>
														<Grid item xs={12}>
															<Grid container>
																<Grid item>
																	<InputLabel style={{ marginTop: '10px' }}>
																		{ordinalSuffix(index + 1)} Moment
																	</InputLabel>
																</Grid>
																{index >= 1 && (
																	<Grid item>
																		<Button
																			color="primary"
																			variant="text"
																			endIcon={<DeleteIcon />}
																			onClick={() => {
																				removeMoment(index);
																			}}></Button>
																	</Grid>
																)}
															</Grid>
														</Grid>
														<Grid item xs={12}>
															<DateTimePicker
																format="DD/MM/YYYY HH:mm"
																disablePast={true}
																ampm={false}
																label="Start Date"
																value={getValues(`locations.${index}.startDate`)}
																onChange={e => {
																	let modifiedDate =
																		moment(e).format('DD-MM-YYYY HH:mm:ss');

																	let updatedList = convocationLocations;
																	updatedList[index].startDate = modifiedDate;

																	if (e) {
																		let date: Date = e.toDate();
																		updatedList[index].dateIsValid =
																			date > new Date();
																	} else {
																		updatedList[index].dateIsValid = false;
																	}

																	setConvocationLocations(updatedList); // set state to new object with updated list
																	setValue(`locations.${index}.startDate`, e);

																	setHasChanged(true);
																}}
																renderInput={params => {
																	return (
																		<TextField
																			{...params}
																			inputProps={{
																				...params.inputProps
																			}}
																		/>
																	);
																}}
															/>
															{!convocationLocations[index].dateIsValid &&
																errors.hasOwnProperty('locations') && (
																	<FormHelperText className={classes.errorInputLabel}>
																		This field is mandatory
																	</FormHelperText>
																)}
														</Grid>

														<Grid item xs={12}>
															<Select
																required
																name={`locations.${index}.location`}
																label="Location"
																options={
																	users.healthLocations?.map((e: any) => ({
																		id: e.id,
																		label: e.name
																	})) || []
																}
																additionalOnChange={(item: any) => {
																	let locInfo = users.healthLocations?.find(
																		e => e.id === item.value
																	);

																	let updatedList = convocationLocations;
																	updatedList[index].address = locInfo.address;
																	updatedList[index].link = locInfo.googleMapsLink;
																	updatedList[index].location = locInfo.name;
																	updatedList[index].linkEmbed =
																		locInfo?.googleMapsEmbedLink;
																	setConvocationLocations(updatedList); // set state to new object with updated list
																	setHasChanged(true);
																}}
																control={control}
																errors={errors}
																hasSearchOption={true}
															/>
															{e.location !== '' && (
																<Grid
																	container
																	spacing={2}
																	style={{ marginTop: '10px' }}>
																	<Grid item xs={12}>
																		<InfoField label="" value="Address:" />
																		<InputLabel>{e.address}</InputLabel>
																	</Grid>
																	<Grid item xs={12}>
																		<InputLabel>
																			<a href={e.link} target="_blank">
																				Google Maps Link
																			</a>
																		</InputLabel>
																	</Grid>
																</Grid>
															)}
														</Grid>
													</>
												)
										)}
									</>
								)}
								<Grid item xs={12}>
									<Button
										color="primary"
										variant="text"
										endIcon={<Adicionar />}
										onClick={() => {
											addLocation();
										}}>
										Add New Moment
									</Button>
								</Grid>
								<Grid item xs={12}>
									<InfoField label="" value="Select the convocation files" />
									<CoreInput
										onChange={changeHandler}
										name="health_files"
										type="file"
										inputProps={{ accept: 'application/pdf' }}
									/>
									{errors.hasOwnProperty('hasFiles') && (
										<FormHelperText className={classes.errorInputLabel}>
											This field is mandatory
										</FormHelperText>
									)}
									{!isFileSizeOk && (
										<InfoField
											className={classes.errorInputLabel}
											label=""
											value="The selected file exceeds the maximum file size"
										/>
									)}
								</Grid>
								<Grid item xs={12}>
									<>
										{allFiles.map((e, index) => {
											return (
												<Grid container>
													<Grid item>
														{' '}
														<InfoField label="" value={e.name} />
													</Grid>
													<Grid item>
														<Button
															color="primary"
															variant="text"
															endIcon={<DeleteIcon />}
															onClick={() => {
																removeFile(index);
															}}></Button>
													</Grid>
												</Grid>
											);
										})}
									</>
								</Grid>
							</Grid>
						</form>
					)
				}}
			/>
		</>
	);
};

export default withLayout(ListEmployees);
