import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		display: 'flex',
		padding: '50px 175px',
		minWidth: '100%',
		alignItems: 'center',
		flexDirection: 'column',
		margin: 'auto'
	},
	feedbackCard: {
		boxShadow: theme.boxShadow,
		borderRadius: '5px',
		padding: '15px'
	}
}));

export { useStyles };
