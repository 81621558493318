import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	totalHours: {
		width: '-webkit-fill-available',
		background: '#F8F9FC', //
		borderRadius: 10,
		padding: '10px'
	},
	availableCredits: {
		color: theme.accentColor,
		fontSize: '24px',
		fontWeight: 'bold'
	},
	creditNumber: {
		fontSize: '24px',
		fontWeight: 'lighter'
	},
	calendarNavs: { fontSize: '22px' },
	statusBar: {
		// direction: 'row',
		justify: 'center',
		alignItems: 'center',
		display: 'flex'
	},
	statusIcon: {
		marginRight: '10px'
	},
	centerInParent: {
		margin: 'auto',
		display: 'block'
	},
	labelTitles: {
		fontSize: '14px',
		fontWeight: 'bold',
		marginRight: '15px',
		color: '#3D464D'
	},
	labelValue: {
		fontSize: '14px',
		marginRight: '15px',
		color: '#3D464D'
	},
	labelTitles2: {
		fontSize: '14px',
		fontWeight: 'bold',
		color: '#3F80EF !important',
		marginInline: '15px'
	},
	labelValue2: {
		fontSize: '14px',
		color: '#3F80EF !important'
	},
	expenseNameCell: {
		overflow: 'hidden',
		display: '-webkit-box',
		WebkitLineClamp: 4,
		WebkitBoxOrient: 'vertical',
		textOverflow: 'ellipsis'
	},
	projectNameCell: {
		overflow: 'hidden',
		display: '-webkit-box',
		WebkitLineClamp: 4,
		WebkitBoxOrient: 'vertical',
		textOverflow: 'ellipsis'
	},
	descriptionCell: {
		overflow: 'hidden',
		display: '-webkit-box',
		WebkitLineClamp: 4,
		WebkitBoxOrient: 'vertical',
		textOverflow: 'ellipsis'
	},
	tooltip: {
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.primary,
		fontSize: theme.typography.pxToRem(14),
		border: '1px solid #dadde9',
		maxWidth: '400px', // Increased max width for tooltip
		whiteSpace: 'normal',
		wordWrap: 'break-word',
		padding: theme.spacing(1)
	},
	numericCustomColor: {
		'& div': {
			'& > input': {
				color: '#3F80EF !important'
			}
		}
	}
}));

export { useStyles };
