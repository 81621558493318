//* EXTERNAL LIBS
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter, Dialog } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { Checkbox } from 'xpand-ui/forms';

//* TYPINGS
import { IDeactivateFormPayload } from 'typings/store/admin/admissions';
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { NewAdmissionProps } from '.';
import { addNotification } from 'lib/utils/notifications';
import { getLSField } from 'lib/utils/cookies';

interface INewAdmission extends NewAdmissionProps {
	match: Match<{
		userId: string;
	}>;
	goToPage: (path: string) => void;
}

const Deactivate: FC<INewAdmission> = ({
	goToPage,
	match,
	admissions,
	getDeactivateForm,
	submitDeactivateForm,
	resetResginAction
}) => {
	const classes = useStyles();
	const { params } = match;
	const userId = Number(params?.userId) || null;
	const [confirmModal, setConfirmModal] = useState<boolean>(false);
	const { loading, deactivateForm, deactivateAction } = admissions;

	const {
		handleSubmit,
		control,
		watch,
		reset,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(
		() => () => {
			resetResginAction();
		},
		[]
	);

	useEffect(() => {
		if (!deactivateForm && userId && !deactivateAction) {
			getDeactivateForm(userId);
		}
	}, [deactivateForm]);

	useEffect(() => {
		if (deactivateAction && deactivateAction.code === 200) {
			if (deactivateAction?.payload?.data?.managedEmployeesMessage) {
				addNotification('warning', deactivateAction?.payload?.data?.managedEmployeesMessage);
			}
			if (deactivateAction?.payload?.data?.roleMessage) {
				addNotification('warning', deactivateAction?.payload?.data?.roleMessage);
			}
			if (deactivateAction?.payload?.data?.timesheetsMessage) {
				addNotification('warning', deactivateAction?.payload?.data?.timesheetsMessage);
			}
			resetResginAction();
			goToPage('/admin/admissions/resignations');
		}
	}, [deactivateAction]);

	const onSubmit = (data: IDeactivateFormPayload) => {
		if (userId) {
			const payload = data;
			submitDeactivateForm(userId, payload);
			reset(defaultValues);
		}
	};

	const confirmModalActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setConfirmModal(false)
		},
		{
			id: 'submit',
			label: 'Submit',
			color: 'primary',
			variant: 'contained',
			onClick: () => setConfirmModal(false),
			type: 'submit',
			form: 'form-deactivate-user'
		}
	];
	const footerActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => goToPage('/admin/admissions/resignations')
		},
		{
			id: 'submit',
			endIcon: 'submit',
			label: 'Submit',
			onClick: () => setConfirmModal(true),
			variant: 'contained',
			disabled: getLSField('impersonate_userInfo')
		}
	];

	const isLoading = deactivateForm === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<Dialog
				title="Confirm Deactivation"
				scroll="body"
				modal={{
					open: confirmModal,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmModal(false);
					},
					content: 'Are you sure you want to deactivate this employee?'
				}}
				actions={confirmModalActions}
			/>
			<div className={classes.root}>
				<div className={classes.userInfo}>
					<Typography className={classes.name}>{deactivateForm.user.name || 'N/A'}</Typography>
					<Typography className={classes.contract}>
						{deactivateForm.user.resignationData.endReason || 'N/A'}
					</Typography>
					<Typography className={classes.dateLabel}>
						<span className={classes.date}>CONTRACT END DATE: </span>
						{(deactivateForm.user.resignationData.contractEndDate &&
							parseDateToShow(deactivateForm.user.resignationData.contractEndDate)) ||
							' N/A'}
					</Typography>
				</div>
				<form id="form-deactivate-user" onSubmit={handleSubmit(() => onSubmit(watch()))}>
					<Grid container spacing={4}>
						<Grid item {...sizes[8]}>
							<Checkbox
								color="primary"
								name="deactivateInErp"
								label="Deactivate In Erp"
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[8]}>
							<Checkbox
								color="primary"
								name="deactivateInXtracker"
								label="Deactivate In Xtracker"
								control={control}
								errors={errors}
							/>
						</Grid>
					</Grid>
				</form>
			</div>
			<ActionFooter actions={footerActions} />
		</>
	);
};

export default withLayout(Deactivate);
