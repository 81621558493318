import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'typings/state';
import { connect } from 'react-redux';

import {
	getEmailTemplates,
	sendPostChangeEmailTemplateStatus,
	sendPostNewEmailTemplate,
	clearAdministrationError,
	sendPostTestEmailTemplate
} from 'store/administration/administration/thunks';
import { setEmailTemplatesPageFilter } from 'store/filters/actions';

import EmailTemplates from './EmailTemplates';

const mapStateToProps = ({ administration, filters }: AppState) => ({ administration, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getEmailTemplates,
			sendPostChangeEmailTemplateStatus,
			sendPostNewEmailTemplate,
			clearAdministrationError,
			setEmailTemplatesPageFilter,
			sendPostTestEmailTemplate
		},
		dispatch
	);

export type EmailTemplatesProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);
