import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import { string, booleanRequired, dateDefault } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	startDate: dateDefault.max(yup.ref('startDate'), ({ max }) =>
		moment(max).isValid() ? `Start date to be before ${parseDateToShow(max as string)}` : 'Invalid Start Date'
	),
	endDate: dateDefault.min(yup.ref('endDate'), ({ min }) =>
		moment(min).isValid() ? `End date to be after ${parseDateToShow(min as string)}` : 'Invalid End Date'
	),
	offices: yup.array().of(string).nullable(),
	projects: yup.array().of(string).nullable(),
	users: yup.array().of(string).nullable(),
	skills: yup.array().of(string).nullable(),
	showAvailableOnly: booleanRequired,
	showTimeOff: booleanRequired
});

export const defaultValues = {
	startDate: '',
	endDate: '',
	offices: [] as string[],
	projects: [] as string[],
	users: [] as string[],
	skills: [] as number[],
	showAvailableOnly: false,
	showTimeOff: true
};
