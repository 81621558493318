export const AZURE_CLIENT = 'e2a8675e-1866-4cda-94a8-e503f0c50522';
export const AZURE_TENENT = 'd6116112-786c-4102-8fa9-ed8eceb61a77';
export const START_API_PATH = '/cp-dev-api-4-0-0';
export const END_API_PATH = '';
export const RELEASE_VERSION = '5.0.0';
export const INSTANCE = 'XPAND';
export const XPAND_EMPLOYEE_ID = 'A6BFB641EE5A4B62BC4898C2BF188113';
export const XPAND_EMPLOYEE_ID_HR = 'CFF53A09D26F423296604E74E0FC60B5';
export const XPAND_EMPLOYEE_ID_UK = 'D25635BD9E5D4B7FB4235CBCB14B72E7';
export const XPAND_EMPLOYEE_ID_SE = 'B5CBFCA088D544E18237722451E6D2D0';
export const XPAND_EMPLOYEE_ID_DE = 'A00BA47575624CB0AC3C8CE7A75442DD';
export const XPAND_CONTRACTOR_ID = '35944569BD0342B2B0A456C407F6EB85';
export const XPAND_CONTRACTOR_ID_SE = '4BEAD68CEC17470A82ED4283753F7538';
export const XPAND_CONTRACTOR_ID_DE = '8E7A6A2E022B46538AB6FF12EB912BD9';
export const XPAND_CONTRACTOR_ID_UK = '63C5ACAB558547BA9F065788F6200207';
export const XPAND_CONTRACTOR_ID_HR = '1A2CE0A44C71495CA2BB24F5EA107B0A';
export const BALWURK_EMPLOYEE_ID = '1AC4CEE26DA34444A60CF6791E7C4459';
export const BALWURK_CONTRACTOR_ID = '2C2AE32AE84E43248948ECF470C3CCC9';
export const ENVIRONMENT = 'DEV';
