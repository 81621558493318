import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {},
	appBar: {
		position: 'relative'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	fullScreenContent: {
		padding: '0px 75px 0 75px'
	},
	avatarContainer: {
		width: '50px',
		margin: 'auto',
		height: '50px',
		position: 'relative',
		overflow: 'hidden',
		borderRadius: '50%'
	},
	avatarImage: {
		display: 'inline',
		margin: '0 auto',
		height: '50px',
		width: 'auto'
	},
	centerInParent: {
		margin: 'auto',
		display: 'block'
	}
}));

export { useStyles };
