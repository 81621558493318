import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getNewProjectData, sendNewProject } from 'store/administration/projectsAndClients/thunks';
import { AppState } from 'typings/state';

import CompanyAllocation from './CompanyAllocation';

const mapStateToProps = ({ projectsAndClients }: AppState) => ({ projectsAndClients });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getNewProjectData, sendNewProject }, dispatch);

export type CompanyAllocationProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAllocation);
