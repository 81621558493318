/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Input } from 'xpand-ui/forms';

// import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';
import { INewEmployeeTabs } from '../../NewEmployee';
import { useSelector } from 'react-redux';

//* COMPONENT
const BasicInfo: FC<INewEmployeeTabs> = ({ control, errors }) => {
	const classes = useStyles();
	//@ts-ignore
	const companyDomain = useSelector(state => state.company.companyDomain)
	return (
		<Grid container spacing={4} className={classes.root}>
			<Grid item {...sizes[6]}>
				<Input
					name="username"
					label="Username"
					helperText="Username for the account, usually 4 or 5 lowercase letters (ex: lpsg, rmdm)"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="email"
					label="Email"
					helperText={`Email address, ${companyDomain} domain`}
					required
					control={control}
					errors={errors}
				/>
			</Grid>
		</Grid>
	);
};

export default BasicInfo;
