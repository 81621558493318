import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, POST, DELETE } from 'lib/handlers/requestTypes';
import { IDeleteEmployee, INewEmployee, IWelcomeEmail } from 'typings/store/admin/users';

export const fetchUserList = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/vpn');

export const deleteUser = async (username: string, payload: IDeleteEmployee): Promise<Success> =>
	restCall(DELETE, `/admin/users/${username}/delete`, payload);

export const deleteUserFromDB = async (payload: any): Promise<Success> =>
	restCall(DELETE, `/admin/users/deleteFromDB`, payload);

export const fetchWelcomeEmail = async (username: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/users/${username}/welcomeEmail`);

export const sendWelcomeEmail = async (username: string, payload: IWelcomeEmail): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/users/${username}/welcomeEmail`, payload);

export const sendImpersonateEmail = async (
	action: string,
	impersonatedUser: string
): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/users/impersonateEmail/${action}/${impersonatedUser}`, {}, '', false, true);

export const postUserListVPN = async (list: string[]): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/vpn', list);

export const fetchUserAdd = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/users/add');

export const postUserAdd = async (payload: INewEmployee): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/users/add', payload);

export const fetchHealthLocations = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/users/locations/list');

export const fetchUsersList = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/users/list');

export const sendHealthConvocation = async (payload: any): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/users/health/create', payload, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
