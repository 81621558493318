import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from 'typings/state';

import {
	getNewProjectData,
	sendNewProject,
	clearProjectAndClientsError
} from 'store/administration/projectsAndClients/thunks';

import NewProject from './NewProject';

const mapStateToProps = ({ projectsAndClients, company }: AppState) => ({ projectsAndClients, company });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getNewProjectData, sendNewProject, clearProjectAndClientsError }, dispatch);

export type NewProjectProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NewProject);
