import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { getBudgetAudit, clearBudgetAction } from 'store/administration/budget/thunks';

import BudgetAudit from './BudgetAudit';

const mapStateToProps = ({ budget }: AppState) => ({ budget });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			clearBudgetAction,
			getBudgetAudit
		},
		dispatch
	);

export type BudgetAuditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(BudgetAudit);
