/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useEffect, FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { FormHelperText } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter } from 'xpand-ui/core';

//* TYPINGS
import { IGrantAccessControl } from 'typings/store/admin/administration';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import OrgAccesses from './OrgAccesses';
import { GrantAccessProps } from '.';
import { useStyles } from './styles';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES
interface IGrantAccess extends GrantAccessProps {
	userId: number;
	handleClose: () => void;
}

//* COMPONENT
const GrantAccess: FC<IGrantAccess> = ({
	administration,
	userId,
	handleClose,
	getAccessControlEditUser,
	sendPutAccessControl,
	clearEditUser
}) => {
	const classes = useStyles();
	const { accessControlEditUser } = administration;

	const {
		handleSubmit,
		control,
		watch,
		getValues,
		setValue,
		reset,
		formState: { errors }
	} = useForm<any>({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(
		() => () => {
			clearEditUser();
		},
		[]
	);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (!accessControlEditUser) {
			getAccessControlEditUser(userId);
		}
	}, [accessControlEditUser]);

	const formData: IGrantAccessControl = getValues();

	const onSubmit = () => {
		formData.id = userId;
		sendPutAccessControl(formData, userId);
		handleClose();
	};

	const footerActions = [
		{
			id: 'submit',
			label: 'Submit',
			onClick: () => ({}),
			type: 'submit',
			form: 'form-accessControl',
			variant: 'contained',
			disabled: getLSField('impersonate_userInfo')
		}
	];

	// FIXME any typing
	const duplicatedError: any = errors?.orgAccesses && typeof errors?.orgAccesses === 'object' && errors?.orgAccesses;

	const isLoading = accessControlEditUser === null;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			<form id="form-accessControl" className={classes.root} onSubmit={handleSubmit(onSubmit)}>
				{/* **************** Org Accesses ****************** */}
				<OrgAccesses
					control={control}
					errors={errors}
					watch={watch}
					reset={reset}
					setValue={setValue}
					payload={accessControlEditUser}
				/>
			</form>
			{/* **************** FIXME any typing ****************** */}
			{duplicatedError && <FormHelperText error>{(duplicatedError as any)?.message}</FormHelperText>}
			<ActionFooter actions={footerActions} />
		</>
	);
};

export default withLayout(GrantAccess);
