/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';

import { phoneNumberRequired, stringRequired } from 'lib/utils/formUtils';

// const findDuplicates = (arr: string[]) => arr.filter((item, index) => arr.indexOf(item) !== index);

export const schema = yup.object().shape({
	contacts: yup
		.array()
		.of(stringRequired)
		.transform(value => value.filter((e: string) => e !== null))
		.min(1)
		.max(1)
		.required('This field is required')
		.nullable()
		.test('phoneregex', 'Please enter a valid phone number.', payload => {
			if (/^[+]{0,1}[0-9]{0,4}[ ]{0,1}[0-9]{6,12}$/.test(payload as unknown as string)) {
				return true;
			}

			return false;
		})
});

export const defaultValues = {
	contacts: ['']
};
