export enum CompanyActions {
	COMPANY_INFORMATION = '@@system/COMPANY_INFORMATION'
}

export type CompanyState = {
	companyLogo: string;
	smallCompanyLogo: string;
	bigCompanyLogo: string;
	companyChart: string;
	companyText: string;
	companyDomain: string;
	companyAbbreviation: string;
	companySite: string;
	urlBenefitis: string | null;
	urlServiceDeskOIProfile: string | null;
	urlServiceDeskCIProfile: string | null;
	urlConfluenceProfile: string | null;
	urlServiceBooking: string | null;
	urlServiceDeskCareer: string | null;
	urlConfluenceCareer: string | null;
	urlNameStandardProject: string | null;
	urlConfluenceProject: string | null;
	proposalReferenceAllowedIDS: any;
	defaultCompanyKey: string | null;
};

//* Thunks interfaces
