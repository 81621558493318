import { createReducer } from 'typesafe-actions';
import { UserActions as Actions, UserState } from 'typings/store/admin/users';

const INITIAL_STATE: UserState = {
	loading: false,
	error: null,
	usersListVPN: null,
	vpnAction: null,
	welcomeEmail: null,
	welcomeEmailAction: null,
	reducerUsersAdd: null,
	usersAction: null,
	usersList: null,
	responseCode: null,
	healthLocations: null,
	impersonateEmailAction: null,
	impersonateEmailClose: false
};

const UsersReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.GET_USERS_LIST_VPN]: (state, { payload }) => ({
		...state,
		loading: false,
		usersListVPN: payload?.users || null
	}),
	[Actions.SEND_DELETE_USER_FROM_DB]: state => ({
		...state,
		loading: false
	}),
	[Actions.SEND_DELETE_USER]: (state, { payload }) => ({ ...state, loading: false, usersAction: payload }),
	[Actions.GET_WELCOME_EMAIL]: (state, { payload }) => ({ ...state, loading: false, welcomeEmail: payload }),
	[Actions.CLEAR_WELCOME_EMAIL]: state => ({ ...state, loading: false, welcomeEmail: null }),
	[Actions.SEND_WELCOME_EMAIL]: (state, { payload }) => ({
		...state,
		loading: false,
		welcomeEmailAction: payload
	}),
	[Actions.POST_USERS_LIST_VPN]: (state, { payload }) => ({ ...state, loading: false, vpnAction: payload }),
	[Actions.GET_USERS_ADD]: (state, { payload }) => ({ ...state, loading: false, reducerUsersAdd: payload }),
	[Actions.POST_USERS_ADD]: (state, { payload }) => ({ ...state, loading: false, usersAction: payload }),
	[Actions.GET_USERS_LIST]: (state, { payload }) => ({ ...state, loading: false, usersList: payload }),
	[Actions.GET_USERS_LIST_FROM_DB]: (state, { payload }) => ({ ...state, loading: false, resignationList: payload }),
	[Actions.GET_RESPONSE_CODE]: (state, { payload }) => ({ ...state, loading: false, responseCode: payload }),
	[Actions.GET_HEALTH_LOCATIONS]: (state, { payload }) => ({ ...state, loading: false, healthLocations: payload }),
	[Actions.SET_HEALTH_CONVOCATION]: (state, { payload }) => ({ ...state, loading: false }),
	[Actions.SEND_IMPERSONATE_EMAIL]: (state, { payload }) => ({
		...state,
		loading: false,
		impersonateEmailAction: payload
	})
});

export default UsersReducer;
