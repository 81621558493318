//* EXTERNAL LIBS
import React, { FC, useEffect, useState } from 'react';

//* EXTERNAL LIBS --> MUI
import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, DualTransferList } from 'xpand-ui/core';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { handleErrorPage } from 'lib/hocs/withLayout';
import { useStyles } from './styles';
import { VPNAccessProps } from '.';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES
interface IUserListVPN {
	username: string;
	fullName: string;
}

//* COMPONENT
const VPNAccess: FC<VPNAccessProps> = ({ users, getUsersListVPN, sendUsersListVPN, clearUsersError }) => {
	const classes = useStyles();
	const { usersListVPN, loading, error } = users;
	const [usersList, setUsersList] = useState<IUserListVPN[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<IUserListVPN[]>([]);

	/** fetch users from db */
	useEffect(() => {
		if (!error && !usersListVPN) {
			getUsersListVPN();
		} else {
			usersListVPN &&
				setUsersList(
					usersListVPN.map(e => ({ username: e.username, fullName: `${e.firstName} ${e.lastName}` }))
				);
		}
	}, [usersListVPN]);

	/** handle submit */
	const handleSubmit = () => {
		sendUsersListVPN(selectedUsers.map(e => e.username));
	};

	/** handle dual transfer list component changes */
	const handleListChanges = (l: IUserListVPN[], r: IUserListVPN[]) => setSelectedUsers(r);

	if (error) return handleErrorPage(error, clearUsersError);

	const isLoading = usersListVPN === null || usersList.length === 0;
	if (isLoading) return <LoadingOverlay />;

	const isImpersonate = () => {
		if (getLSField('impersonate_userInfo')) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			{loading && <LoadingOverlay />}
			<div className={classes.root}>
				<div>
					<DualTransferList
						title="Users"
						leftList={usersList}
						rightList={[]}
						payloadId="username"
						payloadLabel="fullName"
						handleChanges={handleListChanges}
					/>
				</div>
				<div className={classes.navButtons}>
					<Button
						color="primary"
						disabled={selectedUsers.length === 0 || isImpersonate()}
						onClick={handleSubmit}
						variant="contained"
						endIcon={<Check />}>
						Send
					</Button>
				</div>
			</div>
		</>
	);
};

export default VPNAccess;
