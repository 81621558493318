import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import {
	getExistingProposalSummary,
	clearSummary,
	getProposalExportWithID
} from 'store/administration/proposals/thunks';

import DetailsModal from './DetailsModal';

const mapStateToProps = ({ proposals }: AppState) => ({ proposals });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getExistingProposalSummary,
			clearSummary,
			getProposalExportWithID
		},
		dispatch
	);

export type ProposalDetailsModalProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DetailsModal);
