import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import { stringRequired, numberRequired, dateRequired, booleanRequired, string } from 'lib/utils/formUtils';

const DateAux = new Date();
DateAux.setHours(0, 0, 0, 0);

const dateStartAdmin = new Date();
dateStartAdmin.setDate(dateStartAdmin.getDate() - 365);
dateStartAdmin.setHours(0, 0, 0, 0);

const max90DaysFromNow = new Date();
max90DaysFromNow.setMonth(max90DaysFromNow.getMonth() + 3);
max90DaysFromNow.setHours(0, 0, 0, 0);

export const schema = yup.object().shape({
	startDate: dateRequired.when('isAdmin', {
		is: true,
		then: dateRequired.min(dateStartAdmin.toISOString(), ({ min }) =>
			moment(min).isValid() ? `Start date to be after ${parseDateToShow(min as string)}` : 'Invalid Start Date'
		),
		otherwise: dateRequired.min(DateAux.toISOString(), ({ min }) =>
			moment(min).isValid() ? `Start date to be after ${parseDateToShow(min as string)}` : 'Invalid Start Date'
		)
	}),

	// startDate: dateRequired
	// 	.min(DateAux.toISOString(), ({ min }) =>
	// 		moment(min).isValid() ? `Start date to be after ${parseDateToShow(min as string)}` : 'Invalid Start Date'
	// 	)
	// 	.max(yup.ref('endDate'), ({ max }) =>
	// 		moment(max).isValid() ? `Start date to be before ${parseDateToShow(max as string)}` : 'Invalid Start Date'
	// 	),
	endDate: dateRequired.max(max90DaysFromNow.toISOString(), ({ max }) =>
		moment(max).isValid() ? `End date to be before ${parseDateToShow(max as string)}` : 'Invalid Start Date'
	),
	name: stringRequired,
	username: stringRequired,
	duration: numberRequired,
	zoneId: numberRequired,
	resourceId: numberRequired,
	isParkingSeatSelected: booleanRequired,
	isBycicleSeatSelected: booleanRequired,
	licensePlate: string.when(['isParkingSeatSelected', 'isClient'], {
		is: (isParkingSelected: boolean, isClient: boolean) => isParkingSelected && !isClient,
		then: stringRequired.matches(
			/^[a-zA-Z0-9-]+$/,
			'License plate can only contain letters, numbers, and hyphens (-)'
		),
		otherwise: string
	}),
	isClient: booleanRequired,
	previousId: string,
	previousEndDate: string,
	previousStartDate: string,
	blockedReason: string
});

export const defaultValues = {
	startDate: '',
	endDate: '',
	name: '',
	username: '',
	duration: '',
	zoneId: '',
	resourceId: '',
	isAdmin: false,
	validated: true,
	isParkingSeatSelected: false,
	isBycicleSeatSelected: false,
	licensePlate: '',
	isClient: false,
	previousId: '',
	previousStartDate: '',
	previousEndDate: '',
	blockedReason: ''
};
