/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { addNotification } from 'lib/utils/notifications';
import { START_API_PATH } from 'lib/utils/constants';
import { OriginalResponse, Success, GET, POST, PUT, DELETE, EXPORTFILE, EXPORTFILEPOST } from './requestTypes';

/** Axios request helper
 *
 * handles the GET POST PUT DELETE EXPORT methods
 * already with toast notification seamlessly with the API response
 * and with java error messages
 */
const restCall = async (
	type: number,
	path: string,
	reqPayload: any = undefined,
	config: any = undefined,
	getOriginalResponse = false,
	isSilent = false
): Promise<Success | OriginalResponse> => {
	// helper field to return data to the redux thunks
	let data: any = {
		code: 500,
		payload: {
			message: '500 - Server error',
			data: null
		},
		message: '500 - Server error'
	};

	// const token = await authProvider.getAccessToken();

	/** notifications helper */
	const sendNotification = (
		resData: OriginalResponse | undefined,
		errMessage: string | undefined = undefined
	): Success | OriginalResponse | any => {
		//
		/** TREATED responses on the API */
		if (resData) {
			const { code, payload } = resData;
			if (code === 200 || code === 201) {
				payload.message && addNotification('success', payload.message, 6);
				return resData.payload;
			}
			if (code === 409) {
				payload.message && addNotification('warning', payload.message, 6);
				return resData.payload;
			}
			if (code === 406) {
				payload.message && addNotification('info', payload.message, 6);
				return resData.payload;
			}
			// if (code === 404 || code === 500 || code === 401 || code === 403) {
			payload.message && addNotification('danger', payload.message, 6);
			return resData;
			// }

			/** UNTREATED api error */
		}
		errMessage && addNotification('danger', errMessage, 6);
		return null;
	};

	switch (type) {
		case GET: {
			await axios
				.get(`${START_API_PATH}${path}`, reqPayload)
				.then(response => {
					if (!isSilent) data = sendNotification(response.data);
					if (getOriginalResponse) {
						data = response;
					}
				})
				.catch(err => {
					if (!isSilent) sendNotification(undefined, err.message);
					throw err;
				});
			break;
		}
		case POST: {
			await axios
				.post(`${START_API_PATH}${path}`, reqPayload, config)
				.then(response => {
					if (!isSilent) data = sendNotification(response.data);
					if (getOriginalResponse) {
						data = response;
					}
				})
				.catch(err => {
					if (!isSilent) sendNotification(undefined, err.message);
					throw err;
				});
			break;
		}
		case PUT: {
			await axios
				.put(`${START_API_PATH}${path}`, reqPayload, config)
				.then(response => {
					if (!isSilent) data = sendNotification(response.data);
					if (getOriginalResponse) {
						data = response;
					}
				})
				.catch(err => {
					if (!isSilent) sendNotification(undefined, err.message);
					throw err;
				});
			break;
		}
		case DELETE: {
			await axios
				.delete(`${START_API_PATH}${path}`, { data: { reqPayload } })
				.then(response => {
					if (!isSilent) data = sendNotification(response.data);
					if (getOriginalResponse) {
						data = response;
					}
				})
				.catch(err => {
					if (!isSilent) sendNotification(undefined, err.message);
					throw err;
				});
			break;
		}
		case EXPORTFILE: {
			await axios({
				url: `${START_API_PATH}${path}`,
				method: 'GET',
				responseType: 'blob'
			})
				.then(response => {
					const blob = new Blob([response.data], { type: response.data.type });
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					const contentDisposition = response.headers['content-disposition'];
					let fileName = 'unknown';
					if (contentDisposition) {
						const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
						if (fileNameMatch.length === 2) {
							// eslint-disable-next-line prefer-destructuring
							fileName = fileNameMatch[1];
						}
					}
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					link.remove();
					window.URL.revokeObjectURL(url);
				})
				.catch(err => {
					if (!isSilent) sendNotification(undefined, err.message);
					throw err;
				});

			break;
		}
		case EXPORTFILEPOST: {
			await axios({
				url: `${START_API_PATH}${path}`,
				method: 'POST',
				data: reqPayload,
				responseType: 'blob'
			})
				.then(response => {
					const blob = new Blob([response.data], { type: response.data.type });
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					const contentDisposition = response.headers['content-disposition'];
					let fileName = 'unknown';
					if (contentDisposition) {
						const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
						if (fileNameMatch.length === 2) {
							// eslint-disable-next-line prefer-destructuring
							fileName = fileNameMatch[1];
						}
					}
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					link.remove();
					window.URL.revokeObjectURL(url);
				})
				.catch(err => {
					if (!isSilent) sendNotification(undefined, err.message);
					throw err;
				});

			break;
		}
		default:
			return data;
	}
	return data;
};

export { restCall };
