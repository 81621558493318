//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo, ChangeEvent } from 'react';

//* EXTERNAL LIBS --> MUI
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { RadioButtonUnchecked, CheckCircle, List } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, PageTitle, Table, Dialog } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sortbyDate } from 'xpand-ui/utils/sort';

//* TYPINGS
import { ITableAction, ITableColumn, ITableColumnsFilter, ITableExpander } from 'typings/store/ComponentLib';
import { ICollaborationProposal } from 'typings/store/admin/proposals';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
// *
import { allStatus } from 'pages/Admin/_ProposalsFormUtils/utils';

//* LOCAL COMPONENT IMPORTS
import ProposalsListExpander from './ProposalsListExpander';
import { ProposalsListProps } from '.';
import { useStyles } from './styles';

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Collaboration Proposals - XPECP.png';
import Adicionar from '../../../../assets/icons/Adicionar.svg';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES
interface IProposalsList extends ProposalsListProps {
	goToPage: (path: string) => void;
}
//* COMPONENT
const ProposalsList: FC<IProposalsList> = ({
	goToPage,
	proposals,
	filters,
	getProposalsList,
	clearProposalsError,
	setProposalsPageFilter
}) => {
	const classes = useStyles();
	const { loading, proposalsList, error } = proposals;
	const { proposalsFilter, proposalsStatusFilter } = filters;

	// TABLE SEARCH HELPER
	const [searchValue, setSearchValue] = useState(proposalsFilter);

	// TABLE COMPONENT - custom filter state field
	const [statusFilter, setStatusFilter] = useState(proposalsStatusFilter);

	// Update filters whenever necessary
	useEffect(() => {
		setProposalsPageFilter({ searchValue, statusFilter });
	}, [searchValue, statusFilter]);

	useEffect(() => {
		if ((!error && !proposalsList) || proposalsList === null) {
			getProposalsList();
		}
	}, [proposalsList]);

	// PROPOSALS STATUS HELPER
	const statusHelper = useMemo(() => allStatus(classes), []);

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	// TABLE ROW ACTIONS
	const tableActions: ITableAction<ICollaborationProposal>[] = [
		{
			id: 'someAction1',
			type: 'button',
			icon: <List />,
			render: () => true,
			disabled: () => false,
			to: ({ token }) => `/cp/admin/recruitment/proposals/${token}`,
			onClick: ({ token }) => goToPage(`/admin/recruitment/proposals/${token}`)
		}
	];

	// TABLE COLUMNS
	const tableColumns: ITableColumn<ICollaborationProposal>[] = useMemo(
		() => [
			{
				label: 'Name',
				id: 'name',
				accentColumn: true,
				align: 'left',
				width: '25%'
			},
			{
				label: 'Last Proposal Date',
				id: 'proposalDate',
				type: 'date',
				format: (row, date) => parseDateToShow(date as string, true),
				width: '20%'
			},
			{ label: 'Email Address', id: 'email', width: '30%' },
			{
				label: 'Status',
				id: 'proposalStatusAsString',
				width: '15%',
				format: row => {
					if (statusHelper[row.proposalStatus])
						return (
							<div className={classes.statusBar}>
								{statusHelper[row.proposalStatus].icon} {statusHelper[row.proposalStatus].label}
							</div>
						);

					return <div className={classes.statusBar}>{row.proposalStatus}</div>;
				}
			}
		],
		[proposalsList]
	);

	// TABLE EXPANDER / COLLAPSE
	const tableExpander: ITableExpander<ICollaborationProposal> = {
		render: row => row.olderProposals.length > 0,
		disable: () => false,
		component: ({ olderProposals }) => (
			<ProposalsListExpander
				payload={olderProposals}
				tableColumns={tableColumns}
				ignoreColumn="name"
				actionsWidth="10%"
			/>
		)
	};

	// TABLE CUSTOM FILTER STATUS CHANGER
	const handleStatusFilterPropsChange = (event: ChangeEvent<HTMLInputElement>) => {
		setStatusFilter({ ...statusFilter, [event.target.name]: event.target.checked });
	};

	// TABLE CUSTOM FILTER CONTENT
	const statusFilterComponent = () => (
		<FormControl component="fieldset" style={{ padding: '25px' }}>
			<FormGroup>
				{statusHelper.map((e, i) => {
					if (e.name !== 'none' && e.name !== 'edited' && e.name !== 'editedStartDate') {
						return (
							<FormControlLabel
								key={e.name}
								control={
									<Checkbox
										color="primary"
										icon={<RadioButtonUnchecked />}
										checkedIcon={<CheckCircle />}
										checked={statusFilter[i]}
										onChange={handleStatusFilterPropsChange}
										name={`${i}`}
									/>
								}
								label={e.label}
							/>
						);
					}

					return '';
				})}
			</FormGroup>
		</FormControl>
	);

	// TABLE CUSTOM FILTER
	const tableColumnsFilter: ITableColumnsFilter = useMemo(
		() => ({
			proposalStatusAsString: {
				anyToFilter: Object.values(statusFilter).includes(true),
				filterComponent: statusFilterComponent(),
				handleFilter: (info: ICollaborationProposal[]) => {
					const payloadToSearch = Object.keys(statusFilter)
						.filter(field => statusFilter[field])
						.map(e => +e);

					return info.filter(item => payloadToSearch.includes(item.proposalStatus as number));
				}
			}
		}),
		[statusFilter]
	);

	// TABLE DATA PARSED
	const tableData = useMemo(() => {
		if (!proposalsList || proposalsList === null) return [];

		interface IproposalForTable {
			id: string | number;
			name: string;
			proposalDate: string;
			email: string;
			proposalStatus: string;
			proposalStatusAsString: string;
			token: string;
		}

		const proposalListTableConstructor = ({
			id,
			name,
			proposalDate,
			email,
			proposalStatus,
			proposalStatusAsString,
			token
		}: IproposalForTable) => ({
			id,
			name,
			proposalDate,
			email,
			proposalStatus,
			token,
			proposalStatusAsString,
			olderProposals: [],
			proposalDateFormated: parseDateToShow(proposalDate)
		});

		const data = Object.keys(proposalsList?.proposalsPerToken).map((token: string) => {
			// only have 1 proposal
			if (proposalsList.proposalsPerToken[token].length === 1)
				return proposalListTableConstructor(proposalsList.proposalsPerToken[token][0]);

			// More than 1 proposal
			const orderedProposals = sortbyDate(proposalsList.proposalsPerToken[token], 'asc', 'proposalDate');
			return {
				...proposalListTableConstructor(orderedProposals[0] as IproposalForTable),
				olderProposals: orderedProposals.slice(1).map((e: IproposalForTable) => proposalListTableConstructor(e))
			};
		});

		return data;
	}, [proposalsList]);

	// PAGE HEADER ACTIONS
	const pageTitleActions = useMemo(
		() => [
			{
				id: 'addProposal',
				to: '/cp/admin/recruitment/newProposal',
				onClick: () => goToPage('/admin/recruitment/newProposal'),
				icon: <Adicionar />,
				label: 'New Proposal',
				disabled: getLSField('impersonate_userInfo')
			}
		],
		[]
	);

	if (error) return handleErrorPage(error, clearProposalsError);
	const isLoading = proposalsList === null;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle title="" actions={pageTitleActions} />
			<Table
				tableData={tableData}
				columns={tableColumns}
				collapseRow={tableExpander}
				actions={tableActions}
				tableColumnsFilter={tableColumnsFilter}
				defaultSortColumn="proposalDate"
				defaultOrder="desc"
				handleSearch={{ searchValue, setSearchValue }}
				handleDiagramModal={{ diagramModal, setDiagramModal }}
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(ProposalsList);
