import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	rightContainer: {
		marginLeft: '50%'
	},

	[theme.breakpoints.down('lg')]: {
		rightContainer: {
			marginLeft: '0px'
		}
	}
}));

export { useStyles };
