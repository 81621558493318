import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import { BudgetActions as Actions, IBudgetInfo, IImportBudget, INewBudgetModal } from 'typings/store/admin/budget';
import { OriginalResponse } from 'lib/handlers/requestTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setError = (payload: unknown | null): IDispatchType => action(Actions.SET_ERROR, payload);

export const setNewBudget = (payload: INewBudgetModal | null): IDispatchType => action(Actions.SET_NEW_BUDGET, payload);

export const setBudgetTable = (payload: IBudgetInfo[] | null): IDispatchType =>
	action(Actions.GET_BUDGET_TABLE, payload);

export const setBudgetPost = (payload: unknown | null): IDispatchType => action(Actions.SEND_NEW_BUDGET, payload);

export const setBudgetBiRefresh = (payload: unknown | null): IDispatchType =>
	action(Actions.SET_BUDGET_BI_REFRESH, payload);

export const setBudgetEditInfo = (payload: unknown | null): IDispatchType => action(Actions.GET_BUDGET_INFO, payload);

export const setBudgetAudit = (payload: unknown | null): IDispatchType => action(Actions.GET_BUDGET_AUDIT, payload);

export const setBudgetDepProjects = (payload: unknown | null): IDispatchType =>
	action(Actions.FETCH_BUDGET_DEPARTMENT_PROJECTS, payload);

export const setBudgetPut = (payload: unknown | null): IDispatchType => action(Actions.SEND_UPDATE_BUDGET, payload);

export const setDeleteBudget = (payload: unknown | null, newData: IBudgetInfo[] | null): IDispatchType =>
	action(Actions.SEND_DELETE_BUDGET, payload, newData);

export const setExportBudget = (payload: unknown | null): IDispatchType => action(Actions.SEND_EXPORT_BUDGET, payload);

export const setClearBudgetAction = (): IDispatchType => action(Actions.CLEAR_BUDGET_ACTION);

export const setClearBudgetInfo = (): IDispatchType => action(Actions.CLEAR_BUDGET_INFO);

export const setPostImportBudget = (payload: Document | FormData | null): IDispatchType =>
	action(Actions.POST_IMPORT_BUDGET, {
		payload
	});

export const setBudgetCompanies = (payload: any | null): IDispatchType => action(Actions.SET_BUDGET_COMPANY, payload);

export const setBudgetError = (payload: OriginalResponse | null): IDispatchType => action(Actions.SET_ERROR, payload);
