/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import { Grid, Button } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { DatePicker, Input, Select, InfoField, Numeric } from 'xpand-ui/forms';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { parseContactToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { IUpdatePersonalInfo, IUserProfile } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';

//* LOCAL COMPONENT IMPORTS

import { InfoCards } from '../../../utils';
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';

interface IPersonalInfo {
	formData: any;
	userProfile: IUserProfile;
	handleApiSubmit: any;
}

const PersonalInfo: FC<IPersonalInfo> = ({ formData, userProfile, handleApiSubmit }) => {
	const classes = useStyles();
	const [editMode, setEditMode] = useState<boolean | null>(false);

	const {
		handleSubmit,
		control,
		reset,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});
	const userERPData = userProfile?.user?.erpData;

	const getInitialData = () => ({
		emergencyContactName: userERPData?.emergency?.comments,
		emergencyContact: userERPData?.emergency?.value,
		personalEmail: userERPData?.email?.value,
		addressLine1: userERPData?.location?.addressLine1,
		addressLine2: userERPData?.location?.addressLine2,
		postalCode: userERPData?.location?.postalCode,
		city: userERPData?.location?.cityName,
		countryId: userERPData?.location?.country,
		citizenCardExpirationDateString: userERPData?.xPHRCitizenCardExpirationDate
	});
	useEffect(() => {
		if (userProfile) {
			reset(getInitialData());
		}
	}, [userProfile]);

	const onSubmit = (data: IUpdatePersonalInfo) => {
		if (JSON.stringify(data) !== JSON.stringify(getInitialData())) {
			handleApiSubmit([
				{
					type: 'personalInfo',
					data
				}
			]);
		} else {
			// eslint-disable-next-line no-alert
			addNotification('info', 'Nothing to Update');
		}
		setEditMode(false);
		return null;
	};

	const country = useMemo(() => {
		if (!userProfile) return null;

		const countryId = userERPData?.location?.country || null;

		if (!countryId) return null;

		return userProfile.countries?.find(e => e.id === countryId);
	}, [userProfile]);

	return (
		<InfoCards
			title="Personal Information"
			form="form-new-profile"
			classes={classes}
			reset={reset}
			editMode={editMode}
			setEditMode={setEditMode}>
			<form id="form-new-profile" onSubmit={handleSubmit(() => onSubmit(watch()))}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
					<Grid item {...sizes[6]}>
						<InfoField fontSize="lg" label="FULL NAME" value={formData?.erpData?.name} />
					</Grid>
					<Grid item {...sizes[6]}>
						<InfoField fontSize="lg" label="EMPLOYEE NUMBER" value={formData?.erpData?.referenceNumber} />
					</Grid>
					<Grid item {...sizes[6]}>
						<InfoField
							fontSize="lg"
							label="BIRTHDAY"
							value={parseDateToShow(formData?.erpData?.xPHRDateOfBirth)}
						/>
					</Grid>
					<Grid item {...sizes[6]}>
						<InfoField
							fontSize="lg"
							label="NATIONALITY"
							value={
								formData?.erpData?.xphrNationality === 'PT'
									? 'Portuguese'
									: formData?.erpData?.xphrNationality
							}
						/>
					</Grid>
					<Grid item {...sizes[6]}>
						<InfoField
							fontSize="lg"
							label="GENDER"
							value={
								(formData?.erpData?.xphrGender === 'M' && 'Male') ||
								(formData?.erpData?.xphrGender === 'F' && 'Female') ||
								'N/A'
							}
						/>
					</Grid>
					{!editMode && (
						<>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="EMERGENCY CONTACT NAME"
									value={formData?.erpData?.emergency?.comments}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="EMERGENCY CONTACT"
									value={
										formData?.erpData?.emergency?.value !== 'null'
											? parseContactToShow(formData?.erpData?.emergency?.value)
											: ''
									}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField fontSize="lg" label="PERSONAL EMAIL" />
								<Button
									size="large"
									variant="text"
									target="_top"
									color="primary"
									rel="noopener noreferrer"
									className={classes.emailFieldLink}
									href={`mailto:${formData?.erpData?.email?.value}`}>
									{formData?.erpData?.email?.value}
								</Button>
							</Grid>
							<Grid item {...sizes[12]}>
								<InfoField
									fontSize="lg"
									label="HOME ADDRESS"
									value={`${
										userERPData?.location?.addressLine1 &&
										userERPData?.location?.addressLine1 !== '' &&
										userERPData?.location?.addressLine1 !== undefined
											? `${userERPData?.location?.addressLine1}, `
											: ''
									} ${
										userERPData?.location?.addressLine2 &&
										userERPData?.location?.addressLine2 !== '' &&
										userERPData?.location?.addressLine2 !== undefined
											? `${userERPData?.location?.addressLine2}, `
											: ''
									}${
										userERPData?.location?.postalCode &&
										userERPData?.location?.postalCode !== '' &&
										userERPData?.location?.postalCode !== undefined
											? `${userERPData?.location?.postalCode}, `
											: ''
									} ${
										userERPData?.location?.cityName &&
										userERPData?.location?.cityName !== '' &&
										userERPData?.location?.cityName !== undefined
											? `${userERPData?.location?.cityName}, `
											: ''
									}${
										country?.description &&
										country?.description !== '' &&
										country?.description !== undefined
											? `${country?.description} `
											: ''
									}`}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField fontSize="lg" label="ID DOC NO." value={userERPData?.xPHRIDNUMBER} />
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="Valid until"
									value={
										userERPData?.xPHRCitizenCardExpirationDate &&
										userERPData?.xPHRCitizenCardExpirationDate !== '' &&
										parseDateToShow(userERPData?.xPHRCitizenCardExpirationDate)
									}
								/>
							</Grid>
						</>
					)}
					{editMode && (
						<>
							<Grid item {...sizes[6]}>
								<Input
									name="emergencyContactName"
									label="EMERGENCY CONTACT NAME"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Input
									name="emergencyContact"
									label="EMERGENCY CONTACT"
									required
									onKeyPress={event => {
										if (!/[0-9]/.test(event.key)) {
											event.preventDefault();
										}
									}}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Input
									name="personalEmail"
									label="PERSONAL EMAIL"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Input
									name="addressLine1"
									label="Address line 1"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Input name="addressLine2" label="Address line 2" control={control} errors={errors} />
							</Grid>
							<Grid item {...sizes[6]}>
								<Input
									name="postalCode"
									label="Postal Code"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Input name="city" label="City" required control={control} errors={errors} />
							</Grid>
							<Grid item {...sizes[6]} />
							<Grid item {...sizes[6]}>
								<Select
									name="countryId"
									label="Country"
									options={
										userProfile?.countries?.map(e => ({ id: e.id, label: e.description })) || []
									}
									control={control}
									errors={errors}
									required
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField fontSize="lg" label="ID DOC NO." value={userERPData?.xPHRIDNUMBER} />
							</Grid>
							<Grid item {...sizes[6]}>
								<DatePicker
									name="citizenCardExpirationDateString"
									label="Valid until"
									control={control}
									errors={errors}
									required
								/>
							</Grid>
						</>
					)}
				</Grid>
			</form>
		</InfoCards>
	);
};

export default PersonalInfo;
