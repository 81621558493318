/* eslint-disable no-empty-pattern */
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'typings/state';

import { clearPersonalInfoError, getUserTeam } from 'store/personalInfo/thunks';

import MyTeam from './MyTeam';

const mapStateToProps = ({ personalInfo }: AppState) => ({ personalInfo });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ clearPersonalInfoError, getUserTeam }, dispatch);

export type MyTeamProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyTeam);
