import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { getEmployeesList } from 'store/administration/collaborationTypesManagement/thunks';

import CollaborationTypesManagement from './CollaborationTypesManagement';
import { setContractManagementPageFilter, setECPageFilter } from 'store/filters/actions';

const mapStateToProps = ({ collaborationTypesManagement, filters }: AppState) => ({
	collaborationTypesManagement,
	filters
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getEmployeesList,
			setContractManagementPageFilter,
			setECPageFilter
		},
		dispatch
	);

export type CollaborationTypesManagementProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CollaborationTypesManagement);
