import { createReducer } from 'typesafe-actions';
import { ProjectsAndClientsActions as Actions, ProjectsAndClientsState } from 'typings/store/admin/projectsAndClients';

const INITIAL_STATE: ProjectsAndClientsState = {
	loading: false,
	error: null,
	newClient: null,
	newClientAction: null,
	fetchedNewProjectData: null,
	newProjectAction: null
};

const ProjectsAndClientsReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.GET_NEW_CLIENT]: (state, { payload }) => ({
		...state,
		loading: false,
		newClient: payload
	}),

	[Actions.POST_NEW_CLIENT]: (state, { payload }) => ({
		...state,
		loading: false,
		newClientAction: payload
	}),
	[Actions.GET_NEW_PROJECT]: (state, { payload }) => ({
		...state,
		loading: false,
		fetchedNewProjectData: payload
	}),

	[Actions.POST_NEW_PROJECT]: (state, { payload }) => ({
		...state,
		loading: false,
		newProjectAction: payload
	})
});

export default ProjectsAndClientsReducer;
