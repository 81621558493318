/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable indent, @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import {
	IContractsList,
	ICscAuditLog,
	IDownloadDocument,
	IExportCSCsList,
	IGetCSCHistory,
	IGetCSCHistoryDetails,
	IGetCSCList,
	IGetEditCSC,
	IGetNewCSC,
	IPostPutCSC,
	IPostRenewContract
} from 'typings/store/admin/employeeSalaryCategories';

import {
	//! GET INFO TABLES
	// GET CSC FOR ALL USERS
	fetchEmployeesList,
	// GET CSC FOR SPECIFIC USER AND ITS HISTORY
	fetchEmployeeSalaryCategoriesUser,
	//! CSC ACTIONS
	// CLOSE CSC
	employeeSalaryCategoryClose,
	// CANCEL CSC
	employeeSalaryCategoryCancel,
	// REQUEST APPROVAL CSC
	employeeSalaryCategoryRequestApproval,
	// APPROVE CSC
	employeeSalaryCategoryApprove,
	// REJECT CSC
	employeeSalaryCategoryReject,
	//! FORM GETS and POSTS
	// NEW CSC FORM - GET
	fetchNewEmployeeSalary,
	// EDIT CSC FORM - GET
	fetchEditEmployeeSalary,
	// NEW CSC FORM - POST
	postNewEmployeeSalary,
	// EDIT CSC FORM - PUT
	putEditEmployeeSalary,
	//! HELPERS
	// GET IRS VALUES
	fetchIrsValue,
	// GET CSC HISTORY DETAILS FOR MODAL
	fetchCscHistoryDetails,
	fetchCscAuditLog,
	fetchCscHistoryExportExcel,
	fetchContractsList,
	fetchDownloadDocument,
	fetchRenewContract,
	postRenewContract,
	fetchExportMultipleCSCs
} from 'lib/api/administration/employeeSalaryCategories';
import { IIrsValuePost } from 'typings/store/admin/proposals';
import {
	setLoading,
	setEmployeeSalaryCategoriesError,
	//! GET INFO TABLES
	// GET CSC FOR ALL USERS
	setEmployeesList,
	// GET CSC FOR SPECIFIC USER AND ITS HISTORY
	setEmployeeSalaryCategoriesList,
	//! CSC ACTIONS
	// CLOSE CSC
	setEmployeeSalaryCategoryClose,
	// CANCEL CSC
	setEmployeeSalaryCategoryCancel,
	// REQUEST APPROVAL CSC
	setEmployeeSalaryCategoryRequestApproval,
	// APPROVE CSC
	setEmployeeSalaryCategoryApprove,
	// REJECT CSC
	setEmployeeSalaryCategoryReject,

	//! FORM GETS and POSTS
	// NEW CSC FORM - GET
	setNewEmployeeSalary,
	// EDIT CSC FORM - GET
	setEditEmployeeSalary,

	// NEW CSC FORM - POST
	setPostNewEmployeeSalary,
	// EDIT CSC FORM - PUT
	setPutUpdateEmployeeSalary,

	//! HELPERS
	// GET IRS VALUES
	setIrsValue,
	// GET CSC HISTORY DETAILS FOR MODAL
	setCscHistory,
	// RESET REDUCER
	resetFormData,
	setCscAuditLog,
	setCscHistoryExportExcel,
	setContractsList,
	setDownloadDocument,
	setRenewContract,
	setPostRenewContract,
	setExportMultipleCSCs
} from './actions';

//! GET INFO TABLES
// GET CSC FOR ALL USERS
export const getEmployeesList =
	(payload?: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IGetCSCList } = await fetchEmployeesList(payload);
			if (response.data) {
				dispatch(setEmployeesList(response?.data || null));
			} else {
				dispatch(setEmployeeSalaryCategoriesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setEmployeeSalaryCategoriesError(error as OriginalResponse));
		}
	};

// EXPORT CSC'S
export const exportMultipleCSCs =
	(payload: IExportCSCsList) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await fetchExportMultipleCSCs(payload);
			dispatch(setExportMultipleCSCs(data));
		} catch (error) {
			dispatch(setExportMultipleCSCs([]));
		}
	};

// GET CSC FOR SPECIFIC USER AND ITS HISTORY
export const getEmployeeSalaryCategoriesList =
	(hash: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IGetCSCHistory } | OriginalResponse = await fetchEmployeeSalaryCategoriesUser(
				hash
			);

			if (response.data) {
				dispatch(setEmployeeSalaryCategoriesList(response.data, hash));
			} else {
				dispatch(setEmployeeSalaryCategoriesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setEmployeeSalaryCategoriesList(null, null));
		}
	};

//! CSC ACTIONS
// CLOSE CSC
export const submitEmployeeSalaryCategoryClose =
	(hash: string, token: string, proposalId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await employeeSalaryCategoryClose(token, proposalId);
			dispatch(setEmployeeSalaryCategoryClose(response.data || null, hash));
		} catch (error) {
			dispatch(setEmployeeSalaryCategoryClose(null, null));
		}
	};

// CANCEL CSC
export const submitEmployeeSalaryCategoryCancel =
	(hash: string, token: string, proposalId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await employeeSalaryCategoryCancel(token, proposalId);
			dispatch(setEmployeeSalaryCategoryCancel(response.data || null, hash));
		} catch (error) {
			dispatch(setEmployeeSalaryCategoryCancel(null, null));
		}
	};

// REQUEST APPROVAL CSC
export const submitEmployeeSalaryCategoryRequestApproval =
	(hash: string, token: string, proposalId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await employeeSalaryCategoryRequestApproval(token, proposalId);
			dispatch(setEmployeeSalaryCategoryRequestApproval(response.data || response.message, hash));
		} catch (error) {
			dispatch(setEmployeeSalaryCategoryRequestApproval(null, null));
		}
	};

// APPROVE CSC
export const submitEmployeeSalaryCategoryApprove =
	(token: string, proposalId: number, manager: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await employeeSalaryCategoryApprove(token, proposalId, manager);
			dispatch(setEmployeeSalaryCategoryApprove(response, token));
		} catch (error) {
			dispatch(setEmployeeSalaryCategoryApprove(null, null));
		}
	};

// REJECT CSC
export const submitEmployeeSalaryCategoryReject =
	(token: string, proposalId: number, manager: string, payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await employeeSalaryCategoryReject(token, proposalId, manager, payload);
			dispatch(setEmployeeSalaryCategoryReject(response.data, token));
		} catch (error) {
			dispatch(setEmployeeSalaryCategoryReject(null, null));
		}
	};

//! FORM GETS and POSTS
// NEW CSC FORM - GET
export const getNewEmployeeSalary =
	(payload: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IGetNewCSC } = await fetchNewEmployeeSalary(payload);
			if (response.data) {
				dispatch(setNewEmployeeSalary(response?.data || null));
			} else {
				dispatch(setEmployeeSalaryCategoriesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setEmployeeSalaryCategoriesError(error as OriginalResponse));
		}
	};

// EDIT CSC FORM - GET
export const getEditEmployeeSalary =
	(payload: string, token: string, proposalId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IGetEditCSC } = await fetchEditEmployeeSalary(payload, token, proposalId);
			if (response.data) {
				dispatch(setEditEmployeeSalary(response.data || null));
			} else {
				dispatch(setEmployeeSalaryCategoriesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setEmployeeSalaryCategoriesError(error as OriginalResponse));
		}
	};

// NEW CSC FORM - POST
export const submitNewEmployeeSalary =
	(payload: IPostPutCSC, token: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await postNewEmployeeSalary(payload, token);
			dispatch(setPostNewEmployeeSalary(response?.data));
		} catch (error) {
			dispatch(setPostNewEmployeeSalary(null));
		}
	};

// EDIT CSC FORM - PUT
export const submitUpdateEmployeeSalary =
	(payload: IPostPutCSC, token: string, id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await putEditEmployeeSalary(payload, token, id);
			dispatch(setPutUpdateEmployeeSalary(response?.data));
		} catch (error) {
			dispatch(setPutUpdateEmployeeSalary(null));
		}
	};

//! HELPERS
// GET IRS VALUES

export const getIrsValue =
	(payload: IIrsValuePost) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await fetchIrsValue(payload);
			dispatch(setIrsValue(response.data || null));
		} catch (error) {
			dispatch(setIrsValue(null));
		}
	};

// GET CSC HISTORY DETAILS FOR MODAL
export const getCscHistory =
	(hash: string, id: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IGetCSCHistoryDetails } = await fetchCscHistoryDetails(hash, id);
			dispatch(setCscHistory(response.data || null));
		} catch (error) {
			dispatch(setCscHistory(null));
		}
	};

// CLEAR ALL INFO ON CSC'S

export const clearProposalsFormsData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(resetFormData());
	};

// CLEAR ALL INFO ON CSC'S

export const clearCsCHistory =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(dispatch(setCscHistory(null)));
	};

export const getCscAuditLog =
	(hash: string, token: string, id: number | string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: ICscAuditLog } = await fetchCscAuditLog(hash, token, id);
			if (response.data) {
				dispatch(setCscAuditLog(response?.data || null));
			} else {
				dispatch(setEmployeeSalaryCategoriesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setEmployeeSalaryCategoriesError(error as OriginalResponse));
		}
	};

export const clearCscAuditLog =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setCscAuditLog(null));
	};

export const getCscHistoryExportExcel =
	(hash: string, cscErpId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			await fetchCscHistoryExportExcel(hash, cscErpId);
			dispatch(setCscHistoryExportExcel());
		} catch (error) {
			dispatch(setCscHistoryExportExcel());
		}
	};

// CONTRACT MANAGEMENT
export const getContractsList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IContractsList } | OriginalResponse = await fetchContractsList();

			if (response.data) {
				dispatch(setContractsList(response as IContractsList));
			} else {
				dispatch(setEmployeeSalaryCategoriesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setEmployeeSalaryCategoriesError(error as OriginalResponse));
		}
	};

export const downloadDocument =
	(username: string, payload: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await fetchDownloadDocument(username, payload);
			dispatch(setDownloadDocument(username, response.data));
		} catch (error) {
			dispatch(setDownloadDocument(null, null));
		}
	};

export const getRenewContract =
	(username: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: any } = await fetchRenewContract(username);
			if (response.data) {
				dispatch(setRenewContract(response?.data));
			} else {
				dispatch(setEmployeeSalaryCategoriesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setEmployeeSalaryCategoriesError(error as OriginalResponse));
		}
	};

export const submitRenewContractPost =
	(username: string, payload: IPostRenewContract) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IPostRenewContract } | OriginalResponse = await postRenewContract(
				username,
				payload
			);
			const newData: { data?: IContractsList } | OriginalResponse = await fetchContractsList();
			dispatch(setContractsList(newData as IContractsList));
			dispatch(setPostRenewContract(response.data));
		} catch (error) {
			dispatch(setEmployeeSalaryCategoriesError(null));
		}
	};

export const clearCscError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setEmployeeSalaryCategoriesError(null));
	};
