import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		boxShadow: 'none'
	},
	base: {
		marginBottom: '25px',
		boxShadow: 'none'
	},
	appBar: {
		position: 'relative'
	},
	title: {
		flex: 1,
		position: 'relative',
		color: theme.accentColor
	},
	skillsGroupCard: {
		marginBottom: '12px'
	},
	feedbackCard: {
		marginTop: '20px',
		boxShadow: 'none'
	},
	feedbackContainer: {
		boxShadow: 'none',
		padding: '15px 15px'
	},
	feedbackItem: {
		boxShadow: 'none',
		padding: '15px'
	},
	/** skills tab */
	skillsGroupTitle: {
		fontWeight: 600,
		fontSize: 16,
		width: '100%',
		padding: '20px 5%'
	},
	skillsSubGroupsContainer: {
		padding: '0 12% 50px'
	},
	skillHeaderLabel: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px',
		color: theme.palette.secondary.main,
		textAlign: 'initial',
		minWidth: '330px'
	},
	skillHeaderInputs: {
		fontFamily: 'Open Sans Bold',
		fontSize: '12px',
		color: theme.typographyColor,
		textAlign: 'initial',
		minWidth: '330px'
	},
	skillHeaderLabelLanguages: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px',
		color: theme.palette.secondary.main,
		textAlign: 'initial'
	},
	skillHeaderInputsLanguages: {
		fontFamily: 'Open Sans Bold',
		fontSize: '12px',
		color: theme.typographyColor,
		textAlign: 'initial'
	},
	skillHeaderLine: {
		borderBottom: '1px solid #E4E6E8',
		padding: '0 !important'
	},
	skillLabel: {
		fontSize: '18px',
		textAlign: 'initial',
		color: theme.grayedColor
	},
	noFeedback: {
		marginTop: '20px',
		marginLeft: '32px'
	}
	/** end skills tab */
}));

export { useStyles };
