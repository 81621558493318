import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getPlannerData,
	getFiltersData,
	savePlannerNewData,
	getFilteredPlannerData,
	clearPlanData,
	clearAllStoredData,
	deletePlannerEvent,
	clearPlannerError
} from 'store/administration/planner/thunks';
import { setPlannerAction, setPlannerDataCreate } from 'store/administration/planner/actions';
import { setPlannerFilters } from 'store/filters/actions';
import { AppState } from 'typings/state';

import PlannerMobiscroll from './PlannerMobiscroll';

const mapStateToProps = ({ planner, filters }: AppState) => ({ planner, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getPlannerData,
			getFiltersData,
			savePlannerNewData,
			getFilteredPlannerData,
			deletePlannerEvent,
			clearPlanData,
			clearAllStoredData,
			clearPlannerError,
			setPlannerAction,
			setPlannerDataCreate,
			setPlannerFilters
		},
		dispatch
	);

export type PlannerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PlannerMobiscroll);
