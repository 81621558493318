import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { AppState } from 'typings/state';

import {
	getTimeoffData,
	saveTimeoff,
	deleteTimeoff,
	submitTimeoff,
	unsubmitTimeoff,
	getTimeoffRequestApprovalDetailsData,
	exportICALTimeOff,
	exportPDFTimeOff
} from 'store/personalInfo/thunks';

import MyTimeoff from './MyTimeoff';

const mapStateToProps = ({ personalInfo, filters }: AppState) => ({ personalInfo, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getTimeoffData,
			saveTimeoff,
			deleteTimeoff,
			submitTimeoff,
			unsubmitTimeoff,
			getTimeoffRequestApprovalDetailsData,
			exportICALTimeOff,
			exportPDFTimeOff
		},
		dispatch
	);

export type MyTimeoffProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyTimeoff);
