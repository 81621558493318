import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	//* WEB
	[theme.breakpoints.up('md')]: {
		leftContainer: {
			display: 'flex',
			flex: 1,
			padding: '0 20px 30px 20px',
			textAlign: 'center',
			width: '100%'
		},
		rightContainer: {
			flex: 2,
			textAlign: 'left',
			padding: '0 30px'
		},
		card: {
			boxShadow: '0px 3px 18px #EDF0F2',
			borderRadius: '8px',
			padding: '15px'
		}
	},
	//* TABLET
	[theme.breakpoints.down('md')]: {
		leftContainer: {
			display: 'flex',
			flex: 1,
			padding: '0 10px 20px 10px',
			textAlign: 'center',
			width: '100%'
		},
		rightContainer: {
			flex: 2,
			textAlign: 'left',
			padding: '0 20px'
		},
		card: {
			boxShadow: '0px 3px 18px #EDF0F2',
			borderRadius: '8px',
			padding: '9px'
		}
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		leftContainer: {
			display: 'flex',
			flex: 1,
			padding: '0 5px 10px 5px',
			textAlign: 'center',
			width: '100%'
		},
		rightContainer: {
			flex: 2,
			textAlign: 'left',
			padding: '0 15px'
		},
		card: {
			boxShadow: '0px 3px 18px #EDF0F2',
			borderRadius: '8px',
			padding: '5px'
		}
	}
}));

export { useStyles };
