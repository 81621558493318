/* eslint-disable indent */
import { fetchGuide, fetchUserGuide, setMarkUserGuideAsRead } from 'lib/api/system/guides';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import { Dispatch } from 'redux';
import { GuidesState } from 'typings/store/guidesTypes';
import { getGuide, getUserGuide, markUserGuideAsRead } from './actions';

export const getGuideData =
	(guideName: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request
			const response = await fetchGuide(guideName);
			// set api results on the redux state
			if (response.data) {
				dispatch(getGuide(response));
			} else {
				dispatch(getGuide(response as OriginalResponse));
			}
		} catch (error) {
			// No particular errors are set, as the guide system should not break existing pages
		}
	};

export const getUserGuideData =
	(username: string, guideName: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request
			const response = await fetchUserGuide(username, guideName);
			// set api results on the redux state
			if (response.data) {
				dispatch(getUserGuide(response));
			} else {
				dispatch(getUserGuide(response as OriginalResponse));
			}
		} catch (error) {
			// No particular errors are set, as the guide system should not break existing pages
		}
	};

export const setUserGuideAsRead =
	(username: string, guideName: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request
			const response = await setMarkUserGuideAsRead(username, guideName);

			// set api results on the redux state
			if (response) {
				dispatch(markUserGuideAsRead(response.data));
			} else {
				dispatch(markUserGuideAsRead(response as OriginalResponse));
			}
		} catch (error) {
			// No particular errors are set, as the notification system should not break existing pages
		}
	};
