import { getLSField } from 'lib/utils/cookies';

export enum Roles {
	CP_ROLE_SYSTEM_ADMIN = 'Control Panel Role - System Admin',
	CP_ROLE_PAYROLL_AND_BENEFITS = 'Control Panel Role - Payroll And Benefits',
	CP_ROLE_IT_OPERATIONS = 'Control Panel Role - IT Operations',
	CP_ROLE_TALENT_ENGAGEMENT = 'Control Panel Role - Talent Engagement',
	CP_ROLE_LOGISTICS = 'Control Panel Role - Logistics',
	CP_ROLE_TALENT_ACQUISITION = 'Control Panel Role - Talent Acquisition',
	CP_ROLE_TALENT_ACQUISITION_ADMIN = 'Control Panel Role - Talent Acquisition Admin',
	CP_ROLE_SKILLS = 'Control Panel Role - Skills',
	CP_ROLE_RESUMES = 'Control Panel Role - Resumes',
	CP_ROLE_PLANNER = 'Control Panel Role - Planner',
	CP_ROLE_SALARY_CATEGORIES = 'Control Panel Role - Salary Categories',
	CP_ROLE_BUDGET_ADMIN = 'Control Panel Role - Budget Admin',
	CP_ROLE_BUDGET = 'Control Panel Role - Budget',
	CP_ROLE_PROJECTS = 'Control Panel Role - Projects',
	CP_ROLE_CLIENTS = 'Control Panel Role - Clients',
	CP_ROLE_BUSINESS_UNIT_MANAGERS = 'Control Panel Role - Business Unit Managers',
	CP_ROLE_XTRACKER_TESTER = 'Control Panel Role - Xtracker Tester'
}

interface Permissions {
	displayName: string;
}

export const actionPermission = (action: string): boolean => {
	const userPermStorage = getLSField('impersonate_userActions')
		? getLSField('impersonate_userActions')
		: getLSField('userActions');

	const userPerms = (userPermStorage && JSON.parse(userPermStorage)) || [];

	if (userPerms.some((e: Permissions) => e.displayName === 'Control Panel Role - System Admin')) return true;

	return userPerms.some((e: Permissions) => e.displayName === action); // true = hasPermission
};
