import React, { FC, useMemo } from 'react';
import { Typography } from '@mui/material';
import { Table, Dialog } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

interface ITableColumn<T> {
	label: string;
	id: string;
	width?: string;
}

interface IApprovalDetailsModalProps {
	openApprovalDetailsModal: boolean;
	setOpenApprovalDetailsModal: (isOpen: boolean) => void;
	mainExpenseSheetData: any[];
	expenseSheetData: any;
}

// Local component imports
import { useStyles } from './styles';

const ApprovalDetailsModal: FC<IApprovalDetailsModalProps> = ({
	openApprovalDetailsModal,
	setOpenApprovalDetailsModal,
	mainExpenseSheetData,
	expenseSheetData
}) => {
	const classes = useStyles();

	const confirmActionsApprovalDetails = [
		{
			id: 'close',
			label: 'Close',
			color: 'primary',
			variant: 'contained',
			onClick: () => setOpenApprovalDetailsModal(false)
		}
	];

	const tableColumnHeaders: ITableColumn<any>[] = [
		{ label: 'Approver Name', id: 'approvalEmployeeName', width: '25%' },
		{
			label: 'Approved On',
			id: 'approvedOn',
			width: '10%',
			format: (row, text: string) => (text ? parseDateToShow(text) : '')
		},
		{ label: 'Status', id: 'status', width: '10%' },
		{ label: 'Comments', id: 'comments', width: '25%' },
		{ label: 'Projects', id: 'projectName', width: '30%' }
	];

	const approvalTableData = useMemo(
		() =>
			expenseSheetData?.results?.vueAccountExpenseSheetDetailed?.map(e => ({
				...e,
				status: e.isApproved ? 'Approved' : e.isRejected ? 'Rejected' : '', // Default empty string for other cases
				approvalEmployeeName: e.approvalEmployeeName + ' - ' + e.systemApproverType
			})),
		[expenseSheetData]
	);

	return (
		<Dialog
			maxWidth="lg"
			modal={{
				open: Boolean(openApprovalDetailsModal),
				handleClose: (event: any, reason: string) => {
					if (reason !== 'backdropClick') setOpenApprovalDetailsModal(false);
				},
				content: (
					<>
						<Typography gutterBottom style={{ marginLeft: 15 }}>
							<b>Submitted By:</b>{' '}
							{mainExpenseSheetData?.[0]?.submittedDate ? mainExpenseSheetData?.[0]?.employeeName : ''}
						</Typography>
						<Typography gutterBottom style={{ marginLeft: 15 }}>
							<b>Submitted On:</b> {mainExpenseSheetData?.[0]?.submittedDate}
						</Typography>
						<Table
							hideToolBar={true}
							tableData={approvalTableData}
							columns={tableColumnHeaders}
							defaultSortColumn="approvedOn"
							defaultOrder="desc"
							lineless={true}
							abc={true}
						/>
					</>
				)
			}}
			title="Approval Details"
			actions={confirmActionsApprovalDetails}
			scroll="body"
		/>
	);
};

export default ApprovalDetailsModal;
