/* eslint-disable no-console */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IGrantAccessControlInfo, IOrgAccessesCompare } from 'typings/store/admin/administration';
import { CompanyERP, ICompany } from 'typings/store/generalTypes';

/* eslint-disable camelcase */
const allOptions = [{ id: '0', label: '*' }];

interface IResponse {
	company: ICompany[];
	businessUnit?: ICompany[];
	division?: ICompany[];
	department?: ICompany[];
}

export const prepareCompanyData = (
	helper: IOrgAccessesCompare | null,
	fetchedData: IGrantAccessControlInfo
): IResponse => {
	const businessUnits =
		helper?.company !== '0' ? fetchedData.companies.find(e => e.searchKey === helper?.company) : undefined;

	const divisions =
		helper?.businessUnit !== '0'
			? businessUnits?.companyTree?.childNodes.find((e: CompanyERP) => e.organization === helper?.businessUnit)
			: undefined;

	const departments =
		helper?.division !== '0'
			? divisions?.childNodes.find((e: CompanyERP) => e.organization === helper?.division)
			: undefined;

	return {
		company: [...allOptions, ...fetchedData.companies.map(e => ({ id: e.searchKey, label: e.name }))],
		businessUnit: businessUnits
			? [
					...allOptions,
					...businessUnits.companyTree.childNodes.map((e: CompanyERP) => ({
						id: e.organization,
						label: e.organization$_identifier
					}))
			  ]
			: allOptions,
		division: divisions
			? [
					...allOptions,
					...divisions.childNodes.map((e: CompanyERP) => ({
						id: e.organization,
						label: e.organization$_identifier
					}))
			  ]
			: allOptions,
		department: departments
			? [
					...allOptions,
					...departments.childNodes.map((e: CompanyERP) => ({
						id: e.organization,
						label: e.organization$_identifier
					}))
			  ]
			: allOptions
	};
};
