import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from 'typings/state';

import { getEmployeeData, clearEmployeeData } from 'store/administration/admissions/thunks';

import DetailsModal from './DetailsModal';
import { getFormFieldsConfig } from 'store/administration/formFields/thunks';

const mapStateToProps = ({ admissions, formFields }: AppState) => ({ admissions, formFields });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getEmployeeData,
			clearEmployeeData,
			getFormFieldsConfig
		},
		dispatch
	);

export type DetailsModalProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DetailsModal);
