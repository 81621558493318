import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import { stringRequired, numberRequired, boolean, dateRequired, string } from 'lib/utils/formUtils';

const DateAux = new Date();
DateAux.setHours(0, 0, 0, 0);

const dateStartAdmin = new Date();
dateStartAdmin.setDate(dateStartAdmin.getDate() - 365);
dateStartAdmin.setHours(0, 0, 0, 0);

const max90DaysFromNow = new Date();
max90DaysFromNow.setMonth(max90DaysFromNow.getMonth() + 3);
max90DaysFromNow.setHours(0, 0, 0, 0);

export const schema = yup.object().shape({
	startDate: dateRequired.when('isAdmin', {
		is: true,
		then: dateRequired.min(dateStartAdmin.toISOString(), ({ min }) =>
			moment(min).isValid() ? `Start date to be after ${parseDateToShow(min as string)}` : 'Invalid Start Date'
		),
		otherwise: dateRequired.min(DateAux.toISOString(), ({ min }) =>
			moment(min).isValid() ? `Start date to be after ${parseDateToShow(min as string)}` : 'Invalid Start Date'
		)
	}),
	endDate: dateRequired.max(max90DaysFromNow.toISOString(), ({ max }) =>
		moment(max).isValid() ? `End date to be before ${parseDateToShow(max as string)}` : 'Invalid Start Date'
	),
	duration: numberRequired,
	zoneId: numberRequired,
	allZoneSeats: boolean,
	resourceId: numberRequired,
	previousId: string,
	previousEndDate: string,
	previousStartDate: string,
	blockedReason: stringRequired
});

export const defaultValues = {
	startDate: '',
	endDate: '',
	duration: '',
	zoneId: '',
	resourceId: '',
	isAdmin: false,
	validated: true,
	allZoneSeats: false,
	previousId: '',
	previousStartDate: '',
	previousEndDate: '',
	blockedReason: ''
};
