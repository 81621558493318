import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { getUsersAdd, sendUsersAdd, clearUsersError } from 'store/administration/users/thunks';

import NewEmployee from './NewEmployee';

const mapStateToProps = ({ users }: AppState) => ({ users });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getUsersAdd, sendUsersAdd, clearUsersError }, dispatch);

export type NewEmployeeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NewEmployee);
