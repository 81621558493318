/* eslint-disable @typescript-eslint/no-explicit-any */
import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	selectedLabel: {
		color: `${theme.accentColor} !important` as unknown as any,
		fontWeight: '600 !important' as unknown as any
	},

	budgetToolbar: {
		boxShadow: '0px 20px 14px -5px #79797d29',
		width: 'calc(100% + 45px)',
		padding: '0 10px',
		margin: '20px -23px 50px -23px'
	},
	fieldWithInfo: {
		color: theme.accentColor,
		paddingRight: '15px'
	},
	circle: {
		display: 'inline-table',
		verticalAlign: 'middle',
		width: '22px',
		height: '22px',
		backgroundColor: theme.accentColor,
		borderRadius: '50%'
	},
	circleContent: {
		fontSize: '12px',
		fontWeight: `${400} !important` as unknown as any,
		color: 'white',
		fontFamily: 'Open Sans',
		display: 'table-cell',
		verticalAlign: 'middle',
		textAlign: 'center'
	},
	labelContainer: {
		margin: '0 20px',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		alignContent: 'stretch',
		alignItems: 'center'
	},
	items: {
		marginLeft: 'auto',
		'&:hover': {
			'& span:nth-child(2) span:nth-child(2) > span': {
				opacity: 1,
				transition: 'opacity 0.15s'
			}
		}
	},
	selectButtonContainer: {
		color: '#718FA2',
		fontSize: '10px',
		fontWeight: 400,
		fontFamily: 'Open Sans',
		// padding: '5px',
		opacity: 0,
		right: 0,
		transition: 'opacity 0.15s',
		position: 'absolute',
		// marginRight: '17px',
		'&:hover': {
			opacity: 1,
			transition: 'opacity 0.15s'
		}
	},
	selectButton: {
		padding: '6px 20px'
	},
	divider: {
		width: '100%',
		height: '1px',
		padding: '10px 0',
		marginBottom: '20px',
		borderBottom: '1px solid #B4BFCA'
	},
	budgetTableLabel: {
		fontSize: '12px',
		color: theme.typographyColor,
		fontFamily: 'Open Sans Bold',
		textTransform: 'uppercase'
	},
	budgetCategoryType: {
		fontSize: '16px',
		color: '#3D464D',
		fontFamily: 'Open Sans SemiBold',
		paddingLeft: '24px !important'
	},
	readOnlyInput: {
		'& div': {
			'& > input': {
				fontSize: '16px',
				fontWeight: 400,
				fontFamily: 'Open Sans',
				color: '#718FA2'
			}
		}
	}
}));

export { useStyles };
