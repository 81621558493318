import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { submitCollaborationTypeReject } from 'store/administration/collaborationTypesManagement/thunks';

import RejectCollaborationType from './RejectCollaborationType';
import { setNotificationAsReadProposals } from 'store/notifications/thunks';

const mapStateToProps = ({ collaborationTypesManagement }: AppState) => ({ collaborationTypesManagement });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			submitCollaborationTypeReject,
			setNotificationAsReadProposals
		},
		dispatch
	);

export type RejectCollaborationTypeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(RejectCollaborationType);
