/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { Grid } from '@mui/material';

import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { InfoField } from 'xpand-ui/forms';

/** Components struct */
export const GroupCard: FC<any> = props => {
	const { classes } = props;
	return (
		<Grid xs={12} sm={12} md={12} lg={10} xl={9} {...props} classes={{}} className={classes.groupCardResume} item />
	);
};

export const PersonalInfoCardView: FC<any> = ({ classes, payload }) => (
	<GroupCard classes={classes}>
		<Grid container spacing={4}>
			<Grid item {...sizes[3]}>
				<InfoField label="Address" value={payload?.address || ''} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField
					label="Gender"
					value={
						(payload?.gender === 'M' && 'Male') || (payload?.gender === 'F' && 'Female') || payload?.gender
					}
				/>
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Mobile Phone" value={payload?.phone || ''} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Native Language" value={payload?.nativeLanguage || ''} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Country Origin" value={payload?.countryOrigin || ''} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Country Code" value={payload?.countryCode || ''} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Birthday" value={parseDateToShow(payload?.birthday)} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="BirthPlace" value={payload?.birthplace || ''} />
			</Grid>
			<Grid item {...sizes[12]}>
				<InfoField label="Description" value={payload?.description || ''} />
			</Grid>
		</Grid>
	</GroupCard>
);

export const EducationInfoCardView: FC<any> = ({ classes, payload }) => (
	<GroupCard classes={classes}>
		<Grid container spacing={4} style={{ padding: '25px 25px 0 25px' }}>
			{/* NATIVE LANGUAGE */}
			<Grid item {...sizes[3]}>
				<InfoField label="Level in Nation Classification" value={payload?.classification || ''} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Start Date" value={parseDateToShow(payload?.startDate)} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Start Date" value={parseDateToShow(payload?.endDate)} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Organization Name" value={payload?.organizationName} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Main Subjects" value={payload?.mainSubjects} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Minor degree" value={payload?.minor} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Major degree" value={payload?.major} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Name of Certification Awarded" value={payload?.certificationAwarded} />
			</Grid>
		</Grid>
	</GroupCard>
);

export const WorkExperienceInfoCardView: FC<any> = ({ classes, payload }) => (
	<GroupCard classes={classes}>
		<Grid container spacing={4} style={{ padding: '25px 25px 0 25px' }}>
			<Grid item {...sizes[3]}>
				<InfoField label="Start Date" value={parseDateToShow(payload?.startDate)} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="End Date" value={parseDateToShow(payload?.endDate)} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Type of Business Sector" value={payload?.businessSector} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Occupation or Position Held" value={payload?.occupation} />
			</Grid>
			<Grid item />
			<Grid item {...sizes[12]}>
				<InfoField
					label="Main Activities, Responsabilities and Technologies used"
					value={payload?.mainActivities}
				/>
			</Grid>
		</Grid>
	</GroupCard>
);

export const WorkshopsInfoCardView: FC<any> = ({ classes, payload }) => (
	<GroupCard classes={classes}>
		<Grid container spacing={4} style={{ padding: '25px 25px 0 25px' }}>
			<Grid item {...sizes[3]}>
				<InfoField label="Title" value={payload?.title || ''} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Date" value={parseDateToShow(payload?.date)} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Type and Location" value={payload?.location} />
			</Grid>
			<Grid item {...sizes[12]}>
				<InfoField label="Subject" value={payload?.subject} />
			</Grid>
		</Grid>
	</GroupCard>
);

export const OtherProjectsInfoCardView: FC<any> = ({ classes, payload }) => (
	<GroupCard classes={classes}>
		<Grid container spacing={4} style={{ padding: '25px 25px 0 25px' }}>
			<Grid item {...sizes[6]}>
				<InfoField label="Project Name" value={payload?.name} />
			</Grid>
			<Grid item {...sizes[6]} />
			<Grid item {...sizes[12]}>
				<InfoField label="Main activities and responsabilities" value={payload?.mainActivities} />
			</Grid>
		</Grid>
	</GroupCard>
);

export const TrainingInfoCardView: FC<any> = ({ classes, payload }) => (
	<GroupCard classes={classes}>
		<Grid container spacing={4} style={{ padding: '25px 25px 0 25px' }}>
			<Grid item {...sizes[3]}>
				<InfoField label="Title" value={payload?.title || ''} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Start Date" value={parseDateToShow(payload?.startDate)} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="End Date" value={parseDateToShow(payload?.endDate)} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Name and Type of organization" value={payload?.organizationName} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Main subjects and occupation skills covered" value={payload?.mainSubjects} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Name of certification / training awarded" value={payload?.certificationAwarded} />
			</Grid>
			<Grid item {...sizes[3]}>
				<InfoField label="Level Obtained" value={payload?.level} />
			</Grid>
		</Grid>
	</GroupCard>
);
