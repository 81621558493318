import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';
import {
	getSearchResumes,
	exportResume,
	exportMultipleResumes,
	sendPostRemindResume,
	clearSkillsAndResumesError
} from 'store/administration/skillsAndResumes/thunks';
import { setSearchResumesPageFilter } from 'store/filters/actions';

import { getSystemSkills } from 'store/personalInfo/thunks';

import SearchResumes from './SearchResumes';

const mapStateToProps = ({ skillsAndResumes, personalInfo, filters }: AppState) => ({
	skillsAndResumes,
	personalInfo,
	filters
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getSearchResumes,
			exportResume,
			exportMultipleResumes,
			sendPostRemindResume,
			getSystemSkills,
			clearSkillsAndResumesError,
			setSearchResumesPageFilter
		},
		dispatch
	);

export type SearchResumesProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SearchResumes);
