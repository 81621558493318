/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { Input, Select, InfoField } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { IUserProfile } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';

//* LOCAL COMPONENT IMPORTS
import { InfoCards } from '../../../utils';
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';

const validateOneZeroInputs = (field: any) => (field === 0 && '0') || (field === 1 && '1') || field;

interface IFiscalInfo {
	userProfile: IUserProfile;
	handleApiSubmit: any;
	isAdmin: string;
}

const FiscalInfo: FC<IFiscalInfo> = ({ userProfile, handleApiSubmit, isAdmin }) => {
	const classes = useStyles();
	const [editMode, setEditMode] = useState(false);
	const [holderStatusOptions, setHolderStatusOptions] = useState([
		{ id: '1', label: 'Single Holder' },
		{ id: '2', label: 'Two Holders' }
	]);

	const {
		handleSubmit,
		control,
		reset,
		watch,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const userERPData = userProfile?.user?.erpData;

	const getInitialData = () => ({
		maritalStatus: userERPData?.xPHRMaritalStatus,
		holdersNumber: validateOneZeroInputs(userERPData?.xphrHoldersNumber),
		numberOfDependents: validateOneZeroInputs(userERPData?.xphrNumberOfDependents)
	});

	useEffect(() => {
		if (userProfile) {
			reset(getInitialData());
		}
	}, [userProfile]);

	// This useEffect checks the maritalStatus and defines the numbers of holders as 1 if the maritalStatus is equal to "single, divorced or widower"
	// If the maritalStatus is equal to "Married" the user can choose between 1 or 2 holders
	useEffect(() => {
		if (watch('maritalStatus') === 'Married') {
			setHolderStatusOptions([
				{ id: '1', label: 'Single Holder' },
				{ id: '2', label: 'Two Holders' }
			]);
		} else {
			setHolderStatusOptions([{ id: '1', label: 'Single Holder' }]);
			setValue('holdersNumber', 1);
		}
	}, [watch('maritalStatus')]);

	interface IUpdateFiscalInfo2 {
		maritalStatus?: string;
		holdersNumber?: number;
		numberOfDependents?: number;
		bankId?: string;
		iban?: string;
	}

	const removeBankInfo: FC<IUpdateFiscalInfo2> = ({ bankId, iban, ...formData }): any => formData;

	const onSubmit = (data: IUpdateFiscalInfo2) => {
		if (JSON.stringify(removeBankInfo(data)) !== JSON.stringify(removeBankInfo(getInitialData()))) {
			handleApiSubmit([
				{
					type: 'fiscalInfo',
					data: {
						...getInitialData(),
						...removeBankInfo(data)
					}
				}
			]);
		} else {
			// eslint-disable-next-line no-alert
			addNotification('info', 'Nothing to Update');
		}
		setEditMode(false);
		return null;
	};

	const taxZoneField = (
		<>
			<Grid item {...sizes[6]}>
				<InfoField fontSize="lg" label="TAX ZONE" value={userERPData?.currentCsc?.xphrTaxzone} />
			</Grid>
			{!isAdmin && (
				<Grid className={classes.rightContainer} item {...sizes[6]}>
					<InfoField
						fontSize="lg"
						label={
							<>
								TO REQUEST FOR CHANGES IN YOUR TAX ZONE PLEASE OPEN A{' '}
								<a
									style={{ fontSize: '12px' }}
									href={'https://apps.xpand-it.com/jirasd/servicedesk/customer/portal/3/group/56'}
									rel="noopener noreferrer"
									target="_blank">
									Service Desk
								</a>{' '}
								TICKET.
							</>
						}
						value=""
					/>
				</Grid>
			)}
		</>
	);

	return (
		<InfoCards
			title="Fiscal Information"
			form="form-profile-fiscal-info"
			classes={classes}
			reset={reset}
			editMode={editMode}
			setEditMode={setEditMode}>
			<form id="form-profile-fiscal-info" onSubmit={handleSubmit(() => onSubmit(watch()))}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
					{!editMode && (
						<>
							<Grid item {...sizes[6]}>
								<InfoField fontSize="lg" label="TAX ID" value={userERPData?.taxID} />
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="SOCIAL SECURITY ID"
									value={userERPData?.xPHRSOCIALSECURITYNUMBER}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="MARITAL STATUS"
									value={userERPData?.xPHRMaritalStatus}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField fontSize="lg" label="HOLDERS" value={userERPData?.xphrHoldersNumber} />
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="NUMBER OF DEPENDENTS"
									value={
										userERPData?.xphrNumberOfDependents === 0
											? '0'
											: userERPData?.xphrNumberOfDependents
									}
								/>
							</Grid>
							{taxZoneField}
						</>
					)}
					{editMode && (
						<>
							<Grid item {...sizes[6]}>
								<InfoField fontSize="lg" label="TAX ID" value={userERPData?.taxID} />
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="SOCIAL SECURITY ID"
									value={userERPData?.xPHRSOCIALSECURITYNUMBER}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Select
									required
									name="maritalStatus"
									label="MARITAL STATUS"
									options={userProfile?.maritalStatus?.map(e => ({ id: e.searchKey, label: e.name }))}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Select
									required
									name="holdersNumber"
									label="HOLDERS"
									options={holderStatusOptions}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Input
									name="numberOfDependents"
									label="NUMBER OF DEPENDENTS"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
							{taxZoneField}
						</>
					)}
				</Grid>
			</form>
		</InfoCards>
	);
};

export default FiscalInfo;
