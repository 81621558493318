/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useState } from 'react';
import clsx from 'clsx';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography, Popover, IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { Select, DatePicker, Input, InputLabel, Checkbox, Radio } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { HelperProps } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { OwnershipRadioOptions, TemplateOptions, ApplicationOptions, ApplicationOptionsBalwurk } from '../../utils';
import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';
import { getProposalReferenceIds } from '../../yupSchema';
import { useSelector } from 'react-redux';

//* COMPONENT INTERFACES
interface IProjectInformation extends IProjectTabs {
	setNewProjectHelper: React.Dispatch<React.SetStateAction<HelperProps>>;
}

//* COMPONENT
const ProjectInformation: FC<IProjectInformation> = ({
	urlStandardProjectName,
	companySelectOptions,
	projectRelatedOptions,
	setNewProjectHelper,
	setSelectedCompanyLabel,
	control,
	errors,
	// reset,
	// getValues,
	setValue,
	resetField,
	watch
}) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event: any) => setAnchorEl(event.currentTarget);
	const typeHelper = watch('type');
	const companyInfo = useSelector(state => state.company);
	return (
		<Grid container spacing={4} className={classes.root}>
			<Grid item {...sizes[6]}>
				<Select
					name="company"
					label="Company Owner"
					options={companySelectOptions.company}
					helperText="Choose which company will own the project."
					additionalOnChange={(item: { value: string; label: string }) => {
						setNewProjectHelper(prev => ({
							...prev,
							company: item.value,
							businessUnit: null,
							division: null,
							department: null
						}));
						setSelectedCompanyLabel(item.label);
						setValue('businessUnit', '');
						setValue('division', '');
						setValue('department', '');
						setValue('type', '');
					}}
					control={control}
					errors={errors}
					required
				/>
				{watch('company') === 'UK' && (
					<Checkbox
						name="createForPT"
						label="CREATE PROJECT FOR XPAND IT PT?"
						control={control}
						errors={errors}
					/>
				)}
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="projectCode"
					label="Project Code"
					showErrorAndHelperText
					helperText={
						<>
							Project code compliant with the{' '}
							<a
								className={classes.link}
								target="_blank"
								rel="noopener noreferrer"
								href={urlStandardProjectName}>
								Project Naming Standard{' '}
							</a>
						</>
					}
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="name"
					label="Project Name"
					showErrorAndHelperText
					helperText={
						<>
							Project name compliant with the{' '}
							<a
								className={classes.link}
								target="_blank"
								rel="noopener noreferrer"
								href={urlStandardProjectName}>
								Project Naming Standard{' '}
							</a>{' '}
							and cannot be longer than 50 characters
						</>
					}
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<DatePicker
					name="startDate"
					label="Start Date"
					helperText="Start date for this project"
					additionalOnChange={date => {
						date &&
							setNewProjectHelper(prev => ({
								...prev,
								startDate: date
							}));
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<DatePicker
					name="plannedEndDate"
					label="Planned End Date"
					helperText="Planned end date for this project"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="type"
					label="Type"
					helperText="Type of project"
					options={companySelectOptions.projectTypes}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							type: item.value
						}));
						resetField('proposalReference');
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			{getProposalReferenceIds().includes(typeHelper) && (
				<Grid item {...sizes[6]}>
					<Input
						name="proposalReference"
						label="Jira Sales CRM link"
						required
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			<Grid item {...sizes[6]}>
				<Select
					name="businessUnit"
					label="Business Unit"
					options={companySelectOptions.businessUnit}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							businessUnit: item.value,
							division: null,
							department: null
						}));
						setValue('division', '');
						setValue('department', '');
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="division"
					label="Division"
					options={companySelectOptions.division}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							division: item.value,
							department: null
						}));
						setValue('department', '');
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="department"
					label="Department"
					options={companySelectOptions.department}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							department: item.value
						}));
					}}
					helperText="Department responsible for the project"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="lineOfBusiness"
					label="Line Of Business"
					options={companySelectOptions.lineOfBusiness}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							lineOfBusiness: item.value
						}));
					}}
					helperText="Line of Business of this project"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="costType"
					label="Cost Type"
					options={projectRelatedOptions.costTypes}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							costType: item.value
						}));
						resetField('hubDistributionScheme');
						resetField('salesType');
					}}
					helperText="Cost type for this project"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="hubDistributionScheme"
					label="Hub Distribution Scheme"
					options={
						watch('costType') === 'dir'
							? [{ id: 'NA', label: 'Not Applicable' }]
							: projectRelatedOptions.hubDistributionSchemes
					}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							hubDistributionScheme: item.value
						}));
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			{watch('costType') === 'dir' && (
				<Grid item {...sizes[6]}>
					<Select
						name="salesType"
						label="Project Sales Type"
						options={projectRelatedOptions.projectSalesTypes}
						additionalOnChange={item => {
							setNewProjectHelper(prev => ({
								...prev,
								salesTypes: item.value
							}));
						}}
						helperText="Sales type for this project"
						control={control}
						errors={errors}
						required
					/>
				</Grid>
			)}

			{getProposalReferenceIds().includes(typeHelper) && (
				<Grid item {...sizes[12]}>
					<InputLabel>Project sponsor</InputLabel>
				</Grid>
			)}

			{getProposalReferenceIds().includes(typeHelper) && (
				<Grid item {...sizes[12]}>
					<Typography component="span" className={classes.infoLabel}>
						Project sponsor is someone who represents customers interests (Product Owner). It must be
						external to company
					</Typography>
				</Grid>
			)}

			{getProposalReferenceIds().includes(typeHelper) && (
				<Grid item {...sizes[6]}>
					<Input
						name="sponsorName"
						label="Project Sponsor name"
						helperText="Name of the product owner or other sponsor of this project. Cannot be longer than 50 characters"
						required
						control={control}
						errors={errors}
					/>
				</Grid>
			)}

			{getProposalReferenceIds().includes(typeHelper) && (
				<Grid item {...sizes[6]}>
					<Input
						name="sponsorEmail"
						label="Project Sponsor email"
						helperText="Email of the product owner or other sponsor of this project. Cannot be longer than 50 characters"
						required
						control={control}
						errors={errors}
					/>
				</Grid>
			)}

			<Grid item {...sizes[6]}>
				<Checkbox
					name="isTimeAndMaterials"
					label="TIME & MATERIALS?"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="applications"
					label="Applications"
					multiple
					options={companyInfo.companyText !== 'Balwurk' ? ApplicationOptions : ApplicationOptionsBalwurk}
					additionalOnChange={item => {
						setNewProjectHelper(prev => ({
							...prev,
							applications: item.value
						}));
					}}
					helperText="Applications needed by this project"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Checkbox
					name="isMultiUnitProject"
					label="MULTI UNIT PROJECT"
					helperText="Select whenever the project involves more than one business unit"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
		</Grid>
	);
};

export default ProjectInformation;
