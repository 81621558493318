import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import {
	// new
	getNewEmployeeSalary,
	// submit new
	submitNewEmployeeSalary,
	// edit
	getEditEmployeeSalary,
	// submit edit
	submitUpdateEmployeeSalary,
	// Clear form
	clearProposalsFormsData,
	// HELPERS %%%%%%%%%%%%%%%%
	// calculate  irs
	getIrsValue,
	clearCscError
} from 'store/administration/employeeSalaryCategories/thunks';

import {
	// HELPERS %%%%%%%%%%%%%%%%
	// calculate annual gross
	getProposalAnnualGrossValue,
	// calculate summary
	getProposalSummary
} from 'store/administration/proposals/thunks';

import NewEmployeeSalary from './NewEmployeeSalary';

const mapStateToProps = ({ proposals, employeeSalary, company }: AppState) => ({ proposals, employeeSalary, company });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			// new
			getNewEmployeeSalary,
			// submit new
			submitNewEmployeeSalary,
			// edit
			getEditEmployeeSalary,
			// submit edit
			submitUpdateEmployeeSalary,
			// HELPERS %%%%%%%%%%%%%%%%
			// calculate annual gross
			getProposalAnnualGrossValue,
			// calculate  irs
			getIrsValue,
			// calculate summary
			getProposalSummary,
			// Clear form
			clearProposalsFormsData,
			clearCscError
		},
		dispatch
	);

export type NewEmployeeSalaryProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NewEmployeeSalary);
