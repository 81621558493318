import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { AppState } from 'typings/state';

import { getExpensesData, submitNewExpenseSheet, deleteExpenseData, copyExpenseSheet } from 'store/personalInfo/thunks';
import { getGuideData, getUserGuideData, setUserGuideAsRead } from 'store/guides/thunks';
import { setMyExpensesPageFilter } from 'store/filters/actions';

import MyExpenses from './Expenses';

const mapStateToProps = ({ personalInfo, filters, guides }: AppState) => ({ personalInfo, filters, guides });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getExpensesData,
			deleteExpenseData,
			submitNewExpenseSheet,
			copyExpenseSheet,
			setMyExpensesPageFilter,
			getGuideData,
			getUserGuideData,
			setUserGuideAsRead
		},
		dispatch
	);

export type MyExpensesProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyExpenses);
