import { restCall } from 'lib/handlers/restHandler';

import { OriginalResponse, Success, GET, POST, EXPORTFILE } from 'lib/handlers/requestTypes';

//* users list table
export const fetchResignationList = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/resignations/list');

//* resign form page
// ? GET request
export const fetchResignationForm = async (userId: number): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/resignations/resign/${userId}`);

// ? POST request
export const submitResignUser = async (userId: number, payload: unknown): Promise<OriginalResponse> =>
	restCall(POST, `/admin/resignations/resign/${userId}`, payload, undefined, true) as unknown as OriginalResponse;

//* deactivate form page
// ? GET request
export const fetchDeactivateForm = async (userId: number): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/resignations/deactivate/${userId}`);

// ? POST request
export const submitDeactivateUser = async (userId: number, payload: unknown): Promise<OriginalResponse> =>
	restCall(POST, `/admin/resignations/deactivate/${userId}`, payload, undefined, true) as unknown as OriginalResponse;

// Export Work Certificate
export const fetchExportCertificate = async (username: string): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILE, `/admin/resignations/export/certificate/${username}`, username);

// Export Resign Receipt
export const fetchExportReceipt = async (username: string): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILE, `/admin/resignations/export/receipt/${username}`, username);

// resignations/export/receipt/
