import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	leftCardSection: {
		textAlign: 'center',
		margin: '-20px 0 0 0'
	},
	popover: {
		pointerEvents: 'none'
	},
	paper: {
		padding: theme.spacing(1)
	},
	popoverLabel: {
		fontSize: '13px',
		color: theme.grayedColor
	},
	profileActionLabel: {
		fontSize: '15px',
		color: '#536B7A',
		textTransform: 'none'
	}
}));

export { useStyles };
