import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import { FiltersActions as Actions } from 'typings/store/filtersTypes';

// Actions to set filters whenever needed
export const setAppsPageFilter = (payload: unknown): IDispatchType => action(Actions.APPS_FILTER, payload);

export const setMyTrainingsPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.MY_TRAININGS_FILTER, payload);

export const setMyFinancialDocumentsPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.MY_FINANCIAL_DOCUMENTS_FILTER, payload);

export const setListEmployeesPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.LIST_EMPLOYEES_FILTER, payload);

export const setResignationManagementPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.RESIGNATION_MANAGEMENT_FILTER, payload);

export const setManageAdmissionsPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.MANAGE_ADMISSIONS_FILTER, payload);

export const setCSCPageFilter = (payload: unknown): IDispatchType => action(Actions.CSC_FILTER, payload);
export const setECPageFilter = (payload: unknown): IDispatchType => action(Actions.EC_FILTER, payload);

export const setCSCDetailsPageFilter = (payload: unknown): IDispatchType => action(Actions.CSC_DETAILS_FILTER, payload);

export const setCSCDetailsHistoryPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.CSC_DETAILS_HISTORY_FILTER, payload);

export const setContractManagementPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.CONTRACT_MANAGEMENT_FILTER, payload);

export const setProposalsPageFilter = (payload: unknown): IDispatchType => action(Actions.PROPOSALS_FILTER, payload);

export const setProposalsDetailsPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.PROPOSALS_DETAILS_FILTER, payload);

export const setBudgetPageFilter = (payload: unknown): IDispatchType => action(Actions.BUDGET_FILTER, payload);

export const setSkillsReminderPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.SKILLS_REMINDER_FILTER, payload);

export const setSearchResumesPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.SEARCH_RESUMES_FILTER, payload);

export const setAccessControlPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.ACCESS_CONTROL_FILTER, payload);

export const setAccessControlAddPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.ACCESS_CONTROL_ADD_FILTER, payload);

export const setEmailTemplatesPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.EMAIL_TEMPLATES_FILTER, payload);

export const setCacheManagementPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.CACHE_MANAGEMENT_FILTER, payload);

export const setFileTemplatesPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.FILE_TEMPLATES_FILTER, payload);

export const setReleaseHistoryPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.RELEASE_HISTORY_FILTER, payload);

export const setPlannerFilters = (payload: unknown): IDispatchType => action(Actions.PLANNER_FILTERS, payload);

export const setAllEmployeesPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.ALL_EMPLOYEES_FILTERS, payload);

export const setUsersPageFilter = (payload: unknown): IDispatchType => action(Actions.USERS_PAGE_FILTERS, payload);

export const setMyTimesheetsFilter = (payload: unknown): IDispatchType =>
	action(Actions.MY_TIMESHEETS_FILTERS, payload);

export const setClearAllFilters = (): IDispatchType => action(Actions.CLEAR_ALL_FILTERS);

export const setMyExpensesPageFilter = (payload: unknown): IDispatchType =>
	action(Actions.MY_EXPENSES_FILTERS, payload);
