/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { Dispatch } from 'redux';
import { fetchUserPermissions, refreshUserData } from 'lib/api/system/perms';
import { setLSField } from 'lib/utils/cookies';
import { SystemState, IUserAction } from 'typings/store/systemTypes';
import { setLoading, setError, setUserPerm, setDataRefreshed, setUserIsExternal } from './actions';

type IUserPermissionsAndExternal = {
	data?: {
		roles: IUserAction[];
		isExternal: boolean | undefined;
	};
};

export const clearSystemError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setError(null));
	};

export const getUserPermissions =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data }: IUserPermissionsAndExternal = await fetchUserPermissions();

			const permissions =
				data?.approvalsAccess && data?.approvalsAccess == true ? [...data?.roles] : data?.roles || [];

			setLSField('userPermissions', JSON.stringify(permissions));

			const userActions = permissions || [];

			setLSField('userActions', JSON.stringify(userActions));

			dispatch(
				setUserPerm({
					userPermissions: permissions,
					approvalsAccess: data?.approvalsAccess,
					userAccountRoleId: data?.userAccountRoleId,
					userActions
				})
			);
			dispatch(setUserIsExternal(data?.isExternal));
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const checkUserPermissions =
	(store: SystemState) =>
	async (dispatch: Dispatch): Promise<void> => {
		if (store && !store.userPermissions) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			dispatch(getUserPermissions() as any);
		}
	};

export const clearCache =
	(username?: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			localStorage.clear();
			const response = await refreshUserData(username);

			if (response) {
				dispatch(setDataRefreshed());
				setTimeout(() => window.location.reload(), 2000);
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};
