import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import { SystemActions as Actions, UserInformation } from 'typings/store/systemTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setError = (payload: unknown | null): IDispatchType => action(Actions.SET_ERROR, payload);

export const setUserPerm = (payload: UserInformation | null): IDispatchType =>
	action(Actions.GET_USER_PERMISSIONS, payload);

export const setUserIsExternal = (payload: boolean | undefined): IDispatchType =>
	action(Actions.GET_USER_IS_EXTERNAL, payload);

export const setDataRefreshed = (): IDispatchType => action(Actions.REFRESH_USER_PERMISSIONS, {});
