/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, FC } from 'react';
import { Dialog } from 'xpand-ui/core';
import { useDispatch } from 'react-redux';
import { sizes } from 'xpand-ui/utils/handlers';
import { Grid } from '@mui/material';
import { useStyles } from './styles';
import { InfoField } from 'xpand-ui/forms';
import './ViewDetailsModal.css';

//* COMPONENT INTERFACES

// Calendar event Categories
const getCategory = (state: any) => {
	switch (state) {
		case 1:
			return {
				name: 'Not Submitted',
				color: '#FAC15F'
			};
		case 2:
			return {
				name: 'Submitted',
				color: '#2BAAFF'
			};
		case 3:
			return {
				name: 'Approved',
				color: '#21BA45'
			};
		case 4:
			return {
				name: 'Rejected',
				color: '#D1345E'
			};
		default:
			return {
				name: 'Not Submitted',
				color: '#FAC15F'
			};
	}
};

const ViewDetailsModal: FC<any> = ({ eventDetails, isViewDetails, setIsViewDetails }) => {
	const cat = getCategory(eventDetails?.state);

	const totalTime = new Date(eventDetails?.totalTime);

	return (
		<Dialog
			modal={{
				open: Boolean(isViewDetails),
				handleClose: (event, reason) => {
					if (reason === 'backdropClick') {
						setIsViewDetails(false);
					}
				},
				content: (
					<>
						<div
							className="expanded-event-line"
							style={{
								backgroundColor: cat.color
							}}></div>
						<div style={{ marginBottom: '20px' }}></div>
						<Grid
							container
							direction="row"
							alignItems="center"
							justifyContent="space-evenly"
							spacing={3}
							className="expanded-event-details">
							<Grid item {...sizes[12]}>
								<InfoField
									label="Project Name"
									value={eventDetails?.projectName || eventDetails?.title || ''}
								/>
							</Grid>
							<Grid item {...sizes[12]}>
								<InfoField label="Project Task" value={eventDetails?.projectTaskName || ''} />
							</Grid>
							<Grid item {...sizes[12]}>
								<InfoField label="Cost Center" value={eventDetails?.costCenterName || ''} />
							</Grid>
							<Grid item {...sizes[12]}>
								<InfoField label="Work Type" value={eventDetails?.workTypeName || ''} />
							</Grid>
							<Grid item {...sizes[12]}>
								<InfoField
									label="Total Time"
									value={
										totalTime.getHours().toString().padStart(2, '0') +
											':' +
											totalTime.getMinutes().toString().padStart(2, '0') || ''
									}
								/>
							</Grid>
							<Grid item {...sizes[12]}>
								<InfoField label="Description" value={eventDetails?.description} />
							</Grid>
						</Grid>
					</>
				)
			}}
			title={'Event Details'}
			actions={[
				{
					id: 'closeModal',
					label: 'Close',
					color: 'primary',
					variant: 'contained',
					onClick: () => setIsViewDetails(false)
				}
			]}
			scroll="body"
		/>
	);
};
export default ViewDetailsModal;
