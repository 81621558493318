import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		width: 'calc( 100% + 32px)',
		marginLeft: '-16px'
	},
	tableRegularColumn: {
		color: theme.palette.secondary.main,
		fontSize: '14px',
		fontFamily: 'Open Sans'
	}
}));

export { useStyles };
