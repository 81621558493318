import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import {
	CollaborationTypeActions as Actions,
	IGetEmployeeList
} from 'typings/store/admin/collaborationTypesManagement';
import { OriginalResponse } from 'lib/handlers/requestTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);

export const setCollaborationTypeError = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.SET_ERROR, payload);

export const setEmployeesList = (payload: IGetEmployeeList | null): IDispatchType =>
	action(Actions.GET_EMPLOYEES_LIST, payload);

export const setEditEmployee = (payload: any | null): IDispatchType => action(Actions.GET_EMPLOYEE_INFO, payload);

export const setCollaborationTypeList = (payload: any | null): IDispatchType =>
	action(Actions.GET_COLLABORATION_TYPE_LIST, payload);

export const setPostNewCollaborationType = (payload: any | null): IDispatchType =>
	action(Actions.SUBMIT_NEW_COLLABORATION_TYPE, payload);

export const setEditCollaborationType = (payload: any | null): IDispatchType =>
	action(Actions.GET_EDIT_COLLABORATION_TYPE, payload);

export const setCollaborationTypeCancel = (payload: [] | null, hash: string | null): IDispatchType =>
	action(Actions.SET_COLLABORATION_TYPE_CANCEL, { payload, hash });

// RESET REDUCER
export const resetFormData = (): IDispatchType => action(Actions.RESET_FORM_DATA);

// APPROVE CSC
export const setCollaborationTypeApprove = (payload: OriginalResponse | null, hash: string | null): IDispatchType =>
	action(Actions.SET_COLLABORATION_TYPE_APPROVE, { payload, hash });

// REJECT CSC
export const setCollaborationTypeReject = (payload: string | null, hash: string | null): IDispatchType =>
	action(Actions.SET_COLLABORATION_TYPE_REJECT, { payload, hash });

// REQUEST APPROVAL CSC
export const setCollaborationTypeRequestApproval = (payload: [] | null, hash: string | null): IDispatchType =>
	action(Actions.SET_COLLABORATION_TYPE_REQUEST_APPROVAL, { payload, hash });

// CLOSE CSC
export const setCollaborationTypeClose = (payload: [] | null, hash: string | null): IDispatchType =>
	action(Actions.SET_COLLABORATION_TYPE_CLOSE, { payload, hash });

export const resetEmployee = (): IDispatchType => action(Actions.RESET_EMPLOYEE);

export const resetEmployeeActions = (): IDispatchType => action(Actions.RESET_EMPLOYEE_ACTIONS);

export const setInvalidDocuments = (payload: string[]): IDispatchType => action(Actions.SET_INVALID_DOCS, payload);
