import { createReducer } from 'typesafe-actions';
import { NotificationsState, NotificationsActions as Actions } from 'typings/store/notificationsTypes';
import { legacy_createStore as createStore } from 'redux';

const INITIAL_STATE: NotificationsState = {
	notificationsList: []
};

const NotificationsReducer = createReducer(INITIAL_STATE, {
	// Actions responsible for the notifications
	[Actions.GET_NOTIFICATIONS]: (state, { payload }) => ({
		...state,
		notificationsList: payload
	}),
	[Actions.SET_NOTIFICATION]: (state, { payload }) => ({
		...state,
		notificationsList: payload
	}),
	[Actions.MARK_NOTIFICATION_AS_READ]: (state, { payload }) => ({
		...state,
		notificationsList: payload
	}),
	[Actions.DELETE_NOTIFICATION]: (state, { payload }) => ({
		...state,
		notificationsList: payload
	}),
	[Actions.POST_DELETE_ALL_USER_NOTIFICATIONS]: (state, { payload }) => ({
		...state,
		loading: false
	})
});

export const store = createStore(NotificationsReducer);

export default NotificationsReducer;
