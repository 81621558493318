import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import { GuidesActions as Actions } from 'typings/store/guidesTypes';

export const getGuide = (payload: unknown): IDispatchType => action(Actions.GET_GUIDE, payload);

export const getUserGuide = (payload: unknown): IDispatchType => action(Actions.GET_USER_GUIDE, payload);

export const markUserGuideAsRead = (payload: unknown): IDispatchType =>
	action(Actions.MARK_USER_GUIDE_AS_READ, payload);

export const cleanGuides = (): IDispatchType => action(Actions.CLEAN_GUIDES);
