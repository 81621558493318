import { restCall } from 'lib/handlers/restHandler';
import { GET, PUT, POST, OriginalResponse, Success, EXPORTFILE, EXPORTFILEPOST } from 'lib/handlers/requestTypes';
import { IIrsValuePost } from 'typings/store/admin/proposals';
import { IExportCSCsList, IPostPutCSC } from 'typings/store/admin/employeeSalaryCategories';

// CONTRACT MANAGEMENT
export const fetchEmployeesList = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/employees/list');

export const fetchEditEmployee = async (userID: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/collaborationType/manage/${userID}/editEmployee`);

export const fetchCollaborationTypeList = async (hash: string): Promise<OriginalResponse> =>
	restCall(GET, `/admin/collaborationType/details/${hash}`) as unknown as OriginalResponse;

export const postNewCollaborationType = async (payload: any, token: string): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/collaborationType/new${token ? `/${token}` : ''}`, {
		...payload,
		workScheduleExemptionValue: payload.ihtValue,
		prizeValue: payload.prizeBonus
	});

export const postEditCollaborationType = async (
	payload: any,
	token: string,
	id: string | number
): Promise<OriginalResponse | Success> =>
	restCall(PUT, `/admin/collaborationType/edit/${token}/${id}`, {
		...payload,
		workScheduleExemptionValue: payload.ihtValue,
		prizeValue: payload.prizeBonus
	});

export const fetchEditCollaborationType = async (
	hash: string,
	token: string,
	proposalId: number
): Promise<OriginalResponse | Success> => restCall(GET, `/admin/collaborationType/edit/${hash}/${token}/${proposalId}`);

export const collaborationTypeCancel = async (token: string, proposalId: number): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/collaborationType/cancel/${token}/${proposalId}`);

export const collaborationTypeApprove = async (
	token: string,
	proposalId: number,
	manager: string
): Promise<OriginalResponse> =>
	restCall(POST, `/admin/collaborationType/approve/${token}/${proposalId}/${manager}`) as unknown as OriginalResponse;

export const collaborationTypeReject = async (
	token: string,
	proposalId: number,
	manager: string,
	payload: unknown
): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/collaborationType/reject/${token}/${proposalId}/${manager}`, payload);

export const collaborationTypeRequestApproval = async (
	token: string,
	proposalId: number
): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/collaborationType/requestApproval/${token}/${proposalId}`);

export const collaborationTypeClose = async (token: string, proposalId: number): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/collaborationType/close/${token}/${proposalId}`, {});
