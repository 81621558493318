// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		toggleButtongroup: {
			height: '20px',
			alignSelf: 'center'
		},
		toggleButton: {
			border: 'none',
			'& .MuiToggleButton-label': {
				fontSize: '12px',
				textTransform: 'none'
			},
			'&:hover': {
				background: 'none'
			}
		},
		toggleButtonOverrides: {
			background: 'transparent !important',
			'& span:nth-child(1)': {
				borderBottom: '2px solid #3F80EF',
				color: '#3F80EF',
				fontSize: '12px',
				textTransform: 'none',
				fontWeight: 'bold'
			}
		}
	})
);

export { useStyles };
