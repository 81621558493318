import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, POST } from 'lib/handlers/requestTypes';
import { IIdDocs } from 'typings/store/admin/admissions';

export const fetchAppsList = async (): Promise<OriginalResponse | Success> => restCall(GET, '/apps');

export const postDocumentsValidation = async (payload: IIdDocs[]): Promise<OriginalResponse> =>
	restCall(POST, '/public/util/validateFields', payload) as unknown as OriginalResponse;

export const postIbanValidation = async (payload: string): Promise<OriginalResponse> =>
	restCall(POST, '/public/util/validateIban', payload) as unknown as OriginalResponse;
