import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { sendPostSkillsFeedback } from 'store/administration/skillsAndResumes/thunks';
import { AppState } from 'typings/state';

import SkillsFeedback from './SkillsFeedback';
import { setNotificationAsRead } from 'store/notifications/thunks';

const mapStateToProps = ({ skillsAndResumes }: AppState) => ({ skillsAndResumes });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ sendPostSkillsFeedback, setNotificationAsRead }, dispatch);

export type SkillsFeedbackProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SkillsFeedback);
