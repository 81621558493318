import CareerFinancial from './CareerFinancial';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'typings/state';
import { getProposalSummary } from 'store/administration/proposals/thunks';

const mapStateToProps = ({ proposals }: AppState) => ({ proposals });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getProposalSummary }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CareerFinancial);
