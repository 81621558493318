/* eslint-disable @typescript-eslint/no-explicit-any, react-hooks/rules-of-hooks */
//* EXTERNAL LIBS
import React, { FC } from 'react';
import clsx from 'clsx';

//* EXTERNAL LIBS --> MUI
import { usePopupState, bindTrigger, bindPopover, Variant } from 'material-ui-popup-state/hooks';
import { Typography, Grid, Button, Popover, Badge } from '@mui/material';
import { CheckRounded } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { ISkillSubGroup } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';

const getColumnNumber = (orderNumber: number) => {
	const column1 = [1, 4, 7, 10, 13, 16, 19];
	const column2 = [2, 5, 8, 11, 14, 17, 20];
	const column3 = [3, 6, 9, 12, 15, 18, 21];

	return (
		(column1.includes(orderNumber) && 'left') ||
		(column2.includes(orderNumber) && 'center') ||
		(column3.includes(orderNumber) && 'right') ||
		'center'
	);
};
//* COMPONENT INTERFACES
//* COMPONENT
const SkillModalButton: FC<any> = ({ order, id, skill, label, subTitle, selected, toggleSelected }) => {
	const classes = useStyles();
	const popupState = skill.section
		? usePopupState({
				variant: `popover${order}${id}` as unknown as Variant,
				popupId: `demoPopover${order}${id}`
		  })
		: undefined;
	if (skill.section && !popupState) {
		return null;
	}
	const transformVPos = order < 4 ? 'top' : 'bottom';
	const transformHPos = getColumnNumber(order);

	const SkButtonLabel = (
		<>
			<Typography
				className={clsx(classes.ASMButtonTitle, {
					[classes.ASMButtonTitleSel]: selected[id] || popupState?.isOpen
				})}>
				{label}
			</Typography>
			{subTitle && <Typography className={classes.ASMButtonSubTitle}>{subTitle}</Typography>}
		</>
	);

	const SkButton = (
		<Button
			fullWidth
			variant="outlined"
			className={clsx({ [classes.ASMButtonExpanded]: popupState?.isOpen })}
			onClick={() => {
				const newPayload = { ...selected, [id]: !selected[id] };
				if (newPayload[id] === false) delete newPayload[id];

				toggleSelected(newPayload);
			}}
			{...(popupState && { ...bindTrigger(popupState) })}
			classes={{
				outlined: selected[id] || popupState?.isOpen ? classes.buttonOutlinedSelected : classes.buttonOutlined,
				root: classes.buttonRoot
			}}>
			{selected[id] && !popupState && <CheckRounded color="primary" className={classes.selectedIcon} />}
			{selected[id] && popupState && (
				<Badge
					color="primary"
					className={classes.selectedBadge}
					badgeContent={Object.keys(selected[id]).length}
				/>
			)}
			{SkButtonLabel}
		</Button>
	);

	const SkPopoverButton = (subGroup: ISkillSubGroup) => {
		const toggleParentButtonState = (groupId: number, subGroupId: number) => {
			const newPayload = {
				...selected,
				[groupId]: {
					// copy other subGroups select state
					...(selected[groupId] && selected[groupId]),
					// update current subGroups state
					[subGroupId]:
						// validate if group state exists and assign correct value
						selected[groupId] && selected[groupId][subGroupId] !== undefined
							? // subGroup state already exists, toggle itself
							  !selected[groupId][subGroupId]
							: // create first value
							  true
				}
			};

			// if subgroup is deselect, remove it from the object
			if (newPayload[groupId][subGroupId] === false) {
				delete newPayload[groupId][subGroupId];
			}

			// if subgroup is empty, remove it from the object
			if (Object.keys(newPayload[groupId]).length === 0) {
				delete newPayload[groupId];
			}

			return newPayload;
		};

		return (
			<Grid item {...sizes[4]} key={`addSkButton_Order:${order}_id:${id}_subG:${subGroup.id}`}>
				<Button
					fullWidth
					onClick={() => {
						toggleSelected(toggleParentButtonState(id, subGroup.id as number));
					}}
					classes={{
						outlined:
							selected[id] && selected[id][subGroup.id as number]
								? classes.buttonOutlinedSelected
								: classes.buttonOutlined,
						root: classes.buttonRoot
					}}
					variant="outlined">
					{selected[id] && selected[id][subGroup.id as number] && (
						<CheckRounded color="primary" className={classes.selectedIcon} />
					)}
					<Typography
						className={clsx(classes.ASMButtonTitle, {
							[classes.ASMButtonTitleSel]: selected[id] && selected[id][subGroup.id as number]
						})}>
						{subGroup.name}
					</Typography>
				</Button>
			</Grid>
		);
	};

	const SkPopover = popupState && popupState.isOpen && (
		<Popover
			{...bindPopover(popupState)}
			className={classes.ASMSubGroupCollapse}
			classes={{ paper: classes.ASMSubGroupCollapsePaper }}
			anchorOrigin={{
				vertical: transformVPos === 'bottom' ? 'top' : 'bottom',
				horizontal: transformHPos
			}}
			transformOrigin={{
				vertical: transformVPos,
				horizontal: transformHPos
			}}>
			<div className={classes.ASMSubGroupCollapseContainer}>
				<Grid container direction="row" justifyContent="space-around" alignItems="flex-start" spacing={3}>
					{skill.skillSubGroups.map(SkPopoverButton)}
				</Grid>
			</div>
		</Popover>
	);

	return (
		<Grid item {...sizes[4]}>
			{SkButton}
			{popupState && (
				<>
					{popupState.isOpen && <div className={classes.ASMBlurBG} />}
					{SkPopover}
				</>
			)}
		</Grid>
	);
};

export default SkillModalButton;
