import ProjectProviders from './ProjectProviders';


import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';


import {setCompanyInformation } from 'store/company/actions';



const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ 
    setCompanyInformation
}, dispatch);

export type FCProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapDispatchToProps)(ProjectProviders);

