/* eslint-disable indent */
import { Dispatch } from 'redux';
import {
	IEmployeesSkills,
	IUserSkillEval,
	IChangeDueDate,
	ISkillsFeedback,
	IResumeReminder,
	IListResumeUsers,
	IResumeDetails
} from 'typings/store/admin/skillsAndResumes';
import { ISystemSkills } from 'typings/store/personalInfoTypes';
import { Success, OriginalResponse } from 'lib/handlers/requestTypes';
import {
	postNewSkillsTree,
	fetchSkillsEvaluation,
	fetchUserSkillsEvaluation,
	postResendSkillsEvaluation,
	postChangeDueDate,
	postSkillsFeedback,
	fetchSearchResumes,
	fetchExportResume,
	fetchExportMultipleResumes,
	postRemindResume,
	fetchResumeDetails
} from 'lib/api/administration/skillsAndResumes';
import { setUserSkills, setClearUserInfo } from 'store/personalInfo/actions';
import {
	setLoading,
	setPostNewSkillsTree,
	setSkillsEvaluation,
	setUserSkillsEvaluation,
	setClearUserSkillsEvaluation,
	setClearSkillsEvaluation,
	setPostResendSkillsEvaluation,
	setPostChangeDueDate,
	setPostSkillsFeedback,
	setSearchResumes,
	setExportResume,
	setExportMultipleResumes,
	setPostRemindResume,
	setResumeDetails,
	setClearResumesList,
	setSkillsAndResumesError
} from './actions';

export const sendPostNewSkillsTree =
	(payload: ISystemSkills[]) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(setClearUserInfo());
			const response: Success = await postNewSkillsTree(payload);
			// this is not used, response only shown on notification
			dispatch(setPostNewSkillsTree(response));
			// this clears all the skills for the user to use on other pages such as
			// [ profile, skills, resume, admin/skills, admin/resume ]
		} catch (error) {
			dispatch(setPostNewSkillsTree(null));
		}
	};

export const getSkillsEvaluation =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			// update skills evaluations object
			const response: { data?: IEmployeesSkills } = await fetchSkillsEvaluation();
			if (response.data) {
				dispatch(setSkillsEvaluation(response.data || null));
			} else {
				dispatch(setSkillsAndResumesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setSkillsEvaluation(null));
		}
	};

export const getUserSkillsEvaluation =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IUserSkillEval } | OriginalResponse = await fetchUserSkillsEvaluation(payload);
			if (response.data) {
				dispatch(setUserSkillsEvaluation(response?.data?.userSkills || null));
			} else {
				dispatch(setSkillsAndResumesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setUserSkillsEvaluation(null));
		}
	};

export const clearUserSkillsEvaluation =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setClearUserSkillsEvaluation());
	};

export const clearSkillsEvaluation =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setClearSkillsEvaluation());
	};

export const clearResumesList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setClearResumesList());
	};

export const sendPostResendSkillsEvaluation =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const notUsed: Success = await postResendSkillsEvaluation(payload);
			// this is not used, response only shown on notification
			dispatch(setPostResendSkillsEvaluation(notUsed));

			// update skills evaluations object
			const response: { data?: IEmployeesSkills } = await fetchSkillsEvaluation();
			dispatch(setSkillsEvaluation(response.data || null));
		} catch (error) {
			dispatch(setPostResendSkillsEvaluation(null));
		}
	};

export const sendPostChangeDueDate =
	(payload: IChangeDueDate) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const notUsed: Success = await postChangeDueDate(payload);
			dispatch(setPostChangeDueDate(notUsed));

			// update skills evaluations object
			const response: { data?: IEmployeesSkills } = await fetchSkillsEvaluation();
			dispatch(setSkillsEvaluation(response.data || null));
		} catch (error) {
			dispatch(setPostChangeDueDate(null));
			dispatch(setSkillsEvaluation(null));
		}
	};

export const sendPostSkillsFeedback =
	(payload: ISkillsFeedback) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const notUsed: Success = await postSkillsFeedback(payload);
			dispatch(setPostSkillsFeedback(notUsed));
			dispatch(setUserSkills(null));
		} catch (error) {
			dispatch(setPostSkillsFeedback(null));
			dispatch(setUserSkills(null));
		}
	};

export const getSearchResumes =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IListResumeUsers } | OriginalResponse = await fetchSearchResumes();
			if (response.data) {
				dispatch(setSearchResumes(response.data || null));
			} else {
				dispatch(setSkillsAndResumesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setSearchResumes([]));
		}
	};

export const exportResume =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const notUsed: unknown = await fetchExportResume(payload);
			dispatch(setExportResume(notUsed));
		} catch (error) {
			dispatch(setExportResume([]));
		}
	};

export const exportMultipleResumes =
	(payload: number[]) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data } = await fetchExportMultipleResumes(payload);
			dispatch(setExportMultipleResumes(data));
		} catch (error) {
			dispatch(setExportMultipleResumes([]));
		}
	};

export const sendPostRemindResume =
	(payload: IResumeReminder) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: Success } = await postRemindResume(payload);
			dispatch(setPostRemindResume(response.data || null));
		} catch (error) {
			dispatch(setPostRemindResume(null));
		}
	};

export const getResumeDetails =
	(payload: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IResumeDetails } | OriginalResponse = await fetchResumeDetails(payload);

			if (response.data) {
				dispatch(setResumeDetails(response.data || null));
			} else {
				dispatch(setSkillsAndResumesError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setResumeDetails([]));
		}
	};

export const clearSkillsAndResumesError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setSkillsAndResumesError(null));
	};
