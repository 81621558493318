import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { AppState } from 'typings/state';

import {
	getNewExpenseLineData,
	submitNewExpenseLine,
	getExpenseSheetData,
	submitUpdateExpenseSheet
} from 'store/personalInfo/thunks';

import NewExpenseLine from './NewExpenseLine';
import { setAddNewExpenseLine } from 'store/personalInfo/actions';

const mapStateToProps = ({ personalInfo }: AppState) => ({ personalInfo });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getNewExpenseLineData,
			submitNewExpenseLine,
			getExpenseSheetData,
			setAddNewExpenseLine,
			submitUpdateExpenseSheet
		},
		dispatch
	);

export type NewExpenseLineProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NewExpenseLine);
