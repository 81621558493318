// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center'
		},
		navButtons: {
			width: '100%',
			textAlign: 'right',
			padding: '50px'
		}
	})
);

export { useStyles };
