import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	filterButtonSelected: {
		borderRadius: 10,
		background: 'white',
		color: 'black',
		alignSelf: 'center',
		'&:hover': {
			color: 'white',
			background: theme.accentColor
		}
	},
	displayFlex: {
		display: 'flex',
		width: 'max-content'
	},
	alignCenter: {
		alignSelf: 'center'
	},
	infoLabel: {
		fontSize: '16px',
		display: 'flex',
		color: theme.grayedColor
	},
	periodDescription: {
		fontSize: '16px',
		color: theme.grayedColor,
		width: '85%',
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		'-webkit-line-clamp': '1' /* Adjust the number of lines before truncation */,
		textOverflow: 'ellipsis'
	},
	gridRow: {
		marginBottom: '5px'
	},
	rowElement: {
		width: 'max-content'
	},
	totalHours: {
		width: '-webkit-fill-available',
		background: '#F8F9FC', //
		borderRadius: 10,
		color: theme.accentColor,
		padding: '10px',
		textAlign: 'center',
		fontSize: 'large',
		fontWeight: 'bold'
	},

	//! HEADER
	//* BASE HEADER CARD
	cardBase: {
		display: 'block',
		margin: 'auto',
		boxShadow: theme.boxShadow,
		borderRadius: '8px'
	},
	emptyCardBase: {
		display: 'none'
	},
	columnHeader: {
		maxWidth: '95%'
	},

	//* TOP ROW
	topGrid: {
		textAlign: 'left',
		padding: '5px 0px 5px 5px'
	},
	weekDay: {
		fontWeight: 'bold',
		fontSize: '17px'
	},
	dayMonth: {
		fontSize: '15px'
	},

	//* BOTTOM ROW
	bottomGrid: {
		display: 'flex',
		padding: '15px 0px 5px 5px'
	},
	bottomGridDay: {
		display: 'flex',
		padding: '15px 0px 5px 5px',
		textAlignLast: 'left'
	},
	hoursClass: {
		color: theme.accentColor,
		fontWeight: 'bold',
		marginRight: '50px'
	},
	sumHoursClass: {
		color: theme.accentColor,
		alignSelf: 'right',
		textAlign: 'right'
	},
	sumHoursClassDay: {
		color: theme.accentColor,
		alignSelf: 'right',
		textAlign: 'right',
		textAlignLast: 'right'
	},
	paddingNavBar: {
		padding: '7px'
	},
	//! EVENT

	//* EVENT BASE CARD
	eventCardBase: {
		fontSize: '12px',
		fontWeight: '600',
		height: '100%',
		background: '#fff',
		position: 'relative',
		boxSizing: 'border-box',
		boxShadow: '0 0 5px rgba(0, 0, 0, .3)',
		borderRadius: '0 10px 10px 0',
		overflow: 'hidden'
	},

	//* text helpers
	text16Semi: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px'
	},
	text16Reg: {
		fontFamily: 'Open Sans',
		fontSize: '16px'
	},
	text14Semi: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '14px'
	},
	text14Reg: {
		fontFamily: 'Open Sans',
		fontSize: '14px'
	},
	todayButton: {
		color: 'black',
		fontSize: '20',
		marginRight: '10px'
	},
	previousWeekButton: {
		marginRight: '20px',
		height: '30px',
		width: '30px'
	},
	openBtn: {
		color: theme.accentColor,
		float: 'right'
	},
	selectedBadge: {
		paddingRight: '1px'
	},
	marginBottomToolTip: {
		marginBottom: '-20px'
	},
	hoursToolTip: {
		marginTop: '-5px',
		color: '#2BAAFF',
		fontSize: '11px'
	},
	customEventTitle2_TwoLinesEllipsis_MarginTop: {
		fontSize: '11px',
		color: 'initial',
		marginTop: '15px',
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		'-webkit-line-clamp': '2' /* Adjust the number of lines before truncation */,
		textOverflow: 'ellipsis'
	},
	customEventTitle2_TwoLinesEllipsis: {
		fontSize: '11px',
		color: 'initial',
		marginTop: '5px', //Only difference to the class above
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		'-webkit-line-clamp': '2',
		textOverflow: 'ellipsis'
	},
	customEventTitle2_OneLineEllipsis: {
		fontSize: '11px',
		color: 'initial',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	drawer: {
		width: '60vw',
		flexShrink: 0
	},
	drawerOpen: {
		width: '60vw',
		overflowX: 'hidden',
		transition: 'all .2s ease-in-out'
	},
	drawerClose: {
		width: '80vw',
		transition: 'all .2s ease-in-out',

		overflowX: 'hidden'
	},
	eventActions: {
		float: 'right'
	}
}));

export { useStyles };
