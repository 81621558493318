/** Components struct */
export function convertDateFormat(dateString: string) {
	// Validate the date string
	if (!isValidDate(dateString)) {
		return dateString;
	}

	// Split the date string by space to separate date and time
	const [date, time] = dateString.split(' ');

	// Replace hyphens with slashes in the date part
	const formattedDate = date.replace(/-/g, '/');

	// Split the date part by slashes
	const [year, month, day] = formattedDate.split('/');

	// Combine the formatted date and time in the expected format
	return `${day}/${month}/${year} ${time}`;
}

function isValidDate(dateString: string): boolean {
	const date = new Date(dateString);
	return !isNaN(date.getTime());
}

export function convertBinaryToYesNo(input: string): string {
	if (input === '0') {
		return 'No';
	} else if (input === '1') {
		return 'Yes';
	} else {
		return input;
	}
}
