import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import {
	getUserProposalsList,
	submitProposalAccept,
	submitProposalRefuse,
	submitProposalClose,
	submitProposalCancel,
	submitProposalRequestApproval,
	submitProposalChangeStartDate,
	clearProposals,
	clearProposalsError
} from 'store/administration/proposals/thunks';
import { setNotificationAsRead } from 'store/notifications/thunks';

import UserProposalsList from './UserProposalsList';
import { setProposalsDetailsPageFilter } from 'store/filters/actions';

const mapStateToProps = ({ proposals, filters, system }: AppState) => ({ proposals, filters, system });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getUserProposalsList,
			submitProposalAccept,
			submitProposalRefuse,

			submitProposalClose,
			submitProposalCancel,
			submitProposalRequestApproval,
			submitProposalChangeStartDate,
			clearProposals,
			clearProposalsError,
			setProposalsDetailsPageFilter,
			setNotificationAsRead
		},
		dispatch
	);

export type UserProposalsListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserProposalsList);
