import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	//* WEB
	[theme.breakpoints.up('md')]: {
		pageContainer: {
			padding: '4rem 10rem 0'
		}
	},
	//* TABLET
	[theme.breakpoints.down('md')]: {
		pageContainer: {
			padding: '5rem 5rem 0'
		}
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		pageContainer: {
			padding: '3rem 2rem 0'
		}
	},

	root: {
		display: 'flex',
		padding: '50px 175px',
		maxWidth: '100%',
		alignItems: 'center',
		flexDirection: 'column',
		margin: 'auto'
	},
	animation: {
		visibility: 'hidden',
		opacity: '0',
		height: 0,
		padding: '0 !important',
		transition: 'all 0.3s ease'
	},
	show: {
		visibility: 'visible',
		height: 'auto',
		padding: '40px !important',
		opacity: 1
	},
	formStyle: {
		marginTop: '15px',
		marginBottom: '90px'
	},
	button: {
		marginTop: '15px'
	},
	footerContainer: {
		width: 'calc(100vw)',
		'& > div:nth-child(2)': { width: '96%' }
	},
	buttonInputLabel: {
		marginTop: theme.spacing(1),
		color: theme.grayedColor
	},
	errorInputLabel: {
		marginTop: theme.spacing(1),
		color: 'red'
	},
	blockedScreenBox: {
		margin: 'auto',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '100px',
		textAlign: 'center'
	},
	blockedScreenLogo: {
		width: '300px'
	},
	blockedScreenLogoBalwurk: {
		width: '600px'
	},
	blockedScreenMessage: {
		fontWeight: 600,
		fontSize: '16px',
		color: '#718fa2'
	},
	blockedScreenTitle: {
		fontWeight: 700,
		fontSize: '24px',
		color: '#536B7A'
	},
	admissionText: {
		fontWeight: 400,
		fontSize: '16px',
		color: '#3D464D'
	},
	admissionSubmittedImage: {
		marginBottom: '70px',
		marginTop: '40px'
	}
}));

export { useStyles };
