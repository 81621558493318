import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from 'typings/state';

import { getAdmissionSheetData, sendAdmissionSheet } from 'store/administration/admissions/thunks';
import { setCompanyInformation } from 'store/company/actions';

import AdmissionSheet from './AdmissionSheet';

const mapStateToProps = ({ admissions, router, company }: AppState) => ({ admissions, router, company });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getAdmissionSheetData, sendAdmissionSheet, setCompanyInformation }, dispatch);

export type AdmissionSheetProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionSheet);
