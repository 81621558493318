import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	footer: {
		position: 'sticky',
		bottom: '0',
		left: '0',
		backgroundColor: 'white',
		padding: '20px 30px 10px',
		justifyContent: 'right'
	},
	formFields: {
		marginTop: '10px'
	},
	columnIcon: {
		color: '#ccc'
	}
}));

export { useStyles };
