import { action } from 'typesafe-actions';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import {
	SkillsAndResumesActions as Actions,
	IEmployeesSkills,
	IListResumeUsers
} from 'typings/store/admin/skillsAndResumes';
import { IDispatchType } from 'typings/store/storeTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setSkillsAndResumesError = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.SET_ERROR, payload);

export const setPostNewSkillsTree = (payload: Success | null): IDispatchType =>
	action(Actions.POST_NEW_SKILLS_TREE, payload);

export const setSkillsEvaluation = (payload: IEmployeesSkills | null): IDispatchType =>
	action(Actions.GET_SKILLS_EVALUATION, payload);

export const setUserSkillsEvaluation = (payload: IEmployeesSkills | null): IDispatchType =>
	action(Actions.GET_USER_SKILLS_EVALUATION, payload);

export const setClearUserSkillsEvaluation = (): IDispatchType => action(Actions.CLEAR_USER_SKILLS_EVALUATION);

export const setClearSkillsEvaluation = (): IDispatchType => action(Actions.CLEAR_SKILLS_EVALUATION, null);

export const setClearResumesList = (): IDispatchType => action(Actions.CLEAR_RESUMES_LIST);

export const setPostResendSkillsEvaluation = (payload: Success | null): IDispatchType =>
	action(Actions.POST_RESEND_SKILLS_EVALUATION, payload);

export const setPostChangeDueDate = (payload: Success | null): IDispatchType =>
	action(Actions.POST_CHANGE_DUE_DATE, payload);

export const setPostSkillsFeedback = (payload: Success | null): IDispatchType =>
	action(Actions.POST_SKILLS_FEEDBACK, payload);

export const setSearchResumes = (payload: IListResumeUsers | [] | null): IDispatchType =>
	action(Actions.GET_SEARCH_RESUMES, payload);

export const setExportResume = (payload: unknown): IDispatchType => action(Actions.GET_EXPORT_RESUME, payload);

export const setExportMultipleResumes = (payload: unknown): IDispatchType =>
	action(Actions.GET_EXPORT_MULTIPLE_RESUMES, payload);

export const setPostRemindResume = (payload: Success | null): IDispatchType =>
	action(Actions.POST_REMIND_RESUME, payload);

export const setResumeDetails = (payload: unknown): IDispatchType => action(Actions.GET_RESUME_DETAILS, payload);
