/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { ICompany, HelperProps, FetchedData, CompanyERP } from 'typings/store/generalTypes';

interface IResponse {
	company: ICompany[];
	businessUnit?: ICompany[];
	division?: ICompany[];
	department?: ICompany[];
	projectTypes?: ICompany[];
	lineOfBusiness?: ICompany[];
	careerProfiles?: any[];
}

// Return Company related data (BU, Division, Department)
export const prepareCompanyData = (helper: HelperProps | null, fetchedData: FetchedData): IResponse => {
	const businessUnits = helper?.company ? fetchedData.companies.find(e => e.searchKey === helper.company) : undefined;

	const divisions = helper?.businessUnit
		? businessUnits?.companyTree?.childNodes.find((e: CompanyERP) => e.organization === helper.businessUnit)
		: undefined;

	const departments = helper?.division
		? divisions.childNodes.find((e: CompanyERP) => e.organization === helper.division)
		: undefined;

	if (helper?.company)
		return {
			company: fetchedData.companies.map((e: ICompany) => ({ id: e.searchKey, label: e.name })),
			businessUnit: businessUnits
				? businessUnits.companyTree.childNodes.map((e: CompanyERP) => ({
						id: e.organization,
						label: e.organization$_identifier
				  }))
				: [],
			division: divisions
				? divisions.childNodes.map((e: CompanyERP) => ({
						id: e.organization,
						label: e.organization$_identifier
				  }))
				: [],
			department: departments
				? departments.childNodes.map((e: CompanyERP) => ({
						id: e.organization,
						label: e.organization$_identifier
				  }))
				: []
		};
	// No company have been selected yet
	return {
		company: fetchedData.companies.map(e => ({ id: e.searchKey, label: e.name }))
	};
};
// Return Company related data (BU, Division, Department)
export const prepareCompanyDataEditEmployeeCategory = (
	helper: HelperProps | null,
	fetchedData: FetchedData
): IResponse => {
	const businessUnits = helper?.company ? fetchedData?.company : undefined;

	const divisions = helper?.businessUnit
		? businessUnits?.companyTree?.childNodes.find((e: CompanyERP) => e.organization === helper.businessUnit)
		: undefined;

	const departments = helper?.division
		? divisions.childNodes.find((e: CompanyERP) => e.organization === helper.division)
		: undefined;

	if (helper?.company)
		return {
			company: fetchedData.company,
			businessUnit: businessUnits
				? businessUnits.companyTree.childNodes.map((e: CompanyERP) => ({
						id: e.organization,
						label: e.organization$_identifier
				  }))
				: [],
			division: divisions
				? divisions.childNodes.map((e: CompanyERP) => ({
						id: e.organization,
						label: e.organization$_identifier
				  }))
				: [],
			department: departments
				? departments.childNodes.map((e: CompanyERP) => ({
						id: e.organization,
						label: e.organization$_identifier
				  }))
				: []
		};
	// No company have been selected yet
	return {
		company: fetchedData.company
	};
};
