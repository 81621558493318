/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, Input } from 'xpand-ui/forms';

import { useStyles } from './styles';
import { INewEmployeeTabs } from '../../NewEmployee';

//* COMPONENT
const WhereEmployeeBelongs: FC<INewEmployeeTabs> = ({
	control,
	errors,
	companySelectOptions,
	setCompanyHelper,
	setValue
}) => {
	const classes = useStyles();
	return (
		<Grid container spacing={4} className={classes.root}>
			{/* ********* Contacts (invoice) *********** */}
			<Grid item {...sizes[6]}>
				<Select
					name="company"
					label="Company"
					options={companySelectOptions.company}
					additionalOnChange={(item: { value: string }) => {
						setCompanyHelper({
							company: item.value,
							businessUnit: null,
							division: null,
							department: null
						});
						setValue('businessUnit', '');
						setValue('division', '');
						setValue('department', '');
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="businessUnit"
					label="Business Unit"
					helperText="Business Unit that hired this employee"
					options={companySelectOptions.businessUnit}
					additionalOnChange={(item: { value: string }) => {
						setCompanyHelper(prev => ({
							...prev,
							businessUnit: item.value,
							division: null,
							department: null
						}));
						setValue('division', '');
						setValue('department', '');
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="division"
					label="Division"
					helperText="Division where the employee will work"
					options={companySelectOptions.division}
					additionalOnChange={(item: { value: string }) => {
						setCompanyHelper(prev => ({
							...prev,
							division: item.value,
							department: null
						}));
						setValue('department', '');
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="department"
					label="Department"
					helperText="Department where the employee will work"
					options={companySelectOptions.department}
					additionalOnChange={(item: { value: string }) => {
						setCompanyHelper(prev => ({
							...prev,
							department: item.value
						}));
					}}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
		</Grid>
	);
};

export default WhereEmployeeBelongs;
