/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, FC } from 'react';
import { LoadingOverlay, Table } from 'xpand-ui/core';
import { DatePicker, Select as LibSelect } from 'xpand-ui/forms';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Grid,
	IconButton,
	Typography,
	Dialog as MuiDialog,
	DialogTitle,
	DialogContent,
	InputLabel
} from '@mui/material';
import { useStyles } from './styles';
import { Close, ArrowForward } from '@mui/icons-material';
import GroupCollapse from '../../../../Admin/_ProposalsFormUtils/components/GroupCollapse/GroupCollapse';
import BackSpace from '../../../../../assets/icons/backspace_black_24dp.svg';
import { getPeriodListFiltersData, getWeekViewData } from 'store/personalInfo/thunks';
import { Scrollbars } from 'react-custom-scrollbars-2';
import startOfDay from 'date-fns/startOfDay';
import { ITableColumn } from 'typings/store/ComponentLib';
import { IPeriodFiltersPayload } from 'typings/store/admin/users';
import { XTRACKER_ADMIN, XTRACKER_ETL, XTRACKER_EXPENSE_SUBMITTER, XTRACKER_PAYROLL_ADMIN } from '../utils';
import { startOfWeek } from 'date-fns';

//* COMPONENT INTERFACES
const approvalStatusOptions = [
	{ id: 'allStatus', label: 'All Open Timesheet Periods' },
	{ id: 'notSubmitted', label: 'Not Submitted Timesheet Periods' },
	{ id: 'submitted', label: 'Submitted Timesheet Periods' },
	{ id: 'approved', label: 'Approved Timesheet Periods' },
	{ id: 'rejected', label: 'Rejected Timesheet Periods' }
];

const tableColumnHeaders: ITableColumn<IPeriodFiltersPayload>[] = [
	{
		label: 'Name',
		id: 'name',
		width: '12%'
	},
	{
		label: 'Period',
		id: 'period',
		width: '12%',
		format: (row, text: string) =>
			text != null
				? formatDateToDDMMYY(text.split(' - ')[0]) + ' - ' + formatDateToDDMMYY(text.split(' - ')[1])
				: ''
	},
	{
		label: 'Duration',
		id: 'duration',
		width: '8%'
	},
	{
		label: 'Total Hours',
		id: 'totalHours',
		width: '11%'
	},
	{
		label: 'Status',
		id: 'status',
		width: '10%'
	},
	{
		label: 'Submitted On',
		id: 'submittedOn',
		width: '11%',
		format: (row, text: string) => (text != null && text != '0000-00-00' ? formatDateToDDMMYY(text) : ' ')
	},
	{
		label: 'Approved On',
		id: 'approvedOn',
		width: '11%',
		format: (row, text: string) => (text != null && text != '0000-00-00' ? formatDateToDDMMYY(text) : ' ')
	},
	{
		label: 'Rejected On',
		id: 'rejectedOn',
		width: '11%',
		format: (row, text: string) => (text != null && text != '0000-00-00' ? formatDateToDDMMYY(text) : ' ')
	},
	{
		label: '',
		id: 'link',
		width: '10%'
	}
];

function formatDateToDDMMYY(dateString: string): string {
	const originalDate = new Date(dateString);
	const day = originalDate.getDate().toString().padStart(2, '0');
	const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to the month because it's zero-based
	const year = originalDate.getFullYear().toString().slice(-2); // Extract the last two digits of the year

	const formattedDate = `${day}/${month}/${year}`;

	return formattedDate;
}

const FiltersModal: FC<any> = ({
	setOpenModalFilters,
	openModalFilters,
	filterUser,
	setFilterUser,
	employeesList,
	loadCurrentPeriodDate,
	loggedUser,
	xtrackerRole,
	setDifferentUserOpened,
	checkChangesBeforeLeaveTimesheet,
	setSelectedDateForSaveWarning,
	setLoadCurrentPeriodDateForSaveWarning,
	setSelectedDate,
	areChangesMade,
	setChangePageAction,
	setShowSaveWarningModal
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [hasFiltersResults, setHasFiltersResults] = useState(false);
	const [filterStatus, setFilterStatus] = useState('allStatus');
	const [filterStartDate, setFilterStartDate] = useState('');
	const [filterEndDate, setFilterEndDate] = useState('');
	const [filtersResult, setFiltersResult] = useState<any[] | null>(null);
	const periodListFiltersData = useSelector(state => state.personalInfo.getPeriodListFiltersData);
	const [isCollapseOpened, setIsCollapseOpened] = useState(true);

	// Set Period List Result in Filters
	useEffect(() => {
		let tableData: any[] = [];
		const employee = employeesList.find(e => e.id === filterUser);
		const name = employee?.label?.split(' ');
		periodListFiltersData?.results?.timeEntryPeriodList?.forEach((e: any) => {
			const periodHours = periodListFiltersData?.results?.timePeriodsAndHours.find(
				event => event.periodId == e.accountEmployeeTimeEntryPeriodId
			);
			const [hours, minutes] = [Math.floor(periodHours.hours), Math.round((periodHours.hours % 1) * 60)];
			if (name) {
				tableData.push({
					name: <b>{name[2] + ' ' + name[3]}</b>,
					period: e.timeEntryStartDate + ' - ' + e.timeEntryEndDate,
					duration: '40h 00m', // Static Value
					totalHours: hours.toString().padStart(2, '0') + 'h ' + minutes.toString().padStart(2, '0') + 'm',
					status: e.approved
						? 'Approved'
						: e.rejected
						? 'Rejected'
						: e.submitted
						? 'Submitted'
						: 'Not Submitted',
					submittedOn: e.submitted && e.submittedDate ? e.submittedDate : '0000-00-00',
					approvedOn: e.approved && e.approvedOn ? e.approvedOn : '0000-00-00',
					rejectedOn: e.rejected && e.rejectedOn ? e.rejectedOn : '0000-00-00',
					link: (
						<Button
							variant="text"
							onClick={() => {
								if (loggedUser.toUpperCase() != filterUser.toUpperCase()) {
									setDifferentUserOpened(true);
								}
								setSelectedDateForSaveWarning(new Date(e.timeEntryStartDate.split(' ')[0]));
								// Check if changes were in current week
								if (!areChangesMade) {
									setSelectedDate(new Date(e.timeEntryStartDate.split(' ')[0]));
									loadCurrentPeriodDate(e.timeEntryStartDate.split(' ')[0]);
									setOpenModalFilters(false);
								} else {
									setSelectedDateForSaveWarning(new Date(e.timeEntryStartDate.split(' ')[0]));
									setLoadCurrentPeriodDateForSaveWarning(e.timeEntryStartDate.split(' ')[0]);
									setChangePageAction('FilterSelector');
									setShowSaveWarningModal(true);
								}
							}}
							className={classes.openBtn}>
							Open
						</Button>
					)
				});
			}
		});
		setFiltersResult(tableData);
	}, [periodListFiltersData]);

	const loadPeriodListFiltersData = () => {
		dispatch(
			getPeriodListFiltersData({
				username: filterUser,
				status: filterStatus,
				periodStartDate: filterStartDate,
				periodEndDate: filterEndDate
			})
		);
	};

	// Changes Css classe of GroupCollapse
	const handleToggle = e => {
		setIsCollapseOpened(e);
	};

	return (
		<>
			{/* FILTERS MODAL */}
			<MuiDialog
				fullWidth
				fullScreen
				onClose={() => {
					setOpenModalFilters(false);
				}}
				open={openModalFilters}>
				<DialogTitle className={hasFiltersResults ? classes.titleRootWithResults : classes.titleRoot}>
					<IconButton
						className={classes.closeButton}
						edge="start"
						color="inherit"
						onClick={() => {
							setOpenModalFilters(false);
						}}
						aria-label="close">
						<Close className={hasFiltersResults ? classes.closeIconWithResults : classes.closeIcon} />
					</IconButton>
				</DialogTitle>
				<DialogContent
					className={
						hasFiltersResults
							? isCollapseOpened
								? classes.bodyRootWithResultsAndCollpseOpened
								: classes.bodyRootWithResultsAndCollpseClosed
							: classes.bodyRoot
					}>
					<Grid container direction="column" justifyContent="center" alignItems="space-between">
						<Grid container justifyContent="center" className={classes.gridRowFilters}>
							<Typography variant="h2" className={hasFiltersResults ? classes.filtersWithResult : ''}>
								Filters
							</Typography>
						</Grid>
						<Grid container justifyContent="center" className={classes.gridRowFilters}>
							<InputLabel className={hasFiltersResults ? classes.filtersWithResult : classes.infoField}>
								Search Timesheets periods you want to see
							</InputLabel>
						</Grid>
						{hasFiltersResults ? (
							<GroupCollapse
								viewExpanded={true}
								collapse={{
									id: 'filterCollapse',
									label: 'Less Options'
								}}
								isFilterCollapse={true}
								handleToggle={handleToggle}
								removePaddingBottom>
								<Grid
									container
									justifyContent="space-between"
									spacing={2}
									className={classes.gridRowFilters2}>
									{
										<Grid item xs={6}>
											<LibSelect
												name="employee"
												label="EMPLOYEE NAME"
												value={filterUser}
												options={employeesList || []}
												onChange={(e: any) => {
													setFilterUser(e);
												}}
												hasSearchOption={true}
												className={classes.filtersWithResult}
											/>
										</Grid>
									}
									<Grid item xs={6}>
										<LibSelect
											name="approvalStatus"
											label="Approval Status"
											value={filterStatus}
											options={approvalStatusOptions}
											onChange={(e: any) => {
												setFilterStatus(e);
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<DatePicker
											value={filterStartDate}
											format="DD/MM/YYYY"
											name="startDate"
											label="Start Date"
											placeholder="Start Date"
											additionalOnChange={(e: any) => {
												if (e === 'Invalid date') {
													setFilterStartDate('');
												} else {
													setFilterStartDate(e);
												}
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<DatePicker
											value={filterEndDate}
											format="DD/MM/YYYY"
											name="endDate"
											label="End Date"
											placeholder="End Date"
											additionalOnChange={(e: any) => {
												if (e === 'Invalid date') {
													setFilterEndDate('');
												} else {
													setFilterEndDate(e);
												}
											}}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									justifyContent="flex-end"
									spacing={2}
									className={classes.gridRowFilters2}>
									<Grid item xs={4} />
									<Grid item xs={2.5}>
										<Button
											color="inherit"
											variant="text"
											onClick={() => {
												setHasFiltersResults(false);
												setFilterUser(loggedUser.toUpperCase());
												setFilterStatus('allStatus');
												setFilterEndDate('');
												setFilterStartDate('');
											}}
											className={classes.filtersWithResult}>
											<BackSpace style={{ marginRight: '5px' }} /> Clear filters
										</Button>
									</Grid>
									<Grid item xs={2.5}>
										<Button
											color="inherit"
											variant="text"
											onClick={() => {
												const startWeekDay = startOfWeek(new Date(), {
													weekStartsOn: 1
												}); // 1 represents monday
												startWeekDay.setHours(startWeekDay.getHours() + 1);

												if (loggedUser.toUpperCase() != filterUser.toUpperCase()) {
													setDifferentUserOpened(true);
												}
												setSelectedDateForSaveWarning(startWeekDay);
												// Check if changes were in current week
												if (!areChangesMade) {
													setSelectedDate(startWeekDay);
													loadCurrentPeriodDate(startWeekDay);
													setOpenModalFilters(false);
												} else {
													setSelectedDateForSaveWarning(startWeekDay);
													setLoadCurrentPeriodDateForSaveWarning(startWeekDay);
													setChangePageAction('FilterSelector');
													setShowSaveWarningModal(true);
												}
											}}
											className={classes.filtersWithResult}>
											<ArrowForward />
											&nbsp;Go to employee's current timesheet
										</Button>
									</Grid>
									<Grid item xs={1.5}>
										<Button
											color="inherit"
											variant="outlined"
											onClick={() => {
												setOpenModalFilters(false);
											}}
											className={classes.cancelBtnWithResult}>
											Cancel
										</Button>
									</Grid>
									<Grid item xs={1.5}>
										<Button
											variant="contained"
											onClick={() => {
												setFiltersResult(null);
												loadPeriodListFiltersData();
												setHasFiltersResults(true);
											}}
											className={classes.searchBtnWithResult}>
											Search
										</Button>
									</Grid>
								</Grid>
							</GroupCollapse>
						) : (
							<>
								<Grid
									container
									justifyContent="space-between"
									spacing={2}
									className={classes.gridRowFilters2}>
									{
										<Grid item xs={6}>
											<LibSelect
												name="employee"
												label="EMPLOYEE NAME"
												value={filterUser}
												options={employeesList || []}
												hasSearchOption={true}
												onChange={(e: any) => {
													setFilterUser(e);
												}}
											/>
										</Grid>
									}
									<Grid item xs={6}>
										<LibSelect
											name="approvalStatus"
											label="Approval Status"
											value={filterStatus}
											options={approvalStatusOptions}
											onChange={(e: any) => {
												setFilterStatus(e);
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<DatePicker
											value={filterStartDate}
											format="DD/MM/YYYY"
											name="startDate"
											label="Start Date"
											placeholder="Start Date"
											additionalOnChange={(e: any) => {
												if (e === 'Invalid date') {
													setFilterStartDate('');
												} else {
													setFilterStartDate(e);
												}
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<DatePicker
											value={filterEndDate}
											format="DD/MM/YYYY"
											name="endDate"
											label="End Date"
											placeholder="End Date"
											additionalOnChange={(e: any) => {
												if (e === 'Invalid date') {
													setFilterEndDate('');
												} else {
													setFilterEndDate(e);
												}
											}}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									justifyContent="flex-end"
									spacing={2}
									className={classes.gridRowFilters2}>
									<Grid item xs={6}></Grid>
									<Grid item xs={3}></Grid>
									<Grid item xs={1.5}>
										<Button
											color="inherit"
											variant="outlined"
											onClick={() => {
												setOpenModalFilters(false);
												setHasFiltersResults(false);
											}}
											className={classes.cancelBtn}>
											Cancel
										</Button>
									</Grid>
									<Grid item xs={1.5}>
										<Button
											variant="contained"
											onClick={() => {
												setFiltersResult(null);
												loadPeriodListFiltersData();
												setHasFiltersResults(true);
											}}
											className={classes.searchBtn}>
											Search
										</Button>
									</Grid>
								</Grid>
							</>
						)}
					</Grid>
				</DialogContent>
				{hasFiltersResults && (
					<Scrollbars
						style={{
							marginTop: '10px',
							height: isCollapseOpened ? '-webkit-fill-available' : '100%',
							overflowX: 'hidden',
							width: '100%'
						}}
						renderTrackHorizontal={(props: any) => (
							<div {...props} style={{ display: 'none' }} className="track-horizontal" />
						)}>
						<>
							{filtersResult == null ? (
								<LoadingOverlay />
							) : (
								<Grid container justifyContent="center" className={classes.tableResults}>
									<Table
										hideToolBar={true}
										tableData={filtersResult}
										columns={tableColumnHeaders}
										defaultSortColumn="name"
										defaultOrder="desc"
										lineless={true}
										noPagination={true}
										abc={true}
									/>
								</Grid>
							)}
						</>
					</Scrollbars>
				)}
			</MuiDialog>
		</>
	);
};
export default FiltersModal;
