import React, { useMemo, Suspense } from 'react';

import { useIdToken } from 'pages/General/Login/utils';
import { XpandUiWrapper } from './xpandUiTheme';
import { isPublicPage } from './utils';
import { ProjectProviderProps } from './@types';

import CommonProviders from './CommonProviders';
import PublicPages from './PublicPages';
import AzureWrapper from './AzureWrapper';

const ProjectProviders: React.FC<ProjectProviderProps> = ({ history, store }: ProjectProviderProps) => {
	const isPubPage = useMemo(() => isPublicPage(window.location.pathname), [window.location.pathname]);
	// eslint-disable-next-line react/prop-types

	const isAuthed = useIdToken();
	return (
		/* Suspense lets your components “wait” for something before they can render. */
		<Suspense fallback={<div>Loading...</div>}>
			{isPubPage ? ( // redux store provider
				<CommonProviders store={store} history={history}>
					<PublicPages />
				</CommonProviders>
			) : (
				<AzureWrapper store={store} history={history} />
			)}
		</Suspense>
	);
};

export default XpandUiWrapper(ProjectProviders);
