import * as yup from 'yup';

import { stringRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	errorDetails: stringRequired
});

export const defaultValues = {
	errorDetails: ''
};
