/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { ReactNode, SetStateAction } from 'react';
import { DeepMap, FieldError, FieldValue, UnpackNestedValue, UseFormReset, UseFormSetValue } from 'react-hook-form';
import { Dispatch, AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { store } from '../../../store/company/reducer';
//* EXTERNAL LIBS --> MUI
import {
	StarBorder,
	CancelOutlined,
	EmailOutlined,
	HourglassEmpty,
	Check,
	Clear,
	ExitToAppOutlined,
	ThumbUpAltOutlined,
	ThumbDownAltOutlined,
	Create
} from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI

//* TYPINGS
import { IGetNewCSC, IPostPutCSC } from 'typings/store/admin/employeeSalaryCategories';
import { IProposalStatus, IFoodAllowanceData } from 'typings/store/admin/proposalUtils';
import { FetchedData, ICareerProfiles } from 'typings/store/generalTypes';
import { ICreateEmployeeData } from 'typings/store/admin/admissions';
import {
	IAnualGrossValuePost,
	ICollaborationProposal,
	ICompanyInfo,
	IGetNewProposal,
	IIrsValuePost,
	IOtherBenefit,
	IPrizeGoal,
	IProposalFormCareerHelper,
	IProposalPayload,
	IReferenceData
} from 'typings/store/admin/proposals';
import { parseDateToManageSaf } from 'xpand-ui/utils/dates';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';
import { prepareCompanyData, prepareCompanyDataEditEmployeeCategory } from 'lib/utils/erpUtils';

//* LOCAL COMPONENT IMPORTS
import { CSC, PROPOSAL, EMPLOYEE, COLLABTYPE } from './constants';
import { BALWURK_CONTRACTOR_ID, XPAND_CONTRACTOR_ID } from 'lib/utils/constants';

type IClasses = Record<string, string>;

// PROPOSALS STATUS HELPER
export const allStatus = (classes: IClasses): IProposalStatus[] => [
	{
		icon: <StarBorder color="primary" className={classes.statusIcon} />,
		name: 'new',
		label: 'NEW'
	},
	{
		icon: <Check color="primary" className={classes.statusIcon} />,
		name: 'approved',
		label: 'APPROVED'
	},
	{
		icon: <EmailOutlined color="primary" className={classes.statusIcon} />,
		name: 'sent',
		label: 'SENT'
	},
	{
		icon: <CancelOutlined color="primary" className={classes.statusIcon} />,
		name: 'canceled',
		label: 'CANCELED'
	},
	{
		icon: <ThumbDownAltOutlined color="primary" className={classes.statusIcon} />,
		name: 'refused',
		label: 'REFUSED'
	},
	{
		icon: <ThumbUpAltOutlined color="primary" className={classes.statusIcon} />,
		name: 'accepted',
		label: 'ACCEPTED'
	},
	{
		icon: <Clear color="primary" className={classes.statusIcon} />,
		name: 'closed',
		label: 'CLOSED'
	},
	{
		icon: <HourglassEmpty color="primary" className={classes.statusIcon} />,
		name: 'awaiting',
		label: 'AWAITING APPROVAL'
	},
	{
		icon: <ExitToAppOutlined color="primary" className={classes.statusIcon} />,
		name: 'rejected',
		label: 'REJECTED'
	},
	{
		icon: <ExitToAppOutlined color="primary" className={classes.statusHidden} />,
		name: 'none',
		label: 'NONE'
	},
	{
		icon: <Create color="primary" className={classes.statusIcon} />,
		name: 'edited',
		label: 'EDITED'
	},
	{
		icon: <Check color="primary" className={classes.statusIcon} />,
		name: 'editedStartDate',
		label: 'APPROVED (EDITED START DATE)'
	}
];

/** Prepare Food Allowance fields when form inits
 * @param referenceData
 */
export const getInitialFoodAllowanceValues = (referenceData: IReferenceData): IFoodAllowanceData => {
	const foodAllowanceType = referenceData.xphrFoodAllowType || '';
	const foodAllowanceDays = referenceData.xphrFoodAllowanceDays || 20;

	if (foodAllowanceType === 'Cash') {
		return {
			foodAllowanceType,
			foodAllowanceDays: foodAllowanceDays as string,
			foodAllowance: referenceData.xphrFoodAllowValue
		};
	}
	// foodAllowanceType === 'Card'
	return {
		foodAllowanceType,
		foodAllowanceDays: foodAllowanceDays as string,
		foodAllowance: referenceData.xphrCrFoodAllowValue as unknown as string
	};
};

export const createLoadFromOtherUserSelectLabel = (
	classes: Record<string, string>,
	payload: ICollaborationProposal
): ReactNode => (
	<div className={classes.loadFromUser}>
		<div style={{ minWidth: '225px' }}>{payload.name}</div>
		<div className={classes.loadFromUserStatus}>
			<span style={{ marginLeft: '15px' }} />
			{allStatus(classes)[payload.proposalStatus].icon}
			<span style={{ marginRight: '5px' }} />
			{allStatus(classes)[payload.proposalStatus].label}
		</div>
	</div>
);

/** Generate select options form company dependents fields
 */
export const proposalsGetSelectOptions = (
	classes: IClasses,
	proposalInfo: FetchedData,
	selectHelper: any,
	selectedCompany: ICompanyInfo,
	isCollabType = false
): any => {
	const initialState = {
		company: [],
		businessUnit: [],
		division: [],
		department: [],
		//
		collaborationProposals: [],
		careerManagers: [],
		contractPositions: [],
		customers: [],
		referralUsers: [],
		businessPartnerCategories: [],
		contactListLevels: []
	};
	if (!proposalInfo) {
		return initialState;
	}

	// GET  [BU - DIV - DEP] WITH GIVEN COMPANY
	const preparedData = !isCollabType
		? prepareCompanyData(selectHelper, proposalInfo)
		: prepareCompanyDataEditEmployeeCategory(selectHelper, proposalInfo);

	if (selectedCompany)
		return {
			...preparedData,
			careerManagers:
				selectedCompany.searchKey === 'PT'
					? selectedCompany.careerManagers
							?.filter(e => e.xPHRAdOrgBunit === selectHelper.businessUnit)
							.map(e => ({ id: e.id, label: e.name })) || []
					: selectedCompany.careerManagers?.map(e => ({ id: e.id, label: e.name })) || [],
			collaborationProposals:
				selectedCompany.collaborationProposals
					?.sort(
						(a, b) =>
							new Date(parseDateToManageSaf(a.proposalDate)).getTime() -
							new Date(parseDateToManageSaf(b.proposalDate)).getTime()
					)
					.reverse()
					.map(e => ({
						id: e.id,
						label: createLoadFromOtherUserSelectLabel(classes, e),
						proposal: e
					})) || [],
			customers: selectedCompany.customers?.map(e => ({ id: e.id, label: e.name })) || [],
			contractPositions: selectedCompany.contractPositions?.map(e => ({ id: e.id, label: e.name })) || [],
			referralUsers: selectedCompany.referralUsers?.map(e => ({ id: e.searchKey, label: e.name })) || [],
			businessPartnerCategories:
				selectedCompany.businessPartnerCategories?.map(e => ({ id: e.id, label: e.name })) || []
		};
	return { ...initialState, ...preparedData };
};

/** Generate select options for non dependents fields
 */
export const proposalsGetFixedSelectOptions = (newProposal: IGetNewProposal | ICreateEmployeeData): any => ({
	collaborationRoles: newProposal?.collaborationRoles?.map(e => ({ id: e.searchKey, label: e.name })) || [],
	foodAllowanceTypes: newProposal?.foodAllowanceTypes?.map(e => ({ id: e.searchKey, label: e.name })) || [],
	maritalStatus: newProposal?.maritalStatus?.map(e => ({ id: e.searchKey, label: e.name })) || [],
	prizeBonusGoalTypes: newProposal?.prizeBonusGoalTypes?.map(e => ({ id: e.id, name: e.name })) || [],
	contractTypes: newProposal?.contractTypes?.map(e => ({ id: e.searchKey, label: e.name })) || [],
	holidayTypes: newProposal?.holidayTypes?.map(e => ({ id: e.id, label: e.name })) || [],
	previousFreeReferences: newProposal?.previousFreeReferences?.map(e => ({ id: e, label: e })) || [],
	primaryWorkPlaces: newProposal?.primaryWorkPlaces?.map(e => ({ id: e.searchKey, label: e.name })) || [],
	projects: newProposal?.projects?.map(e => ({ projectId: e.id, projectName: e.name })) || [],
	referralTypes: newProposal?.referralTypes?.map(e => ({ id: e.searchKey, label: e.name })) || [],
	timeOffApprovalTypes: newProposal?.timeOffApprovalTypes?.map(e => ({ id: e.id, label: e.name })) || [],
	timeOffPolicies: newProposal?.timeOffPolicies?.map(e => ({ id: e.id, label: e.name })) || [],
	workPlaces: newProposal?.workPlaces?.map(e => ({ id: e.searchKey, label: e.name })) || [],
	xtrackerEmployees: newProposal?.xtrackerEmployees?.map(e => ({ id: e.id, label: e.name })) || [],
	contactListLevels: newProposal?.contactListLevels?.map(e => ({ id: e.id, label: e.name })) || []
});

/** This is an cascade update helper to help manage the selected fields and update the select
 *  fields that are dependents of one another
 *
 * @param {function} setSelectHelper
 * @param {function} setValue
 * @param {string} field
 * @param {string} value
 */
export const updateSelectsInCascade = (
	setSelectHelper: React.Dispatch<SetStateAction<IProposalFormCareerHelper>>,
	setValue: UseFormSetValue<FieldValue<any>>,
	field: string,
	value: string | null
): any => {
	const state = store.getState();

	switch (field) {
		case 'company': {
			setSelectHelper(prev => ({
				...prev,
				company: value,
				businessUnit: null,
				division: null,
				department: null,
				careerPosition: null,
				careerJobRole: null,
				careerJobRoleLevel: null
			}));

			setValue('businessUnit', '');
			setValue('division', '');
			setValue('department', '');
			setValue('careerPosition', '');
			setValue('careerJobRole', '');
			setValue('careerJobRoleLevel', '');
			setValue('careerManager', '');
			setValue('contractPosition', '');
			setValue('customerInContract', '');
			setValue('reference', '');
			break;
		}
		case 'businessUnit': {
			setSelectHelper(prev => ({
				...prev,
				businessUnit: value,
				division: null,
				department: null,
				careerPosition: null,
				careerJobRole: null,
				careerJobRoleLevel: null
			}));

			setValue('division', '');
			setValue('department', '');
			setValue('careerPosition', '');
			setValue('careerJobRole', '');
			setValue('careerJobRoleLevel', '');
			setValue('careerManager', '');
			break;
		}
		case 'division': {
			setSelectHelper(prev => ({
				...prev,
				division: value,
				department: null,
				careerPosition: null,
				careerJobRole: null,
				careerJobRoleLevel: null
			}));
			setValue('department', '');
			setValue('careerPosition', '');
			setValue('careerJobRole', '');
			setValue('careerJobRoleLevel', '');
			break;
		}
		case 'department': {
			setSelectHelper(prev => ({
				...prev,
				department: value,
				careerPosition: null,
				careerJobRole: null,
				careerJobRoleLevel: null
			}));
			setValue('careerPosition', '');
			setValue('careerJobRole', '');
			setValue('careerJobRoleLevel', '');
			break;
		}
		case 'careerPosition': {
			setSelectHelper(prev => ({
				...prev,
				careerPosition: value,
				careerJobRole: null,
				careerJobRoleLevel: null
			}));
			setValue('careerJobRole', '');
			setValue('careerJobRoleLevel', '');
			break;
		}
		case 'careerJobRole': {
			setSelectHelper(prev => ({
				...prev,
				careerJobRole: value,
				careerJobRoleLevel: null
			}));
			setValue('careerJobRoleLevel', '');
			break;
		}
		case 'careerJobRoleLevel': {
			setSelectHelper(prev => ({
				...prev,
				careerJobRoleLevel: value
			}));
			break;
		}
		case 'startDate': {
			setSelectHelper(prev => ({
				...prev,
				startDate: value
			}));
			break;
		}
		default: {
			// RESET FORM, DEFAULT STATE
			setSelectHelper({
				company: null,
				businessUnit: null,
				division: null,
				department: null,
				careerPosition: null,
				careerJobRole: null,
				careerJobRoleLevel: null
			});

			setValue('company', state.defaultCompanyKey);
			setValue('businessUnit', '');
			setValue('division', '');
			setValue('department', '');
			setValue('careerPosition', '');
			setValue('careerJobRole', '');
			setValue('careerJobRoleLevel', '');
		}
	}
};

/** Check required fields for the summary api request take place
 */
export const getProposalSummaryInfoHelper = (
	data: IProposalPayload,
	type: string,
	isContractor: string,
	formFields: unknown
): any => {
	const checkS = (val: string) =>
		//When date is invalid the field values is 'Invalid Date', without this generates an error
		Boolean(
			data[val] !== null &&
				typeof data[val] !== 'undefined' &&
				data[val].toString().trim() !== '' &&
				data[val] !== 'Invalid date'
		);

	const checkN = (val: string) =>
		data[val] !== null && typeof data[val] !== 'undefined' && (Number(data[val]) === 0 ? 0 : Number(data[val]));

	const CSCValues = {
		collaborationType: checkS('collaborationType'),
		startDate: checkS('startDate'),
		collaborationRole: checkS('collaborationRole'),
		careerPosition: checkS('careerPosition'),
		careerManager: checkS('careerManager'),
		baseValue: checkN('baseValue') !== 0,
		foodAllowanceType: checkS('foodAllowanceType'),
		foodAllowance: checkN('foodAllowance') !== 0,
		foodAllowanceDays: checkN('foodAllowanceDays') !== 0,
		monthsApplicable: checkN('monthsApplicable'),
		...(((!data.healthInsuranceCheck || !data.commsPlafondFlag) && {
			benefitsComments: checkS('benefitsComments')
		}) ||
			{}),
		company: checkS('company'),
		email: checkS('email'),
		name: checkS('name'),
		businessUnit: checkS('businessUnit'),
		division: checkS('division'),
		department: checkS('department'),
		contractType: checkS('contractType'),
		contractPosition: checkS('contractPosition'),
		// customerInContract: checkS('customerInContract'),
		primaryOffice: checkS('primaryOffice'),
		workingOffice: checkS('workingOffice'),
		employeeManager: checkS('employeeManager'),
		holidayType: checkS('holidayType'),
		referralType: checkS('referralType'),
		employeeCategory: checkS('employeeCategory'),
		careerJobRole: checkS('careerJobRole'),
		careerJobRoleLevel: checkS('careerJobRoleLevel'),
		hourlyRate: checkN('hourlyRate') !== 0,
		budget: checkN('budget') !== 0,
		socialSecurityNumber: checkS('socialSecurityNumber'),
		...(((data.contractType === '20' || data.contractType === '80') && {
			fixedTermContractDuration: checkN('fixedTermContractDuration')
		}) ||
			{}),
		...(((data.contractType === '20' || data.contractType === '80') && {
			endDate: checkS('endDate')
		}) ||
			{}),
		...((data.contractType === '80' && {
			traineeType: checkS('traineeType')
		}) ||
			{}),
		username: checkS('username'),
		password: checkS('password'),
		...{
			...((!data.noTimeOffPolicy && {
				vacationDays: checkN('vacationDays') >= 0,
				timeOffApprovalType: checkS('timeOffApprovalType'),
				timeoffPolicy: checkS('timeoffPolicy')
			}) ||
				{})
		}
	};

	if (!formFields.formFieldsConfig.company) delete CSCValues.company;
	if (!formFields.formFieldsConfig.collabType) delete CSCValues.collaborationType;
	if (!formFields.formFieldsConfig.username) delete CSCValues.username;
	if (!formFields.formFieldsConfig.name) delete CSCValues.name;
	if (!formFields.formFieldsConfig.businessUnit) delete CSCValues.businessUnit;
	if (!formFields.formFieldsConfig.email) delete CSCValues.email;
	if (!formFields.formFieldsConfig.division) delete CSCValues.division;
	if (!formFields.formFieldsConfig.department) delete CSCValues.department;
	if (!formFields.formFieldsConfig.collabStartDate) delete CSCValues.startDate;
	if (!formFields.formFieldsConfig.collabRole) delete CSCValues.collaborationRole;
	if (!formFields.formFieldsConfig.employeeCategory) delete CSCValues.employeeCategory;
	if (!formFields.formFieldsConfig.careerName) delete CSCValues.careerPosition;
	if (!formFields.formFieldsConfig.careerJobRole) delete CSCValues.careerJobRole;
	if (!formFields.formFieldsConfig.careerJobRoleLevel) delete CSCValues.careerJobRoleLevel;
	if (!formFields.formFieldsConfig.careerManager) delete CSCValues.careerManager;
	if (!formFields.formFieldsConfig.baseValue) delete CSCValues.baseValue;
	if (!formFields.formFieldsConfig.monthlyBudget) delete CSCValues.budget;
	if (!formFields.formFieldsConfig.hourlyRate) delete CSCValues.hourlyRate;
	if (!formFields.formFieldsConfig.foodAllowance) delete CSCValues.foodAllowance;
	if (!formFields.formFieldsConfig.foodAllowanceDays) delete CSCValues.foodAllowanceDays;
	if (!formFields.formFieldsConfig.foodAllowanceType) delete CSCValues.foodAllowanceType;
	if (!formFields.formFieldsConfig.monthsApplicable) delete CSCValues.monthsApplicable;
	if (
		!formFields.formFieldsConfig.contractType ||
		(formFields.formFieldsConfig.contractType && formFields.formFieldsConfig.formType === 'Cost Salary Category')
	)
		delete CSCValues.contractType;
	if (!formFields.formFieldsConfig.contractPosition) delete CSCValues.contractPosition;
	if (!formFields.formFieldsConfig.primaryOffice) delete CSCValues.primaryOffice;
	if (!formFields.formFieldsConfig.workingOffice) delete CSCValues.workingOffice;
	if (!formFields.formFieldsConfig.employeeManager) delete CSCValues.employeeManager;
	if (!formFields.formFieldsConfig.holidayType) delete CSCValues.holidayType;
	if (!formFields.formFieldsConfig.referralType) delete CSCValues.referralType;
	if (!formFields.formFieldsConfig.password) delete CSCValues.password;
	if (!formFields.formFieldsConfig.nrVacationDays) delete CSCValues.vacationDays;
	if (!formFields.formFieldsConfig.socialSecurityNumber) delete CSCValues.socialSecurityNumber;
	if (!formFields.formFieldsConfig.timeoffPolicy) delete CSCValues.timeoffPolicy;
	if (!formFields.formFieldsConfig.timeoffApprovalType) delete CSCValues.timeOffApprovalType;
	if (!formFields.formFieldsConfig.healthInsurance || !formFields.formFieldsConfig.communicationsPlafond)
		delete CSCValues.benefitsComments;
	if (!formFields.formFieldsConfig.otherComments) delete CSCValues.benefitsComments;
	if (!formFields.formFieldsConfig.traineeType) delete CSCValues.traineeType;
	return CSCValues;
};

/** Get default company projects
 */
export const getDefaultProjectsList = (
	company: string,
	division: string,
	defaultProjects: Record<string, string[]>,
	defaultDivisionProjects: Record<string, string[]>
): any => {
	let returnProjects = defaultProjects[company] || defaultProjects.PT || defaultProjects.BW;

	if (division !== null) {
		returnProjects = returnProjects.concat(defaultDivisionProjects[division] || []);
	}

	return returnProjects;
};

export const setValuesFromProposal = (
	proposalToCopy: ICollaborationProposal,
	reset: any,
	watch: any,
	loadComments: boolean
): any => {
	const newData = { ...proposalToCopy };
	if (newData.budget !== 0) {
		newData.hourlyRate = newData.budget / 18 / 8;
	}
	if (proposalToCopy.employeeCategory !== (XPAND_CONTRACTOR_ID || BALWURK_CONTRACTOR_ID)) {
		// clear values to avoid data inconsistency
		if (!loadComments) {
			newData.benefitsComments = '';
			newData.careerAndFinancialNotes = '';
			newData.familyMembersComments = '';
		}

		newData.prizeBonusGoals =
			proposalToCopy?.prizeBonusGoals?.map(({ id, ...e }) => ({ ...e, uniqueId: uuidv4() })) || [];

		newData.projects = proposalToCopy?.projects?.map(({ id, ...e }) => e) || [];

		// manually fill benefits
		const benefits = [];
		if (proposalToCopy.benefitsValue1) {
			benefits.push({
				uniqueId: uuidv4(),
				goalDetails: proposalToCopy.benefitsDescription1,
				prizeValue: proposalToCopy.benefitsValue1
			});
		}
		if (proposalToCopy.benefitsValue2) {
			benefits.push({
				uniqueId: uuidv4(),
				goalDetails: proposalToCopy.benefitsDescription2,
				prizeValue: proposalToCopy.benefitsValue2
			});
		}
		if (proposalToCopy.benefitsValue3) {
			benefits.push({
				uniqueId: uuidv4(),
				goalDetails: proposalToCopy.benefitsDescription3,
				prizeValue: proposalToCopy.benefitsValue3
			});
		}

		newData.otherBenefitsList = benefits;

		const prizeBonus = proposalToCopy.prizeBonus ? Number(proposalToCopy.prizeBonus) : 0;
		const monthsApplicable = proposalToCopy.monthsApplicable ? Number(proposalToCopy.monthsApplicable) : 12;
		const annualGrossValue = proposalToCopy.annualGrossValue ? Number(proposalToCopy.annualGrossValue) : 0;

		newData.prizeBonus =
			(prizeBonus as unknown as string) !== 'NaN' ? parseFloat(prizeBonus as unknown as string).toFixed(2) : '0';

		newData.otherbenefits = benefits.length > 0;

		newData.eligiblePrizeValue = (prizeBonus * monthsApplicable) / 12;
		newData.prizeBonusPercentage = (prizeBonus / (prizeBonus + annualGrossValue)) * 100;
	}

	// map all props from proposalToCopy proposalToCopy and set their value to the form
	// Object.keys(proposalToCopy).map((key: any) => setValue(key, proposalToCopy[key], { shouldValidate: true }));
	reset({ ...watch(), ...newData });
};

export const setValuesFromCurrentProposal = (
	payload: IGetNewCSC,
	getValues: (fields?: any) => UnpackNestedValue<IPostPutCSC>,
	reset: (fields: any) => void,
	selectedCompany: ICompanyInfo,
	defaultValues: IPostPutCSC,
	isCollabType?: boolean
): void => {
	const formData = getValues();

	const getGoalTypeId = (erpGoal: string): number | null => {
		switch (erpGoal) {
			case 'xphrBuGoal':
				return 2;
			case 'xphrCompanyGoal':
				return 1;
			case 'xphrIndGoal':
				return 4;
			case 'xphrProjectGoal':
				return 3;
			default:
				return null;
		}
	};

	// manually fill prizeGoals
	const prizeBonusGoals: IPrizeGoal[] = [];
	['xphrBuGoal', 'xphrCompanyGoal', 'xphrIndGoal', 'xphrProjectGoal'].map(goal => {
		[1, 2, 3, 4].map(e => {
			if (payload?.currentCSC && payload?.currentCSC[`${goal}${e}Amount`]) {
				prizeBonusGoals.push({
					uniqueId: uuidv4(),
					goalTypeId: getGoalTypeId(goal),
					goalDetails: payload?.currentCSC[`${goal}${e}`] || '',
					prizePercentage: payload?.currentCSC[`${goal}${e}Percent`] || 0,
					prizeValue: payload?.currentCSC[`${goal}${e}Amount`] || 0
				});
			}
			return e;
		});
		return goal;
	});

	// manually fill Benefits
	const benefits: IOtherBenefit[] = [];
	['xphrMonthlyOtherBen'].map(goal => {
		[1, 2, 3, 4].map(e => {
			if (payload?.currentCSC && payload?.currentCSC[`${goal}${e}V`]) {
				benefits.push({
					uniqueId: uuidv4(),
					goalDetails: payload?.currentCSC[`${goal}${e}D`],
					prizeValue: payload?.currentCSC[`${goal}${e}V`]
				});
			}
			return e;
		});
		return goal;
	});

	reset({
		...formData,
		...(selectedCompany?.referenceData && { ...getInitialFoodAllowanceValues(selectedCompany?.referenceData) }),
		// TAXES
		careerPosition: payload?.employeeCurrentCareer?.profile,
		careerJobRole: payload?.employeeCurrentCareer?.xphrProfileEligibleRole || defaultValues.careerJobRole,
		careerJobRoleLevel: payload?.employeeCurrentCareer?.xphrProfileRoleLevel || defaultValues.careerJobRoleLevel,
		// ? no current value to assign, leave empty ! careerManager: payload?.employeeCurrentCareer?.profile,
		// TAXES
		holdersNumber: payload?.employeeInfo?.xphrHoldersNumber || defaultValues.holdersNumber,
		disabled: payload?.employeeInfo?.xphrDisable || defaultValues.disabled,
		maritalStatus: payload?.employeeInfo?.xPHRMaritalStatus || defaultValues.maritalStatus,
		tsuExemption: payload?.currentCSC?.xphrSocSecurityExemption > 0 || defaultValues.tsuExemption,
		dependentsNumber: payload?.employeeInfo?.xphrNumberOfDependents || defaultValues.dependentsNumber,
		// GENERAL VALUES
		baseValue: payload?.currentCSC?.xphrBaseIncome || payload?.proposal?.baseValue || defaultValues.baseValue,
		ihtPercentage: payload?.currentCSC?.xphrWorkExempion || defaultValues.ihtPercentage,
		ihtValue: payload?.currentCSC?.xphrWorkExempionValue || defaultValues.ihtValue,
		hasTwelfths: payload?.currentCSC?.xphrBaseAmountTwelfths !== 0 || defaultValues.hasTwelfths,
		foodAllowanceType: payload?.currentCSC?.xphrFoodAllowType || defaultValues.foodAllowanceType,
		foodAllowance: payload?.currentCSC?.xphrFoodAllowance || defaultValues.foodAllowance,
		foodAllowanceDays: payload?.currentCSC?.xphrFoodAllowanceDays || defaultValues.foodAllowanceDays,
		//
		nationalAllowance: payload?.currentCSC?.xphrNationalSubsistence || defaultValues.nationalAllowance,
		nationalAllowanceDays: payload?.currentCSC?.xphrNatSubsistDays || defaultValues.nationalAllowanceDays,
		internationalAllowance: payload?.currentCSC?.xphrIntSubsistence || defaultValues.internationalAllowance,
		internationalAllowanceDays: payload?.currentCSC?.xphrIntSubsistDays || defaultValues.internationalAllowanceDays,

		travelPass: payload?.currentCSC?.xphrMonthlyTransValue || defaultValues.travelPass,
		additionalExpenses: payload?.currentCSC?.xphrOtherBenefits || defaultValues.additionalExpenses,
		nurseryCheck: payload?.currentCSC?.xphrMonthlyChildAlloVal || defaultValues.nurseryCheck,
		lifeInsurance: payload?.currentCSC?.xphrLifeInsuranceValue || defaultValues.lifeInsurance,
		eligiblePrizeValue: payload?.currentCSC?.xphrPrizeValue
			? (Number(payload?.currentCSC?.xphrPrizeValue) * 12) / 12
			: 0,
		prizeBonus: payload?.currentCSC?.xphrPrizeValue
			? parseFloat(payload?.currentCSC?.xphrPrizeValue as string).toFixed(2)
			: '0',
		prizeBonusPercentage:
			(Number(payload?.currentCSC?.xphrPrizeValue) /
				(Number(payload?.currentCSC?.xphrPrizeValue) + Number(payload?.currentCSC?.xphrAnnualGrossValue))) *
				100 || 0,
		prizeBonusGoals,
		otherBenefitsList: benefits.length > 0 ? benefits : defaultValues.otherBenefitsList,
		otherbenefits: benefits.length > 0 ? true : defaultValues.otherbenefits,

		commsPlafond: payload?.cscPrefilledBenefits?.communicationsPlafondValue || defaultValues.commsPlafond,
		commsPlafondFlag: payload?.cscPrefilledBenefits?.hasCommunicationsPlafond || defaultValues.commsPlafondFlag,
		healthInsuranceCheck: payload?.cscPrefilledBenefits?.hasHealthInsurance || defaultValues.healthInsuranceCheck,
		healthInsuranceValue: payload?.cscPrefilledBenefits?.healthInsuranceValue || defaultValues.healthInsuranceValue,
		includeFamilyMembers: payload?.cscPrefilledBenefits?.includeFamilyMembers || defaultValues.includeFamilyMembers,
		numberMembersDep: payload?.cscPrefilledBenefits?.numberOfFamilyMembers || defaultValues.numberMembersDep
	});

	if (isCollabType !== true) {
		if (typeof payload === 'undefined' || typeof payload?.currentCSC === 'undefined') {
			addNotification('info', "The selected employee doesn't have any registered Cost Salary Category yet.");
		}
	}
};

export const setValuesFromCurrentProposalContractor = (
	payload: IGetNewCSC,
	getValues: (fields?: any) => UnpackNestedValue<IPostPutCSC>,
	reset: (fields: any) => void,
	selectedCompany: ICompanyInfo,
	defaultValues: IPostPutCSC,
	isCollabType?: boolean
): void => {
	const formData = getValues();

	const getGoalTypeId = (erpGoal: string): number | null => {
		switch (erpGoal) {
			case 'xphrBuGoal':
				return 2;
			case 'xphrCompanyGoal':
				return 1;
			case 'xphrIndGoal':
				return 4;
			case 'xphrProjectGoal':
				return 3;
			default:
				return null;
		}
	};

	// manually fill prizeGoals
	const prizeBonusGoals: IPrizeGoal[] = [];
	['xphrBuGoal', 'xphrCompanyGoal', 'xphrIndGoal', 'xphrProjectGoal'].map(goal => {
		[1, 2, 3, 4].map(e => {
			if (payload?.currentCSC && payload?.currentCSC[`${goal}${e}Amount`]) {
				prizeBonusGoals.push({
					uniqueId: uuidv4(),
					goalTypeId: getGoalTypeId(goal),
					goalDetails: payload?.currentCSC[`${goal}${e}`] || '',
					prizePercentage: payload?.currentCSC[`${goal}${e}Percent`] || 0,
					prizeValue: payload?.currentCSC[`${goal}${e}Amount`] || 0
				});
			}
			return e;
		});
		return goal;
	});

	// manually fill Benefits
	const benefits: IOtherBenefit[] = [];
	['xphrMonthlyOtherBen'].map(goal => {
		[1, 2, 3, 4].map(e => {
			if (payload?.currentCSC && payload?.currentCSC[`${goal}${e}V`]) {
				benefits.push({
					uniqueId: uuidv4(),
					goalDetails: payload?.currentCSC[`${goal}${e}D`],
					prizeValue: payload?.currentCSC[`${goal}${e}V`]
				});
			}
			return e;
		});
		return goal;
	});

	reset({
		...formData,
		...(selectedCompany?.referenceData && { ...getInitialFoodAllowanceValues(selectedCompany?.referenceData) }),
		// ? no current value to assign, leave empty ! careerManager: payload?.employeeCurrentCareer?.profile,
		// TAXES
		holdersNumber: payload?.employeeInfo?.xphrHoldersNumber || defaultValues.holdersNumber,
		disabled: payload?.employeeInfo?.xphrDisable || defaultValues.disabled,
		maritalStatus: payload?.employeeInfo?.xPHRMaritalStatus || defaultValues.maritalStatus,
		dependentsNumber: defaultValues.dependentsNumber,
		// GENERAL VALUES,
		//
		nationalAllowance: payload?.currentCSC?.xphrNationalSubsistence || defaultValues.nationalAllowance,
		nationalAllowanceDays: payload?.currentCSC?.xphrNatSubsistDays || defaultValues.nationalAllowanceDays,
		internationalAllowance: payload?.currentCSC?.xphrIntSubsistence || defaultValues.internationalAllowance,
		internationalAllowanceDays: payload?.currentCSC?.xphrIntSubsistDays || defaultValues.internationalAllowanceDays,

		travelPass: payload?.currentCSC?.xphrMonthlyTransValue || defaultValues.travelPass,
		additionalExpenses: payload?.currentCSC?.xphrOtherBenefits || defaultValues.additionalExpenses,
		nurseryCheck: payload?.currentCSC?.xphrMonthlyChildAlloVal || defaultValues.nurseryCheck,
		lifeInsurance: payload?.currentCSC?.xphrLifeInsuranceValue || defaultValues.lifeInsurance,
		eligiblePrizeValue: payload?.currentCSC?.xphrPrizeValue
			? (Number(payload?.currentCSC?.xphrPrizeValue) * 12) / 12
			: 0,
		otherBenefitsList: benefits,
		otherbenefits: benefits.length > 0,

		commsPlafond: defaultValues.commsPlafond,
		commsPlafondFlag: defaultValues.commsPlafondFlag,
		healthInsuranceCheck: payload?.cscPrefilledBenefits?.hasHealthInsurance || defaultValues.healthInsuranceCheck,
		healthInsuranceValue: payload?.cscPrefilledBenefits?.healthInsuranceValue || defaultValues.healthInsuranceValue,
		includeFamilyMembers: payload?.cscPrefilledBenefits?.includeFamilyMembers || defaultValues.includeFamilyMembers,
		numberMembersDep: payload?.cscPrefilledBenefits?.numberOfFamilyMembers || defaultValues.numberMembersDep
	});

	if (isCollabType !== true) {
		if (typeof payload === 'undefined' || typeof payload?.currentCSC === 'undefined') {
			addNotification('info', "The selected employee doesn't have any registered Cost Salary Category yet.");
		}
	}
};

export const getProposalApiMethods = (
	reset: UseFormReset<any>,
	getIrsValue: (payload: IIrsValuePost) => (dispatch: Dispatch<AnyAction>) => Promise<void>,
	getProposalAnnualGrossValue: (payload: IAnualGrossValuePost) => (dispatch: Dispatch<AnyAction>) => Promise<void>
): any => ({
	/** Calculate taxable and gross values, as well the twelfths
	 * @onchange of
	 * @param baseValue
	 * @param lifeInsurance
	 * @param ihtPercentage
	 * @param hasTwelfths
	 */
	initFinancialValues: (data: IPostPutCSC, dontReset = false): IPostPutCSC | null => {
		const { baseValue, lifeInsurance, ihtPercentage, hasTwelfths } = data;

		const exemptionValue = (parseFloat(baseValue as string) * parseFloat(ihtPercentage as string)) / 100;
		const grossBasValue = exemptionValue + parseFloat(baseValue as string) + parseFloat(lifeInsurance as string);
		const twelfths = hasTwelfths ? (exemptionValue + parseFloat(baseValue as string)) / 12 : 0;
		const newTotalTaxableValue = grossBasValue + twelfths + twelfths;

		const newState = {
			...data,
			ihtValue: exemptionValue,
			grossBaseValue: grossBasValue,
			totalTaxableValue: `${newTotalTaxableValue}`,
			vacationsTwelfth: twelfths,
			christmasTwelfth: twelfths
		};

		if (dontReset) {
			return newState;
		}
		reset(newState, { keepErrors: true });

		return null;
	},
	/** Calculate IRS value of the proposal
	 * @onchange of
	 * @param baseValue this must be populated for the request take place
	 * @param company
	 * @param maritalStatus
	 * @param disabled
	 * @param holdersNumber
	 * @param dependentsNumber
	 * @param grossBaseValue
	 * @param ihtValue
	 * @param lifeInsurance
	 * @param foodAllowanceType
	 * @param foodAllowance
	 * @param taxZone
	 */
	updateIRSTax: (data: ICollaborationProposal): void => {
		if (data.company == 'PT' || data.company == 'BW') {
			if (data.baseValue && +data.baseValue !== 0) {
				getIrsValue({
					company: data.company,
					maritalStatus: data.maritalStatus,
					disabled: data.disabled,
					holdersNumber: data.holdersNumber,
					dependentsNumber: data.dependentsNumber,
					grossBaseValue: data.grossBaseValue as number | string,
					baseValue: data.baseValue,
					workScheduleExemptionValue: data.ihtValue,
					lifeInsurance: data.lifeInsurance as number | string,
					prizeValue: data.prizeBonus as number | string,
					foodAllowanceType: data.foodAllowanceType,
					foodAllowance: data.foodAllowance,
					taxZone: data.taxZone,
					startDate: data.startDate
				});
			}
		}
	},
	/** Calculate Annual Gross Value
	 * @param baseValue
	 * @param ihtValue
	 * @param nationalAllowance
	 * @param nationalAllowanceDays
	 * @param internationalAllowance
	 * @param internationalAllowanceDays
	 * @param foodAllowance
	 * @param foodAllowanceType
	 * @param foodAllowanceDays
	 * @param nurseryCheck
	 * @param travelPass
	 * @param additionalExpenses
	 * @param lifeInsurance
	 */
	getAnnualGrossValue: (data: ICollaborationProposal): void => {
		const checkS = (val: string) => data[val] !== null && data[val] && data[val].trim() !== '';
		const checkN = (val: string) => data[val] !== null && (Number(data[val]) === 0 ? true : Number(data[val]));

		if (
			checkN('baseValue') !== 0 &&
			checkN('ihtValue') &&
			checkN('nationalAllowance') &&
			checkN('nationalAllowanceDays') &&
			checkN('internationalAllowance') &&
			checkN('internationalAllowanceDays') &&
			checkN('foodAllowance') &&
			checkS('foodAllowanceType') &&
			checkN('foodAllowanceDays') &&
			checkN('nurseryCheck') &&
			checkN('travelPass') &&
			checkN('additionalExpenses') &&
			checkN('lifeInsurance')
		) {
			getProposalAnnualGrossValue({
				baseValue: data.baseValue,
				ihtValue: data.ihtValue,
				hasTwelfths: data.hasTwelfths,
				nationalAllowance: data.nationalAllowance as number | string,
				nationalAllowanceDays: data.nationalAllowanceDays as number | string,
				internationalAllowance: data.internationalAllowance as number | string,
				internationalAllowanceDays: data.internationalAllowanceDays as number | string,
				foodAllowance: data.foodAllowance,
				foodAllowanceType: data.foodAllowanceType,
				foodAllowanceDays: data.foodAllowanceDays,
				nurseryCheck: data.nurseryCheck as number | string,
				travelPass: data.travelPass as number | string,
				additionalExpenses: data.additionalExpenses as number | string,
				lifeInsurance: data.lifeInsurance as number | string
			});
		}
	},
	/** Generate select fields for:
	 * @field careerPosition
	 * @field careerJobRole
	 * @field careerJobRoleLevel
	 *
	 * @param company
	 * @param careerHelper
	 */
	getCareerFields: (company: ICompanyInfo, careerHelper: IProposalFormCareerHelper) => {
		const initialState = {
			careerPosition: [],
			careerJobRole: [],
			careerJobRoleLevel: []
		};

		const hasBus = careerHelper.businessUnit;
		const hasDiv = careerHelper.division;
		const hasDep = careerHelper.department;

		// if a department is not set, there will be no career name / job role
		if (!company || !(hasBus && hasDiv && hasDep)) {
			return initialState;
		}

		// get available CAREER NAMES
		const newCareerPosition = company.careerProfiles
			?.map((profiles: ICareerProfiles) => ({
				...profiles,
				eligibleDepartments: profiles.eligibleDepartments.filter(
					e => e.orgBunit === hasBus && e.orgDivision === hasDiv && e.orgDepartment === hasDep
				)
			}))
			.filter(e => e.eligibleDepartments.length !== 0)
			.filter(
				e =>
					!e.endDate ||
					(careerHelper.startDate &&
						new Date(parseDateToManageSaf(e.endDate)).getTime() >=
							new Date(parseDateToManageSaf(careerHelper.startDate)).getTime())
			);

		// GET CAREER NAMES selected
		const hasCareerPosition = careerHelper.careerPosition;

		const getCareerJobRole = () => {
			if (hasCareerPosition) {
				const payload = newCareerPosition?.find(e => e.id === hasCareerPosition);
				if (payload) {
					const payloadDep = payload?.eligibleDepartments?.find(e => e.orgDepartment === hasDep);
					if (payloadDep) {
						return payloadDep.eligibleJobRoles;
					}
				}
			}
			return undefined;
		};

		// if CAREER NAME selected, GET CAREER JOB ROLE
		const careerJobRolePayload = getCareerJobRole();

		// GET CAREER JOB ROLE selected
		const hasCareerJobRole = careerHelper.careerJobRole;

		const getCareerJobRoleLevel = () => {
			if (hasCareerJobRole) {
				const payload = careerJobRolePayload?.find(e => e.id === hasCareerJobRole);
				if (payload) {
					return payload.levels;
				}
			}
			return undefined;
		};
		// if CAREER JOB ROLE selected, GET CAREER JOB ROLE LEVEL
		const careerJobRoleLevelPayload = getCareerJobRoleLevel();
		let allJobRoleLevelNSalaries = [];
		if (careerJobRoleLevelPayload) {
			for (let level of careerJobRoleLevelPayload) {
				if (level.salaries.length !== 0) {
					for (let salary of level.salaries) {
						let name = level.name.split('>')[0];
						name = name
							.toLowerCase()
							.split(' ')
							.map(word => word.charAt(0).toUpperCase() + word.slice(1))
							.join(' ');
						let obj = {
							id: level.id,
							careerLevel: name,
							careerSubLevel: level.careerSubLevel,
							place: level.place,
							preferable: level?.preferable,
							monthlyNet: salary.monthlyNetAmount.toFixed(2),
							annualCost: salary.annualGrossCost.toFixed(2),
							baseAmount: salary.baseAmount,
							workExeptionPercentage: salary.workExemptionAllowance,
							twelfth: salary.baseAmountWTwelfths,
							vacationsTwelfth: salary.holidayAllowance,
							christmasTwelfth: salary.christmasAllowance,
							foodAllowance: salary.foodAllowance,
							otherExpenses: salary.otherExpenses,
							lifeInsurance: salary.lifeInsurance,
							isEmpty: false,
							name: level.name
						};
						allJobRoleLevelNSalaries.push(obj);
					}
				} else {
					let name = level.name.split(' ')[0];
					name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
					let obj = {
						id: level.id,
						careerLevel: name,
						careerSubLevel: level.careerSubLevel,
						place: level.place,
						monthlyNet: 0,
						annualCost: 0,
						baseAmount: 0.0,
						isEmpty: true,
						name: level.name
					};
					allJobRoleLevelNSalaries.push(obj);
				}
			}
		}

		return {
			careerPosition: newCareerPosition?.map(e => ({ id: e.id, label: e.name })),
			careerJobRole: careerJobRolePayload?.map(e => ({ id: e.id, label: e.name })) || [],
			careerJobRoleLevel: allJobRoleLevelNSalaries || []
		};
	}
});

export const getMissingFieldsNames = (
	errors: DeepMap<any, FieldError>,
	isContractor: string
): {
	names: string[];
	tabs: Set<number>;
} => {
	const fields = {
		name: {
			name: 'Full Name',
			tab: 0
		},
		email: {
			name: 'Email ',
			tab: 0
		},
		startDate: {
			name: 'Collaboration Start Date',
			tab: 0
		},
		company: {
			name: 'Company',
			tab: 0
		},
		businessUnit: {
			name: 'Business Unit',
			tab: 0
		},
		division: {
			name: 'Division',
			tab: 0
		},
		department: {
			name: 'Department',
			tab: 0
		},
		employeeCategory: {
			name: 'Employee Category',
			tab: 0
		},
		collaborationRole: {
			name: 'Collaboration Role',
			tab: 0
		},
		careerPosition: {
			name: 'Career Name',
			tab: 1
		},
		careerJobRole: {
			name: 'Career Job Role',
			tab: 1
		},
		careerJobRoleLevel: {
			name: 'Career Job Role Level',
			tab: 1
		},
		careerManager: {
			name: 'Career Manager',
			tab: 1
		},
		socialSecurityNumber: {
			name: 'Social Security Number',
			tab: 1
		},
		holdersNumber: {
			name: 'Holders',
			tab: 1
		},
		maritalStatus: {
			name: 'Marital Status',
			tab: 1
		},
		dependentsNumber: {
			name: 'Number Of Dependents',
			tab: 1
		},
		baseValue: {
			name: 'Base Value',
			tab: 1
		},
		hourlyRate: {
			name: 'Hourly Rate',
			tab: 1
		},
		budget: {
			name: 'Budget',
			tab: 1
		},
		ihtValue: {
			name: 'Work Schedule Exemption Value',
			tab: 1
		},
		ihtPercentage: {
			name: 'Work Schedule Exemption  Percentage',
			tab: 1
		},
		vacationsTwelfth: {
			name: 'Vacations Twelfth',
			tab: 1
		},
		christmasTwelfth: {
			name: 'Christmas Twelfth',
			tab: 1
		},
		grossBaseValue: {
			name: 'Gross Base Value',
			tab: 1
		},
		totalTaxableValue: {
			name: 'Total Taxable Value',
			tab: 1
		},
		foodAllowanceType: {
			name: 'Food Allowance Type',
			tab: 1
		},
		foodAllowance: {
			name: 'Food Allowance',
			tab: 1
		},
		foodAllowanceDays: {
			name: 'Food Allowance Days',
			tab: 1
		},
		nationalAllowanceDays: {
			name: 'National Allowance Days',
			tab: 1
		},
		nationalAllowance: {
			name: 'National Allowance',
			tab: 1
		},
		internationalAllowanceDays: {
			name: 'International Allowance Days',
			tab: 1
		},
		internationalAllowance: {
			name: 'International Allowance',
			tab: 1
		},
		travelPass: {
			name: 'Travel Pass',
			tab: 1
		},
		additionalExpenses: {
			name: 'Other Expenses',
			tab: 1
		},
		nurseryCheck: {
			name: 'Nursery Check',
			tab: 1
		},
		lifeInsurance: {
			name: 'Life Insurance',
			tab: 1
		},
		prizeBonus: {
			name: 'Maximum Prize Bonus',
			tab: 1
		},
		prizeBonusPercentage: {
			name: '% From Annual Gross Value',
			tab: 1
		},
		monthsApplicable: {
			name: 'Months Applicable',
			tab: 1
		},
		eligiblePrizeValue: {
			name: 'Eligible Prize Value',
			tab: 1
		},
		prizeBonusGoals: {
			name: 'Prize Bonus Goals',
			tab: 1
		},
		annualPrizeIRS: {
			name: 'IRS Tax (In Prize Month)',
			tab: 1
		},
		totalComponentsPrizePercentage: {
			name: 'Total Prize Goals Percentage',
			tab: 1
		},
		totalComponentsPrizeValue: {
			name: 'Total Prize Goals Value',
			tab: 1
		},
		careerAndFinancialNotes: {
			name: 'Comments (Career & Finantial)',
			tab: 1
		},
		commsPlafondFlag: {
			name: 'Communications Plafond?',
			tab: 2
		},
		commsPlafond: {
			name: 'Communications Plafond Value',
			tab: 2
		},
		healthInsuranceCheck: {
			name: 'Health Insurance?',
			tab: 2
		},
		healthInsuranceValue: {
			name: 'Health Insurance Value',
			tab: 2
		},
		includeFamilyMembers: {
			name: 'Include Family Members?',
			tab: 2
		},
		numberMembersDep: {
			name: 'Number Of Family Members',
			tab: 2
		},
		familyMembersComments: {
			name: 'Include Family In Insurance Details',
			tab: 2
		},
		otherbenefits: {
			name: 'Has Other Benefits?',
			tab: 2
		},
		otherBenefitsList: {
			name: 'Other Benefits List',
			tab: 2
		},
		benefitsComments: {
			name: 'Other Comments (Other Benefits)',
			tab: 2
		},
		contractType: {
			name: 'Contract Type',
			tab: 3
		},
		customerInContract: {
			name: 'Customer In Contract',
			tab: 3
		},
		traineeType: {
			name: 'Trainee Type',
			tab: 3
		},
		fixedTermContractDuration: {
			name: 'Fixed Term Contract Duration',
			tab: 3
		},
		endDate: {
			name: 'End Date',
			tab: 3
		},
		contractPosition: {
			name: 'Contract Position',
			tab: 3
		},
		primaryOffice: {
			name: 'Primary Office',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		workingOffice: {
			name: 'Working Office',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		employeeManager: {
			name: 'Employee Manager',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		holidayType: {
			name: 'Holiday Type',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		referralType: {
			name: 'Referral Type',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		reference: {
			name: 'Referenced By',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		referralId: {
			name: 'Referral Id',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		projects: {
			name: 'Projects List',
			tab: isContractor === 'Contractor' ? 3 : 4
		},
		username: {
			name: 'Username',
			tab: 0
		},
		vacationDays: {
			name: 'Nr Of Vacation Days',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		timeOffApprovalType: {
			name: 'Time Off Approval Type',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		timeoffPolicy: {
			name: 'Time Off Policy',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		password: {
			name: 'Password',
			tab: isContractor === 'Contractor' ? 2 : 3
		}
	};

	const fieldsMissing: { name: string; tab: number }[] = [];

	Object.keys(errors).forEach(name => fieldsMissing.push(fields[name]));

	return {
		names: fieldsMissing.map(e => e?.name),
		tabs: new Set(fieldsMissing.map(e => e?.tab))
	};
};

export const getAdditionalErrors = (
	errors: DeepMap<any, FieldError>,
	isContractor: string
): {
	additionalNames: string[];
	additionalTabs: Set<number>;
} => {
	const fields = {
		name: {
			name: 'Full Name',
			tab: 0
		},
		email: {
			name: 'Email ',
			tab: 0
		},
		startDate: {
			name: 'Collaboration Start Date',
			tab: 0
		},
		company: {
			name: 'Company',
			tab: 0
		},
		businessUnit: {
			name: 'Business Unit',
			tab: 0
		},
		division: {
			name: 'Division',
			tab: 0
		},
		department: {
			name: 'Department',
			tab: 0
		},
		employeeCategory: {
			name: 'Employee Category',
			tab: 0
		},
		collaborationRole: {
			name: 'Collaboration Role',
			tab: 0
		},
		careerPosition: {
			name: 'Career Name',
			tab: 1
		},
		careerJobRole: {
			name: 'Career Job Role',
			tab: 1
		},
		careerJobRoleLevel: {
			name: 'Career Job Role Level',
			tab: 1
		},
		careerManager: {
			name: 'Career Manager',
			tab: 1
		},
		socialSecurityNumber: {
			name: 'Social Security Number',
			tab: 1
		},
		holdersNumber: {
			name: 'Holders',
			tab: 1
		},
		maritalStatus: {
			name: 'Marital Status',
			tab: 1
		},
		dependentsNumber: {
			name: 'Number Of Dependents',
			tab: 1
		},
		baseValue: {
			name: 'Base Value',
			tab: 1
		},
		ihtValue: {
			name: 'Work Schedule Exemption Value',
			tab: 1
		},
		ihtPercentage: {
			name: 'Work Schedule Exemption  Percentage',
			tab: 1
		},
		vacationsTwelfth: {
			name: 'Vacations Twelfth',
			tab: 1
		},
		christmasTwelfth: {
			name: 'Christmas Twelfth',
			tab: 1
		},
		grossBaseValue: {
			name: 'Gross Base Value',
			tab: 1
		},
		totalTaxableValue: {
			name: 'Total Taxable Value',
			tab: 1
		},
		foodAllowanceType: {
			name: 'Food Allowance Type',
			tab: 1
		},
		foodAllowance: {
			name: 'Food Allowance',
			tab: 1
		},
		foodAllowanceDays: {
			name: 'Food Allowance Days',
			tab: 1
		},
		nationalAllowanceDays: {
			name: 'National Allowance Days',
			tab: 1
		},
		nationalAllowance: {
			name: 'National Allowance',
			tab: 1
		},
		internationalAllowanceDays: {
			name: 'International Allowance Days',
			tab: 1
		},
		internationalAllowance: {
			name: 'International Allowance',
			tab: 1
		},
		travelPass: {
			name: 'Travel Pass',
			tab: 1
		},
		additionalExpenses: {
			name: 'Other Expenses',
			tab: 1
		},
		nurseryCheck: {
			name: 'Nursery Check',
			tab: 1
		},
		lifeInsurance: {
			name: 'Life Insurance',
			tab: 1
		},
		prizeBonus: {
			name: 'Maximum Prize Bonus',
			tab: 1
		},
		prizeBonusPercentage: {
			name: '% From Annual Gross Value',
			tab: 1
		},
		monthsApplicable: {
			name: 'Months Applicable',
			tab: 1
		},
		eligiblePrizeValue: {
			name: 'Eligible Prize Value',
			tab: 1
		},
		prizeBonusGoals: {
			name: 'Prize Bonus Goals',
			tab: 1
		},
		annualPrizeIRS: {
			name: 'IRS Tax (In Prize Month)',
			tab: 1
		},
		totalComponentsPrizePercentage: {
			name: 'Total Prize Goals Percentage',
			tab: 1
		},
		totalComponentsPrizeValue: {
			name: 'Total Prize Goals Value',
			tab: 1
		},
		careerAndFinancialNotes: {
			name: 'Comments (Career & Finantial)',
			tab: 1
		},
		commsPlafondFlag: {
			name: 'Communications Plafond?',
			tab: 2
		},
		commsPlafond: {
			name: 'Communications Plafond Value',
			tab: 2
		},
		healthInsuranceCheck: {
			name: 'Health Insurance?',
			tab: 2
		},
		healthInsuranceValue: {
			name: 'Health Insurance Value',
			tab: 2
		},
		includeFamilyMembers: {
			name: 'Include Family Members?',
			tab: 2
		},
		numberMembersDep: {
			name: 'Number Of Family Members',
			tab: 2
		},
		familyMembersComments: {
			name: 'Include Family In Insurance Details',
			tab: 2
		},
		otherbenefits: {
			name: 'Has Other Benefits?',
			tab: 2
		},
		otherBenefitsList: {
			name: 'Other Benefits List',
			tab: 2
		},
		benefitsComments: {
			name: 'Other Comments (Other Benefits)',
			tab: 2
		},
		contractType: {
			name: 'Contract Type',
			tab: 3
		},
		customerInContract: {
			name: 'Customer In Contract',
			tab: 3
		},
		traineeType: {
			name: 'Trainee Type',
			tab: 3
		},
		fixedTermContractDuration: {
			name: 'Fixed Term Contract Duration',
			tab: 3
		},
		endDate: {
			name: 'End Date',
			tab: 3
		},
		contractPosition: {
			name: 'Contract Position',
			tab: 3
		},
		primaryOffice: {
			name: 'Primary Office',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		workingOffice: {
			name: 'Working Office',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		employeeManager: {
			name: 'Employee Manager',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		holidayType: {
			name: 'Holiday Type',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		referralType: {
			name: 'Referral Type',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		reference: {
			name: 'Referenced By',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		referralId: {
			name: 'Referral Id',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		projects: {
			name: 'Projects List',
			tab: isContractor === 'Contractor' ? 3 : 4
		},
		username: {
			name: 'Username',
			tab: 0
		},
		vacationDays: {
			name: 'Nr Of Vacation Days',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		timeOffApprovalType: {
			name: 'Time Off Approval Type',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		timeoffPolicy: {
			name: 'Time Off Policy',
			tab: isContractor === 'Contractor' ? 2 : 3
		},
		password: {
			name: 'Password',
			tab: isContractor === 'Contractor' ? 2 : 3
		}
	};

	const fieldsMissing: { name: string; tab: number }[] = [];

	if (errors) {
		Object.keys(errors).forEach(name => fieldsMissing.push(fields[name]));
	}
	return {
		additionalNames: fieldsMissing.map(e => e?.name),
		additionalTabs: new Set(fieldsMissing.map(e => e?.tab))
	};
};

export const formatCurrency = value => {
	if (typeof value !== 'number') {
		return '';
	}
	return new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR'
	}).format(value);
};
