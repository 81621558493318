//* EXTERNAL LIBS
import React, { useState, useEffect, FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, TabPanel, TabHeader, NoData, Tab } from 'xpand-ui/core';
import { InputLabel, InfoField, Numeric } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { IBenefit } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars-2';
import { useStyles } from './styles';
import { MyBenefitsProps } from '.';

//* COMPONENT
const MyBenefits: FC<MyBenefitsProps> = ({ personalInfo, getUserBenefits, clearPersonalInfoError }) => {
	const classes = useStyles();
	const { error, userBenefits } = personalInfo;
	const scrollbarRef = React.createRef();
	const urlBenefits = useSelector(state => state.company.urlBenefitis);
	const payload: { [key: string]: IBenefit[] } = {};

	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	});

	useEffect(() => {
		if (!userBenefits && !error) getUserBenefits();
	}, [userBenefits]);

	// TABS COMPONENT - state
	const [tabSelected, setTabSelected] = useState(0);

	// TABS COMPONENT - handle method
	const handleTabChange = (event: unknown, newValue: React.SetStateAction<number>) => {
		setTabSelected(newValue);
	};

	if (userBenefits) {
		userBenefits.benefits?.map(e => {
			if (payload[e.benefitsCategory$_identifier]) {
				payload[e.benefitsCategory$_identifier].push(e);
			} else {
				payload[e.benefitsCategory$_identifier] = [e];
			}

			return e;
		});
	}

	const hasBenefits: boolean = Object.keys(payload).length > 0;

	if (error) return handleErrorPage(error, clearPersonalInfoError);

	const isLoading = userBenefits === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			<div className={classes.tabsRoot}>
				{hasBenefits ? (
					<>
						{payload !== null && (
							<TabHeader
								style={{ marginBottom: '20px' }}
								selected={tabSelected}
								handleSelected={handleTabChange}>
								{Object.keys(payload).map((e, index) => (
									<Tab key={`${e}_tab_button`} label={e} index={index} />
								))}
							</TabHeader>
						)}
						{urlBenefits && (
							<InfoField
								fontSize="lg"
								label={
									<>
										Please check the detailed documentation about benefits{' '}
										<a
											style={{ fontSize: '12px' }}
											href={urlBenefits}
											rel="noopener noreferrer"
											target="_blank">
											here
										</a>
									</>
								}
								value=""
							/>
						)}
						<Scrollbars
							ref={scrollbarRef}
							style={{ height: 'calc(100vh - 270px)', overflowX: 'hidden' }}
							// eslint-disable-next-line
							renderTrackHorizontal={(props: any) => (
								<div {...props} style={{ display: 'none' }} className="track-horizontal" />
							)}>
							{Object.keys(payload).map((e, index) => (
								<TabPanel
									key={`${e}_tab_content`}
									value={tabSelected}
									index={index}
									className={classes.benefitsContainer}>
									<Grid
										container
										direction="row"
										justifyContent="space-evenly"
										alignItems="flex-start"
										alignContent="center"
										spacing={4}>
										{payload[e]?.map(ben => (
											<Grid key={ben.id} item {...sizes[12]}>
												<Grid
													container
													direction="row"
													justifyContent="space-between"
													alignItems="flex-start"
													spacing={3}
													className={classes.benefitsItem}>
													<Grid item {...sizes[4]}>
														<InputLabel>Name</InputLabel>
														<Typography
															variant="body1"
															className={classes.nonEditableField}
															gutterBottom>
															{ben.xphrDefBenCatName$_identifier}
														</Typography>
													</Grid>

													<Grid item {...sizes[4]}>
														<InputLabel>Supplier</InputLabel>
														<Typography
															variant="body1"
															className={classes.nonEditableField}
															gutterBottom>
															{ben.xphrDefBenCatSupplier$_identifier}
														</Typography>
													</Grid>

													<Grid item {...sizes[4]}>
														<InputLabel>Number</InputLabel>
														<Typography
															variant="body1"
															className={classes.nonEditableField}
															gutterBottom>
															{ben.number}
														</Typography>
													</Grid>

													<Grid item {...sizes[4]}>
														<InputLabel>Extra Information</InputLabel>
														<Typography
															variant="body1"
															className={classes.nonEditableField}
															gutterBottom>
															{ben.extraInformation$_identifier}
														</Typography>
													</Grid>

													<Grid item {...sizes[4]}>
														<InputLabel>Amount</InputLabel>
														{ben.benefitsCategory$_identifier === 'Health Insurance' &&
															ben.extraInformation$_identifier !== 'Cônjuge' && (
																<Typography
																	variant="body1"
																	className={classes.nonEditableField}
																	gutterBottom>
																	n.a.
																</Typography>
															)}

														{(ben.benefitsCategory$_identifier !== 'Health Insurance' ||
															ben.extraInformation$_identifier === 'Cônjuge') && (
															<Numeric readOnly money value={ben.amount} />
														)}
													</Grid>

													<Grid item {...sizes[4]} />
												</Grid>
											</Grid>
										))}
									</Grid>
								</TabPanel>
							))}
						</Scrollbars>
					</>
				) : (
					<Grid>
						<NoData />
					</Grid>
				)}
			</div>
		</>
	);
};

export default withLayout(MyBenefits);
