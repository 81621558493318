/* eslint-disable @typescript-eslint/no-explicit-any */
import { OriginalResponse } from 'lib/handlers/requestTypes';
import { ICompanyInfo } from './proposals';

export enum BudgetActions {
	SET_ERROR = '@@administration/budget/SET_ERROR',
	SET_LOADING = '@@administration/budget/SET_LOADING',
	GET_BUDGET_TABLE = '@@administration/budget/GET_BUDGET_TABLE',
	SET_NEW_BUDGET = '@@administration/budget/SET_NEW_BUDGET',
	SET_BUDGET_BI_REFRESH = '@@administration/budget/SET_BUDGET_BI_REFRESH',
	SEND_NEW_BUDGET = '@@administration/budget/SEND_NEW_BUDGET',
	GET_BUDGET_INFO = '@@administration/budget/GET_BUDGET_INFO',
	FETCH_BUDGET_DEPARTMENT_PROJECTS = '@@administration/budget/FETCH_BUDGET_DEPARTMENT_PROJECTS',
	SEND_UPDATE_BUDGET = '@@administration/budget/SEND_UPDATE_BUDGET',
	GET_BUDGET_AUDIT = '@@administration/budget/GET_BUDGET_AUDIT',
	CLEAR_BUDGET_ACTION = '@@administration/budget/CLEAR_BUDGET_ACTION',
	CLEAR_BUDGET_INFO = '@@administration/budget/CLEAR_BUDGET_INFO',
	SEND_DELETE_BUDGET = '@@administration/budget/SEND_DELETE_BUDGET',
	SEND_EXPORT_BUDGET = '@@administration/budget/SEND_EXPORT_BUDGET',
	SET_BUDGET_COMPANY = '@@administration/budget/SET_BUDGET_COMPANY',
	POST_IMPORT_BUDGET = '@@administration/administration/POST_UPLOAD_FILE_TEMPLATE'
}

export type BudgetState = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly budgetList: IBudgetInfo[] | null;
	readonly newBudget: any | null;
	readonly budgetAction: OriginalResponse | null;
	readonly budgetInfo: IEditBudget | null;
	readonly budgetProjects: unknown | null;
	readonly budgetAudit: unknown | null;
	readonly deleteBudget: unknown | null;
	readonly exportBudget: unknown | null;
	readonly importBudget: unknown | null;
	readonly budgetCompanies: any | null;
};

export interface IDepartmentData {
	departmentId: string;
	id?: number;
	departmentalBudgetFlag: boolean;
	incomeQ1: number | string;
	incomeQ2: number | string;
	incomeQ3: number | string;
	incomeQ4: number | string;
	incomeQ5: number | string;
	employeeCostQ1: number | string;
	employeeCostQ2: number | string;
	employeeCostQ3: number | string;
	employeeCostQ4: number | string;
	employeeCostQ5: number | string;
	nonEmployeeCostQ1: number | string;
	nonEmployeeCostQ2: number | string;
	nonEmployeeCostQ3: number | string;
	nonEmployeeCostQ4: number | string;
	nonEmployeeCostQ5: number | string;
}

export interface IBudgetInfo {
	id: number;
	name: string;
	customName: string;
	year: number;
	history?: unknown;
	version: number;
	statusId: number;
	creationDate: string;
	updatedDate: string;
	approvalDate: string;
	type: string;
	budgetCompany: string | null;
	lines: IDepartmentData[];
}

export interface INewBudgetModal {
	budgetTypes: { id: string; name: string }[];
	budgetList: IBudgetInfo[];
}

export interface IEditBudget {
	companies: ICompanyInfo[];
	budget: IBudgetInfo;
	columnHeaders: string[];
}

export interface IImportBudget {
	budget?: IImportBudget[];
}

export interface IBudgetPostPayload {
	id: number;
	name: string;
	customName: string;
	year: number;
	version: number;
	statusId: number;
	creationDate: string;
	updatedDate: string;
	approvalDate: string;
	approvedFlag: boolean;
	type: string;
	lines: {
		[company: string]: {
			name: string;
			data: {
				[businessUnit: string]: {
					name: string;
					data: {
						[division: string]: {
							name: string;
							data: {
								[department: string]: {
									name: string;
									data: {
										departmentId: string;
										departmentalBudgetFlag: boolean;
										incomeQ1: number | string;
										incomeQ2: number | string;
										incomeQ3: number | string;
										incomeQ4: number | string;
										incomeQ5: number | string;
										employeeCostQ1: number | string;
										employeeCostQ2: number | string;
										employeeCostQ3: number | string;
										employeeCostQ4: number | string;
										employeeCostQ5: number | string;
										nonEmployeeCostQ1: number | string;
										nonEmployeeCostQ2: number | string;
										nonEmployeeCostQ3: number | string;
										nonEmployeeCostQ4: number | string;
										nonEmployeeCostQ5: number | string;
									};
								};
							};
						};
					};
				};
			};
		};
	};
}
