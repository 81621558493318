/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { IProposalProject } from 'typings/store/admin/proposals';

import { DualTransferList } from 'xpand-ui/core';
import { useStyles } from './styles';

const Projects: FC<any> = ({ watch, setValue, selectOptions }) => {
	const formProjects = watch('projects');
	const classes = useStyles();

	/** handle dual transfer list component changes */
	const handleListChanges = (l: IProposalProject[], r: IProposalProject[]) => setValue('projects', r);

	return (
		<div>
			<DualTransferList
				title="Projects"
				leftList={selectOptions.projects.filter(
					(e: IProposalProject) => !formProjects?.some((k: IProposalProject) => k.projectId === e.projectId)
				)}
				rightList={typeof formProjects !== 'undefined' ? formProjects : []}
				payloadId="projectId"
				payloadLabel="projectName"
				handleChanges={handleListChanges}
			/>
		</div>
	);
};

export default Projects;
