import { createReducer } from 'typesafe-actions';
import { SkillsAndResumesActions as Actions, SkillsAndResumesState } from 'typings/store/admin/skillsAndResumes';

const INITIAL_STATE: SkillsAndResumesState = {
	loading: false,
	error: null,
	newSkillsTreeAction: null,
	listUsersSkills: null,
	userSkillsEvaluation: null,
	userResendSkillsEvaluation: null,
	userChangeDueDate: null,
	skillsFeedback: null,
	listSearchResumes: null,
	exportResume: null,
	exportMultipleResumes: null,
	remindResume: null,
	resumeDetails: null
};

const SkillsAndResumesReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.POST_NEW_SKILLS_TREE]: (state, { payload }) => ({
		...state,
		loading: false,
		newSkillsTreeAction: payload
	}),
	[Actions.GET_SKILLS_EVALUATION]: (state, { payload }) => ({
		...state,
		loading: false,
		listUsersSkills: payload
	}),
	[Actions.CLEAR_SKILLS_EVALUATION]: state => ({
		...state,
		loading: false,
		listUsersSkills: null
	}),
	[Actions.GET_USER_SKILLS_EVALUATION]: (state, { payload }) => ({
		...state,
		loading: false,
		userSkillsEvaluation: payload
	}),
	[Actions.CLEAR_USER_SKILLS_EVALUATION]: state => ({
		...state,
		loading: false,
		userSkillsEvaluation: null
	}),
	[Actions.POST_RESEND_SKILLS_EVALUATION]: (state, { payload }) => ({
		...state,
		loading: false,
		userResendSkillsEvaluation: payload
	}),
	[Actions.POST_CHANGE_DUE_DATE]: (state, { payload }) => ({
		...state,
		loading: false,
		userChangeDueDate: payload
	}),
	[Actions.POST_SKILLS_FEEDBACK]: (state, { payload }) => ({
		...state,
		loading: false,
		skillsFeedback: payload
	}),
	[Actions.GET_SEARCH_RESUMES]: (state, { payload }) => ({
		...state,
		loading: false,
		listSearchResumes: payload
	}),
	[Actions.GET_EXPORT_RESUME]: (state, { payload }) => ({
		...state,
		loading: false,
		exportResume: payload
	}),
	[Actions.GET_EXPORT_MULTIPLE_RESUMES]: (state, { payload }) => ({
		...state,
		loading: false,
		exportMultipleResumes: payload
	}),
	[Actions.POST_REMIND_RESUME]: (state, { payload }) => ({
		...state,
		loading: false,
		remindResume: payload
	}),
	[Actions.GET_RESUME_DETAILS]: (state, { payload }) => ({
		...state,
		loading: false,
		resumeDetails: payload
	}),
	[Actions.CLEAR_RESUMES_LIST]: state => ({
		...state,
		loading: false,
		resumeDetails: null
	})
});

export default SkillsAndResumesReducer;
