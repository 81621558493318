import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';
import { clearAppsError, getAppsList } from 'store/general/apps/thunks';
import { getContractsList } from 'store/administration/employeeSalaryCategories/thunks';
import { setAppsPageFilter } from 'store/filters/actions';
import { getUserProfile, sendUserUpdate } from 'store/personalInfo/thunks';

import AppsPage from './AppsPage';

const mapStateToProps = ({ general, employeeSalary, filters, personalInfo }: AppState) => ({
	general,
	employeeSalary,
	filters,
	personalInfo,
	getUserProfile,
	sendUserUpdate
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{ clearAppsError, getAppsList, getContractsList, setAppsPageFilter, getUserProfile, sendUserUpdate },
		dispatch
	);

export type AppsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AppsPage);
