import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import { AppPageActions as Actions, IApp } from 'typings/store/appPageTypes';
import { OriginalResponse } from 'lib/handlers/requestTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setError = (payload: unknown | null): IDispatchType => action(Actions.SET_ERROR, payload);

export const setAppsList = (payload: IApp | []): IDispatchType => action(Actions.GET_APPS_LIST, payload);

export const setAppsError = (payload: OriginalResponse | null): IDispatchType => action(Actions.SET_ERROR, payload);
