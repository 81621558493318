import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getSkillsEvaluation,
	sendPostResendSkillsEvaluation,
	sendPostChangeDueDate,
	clearSkillsAndResumesError
} from 'store/administration/skillsAndResumes/thunks';
import { getSystemSkills } from 'store/personalInfo/thunks';
import { setSkillsReminderPageFilter } from 'store/filters/actions';
import { AppState } from 'typings/state';

import SkillsEvaluation from './SkillsEvaluation';

const mapStateToProps = ({ skillsAndResumes, personalInfo, filters }: AppState) => ({
	skillsAndResumes,
	personalInfo,
	filters
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getSkillsEvaluation,
			getSystemSkills,
			sendPostResendSkillsEvaluation,
			sendPostChangeDueDate,
			clearSkillsAndResumesError,
			setSkillsReminderPageFilter
		},
		dispatch
	);

export type SkillsEvaluationProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SkillsEvaluation);
