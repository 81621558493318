/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Radio, DatePicker, Input } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { IAdmissionSheetTabs } from '../../AdmissionSheet';
import { useStyles } from './styles';

const GenderOptions = [
	{ id: 'M', label: 'MALE' },
	{ id: 'F', label: 'FEMALE' }
];

//* COMPONENT INTERFACES
interface IGeneralInfo extends IAdmissionSheetTabs {
	admissionSheetData: any;
	pageToken: string;
}

//* COMPONENT
const GeneralInfo: FC<IGeneralInfo> = ({ control, errors, setValue, admissionSheetData, pageToken, formFields }) => {
	const classes = useStyles();

	return (
		<Grid container spacing={4} className={classes.root}>
			{formFields?.formFieldsConfig?.fullName && (
				<Grid item {...sizes[6]}>
					<Input name="fullName" label="Full Name" required control={control} errors={errors} />
				</Grid>
			)}
			{formFields?.formFieldsConfig?.placeOfBirth && (
				<Grid item {...sizes[6]}>
					<Input name="placeOfBirth" label="Place Of Birth" required control={control} errors={errors} />
				</Grid>
			)}
			{formFields?.formFieldsConfig?.gender && (
				<Grid item {...sizes[6]}>
					<Radio
						row
						required
						name="gender"
						label="Gender"
						options={GenderOptions}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields?.formFieldsConfig?.dateOfBirth && (
				<Grid item {...sizes[6]}>
					<DatePicker
						name="dateOfBirth"
						label="Date of birth"
						control={control}
						errors={errors}
						additionalOnChange={item => {
							if (
								item !== null &&
								Boolean(admissionSheetData) &&
								Boolean(admissionSheetData[pageToken])
							) {
								setValue(
									'showContractCheckbox',
									item >= admissionSheetData[pageToken].contractCheckboxMaximumDate
								);
							}
						}}
						required
					/>
				</Grid>
			)}
			{Boolean(admissionSheetData) &&
				Boolean(admissionSheetData[pageToken].associatedProposal?.additionalExpenses) &&
				formFields?.formFieldsConfig?.licensePlate && (
					<Grid item {...sizes[6]}>
						<Input
							name="licensePlate"
							label="License Plate"
							helperText="Required for the Expense Reports (KMs) generated based on the value registered in 'Other Expenses' on the Collaboration Proposal.
									In case you do not own a vehicle, please insert the license plate of a family member or friend."
							required
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
		</Grid>
	);
};

export default GeneralInfo;
