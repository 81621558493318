import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import {
	ProposalActions as Actions,
	IProposalsList,
	IProposalSummaryPayload,
	IUserProposalList,
	IGetNewProposal,
	ISendEmail,
	IIrsValueGet,
	IAnualGrossValueGet,
	IProposalAuditLog
} from 'typings/store/admin/proposals';
import { OriginalResponse } from 'lib/handlers/requestTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setProposalsError = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.SET_ERROR, payload);

export const setProposalsList = (payload: IProposalsList | null): IDispatchType =>
	action(Actions.GET_PROPOSALS_LIST, payload);

export const setUserProposalsList = (payload: IUserProposalList | null, token: string | null = null): IDispatchType =>
	action(Actions.GET_USER_PROPOSALS_LIST, { payload, token });

// PROPOSAL ACTIONS

export const setProposalSendEmail = (payload: ISendEmail | null): IDispatchType =>
	action(Actions.SET_PROPOSAL_SEND_EMAIL, payload);

export const setPostProposalSendEmail = (
	payload: OriginalResponse | null,
	token: string | null = null
): IDispatchType => action(Actions.SET_POST_PROPOSAL_SEND_EMAIL, { payload, token });

// Payloads vazios?
export const setProposalAccept = (payload: [] | null, token: string | null = null): IDispatchType =>
	action(Actions.SET_PROPOSAL_ACCEPT, { payload, token });

export const setProposalRefuse = (payload: [] | null, token: string | null = null): IDispatchType =>
	action(Actions.SET_PROPOSAL_REFUSE, { payload, token });

export const setProposalClose = (payload: [] | null, token: string | null = null): IDispatchType =>
	action(Actions.SET_PROPOSAL_CLOSE, { payload, token });

export const setProposalCancel = (payload: [] | null, token: string | null = null): IDispatchType =>
	action(Actions.SET_PROPOSAL_CANCEL, { payload, token });

export const setProposalRequestApproval = (payload: string | null, token: string | null = null): IDispatchType =>
	action(Actions.SET_PROPOSAL_REQUEST_APPROVAL, { payload, token });

export const setProposalChangeStartDate = (payload: string | null, token: string | null = null): IDispatchType =>
	action(Actions.SET_PROPOSAL_START_CHANGE_DATE, { payload, token });

export const setProposalApprove = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.SET_PROPOSAL_APPROVE, { payload });

export const setProposalReject = (payload: OriginalResponse | null): IDispatchType =>
	action(Actions.SET_PROPOSAL_REJECT, { payload });

// PROPOSAL FORM GETS

export const setNewProposal = (payload: IGetNewProposal | null): IDispatchType =>
	action(Actions.GET_NEW_PROPOSAL, payload);

export const setNewProposalWToken = (payload: IGetNewProposal | null): IDispatchType =>
	action(Actions.GET_NEW_PROPOSAL_WITH_TOKEN, payload);

export const setEditProposal = (payload: IGetNewProposal | null): IDispatchType =>
	action(Actions.GET_EDIT_PROPOSAL, payload);
// PROPOSAL FORM SUBMITS

export const setPostNewProposal = (payload: OriginalResponse | null = null): IDispatchType =>
	action(Actions.SUBMIT_NEW_PROPOSAL, payload);

export const setPutUpdateProposal = (payload: OriginalResponse | null = null): IDispatchType =>
	action(Actions.SUBMIT_PUT_UPDATE_PROPOSAL, payload);

// HELPERS
export const setProposalAnnualGrossValue = (payload: IAnualGrossValueGet | null): IDispatchType =>
	action(Actions.SET_PROPOSAL_ANNUAL_GROSS_VALUE, payload);

export const setIrsValue = (payload: IIrsValueGet | null): IDispatchType => action(Actions.SET_IRS_VALUE, payload);

export const setProposalSummary = (payload: IProposalSummaryPayload | null): IDispatchType =>
	action(Actions.SET_PROPOSAL_SUMMARY, payload);

export const setExistingProposalSummary = (payload: IProposalSummaryPayload | null): IDispatchType =>
	action(Actions.SET_EXISTING_PROPOSAL_SUMMARY, payload);

export const setClearSummary = (): IDispatchType => action(Actions.CLEAR_PROPOSAL_SUMMARY, null);

export const setProposalSummaryLoading = (payload: boolean): IDispatchType =>
	action(Actions.SET_PROPOSAL_SUMMARY_LOADING, payload);

// Duvida nos exports
export const setProposalExport = (): IDispatchType => action(Actions.SET_PROPOSAL_EXPORT);

export const setProposalExportWithID = (): IDispatchType => action(Actions.SET_PROPOSAL_EXPORT_WITH_ID);

export const resetFormData = (): IDispatchType => action(Actions.RESET_FORM_DATA);

// Audit Log

export const setProposalAuditLog = (payload: IProposalAuditLog | null): IDispatchType =>
	action(Actions.GET_PROPOSAL_AUDIT_LOG, payload);
