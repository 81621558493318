import { createReducer } from 'typesafe-actions';
import { AdmissionsActions as Actions, AdmissionsState } from 'typings/store/admin/admissions';

const INITIAL_STATE: AdmissionsState = {
	loading: false,
	error: null,
	newAdmission: null,
	admissionSheetData: null,
	newAdmissionSheet: null,
	invalidDocs: [],
	blockAdmissionSheet: false,
	admissionManagementData: null,
	userAdmissionDetailsData: null,
	employeeDataResult: null,
	sentAdmissionResult: null,
	canceledAdmissionResult: null,
	closedAdmissionResult: null,
	admissionApprovalResult: null,
	admissionInvalidationResult: null,
	fetchFileResult: null,
	deleteFileResult: null,
	// create employee
	newEmployee: null,
	admissionAction: null,
	// 	RESIGNATION
	resignationList: null,
	resignationForm: null,
	resignationAction: null,
	deactivateForm: null,
	deactivateAction: null,
	exportCertificate: null,
	exportReceipt: null
};

const AdmissionsReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload, loading: false }),
	[Actions.GET_NEW_ADMISSION]: (state, { payload }) => ({
		...state,
		loading: false,
		newAdmission: payload
	}),
	[Actions.POST_NEW_ADMISSION]: (state, { payload }) => ({
		...state,
		loading: false,
		admissionAction: payload
	}),
	[Actions.SET_INVALID_DOCS]: (state, { payload }) => ({ ...state, loading: false, invalidDocs: payload }),
	[Actions.GET_ADMISSION_SHEET]: (state, { payload }) => ({
		...state,
		loading: false,
		admissionSheetData: { ...state.admissionSheetData, [payload.token]: payload.data }
	}),
	[Actions.SET_BLOCK_ADMISSION_SHEET]: (state, { payload }) => ({
		...state,
		loading: false,
		blockAdmissionSheet: payload
	}),
	[Actions.GET_ADMISSION_MANAGEMENT]: (state, { payload }) => ({
		...state,
		newAdmission: null,
		admissionAction: null,
		loading: false,
		admissionManagementData: payload
	}),
	[Actions.GET_ADMISSION_DETAILS]: (state, { payload }) => ({
		...state,
		newAdmission: null,
		admissionAction: null,
		loading: false,
		userAdmissionDetailsData: payload
	}),
	[Actions.GET_EMPLOYEE_DATA]: (state, { payload }) => ({
		...state,
		newAdmission: null,
		admissionAction: null,
		loading: false,
		employeeDataResult: payload
	}),
	// uses the same userAdmissionDetailsData as GET_ADMISSION_DETAILS
	[Actions.GET_SUBMISSION_INFO]: (state, { payload }) => ({
		...state,
		loading: false,
		userAdmissionDetailsData: payload
	}),
	[Actions.POST_SEND_ADMISSION]: (state, { payload }) => ({
		...state,
		loading: false,
		sentAdmissionResult: payload
	}), // TODO not used
	[Actions.POST_RESEND_ADMISSION]: (state, { payload }) => ({
		...state,
		loading: false,
		sentAdmissionResult: payload
	}), // TODO not used
	[Actions.POST_CANCEL_ADMISSION]: (state, { payload }) => ({
		...state,
		loading: false,
		canceledAdmissionResult: payload
	}), // TODO not used
	[Actions.POST_CLOSE_ADMISSION]: (state, { payload }) => ({
		...state,
		loading: false,
		closeAdmissionResult: payload
	}), // TODO not used
	[Actions.POST_APPROVE_ADMISSION]: (state, { payload }) => ({
		...state,
		loading: false,
		admissionApprovalResult: payload
	}), // TODO not used
	[Actions.POST_INVALIDATE_ADMISSION]: (state, { payload }) => ({
		...state,
		loading: false,
		admissionInvalidationResult: payload
	}), // TODO not used
	[Actions.GET_FILE]: (state, { payload }) => ({ ...state, loading: false, fetchFileResult: payload }), // TODO not used
	[Actions.DELETE_FILE]: (state, { payload }) => ({ ...state, loading: false, deleteFileResult: payload }), // TODO not used
	[Actions.CLEAR_EMPLOYEE_DATA]: state => ({
		...state,
		loading: false,
		employeeDataResult: null
	}),
	//!	CREATE EMPLOYEE
	// Admission Management - Create Employee GET
	[Actions.GET_CREATE_EMPLOYEE]: (state, { payload }) => ({
		...state,
		loading: false,
		newEmployee: payload,
		admissionAction: null
	}),
	// Admission Management - Create Employee POST
	[Actions.SUBMIT_CREATE_EMPLOYEE]: (state, { payload }) => ({
		...state,
		loading: false,
		admissionAction: payload
	}),
	[Actions.CLEAR_CREATE_EMPLOYEE_FORM]: state => ({
		...state,
		...INITIAL_STATE,
		loading: false
	}),

	// 	RESIGNATION
	[Actions.GET_USERS_RESIGNATION]: (state, { payload }) => ({
		...state,
		loading: false,
		resignationList: payload
	}),
	[Actions.GET_RESIGNATION_FORM]: (state, { payload }) => ({
		...state,
		loading: false,
		resignationForm: payload
	}),
	[Actions.GET_RESIGNATION_FORM_SUBMIT]: (state, { payload }) => ({
		...state,
		loading: false,
		...(payload.code === 200 && { resignationList: null, resignationForm: null }),
		resignationAction: payload
	}),
	[Actions.GET_DEACTIVATE_FORM]: (state, { payload }) => ({
		...state,
		loading: false,
		deactivateForm: payload
	}),
	[Actions.GET_DEACTIVATE_FORM_SUBMIT]: (state, { payload }) => ({
		...state,
		loading: false,
		...(payload.code === 200 && { resignationList: null, resignationForm: null }),
		deactivateAction: payload
	}),
	[Actions.RESET_RESIGN_ACTION]: () => ({ ...INITIAL_STATE }),
	[Actions.GET_EXPORT_CERTIFICATE]: (state, { payload }) => ({
		...state,
		loading: false,
		exportCertificate: payload
	}),
	[Actions.GET_EXPORT_RECEIPT]: (state, { payload }) => ({
		...state,
		loading: false,
		exportReceipt: payload
	})
});

export default AdmissionsReducer;
