import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	//* WEB
	[theme.breakpoints.up('md')]: {
		root: {
			padding: '30px 100px'
		}
	},
	//* TABLET
	[theme.breakpoints.down('md')]: {
		root: {
			padding: '10px 20px'
		}
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		root: {
			padding: '5px 10px'
		}
	},
	animation: {
		visibility: 'hidden',
		opacity: '0',
		transition: 'all 0.3s ease'
	},
	show: {
		visibility: 'visible',
		opacity: 1,
		display: 'block'
	},
	link: {
		fontSize: '10px'
	},
	infoLabel: {
		fontSize: '16px',
		display: 'flex',
		color: theme.grayedColor
	},
	attachmentButton: {
		marginTop: '10px'
	},
	errorInputLabel: {
		marginTop: theme.spacing(1),
		color: 'red'
	}
}));

export { useStyles };
