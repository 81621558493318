// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		name: {
			fontFamily: 'Open Sans SemiBold',
			textTransform: 'uppercase',
			fontSize: '16px'
		},
		statusBar: {
			// direction: 'row',
			justify: 'center',
			alignItems: 'center',
			display: 'flex'
		},
		statusIcon: {
			marginRight: '10px',
			marginBotton: '50px'
		}
	})
);

export { useStyles };
