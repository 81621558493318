import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, POST, PUT, EXPORTFILE, EXPORTFILEPOST } from 'lib/handlers/requestTypes';
import {
	IAnualGrossValuePost,
	IDownloadProposal,
	IIrsValuePost,
	IProposalPayload,
	IRejectProposalPost,
	ISendEmail
} from 'typings/store/admin/proposals';

// COLLABORATION PROPOSALS LISTS
export const fetchProposalsList = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/proposals');

export const fetchUserProposalsList = async (payload: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/proposals/${payload}`);

// NEW/Edit
export const fetchNewProposal = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/proposals/new');
export const fetchNewProposalWToken = async (token: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/proposals/new/${token}`);

export const fetchEditProposal = async (token: string, id: number | string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/proposals/edit/${token}/${id}`);

export const postNewProposal = async (payload: IProposalPayload, token: string | boolean): Promise<OriginalResponse> =>
	restCall(POST, `/admin/proposals/new${token ? `/${token}` : ''}`, {
		...payload,
		workScheduleExemptionValue: payload.ihtValue,
		prizeValue: payload.prizeBonus
	}) as unknown as OriginalResponse;
export const putEditProposal = async (
	payload: IProposalPayload,
	token: string,
	id: number
): Promise<OriginalResponse> =>
	restCall(PUT, `/admin/proposals/edit/${token}/${id}`, {
		...payload,
		workScheduleExemptionValue: payload.ihtValue,
		prizeValue: payload.prizeBonus
	}) as unknown as OriginalResponse;

// COLLABORATION PROPOSALS ACTIONS
// REQUEST APPROVAL
export const proposalRequestApproval = async (
	token: string | undefined,
	proposalId: number,
	payload: unknown
): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/proposals/requestApproval/${token}/${proposalId}`, payload);

export const proposalChangeStartDate = async (
	token: string | undefined,
	proposalId: number,
	payload: unknown
): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/proposals/changeStartDate/${token}/${proposalId}`, payload);

// APPROVE
export const proposalApprove = async (
	token: string | undefined,
	proposalId: number,
	manager: string
): Promise<OriginalResponse> =>
	restCall(POST, `/admin/proposals/approve/${token}/${proposalId}/${manager}`) as unknown as OriginalResponse;

// REJECT
export const proposalReject = async (
	token: string | undefined,
	proposalId: number,
	manager: string,
	payload: IRejectProposalPost
): Promise<OriginalResponse> =>
	restCall(POST, `/admin/proposals/reject/${token}/${proposalId}/${manager}`, payload) as unknown as OriginalResponse;

// GET SEND EMAIL
export const proposalSendEmail = async (
	token: string | undefined,
	proposalId: number
): Promise<OriginalResponse | Success> => restCall(GET, `/admin/proposals/email/${token}/${proposalId}`);

// POST SEND EMAIL
export const sendProposalSendEmail = async (
	token: string | undefined,
	proposalId: number,
	payload: ISendEmail
): Promise<OriginalResponse> =>
	restCall(POST, `/admin/proposals/email/${token}/${proposalId}`, payload) as unknown as OriginalResponse;

// ACCEPT
export const proposalAccept = async (
	token: string | undefined,
	proposalId: number
): Promise<OriginalResponse | Success> => restCall(POST, `/admin/proposals/accept/${token}/${proposalId}`, {});

// REFUSE
export const proposalRefuse = async (
	token: string | undefined,
	proposalId: number
): Promise<OriginalResponse | Success> => restCall(POST, `/admin/proposals/refuse/${token}/${proposalId}`, {});

// CLOSE
export const proposalClose = async (
	token: string | undefined,
	proposalId: number
): Promise<OriginalResponse | Success> => restCall(POST, `/admin/proposals/close/${token}/${proposalId}`, {});

// CANCEL
export const proposalCancel = async (
	token: string | undefined,
	proposalId: number
): Promise<OriginalResponse | Success> => restCall(POST, `/admin/proposals/cancel/${token}/${proposalId}`, {});

export const fetchIrsValue = async (payload: IIrsValuePost): Promise<OriginalResponse | Success> =>
	restCall(POST, '/public/util/financial/irs', payload);

export const fetchProposalSummary = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/public/util/financial/proposalSummary', payload);

export const fetchExistingProposalSummary = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(GET, `/public/util/financial/proposalSummary/${payload}`);

export const fetchProposalAnnualGrossValue = async (
	payload: IAnualGrossValuePost
): Promise<OriginalResponse | Success> => restCall(POST, '/public/util/financial/annualGrossValue', payload);

export const fetchProposalExport = async (
	exportType: string,
	payload: IDownloadProposal
): Promise<OriginalResponse | Success> => restCall(EXPORTFILEPOST, `/admin/proposals/download/${exportType}`, payload);

export const fetchProposalExportWithID = async (
	exportType: string,
	token: string | undefined,
	id: number
): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILE, `/admin/proposals/download/${exportType}/${token}/${id}`);

export const fetchProposalAuditLog = async (token: string, id: number | string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/proposals/audit/${token}/${id}`);
