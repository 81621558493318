import * as yup from 'yup';

import { stringRequired, dateRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	contractEndDate: dateRequired,
	endReason: stringRequired,
	contractEndReason: stringRequired
});

export const defaultValues = {
	contractEndDate: '',
	endReason: '',
	contractEndReason: ''
};
