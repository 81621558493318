import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	clearBudgetError,
	getBudgetEditInfo,
	getBudgetDepProjects,
	submitPUTNewBudget,
	clearBudgetAction,
	clearBudgetInfo
} from 'store/administration/budget/thunks';

import { getAccessControlEditUser } from 'store/administration/administration/thunks';
import { AppState } from 'typings/state';

import Component from './EditBudget';

const mapStateToProps = ({ budget, administration }: AppState) => ({ budget, administration });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			clearBudgetError,
			getBudgetEditInfo,
			getBudgetDepProjects,
			submitPUTNewBudget,
			getAccessControlEditUser,
			clearBudgetAction,
			clearBudgetInfo
		},
		dispatch
	);

export type EditBudgetProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
