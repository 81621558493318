/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
//* EXTERNAL LIBS
import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { yupResolver } from '@hookform/resolvers/yup';
import { Control, useForm, UseFormWatch, UseFormSetValue, FieldErrors } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter, TabPanel, FormSteps } from 'xpand-ui/core';

//* TYPINGS
import { HelperProps } from 'typings/store/generalTypes';
import { INewProject } from 'typings/store/admin/projectsAndClients';

//* PROJECT IMPORTS [LIB / PAGES ]
import { prepareCompanyData } from 'lib/utils/erpUtils';
import { isTouchedFieldsUsed } from 'lib/utils/formUtils';

//* LOCAL COMPONENT IMPORTS
import { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { Prompt } from 'react-router';
import { schema, defaultValues, tabs } from './yupSchema';
import ProjectInformation from './tabs/ProjectInformation';
import ProjectTeam from './tabs/ProjectTeam';
import ProjectRepositories from './tabs/ProjectRepositories';
import ExternalUsers from './tabs/ExternalUsers';
import Budgets from './tabs/Budgets';
import Comments from './tabs/Comments';
import { useStyles } from './styles';
import { NewProjectProps } from '.';
import { getLSField } from 'lib/utils/cookies';

// //* COMPONENT TABS INTERFACES
export interface IProjectTabs {
	[x: string]: any;
	control: Control<INewProject>;
	errors: FieldErrors<INewProject>;
	watch: UseFormWatch<INewProject>;
	setValue: UseFormSetValue<INewProject>;
}

//* COMPONENT
const NewProject: FC<NewProjectProps> = ({
	projectsAndClients,
	company,
	getNewProjectData,
	sendNewProject,
	clearProjectAndClientsError
}) => {
	const classes = useStyles();
	const { fetchedNewProjectData, loading, error } = projectsAndClients;
	const scrollToTopRef = useRef<HTMLDivElement | any>(null);
	const scrollbarRef = React.createRef();
	const [tabSelected, setTabSelected] = useState(0);
	const [submitClick, setSubmitClick] = useState(false);

	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	}, [tabSelected]);

	useEffect(() => {
		scrollToTopRef?.current?.scrollIntoView(0, 0);
	});

	const [formWarnings, setFormWarnings] = useState<Array<number>>([]);

	const [selectedCompanyLabel, setSelectedCompanyLabel] = useState('');
	const [newProjectHelper, setNewProjectHelper] = useState<HelperProps>({
		company: null,
		businessUnit: null,
		division: null,
		department: null
	});
	const [repositories, setSourceRepositories] = useState<
		{
			type: string;
			name: string;
		}[]
	>([]);
	const [externalUsers, setExternalUsers] = useState<
		{
			name: string;
			company: string;
			email: string;
		}[]
	>([]);
	const [budget, setBudget] = useState<
		{ profile: string; role: string; level: string; days: string; dailyRate: string }[]
	>([]);

	const {
		handleSubmit,
		control,
		watch,
		getValues,
		setValue,
		reset,
		resetField,
		trigger,
		formState: { errors, touchedFields }
	} = useForm<INewProject>({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		if (!fetchedNewProjectData && !error) {
			getNewProjectData(false);
		}
	}, [fetchedNewProjectData]);

	// Budget options depend on the company selected. If the company changes, all the budget must be cleared.
	useEffect(() => {
		setBudget([]);
		const budgets = getValues('budget');
		if (budgets && budgets.length > 0) reset({ ...getValues(), budget: [] });
	}, [newProjectHelper.company]);

	// Budget options depend on the department selected. If the department changes, all the budget must be cleared.
	useEffect(() => {
		const budgets = getValues('budget');
		for (let budgetLine of budgets) {
			budgetLine.profile = '';
		}
		setValue('budget', budgets);
	}, [newProjectHelper.department]);

	const clearFormData = () => {
		setSourceRepositories([]);
		setExternalUsers([]);
		setBudget([]);
		setValue('repositories', []);
		setValue('externalUsers', []);
		setValue('budget', []);
	};

	const onSubmit = async () => {
		const payload = watch() as INewProject;
		payload.isPtOwnership = payload.ownership?.toLowerCase() === 'PT'.toLowerCase();
		payload.isUkOwnership = !payload.isPtOwnership;
		delete payload.ownership;
		delete payload.businessUnit;
		delete payload.division;
		await sendNewProject(payload);
		// clear all data from form
		reset(defaultValues);
		clearFormData();
	};

	const selectedCompany = useMemo(() => {
		if (!fetchedNewProjectData) {
			return undefined;
		}
		return newProjectHelper.company
			? fetchedNewProjectData.companies.find(company => company.searchKey === newProjectHelper.company)
			: undefined;
	}, [fetchedNewProjectData, newProjectHelper.company]);

	// Data related to the Company option
	const companySelectOptions = useMemo(() => {
		if (!fetchedNewProjectData) {
			return {
				company: [],
				projectTypes: [],
				businessUnit: [],
				division: [],
				department: [],
				lineOfBusiness: [],
				careerProfiles: []
			};
		}

		const result = !selectedCompany
			? prepareCompanyData(newProjectHelper, fetchedNewProjectData)
			: {
					...prepareCompanyData(newProjectHelper, fetchedNewProjectData),
					projectTypes: selectedCompany?.projectTypes?.map(prjType => ({
						id: prjType.id,
						label: prjType.name
					})),
					lineOfBusiness: selectedCompany?.linesOfBusiness?.map(lob => ({ id: lob.id, label: lob.name })),
					careerProfiles: selectedCompany?.careerProfiles?.map(careerProfile => ({
						id: careerProfile.id,
						label: careerProfile.name,
						eligibleDepartments: careerProfile.eligibleDepartments
					}))
			  };

		return result;
	}, [fetchedNewProjectData, newProjectHelper.company, newProjectHelper.businessUnit, newProjectHelper.division]);

	// Data related to the Project
	const projectRelatedOptions = useMemo(() => {
		if (!fetchedNewProjectData) {
			return {
				costTypes: [],
				hubDistributionSchemes: [],
				projectSalesTypes: [],
				users: [],
				timesheetApprovalTypes: []
			};
		}

		return {
			costTypes: fetchedNewProjectData.costTypes.map(costType => ({
				id: costType.searchKey,
				label: costType.name
			})),
			hubDistributionSchemes: fetchedNewProjectData.hubDistributionSchemes.map(hubDistributionScheme => ({
				id: hubDistributionScheme.searchKey,
				label: hubDistributionScheme.name
			})),
			projectSalesTypes: fetchedNewProjectData.projectSalesTypes.map(projectSalesType => ({
				id: projectSalesType.searchKey,
				label: projectSalesType.name
			})),
			users: fetchedNewProjectData.users.map(user => ({
				id: user.username,
				label: `${user.firstName} ${user.lastName}`,
				company: user.companyCode
			})),
			timesheetApprovalTypes: fetchedNewProjectData.timesheetApprovalTypes.map(timesheetApprovalType => ({
				id: timesheetApprovalType.id,
				label: timesheetApprovalType.name
			}))
		};
	}, [fetchedNewProjectData]);

	if (error) return handleErrorPage(error, clearProjectAndClientsError);
	const isLoading = fetchedNewProjectData === null;
	if (isLoading) return <LoadingOverlay />;

	// SOURCE REPOSITORY ***************************************************************
	const addSourceRepos = () => {
		// add new item on the list with an default value
		const repos = getValues('repositories');

		if (repos && typeof repos === 'object' && Object.prototype.hasOwnProperty.call(repos, 'length')) {
			const sourceRepos = [...repos, { type: 'Git', name: 'Main' }];

			// update state on react hook form lib
			setValue('repositories', sourceRepos);
			// update state on react state
			setSourceRepositories(sourceRepos);
		}
	};

	const deleteSourceRepos = (field: string, index: number) => {
		const newPayload = getValues();
		newPayload[field].splice(index, 1);
		// update state on react hook form lib
		reset(newPayload);

		// update state on react state
		setSourceRepositories(newPayload[field]);
	};

	// EXTERNAL USER ***********************************************************************************
	const addExternalUser = () => {
		// add new item on the list with an default value
		const externalUsersPayload = [...getValues('externalUsers'), { name: '', company: '', email: '' }];

		// update state on react hook form lib
		setValue('externalUsers', externalUsersPayload);

		// update state on react state
		setExternalUsers(externalUsersPayload);
	};

	const deleteExternalUser = (field: string, index: number) => {
		const newPayload = getValues();
		newPayload[field].splice(index, 1);
		// update state on react hook form lib
		reset(newPayload);

		// update state on react state
		setExternalUsers(newPayload[field]);
	};

	// BUDGET *****************************************************************************************
	const addBudget = () => {
		// add new item on the list with an default value
		const budgetPayload = [...getValues('budget'), { profile: '', role: '', level: '', days: '', dailyRate: '' }];

		// update state on react hook form lib
		setValue('budget', budgetPayload);

		// update state on react state
		setBudget(budgetPayload);
	};

	const deleteBudget = (field: string, index: number) => {
		const newPayload = getValues();
		newPayload[field].splice(index, 1);
		// update state on react hook form lib
		reset(newPayload);

		// update state on react state
		setBudget(newPayload[field]);
	};

	// 	CONSTANTS
	const footerActions = [
		...(tabSelected !== 0
			? [
					{
						id: 'previous',
						label: 'Previous',
						// left: true,
						onClick: () => {
							setTabSelected(tabSelected - 1);
							scrollToTopRef.current.scrollIntoView({
								behavior: 'smooth',
								block: 'start'
							});
						},
						variant: 'outlined',
						disabled: false
					}
			  ]
			: []),
		...(tabSelected !== 5
			? [
					{
						id: 'next',
						label: 'Next',
						onClick: () => {
							setTabSelected(tabSelected + 1);
							/* Checking if the tabSelected is in the formWarnings array. If it is, it is removing it from
							the array. */
							if (formWarnings.includes(tabSelected)) {
								setFormWarnings(formWarnings.filter(e => e !== tabSelected));
							}
							scrollToTopRef.current.scrollIntoView({
								behavior: 'smooth',
								block: 'start'
							});
						},
						variant: 'contained',
						disabled: false
					}
			  ]
			: []),
		...(tabSelected === 5
			? [
					{
						id: 'submit',
						label: 'Submit',
						onClick: () => {
							setSubmitClick(true);
						},
						type: 'submit',
						form: 'form-new-project',
						variant: 'contained',
						disabled: getLSField('impersonate_userInfo')
					}
			  ]
			: [])
	];

	return (
		<>
			{loading && <LoadingOverlay />}
			<Grid item xs={12} style={{ paddingTop: '0px', display: 'flex' }}>
				{company.urlConfluenceProject && (
					<>
						<PriorityHigh color="primary" style={{ marginRight: '10px' }} />
						<Typography gutterBottom className={classes.infoLabel}>
							For more information about filling this form please access this&nbsp;
							<a
								style={{ fontSize: '16px' }}
								href={company.urlConfluenceProject}
								rel="noreferrer"
								target="_blank">
								link
							</a>{' '}
							.
						</Typography>
					</>
				)}
			</Grid>
			<form ref={scrollToTopRef} id="form-new-project" onSubmit={handleSubmit(onSubmit)}>
				<FormSteps steps={tabs} selected={tabSelected} errors={formWarnings} />
				<Scrollbars
					ref={scrollbarRef}
					style={{ height: 'calc(95vh - 270px)', overflowX: 'hidden' }}
					renderTrackHorizontal={(props: any) => (
						<div {...props} style={{ display: 'none' }} className="track-horizontal" />
					)}>
					{tabs.map(tab => {
						const commonProps = {
							// react-hook-form helpers
							setNewProjectHelper,
							control,
							errors,
							reset,
							resetField,
							getValues,
							setValue,
							watch
						};

						return (
							<TabPanel key={`proposal_tab_container_${tab.id}`} value={tabSelected} index={tab.id}>
								{tab.id === 0 && (
									<ProjectInformation
										urlStandardProjectName={company.urlNameStandardProject}
										projectRelatedOptions={projectRelatedOptions}
										companySelectOptions={companySelectOptions}
										setSelectedCompanyLabel={setSelectedCompanyLabel}
										{...commonProps}
									/>
								)}
								{tab.id === 1 && (
									<ProjectTeam
										projectRelatedOptions={projectRelatedOptions}
										{...commonProps}
										selectedCompanyLabel={selectedCompanyLabel}
									/>
								)}
								{tab.id === 2 && (
									<ProjectRepositories
										repositories={repositories}
										addSourceRepos={addSourceRepos}
										deleteSourceRepos={deleteSourceRepos}
										{...commonProps}
									/>
								)}
								{tab.id === 3 && (
									<ExternalUsers
										trigger={trigger}
										externalUsers={externalUsers}
										addExternalUser={addExternalUser}
										deleteExternalUser={deleteExternalUser}
										{...commonProps}
									/>
								)}
								{tab.id === 4 && (
									<Budgets
										trigger={trigger}
										budget={budget}
										addBudget={addBudget}
										deleteBudget={deleteBudget}
										selectedCompany={selectedCompany}
										newProjectHelper={newProjectHelper}
										{...commonProps}
									/>
								)}
								{tab.id === 5 && (
									<Comments
										additionalErrors={errors}
										handleWarning={(names: unknown, tabsWithWarn: number[]) =>
											setFormWarnings(tabsWithWarn)
										}
										{...commonProps}
										setSubmitClick={setSubmitClick}
										submitClick={submitClick}
									/>
								)}
							</TabPanel>
						);
					})}
				</Scrollbars>
			</form>
			<ActionFooter actions={footerActions} />
			<Prompt
				when={isTouchedFieldsUsed(touchedFields, getValues)}
				message="Are you sure you want to leave without saving?"
			/>
		</>
	);
};

export default NewProject;
