/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, Fragment, FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { Input, InfoField } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { IUpdatePrivateData, IUserProfile } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';

//* LOCAL COMPONENT IMPORTS
import { InfoCards } from '../../../utils';
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';

interface IOtherInfo {
	isAdmin?: string;
	userProfile: IUserProfile;
	handleApiSubmit: any;
}

const OtherInfo: FC<IOtherInfo> = ({ isAdmin, userProfile, handleApiSubmit }) => {
	const classes = useStyles();
	const [editMode, setEditMode] = useState(false);
	const company = useSelector(state => state.company)
	const {
		handleSubmit,
		control,
		reset,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const userERPData = userProfile?.user?.erpData;

	const getInitialData = () => ({
		clothingSize: userERPData?.privateData?.clothingSize || '',
		disabilities: userERPData?.privateData?.disabilities || '',
		foodAllergiesIntolerances: userERPData?.privateData?.foodAllergiesIntolerances || '',
		healthConditions: userERPData?.privateData?.healthConditions || '',
		// concents
		biometricSysConsent: userERPData?.privateData?.biometricSysConsent || true,
		intranetSysConsent: userERPData?.privateData?.intranetSysConsent || true,
		promoMediaConsent: userERPData?.privateData?.promoMediaConsent || true,
		demogInfoEngConsent: userERPData?.privateData?.demogInfoEngConsent || false,
		demogInfoTraConsent: userERPData?.privateData?.demogInfoTraConsent || false,
		familyInfoConsent: userERPData?.privateData?.familyInfoConsent || false,
		lifeHistoryConsent: userERPData?.privateData?.lifeHistoryConsent || false,
		clotheSizeConsent: userERPData?.privateData?.clotheSizeConsent || false,
		sensitiveInfoConsent: userERPData?.privateData?.sensitiveInfoConsent || false,
		contactInfoConsent: userERPData?.privateData?.contactInfoConsent || false
	});

	useEffect(() => {
		if (userProfile) {
			reset(getInitialData());
		}
	}, [userProfile]);

	const onSubmit = (data: IUpdatePrivateData) => {
		if (JSON.stringify(data) !== JSON.stringify(getInitialData())) {
			handleApiSubmit([
				{
					type: 'privateData',
					data
				}
			]);
		} else {
			// eslint-disable-next-line no-alert
			addNotification('info', 'Nothing to Update');
		}
		setEditMode(false);
		return null;
	};

	return (
		<InfoCards
			title="Other Information"
			form="form-profile-other-info"
			classes={classes}
			reset={reset}
			editMode={isAdmin ? null : editMode}
			setEditMode={isAdmin ? null : setEditMode}>
			<form id="form-profile-other-info" onSubmit={handleSubmit(() => onSubmit(watch()))}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
					{userERPData?.laptopsInformation.map(e => (
						<Fragment key={e.searchKey}>
							<Grid item {...sizes[6]}>
								<InfoField fontSize="lg" label="LAPTOP Description" value={e.name} />
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField fontSize="lg" label="LAPTOP S/N" value={e.searchKey} />
							</Grid>
						</Fragment>
					))}
					{!isAdmin && (
						<Grid item {...sizes[12]}>
							<InfoField
								fontSize="lg"
								label={
									<>
										If this is not your current Laptop Serial Number, please report the situation
										under{' '}
										<a
											style={{ fontSize: '12px' }}
											href={company.urlServiceDeskOIProfile}
											rel="noopener noreferrer"
											target="_blank">
											Service Desk
										</a>
									</>
								}
								value=""
							/>
						</Grid>
					)}
					{(!editMode || isAdmin) && (
						<>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="Clothing Size"
									value={userERPData?.privateData?.clothingSize}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="Disabilities"
									value={userERPData?.privateData?.disabilities}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="Food Allergies or Intolerances"
									value={userERPData?.privateData?.foodAllergiesIntolerances}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="Health Conditions"
									value={userERPData?.privateData?.healthConditions}
								/>
							</Grid>
						</>
					)}
					{editMode && !isAdmin && (
						<>
							<Grid item {...sizes[6]}>
								<Input
									name="clothingSize"
									label="Clothing Size"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Input
									name="disabilities"
									label="Disabilities"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Input
									name="foodAllergiesIntolerances"
									label="Food Allergies or Intolerances"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<Input
									name="healthConditions"
									label="Health Conditions"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
						</>
					)}
				</Grid>
			</form>
		</InfoCards>
	);
};

OtherInfo.defaultProps = {
	isAdmin: undefined
};

export default OtherInfo;
