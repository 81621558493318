import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	marginButtom: {
		marginTop: '145px'
	},
	xpLabel: {
		marginBottom: theme.spacing(1),
		color: theme.grayedColor,
		textAlign: 'justify',
		whiteSpace: 'pre-line',
		fontSize: '13px',
		lineHeight: 1,
		borderRadius: '10px',
		width: '100%'
	},
	xpLabel2: {
		marginBottom: theme.spacing(1),
		color: theme.grayedColor,
		textAlign: 'justify',
		whiteSpace: 'pre-line',
		fontSize: '13px',
		lineHeight: 1,
		borderRadius: '10px',
		width: '100%',
		backgroundColor: '#F7F8FA'
	},
	pauseButton: {
		color: '#FFFFFF',
		backgroundColor: '#60CF8B',
		textAlign: 'justify',
		whiteSpace: 'pre-line',
		fontSize: '13px',
		lineHeight: 1,
		borderRadius: '10px',
		'&:hover': {
			backgroundColor: '#44af6e'
		}
	},
	pauseIcon: {
		margin: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(2)
	},
	icons: {
		marginRight: theme.spacing(1)
	},
	fromToBar: {
		display: 'flex',
		alignItems: 'center'
	},
	timePickers: {
		width: '120px'
	},
	iconsFromTo: {
		width: '40px',
		marginLeft: '8px'
	},
	fromToLabels: {
		color: theme.grayedColor,
		marginRight: '5px'
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		marginButtom: {
			marginTop: '200px'
		}
	},
	marginForCalendar: {
		marginTop: '200px'
	},
	dialogHeight: {
		maxHeight: '600px'
	},
	marginCopy: {
		marginTop: '10px'
	}
}));

export { useStyles };
