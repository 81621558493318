import PublicPages from 'lib/routes/publicPages.json';

export const validDynamicPaths = (original: string, current: string): boolean => {
	// eslint-disable-next-line no-useless-escape
	const regex = /\:(.*?)\//g;
	const payload1 = original
		.replaceAll(regex, '/')
		.split('/')
		.map(e => (e.includes(':') ? '' : e));
	const payload2 = current.replaceAll(regex, '/').split('/');

	let valid = true;
	payload1.forEach((e, i) => {
		if (e !== '' && !!valid) {
			valid = payload2[i] === e;
		}
	});

	return valid;
};

export const isPublicPage = (currentPage: string): boolean => {
	let pageFound = PublicPages.includes(currentPage);
	if (!pageFound) {
		pageFound = PublicPages.filter(pubPage => pubPage.includes(':')).some(pubPage =>
			validDynamicPaths(pubPage, currentPage)
		);
	}
	return pageFound;
};
