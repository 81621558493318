/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/extensions */
import React from 'react';

import { DateHelper, PresetManager } from '@bryntum/scheduler/scheduler.umd.js';
import { START_API_PATH } from 'lib/utils/constants';
import { getLSField } from 'lib/utils/cookies';

// const newPresets: any = PresetManager.records.slice(6, 11);
const newPresets: any = [PresetManager.records[10], PresetManager.records[9], PresetManager.records[6]];

// day
newPresets[0].data.headers = [
	{
		dateFormat: 'YYYY MMMM DD',
		increment: 1,
		originalDateFormat: 'YYYY MMMM DD',
		unit: 'month',
		renderer: (
			start: Date // start, end, headerConfig, index
		) => `<span class="monthYear">${DateHelper.format(start, 'MMMM YYYY')}</span>`
	},
	{
		dateFormat: '',
		increment: 1,
		originalDateFormat: 'DD MMM',
		unit: 'day',
		renderer: (
			start: Date // start, end, headerConfig, index
		) =>
			`<span style="display: flex; flex-direction: column;">
				<span class="header-week-cell">${DateHelper.format(start, 'ddd')}</span>
				<span class="header-day-cell">${DateHelper.format(start, 'DD')}</span>
			</span>`
	}
];

// Week
newPresets[1].data.headers = [
	{
		dateFormat: 'YYYY MMMM DD',
		increment: 1,
		originalDateFormat: 'YYYY MMMM DD',
		unit: 'month',
		renderer: (
			start: Date // start, end, headerConfig, index
		) => `<span class="monthYear">${DateHelper.format(start, 'MMMM YYYY')}</span>`
	},
	{
		dateFormat: '',
		increment: 1,
		originalDateFormat: 'DD MMM',
		unit: 'day',
		renderer: (
			start: Date // start, end, headerConfig, index
		) =>
			`<span style="display: flex; flex-direction: column; padding-left: 15px; padding-right: 15px">
				<span class="header-week-cell">${DateHelper.format(start, 'dd').charAt(0)}</span>
				<span class="header-day-cell">${DateHelper.format(start, 'DD')}</span>
			</span>`
	}
];

// Month View
newPresets[2].data.headers = [
	{
		dateFormat: 'YYYY MMMM DD',
		increment: 1,
		originalDateFormat: 'YYYY MMMM DD',
		unit: 'year',
		renderer: (
			start: Date // start, end, headerConfig, index
		) => `<span class="monthYear">${DateHelper.format(start, 'YYYY')}</span>`
	},
	{
		dateFormat: 'YYYY MMMM DD',
		increment: 1,
		originalDateFormat: 'YYYY MMMM DD',
		unit: 'month',
		renderer: (
			start: Date // start, end, headerConfig, index
		) =>
			`<span style="display: flex; flex-direction: column;">
				<span class="header-week-cell">${DateHelper.format(start, 'MMM')}</span>
			</span>`
	}
];

export { newPresets };

export const schedulerConfig = (classes: any) => ({
	eventStyle: 'border',
	features: {
		group: 'group',
		resourceTimeRanges: true,
		nonWorkingTime: true,
		scheduleMenu: {
			disabled: true
		},
		eventDrag: {
			disabled: true
		},
		eventCopyPaste: false,
		rowCopyPaste: false,
		eventEdit: {
			editorConfig: {
				title: 'Edit Allocation'
			},
			items: {
				startTimeField: false,
				endTimeField: false,
				nameField: false,
				startDateField: {
					editable: false
				},
				endDateField: {
					editable: false
				},
				allocation: {
					type: 'numberfield',
					label: 'Allocation %',
					max: 100,
					min: 0,
					name: 'allocationPercentage',
					listeners: {
						input: ({ source }: any) => {
							const widget = source;

							const errors = widget.getErrors();
							if (errors) {
								errors.map((error: string) => widget.clearError(error));
							}
						}
					}
				},
				projects: {
					type: 'combo',
					label: 'Project',
					name: 'projectID',
					valueField: 'value',
					displayField: 'text',
					listeners: {
						action: ({ source }: any) => {
							const widget = source;
							const editor = widget.parent;

							const errors = widget.getErrors();
							if (errors) {
								errors.map((error: string) => widget.clearError(error));
							}

							// remove projects combo errors
							const projectLabelErrors = editor.widgetMap.projectLabel.getErrors();
							if (projectLabelErrors) {
								projectLabelErrors.map((error: string) =>
									editor.widgetMap.projectLabel.clearError(error)
								);
							}
						}
					}
				},
				projectLabel: {
					type: 'textField',
					label: 'Project Label',
					name: 'projectLabel',
					listeners: {
						input: ({ source }: any) => {
							const widget = source;
							const editor = widget.parent;

							// remove own errors
							const errors = widget.getErrors();
							if (errors) {
								errors.map((error: string) => widget.clearError(error));
							}
							// remove projects combo errors
							const projectsComboErrors = editor.widgetMap.projects.getErrors();
							if (projectsComboErrors) {
								projectsComboErrors.map((error: string) => editor.widgetMap.projects.clearError(error));
							}
						}
					}
				},
				color: {
					type: 'combo',
					label: 'Color',
					name: 'eventColor',
					valueField: 'value',
					listeners: {
						action: ({ source }: any) => {
							const widget = source;
							const editor = widget.parent;

							const errors = widget.getErrors();
							if (errors) {
								errors.map((error: string) => widget.clearError(error));
							}

							// remove colors combo errors
							const colorErrors = editor.widgetMap.color.getErrors();
							if (colorErrors) {
								colorErrors.map((error: string) => editor.widgetMap.color.clearError(error));
							}
						}
					}
				},
				bbar: {
					items: {
						deleteButton: {
							hidden: true
						}
					}
				},
				resourceField: {
					weight: 0
				}
			},
			disabled: getLSField('impersonate_userInfo')
		},
		eventMenu: {
			items: {
				deleteEvent: {
					text: 'Delete Allocation'
				},
				editEvent: {
					text: 'Edit Allocation'
				}
				// copyEvent: {
				// 	text: 'Copy Allocation'
				// },
				// cutEvent: {
				// 	text: 'Cut Allocation'
				// }
			},
			disabled: getLSField('impersonate_userInfo')
		},
		timeRanges: {
			showCurrentTimeLine: true,
			disabled: getLSField('impersonate_userInfo')
		}
	},
	presets: newPresets, // Only 2 zoom levels are available
	viewPreset: newPresets[0].id,
	timeRangesFeature: {
		narrowThreshold: 10
	},
	zoomOnTimeAxisDoubleClick: false,
	autoAdjustTimeAxis: false,
	autoAdjust: false,
	timeAxisHeaderMenu: {
		items: {
			zoomLevel: false
		}
	},
	columns: [
		{
			type: 'resourceInfo',
			text: 'Users',
			showImage: false,
			enableCellContextMenu: false,
			width: 220,
			renderer: (props: any) => {
				const {
					value,
					record: { originalData }
				} = props;

				if (originalData.name) {
					return (
						<div className={classes.recordItem}>
							<div className={classes.avatarContainer}>
								<img
									src={`${START_API_PATH}/admin/users/${originalData.id}/photo?small=true`}
									alt=""
									className={classes.avatarImage}
								/>
							</div>
							<div>
								<span className={classes.recordItemName}>{originalData.name}</span>
								<span className={classes.recordItemUsername}>{originalData.id}</span>
							</div>
						</div>
					);
				}

				return value;
			}
		}
	],
	eventRenderer: ({ eventRecord, renderData }: any) => {
		const name = eventRecord.data?.projectLabel || eventRecord.data?.alternativeProjLabel || 'N/A';

		// Add an extra element to the children of the container, to display a progress bar.
		// In a real app, you should avoid having inline styles and instead style using the CSS class
		renderData.children.push({
			html: `${name}  -  ( ${eventRecord.data?.allocationPercentage || 'N/A'}% )`,

			style: {
				color: 'white',
				'padding-left': '1em',
				'align-items': 'left'
			}
		});
	}
});
