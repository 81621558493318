/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, FC, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TimeLogger, Dialog, TimeEntryMover } from 'xpand-ui/core';
import { Select as LibSelect, TextAreaResizable } from 'xpand-ui/forms';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useStyles } from './styles';
import { defaultValues, schema } from './yupSchema';
import ArrowRight from '../../../../../assets/icons/icon-arrow-right.svg';
// import Clock from '../../../../../assets/icons/icon-clock.svg';
import Copy from '../../../../../assets/icons/icon-copy.svg';
// import DoubleArrow from '../../../../../assets/icons/icon-double-arrow.svg';
// import Pause from '../../../../../assets/icons/pause.svg';
import { addNotification } from 'lib/utils/notifications';
import { TimePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import FlagIcon from 'assets/images/myTimesheets/flag.svg';
import StopwatchIcon from 'assets/images/myTimesheets/stopwatch.svg';
import { Check } from '@mui/icons-material';
import { XTRACKER_ABSENCE_PROJECTS } from '../utils';
//* COMPONENT INTERFACES

const NewEntryModal: FC<any> = ({
	isOpen,
	setOpen,
	deleteEvent,
	popupEventID,
	saveEvent,
	weekViewData,
	popupEventDate,
	mergedArray,
	isEdit,
	setEdit,
	setDateToMove,
	dateToMove,
	isToMove,
	setIsToMove,
	selectedDatesOnCopy,
	setSelectedDatesOnCopy,
	eventInformation,
	selectedDate
}) => {
	const classes = useStyles();
	const styles = { ...classes };
	const [projectTaskOptions, setProjectTaskOptions] = useState([]);
	const [disableTimeLogger, setDisableTimeLogger] = useState(true);
	const [initialHour, setInitialHour] = useState(0);
	const [initialMinutes, setInitialMinutes] = useState(0);
	const [initialSeconds, setInitialSeconds] = useState(0);
	const [timeChangedByTimeLogger, setTimeChangedByTimeLogger] = useState(false);
	const [selectedProjectName, setSelectedProjectName] = useState('');
	const [selectedProjectTaskName, setSelectedProjectTaskName] = useState('');
	const [isCopySelected, setCopySelected] = useState(false);
	const [isFromToSelected, setFromToSelected] = useState(false);
	const [fromTime, setFromTime] = useState(null);
	const [toTime, setToTime] = useState(null);
	const [isTimerRunning, setTimerRunning] = useState(false);
	const [showWorkTypeAndCostCenter, setShowWorkTypeAndCostCenter] = useState(true);
	const childRef = useRef(null);
	const [eventInfoUpdated, setEventInfoUpdated] = useState(false);
	const [eventFromDate, setEventFromDate] = useState(new Date());

	// form
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		reset,
		getValues,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues
	});

	const confirmModalActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				if (!isEdit) {
					deleteEvent(popupEventID);
				}
				setEdit(false);
				setOpen(false);
				setTimerRunning(false);
				reset(defaultValues);
			}
		},
		{
			id: 'submit',
			label: 'Save',
			color: 'primary',
			variant: 'contained',
			onClick: () => {},
			type: 'submit',
			form: 'form-save-new-entry'
		}
	];
	const resetTimeChanges = () => {
		setInitialHour(0);
		setInitialMinutes(0);
		setInitialSeconds(0);
		setTimeChangedByTimeLogger(false);
		setCopySelected(false);
		setSelectedDatesOnCopy([]);
	};

	const changeTimeLogger = async (time: Date) => {
		setValue('hours', time.getHours());
		setValue('minutes', time.getMinutes());
		setInitialHour(time.getHours());
		setInitialMinutes(time.getMinutes());
		setTimeChangedByTimeLogger(true);
	};

	const saveClickHandler = async (payloadForm: any) => {
		if ((isToMove && dateToMove != null) || (!isToMove && dateToMove == null)) {
			const assignedAccountProject = weekViewData?.results?.assignedAccountProjects.find((e: any) => {
				return e.accountProjectId == payloadForm.projectName;
			});
			const assignedAccountProjectIndex = weekViewData?.results?.assignedAccountProjects.findIndex((e: any) => {
				return e.accountProjectId == payloadForm.projectName;
			});

			const assignedProjectTask = weekViewData?.results?.assignedProjectTasks[assignedAccountProjectIndex].find(
				(e: any) => {
					return e.id == payloadForm.projectTask;
				}
			);
			const assignedTimeOffType = weekViewData?.results?.accountTimeOffType.find((e: any) => {
				return e.accountTimeOffTypeId == payloadForm.projectTask;
			});

			payloadForm.assignedAccountProjectDetails = assignedAccountProject;
			payloadForm.assignedProjectTaskDetails = assignedProjectTask;
			payloadForm.assignedTimeOffType = assignedTimeOffType;
			payloadForm.timeChangedByTimeLogger = timeChangedByTimeLogger;
			payloadForm.hours = initialHour;
			payloadForm.minutes = initialMinutes;
			payloadForm.selectedDatesOnCopy = selectedDatesOnCopy;
			payloadForm.isCopySelected = isCopySelected;
			payloadForm.id = popupEventID;
			if (initialHour == 0 && initialMinutes == 0) {
				addNotification('danger', 'Please insert a valid Logged Time!');
				return;
			}
			reset(defaultValues);
			setEdit(false);
			setOpen(false);
			setTimerRunning(false);
			saveEvent(payloadForm);
			setCopySelected(false);
		} else if (isToMove && dateToMove == null) {
			addNotification('danger', 'Please select a date to move the time entry!');
		}
	};

	useEffect(() => {
		const countryId = weekViewData?.results?.viewAccountEmployees?.countryId;

		//Set default Cost Center
		if (countryId == 82) {
			//Germany
			setValue('costCenter', '13'); // DE -> Remote
		} else if (countryId == 53) {
			//Croatia
			setValue('costCenter', '12'); //HR -> Remote
		} else if (countryId == 232) {
			//Croatia
			setValue('costCenter', '9'); //UK -> Remote
		} else {
			//PT -> Remote
			setValue('costCenter', '5');
		}

		//Set default Work Type
		setValue('workType', '1'); // Normal
	}, [weekViewData?.results, isOpen]);

	// Set Initial Values Edit and Create
	useEffect(() => {
		resetTimeChanges();
		let startDateEvent = null;
		let endDateEvent = null;
		if (isEdit) {
			// Edit Event
			const event = mergedArray.find(e => e.id == popupEventID);
			setValue('projectName', event?.accountProjectId);
			setValue('projectTask', event?.accountProjectTaskId);
			setValue('description', event?.description);
			setValue('workType', event?.accountWorkTypeId);
			setValue('costCenter', event?.accountCostCenterId);
			startDateEvent = new Date(event?.start);
			endDateEvent = new Date(event?.end);
			setEventInfoUpdated(true);
		} else {
			// Create Event
			startDateEvent = new Date(popupEventDate[0]);
			endDateEvent = new Date(popupEventDate[1]);
		}
		//Copy/ Move from date
		const fromDate = new Date(startDateEvent);
		fromDate.setHours(0, 0, 0, 0);
		setEventFromDate(fromDate);
		//Set Initial Time Logged
		// Calculate the time difference in milliseconds
		const timeDifferenceMs: number = endDateEvent.getTime() - startDateEvent.getTime();

		// Calculate the hours and minutes
		const hours: number = Math.floor(timeDifferenceMs / 3600000); // 1 hour = 3600000 milliseconds
		const minutes: number = Math.floor((timeDifferenceMs % 3600000) / 60000); // 1 minute = 60000 milliseconds
		setInitialHour(hours);
		setInitialMinutes(minutes);
		setValue('hours', hours);
		setValue('minutes', minutes);
	}, [isEdit, popupEventDate]);

	// Set Task Options
	useEffect(() => {
		let projectTaskOptionsAux: any = [];
		const index = weekViewData?.results?.assignedAccountProjects.findIndex(e => {
			return e.accountProjectId == watch('projectName');
		});
		//Happens when it is ABSENCE PROJECT
		//? All code with this id must change, to accept other absence project from another countries
		if (XTRACKER_ABSENCE_PROJECTS.includes(watch('projectName'))) {
			if (weekViewData?.results?.assignedAccountTimeOffType) {
				projectTaskOptionsAux = weekViewData.results?.assignedAccountTimeOffType.map((e: any) => {
					return { id: e.accountTimeOffTypeId, label: e.accountTimeOffType, tooltip: e.briefExplanation };
				});
			}
			setProjectTaskOptions(projectTaskOptionsAux);
		} else {
			if (weekViewData?.results?.assignedProjectTasks[index]) {
				projectTaskOptionsAux = weekViewData.results?.assignedProjectTasks[index].map((e: any) => {
					return { id: e.id, label: e.taskName };
				});
			}
			setProjectTaskOptions(projectTaskOptionsAux);
		}

		const selectedProject = weekViewData?.results?.assignedAccountProjects.find(e => {
			return e.accountProjectId == watch('projectName');
		});

		setSelectedProjectName(selectedProject?.projectName);

		// If HR ABSENCE is Selected costCenter and workType must be hiden
		if (XTRACKER_ABSENCE_PROJECTS.includes(watch('projectName'))) {
			setShowWorkTypeAndCostCenter(false);
			setValue('costCenter', '0');
			setValue('workType', '0');
		} else {
			if (!eventInfoUpdated) {
				const countryId = weekViewData?.results?.viewAccountEmployees?.countryId;

				//Set default Cost Center
				if (countryId == 82) {
					//Germany
					setValue('costCenter', '13'); // DE -> Remote
				} else if (countryId == 53) {
					//Croatia
					setValue('costCenter', '12'); //HR -> Remote
				} else if (countryId == 232) {
					//Croatia
					setValue('costCenter', '9'); //UK -> Remote
				} else {
					//PT -> Remote
					setValue('costCenter', '5');
				}
				setValue('workType', '1');
				setShowWorkTypeAndCostCenter(true);
			}
			setEventInfoUpdated(false);
		}
	}, [watch('projectName')]);

	// TimeLoggerDisable
	useEffect(() => {
		if (watch('costCenter') && watch('workType') && watch('projectTask')) {
			setDisableTimeLogger(false);
		} else {
			setDisableTimeLogger(true);
		}
		const selectedProject = projectTaskOptions.find(e => {
			return e.id == watch('projectTask');
		});
		if (!isEdit) {
			setDisableTimeLogger(false);
		}
		setSelectedProjectTaskName(selectedProject ? selectedProject.label : '');
	}, [watch('costCenter'), watch('workType'), watch('projectTask'), projectTaskOptions, isEdit]);
	//   TODO - Try to add scroll when open Move or Copy feature
	return (
		<>
			{/* NEW ENTRY MODAL */}
			<Dialog
				title={!isEdit ? 'NEW ENTRY' : 'EDIT ENTRY'}
				scroll="body"
				modal={{
					open: isOpen,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpen(false);
						if (reason === 'escapeKeyDown') {
							if (!isEdit) deleteEvent(popupEventID);
						}
					},
					content: (
						<form id="form-save-new-entry" onSubmit={e => handleSubmit(() => saveClickHandler(watch()))(e)}>
							<Grid
								container
								style={
									isCopySelected || isToMove
										? { padding: '10px 5px 50px 5px' }
										: { padding: '10px 5px 0px 5px' }
								}
								spacing={2}>
								<Grid item xs={12}>
									<LibSelect
										name="projectName"
										label="Project Name"
										options={weekViewData?.results?.assignedAccountProjects
											?.filter(
												(e: any) => !XTRACKER_ABSENCE_PROJECTS.includes(e.accountProjectId)
											)
											?.map((e: any) => ({ id: e.accountProjectId, label: e.projectName }))}
										recOptions={weekViewData?.results?.favoriteAssignedAccountProjects
											?.filter(
												(e: any) => !XTRACKER_ABSENCE_PROJECTS.includes(e.accountProjectId)
											)
											?.map((e: any) => ({ id: e.accountProjectId, label: e.projectName }))}
										cstmOptionsHeader="Timeoff"
										cstmOptions={weekViewData?.results?.assignedAccountProjects
											?.filter((e: any) => XTRACKER_ABSENCE_PROJECTS.includes(e.accountProjectId))
											?.map((e: any) => ({ id: e.accountProjectId, label: e.projectName }))}
										additionalOnChange={() => {
											setValue('projectTask', '');
											setDisableTimeLogger(true);
										}}
										allOptionsName="Other Projects"
										control={control}
										errors={errors}
										hasSearchOption={true}
									/>
								</Grid>
								<Grid item xs={12}>
									<LibSelect
										name="projectTask"
										label="Project Task"
										additionalOnChange={e => {
											if (e.label === 'HOLIDAY LEAVE') {
												setValue('hours', 8);
												setValue('minutes', 0);
												setInitialHour(8);
												setInitialMinutes(0);
											}
										}}
										options={projectTaskOptions}
										control={control}
										errors={errors}
									/>
								</Grid>
								{showWorkTypeAndCostCenter && (
									<Grid item xs={6}>
										<LibSelect
											name="costCenter"
											label="Cost Center"
											options={weekViewData?.results?.costCenters?.map((e: any) => ({
												id: e.accountCostCenterId,
												label: e.accountCostCenterName
											}))}
											control={control}
											errors={errors}
										/>
									</Grid>
								)}
								{showWorkTypeAndCostCenter && (
									<Grid item xs={6}>
										<LibSelect
											name="workType"
											label="Work Type"
											value={'Normal'}
											options={weekViewData?.results?.workTypes.map((e: any) => ({
												id: e.accountWorkTypeId,
												label: e.accountWorkTypeName
											}))}
											control={control}
											errors={errors}
										/>
									</Grid>
								)}
								<Grid item xs={12}>
									<TextAreaResizable
										name="description"
										label="Description"
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item xs={12}>
									<TimeLogger
										ref={childRef}
										darkMode={!disableTimeLogger}
										disabled={disableTimeLogger}
										initialHour={initialHour}
										initialMinutes={initialMinutes}
										initialSeconds={initialSeconds}
										title={isTimerRunning ? 'Running Timer' : 'Logged Time'}
										incrementButtons={[
											{
												id: 0,
												hours: 0,
												minutes: 15,
												seconds: 0,
												disable: false
											},
											{
												id: 1,
												hours: 0,
												minutes: 30,
												seconds: 0,
												disable: false
											},
											{
												id: 2,
												hours: 1,
												minutes: 0,
												seconds: 0,
												disable: false
											}
										]}
										decrementButtons={[
											{
												id: 0,
												hours: 0,
												minutes: 15,
												seconds: 0,
												disable: false
											},
											{
												id: 1,
												hours: 0,
												minutes: 30,
												seconds: 0,
												disable: false
											},
											{
												id: 2,
												hours: 1,
												minutes: 0,
												seconds: 0,
												disable: false
											}
										]}
										extraButtons={[
											{
												id: 0,
												hours: 4,
												minutes: 0,
												seconds: 0,
												disable: false
											},
											{
												id: 0,
												hours: 6,
												minutes: 0,
												seconds: 0,
												disable: false
											},
											{
												id: 0,
												hours: 8,
												minutes: 0,
												seconds: 0,
												disable: false
											}
										]}
										additionalOnChange={changeTimeLogger}
										hideSeconds={!isTimerRunning}
										isTimerRunning={isTimerRunning}
									/>
								</Grid>
								{/* If From-to and Timer are enable */}
								{/* <Grid item xs={3} className={styles.marginButtom}>
									<Button
										color="inherit"
										className={styles.xpLabel}
										variant="text"
										onClick={() => {
											setIsToMove(false);
											setCopySelected(false);
											setFromToSelected(!isFromToSelected);
										}}>
										<DoubleArrow className={styles.icons} />
										From & To
									</Button>
								</Grid>
								<Grid item xs={2.5} className={styles.marginButtom}>
									{isTimerRunning ? (
										<Button
											color="inherit"
											className={styles.pauseButton}
											variant="text"
											onClick={() => {
												setIsToMove(false);
												setCopySelected(false);
												setTimerRunning(false);
												setDisableTimeLogger(false);
											}}>
											<Pause className={styles.pauseIcon} /> Pause
										</Button>
									) : (
										<Button
											color="inherit"
											className={styles.xpLabel}
											variant="text"
											disabled={disableTimeLogger}
											onClick={() => {
												setIsToMove(false);
												setCopySelected(false);
												setTimerRunning(true);
												setDisableTimeLogger(true);
												setEdit(false);
											}}>
											<Clock className={styles.icons} /> Timer
										</Button>
									)}
								</Grid> */}
								<Grid item xs={isEdit ? 6 : 12} className={styles.marginButtom}>
									<Button
										color="inherit"
										className={isCopySelected ? styles.xpLabel2 : styles.xpLabel}
										variant="text"
										onClick={() => {
											setIsToMove(false);
											setCopySelected(true);
											setFromToSelected(false);
										}}
										disabled={
											isEdit &&
											(eventInformation?.approved ||
												eventInformation?.submitted ||
												XTRACKER_ABSENCE_PROJECTS.includes(watch('projectName')))
										}>
										<Copy className={styles.icons} />
										Apply Recurrence
									</Button>
								</Grid>
								{isEdit && (
									<Grid item xs={6} className={styles.marginButtom}>
										<Button
											color="inherit"
											className={isToMove ? styles.xpLabel2 : styles.xpLabel}
											variant="text"
											onClick={() => {
												setCopySelected(false);
												setIsToMove(true);
												setFromToSelected(false);
											}}
											disabled={
												isEdit &&
												(eventInformation?.approved ||
													eventInformation?.submitted ||
													XTRACKER_ABSENCE_PROJECTS.includes(watch('projectName')))
											}>
											<ArrowRight className={styles.icons} /> Move
										</Button>
									</Grid>
								)}
								{/* If From-to and Timer are enable */}
								{/* <Grid item xs={1.5} className={styles.marginButtom}></Grid> */}
								{isCopySelected && (
									<>
										<Grid item xs={12} sx={{ paddingTop: '0px' }}>
											<TimeEntryMover
												titleLabel={'Project Name'}
												titleValue={
													weekViewData?.results?.assignedAccountProjects.find((e: any) => {
														return e.accountProjectId == watch('projectName');
													})?.projectName
												}
												secondTitleLabel={'Project Task'}
												secondTitleValue={selectedProjectTaskName}
												hours={
													watch('hours').toString().padStart(2, '0') +
													'h ' +
													watch('minutes').toString().padStart(2, '0') +
													'm'
												}
												onClose={() => {
													setCopySelected(false);
													setSelectedDatesOnCopy([]);
												}}
												onCopy={() => {}}
												isOnNewEntryModal={true}
												isMultipleSelect={true}
												setSelectedDates={setSelectedDatesOnCopy}
												selectedDates={selectedDatesOnCopy}
												mainTitle={'Copy Time'}
												mainButton={'Copy'}
												fromDate={eventFromDate}
												control={control}
												errors={errors}
												selectedDate={selectedDate}
											/>
										</Grid>

										<Grid item xs={12} className={styles.marginForCalendar}></Grid>
									</>
								)}
								{isToMove && (
									<>
										<Grid item xs={12} sx={{ paddingTop: '0px' }}>
											<TimeEntryMover
												titleLabel={'Project Name'}
												titleValue={selectedProjectName}
												secondTitleLabel={'Project Task'}
												secondTitleValue={selectedProjectTaskName}
												hours={
													initialHour.toString().padStart(2, '0') +
													'h' +
													':' +
													initialMinutes.toString().padStart(2, '0') +
													'm'
												}
												showButtons={false}
												setDateToMove={setDateToMove}
												toDate={dateToMove}
												isOnNewEntryModal={true}
												mainTitle="Move Time"
												control={control}
												isMultipleSelect={false}
												errors={errors}
												onClose={e => {
													setDateToMove(null);
													setIsToMove(false);
												}}
												fromDate={eventFromDate}
												mainButton="Move"
												selectedDate={selectedDate}></TimeEntryMover>
										</Grid>
										<Grid item xs={12} className={styles.marginForCalendar}></Grid>
									</>
								)}
								{isFromToSelected && (
									<>
										<Grid item className={styles.fromToBar}>
											<Grid item className={styles.fromToBar}>
												<StopwatchIcon className={styles.iconsFromTo} />
												<Typography className={classes.fromToLabels} gutterBottom>
													From
												</Typography>
												<TimePicker
													ampm={false}
													value={fromTime}
													onChange={e => setFromTime(e)}
													viewRenderers={{
														hours: renderTimeViewClock,
														minutes: renderTimeViewClock
													}}
													renderInput={params => (
														<TextField {...params} className={classes.timePickers} />
													)}
												/>{' '}
											</Grid>
											<Grid item className={styles.fromToBar}>
												<FlagIcon className={styles.iconsFromTo} />
												<Typography className={classes.fromToLabels} gutterBottom>
													To
												</Typography>
												<TimePicker
													ampm={false}
													value={toTime}
													onChange={e => setToTime(e)}
													viewRenderers={{
														hours: renderTimeViewClock,
														minutes: renderTimeViewClock
													}}
													renderInput={params => (
														<TextField {...params} className={classes.timePickers} />
													)}
												/>{' '}
											</Grid>
											<Button
												color="inherit"
												className={styles.xpLabel}
												variant="text"
												disabled={!fromTime || !toTime || toTime.isBefore(fromTime)}
												onClick={() => {
													setToTime(null);
													setFromTime(null);
													setValue('hours', toTime.hour() - fromTime.hour());
													setValue('minutes', toTime.minute() - fromTime.minute());
													setInitialHour(toTime.hour() - fromTime.hour());
													setInitialMinutes(toTime.minute() - fromTime.minute());
													setFromToSelected(false);
												}}>
												<Check />
												Apply
											</Button>
										</Grid>
									</>
								)}
							</Grid>
						</form>
					)
				}}
				actions={confirmModalActions}
			/>
		</>
	);
};
export default NewEntryModal;
