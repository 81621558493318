/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable indent, @typescript-eslint/no-explicit-any */
import { Dispatch } from 'redux';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import { IGetEmployeeList } from 'typings/store/admin/collaborationTypesManagement';

import {
	fetchEmployeesList,
	fetchEditEmployee,
	fetchCollaborationTypeList,
	postNewCollaborationType,
	fetchEditCollaborationType,
	postEditCollaborationType,
	collaborationTypeCancel,
	collaborationTypeApprove,
	collaborationTypeReject,
	collaborationTypeRequestApproval,
	collaborationTypeClose
} from 'lib/api/administration/collaborationTypesManagement';

import {
	setLoading,
	setCollaborationTypeError,
	setEmployeesList,
	setEditEmployee,
	setCollaborationTypeList,
	setPostNewCollaborationType,
	setEditCollaborationType,
	setCollaborationTypeCancel,
	setCollaborationTypeApprove,
	setCollaborationTypeReject,
	setCollaborationTypeRequestApproval,
	setCollaborationTypeClose,
	setInvalidDocuments,
	resetFormData
} from './actions';
import { postDocumentsValidation } from 'lib/api/general/apps';
import { IIdDocs } from 'typings/store/admin/admissions';

//! GET INFO TABLES
// GET CSC FOR ALL USERS
export const getEmployeesList =
	(payload?: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IGetEmployeeList } = await fetchEmployeesList();
			if (response.data) {
				dispatch(setEmployeesList(response?.data || null));
			} else {
				dispatch(setCollaborationTypeError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setCollaborationTypeError(error as OriginalResponse));
		}
	};

export const getEditEmployee =
	(userID: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const data = await fetchEditEmployee(userID);
			if (data) {
				dispatch(setEditEmployee(data.data));
			} else {
				dispatch(setCollaborationTypeError(data as OriginalResponse));
			}
		} catch (error) {
			dispatch(setEditEmployee(null));
		}
	};

// GET CSC FOR SPECIFIC USER AND ITS HISTORY
export const getCollaborationTypeList =
	(hash: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			dispatch(resetFormData());
			const response: { data?: any } | OriginalResponse = await fetchCollaborationTypeList(hash);

			if (response.data) {
				dispatch(setCollaborationTypeList(response.data));
			} else {
				dispatch(setCollaborationTypeError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setCollaborationTypeList(null));
		}
	};

// EDIT CSC FORM - GET
export const getEditCollaborationType =
	(payload: string, token: string, proposalId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: any } = await fetchEditCollaborationType(payload, token, proposalId);
			if (response.data) {
				dispatch(setEditEmployee(response.data || null));
			} else {
				dispatch(setCollaborationTypeError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setCollaborationTypeError(error as OriginalResponse));
		}
	};

export const submitNewCollaborationType =
	(payload: any, token: string, docs?: IIdDocs[]) =>
	async (dispatch: Dispatch): Promise<Boolean> => {
		try {
			dispatch(setLoading());
			if (!!docs && docs.length > 0) {
				const resultCheckDoc = await postDocumentsValidation(docs).then(async response => {
					if (response.payload && response.payload.data) {
						dispatch(setInvalidDocuments(response.payload.data.invalidFields));
						return false;
					}
					return true;
				});

				if (resultCheckDoc) {
					const response: Success = await postNewCollaborationType(payload, token);
					if (response.code && response.code !== 200) {
						dispatch(setPostNewCollaborationType(null));
						return true;
					}
					dispatch(setPostNewCollaborationType({ token, response }));
					return true;
				}

				return await new Promise(resolve => {
					resolve(false);
				});
			}
			const response: Success = await postNewCollaborationType(payload, token);
			dispatch(setPostNewCollaborationType(response?.data));
			return await new Promise(resolve => {
				resolve(true);
			});
		} catch (error) {
			dispatch(setPostNewCollaborationType(null));

			return await new Promise(resolve => {
				resolve(false);
			});
		}
	};

export const submitEditCollaborationType =
	(payload: any, token: string, id: string | number, docs: IIdDocs[]) =>
	async (dispatch: Dispatch): Promise<Boolean> => {
		try {
			dispatch(setLoading());
			if (docs && !!docs && docs.length > 0) {
				const resultCheckDoc = await postDocumentsValidation(docs).then(async response => {
					if (response.payload && response.payload.data) {
						dispatch(setInvalidDocuments(response.payload.data.invalidFields));
						return false;
					}

					return true;
				});

				if (resultCheckDoc) {
					const response: Success = await postEditCollaborationType(payload, token, id);
					if (response.code && response.code !== 200) {
						dispatch(setPostNewCollaborationType(null));
						return true;
					}
					dispatch(setPostNewCollaborationType({ token, response }));
					return true;
				}
			}
			const response: Success = await postEditCollaborationType(payload, token, id);
			if (response.code && response.code !== 200) {
				dispatch(setPostNewCollaborationType(null));
				return true;
			}
			dispatch(setPostNewCollaborationType({ token, response }));
			return true;
		} catch (error) {
			dispatch(setPostNewCollaborationType(null));
			return false;
		}
	};

// CANCEL COLLABORATION TYPE
export const submitCollaborationTypeCancel =
	(
		hash: string,
		token: string,
		proposalId: number,
		setIsFinishingAction: React.Dispatch<React.SetStateAction<boolean>>
	) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await collaborationTypeCancel(token, proposalId);
			dispatch(setCollaborationTypeCancel(response.data || null, hash));
		} catch (error) {
			dispatch(setCollaborationTypeCancel(null, null));
		}
		setIsFinishingAction(false);
	};

// APPROVE CSC
export const submitCollaborationTypeApprove =
	(token: string, proposalId: number, manager: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await collaborationTypeApprove(token, proposalId, manager);
			dispatch(setCollaborationTypeApprove(response, token));
		} catch (error) {
			dispatch(setCollaborationTypeApprove(null, null));
		}
	};

// REJECT CSC
export const submitCollaborationTypeReject =
	(token: string, proposalId: number, manager: string, payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await collaborationTypeReject(token, proposalId, manager, payload);
			dispatch(setCollaborationTypeReject(response.data, token));
		} catch (error) {
			dispatch(setCollaborationTypeReject(null, null));
		}
	};

// REQUEST APPROVAL CSC
export const submitCollaborationTypeRequestApproval =
	(
		hash: string,
		token: string,
		proposalId: number,
		setIsFinishingAction: React.Dispatch<React.SetStateAction<boolean>>
	) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await collaborationTypeRequestApproval(token, proposalId);
			dispatch(setCollaborationTypeRequestApproval(response.data || response.message, hash));
		} catch (error) {
			dispatch(setCollaborationTypeRequestApproval(null, null));
		}
		setIsFinishingAction(false);
	};

// CLOSE CSC
export const submitCollaborationTypeClose =
	(
		hash: string,
		token: string,
		proposalId: number,
		setIsFinishingAction: React.Dispatch<React.SetStateAction<boolean>>
	) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await collaborationTypeClose(token, proposalId);
			dispatch(setCollaborationTypeClose(response.data || null, hash));
		} catch (error) {
			dispatch(setCollaborationTypeClose(null, null));
		}
		setIsFinishingAction(false);
	};
