/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid, Typography, Button } from '@mui/material';

import { parseDateToShow } from 'xpand-ui/utils/dates';
import { Checkbox, InfoField } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

import { IClasses } from 'typings/store/generalTypes';
import { useSelector } from 'react-redux';

export const DOCTYPES = {
	CC: 'CC',
	FID: 'FID',
	PP: 'PP',
	RP: 'RP',
	IBAN: 'IBAN',
	QC: 'QUAL_CERT'
};

/** Components struct */
export const GroupCard: FC<any> = props => {
	const { classes } = props;
	return (
		<Grid
			xs={12}
			sm={12}
			md={12}
			lg={10}
			xl={9}
			{...props}
			classes={{}}
			className={classes.groupCardAdmissions}
			item
		/>
	);
};

export const PersonalInfoCard: FC<{
	classes: IClasses;
	payload: any;
	formFieldsConfig: any;
	hasFile: any;
	showDoc: any;
	deleteDoc: any;
}> = ({ classes, payload, formFieldsConfig, hasFile, showDoc, deleteDoc }) => {
	return (
		<GroupCard classes={classes}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography component="span" className={classes.infoLabel}>
						PERSONAL INFORMATION
					</Typography>
				</Grid>
				{/* Name */}
				{formFieldsConfig?.fullName && (
					<Grid item {...sizes[4]}>
						<InfoField label="Name" value={payload.employeeName} />
					</Grid>
				)}
				{/* GENDER */}
				{formFieldsConfig?.gender && (
					<Grid item {...sizes[4]}>
						<InfoField label="Gender" value={payload.genderName} />
					</Grid>
				)}
				{/* DATE OF BIRTH */}
				{formFieldsConfig?.dateOfBirth && (
					<Grid item {...sizes[4]}>
						<InfoField label="Date of Birth" value={parseDateToShow(payload.dateOfBirth)} />
					</Grid>
				)}
				{/* ID DOC TYPE */}
				<Grid item {...sizes[4]}>
					{formFieldsConfig?.idDocumentType && (
						<InfoField
							label="ID Document Type"
							value={payload.idDocumentTypeName ? payload.idDocumentTypeName : 'N/A'}
						/>
					)}
					{hasFile && (
						<div>
							<Button
								color="primary"
								variant="text"
								style={{ paddingLeft: 0 }}
								// classes={{ label: classes.buttonLabelOverride }}
								endIcon={<VisibilityIcon />}
								onClick={() => showDoc(DOCTYPES.FID)}>
								Show ID Document
							</Button>
							{deleteDoc && (
								<Button
									color="secondary"
									variant="text"
									// classes={{ label: classes.buttonLabelOverride }}
									endIcon={<DeleteOutlineIcon />}
									onClick={() => deleteDoc({ isVisible: true, docType: DOCTYPES.FID })}>
									<Typography variant="body1">Delete ID Document</Typography>
								</Button>
							)}
						</div>
					)}
				</Grid>
				{/* ID DOC NUMBER */}
				{formFieldsConfig?.citizenCardNumber && (
					<Grid item {...sizes[4]}>
						<InfoField label="ID Doc. Number" value={payload.citizenCardNumber} />
					</Grid>
				)}
				{/* DATE OF BIRTH */}
				{formFieldsConfig?.citizenCardValidityDate && (
					<Grid item {...sizes[4]}>
						<InfoField label="Valid Until" value={parseDateToShow(payload.citizenCardValidityDate)} />
					</Grid>
				)}
				{/* FISCAL NUMBER */}
				{formFieldsConfig?.fiscalNumber && (
					<Grid item {...sizes[4]}>
						<InfoField label="Fiscal Number" value={payload.fiscalNumber} />
					</Grid>
				)}
				{/* SOCIAL SECURITY */}
				{formFieldsConfig?.socialSecurityNumber && (
					<Grid item {...sizes[4]}>
						<InfoField label="Social Security Number" value={payload.socialSecurityNumber} />
					</Grid>
				)}
				{/* PENSION FUND NUMBER */}
				{formFieldsConfig?.pensionFund && (
					<Grid item {...sizes[4]}>
						<InfoField label="Pension Fund Number" value={payload.pensionFundNumber} />
					</Grid>
				)}
				{/* HAVE YOU EVER HAD A WITHOUT-TERM EMPLOYMENT CONTRACT BEFORE? */}
				{formFieldsConfig?.hasHadWithoutTermContract && (
					<Grid item {...sizes[4]}>
						<InfoField
							label="Have you ever had a without-term employment contract before?"
							value={
								payload.ageOnContractStartDate === 'N/A'
									? 'N/A'
									: payload.hasHadWithoutTermContract
									? 'Yes'
									: 'No'
							}
						/>
					</Grid>
				)}
				{/* AGE ON CONTRACT START DATE */}
				{formFieldsConfig?.ageOnContractStartDate && (
					<Grid item {...sizes[4]}>
						<InfoField label="Age on Contract Start Date" value={payload.ageOnContractStartDate} />
					</Grid>
				)}
				{/* LICENSE PLATE */}
				{payload.licensePlate && payload.licensePlate !== null && formFieldsConfig?.licensePlate && (
					<Grid item {...sizes[4]}>
						<InfoField label="License Plate" value={payload.licensePlate} />
					</Grid>
				)}
			</Grid>
		</GroupCard>
	);
};

export const ContactInfoCard: FC<{ classes: IClasses; payload: any; formFieldsConfig: any }> = ({
	classes,
	payload,
	formFieldsConfig
}) => (
	<GroupCard classes={classes}>
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography component="span" className={classes.infoLabel}>
					CONTACT INFORMATION
				</Typography>
			</Grid>
			{/* ADDRESS */}
			{formFieldsConfig?.address && (
				<Grid item {...sizes[4]}>
					<InfoField label="Address" value={payload.address} />
				</Grid>
			)}
			{/* CITY */}
			{formFieldsConfig?.city && (
				<Grid item {...sizes[4]}>
					<InfoField label="City" value={payload.city} />
				</Grid>
			)}
			{/* ZIP CODE */}
			{formFieldsConfig?.zipCode && (
				<Grid item {...sizes[4]}>
					<InfoField label="Zip Code" value={payload.zipCode} />
				</Grid>
			)}
			{/* EMAIL */}
			{formFieldsConfig?.email && (
				<Grid item {...sizes[4]}>
					<InfoField label="Email" value={payload.email} />
				</Grid>
			)}
			{/* MOBILE */}
			{formFieldsConfig?.mobilePhone && (
				<Grid item {...sizes[4]}>
					<InfoField label="Mobile Phone Number" value={payload.mobilePhone} />
				</Grid>
			)}
			{/* NATIONALITY */}
			{formFieldsConfig?.nationality && (
				<Grid item {...sizes[4]}>
					<InfoField label="Nationality" value={payload.nationality} />
				</Grid>
			)}
			{/* EMERGENCY CONTACT */}
			{formFieldsConfig?.emergencyContact && (
				<Grid item {...sizes[4]}>
					<InfoField label="Emergency Contact" value={payload.emergencyContact} />
				</Grid>
			)}
			{/* EMERGENCY CONTACT NAME */}
			{formFieldsConfig?.emergencyContactName && (
				<Grid item {...sizes[4]}>
					<InfoField label="Emergency Contact Name" value={payload.emergencyContactName} />
				</Grid>
			)}
			{/* PLACE OF BIRTH */}
			{formFieldsConfig?.placeOfBirth && (
				<Grid item {...sizes[4]}>
					<InfoField label="Place Of Birth" value={payload.placeOfBirth} />
				</Grid>
			)}
			{/* MARITAL STATUS */}
			{formFieldsConfig?.maritalStatus && (
				<Grid item {...sizes[4]}>
					<InfoField label="Marital Status" value={payload.maritalStatus} />
				</Grid>
			)}
			{/* HOLDERS */}
			{formFieldsConfig?.holdersNumber && (
				<Grid item {...sizes[4]}>
					<InfoField label="Holder Status" value={payload.holdersNumber} />
				</Grid>
			)}
			{/* NUMBER OF DEPENDENTS */}
			{formFieldsConfig?.numberDependents && (
				<Grid item {...sizes[4]}>
					<InfoField label="Number Of Dependents" value={payload.dependentsNumber || '0'} />
				</Grid>
			)}
			{/* TAX ZONE */}
			{formFieldsConfig?.taxZone && (
				<Grid item {...sizes[4]}>
					<InfoField label="Tax Zone" value={payload.taxZone} />
				</Grid>
			)}
		</Grid>
	</GroupCard>
);

export const BankingInfoCard: FC<{
	classes: IClasses;
	payload: any;
	formFieldsConfig: any;
	hasFile: any;
	showDoc: any;
	deleteDoc: any;
}> = ({ classes, payload, formFieldsConfig, hasFile, showDoc, deleteDoc }) => (
	<GroupCard classes={classes}>
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography component="span" className={classes.infoLabel}>
					BANKING INFORMATION
				</Typography>
			</Grid>
			{/* CAREER START DATE */}
			{formFieldsConfig?.bankId && (
				<Grid item {...sizes[4]}>
					<InfoField label="Bank Name" value={payload.bankName} />
				</Grid>
			)}
			{/* PROFESSIONAL EXPERIENCE */}
			{formFieldsConfig?.bankNumber && (
				<Grid item {...sizes[4]}>
					<InfoField label="Bank Iban" value={payload.bankNib ? payload.bankNib : 'N/A'} />
				</Grid>
			)}
			<Grid item {...sizes[12]}>
				{hasFile && (
					<div>
						<Button
							color="primary"
							variant="text"
							style={{ paddingLeft: 0 }}
							endIcon={<VisibilityIcon />}
							onClick={() => showDoc(DOCTYPES.IBAN)}>
							Show IBAN Proof
						</Button>
						{deleteDoc && (
							<Button
								size="small"
								color="secondary"
								variant="text"
								endIcon={<DeleteOutlineIcon />}
								onClick={() => deleteDoc({ isVisible: true, docType: DOCTYPES.IBAN })}>
								<Typography variant="body1">Delete IBAN Proof</Typography>
							</Button>
						)}
					</div>
				)}
			</Grid>
		</Grid>
	</GroupCard>
);
export const AcademicInfoCard: FC<{
	classes: IClasses;
	payload: any;
	formFieldsConfig: any;
	hasFile: any;
	showDoc: any;
	deleteDoc: any;
}> = ({ classes, payload, formFieldsConfig, hasFile, showDoc, deleteDoc }) => (
	<GroupCard classes={classes}>
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography component="span" className={classes.infoLabel}>
					ACADEMIC INFORMATION
				</Typography>
			</Grid>
			{/* ACADEMIC DEGREE */}
			{formFieldsConfig?.courseQualificationId && (
				<Grid item {...sizes[4]}>
					<InfoField label="Academic Degree" value={payload.courseQualificationIdName} />
				</Grid>
			)}
			{/* POST BOLOGNA ? */}
			{formFieldsConfig?.coursePostBologna && (
				<Grid item {...sizes[4]}>
					<InfoField label="Post Bologna?" value={payload.coursePostBologna ? 'Yes' : 'No'} />
				</Grid>
			)}
			{/* EDUCATION STATUS  */}
			{formFieldsConfig?.educationStatus && (
				<Grid item {...sizes[4]}>
					<InfoField label="Education Status" value={payload.educationStatus} />
				</Grid>
			)}
			{/* EDUCATION CODE  */}
			{formFieldsConfig?.educationCode && (
				<Grid item {...sizes[4]}>
					<InfoField label="Education Code" value={payload.educationCodeName} />
				</Grid>
			)}
			{/* COURSE NAME  */}
			{formFieldsConfig?.courseName && (
				<Grid item {...sizes[4]}>
					<InfoField label="Course Name" value={payload.courseName} />
				</Grid>
			)}
			{/* INSTITUTION NAME  */}
			{formFieldsConfig?.courseInstitution && (
				<Grid item {...sizes[4]}>
					<InfoField label="Institution Name" value={payload.courseInstitution} />
				</Grid>
			)}
			{/* START DATE  */}
			{formFieldsConfig?.courseStartDate && (
				<Grid item {...sizes[4]}>
					<InfoField
						label="Start Date"
						value={
							!!payload.courseStartDate && payload.courseStartDate !== null
								? parseDateToShow(payload.courseStartDate)
								: 'N/A'
						}
					/>
				</Grid>
			)}
			{/* END DATE  */}
			{formFieldsConfig?.courseEndDate && (
				<Grid item {...sizes[4]}>
					<InfoField
						label="End Date"
						value={
							!!payload.courseEndDate && payload.courseEndDate !== null
								? parseDateToShow(payload.courseEndDate)
								: 'N/A'
						}
					/>
				</Grid>
			)}
			{/* CERTIFICATE FILE  */}
			<Grid item {...sizes[12]}>
				{hasFile && (
					<div>
						<Button
							color="primary"
							variant="text"
							style={{ paddingLeft: 0 }}
							endIcon={<VisibilityIcon />}
							onClick={() => showDoc(DOCTYPES.QC)}>
							Show Qualifications Certificate
						</Button>
						{deleteDoc && (
							<Button
								size="small"
								color="secondary"
								variant="text"
								endIcon={<DeleteOutlineIcon />}
								onClick={() => deleteDoc({ isVisible: true, docType: DOCTYPES.QC })}>
								<Typography variant="body1">Delete Qualifications Certificate</Typography>
							</Button>
						)}
					</div>
				)}
			</Grid>
		</Grid>
	</GroupCard>
);
export const PersonalSummaryCard: FC<{ classes: IClasses; payload: any; formFieldsConfig: any }> = ({
	classes,
	payload,
	formFieldsConfig
}) => (
	<GroupCard classes={classes}>
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography component="span" className={classes.infoLabel}>
					PERSONAL SUMMARY
				</Typography>
			</Grid>
			{/* CAREER START DATE */}
			{formFieldsConfig?.careerStartDate && (
				<Grid item {...sizes[4]}>
					<InfoField
						label="Career Start Date"
						value={
							!!payload.careerStartDate && payload.careerStartDate !== null
								? parseDateToShow(payload.careerStartDate)
								: 'N/A'
						}
					/>
				</Grid>
			)}
			{/* PROFESSIONAL EXPERIENCE */}
			{formFieldsConfig?.professionalExperience && (
				<Grid item {...sizes[4]}>
					<InfoField label="Professional Experience" value={payload.professionalExperience} />
				</Grid>
			)}
		</Grid>
	</GroupCard>
);
export const GDPRCard: FC<{ classes: IClasses; payload: any; formFieldsConfig: any }> = ({
	classes,
	payload,
	formFieldsConfig
}) => {
	const company = useSelector(state => state.company);

	return (
		<GroupCard classes={classes}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography component="span" className={classes.infoLabel}>
						GDPR CONSENT
					</Typography>
				</Grid>
				{formFieldsConfig?.biometricSystemsConsent && (
					<Grid item xs={12}>
						<Checkbox
							name="biometricSystemsConsent"
							style={{
								color: '#00e676'
							}}
							label="BIOMETRIC SYSTEMS CONSENT"
							helperText={`I acknowledge ${company.companyText}'s legitimate interest in collecting my biometric data, such as fingerprints or eyepiece, for exclusive use in ${company.companyText}'s facilities access control systems, thereby giving my express consent to the collection , processing and use of such data for said purposes, leaving ${company.companyText} as the entity responsible for complying with obligations regarding the protection of personal data, in accordance with the applicable law, namely for its confidentiality and elimination.`}
							value={payload.biometricSystemsConsent}
							disabled
						/>
					</Grid>
				)}
				{formFieldsConfig?.intranetSystemsConsent && (
					<Grid item xs={12}>
						<Checkbox
							name="intranetSystemsConsent"
							label="INTRANET SYSTEMS CONSENT"
							helperText={`I acknowledge ${company.companyText}'s legitimate interest in collecting my personal data, such as facial photography and personal mobile phone, for exclusive use in its internal information systems, for work purposes and for internal and corporate organization and operation, giving to ${company.companyText} my express consent to the collection, processing and use of such data for said purposes, leaving ${company.companyText} as the entity responsible for complying with obligations regarding the protection of personal data, in accordance with the applicable law, namely for its confidentiality and elimination.`}
							value={payload.intranetSystemsConsent}
							disabled
						/>
					</Grid>
				)}
				{formFieldsConfig?.promotionalMediaConsent && (
					<Grid item xs={12}>
						<Checkbox
							name="promotionalMediaConsent"
							label="PROMOTIONAL MEDIA CONSENT"
							helperText={`I acknowledge ${company.companyText}'s legitimate interest in collecting of my personal data, such as personal image in photographs, videos or any media type, its recording, editing and public display in any media in Portugal and abroad, solely for the purpose of ${company.companyText} using it for its institutional communication and promotional marketing, thereby giving ${company.companyText}, my express consent to the collection, processing and use of such data for those mentioned purposes, leaving ${company.companyText} as the entity responsible for complying with obligations regarding the protection of personal data, in accordance with the applicable law, namely for its confidentiality and elimination.`}
							value={payload.promotionalMediaConsent}
							disabled
						/>
					</Grid>
				)}
			</Grid>
		</GroupCard>
	);
};
