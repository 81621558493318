/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/extensions
import moment, { Moment } from 'moment';
import { IUserToken } from 'typings/store/generalTypes';
// import { parseDateToManage } from 'xpand-ui/utils/dates';
import { removeTimezone } from 'xpand-ui/utils/dates';

const parseDateToManage = (date: Date | Moment | string, clearHour = false): string =>
	clearHour
		? `${moment(removeTimezone(date)).format('YYYY/MM/DD')} 00:00:00`
		: moment(removeTimezone(date)).format('YYYY/MM/DD HH:mm:ss');

export const parseStartDateToString = (date: Date | string) => parseDateToManage(date, true);
export const parseEndDateToString = (date: Date | string) =>
	parseDateToManage(date, true).replace('00:00:00', '23:59:00');

export enum EventColors {
	UserValid = '#69c01a', // user + ok -> verde vivo
	UserNotValid = '#f6db18', // user + not OK -> amarelo
	NotUserValid = '#69c01a5e', // not user + OK -> verde claro
	NotUserNotValid = '#f6db185e', // not user + not OK -> amarelo claro
	BlockedSeat = '#ec5353' // Blocked Seat -> vermelho
}
export enum EventStyle {
	Validated = 'border',
	NotValidated = 'border'
}
const eventSchedulerDefaultProps = {
	resizable: false,
	milestoneWidth: 0,
	draggable: false,
	allDay: false,
	validated: true
};

export const getRandomInt = (min = 500000, max = 500000) => Math.floor(Math.random() * max) + min;

/* eslint-disable no-param-reassign */
export const sortDates = (s: Date, e: Date) => [s, e].sort((a: Date, b: Date) => a.valueOf() - b.valueOf());

// ? chamado no fim do Drag do create event
export const beforeDragCreateFinalize = ({ context }: any) => {
	// * vai esperar pela nossa logica
	// ! depois tem de ser finalizado com "context.finalize(true);"
	context.async = true;
	setTimeout(() => {
		const [sd, ed] = sortDates(context.oldValue, context.date);
		const days = Math.ceil((ed.getTime() - sd.getTime()) / (1000 * 3600 * 24));
		//* recordData should be merged with the default data in finalizeDragCreate
		// ? workaround para fazer override do endDate e do duration
		// ? isto é chamado depois no 'dragCreateEnd'
		context.event._recordData = {
			startDate: new Date(parseStartDateToString(sd)),
			endDate: new Date(parseEndDateToString(ed)),
			duration: days
		};
		// * pass true to create new record
		// ? [true] -> adiciona à store, e vai para os proximos listners
		// ? [false]-> nao adiciona à store, e acção termina aqui
		context.finalize(true);
	}, 200);
};

// ? workaround para fazer override do endDate e do duration
// ? isto é chamado a partir do beforeDragCreateFinalize
export const dragCreateEnd = {
	fn: ({ newEventRecord, event }: any) => {
		// fazer override do endDate e do duration
		newEventRecord.startDate = event._recordData.startDate;
		newEventRecord.endDate = event._recordData.endDate;
		newEventRecord.duration = event._recordData.duration;
	},
	prio: 1 // To set data BEFORE Event Edit feature loads new record to the editor
};

// ? chamado no INICIO do DragCreate
// ? chamado ao Criar um evento
export const beforeEventAdd = ({ eventRecord: newEvent, resourceRecords }: any, loggedUser: IUserToken) => {
	const [resource] = resourceRecords;
	const isWeekend = newEvent.startDate.getDay() === 0 || newEvent.startDate.getDay() === 6;
	const available = !(resource.children || isWeekend);
	if (available) {
		const [sd, ed] = sortDates(newEvent.startDate, newEvent.startDate);
		const startDate = new Date(parseStartDateToString(sd));
		const endDateAux = newEvent.duration === 1 ? sd : ed;
		const endDate = new Date(parseEndDateToString(endDateAux));

		newEvent.startDate = startDate;
		newEvent.endDate = endDate;
		newEvent.zoneId = resource.data.zoneId;
		if (newEvent.isBlockedSeat) {
			newEvent.eventColor = EventColors.BlockedSeat;
		} else {
			newEvent.eventColor = EventColors.UserValid;
		}
		newEvent.username = loggedUser.username;
		newEvent.name = loggedUser.username.toUpperCase();
	}

	return available;
};

// ? chamado ao abrir o modal de create
// ? chamado ao abrir o modal de edit
export const beforeEventEdit = (source: any, showEditor: any, username: string) => {
	const { validated } = source.eventRecord;
	if (source.eventRecord.username !== username) return false;

	source.eventRecord.data.resourceId = source.resourceRecord.id;
	source.eventRecord.resourceId = source.resourceRecord.id;
	//! EDIT FUNCIONALLITY IS DISABLED with the 'validated !== undefined' on the IF above
	// ? ON EDIT, ITS MISSING THE ZONE ID FILLED, BECAUSE THAT FIELD IS MANUALLY ADDED ON beforeEventAdd
	// ? and there is also an error with the endDate, its assumid the end date on the next date, and the event
	// ? transforms itself to an 'milestone' type one, this needs to be addressed when implementing this funcionallity
	if (source.eventRecord.duration <= 1) {
		source.eventRecord.duration = 1;
		source.eventRecord.endDate = source.eventRecord.startDate;
	}
	showEditor(source.eventRecord);
	return false;
};

export const parseEventData = (payload: any, loggedUsername: string, fromBackEnd = false) => {
	const startDate = new Date(parseStartDateToString(payload.startDate));
	const endDate = new Date(parseEndDateToString(payload.endDate));

	const belongsToUser = loggedUsername === payload.username;
	const createdByUser = loggedUsername === payload.createdBy;
	/* 
	 * The VALIDATED value comes from:
	 ? 1 - from the Backend -> then payload.validated
	 ? 2 - from a new booking -> if its the own user booking to itself
	*/

	const isValidated = (fromBackEnd && payload.validated) || (!fromBackEnd && belongsToUser && createdByUser);
	const isBlockedSeat = fromBackEnd && payload.blockedSeat;

	// UserValid = '#9ccc65', // verde
	// UserNotValid = '#ffd54f', // amarelo
	// NotUserValid = '#bedd9a', // verde claro
	// NotUserNotValid = '#ffe69b',// amarelo claro
	const event = {
		...payload,
		...eventSchedulerDefaultProps,
		fullName: payload.fullName || payload.username,
		customId: fromBackEnd ? payload.id : `${getRandomInt()}`,
		canEdit: belongsToUser || (createdByUser && !isValidated),
		validated: isValidated,
		// eventStyle: isValidated ? EventStyle.Validated : EventStyle.NotValidated,
		eventStyle: 'border',
		eventColor:
			(isBlockedSeat && EventColors.BlockedSeat) ||
			(belongsToUser && isValidated && EventColors.UserValid) ||
			(belongsToUser && !isValidated && EventColors.UserNotValid) ||
			(!belongsToUser && isValidated && EventColors.NotUserValid) ||
			(!belongsToUser && !isValidated && EventColors.NotUserNotValid),
		name: !isBlockedSeat ? payload.username.toUpperCase() : '',
		startDate,
		endDate
	};
	return event;
};
