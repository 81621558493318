/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { Dispatch } from 'redux';
import { fetchAppsList } from 'lib/api/general/apps';

import { OriginalResponse } from 'lib/handlers/requestTypes';
import { setLoading, setAppsError, setAppsList } from './actions';

export const getAppsList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			// api request
			const response = await fetchAppsList();

			// set api results on the redux state
			if (response.data) {
				dispatch(setAppsList(response.data.apps));
			} else {
				dispatch(setAppsError(response as OriginalResponse));
			}
		} catch (error) {
			// set default value in case of error
			// in the case, in the success case we need an array with values
			//  so in case of error, we send a empty array
			dispatch(setAppsError(error as OriginalResponse));
		}
	};

export const clearAppsError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setAppsError(null));
	};
