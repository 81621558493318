/* eslint-disable indent */
import { Dispatch } from 'redux';
import { IWelcomeEmail, INewEmployee, IEmployeeVPNList, IHealthLocations } from 'typings/store/admin/users';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import {
	fetchUserList,
	deleteUser,
	deleteUserFromDB,
	fetchWelcomeEmail,
	sendWelcomeEmail,
	postUserListVPN,
	fetchUserAdd,
	postUserAdd,
	fetchUsersList,
	fetchHealthLocations,
	sendHealthConvocation,
	sendImpersonateEmail
} from 'lib/api/administration/users';
import { fetchContractsList } from 'lib/api/administration/employeeSalaryCategories';
import {
	setUsersListVPN,
	setDeleteUser,
	setDeleteUserFromDB,
	setWelcomeEmail,
	setPostWelcomeEmail,
	setPostUsersListVPN,
	setUsersAdd,
	setPostUsersAdd,
	setUsersList,
	setLoading,
	setClearWelcomeEmail,
	setUsersError,
	setResponseCode,
	setHealthLocations,
	setPostHealthConvocation,
	setPostImpersonateEmail
} from './actions';
import { setResignationList } from '../admissions/actions';
import { fetchResignationList } from 'lib/api/administration/admissions';
import { IUsersResignationList } from 'typings/store/admin/resignations';
import { IContractsList } from 'typings/store/admin/employeeSalaryCategories';
import { setContractsList } from '../employeeSalaryCategories/actions';

export const getUsersListVPN =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// set loading
			dispatch(setLoading());
			// api request
			const response: { data?: IEmployeeVPNList } | OriginalResponse = await fetchUserList();
			// set api results on the redux state
			if (response.data) {
				dispatch(setUsersListVPN(response.data || null));
			} else {
				dispatch(setUsersError(response as OriginalResponse));
			}
		} catch (error) {
			// set default value in case of error
			// in the case, in the success case we need an array with values
			//  so in case of error, we send a empty array
			dispatch(setUsersListVPN(null));
		}
	};

export const sendDeleteUser =
	(username: string, payload: IDeleteUser) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await deleteUser(username, payload);
			dispatch(setDeleteUser(response));
		} catch (error) {
			dispatch(setDeleteUser(null));
		}
	};

export const getWelcomeEmail =
	(username: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IWelcomeEmail } | OriginalResponse = await fetchWelcomeEmail(username);
			if (response.data) {
				dispatch(setWelcomeEmail(response?.data || null));
			} else {
				dispatch(setUsersError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setWelcomeEmail(null));
		}
	};

export const sendPostWelcomeEmail =
	(username: string, payload: IWelcomeEmail) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: unknown } = await sendWelcomeEmail(username, payload);
			dispatch(setPostWelcomeEmail(response.data || null));
		} catch (error) {
			dispatch(setPostWelcomeEmail(null));
		}
	};

export const clearWelcomeEmail =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setClearWelcomeEmail());
	};

export const sendUsersListVPN =
	(list: string[]) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: unknown } = await postUserListVPN(list);
			dispatch(setPostUsersListVPN(response.data || null));
		} catch (error) {
			dispatch(setPostUsersListVPN(null));
		}
	};

export const getUsersAdd =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			// api request
			dispatch(setLoading());
			const response: { data?: unknown } | OriginalResponse = await fetchUserAdd();
			// set api results on the redux state
			if (response.data) {
				dispatch(setUsersAdd(response.data || null));
			} else {
				dispatch(setUsersError(response as OriginalResponse));
			}
		} catch (error) {
			// set default value in case of error
			// in the case, in the success case we need an array with values
			//  so in case of error, we send a empty array
			dispatch(setUsersAdd([]));
		}
	};

export const sendUsersAdd =
	(payload: INewEmployee) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const res = await postUserAdd(payload);
			dispatch(setPostUsersAdd(res));
			if (res.code) dispatch(setResponseCode(res.code));
			else dispatch(setResponseCode(200));
		} catch (error) {
			dispatch(setPostUsersAdd(null));
		}
	};

export const getUsersList =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: IEmployeeVPNList } | OriginalResponse = await fetchUsersList();

			if (response.data) {
				dispatch(setUsersList((response.data && response.data.users) || null));
			} else {
				dispatch(setUsersError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setUsersList(null));
		}
	};

export const getHealthLocations =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const response: { data?: IHealthLocations } | OriginalResponse = await fetchHealthLocations();
			if (response.data) {
				dispatch(setHealthLocations((response.data && response.data.locations) || null));
			} else {
				dispatch(setHealthLocations(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setHealthLocations(null));
		}
	};

export const clearUsersError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setUsersError(null));
	};

// Admission Management - Create Employee POST
export const submitHealthConvocation =
	(payload: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const data = await sendHealthConvocation(payload);
			dispatch(setPostHealthConvocation());
		} catch (error) {
			dispatch(setPostHealthConvocation());
		}
	};

export const sendPostImpersonateEmail =
	(action: string, impersonatedUser: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: unknown } = await sendImpersonateEmail(action, impersonatedUser);
			dispatch(setPostImpersonateEmail(response.data || null));
		} catch (error) {
			dispatch(setPostImpersonateEmail(null));
		}
	};

export const sendDeleteUserFromDB =
	(payload: any) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await deleteUserFromDB(payload);
			const response2: { data?: IContractsList } | OriginalResponse = await fetchContractsList();
			if (response && response2) {
				dispatch(setDeleteUserFromDB(response));
				dispatch(setContractsList(response2 as IContractsList));
				dispatch(setResignationList(null));
			} else {
				dispatch(setUsersError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setDeleteUserFromDB(null));
		}
	};
