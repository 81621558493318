import { connect } from 'react-redux';
import { AppState } from 'typings/state';
import AuthRoute from './AuthRoute';

const mapStateToProps = ({ system, router }: AppState) => ({
	system,
	router
});

export default connect(mapStateToProps, null)(AuthRoute);
