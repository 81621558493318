/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, InputAdornment } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, Checkbox, Input, DatePicker } from 'xpand-ui/forms';
import { addNotification } from 'lib/utils/notifications';

import clsx from 'clsx';
import { Loop } from '@mui/icons-material';
import { DeepMap, FieldError } from 'react-hook-form';
import { useStyles } from './styles';
import { INewEmployeeTabs } from '../../NewEmployee';

const markRequiredValues = (data: any): any => {
	const checkS = (val: string) =>
		Boolean(data[val] !== null && typeof data[val] !== 'undefined' && data[val].trim() !== '');

	const checkN = (val: string) =>
		data[val] !== null && typeof data[val] !== 'undefined' && (Number(data[val]) === 0 ? 0 : Number(data[val]));

	const requiredValues = {
		// Basic Info Tab
		username: checkS('username'),
		email: checkS('email'),

		// Where The Employee Belongs Tab
		company: checkS('company'),
		businessUnit: checkS('businessUnit'),
		division: checkS('division'),
		department: checkS('department'),
		// Employee Company Info Tab
		prefix: checkS('prefix'),
		firstName: checkS('firstName'),
		lastName: checkS('lastName'),
		location: checkS('location'),
		mobilePhone: checkS('mobilePhone'),
		hiredDate: checkS('hiredDate'),
		...((data.addToXtracker && {
			holidayType: checkS('holidayType')
		}) ||
			{}),
		...((!data.hasTimeOffPolicy && {
			timeOffApprovalType: checkS('timeOffApprovalType')
		}) ||
			{}),
		...((!data.hasTimeOffPolicy && {
			timeOffPolicy: checkS('timeOffPolicy')
		}) ||
			{}),
		...((data.showEmployeeManagers && {
			employeeManager: checkS('employeeManager')
		}) ||
			{}),
		...((data.showEmployeeManagers && {
			vacationDays: checkN('vacationDays')
		}) ||
			{}),
		password: checkS('password')
	};

	return requiredValues;
};

const getMissingFieldsNames = (
	errors: DeepMap<any, FieldError>
): {
	names: string[];
	tabs: Set<number>;
} => {
	const fields = {
		// Basic Info Tab
		username: {
			name: 'Username',
			tab: 0
		},
		email: {
			name: 'Email ',
			tab: 0
		},
		// Where The Employee Belongs Tab
		company: {
			name: 'Company',
			tab: 1
		},
		businessUnit: {
			name: 'Business Unit',
			tab: 1
		},
		division: {
			name: 'Division',
			tab: 1
		},
		department: {
			name: 'Department',
			tab: 1
		},
		// Employee Company Info Tab
		prefix: {
			name: 'Prefix',
			tab: 2
		},
		firstName: {
			name: 'First Name',
			tab: 2
		},
		lastName: {
			name: 'Last Name',
			tab: 2
		},
		location: {
			name: 'Location',
			tab: 2
		},
		mobilePhone: {
			name: 'Mobile Phone',
			tab: 2
		},
		hiredDate: {
			name: 'Hired Date',
			tab: 2
		},
		holidayType: {
			name: 'Holiday Type',
			tab: 2
		},
		timeOffApprovalType: {
			name: 'Time Off Approval Type',
			tab: 2
		},
		timeOffPolicy: {
			name: 'Time Off Policy',
			tab: 2
		},
		employeeManager: {
			name: 'Employee Manager',
			tab: 2
		},
		vacationDays: {
			name: 'Vacation Days',
			tab: 2
		},
		password: {
			name: 'Password',
			tab: 2
		}
	};

	const fieldsMissing: { name: string; tab: number }[] = [];
	Object.keys(errors).forEach(name => fieldsMissing.push(fields[name]));

	return {
		names: fieldsMissing.map(e => e?.name),
		tabs: new Set(fieldsMissing.map(e => e?.tab))
	};
};

const getAdditionalErrors = (
	errors: DeepMap<any, FieldError>
): {
	additionalNames: string[];
	additionalTabs: Set<number>;
} => {
	const fields = {
		// Basic Info Tab
		username: {
			name: 'Username',
			tab: 0
		},
		email: {
			name: 'Email ',
			tab: 0
		},
		// Where The Employee Belongs Tab
		company: {
			name: 'Company',
			tab: 1
		},
		businessUnit: {
			name: 'Business Unit',
			tab: 1
		},
		division: {
			name: 'Division',
			tab: 1
		},
		department: {
			name: 'Department',
			tab: 1
		},
		// Employee Company Info Tab
		prefix: {
			name: 'Prefix',
			tab: 2
		},
		firstName: {
			name: 'First Name',
			tab: 2
		},
		lastName: {
			name: 'Last Name',
			tab: 2
		},
		location: {
			name: 'Location',
			tab: 2
		},
		mobilePhone: {
			name: 'Mobile Phone',
			tab: 2
		},
		hiredDate: {
			name: 'Hired Date',
			tab: 2
		},
		holidayType: {
			name: 'Holiday Type',
			tab: 2
		},
		timeOffApprovalType: {
			name: 'Time Off Approval Type',
			tab: 2
		},
		timeOffPolicy: {
			name: 'Time Off Policy',
			tab: 2
		},
		employeeManager: {
			name: 'Employee Manager',
			tab: 2
		},
		vacationDays: {
			name: 'Vacation Days',
			tab: 2
		},
		password: {
			name: 'Password',
			tab: 2
		}
	};

	const fieldsMissing: { name: string; tab: number }[] = [];
	Object.keys(errors).forEach(name => fieldsMissing.push(fields[name]));

	return {
		additionalNames: fieldsMissing.map(e => e?.name),
		additionalTabs: new Set(fieldsMissing.map(e => e?.tab))
	};
};

//* COMPONENT
const EmployeeCompanyProfile: FC<INewEmployeeTabs> = ({
	control,
	errors,
	actions,
	formSelectOptions,
	genPassword,
	setVacationDays,
	watch,
	setValue,
	handleWarning,
	additionalErrors,
	setSubmitClick,
	submitClick,
	resetField
}) => {
	const classes = useStyles();

	const xtrackerValue = watch('addToXtracker');
	const timeOffPoliciesValue = watch('hasTimeOffPolicy');
	const employeeManagerType = watch('timeOffApprovalType');

	useEffect(() => {
		if (submitClick) {
			const formPayload = watch();

			const requiredValues = markRequiredValues(formPayload);

			const warningValues = {};
			Object.keys(requiredValues).forEach(e => {
				if (requiredValues[e] === false) {
					warningValues[e] = '';
				}
			});

			const { names, tabs } = getMissingFieldsNames(warningValues);
			const { additionalNames, additionalTabs } = getAdditionalErrors(additionalErrors);

			let mergedNames = names.concat(additionalNames);

			let mergedTabs = new Set([...tabs, ...additionalTabs]);

			if (mergedNames.length !== 0) {
				handleWarning(mergedNames, [...mergedTabs]);
				addNotification(
					'danger',
					`New Employee's form is not completed, the following fields must be valid: ${mergedNames.join(
						', '
					)}`
				);
			}
			setSubmitClick(false);
		}
	}, [submitClick]);

	return (
		<Grid container spacing={4} className={classes.root}>
			<Grid item {...sizes[6]}>
				<Select
					name="prefix"
					label="Prefix"
					helperText="Prefix for the employee (required by XTracker app)"
					options={formSelectOptions.prefixes}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="firstName"
					label="First Name"
					helperText="First name, latin characters allowed (ex: Pedro, Rui, João)"
					required
					control={control}
					errors={errors}
					style={{ width: '95%' }}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="lastName"
					label="Last Name"
					helperText="Last name, latin characters allowed (ex: Silva, Gonçalves)"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Select
					name="location"
					label="Location"
					helperText="Location where the employee will work (required by Timesheets app)"
					options={formSelectOptions.locations}
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="mobilePhone"
					label="Mobile Phone"
					required
					onKeyPress={event => {
						// if (!/^\+?\d*$/.test(event.key)) {
						if (!/[0-9+ ]/.test(event.key)) {
							event.preventDefault();
						}
					}}
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<DatePicker
					name="hiredDate"
					label="Hired Date"
					helperText="First day of work for this employee"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					required
					name="password"
					label="Password"
					helperText="Initial password for this employee"
					control={control}
					errors={errors}
					InputProps={{
						endAdornment: (
							<InputAdornment onClick={genPassword} position="end">
								<Loop />
							</InputAdornment>
						)
					}}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Checkbox
					name="addToXtracker"
					label="CREATE EMPLOYEE IN XTRACKER"
					additionalOnChange={(item: boolean) => {
						if (!item) {
							resetField('holidayType')
							resetField('hasTimeOffPolicy')
							resetField('timeOffApprovalType')
							resetField('employeeManager')
							resetField('vacationDays')							
							resetField('timeOffPolicy');
						}
					}}
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				className={clsx(classes.animation, {
					[classes.show]: xtrackerValue
				})}>
				<Select
					name="holidayType"
					label="Holiday Type"
					required
					options={formSelectOptions.holidayTypes}
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				className={clsx(classes.animation, {
					[classes.show]: xtrackerValue
				})}>
				<Checkbox
					name="hasTimeOffPolicy"
					label="NO TIME OFF POLICY"
					checked
					
					additionalOnChange={() => {
						resetField('vacationDays');
						resetField('timeOffApprovalType');
						resetField('timeOffPolicy');
					}}
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid
				item
				xs={6}
				className={clsx(classes.animation, {
					[classes.show]: !timeOffPoliciesValue && xtrackerValue
				})}>
				<Select
					name="timeOffApprovalType"
					label="Time Off Approval Type"
					required
					additionalOnChange={(item: { value: string }) => {
						if (item.value === '307') {
							setValue('showEmployeeManagers', true);
						} else {
							setValue('showEmployeeManagers', false);
							setValue('employeeManager', '');
						}
					}}
					options={formSelectOptions.timeOffApprovalTypes}
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid
				item
				xs={6}
				className={clsx(classes.animation, {
					[classes.show]: !timeOffPoliciesValue && xtrackerValue
				})}>
				<Select
					name="timeOffPolicy"
					label="Time Off Policy"
					additionalOnChange={item => {
						if (!item) {
							setVacationDays();
						} else {
							setValue('vacationDays', '0');
						}
					}}
					required
					options={formSelectOptions.timeoffPolicies}
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				className={clsx(classes.animation, {
					[classes.show]: !timeOffPoliciesValue && xtrackerValue && employeeManagerType === '307'
				})}>
				<Select
					name="employeeManager"
					label="Employee Manager"
					required
					options={formSelectOptions.employeeManagers}
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				className={clsx(classes.animation, {
					[classes.show]: !timeOffPoliciesValue && xtrackerValue
				})}>
				<Input
					name="vacationDays"
					label="Nr Of Vacation Days"
					type="number"
					required
					helperText="This is the number of vacation days suggested"
					control={control}
					errors={errors}
				/>
			</Grid>
		</Grid>
	);
};

export default EmployeeCompanyProfile;
