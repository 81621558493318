/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

//* THEME REALTED IMPORTS */
import { theme, WithJssAndCustomTheme } from 'xpand-ui/theme';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';

const muiTheme = createTheme(theme);
export const XpandUiWrapper = (Component: any) => (props: any) =>
	(
		<WithJssAndCustomTheme theme={muiTheme}>
			<ThemeProvider theme={muiTheme}>
				<Component {...props} />
			</ThemeProvider>
		</WithJssAndCustomTheme>
	);
