import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';
import { getExpensesAuditData } from 'store/personalInfo/thunks';

import ExpenseSheetAudit from './ExpenseSheetAudit';

const mapStateToProps = ({ personalInfo }: AppState) => ({ personalInfo });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getExpensesAuditData }, dispatch);

export type ExpenseSheetAuditProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseSheetAudit);
