import { createReducer } from 'typesafe-actions';
import {
	CollaborationTypeActions as Actions,
	CollaborationTypesState
} from 'typings/store/admin/collaborationTypesManagement';

const INITIAL_STATE: CollaborationTypesState = {
	loading: false,
	error: null,
	employeesList: null,
	employee: null,
	employeeCategoryList: null,
	employeeCategoryActions: null,
	invalidDocs: []
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CSCActions = (state: any, { payload }: any) => ({
	...INITIAL_STATE,
	// for any actions we must ensure that a new request must be made the next time we access the ESC by user page
	employeeCategoryActions: payload.payload
});

const CollaborationTypesManagementReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.GET_EMPLOYEES_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		employeesList: payload
	}),
	[Actions.RESET_EMPLOYEE_ACTIONS]: state => ({
		...state,
		employeeCategoryActions: null
	}),
	[Actions.GET_EMPLOYEE_INFO]: (state, { payload }) => ({
		...state,
		loading: false,
		employee: payload
	}),
	[Actions.RESET_EMPLOYEE]: state => ({
		...state,
		employee: null,
		employeeCategoryActions: null
	}),
	[Actions.GET_COLLABORATION_TYPE_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		employeeCategoryList: payload
	}),
	[Actions.SUBMIT_NEW_COLLABORATION_TYPE]: (state, { payload }) => ({
		...state,
		employeesList: null,
		employeesSalaryActions: payload
	}),
	[Actions.SET_COLLABORATION_TYPE_CANCEL]: CSCActions,
	[Actions.SET_COLLABORATION_TYPE_APPROVE]: CSCActions,
	[Actions.SET_COLLABORATION_TYPE_REJECT]: CSCActions,
	[Actions.SET_COLLABORATION_TYPE_REQUEST_APPROVAL]: CSCActions,
	[Actions.SET_COLLABORATION_TYPE_CLOSE]: CSCActions,
	[Actions.RESET_FORM_DATA]: () => ({ ...INITIAL_STATE }),
	[Actions.SET_INVALID_DOCS]: (state, { payload }) => ({ ...state, loading: false, invalidDocs: payload })
});

export default CollaborationTypesManagementReducer;
