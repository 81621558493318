import { getLSField } from 'lib/utils/cookies';

// check Control Panel User Permissions
export const hasPermission = (permission: string) => {
	const userPermissionsStorage = getLSField('userPermissions');
	const userPermissions: Permissions[] = (userPermissionsStorage && JSON.parse(userPermissionsStorage)) || null;
	let result: boolean = false;
	if (userPermissions != null) {
		userPermissions.forEach(userPermission => {
			if (userPermission.displayName === permission) {
				result = true;
			}
		});
	}
	return result;
};
