import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, POST } from 'lib/handlers/requestTypes';
import { INewClient, INewProject } from 'typings/store/admin/projectsAndClients';

export const fetchNewClient = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/forms/client');

export const postNewClient = async (payload: INewClient): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/forms/client', payload);

export const fetchNewProjectData = async (payload: boolean): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/forms/project?loadAllCompanies=${payload}`);

export const postNewProject = async (payload: INewProject): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/forms/project', payload);
