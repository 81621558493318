import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { getResumeDetails, clearResumesList } from 'store/administration/skillsAndResumes/thunks';

import ResumeDetails from './ResumeDetails';

const mapStateToProps = ({ skillsAndResumes }: AppState) => ({ skillsAndResumes });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getResumeDetails, clearResumesList }, dispatch);

export type ResumeDetailsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ResumeDetails);
