import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const cardWebSize = '218px';
const cardTabletSize = '190px';
const cardMobileSize = '150px';

const cardStyle = {
	flexGrow: '1',
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	flexDirection: 'column',
	justifyContent: ' space-evenly',
	padding: '24px',
	boxShadow: 'none',
	filter: 'gray' /* IE5+ */,
	'-webkit-filter': 'grayscale(1) brightness(1.15)' /* Webkit Nightlies & Chrome Canary */,
	'-webkit-transition': 'all .1s ease-in-out',
	'&:hover': {
		filter: 'none',
		'-webkit-filter': 'grayscale(0) brightness(1)',
		'-webkit-transform': 'scale(1.01)'
	}
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const a = (theme: XpandUiTheme) => ({
	//* WEB
	[theme.breakpoints.up('md')]: {
		//
	},
	//* TABLET
	[theme.breakpoints.down('md')]: {
		//
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		//
	}
});

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		flexGrow: 1
	},
	//* WEB
	[theme.breakpoints.up('md')]: {
		cardRoot: {
			...cardStyle,
			maxWidth: cardWebSize,
			minWidth: cardWebSize,
			minHeight: cardWebSize,
			height: cardWebSize
		},
		appText: {
			textAlign: 'center',
			margin: '0 auto 12px auto',
			maxWidth: cardWebSize
		},
		appIcon: {
			flexGrow: 1,
			// margin: '12px 40px 0 40px',
			minHeight: '100px'
		}
	},
	//* TABLET
	[theme.breakpoints.down('md')]: {
		cardRoot: {
			...cardStyle,
			maxWidth: cardTabletSize,
			minWidth: cardTabletSize,
			height: cardTabletSize,
			minHeight: cardTabletSize
		},
		appText: {
			textAlign: 'center',
			margin: '0 auto 12px auto',
			maxWidth: cardTabletSize
		},
		appIcon: {
			flexGrow: 1,
			transform: 'scale(.8)',
			minHeight: '90px'
		}
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		cardRoot: {
			...cardStyle,
			padding: '5px',
			maxWidth: cardMobileSize,
			minWidth: cardMobileSize,
			height: cardMobileSize,
			minHeight: cardMobileSize
		},
		appText: {
			textAlign: 'center',
			margin: '0 auto 12px auto',
			maxWidth: cardMobileSize
		},

		appIcon: {
			flexGrow: 1,
			transform: 'scale(.6)',
			minHeight: '80px'
		}
	},
	searchTableInput: {
		float: 'right',
		minHeight: '64px',
		maxWidth: '350px',
		boxShadow: 'none',
		padding: '0 16px 45px 16px'
	},
	input: {
		borderBottom: 'none'
	},
	search: {
		color: '#AAC3D1'
	},
	inputFocused: {
		borderBottom: `1px solid ${theme.accentColor}`,
		'& > div': {
			color: theme.accentColor
		}
	},
	cardBase: {
		display: 'block',
		margin: 'auto',
		boxShadow: theme.boxShadow,
		borderRadius: '8px'
	},
	centerNoResults: {
		display: 'block'
	}
}));

export { useStyles };
