import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import { stringRequired, dateDefault, dateRequired, stringLongRequired, maxDateFromDay } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	personalInfo: yup.array().of(
		yup.object().shape({
			nativeLanguage: stringRequired,
			countryOrigin: stringRequired,
			countryCode: stringRequired,
			birthplace: stringRequired,
			description: stringLongRequired
		})
	),
	education: yup.array().of(
		yup
			.object()
			.shape({
				classification: stringRequired,
				startDate: dateRequired.max(
					maxDateFromDay(new Date()).toISOString(),
					({ max }) => `Start date to be before ${parseDateToShow(max as string)}`
				),
				endDate: dateDefault.min(yup.ref('startDate'), ({ min }) =>
					moment(min).isValid()
						? `End date to be after ${parseDateToShow(min as string)}`
						: 'Invalid Start Date'
				),
				mainSubjects: stringRequired,
				major: stringRequired,
				minor: stringRequired,
				organizationName: stringRequired,
				certificationAwarded: stringRequired
			})
			.nullable()
	),
	workExperience: yup.array().of(
		yup
			.object()
			.shape({
				startDate: dateRequired.max(
					new Date().toISOString(),
					({ max }) => `Start date to be before ${parseDateToShow(max as string)}`
				),
				endDate: dateDefault.min(yup.ref('startDate'), ({ min }) =>
					moment(min).isValid()
						? `End date to be after ${parseDateToShow(min as string)}`
						: 'Invalid Start Date'
				),
				businessSector: stringRequired,
				occupation: stringRequired,
				mainActivities: stringLongRequired
			})
			.nullable()
	),
	workshops: yup.array().of(
		yup
			.object()
			.shape({
				title: stringRequired,
				date: dateRequired.max(
					new Date().toISOString(),
					({ max }) => `Start date to be before ${parseDateToShow(max as string)}`
				),
				location: stringRequired,
				subject: stringLongRequired
			})
			.nullable()
	),
	otherProjects: yup.array().of(
		yup
			.object()
			.shape({
				name: stringRequired,
				mainActivities: stringLongRequired
			})
			.nullable()
	),
	training: yup.array().of(
		yup
			.object()
			.shape({
				title: stringRequired,
				startDate: dateRequired.max(
					new Date().toISOString(),
					({ max }) => `Start date to be before ${parseDateToShow(max as string)}`
				),
				endDate: dateDefault.min(yup.ref('startDate'), ({ min }) =>
					moment(min).isValid()
						? `End date to be after ${parseDateToShow(min as string)}`
						: 'Invalid Start Date'
				),
				organizationName: stringRequired,
				mainSubjects: stringRequired,
				certificationAwarded: stringRequired,
				level: stringRequired
			})
			.nullable()
	)
});
