/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { OriginalResponse } from 'lib/handlers/requestTypes';
import { IChoosableBaseInfo } from '../generalTypes';
import {
	ICollaborationProposal,
	IOtherBenefit,
	IPrizeGoal,
	IProposalPerToken,
	ICompanyInfo,
	IIrsValueGet
} from './proposals';

export enum EmployeeSalaryActions {
	SET_LOADING = '@@administration/employeeSalaryCategories/SET_LOADING',
	SET_ERROR = '@@administration/employeeSalaryCategories/SET_ERROR',
	//! GET INFO TABLES
	// GET CSC FOR ALL USERS
	GET_EMPLOYEES_LIST = '@@administration/employeeSalaryCategories/GET_EMPLOYEES_LIST',
	// GET CSC FOR ALL USERS
	GET_EXPORT_MULTIPLE_CSCS = '@@administration/employeeSalaryCategories/GET_EXPORT_MULTIPLE_CSCS',
	// GET CSC FOR SPECIFIC USER AND ITS HISTORY
	GET_EMPLOYEE_SALARY_CATEGORIES_LIST = '@@administration/employeeSalaryCategories/GET_EMPLOYEE_SALARY_CATEGORIES_LIST',
	//! CSC ACTIONS
	// CLOSE CSC
	SET_EMPLOYEE_SALARY_CATEGORY_CLOSE = '@@administration/employeeSalaryCategories/SET_EMPLOYEE_SALARY_CATEGORY_CLOSE',
	// CANCEL CSC
	SET_EMPLOYEE_SALARY_CATEGORY_CANCEL = '@@administration/employeeSalaryCategories/SET_EMPLOYEE_SALARY_CATEGORY_CANCEL',
	// REQUEST APPROVAL CSC
	SET_EMPLOYEE_SALARY_CATEGORY_REQUEST_APPROVAL = '@@administration/employeeSalaryCategories/SET_EMPLOYEE_SALARY_CATEGORY_REQUEST_APPROVAL',
	// APPROVE CSC
	SET_EMPLOYEE_SALARY_CATEGORY_APPROVE = '@@administration/employeeSalaryCategories/SET_EMPLOYEE_SALARY_CATEGORY_APPROVE',
	// REJECT CSC
	SET_EMPLOYEE_SALARY_CATEGORY_REJECT = '@@administration/employeeSalaryCategories/SET_EMPLOYEE_SALARY_CATEGORY_REJECT',
	//! FORM GETS and POSTS
	// NEW CSC FORM - GET
	GET_NEW_EMPLOYEE_SALARY = '@@administration/employeeSalaryCategories/GET_NEW_EMPLOYEE_SALARY',
	// EDIT CSC FORM - GET
	GET_EDIT_EMPLOYEE_SALARY = '@@administration/employeeSalaryCategories/GET_EDIT_EMPLOYEE_SALARY',
	// NEW CSC FORM - POST
	SUBMIT_NEW_EMPLOYEE_SALARY = '@@administration/employeeSalaryCategories/SUBMIT_NEW_EMPLOYEE_SALARY',
	// EDIT CSC FORM - PUT
	SUBMIT_PUT_UPDATE_EMPLOYEE_SALARY = '@@administration/employeeSalaryCategories/SUBMIT_PUT_UPDATE_EMPLOYEE_SALARY',
	//! HELPERS
	// GET IRS VALUES
	SET_IRS_VALUE = '@@administration/employeeSalaryCategories/SET_IRS_VALUE',
	// GET CSC HISTORY DETAILS FOR MODAL
	SET_CSC_HISTORY_DETAILS = '@@administration/employeeSalaryCategories/SET_CSC_HISTORY_DETAILS',
	// RESET REDUCER
	RESET_FORM_DATA = '@@administration/employeeSalaryCategories/RESET_FORM_DATA',
	// AUDIT LOG
	GET_CSC_AUDIT_LOG = '@@administration/employeeSalaryCategories/GET_CSC_AUDIT_LOG',
	// CSC HISTORY EXPORT
	SET_CSC_HISTORY_EXPORT_EXCEL = '@@administration/employeeSalaryCategories/SET_CSC_HISTORY_EXPORT_EXCEL',
	// GET CONTRACTS LIST
	GET_CONTRACTS_LIST = '@@administration/employeeSalaryCategories/GET_CONTRACTS_LIST',
	// GET DOWNLOAD DOCUMENT
	GET_DOWNLOAD_DOCUMENT = '@@administration/administration/GET_DOWNLOAD_DOCUMENT',
	// GET RENEW CONTRACT
	GET_RENEW_CONTRACT = '@@administration/administration/GET_RENEW_CONTRACT',
	// POST RENEW CONTRACT
	POST_RENEW_CONTRACT = '@@administration/administration/POST_RENEW_CONTRACT'
}

export interface EmployeeSalaryState {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	// IGetCSCList
	readonly employeesList: IGetCSCList | null;
	readonly exportMultipleCSCs: IExportCSCsList | null;
	// IGetCSCHistory
	readonly employeeSalaryCategoriesList: any | null;

	// IGetNewCSC
	readonly newEmployeeSalary: IGetNewCSC | null;
	readonly employeesSalaryActions: any | null;
	readonly employeeSalarySummary: any | null;
	readonly proposalSummary: any | null;
	readonly cscDownload: unknown | null;

	readonly irsValue: IIrsValueGet | null;

	readonly cscAuditLog: ICscAuditLog | null;

	readonly contractsList: IContractsList | null;

	readonly downloadDocument: null;

	readonly renewContract: IRenewContract | null;

	readonly postRenewContract: IPostRenewContract | null;
}

export interface IEmployee {
	id: number;
	name: string;
	status: number;
	statusAsString: string;
	summaryData: string;
	username: string;
}
export interface IContractsList {
	data: IEmployee[];
}

export interface IExportCSCsList {
	userIDs: string[];
	usernames: string[];
	exportMethod: number;
}

export interface IRenewContractRow {
	contractStartDate: string;
	contractStartDateFormated: string;
	contractType: string;
	contractValidUntil?: string;
	contractValidUntilFormatted?: string;
	customerInContract: string;
	id: number;
	name: string;
	status: string;
	statusAsString: string;
	summaryData: string;
	username: string;
}
export interface IRenewContract {
	company: ICompanyInfo;
	contractTypes: IChoosableBaseInfo[];
}

export interface IPostRenewContract {
	contractStartDate: string;
	contractType: string;
	contractPosition: string;
	customerInContract: string;
	traineeType: string;
	contractEndDate: string;
}

export interface IGetCSCList {
	companies: ICompanyInfo[];
	defaultCompany: string;
	employees: IEmployeeInfo[];
}
export interface IGetCSCHistory {
	cscHistory: ICSCHistory[];
	employeeInfo: IEmployeeInfo;
	proposalsPerToken?: IProposalPerToken[];
	userInfo: IUserInfo;
}

export interface ICSCHistory {
	active: boolean;
	client: string;
	id: string;
	startingDate: string;
	xPHRAnnualNetValuewithPrize: string | number;
	xphrAnGrossCostPNoBen: string | number;
	xphrAnnualGrossAmount: string | number;
	xphrAnnualGrossCostPriz: string | number;
	xphrAnnualGrossValPrize: string | number;
	xphrAnnualGrossValue: string | number;
	xphrAnnualNetAmount: string | number;
	xphrAutonomousTaxPenalty: string | number;
	xphrBaseAmountTwelfths: string | number;
	xphrBaseIncome: string | number;
	xphrBuGoal1Amount: string | number;
	xphrBuGoal1Percent: string | number;
	xphrBuGoal2Amount: string | number;
	xphrBuGoal2Percent: string | number;
	xphrBuGoal3Amount: string | number;
	xphrBuGoal3Percent: string | number;
	xphrCommunicationPlafond: string | number;
	xphrCompanyGoal1Amount: string | number;
	xphrCompanyGoal1Percent: string | number;
	xphrCompanyGoal2Amount: string | number;
	xphrCompanyGoal2Percent: string | number;
	xphrCompanyGoal3Amount: string | number;
	xphrCompanyGoal3Percent: string | number;
	xphrCompanySocSecurity: string | number;
	xphrCreatePrize: false;
	xphrDailyCostMax: string | number;
	xphrDailyCostWithPrize: string | number;
	xphrEmployeeIrsTax: string | number;
	xphrEmployeeSocSecuTax: string | number;
	xphrExempInitialAmount: string | number;
	xphrFailuresAllow: string | number;
	xphrFctPercentage: string | number;
	xphrFgctPercentage: string | number;
	xphrFoodAllowance: string | number;
	xphrFoodAllowanceDays: string | number;
	xphrGrossamt: string | number;
	xphrHealthInsurance: string | number;
	xphrIndGoal1Amount: string | number;
	xphrIndGoal1Percent: string | number;
	xphrIndGoal2Amount: string | number;
	xphrIndGoal2Percent: string | number;
	xphrIndGoal3Amount: string | number;
	xphrIndGoal3Percent: string | number;
	xphrIntSubsistence: string | number;
	xphrIsmonthlyvalue: boolean;
	xphrLifeInsuranceValue: string | number;
	xphrMonthlyAdHInCost: string | number;
	xphrMonthlyAvgFoodValue: string | number;
	xphrMonthlyChildAlloVal: string | number;
	xphrMonthlyCommPlCost: string | number;
	xphrMonthlyGrossCost: string | number;
	xphrMonthlyGrossCostPri: string | number;
	xphrMonthlyGrossVal: string | number;
	xphrMonthlyGrossValPriz: string | number;
	xphrMonthlyHealthInCost: string | number;
	xphrMonthlyHolidayValue: string | number;
	xphrMonthlyIntValue: string | number;
	xphrMonthlyLaptopCost: string | number;
	xphrMonthlyNationalValue: string | number;
	xphrMonthlyNetAmount: string | number;
	xphrMonthlyNetWithPrize: string | number;
	xphrMonthlyOtherBen1V: string | number;
	xphrMonthlyOtherBen2V: string | number;
	xphrMonthlyOtherBen3V: string | number;
	xphrMonthlyStCFoodAllo: string | number;
	xphrMonthlyStComPlafond: string | number;
	xphrMonthlyStFoodAllow: string | number;
	xphrMonthlyStHeaInCost: string | number;
	xphrMonthlyStLaptopCost: string | number;
	xphrMonthlyStWorkIns: string | number;
	xphrMonthlyTransValue: string | number;
	xphrMonthlyWorkInECost: string | number;
	xphrMonthlyXmasValue: string | number;
	xphrNationalSubsistence: string | number;
	xphrNetamt: string | number;
	xphrOccupHealthcareCost: string | number;
	xphrOtherBenefits: string | number;
	xphrPrizeIrsTax: string | number;
	xphrPrizeValue: string | number;
	xphrProjectGoal1Amount: string | number;
	xphrProjectGoal1Percent: string | number;
	xphrProjectGoal2Amount: string | number;
	xphrProjectGoal2Percent: string | number;
	xphrProjectGoal3Amount: string | number;
	xphrProjectGoal3Percent: string | number;
	xphrSocSecurityExemption: string | number;
	xphrTaxableBaseAmount: string | number;
	xphrTotalBenefits: string | number;
	xphrTrainingCosts: string | number;
	xphrTravelExpenses: string | number;
	xphrWorkExempion: string | number;
	xphrWorkExempionValue: string | number;
	xphrWorkInsurance: string | number;
	xphrWorkingMaterials: string | number;
}

export interface IEmployeeInfo {
	careerLevel: {
		xphrProfileEligibleRoleData: {
			name: string;
		};
		xphrProfileRoleLevelData: {
			name: string;
			careerSubLevel: string;
		};
	};
	businessPartnerCategory: string;
	client: string;
	hash?: string;
	id: string;
	lastCscInProgressStatus: string | number;
	name: string;
	searchKey: string;
	taxID: string;
	xPHRAdOrgBunit: string;
	xPHRAdOrgBunit$_identifier: string;
	xPHRChildrenNUMBER: string | number;
	xPHRCitizenCardExpirationDate: string;
	xPHRDateOfBirth: string;
	xPHRIDNUMBER: string;
	xPHRMaritalStatus: string;
	xPHRSOCIALSECURITYNUMBER: string;
	xphrAdOrgDepartment: string;
	xphrAdOrgDepartment$_identifier: string;
	xphrAdOrgDivision: string;
	xphrAdOrgDivision$_identifier: string;
	xphrDisable: false;
	xphrEmployeeOffice: string;
	xphrEmployeeWorkOffice: string;
	xphrGender: string;
	xphrHoldersNumber: string | number;
	xphrIdControlDigits: string;
	xphrNationality: string;
	xphrNumberOfDependents: string | number;
	userCompany: string;
}

export interface IUserInfo {
	companyCode: string;
	department: string;
	email: string;
	firstName: string;
	lastName: string;
	officeName: string;
	phone: string[];
	username: string;
}

export interface IGetNewCSC {
	proposal?: ICollaborationProposal;
	code?: string | number;
	collaborationRoles: IChoosableBaseInfo[];
	company: ICompanyInfo;
	cscPrefilledBenefits: ICSCPrefilledBenefits;
	currentCSC: ICurrentCSC;
	employeeCurrentCareer: ICurrentCareer;
	employeeCurrentContract: ICurrentContract;
	employeeInfo: IEmployeeInfo;
	foodAllowanceTypes: IChoosableBaseInfo[];
	maritalStatus: IChoosableBaseInfo[];
	prizeBonusGoalTypes: IChoosableBaseInfo[];
	allEmployeeCSCs: unknown[];
	token: string;
	userInfo: IUserInfo;
}

export interface ICSCPrefilledBenefits {
	communicationsPlafondValue: string | number;
	hasCommunicationsPlafond: boolean;
	hasHealthInsurance: boolean;
	healthInsuranceValue: string | number;
	includeFamilyMembers: boolean;
	numberOfFamilyMembers: string | number;
}
export interface ICurrentContract {
	id: string;
	documentNo: string;
	startDate: string;
	startReason: string;
	validUntil: string;
	endReason: string;
	irct: string;
	workingRegime: string;
	pNTTime: string;
	workTimeDuration: string;
	contractType: string;
	contractTypeAString: string;
	salaryCategory: string;
	salaryCategory$_identifier: string;
}

export interface ICurrentCareer {
	id: string;
	profile: string;
	profile$_identifier: string;
	startDate: string;
	xphrProfileEligibleRole: string;
	xphrProfileRoleLevel: string;
}
export interface ICurrentCSC {
	active: boolean;
	businessPartner: string;
	client: string;
	id: string;
	organization: string;
	salaryCategory: string;
	startingDate: string;
	xPHRAnnualNetValuewithPrize: string | number;
	xphrAnGrossCostPNoBen: string | number;
	xphrAnnualGrossAmount: string | number;
	xphrAnnualGrossCostPriz: string | number;
	xphrAnnualGrossValPrize: string | number;
	xphrAnnualGrossValue: string | number;
	xphrAnnualNetAmount: string | number;
	xphrAutonomousTaxPenalty: string | number;
	xphrBaseAmountTwelfths: string | number;
	xphrBaseIncome: string | number;
	xphrBuGoal1Amount: string | number;
	xphrBuGoal1Percent: string | number;
	xphrBuGoal2Amount: string | number;
	xphrBuGoal2Percent: string | number;
	xphrBuGoal3Amount: string | number;
	xphrBuGoal3Percent: string | number;
	xphrCommunicationPlafond: string | number;
	xphrCompanyGoal1Amount: string | number;
	xphrCompanyGoal1Percent: string | number;
	xphrCompanyGoal2Amount: string | number;
	xphrCompanyGoal2Percent: string | number;
	xphrCompanyGoal3Amount: string | number;
	xphrCompanyGoal3Percent: string | number;
	xphrCompanySocSecurity: string | number;
	xphrCreatePrize: false;
	xphrDailyCostMax: string | number;
	xphrDailyCostWithPrize: string | number;
	xphrDependentsNumber: string | number;
	xphrEmployeeIrsTax: string | number;
	xphrEmployeeSocSecuTax: string | number;
	xphrExempDateEnd: string;
	xphrExempDateStart: string;
	xphrExempInitialAmount: string | number;
	xphrFailuresAllow: string | number;
	xphrFctPercentage: string | number;
	xphrFgctPercentage: string | number;
	xphrFoodAllowType: string;
	xphrFoodAllowance: string | number;
	xphrFoodAllowanceDays: string | number;
	xphrGrossamt: string | number;
	xphrHealthInsurance: string | number;
	xphrHoldersNumber: string | number;
	xphrIndGoal1Amount: string | number;
	xphrIndGoal1Percent: string | number;
	xphrIndGoal2Amount: string | number;
	xphrIndGoal2Percent: string | number;
	xphrIndGoal3Amount: string | number;
	xphrIndGoal3Percent: string | number;
	xphrIntSubsistDays: string | number;
	xphrIntSubsistence: string | number;
	xphrIsmonthlyvalue: boolean;
	xphrLifeInsuranceValue: string | number;
	xphrLocalBpGroup: string;
	xphrMonthlyAdHInCost: string | number;
	xphrMonthlyAvgFoodValue: string | number;
	xphrMonthlyChildAlloVal: string | number;
	xphrMonthlyCommPlCost: string | number;
	xphrMonthlyGrossCost: string | number;
	xphrMonthlyGrossCostPri: string | number;
	xphrMonthlyGrossVal: string | number;
	xphrMonthlyGrossValPriz: string | number;
	xphrMonthlyHealthInCost: string | number;
	xphrMonthlyHolidayValue: string | number;
	xphrMonthlyIntValue: string | number;
	xphrMonthlyLaptopCost: string | number;
	xphrMonthlyNationalValue: string | number;
	xphrMonthlyNetAmount: string | number;
	xphrMonthlyNetWithPrize: string | number;
	xphrMonthlyOtherBen1V: string | number;
	xphrMonthlyOtherBen2V: string | number;
	xphrMonthlyOtherBen3V: string | number;
	xphrMonthlyStCFoodAllo: string | number;
	xphrMonthlyStComPlafond: string | number;
	xphrMonthlyStFoodAllow: string | number;
	xphrMonthlyStHeaInCost: string | number;
	xphrMonthlyStLaptopCost: string | number;
	xphrMonthlyStWorkIns: string | number;
	xphrMonthlyTransValue: string | number;
	xphrMonthlyWorkInECost: string | number;
	xphrMonthlyXmasValue: string | number;
	xphrNatSubsistDays: string | number;
	xphrNationalSubsistence: string | number;
	xphrNetamt: string | number;
	xphrOccupHealthcareCost: string | number;
	xphrOtherBenefits: string | number;
	xphrPrizeIrsTax: string | number;
	xphrPrizeValue: string | number;
	xphrProfile: string;
	xphrProjectGoal1Amount: string | number;
	xphrProjectGoal1Percent: string | number;
	xphrProjectGoal2Amount: string | number;
	xphrProjectGoal2Percent: string | number;
	xphrProjectGoal3Amount: string | number;
	xphrProjectGoal3Percent: string | number;
	xphrSocSecurityExemption: string | number;
	xphrTaxableBaseAmount: string | number;
	xphrTaxzone: string;
	xphrTotalBenefits: string | number;
	xphrTrainingCosts: string | number;
	xphrTravelExpenses: string | number;
	xphrWorkExempion: string | number;
	xphrWorkExempionValue: string | number;
	xphrWorkInsurance: string | number;
	xphrWorkingMaterials: string | number;
}
export interface IGetEditCSC {
	collaborationRoles: IChoosableBaseInfo[];
	company: ICompanyInfo;
	employeeInfo: IEmployeeInfo;
	foodAllowanceTypes: IChoosableBaseInfo[];
	maritalStatus: IChoosableBaseInfo[];
	prizeBonusGoalTypes: IChoosableBaseInfo[];
	proposal: ICollaborationProposal;
	userInfo: IUserInfo;
}
export interface IPostPutCSC {
	additionalExpenses?: string;
	annualGrossValue: string | number;
	annualPrizeIRS: string | number;
	baseValue: string;
	benefitsComments?: string;
	businessUnit: string;
	careerAndFinancialNotes: string;
	careerJobRole: string;
	careerJobRoleLevel: string;
	careerManager: string;
	careerPosition: string;
	christmasTwelfth: string | number;
	collaborationRole: string;
	collaborationType: string;
	commsPlafond?: string;
	commsPlafondFlag: boolean;
	company: string;
	createPrizeBonusErp: boolean;
	department: string;
	dependentsNumber: string | number;
	disabled: boolean;
	division: string;
	eligiblePrizeValue?: string | number;
	email: string;
	familyMembersComments?: string;
	foodAllowance: string | number;
	foodAllowanceDays: string | number;
	foodAllowanceType: string;
	grossBaseValue: string | number;
	hasTwelfths: boolean;
	healthInsuranceCheck: boolean;
	healthInsuranceValue?: string | number;
	holdersNumber: string;
	ihtPercentage?: string | number;
	ihtValue: string | number;
	importFromAnotherProposal: boolean;
	includeFamilyMembers: boolean;
	internationalAllowance?: string | number;
	internationalAllowanceDays?: string;
	irsToApply: string | number;
	irsTwelfth: string | number;
	jiraCandidateApplication: string;
	lifeInsurance?: string;
	maritalStatus: string;
	monthsApplicable?: string;
	name: string;
	nationalAllowance?: string;
	nationalAllowanceDays?: string;
	numberMembersDep?: string;
	nurseryCheck?: string;
	otherBenefitsList?: IOtherBenefit[];
	otherbenefits: boolean;
	prizeBonus?: string;
	prizeBonusGoals?: IPrizeGoal[];
	prizeBonusPercentage?: string | number;
	prizeValue: string;
	proposalId?: string;
	softwareDevelopmentCenter: boolean;
	startDate: string;
	token?: string | null;
	totalComponentsPrizePercentage: string | number;
	totalComponentsPrizeValue: string | number;
	totalTaxableValue: string | number;
	travelPass?: string;
	tsuExemption: boolean;
	username: string;
	vacationsTwelfth: string | number;
	workScheduleExemptionValue: string | number;
	socialSecurityNumber: string | number;
}

export interface IGetCSCHistoryDetails {
	csc: ICSCHistoryDetails;
}
export interface ICSCHistoryDetails {
	active: true;
	businessPartner: string;
	client: string;
	id: string;
	organization: string;
	salaryCategory: string;
	startingDate: string;
	xPHRAnnualNetValuewithPrize: string | number;
	xphrAnGrossCostPNoBen: string | number;
	xphrAnnualGrossAmount: string | number;
	xphrAnnualGrossCostPriz: string | number;
	xphrAnnualGrossValPrize: string | number;
	xphrAnnualGrossValue: string | number;
	xphrAnnualNetAmount: string | number;
	xphrAutonomousTaxPenalty: string | number;
	xphrBaseAmountTwelfths: string | number;
	xphrBaseIncome: string | number;
	xphrBuGoal1Amount: string | number;
	xphrBuGoal1Percent: string | number;
	xphrBuGoal2Amount: string | number;
	xphrBuGoal2Percent: string | number;
	xphrBuGoal3Amount: string | number;
	xphrBuGoal3Percent: string | number;
	xphrCommunicationPlafond: string | number;
	xphrCompanyGoal1Amount: string | number;
	xphrCompanyGoal1Percent: string | number;
	xphrCompanyGoal2Amount: string | number;
	xphrCompanyGoal2Percent: string | number;
	xphrCompanyGoal3Amount: string | number;
	xphrCompanyGoal3Percent: string | number;
	xphrCompanySocSecurity: string | number;
	xphrCreatePrize: false;
	xphrDailyCostMax: string | number;
	xphrDailyCostWithPrize: string | number;
	xphrDependentsNumber: string | number;
	xphrEmployeeIrsTax: string | number;
	xphrEmployeeSocSecuTax: string | number;
	xphrExempInitialAmount: string | number;
	xphrFailuresAllow: string | number;
	xphrFctPercentage: string | number;
	xphrFgctPercentage: string | number;
	xphrFoodAllowType: string;
	xphrFoodAllowance: string | number;
	xphrFoodAllowanceDays: string | number;
	xphrGrossamt: string | number;
	xphrHealthInsurance: string | number;
	xphrHoldersNumber: string | number;
	xphrIndGoal1Amount: string | number;
	xphrIndGoal1Percent: string | number;
	xphrIndGoal2Amount: string | number;
	xphrIndGoal2Percent: string | number;
	xphrIndGoal3Amount: string | number;
	xphrIndGoal3Percent: string | number;
	xphrIntSubsistDays: string | number;
	xphrIntSubsistence: string | number;
	xphrIsmonthlyvalue: boolean;
	xphrLifeInsuranceValue: string | number;
	xphrLocalBpGroup: string;
	xphrMonthlyAdHInCost: string | number;
	xphrMonthlyAvgFoodValue: string | number;
	xphrMonthlyChildAlloVal: string | number;
	xphrMonthlyCommPlCost: string | number;
	xphrMonthlyGrossCost: string | number;
	xphrMonthlyGrossCostPri: string | number;
	xphrMonthlyGrossVal: string | number;
	xphrMonthlyGrossValPriz: string | number;
	xphrMonthlyHealthInCost: string | number;
	xphrMonthlyHolidayValue: string | number;
	xphrMonthlyIntValue: string | number;
	xphrMonthlyLaptopCost: string | number;
	xphrMonthlyNationalValue: string | number;
	xphrMonthlyNetAmount: string | number;
	xphrMonthlyNetWithPrize: string | number;
	xphrMonthlyOtherBen1V: string | number;
	xphrMonthlyOtherBen2V: string | number;
	xphrMonthlyOtherBen3V: string | number;
	xphrMonthlyStCFoodAllo: string | number;
	xphrMonthlyStComPlafond: string | number;
	xphrMonthlyStFoodAllow: string | number;
	xphrMonthlyStHeaInCost: string | number;
	xphrMonthlyStLaptopCost: string | number;
	xphrMonthlyStWorkIns: string | number;
	xphrMonthlyTransValue: string | number;
	xphrMonthlyWorkInECost: string | number;
	xphrMonthlyXmasValue: string | number;
	xphrNatSubsistDays: string | number;
	xphrNationalSubsistence: string | number;
	xphrNetamt: string | number;
	xphrOccupHealthcareCost: string | number;
	xphrOtherBenefits: string | number;
	xphrPrizeIrsTax: string | number;
	xphrPrizeValue: string | number;
	xphrProjectGoal1Amount: string | number;
	xphrProjectGoal1Percent: string | number;
	xphrProjectGoal2Amount: string | number;
	xphrProjectGoal2Percent: string | number;
	xphrProjectGoal3Amount: string | number;
	xphrProjectGoal3Percent: string | number;
	xphrSocSecurityExemption: string | number;
	xphrTaxableBaseAmount: string | number;
	xphrTotalBenefits: string | number;
	xphrTrainingCosts: string | number;
	xphrTravelExpenses: string | number;
	xphrWorkExempion: string | number;
	xphrWorkExempionValue: string | number;
	xphrWorkInsurance: string | number;
	xphrWorkingMaterials: string | number;
}

export interface IAuditLogEntry {
	date: string;
	id: number;
	newStatus: string;
	newStatusId: number;
	previousStatus?: string;
	previousStatusId: number;
	proposalId: number;
	username: string;
}

export interface ICscAuditLog {
	auditLog: IAuditLogEntry[];
}

export interface IDownloadDocument {
	exportType: string;
}
