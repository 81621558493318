import { restCall } from 'lib/handlers/restHandler';

import { OriginalResponse, Success, GET, POST } from 'lib/handlers/requestTypes';

export const fetchNewAdmission = async (): Promise<OriginalResponse | Success> =>
	restCall(GET, '/admin/admissions/new');

export const postNewAdmission = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/admissions/new', payload);

// Admission Sheet
export const fetchAdmissionSheetData = async (token: string): Promise<OriginalResponse> =>
	restCall(GET, `/public/admissionsheet/${token}`) as unknown as OriginalResponse;

export const postAdmissionSheet = async (token: string, payload: FormData): Promise<OriginalResponse> =>
	restCall(POST, `/public/admissionsheet/${token}`, payload, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}) as unknown as OriginalResponse;
