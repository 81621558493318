/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, Fragment, FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography, Button, IconButton, FormHelperText } from '@mui/material';
import { Email, Phone, Edit, Add, Delete } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { parseContactToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { Dialog } from 'xpand-ui/core';
import { Input } from 'xpand-ui/forms';
import Adicionar from '../../../../../../../assets/icons/Adicionar.svg';

//* TYPINGS
import { IUserInfo } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';

//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { getLSField } from 'lib/utils/cookies';

let indexHelper: any = null;
interface IPhoneList {
	contacts: string[];
}
interface IContacts {
	userProfile: IUserInfo;
	handleApiSubmit: any;
}

const Contacts: FC<IContacts> = ({ userProfile, handleApiSubmit }) => {
	const classes = useStyles();
	const [contactEditModal, setOpenContactEditModal] = useState<boolean | null>(null);

	const {
		handleSubmit,
		control,
		reset,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		reset({ contacts: userProfile?.ldapData?.phone || [] });
	}, [userProfile]);

	const phones: string[] = watch().contacts || [];

	const onSubmit = (data: IPhoneList, manualPayload: any) => {
		const payload = data || manualPayload;
		const newPhonePayload = { [indexHelper]: null };

		if (JSON.stringify(payload.contacts) !== JSON.stringify(userProfile?.ldapData?.phone)) {
			// eslint-disable-next-line no-console

			handleApiSubmit([
				{
					type: 'contacts',
					data: {
						phoneNumbers: payload.contacts.filter((e, i) => !newPhonePayload[i]).filter(e => e !== null)
					}
				}
			]);
			setOpenContactEditModal(null);
		} else {
			// eslint-disable-next-line no-console
			addNotification('info', 'Nothing to Update');
		}

		return null;
	};

	const closeContactsModal = () => {
		setOpenContactEditModal(null);
	};

	// PAGE FOOTER ACTIONS

	const modalFooterActions = [
		{
			id: 'back',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				closeContactsModal();
				reset({ contacts: userProfile?.ldapData?.phone || [] });
			}
		},
		{
			id: 'submit',
			label: 'Save Changes',
			onClick: () => ({}),
			type: 'submit',
			form: 'form-profile',
			variant: 'contained'
		}
	];

	const removePhone = (index: number) => {
		// Update form lib object
		const payload = watch();

		payload.contacts.splice(index, 1);

		reset(payload);
	};

	const addPhone = () => {
		const payload = watch();

		const newIndex = payload.contacts.length;

		// Update form lib object
		payload.contacts = [...(payload.contacts || userProfile.ldapData?.phone)];
		payload.contacts[newIndex] = '+351 ';
		reset(payload);
	};

	const duplicatedError = errors.contacts && typeof errors.contacts === 'object' && errors.contacts;
	const phonesToList = userProfile.ldapData?.phone;

	let contactsNumberIcon = 0;
	return (
		<Grid item {...sizes[12]} className={classes.leftCardSection}>
			{/* EMAIL ICON */}
			<Button
				size="large"
				style={{ justifyContent: 'flex-start', paddingLeft: '5px', marginBottom: '5px' }}
				variant="text"
				fullWidth
				target="_top"
				color="primary"
				rel="noopener noreferrer"
				startIcon={<Email className={classes.iconGrayed} />}
				href={`mailto:${userProfile.ldapData?.email}`}>
				<Typography variant="body1">{userProfile.ldapData?.email}</Typography>
			</Button>

			<Dialog
				maxWidth="xs"
				modal={{
					open: Boolean(contactEditModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenContactEditModal(false);
					},
					content: (
						<div>
							{/* CONTACT LIST */}
							<form id="form-profile" onSubmit={handleSubmit(onSubmit)}>
								{phones.map((e, i) => {
									const name = `contacts[${i}]`;

									return (
										e !== null &&
										e !== 'null' && (
											<Typography
												// eslint-disable-next-line react/no-array-index-key
												key={`phone_${i}`}
												component="span"
												variant="body1"
												className={classes.phoneLine}>
												{/* PHONE INPUT */}

												<Input
													required
													name={name}
													onKeyPress={event => {
														// if (!/^\+?\d*$/.test(event.key)) {
														if (!/[0-9+ ]/.test(event.key)) {
															event.preventDefault();
														}
													}}
													control={control}
													errors={errors}
												/>

												{/* DELETE PHONE BUTTON */}
												<IconButton
													className={classes.phoneLineActionDelete}
													onClick={() => {
														indexHelper = i;
														removePhone(i);
													}}>
													<Delete />
												</IconButton>
											</Typography>
										)
									);
								})}
							</form>
							{duplicatedError && (
								<FormHelperText error>{(duplicatedError as any)?.message}</FormHelperText>
							)}
							{/* ADD NEW CONTACT */}
							{phones.filter(e => e !== null).length < 1 && (
								<Button
									key="phone_add"
									size="large"
									variant="text"
									color="primary"
									endIcon={<Adicionar />}
									onClick={addPhone}>
									<Typography variant="body1">Add new Phone</Typography>
								</Button>
							)}
						</div>
					)
				}}
				title="UPDATE PHONE NUMBER"
				actions={modalFooterActions}
				scroll="body"
			/>
			{phonesToList.map((e, i) => {
				if (e !== null) {
					return (
						// eslint-disable-next-line react/no-array-index-key
						<Fragment key={`phone_${i}`}>
							<Typography component="span" variant="body1" className={classes.phoneLine}>
								<Phone className={classes.iconGrayed} style={{ marginRight: '10px' }} />
								{e !== 'null' ? parseContactToShow(e as unknown as number) : ''}
								{/* eslint-disable-next-line no-plusplus */}
								{contactsNumberIcon++ === 0 && !getLSField('impersonate_userInfo') && (
									<IconButton
										color="yale"
										onClick={() => {
											setOpenContactEditModal(true);
										}}>
										<Edit />
									</IconButton>
								)}
							</Typography>
						</Fragment>
					);
				}
				return null;
			})}
		</Grid>
	);
};

export default Contacts;
