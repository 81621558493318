export const tabs = [
	{
		id: 'approved',
		label: 'Approved'
	},
	{
		id: 'pending',
		label: 'Pending'
	}
];
