import { Dispatch, SetStateAction, createContext, ReactNode } from 'react';

export interface ITitle {
	title: string;
	icon: ReactNode;
	optionalAvatar?: string;
	onClose?: () => void;
}
export interface IPageTitle {
	title: ITitle;
	setTitle: Dispatch<SetStateAction<ITitle>>;
}

export const TitleContext = createContext<IPageTitle>({
	title: {
		title: '',
		icon: null,
		onClose: () => {}
	},
	setTitle: () => {}
});
