//* EXTERNAL LIBS
import React, { FC, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter } from 'xpand-ui/core';
import { Input } from 'xpand-ui/forms';

//* TYPINGS
import { IRejectProposalPost } from 'typings/store/admin/proposals';
import { IUserPermission } from 'typings/store/systemTypes';
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { Roles } from 'lib/roles';
import { getLSField } from 'lib/utils/cookies';
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';
import { RejectProposalProps } from '.';

//* COMPONENT INTERFACES
interface IRejectProposal extends RejectProposalProps {
	match: Match<{
		token: string;
		proposalId: string | number;
		manager: string;
	}>;
	goToPage: (path: string) => void;
}

const spacing = 3;

const notificationPayload = {
	area: 'Recruitment',
	section: 'Collaboration Proposals'
};

//* COMPONENT
const RejectProposal: FC<IRejectProposal> = ({
	match,
	goToPage,
	proposals,
	submitProposalReject,
	setNotificationAsRead
}) => {
	const classes = useStyles();
	const { loading, proposalsActions } = proposals;
	const { params } = match;
	const token = params?.token;
	const proposalId = params?.proposalId;
	const manager = params?.manager;
	const userPermissions = getLSField('userPermissions');
	const userPerms = userPermissions && JSON.parse(userPermissions);

	useEffect(() => {
		if (proposalsActions) {
			const res = userPerms.some(
				(e: IUserPermission) =>
					e.displayName === Roles.CP_ROLE_TALENT_ACQUISITION ||
					e.displayName === Roles.CP_ROLE_TALENT_ACQUISITION_ADMIN ||
					e.displayName === Roles.CP_ROLE_SYSTEM_ADMIN
			);
			if (res) {
				goToPage(`/admin/recruitment/proposals/${token}`);
			} else {
				goToPage('/profile/personalFiscal');
			}
		}
	}, [proposalsActions]);

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const onSubmit = (payload: IRejectProposalPost) => {
		submitProposalReject(token, proposalId as number, manager, payload);
		setNotificationAsRead(manager, notificationPayload);
	};

	// PAGE FOOTER ACTIONS
	const footerActions = useMemo(
		() => [
			{
				id: 'back',
				label: 'Back',
				variant: 'text',
				left: true,
				startIcon: 'back',
				onClick: () => goToPage('/admin/recruitment/proposals')
			},
			{
				id: 'submit',
				label: 'Submit',
				onClick: () => ({}),
				type: 'submit',
				form: 'form-feedback',
				variant: 'contained',
				disabled: false
			}
		],
		[]
	);

	const isLoading = false;

	if (isLoading) return <LoadingOverlay />;

	// if sheet is already submitted, a different screen must be shown
	return (
		<>
			{loading && <LoadingOverlay />}
			<div className={classes.feedbackCard}>
				<form id="form-feedback" onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={spacing}>
						<Grid item xs={12}>
							<Input
								label="Reasons for rejection"
								name="rejectionReason"
								multiline
								required
								minRows={5}
								maxRows={8}
								control={control}
								errors={errors}
							/>
						</Grid>
					</Grid>
				</form>
			</div>
			<ActionFooter actions={footerActions} />
		</>
	);
};

export default withLayout(RejectProposal);
