import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getResignationList,
	clearAdmissionsError,
	exportCertificate,
	exportReceipt
} from 'store/administration/admissions/thunks';
import { setResignationManagementPageFilter } from 'store/filters/actions';
import { AppState } from 'typings/state';

import ListUsers from './ResignationMngt';

const mapStateToProps = ({ admissions, filters }: AppState) => ({ admissions, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getResignationList,
			clearAdmissionsError,
			exportCertificate,
			exportReceipt,
			setResignationManagementPageFilter
		},
		dispatch
	);

export type ResignationMngtProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
