// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			padding: '50px 10px',
			marginBottom: '90px'
		},
		tabsRoot: {
			// padding: '5px'
		},
		rows: {
			width: '100%'
		},
		statusBar: {
			// direction: 'row',
			justify: 'center',
			alignItems: 'center',
			display: 'flex'
		},
		statusIcon: {
			marginRight: '10px'
		},
		statusHidden: {
			color: 'transparent',
			marginRight: '10px'
		},
		backButtonTablet: {
			marginTop: '60px'
		},
		centerInParent: {
			margin: 'auto',
			display: 'block'
		}
	})
);

export { useStyles };
