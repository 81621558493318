import React from 'react';

import ProjectProviders from 'lib/hocs/ProjectProviders';
import { store, history } from './store';

/** NOTIFICATIONS */
import 'assets/styles/react-notifications.css';
import 'animate.css/animate.min.css';

/** PLANNER SCHEDULER */
import '@bryntum/scheduler/scheduler.material.css';
import 'assets/styles/scheduler.xpand.css';

/** PROJECT MAIN CSS */
import 'assets/styles/main.css';

const App: React.FC = () => {
	const timestamp = new Date().getTime();
	return (
		<ProjectProviders {...{ store, history }}>
			{/* Append the timestamp as a version query parameter to your asset URLs */}
			<link rel="stylesheet" href={`/assets/styles/react-notifications.css?version=${timestamp}`} />
			<link rel="stylesheet" href={`/assets/styles/scheduler.xpand.css?version=${timestamp}`} />
			<link rel="stylesheet" href={`/assets/styles/main.css?version=${timestamp}`} />
		</ProjectProviders>
	);
};

export default App;
