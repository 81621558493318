export enum NotificationsActions {
	GET_NOTIFICATIONS = '@@system/GET_NOTIFICATIONS',
	SET_NOTIFICATION = '@@system/SET_NOTIFICATION',
	MARK_NOTIFICATION_AS_READ = '@@system/MARK_NOTIFICATION_AS_READ',
	DELETE_NOTIFICATION = '@@system/DELETE_NOTIFICATION',
	POST_DELETE_ALL_USER_NOTIFICATIONS = '@@system/POST_DELETE_ALL_USER_NOTIFICATIONS'
}

export interface Notification {
	id: string;
	appUserId: string;
	area: string;
	isRead: any;
	createdAt: any;
	section: string;
}
export type NotificationsState = {
	readonly notificationsList: Notification[] | null;
};
