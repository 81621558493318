import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	groupCardProposals: {
		minWidth: '900px',
		width: '100%'
	},
	//* WEB
	[theme.breakpoints.up('md')]: {
		groupCardProposals: {
			minWidth: '600px'
		}
	},
	//* TABLET
	[theme.breakpoints.down('md')]: {
		groupCardProposals: {
			minWidth: '500px'
		}
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		groupCardProposals: {
			minWidth: '400px'
		}
	},
	items: {
		marginLeft: 'auto'
	},
	formContainer: {
		padding: '50px',
		alignItems: 'flex-start'
	},
	formContainerNoPaddingBotton: {
		padding: '50px 50px 0px 50px',
		alignItems: 'flex-start'
	}
}));

export { useStyles };
