/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Button, Grid } from '@mui/material';
import { Publish, Check } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, Input, InputLabel, DatePicker, Checkbox } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { IAdmissionSheetTabs } from '../../AdmissionSheet';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';

//* COMPONENT INTERFACES
interface IQualifications extends IAdmissionSheetTabs {
	showUploadQualificationsCertificateFile: boolean;
	selectedFilesFormData: FormData;
	getFileName: (fileType: string) => string;
	uploadQualificationCertificate: () => void;
}

//* COMPONENT
const Qualifications: FC<IQualifications> = ({
	control,
	errors,
	AdmissionSheetDataOptions,
	FileUploadOptions,
	// this tab
	showUploadQualificationsCertificateFile,
	selectedFilesFormData,
	getFileName,
	uploadQualificationCertificate,
	formFields,
	isEmployee
}) => {
	const classes = useStyles();

	const companyInfo = useSelector(state => state.company);
	return (
		<Grid container spacing={4} className={classes.root}>
			{formFields.formFieldsConfig.courseQualificationId && (
				<Grid item {...sizes[6]}>
					<Select
						name="courseQualificationId"
						label="Course Qualification"
						options={AdmissionSheetDataOptions.courseQualifications}
						control={control}
						errors={errors}
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.courseInstitution && (
				<Grid item {...sizes[6]}>
					<Input
						name="courseInstitution"
						label="Institution Name"
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.educationCode && (
				<Grid item {...sizes[6]}>
					<Select
						name="educationCode"
						label="Education"
						options={AdmissionSheetDataOptions.educationCodes}
						control={control}
						errors={errors}
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.courseStartDate && (
				<Grid item {...sizes[6]}>
					<DatePicker
						name="courseStartDate"
						label="Start Date"
						control={control}
						errors={errors}
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.educationStatus && (
				<Grid item {...sizes[6]}>
					<Select
						name="educationStatus"
						label="Education Status"
						options={AdmissionSheetDataOptions.educationStatus}
						control={control}
						errors={errors}
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.courseEndDate && (
				<Grid item {...sizes[6]}>
					<DatePicker name="courseEndDate" label="End Date" control={control} errors={errors} />
				</Grid>
			)}
			<Grid item {...sizes[6]}>
				{formFields.formFieldsConfig.courseName && (
					<Input
						name="courseName"
						label="Course Name"
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
						control={control}
						errors={errors}
					/>
				)}
				{formFields.formFieldsConfig.coursePostBologna && (
					<Checkbox name="coursePostBologna" label="POST BOLOGNA COURSE" control={control} errors={errors} />
				)}
			</Grid>
			{formFields.formFieldsConfig.careerStartDate && (
				<Grid item {...sizes[6]}>
					<DatePicker
						name="careerStartDate"
						label="Career Start Date"
						helperText={`Insert the date you started your first job. If at ${companyInfo.companyText} is your first job, insert the date you agreed that would be you first day`}
						control={control}
						errors={errors}
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
					/>
				</Grid>
			)}
			<Grid item xs={12}>
				{formFields.formFieldsConfig.professionalExperience && (
					<Input
						label="PROFESSIONAL EXPERIENCE"
						name="professionalExperience"
						helperText="Small text describing professional experience and interests (written in 3rd person, and about a 2 paragraph length)"
						multiline
						minRows={5}
						maxRows={8}
						control={control}
						errors={errors}
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
					/>
				)}
				{showUploadQualificationsCertificateFile && formFields.formFieldsConfig.hasQcDoc && (
					<Button
						color="primary"
						className={classes.attachmentButton}
						startIcon={
							!selectedFilesFormData.get(FileUploadOptions.QUALIFCERTIFICATE) ? <Publish /> : <Check />
						}
						variant="outlined"
						onClick={uploadQualificationCertificate}>
						Qualifications Certificate
					</Button>
				)}
				{!!selectedFilesFormData.get(FileUploadOptions.QUALIFCERTIFICATE) && (
					<InputLabel className={classes.buttonInputLabel}>
						{/* {selectedFilesFormData.get(FileUploadOptions.QUALIFCERTIFICATE).name} */}
						{getFileName(FileUploadOptions.QUALIFCERTIFICATE)}
					</InputLabel>
				)}
			</Grid>
		</Grid>
	);
};

export default Qualifications;
