// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		avatarContainer: {
			width: '175px',
			height: '175px',
			margin: 'auto',
			position: 'relative',
			overflow: 'hidden',
			borderRadius: '50%'
		},
		avatarImage: {
			display: 'inline',
			margin: '0 auto',
			height: '175px',
			width: 'auto'
		},
		editButton: {
			background: 'white',
			margin: '-50px -100px 0 0',
			boxShadow: '0px 3px 18px #c8c9ca',
			'&:hover': {
				background: '#f3f3f3'
			}
		},
		profileActionLabel: {
			fontSize: '16px',
			color: '#536B7A',
			textTransform: 'none'
		},
		box: {
			display: 'block',
			marginLeft: 'auto',
			marginRight: 'auto',
			width: '166px',
			height: '166px',
			objectFit: 'cover',
			objectPosition: '50% 35%',
			borderStyle: 'dashed',
			borderWidth: '2px',
			borderColor: '#CAD5DD'
		},
		hideInput: {
			display: 'none'
		},
		uploadImage: {
			width: '94px',
			marginTop: '30px',
			marginLeft: 'auto',
			marginRight: 'auto'
		},
		uploadImageText: {
			color: '#3D464D',
			margin: 'auto',
			fontWeight: '600',
			whiteSpace: 'pre-line'
		},
		closeButton: {
			position: 'absolute',
			top: '110px',
			left: '365px',
			background: 'white',
			boxShadow: '0px 3px 18px #c8c9ca',
			'&:hover': {
				background: '#f3f3f3'
			}
		},
		closeIcon: {
			fontSize: '24px',
			color: '#B4BDC6'
		}
	})
);

export { useStyles };
