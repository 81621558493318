import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, POST, EXPORTFILEPOST } from 'lib/handlers/requestTypes';

//* Bookings - initial page request */
export const fetchBooking = async (): Promise<OriginalResponse | Success> => restCall(GET, '/bookings');

export const fetchBookingsList = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/bookings/bookings', payload);

export const sendNewBookings = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/bookings/save', payload);

export const sendNewBlockedSeats = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/bookings/block', payload);

export const sendEditBookings = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/bookings/edit', payload);

export const fetchEmployeeBookings = async (office: number): Promise<OriginalResponse | Success> =>
	restCall(POST, '/bookings/employee', { office });

export const fetchEmployeeCountPending = async (office: number): Promise<OriginalResponse | Success> =>
	restCall(POST, '/bookings/countPending', { office });

export const sendConfirmBooking = async (id: number): Promise<OriginalResponse | Success> =>
	restCall(POST, '/bookings/confirm', { id });

export const sendDeleteBooking = async (id: number): Promise<OriginalResponse | Success> =>
	restCall(POST, '/bookings/delete', { id });

export const fetchExportBookings = async (payload: {
	office: Number | null;
	startDate: Date;
	type: string;
	endDate: Date | null;
}): Promise<OriginalResponse | Success> => restCall(EXPORTFILEPOST, '/bookings/export', payload);
