import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getUsersListVPN, sendUsersListVPN, clearUsersError } from 'store/administration/users/thunks';
import { AppState } from 'typings/state';

import VPNAccess from './VPNAccess';

const mapStateToProps = ({ users }: AppState) => ({ users });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getUsersListVPN, sendUsersListVPN, clearUsersError }, dispatch);

export type VPNAccessProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VPNAccess);
