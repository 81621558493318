/* eslint-disable func-names */
import * as yup from 'yup';

import { string, stringRequired } from 'lib/utils/formUtils';
import { IOrgAccessesCompare } from 'typings/store/admin/administration';

const findDuplicates = (arr: IOrgAccessesCompare[]) => {
	const teste = arr.filter((item, index) => {
		const duplicatedValues = arr.filter((itemToCompare, indexToCompare) => {
			// To prevent company from being *
			if (item.company === '0') {
				return true;
			}
			// To avoid comparisons inside the same object
			if (index !== indexToCompare) {
				// In case everything is exactly the same as other line
				if (
					itemToCompare.department === item.department &&
					itemToCompare.division === item.division &&
					itemToCompare.businessUnit === item.businessUnit &&
					itemToCompare.company === item.company
				) {
					return true;
				}

				// In case division is the same, not * and below are *
				if (itemToCompare.division === item.division && item.department === '0' && item.division !== '0') {
					return true;
				}

				// In case businessUnit is the same, not * and below are *
				if (
					itemToCompare.businessUnit === item.businessUnit &&
					item.division === '0' &&
					item.businessUnit !== '0'
				) {
					return true;
				}
				// In case company is the same, not * and below are *
				if (itemToCompare.company === item.company && item.businessUnit === '0' && item.company !== '0') {
					return true;
				}
			}
			return false;
		});
		return duplicatedValues.length > 0;
	});
	return teste;
};

export const schema = yup.object().shape({
	roles: yup
		.array()
		.of(
			yup.object().shape({
				id: stringRequired,
				name: stringRequired
			})
		)
		.required('Must have Roles'),
	orgAccesses: yup
		.array()
		.of(
			yup.object().shape({
				company: string,
				businessUnit: string,
				division: string,
				department: string
			})
		)
		.test(
			'duplicated-line',
			'Invalid Org Access combination, please do not repeat entries or invalid combinations.',
			payload => {
				const helper = payload?.map(({ uniqueId, ...e }) => ({ ...e }));
				return findDuplicates(helper as unknown as IOrgAccessesCompare[]).length === 0;
			}
		)
});

export const defaultValues = {
	roles: [],
	orgAccesses: []
};
