/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography, Button, FormHelperText } from '@mui/material';
import { Add } from '@mui/icons-material';
import Adicionar from '../../../../../../../assets/icons/Adicionar.svg';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { ExternalUsersLine, ExternalUsersTitle } from '../../utils';
import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';

//* COMPONENT INTERFACES
interface IExternalUsers extends IProjectTabs {
	externalUsers: {
		name: string;
		company: string;
		email: string;
	}[];
	addExternalUser: () => void;
	deleteExternalUser: (field: string, index: number) => void;
}

//* COMPONENT
const ExternalUsers: FC<IExternalUsers> = ({
	trigger,
	control,
	errors,
	addExternalUser,
	externalUsers,
	deleteExternalUser,
	setValue
}) => {
	const classes = useStyles();

	useEffect(() => {
		if (externalUsers.length === 0) {
			setValue('hasExternalUsers', false);
		}
		const validateExternalUsers = async () => {
			await trigger('externalUsers');
		};
		validateExternalUsers();
	}, [externalUsers]);

	return (
		<Grid container spacing={4} className={classes.root}>
			{/* **************** External Users ****************** */}
			<Grid item {...sizes[12]}>
				<Typography variant="h2">External Users</Typography>
			</Grid>
			<Grid item {...sizes[9]}>
				<Typography component="span" className={classes.infoLabel}>
					External users that will be granted access to Confluence and JIRA
				</Typography>
				{errors.externalUsers && <FormHelperText error>Empty fields are not allowed</FormHelperText>}
			</Grid>
			<Grid item {...sizes[3]}>
				<Button
					color="primary"
					variant="text"
					endIcon={<Adicionar />}
					onClick={() => {
						addExternalUser();
						setValue('hasExternalUsers', true);
					}}>
					Add External User
				</Button>
			</Grid>

			<Grid item {...sizes[12]}>
				{externalUsers.length > 0 && (
					<Grid container spacing={3}>
						<ExternalUsersTitle classes={classes} />
						{externalUsers.map(
							(e, index) =>
								e !== null && (
									<ExternalUsersLine
										// eslint-disable-next-line react/no-array-index-key
										key={index}
										index={index}
										handleDelete={deleteExternalUser}
										control={control}
										errors={errors}
										classes={classes}
									/>
								)
						)}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default ExternalUsers;
