/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-template-curly-in-string */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Typography, Grid, TextField } from '@mui/material';
import { Add, Check, Clear } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { InfoField, TextEditor, Input as InputLib } from 'xpand-ui/forms';
import { LoadingOverlay, Table, Dialog, PageTitle } from 'xpand-ui/core';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { INewEmailTemplate } from 'typings/store/admin/administration';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { EmailTemplatesProps } from '.';

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Email Templates - XPECP.png';
import Adicionar from '../../../../assets/icons/Adicionar.svg';
import { getLSField } from 'lib/utils/cookies';

const initialContentValue =
	'<!DOCTYPE html>\n' +
	"<html xmlns='http://www.w3.org/1999/xhtml'>\n" +
	'	<head>\n' +
	"		<meta http-equiv='Content-Type' content='text/html; charset=utf-8' />\n" +
	'	</head>\n' +
	'	<body>\n' +
	"		<div style='font-family: calibri, sans-serif; font-size: 14px; color: #7f7f7f;'>\n" +
	'			<p>\n' +
	"				<img src='https://apps.xpand-it.com/cp/assets/images/banner_external.jpg' width='100%' />\n" +
	'			</p>\n' +
	"			<br class='substitute'/>\n" +
	"			<p style='line-height: 1.3; margin: 0px; font-size: 18px; text-transform: uppercase; color: #3F80EF;'>This is a blank email template. Use placeholders like ${this} to get data sent from server or control certain elements display.</p>\n" +
	"			<br class='substitute'/>\n" +
	"			<p style='line-height:1.3;margin:0px;'>Best Regards,</p>\n" +
	"			<br class='substitute'/>\n" +
	"			<br class='substitute'/>\n" +
	'		</div>\n' +
	'	</body>\n' +
	'</html>';

const schema = yup.object().shape({
	name: yup
		.string()
		.matches(/email-.*/, 'Name has to start with "email-"')
		.required(),
	content: yup.string().required()
});

//* COMPONENT
const EmailTemplates: FC<EmailTemplatesProps> = ({
	administration,
	filters,
	getEmailTemplates,
	sendPostChangeEmailTemplateStatus,
	sendPostNewEmailTemplate,
	clearAdministrationError,
	setEmailTemplatesPageFilter,
	sendPostTestEmailTemplate
}) => {
	const classes = useStyles();
	const { listEmailTemplates, error } = administration;
	const { emailTemplatesFilter } = filters;

	const [selectedTestEmail, setSelectedTestEmail] = useState<string>('');
	const [editModal, setEditModal] = useState<boolean | number>(false);
	const [confirmModal, setConfirmModal] = useState<boolean | number>(false);
	const [emailTestModal, setEmailTestModal] = useState<boolean | number>(false);
	const [addModalOpen, setAddModalOpen] = useState<boolean | number>(false);

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(emailTemplatesFilter);

	const [inputValues, setInputValues] = useState({});

	const handleInputChange = (placeholder, value) => {
		setInputValues(prevValues => ({
			...prevValues,
			[placeholder]: value
		}));
	};

	useEffect(() => {
		setEmailTemplatesPageFilter(searchValue);
	}, [searchValue]);

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	const {
		handleSubmit,
		control,
		reset,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues: {
			name: 'email-',
			content: initialContentValue
		},
		shouldUnregister: false
	});

	useEffect(() => {
		if ((!error && !listEmailTemplates) || listEmailTemplates?.emailTemplates?.length === 0) {
			getEmailTemplates();
		}
	}, [listEmailTemplates]);

	// Different submits depending if it is edit mode or not. Edit mode sends the payload with the ID as well
	const onSubmit = (payload: INewEmailTemplate, type: string | boolean, id: number) => {
		const payloadWithId = { ...payload, id };
		if (type === 'EDIT') {
			sendPostNewEmailTemplate(payloadWithId);
		} else {
			sendPostNewEmailTemplate(payload);
		}

		if (editModal) {
			setEditModal(false);
		} else {
			setAddModalOpen(false);
		}
	};

	// Different submits depending if it is edit mode or not. Edit mode sends the payload with the ID as well
	const onSubmitTestEmail = () => {
		// Log the payload
		const payloadToSend = { inputValues, selectedTestEmail };
		sendPostTestEmailTemplate(payloadToSend);
		setInputValues({});
		// Perform other actions with the payload, such as sending a test email
	};
	// Changes the status of the email Template to it's opposite(Enable/Disable)
	const changeEmailTemplateStatus = (id: number) => {
		sendPostChangeEmailTemplateStatus(id);
		setConfirmModal(false);
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{ label: 'Name', id: 'name', accentColumn: true, width: '60%' },
			{
				label: 'Status',
				id: 'active',
				format: (row, text) =>
					text === false ? (
						<div>
							<Clear color="primary" /> Disabled{' '}
						</div>
					) : (
						<div>
							<Check color="primary" /> Enabled{' '}
						</div>
					)
			}
		],
		[]
	);

	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'emailTemplatesMenu',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'emailTemplatesMenuViewDetails',
						label: 'Edit Template',
						onClick: row => {
							setEditModal(row.id);
							reset({ name: row.name, content: row.content });
						}
					},
					{
						id: 'emailTemplatesMenuChange',
						label: row => (row.active ? 'Disable' : 'Enable'),
						onClick: row => {
							setConfirmModal(row.id);
						}
					},
					{
						id: 'emailTest',
						label: 'Test Email',
						onClick: row => {
							setSelectedTestEmail(row.name);
							setEmailTestModal(row.id);
						}
					}
				]
			}
		],
		[listEmailTemplates]
	);

	const pageTitleActions = useMemo(
		() => [
			{
				id: 'addEmailTemplate',
				onClick: () => {
					setAddModalOpen(true);
					// Reset to clear the value of the content field
					reset({ name: 'email-', content: initialContentValue });
				},
				icon: <Adicionar />,
				label: 'Add Email Template'
			}
		],
		[]
	);

	// Checking whether the contentList is different than it's initial Value
	const setDisableStateOnModalSubmit = () => {
		if (editModal) {
			const old = listEmailTemplates?.emailTemplates?.find(e => e.id === editModal);
			return old?.content === watch('content');
		}
		return initialContentValue === watch('content');
	};

	const newTemplateActions = [
		{
			id: 'submit',
			label: 'Submit',
			color: 'primary',
			type: 'submit',
			form: 'form-new-email-template',
			// Disabling the submit button if the value of Content isn't changed from the dummy initial value
			disabled: setDisableStateOnModalSubmit() || getLSField('impersonate_userInfo'),
			variant: 'contained',
			onClick: () => ({})
		}
	];

	const tableData = useMemo(
		() =>
			listEmailTemplates?.emailTemplates?.map(e => ({
				...e,
				activeParsed: e.active === false ? 'Disabled' : 'Enabled'
			})),
		[listEmailTemplates]
	);

	if (error) return handleErrorPage(error, clearAdministrationError);

	const isLoading = listEmailTemplates === null;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			<PageTitle title="" actions={pageTitleActions} />
			<Dialog
				fullScreen
				customClasses={{ fullScreenContent: classes.fullScreenContent }}
				modal={{
					// Sending Edit or Add mode
					open: Boolean(editModal) || Boolean(addModalOpen),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							if (editModal) {
								setEditModal(false);
							} else {
								setAddModalOpen(false);
							}
						}
					},
					content: (
						<form
							id="form-new-email-template"
							onSubmit={handleSubmit((data: any) =>
								onSubmit(
									data,
									(Boolean(editModal) && 'EDIT') || (Boolean(addModalOpen) && 'ADD'),
									(editModal as number) || (addModalOpen as number)
								)
							)}>
							<Grid container style={{ padding: '10px 5px 40px 5px' }}>
								<Grid item xs={12}>
									{editModal ? (
										// Making the Name field editable or not depending if it is Add or Edit action
										<InfoField label="Name" value={watch('name')} />
									) : (
										<InputLib
											name="name"
											label="Name"
											helperText="Name has to be email-"
											required
											control={control}
											errors={errors}
										/>
									)}
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextEditor
												name="content"
												label="Content"
												height={700}
												required
												control={control}
												errors={errors}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					)
				}}
				// Changing title of the modal between Edit and Add actions
				title={editModal ? `Editing "${watch('name')}"` : 'Add new email template'}
				actions={newTemplateActions}
				scroll="body"
			/>
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="name"
				handleSearch={{ searchValue, setSearchValue }}
				handleDiagramModal={{ diagramModal, setDiagramModal }}
			/>
			<Dialog
				modal={{
					open: Boolean(confirmModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmModal(false);
					},
					content: (
						<Typography gutterBottom>
							Are you sure you want to change the status of this template?
						</Typography>
					)
				}}
				title="Change Email Status"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setConfirmModal(false)
					},
					{
						id: 'changeStatus',
						label: 'Change Status',
						color: 'primary',
						variant: 'contained',
						onClick: () => changeEmailTemplateStatus(confirmModal as number),
						disabled: getLSField('impersonate_userInfo')
					}
				]}
				scroll="body"
			/>
			<Dialog
				modal={{
					open: Boolean(emailTestModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setEmailTestModal(false);
							setSelectedTestEmail('');
						}
					},
					content: (
						<form id="form-send-test-email" onSubmit={handleSubmit(onSubmitTestEmail)}>
							<Typography gutterBottom>Please fill in the placeholders to test the email</Typography>
							{listEmailTemplates?.emailTemplates?.map(template =>
								template.name === selectedTestEmail ? (
									<div key={template.id}>
										<Typography variant="subtitle1" gutterBottom>
											{template.name}
										</Typography>
										{template?.placeholders?.map(placeholder => (
											<TextField
												key={placeholder}
												label={placeholder}
												fullWidth
												value={inputValues[placeholder] || ''}
												onChange={e => handleInputChange(placeholder, e.target.value)}
												margin="normal"
											/>
										))}
									</div>
								) : (
									''
								)
							)}
						</form>
					)
				}}
				title="Test Email Template"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => {
							setEmailTestModal(false);
							setSelectedTestEmail('s');
						}
					},
					{
						id: 'sendTestEmail',
						label: 'Send Test Email',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							handleSubmit(onSubmitTestEmail)();
							setSelectedTestEmail('');
							setEmailTestModal(false);
						},
						disabled: getLSField('impersonate_userInfo')
					}
				]}
				scroll="body"
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(EmailTemplates);
