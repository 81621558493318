import { createReducer } from 'typesafe-actions';
import { PersonalInfoActions as Actions, PersonalInfoSate } from 'typings/store/personalInfoTypes';

const INITIAL_STATE: PersonalInfoSate = {
	loading: false,
	error: null,
	// Booking
	deskBooking: null,
	deskBookingsList: null,
	countPending: null,
	employeeBookings: null,
	deskBookingActions: null,
	exportBookings: null,
	// Profile
	userProfile: null,
	userProfileAction: null,
	// company
	allEmployees: null,
	userTeam: null,
	// Skills
	userSkills: null,
	systemSkills: null,
	skillsAction: null,
	// Resume
	userResume: null,
	resumeAction: null,
	// Trainings
	userTrainings: null,
	// Benefits
	userBenefits: null,
	// Career
	userCareer: null,
	// Financial Documents
	userFinancialDocuments: null,
	// Xtracker
	weekViewData: null,
	weekViewDataForCopy: null,
	getPeriodListFiltersData: null,

	dayViewData: null,

	userPendingConvocationMessage: null,
	acknowledgedNewRelease: false,

	convocationMessageHasChanged: false,
	exportTimesheetList: null,
	isMoved: null,
	copySaveMessage: null,
	saveTimesheetMessage: null,
	attachList: null,

	// Xtracker - MyExpenses
	expensesData: null,
	expenseSheetData: null,
	newExpenseLineData: null,
	newExpenseSheet: null,
	updateExpenseSheet: null,
	newExpenseLine: null,
	expenseAttachList: null,
	expenseSheetSubmitMessage: null,
	expensesAuditData: null,
	copiedExpenseSheetId: null,

	// Xtracker - MyExpenses
	timeoffData: null,
	saveTimeoffMessage: null,

	//	Approvals - Timesheets
	approvalsTimesheet: null,
	approvalsTimesheetPeriodDetails: null,
	updateTimesheet: null,

	// Approvals - Expenses
	approvalsExpenses: null,
	updateExpenses: null,

	//Approvals - Timeoff
	approvalsTimeoff: null,
	approvalsTimeOffDetails: null,
	updateTimeoff: null
};

const personalInfoReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({
		...state,
		loading: true,
		convocationMessageHasChanged: false
	}),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	/** Bookings */
	[Actions.GET_DESK_BOOKING]: (state, { payload }) => ({
		...state,
		loading: false,
		deskBooking: payload
	}),
	[Actions.GET_DESK_BOOKINGS_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		employeeBookings: null,
		deskBookingActions: null,
		deskBookingsList: payload
	}),
	[Actions.SET_UPDATE_BOOKINGS]: (state, { payload }) => ({
		...state,
		loading: false,
		deskBookingActions: payload,
		deskBookingsList: null
	}),
	[Actions.GET_EMPLOYEE_PENDING_COUNT]: (state, { payload }) => ({
		...state,
		loading: false,
		countPending: payload
	}),
	[Actions.GET_EMPLOYEE_BOOKINGS]: (state, { payload }) => ({
		...state,
		loading: false,
		employeeBookings: payload
	}),
	[Actions.SEND_CONFIRM_BOOKING]: (state, { payload }) => ({
		...state,
		loading: false,
		deskBookingActions: payload
	}),
	[Actions.SEND_DELETE_BOOKING]: (state, { payload }) => ({
		...state,
		loading: false,
		deskBookingActions: payload
	}),
	[Actions.GET_EXPORT_BOOKINGS]: (state, { payload }) => ({
		...state,
		loading: false,
		exportBookings: payload
	}),
	/** Profile */
	[Actions.GET_USER_PROFILE]: (state, { payload }) => ({
		...state,
		loading: false,
		userProfile: payload
	}),
	[Actions.GET_ALL_EMPLOYEES]: (state, { payload }) => ({
		...state,
		loading: false,
		allEmployees: payload
	}),
	[Actions.GET_USER_TEAM]: (state, { payload }) => ({
		...state,
		loading: false,
		userTeam: payload
	}),
	[Actions.SET_UPDATE_USER_PROFILE]: (state, { payload }) => ({
		...state,
		loading: false,
		userProfileAction: payload.payload,
		userProfile: payload.newData
	}),
	[Actions.SET_NEW_USER_PROFILE_PIC]: (state, { payload }) => ({
		...state,
		loading: false,
		userProfileAction: payload.payload,
		userProfile: payload.newData
	}),
	[Actions.CLEAR_USER_PROFILE]: () => ({ ...INITIAL_STATE }),
	/** Skills */
	[Actions.GET_USER_SKILLS]: (state, { payload }) => ({ ...state, loading: false, userSkills: payload }),
	[Actions.GET_SYSTEM_SKILLS]: (state, { payload }) => ({
		...state,
		loading: false,
		systemSkills: payload
	}),
	[Actions.UPDATE_SYSTEM_SKILLS]: (state, { payload }) => ({
		...state,
		loading: false,
		skillsAction: payload.payload,
		userSkills: payload.newData
	}),
	/** Resume */
	[Actions.GET_USER_RESUME]: (state, { payload }) => ({ ...state, loading: false, userResume: payload }),
	[Actions.SEND_USER_RESUME]: (state, { payload }) => ({
		...state,
		loading: false,
		resumeAction: payload.payload,
		userResume: payload.newData
	}),
	/** Trainings */
	[Actions.GET_USER_TRAININGS]: (state, { payload }) => ({
		...state,
		loading: false,
		userTrainings: payload
	}),
	/** Benefits */
	[Actions.GET_USER_BENEFITS]: (state, { payload }) => ({
		...state,
		loading: false,
		userBenefits: payload
	}),
	/** Career */
	[Actions.GET_USER_CAREER]: (state, { payload }) => ({ ...state, loading: false, userCareer: payload }),

	/** Financial Documents */
	[Actions.GET_USER_FINANCIAL_DOCUMENTS]: (state, { payload }) => ({
		...state,
		loading: false,
		userFinancialDocuments: payload
	}),
	[Actions.DOWNLOAD_USER_DOCUMENT]: state => ({
		...state,
		loading: false
	}),
	[Actions.CLEAR_SYSTEM_SKILLS]: state => ({
		...state,
		loading: false,
		systemSkills: null
	}),
	[Actions.GET_PENDING_CONVOCATIONS_MESSAGE]: (state, { payload }) => ({
		...state,
		userPendingConvocationMessage: payload,
		convocationMessageHasChanged: true,
		loading: false
	}),
	[Actions.CLEAR_CONVOCATION_MESSAGE_CONFIG]: state => ({
		...state,
		userPendingConvocationMessage: null
	}),
	[Actions.CLEAR_BOOKING]: state => ({
		...state,
		deskBookingsList: null
	}),
	[Actions.GET_WEEK_VIEW_DATA]: (state, { payload }) => ({
		...state,
		loading: false,
		weekViewData: payload
	}),
	[Actions.GET_EXPENSES_DATA]: (state, { payload }) => ({
		...state,
		loading: false,
		expensesData: payload
	}),
	[Actions.GET_EXPENSE_SHEET_DATA]: (state, { payload }) => ({
		...state,
		loading: false,
		expenseSheetData: payload
	}),
	[Actions.GET_NEW_EXPENSE_LINE_DATA]: (state, { payload }) => ({
		...state,
		loading: false,
		newExpenseLineData: payload
	}),
	[Actions.SEND_NEW_EXPENSE_SHEET]: (state, { payload }) => ({
		...state,
		loading: false,
		newExpenseSheet: payload
	}),
	[Actions.SEND_UPDATE_EXPENSE_SHEET]: (state, { payload }) => ({
		...state,
		loading: false,
		updateExpenseSheet: payload
	}),
	[Actions.GET_WEEK_VIEW_DATA_FOR_COPY]: (state, { payload }) => ({
		...state,
		loading: false,
		weekViewDataForCopy: payload
	}),
	[Actions.GET_PERIODLIST_FILTERS_DATA]: (state, { payload }) => ({
		...state,
		loading: false,
		getPeriodListFiltersData: payload
	}),
	[Actions.GET_DAY_VIEW_DATA]: (state, { payload }) => ({
		...state,
		loading: false,
		dayViewData: payload
	}),
	[Actions.GET_EXPORT_TIMESHEET]: (state, { payload }) => ({
		...state,
		loading: false,
		exportTimesheetList: null
	}),
	[Actions.MOVE_TIMEENTRY]: (state, { payload }) => ({
		...state,
		loading: false,
		isMoved: payload
	}),
	[Actions.DELETE_MOVE_TIMEENTRY]: state => ({
		...state,
		isMoved: null
	}),
	[Actions.SAVE_TIMEENTRIES_COPY]: (state, { payload }) => ({
		...state,
		loading: false,
		copySaveMessage: payload
	}),
	[Actions.CLEAR_COPY_MESSAGE]: (state, { payload }) => ({
		...state,
		loading: false,
		copySaveMessage: null
	}),
	[Actions.GET_EXPORT_TIMESHEET_CSV]: (state, { payload }) => ({
		...state,
		loading: false
	}),
	[Actions.SAVE_TIMESHEET]: (state, { payload }) => ({
		...state,
		loading: false,
		saveTimesheetMessage: payload
	}),
	[Actions.GET_ATTACHMENT_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		attachList: payload
	}),
	[Actions.SET_LOADING_FALSE]: (state, { payload }) => ({
		...state,
		loading: false
	}),
	[Actions.GET_EXPENSES_AUDIT_DATA]: (state, { payload }) => ({
		...state,
		loading: false,
		expensesAuditData: payload
	}),
	[Actions.SEND_NEW_EXPENSE_LINE]: (state, { payload }) => ({
		...state,
		loading: false,
		newExpenseLine: payload
	}),
	[Actions.GET_EXPENSE_ATTACHMENT_LIST]: (state, { payload }) => ({
		...state,
		loading: false,
		expenseAttachList: payload
	}),
	[Actions.GET_SUBMIT_EXPENSE_SHEET]: (state, { payload }) => ({
		...state,
		loading: false,
		expenseSheetSubmitMessage: payload
	}),
	[Actions.CLEAR_EXPENSE_SHEET_DATA]: state => ({
		...state,
		loading: false,
		expenseSheetData: null
	}),
	[Actions.COPIED_EXPENSE_SHEET]: (state, { payload }) => ({
		...state,
		loading: false,
		copiedExpenseSheetId: payload
	}),
	//* Xtracker - My Timeoff */
	[Actions.GET_TIME_OFF_DATA]: (state, { payload }) => ({
		...state,
		loading: false,
		timeoffData: payload
	}),
	[Actions.SAVE_TIMEOFF]: (state, { payload }) => ({
		...state,
		loading: false,
		saveTimeoffMessage: payload
	}),
	[Actions.GET_TIME_OFF_REQUEST_APPROVAL_DETAILS]: (state, { payload }) => ({
		...state,
		loading: false,
		timeOffRequestApprovalDetailData: payload
	}),
	[Actions.POST_EXPORT_TIMEOFF_ICAL]: (state, { payload }) => ({
		...state,
		loading: false,
		timeoffExportICAL: payload
	}),
	[Actions.POST_EXPORT_TIMEOFF_PDF]: (state, { payload }) => ({
		...state,
		loading: false,
		timeoffExportPDF: payload
	}),
	[Actions.GET_OUTLOOK_EVENTS]: (state, { payload }) => ({
		...state,
		loading: false,
		outlookEvents: payload
	})
});

export default personalInfoReducer;
