/* eslint-disable func-names */
import * as yup from 'yup';

import { dateRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	newStartDate: dateRequired
});

export const defaultValues = {
	newStartDate: ''
};
