import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import {
	// new
	getCreateEmployee,
	// submit new
	submitCreateEmployee,
	clearNewEmployeeForm,
	clearAdmissionsError
} from 'store/administration/admissions/thunks';

import {
	// HELPERS %%%%%%%%%%%%%%%%
	// calculate annual gross
	getProposalAnnualGrossValue,
	// calculate  irs
	getIrsValue
} from 'store/administration/proposals/thunks';

import CreateEmployee from './CreateEmployee';

const mapStateToProps = ({ proposals, admissions, company, formFields }: AppState) => ({
	proposals,
	admissions,
	company,
	formFields
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			// new
			getCreateEmployee,
			// submit new
			submitCreateEmployee,
			// HELPERS %%%%%%%%%%%%%%%%
			// calculate annual gross
			getProposalAnnualGrossValue,
			// calculate  irs
			getIrsValue,
			clearNewEmployeeForm,
			clearAdmissionsError
		},
		dispatch
	);

export type CreateEmployeeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmployee);
