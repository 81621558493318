/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useForm, UseFormReset } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
// import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter, FormSteps, TabPanel } from 'xpand-ui/core';
import { parseDateToManageSaf } from 'xpand-ui/utils/dates';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';

//* TYPINGS
import {
	ICollaborationProposal,
	ICompanyInfo,
	IGetNewProposal,
	IProposalFormCareerHelper
} from 'typings/store/admin/proposals';
import { IPostPutCSC } from 'typings/store/admin/employeeSalaryCategories';
import { IPageTitle, ITitle } from 'components/App/TitleProvider';
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
//*
import General from 'pages/Admin/_ProposalsFormUtils/General';
import CareerFinancial from 'pages/Admin/_ProposalsFormUtils/CareerFinancial';
import OtherBenefits from 'pages/Admin/_ProposalsFormUtils/OtherBenefits';
import ContractCompany from 'pages/Admin/_ProposalsFormUtils/ContractCompany';
import Summary from 'pages/Admin/_ProposalsFormUtils/Summary';
import {
	createLoadFromOtherUserSelectLabel,
	proposalsGetFixedSelectOptions,
	updateSelectsInCascade,
	setValuesFromCurrentProposal,
	setValuesFromProposal,
	getProposalApiMethods,
	getMissingFieldsNames
} from 'pages/Admin/_ProposalsFormUtils/utils';
import { CSC } from 'pages/Admin/_ProposalsFormUtils/constants';
import { isTouchedFieldsUsed } from 'lib/utils/formUtils';
//* LOCAL COMPONENT IMPORTS
import { Prompt } from 'react-router';
import { createYupSchema, createDefaultValues } from './yupSchema';

import { useStyles } from './styles';
import { NewEmployeeSalaryProps } from '.';
import { getLSField } from 'lib/utils/cookies';
import moment from 'moment';
import { getFormFieldsConfig } from 'store/administration/formFields/thunks';
import { useDispatch, useSelector } from 'react-redux';

//* COMPONENT INTERFACES
interface INewEmployeeSalary extends NewEmployeeSalaryProps, IPageTitle {
	match: Match<{
		token: string;
		proposalId: string | number;
		hash: string;
	}>;
	goToPage: (path: string) => void;
}

//* COMPONENT
const NewEmployeeSalary: FC<INewEmployeeSalary> = ({
	match,
	title,
	setTitle,
	goToPage,
	proposals,
	employeeSalary,
	getNewEmployeeSalary,
	submitNewEmployeeSalary,
	submitUpdateEmployeeSalary,
	getProposalAnnualGrossValue,
	getIrsValue,
	getEditEmployeeSalary,
	clearProposalsFormsData,
	clearCscError,
	company
}) => {
	const classes = useStyles();
	const { loading, newEmployeeSalary, employeesSalaryActions, irsValue, error } = employeeSalary;
	const { loading: loadingProposal, proposalAnnualGross } = proposals;
	const { defaultCompanyKey, companyDomain } = company;
	const { params } = match;
	const pageToken = params?.token;
	const cscHash = params?.hash;
	const isEdit = params?.proposalId;
	const scrollToTopRef = useRef<HTMLDivElement | any>(null);
	const scrollbarRef = React.createRef();
	const [tabSelected, setTabSelected] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { width } = useWindowDimensions();
	const [currentYupSchema, setCurrentYupSchema] = useState(() => createYupSchema('PT'));
	const [currentDefaultValues, setCurrentDefaultValues] = useState(() => createDefaultValues('PT'));
	const isTablet = useMemo(() => width <= 900, [width]); // default is 900 tablet
	const [firstChanged, setIsFirstChanged] = useState(false);
	const dispatch = useDispatch();
	const formFields = useSelector(state => state.formFields.formFieldsConfig);
	const [filtersRows, setFiltersRows] = useState(10);

	//Forces to update of the value "company" to the current company
	useEffect(() => {
		setValue('company', defaultCompanyKey);
	}, []);

	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	}, [tabSelected]);

	const [isContractor, setIsContractor] = useState('Employee');
	const [isCSC, setIsCSC] = useState(true);

	useEffect(() => {
		scrollToTopRef?.current?.scrollIntoView(0, 0);
	});

	const [tabs, setTabs] = useState([
		{
			id: 0,
			label: 'General'
		},
		{
			id: 1,
			label: 'Career & Financial'
		},
		{
			id: 2,
			label: 'Other Benefits'
		},
		{
			id: 3,
			label: 'Contract & Company'
		},
		{
			id: 4,
			label: 'Summary'
		}
	]);

	//* when the proposal is submitted and there is no error, redirect to the corresponding page
	useEffect(() => {
		if (employeesSalaryActions && !employeesSalaryActions.code && !error) {
			clearProposalsFormsData();
			goToPage(`/admin/salariesAndContracts/employeeSalaryCategories/details/${cscHash}`);
		}
	}, [employeesSalaryActions]);

	//Load necessary info to yupSchema
	useEffect(() => {
		setValue(
			'commsPlafond',
			isEdit
				? newEmployeeSalary?.proposalSummary?.communicationsPlafond
				: newEmployeeSalary?.currentCSC.xphrMonthlyCommPlCost
		);
		setValue(
			'healthInsuranceValue',
			newEmployeeSalary?.proposal?.healthInsuranceValue
				? newEmployeeSalary?.proposal?.healthInsuranceValue
				: newEmployeeSalary?.cscPrefilledBenefits?.healthInsuranceValue
				? newEmployeeSalary?.cscPrefilledBenefits?.healthInsuranceValue
				: currentDefaultValues?.healthInsuranceValue
		);

		let benefits = [];
		benefits.push({
			goalDetails: newEmployeeSalary?.proposal?.benefitsDescription1,
			prizeValue: newEmployeeSalary?.proposal?.benefitsValue1
		});
		benefits.push({
			goalDetails: newEmployeeSalary?.proposal?.benefitsDescription2,
			prizeValue: newEmployeeSalary?.proposal?.benefitsValue2
		});
		benefits.push({
			goalDetails: newEmployeeSalary?.proposal?.benefitsDescription3,
			prizeValue: newEmployeeSalary?.proposal?.benefitsValue3
		});
		benefits = benefits.filter(e => e.goalDetails !== undefined);
		setValue('otherbenefits', benefits.length > 0 ? true : isEdit ? false : currentDefaultValues?.otherbenefits);
		setValue(
			'otherBenefitsList',
			benefits.length > 0 ? benefits : isEdit ? benefits : currentDefaultValues?.otherBenefitsList
		);
	}, [currentDefaultValues, newEmployeeSalary]);
	//* when the proposal is submitted and there is no error, redirect to the corresponding page
	useEffect(() => {
		if (newEmployeeSalary?.employeeInfo) {
			let newTitle: ITitle;

			if (isEdit) {
				newTitle = {
					...title,
					title: !newEmployeeSalary?.employeeInfo?.searchKey
						? 'Edit Employee Salary'
						: `Edit Employee Salary - ${`${newEmployeeSalary?.employeeInfo?.searchKey}`.toUpperCase()} : ${
								newEmployeeSalary?.employeeInfo?.name
						  }`
				};
			} else {
				newTitle = {
					...title,
					title: !newEmployeeSalary?.employeeInfo?.searchKey
						? 'New Employee Salary'
						: `New Employee Salary - ${`${newEmployeeSalary?.employeeInfo?.searchKey}`.toUpperCase()} : ${
								newEmployeeSalary?.employeeInfo?.name
						  }`
				};
			}
			setTitle(newTitle);
		}
	}, [newEmployeeSalary]);

	const {
		handleSubmit,
		control,
		formState: { errors, touchedFields },
		reset,
		resetField,
		watch,
		setValue,
		getValues,
		clearErrors,
		trigger
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(currentYupSchema),
		reValidateMode: 'onChange',
		defaultValues: currentDefaultValues,
		shouldUnregister: false
	});

	const [formWarnings, setFormWarnings] = useState<Array<number>>([]);
	const [errorCheck, setErrorCheck] = useState(false);
	const [selectHelper, setSelectHelper] = useState<IProposalFormCareerHelper>({
		company: defaultCompanyKey,
		businessUnit: currentDefaultValues?.businessUnit,
		division: currentDefaultValues?.division,
		department: currentDefaultValues?.department,
		startDate: currentDefaultValues?.startDate,
		careerPosition: currentDefaultValues?.careerPosition,
		careerJobRole: currentDefaultValues?.careerJobRole,
		careerJobRoleLevel: currentDefaultValues?.careerJobRoleLevel,
		holdersNumber: currentDefaultValues?.holdersNumber
	});

	const [initialBaseValue, setInitialBaseValue] = useState(watch().baseValue);

	useEffect(() => {
		if (watch('company') !== null && watch('company') !== '') {
			dispatch(getFormFieldsConfig(isContractor, watch('company'), 'Cost Salary Category'));
		}
	}, [newEmployeeSalary, watch('company'), isContractor]);

	const selectedCompany = useMemo(() => {
		if (!newEmployeeSalary || newEmployeeSalary?.code) {
			return undefined;
		}
		return selectHelper.company ? newEmployeeSalary.company : undefined;
	}, [newEmployeeSalary, selectHelper.company]);

	const referenceData = useMemo(() => selectedCompany?.referenceData || null, [selectedCompany]);

	// UseEffect required to adjust the schemas and defaultValues whenever the company changes
	useEffect(() => {
		if (formFields) {
			setCurrentYupSchema(createYupSchema(formFields?.formFieldsConfig?.country));
			setCurrentDefaultValues(createDefaultValues(formFields?.formFieldsConfig?.country, referenceData));
		}
	}, [formFields, referenceData]);

	/** //* GET API CALCULATIONS HELPERS
	 * @function initFinancialValues CALCULATE TAXABLE / GROSS VALUES / TWELFTHS
	 * @function updateIRSTax CALCULATE IRS
	 * @function getAnnualGrossValue
	 * @function getCareerFields CAREER NAMES HELPERS
	 */
	const { initFinancialValues, updateIRSTax, getAnnualGrossValue, getCareerFields } = getProposalApiMethods(
		reset as unknown as UseFormReset<IPostPutCSC>,
		getIrsValue,
		getProposalAnnualGrossValue
	);

	useEffect(() => {
		if (Object.keys(errors).length > 0 && errorCheck) {
			const { names } = getMissingFieldsNames(errors);
			addNotification('danger', `The following required fields are required: ${names.join(', ')}`, 5);

			setErrorCheck(false);
		}
	}, [errorCheck, errors]);

	/** Set food Allowance value when foodAllowance type changes
	 * @onchange of the following fields
	 * @field1 - foodAllowanceType
	 */
	const updateFoodAllowanceValue = useMemo(
		() => (data: IPostPutCSC) => {
			if (referenceData) {
				const { foodAllowanceType, foodAllowance } = data;

				const result =
					foodAllowanceType === 'Cash'
						? foodAllowance || referenceData.xphrFoodAllowValue
						: foodAllowance || referenceData.xphrCrFoodAllowValue;
				setValue('foodAllowance', Number(result));
			}
		},
		[referenceData]
	);
	//When is a new CSC the default startDate is the first day of the next month
	useEffect(() => {
		if (!isEdit) {
			const todayDate = new Date();
			const firstDayNextMonth = new Date(
				todayDate.getFullYear(),
				todayDate.getMonth() + 1,
				1,
				todayDate.getHours(),
				todayDate.getMinutes()
			);
			setValue('startDate', moment(firstDayNextMonth).format('YYYY-MM-DD HH:mm:ss').toString());
		}
	}, []);
	const updateFormAfterLoad = (proposal: ICollaborationProposal | null = null) => {
		// assign helpers values
		proposal &&
			setSelectHelper({
				company: proposal.company,
				businessUnit: proposal.businessUnit,
				division: proposal.division,
				department: proposal.department,
				startDate: proposal.startDate,
				careerPosition: proposal.careerPosition,
				careerJobRole: proposal.careerJobRole,
				careerJobRoleLevel: proposal.careerJobRoleLevel,
				holdersNumber: proposal.holdersNumber as string
			});

		const payload = watch();
		if (payload.prizeBonus === '0.00') {
			payload.eligiblePrizeValue = 0;
			payload.prizeBonusPercentage = 0;
			payload.prizeBonusGoals = [];
		} else {
			payload.eligiblePrizeValue = (Number(payload.prizeBonus) * Number(payload.monthsApplicable)) / 12 || 0;
			payload.prizeBonusPercentage =
				(Number(payload.prizeBonus) / (Number(payload.prizeBonus) + Number(payload.annualGrossValue))) * 100 ||
				0;
		}

		// Set tax related fields if they were sent
		if (proposal?.dependentsNumber) {
			payload.dependentsNumber = Number(proposal.dependentsNumber);
		}
		if (proposal?.disabled) {
			payload.disabled = proposal.disabled;
		}
		if (proposal?.maritalStatus) {
			payload.maritalStatus = proposal.maritalStatus;
		}
		if (proposal?.tsuExemption) {
			payload.tsuExemption = proposal.tsuExemption;
		}
		if (proposal?.company == 'HR') {
			payload.foodAllowanceDays = 0;
			payload.ihtValue = '0';
			payload.ihtPercentage = '0';
			payload.hasTwelfths = false;
			payload.monthsApplicable = 0;
			payload.payBonusSalary = proposal?.payBonusSalary != undefined ? proposal?.payBonusSalary : true;
			delete payload.vacationsTwelfth;
			delete payload.christmasTwelfth;
		}

		// load api related fields after form is filled
		initFinancialValues(payload);
		updateIRSTax(watch());
		getAnnualGrossValue(payload);
		updateFoodAllowanceValue(watch());
		setInitialBaseValue(watch().baseValue);
	};

	//* USE EFFECT TO LOAD INITIAL DATA
	useEffect(() => {
		if (!newEmployeeSalary) {
			// load data on edit
			if (isEdit) {
				getEditEmployeeSalary(cscHash, pageToken, Number(isEdit));
			} else {
				getNewEmployeeSalary(cscHash);
			}
		} else {
			// eslint-disable-next-line no-lonely-if
			if (isEdit) {
				if (typeof newEmployeeSalary.proposal === 'undefined') {
					getEditEmployeeSalary(cscHash, pageToken, Number(isEdit));
				} else {
					// update individually each field with the new data
					// eslint-disable-next-line no-unused-vars
					const { irsToApply, ...propToLoad } = newEmployeeSalary.proposal;

					if (propToLoad) {
						setValuesFromProposal(propToLoad as ICollaborationProposal, reset, watch, true);

						// LOAD INITIAL DATA
						reset({
							...getValues(),
							name: propToLoad.name || '',
							email: propToLoad.email || '',
							username: (propToLoad.username || '') as string,
							company: propToLoad?.company,
							businessUnit: propToLoad?.businessUnit,
							division: propToLoad?.division,
							department: propToLoad?.department,
							careerPosition: propToLoad?.careerPosition,
							careerJobRole: propToLoad?.careerJobRole || '',
							careerJobRoleLevel: propToLoad?.careerJobRoleLevel || '',
							token: (propToLoad.token ? (propToLoad.token as string) : null) as string
						});
						// update the form selects and remake the calculations API requests
						updateFormAfterLoad({
							company: propToLoad?.company,
							businessUnit: propToLoad?.businessUnit,
							division: propToLoad?.division,
							startDate: propToLoad?.startDate,
							department: propToLoad?.department,
							careerPosition: propToLoad?.careerPosition,
							careerJobRole: propToLoad?.careerJobRole || '',
							careerJobRoleLevel: propToLoad?.careerJobRoleLevel || '',
							payBonusSalary: propToLoad?.payBonusSalary
						} as ICollaborationProposal);
					}
				}
			} else {
				// LOAD INITIAL DATA
				reset({
					...getValues(),
					name: newEmployeeSalary?.employeeInfo?.name || '',
					email: newEmployeeSalary?.userInfo?.email || '',
					username: newEmployeeSalary?.userInfo?.username || '',
					company: newEmployeeSalary?.company?.searchKey,
					businessUnit: newEmployeeSalary?.employeeInfo?.xPHRAdOrgBunit,
					division: newEmployeeSalary?.employeeInfo?.xphrAdOrgDivision,
					department: newEmployeeSalary?.employeeInfo?.xphrAdOrgDepartment,
					careerPosition: newEmployeeSalary?.employeeCurrentCareer?.profile,
					careerManager: newEmployeeSalary?.employeeCurrentCareer?.reportsTo,
					careerJobRole: newEmployeeSalary?.employeeCurrentCareer?.xphrProfileEligibleRole || '',
					careerJobRoleLevel: newEmployeeSalary?.employeeCurrentCareer?.xphrProfileRoleLevel || '',
					holdersNumber: (newEmployeeSalary?.employeeInfo?.xphrHoldersNumber || '1') as string,
					dependentsNumber: Number(newEmployeeSalary?.employeeInfo?.xphrNumberOfDependents) || 0,
					disabled: newEmployeeSalary?.employeeInfo?.xphrDisable,
					maritalStatus: newEmployeeSalary?.employeeInfo?.xPHRMaritalStatus || '',
					tsuExemption: newEmployeeSalary?.currentCSC?.xphrSocSecurityExemption > 0,
					token: (newEmployeeSalary && newEmployeeSalary.token ? newEmployeeSalary.token : null) as string,
					taxZone: newEmployeeSalary?.currentCSC?.xphrTaxzone
				});

				// update individually each field with the new data
				setValuesFromCurrentProposal(
					newEmployeeSalary,
					getValues,
					reset,
					selectedCompany as ICompanyInfo,
					currentDefaultValues,
					false
				);

				// update the form selects and remake the calculations API requests
				updateFormAfterLoad({
					company: newEmployeeSalary?.company?.searchKey as string,
					businessUnit: newEmployeeSalary?.employeeInfo?.xPHRAdOrgBunit,
					division: newEmployeeSalary?.employeeInfo?.xphrAdOrgDivision,
					department: newEmployeeSalary?.employeeInfo?.xphrAdOrgDepartment,
					careerPosition: newEmployeeSalary?.employeeCurrentCareer?.profile,
					careerJobRole: newEmployeeSalary?.employeeCurrentCareer?.xphrProfileEligibleRole || '',
					careerJobRoleLevel: newEmployeeSalary?.employeeCurrentCareer?.xphrProfileRoleLevel || '',
					holdersNumber: newEmployeeSalary?.employeeInfo?.xphrHoldersNumber || 1,
					dependentsNumber: newEmployeeSalary?.employeeInfo?.xphrNumberOfDependents || 0,
					disabled: newEmployeeSalary?.employeeInfo?.xphrDisable,
					maritalStatus: newEmployeeSalary?.employeeInfo?.xPHRMaritalStatus || '',
					tsuExemption: newEmployeeSalary?.currentCSC?.xphrSocSecurityExemption > 0
				} as ICollaborationProposal);
			}
		}

		setValue('prizeBonus', watch('prizeBonus'), { shouldValidate: true });
	}, [newEmployeeSalary, cscHash]);

	//* USE EFFECT TO UPDATE FORM WHEN THE IRS IS CALCULATED
	useEffect(() => {
		if (irsValue) {
			setValue('irsToApply', irsValue.irs as string, { shouldValidate: true });
			setValue('annualPrizeIRS', irsValue.irsPrize as string, { shouldValidate: true });
			setValue('irsTwelfth', irsValue.irsTwelfth as string, { shouldValidate: true });
		}
	}, [irsValue]);

	//* USE EFFECT TO UPDATE ANNUAL GROSS VALUE
	useEffect(() => {
		if (proposalAnnualGross) {
			const currentForm = watch();
			const { annualGrossValue } = proposalAnnualGross;
			setValue('annualGrossValue', annualGrossValue as string, { shouldValidate: true });
			setValue(
				'prizeBonusPercentage',
				(Number(currentForm.prizeBonus) / (Number(currentForm.prizeBonus) + Number(annualGrossValue))) * 100 ||
					0,
				{ shouldValidate: true }
			);
		}
	}, [proposalAnnualGross]);

	// ? SUBMIT PROPOSAL
	const onSubmit = (data: any): any => {
		setIsSubmitting(true);
		data = { ...data, formFields };
		if (data.company == 'HR') {
			data.foodAllowance = 0;
			data.foodAllowanceDays = 0;
			data.hasTwelfths = false;
			data.ihtPercentage = 0;
			data.ihtValue = 0;
			data.travelPass = 0;
			data.monthsApplicable = 0;
			data.additionalExpenses = '0';
		}
		if (isEdit) {
			submitUpdateEmployeeSalary(data, cscHash, Number(isEdit));
		} else {
			submitNewEmployeeSalary(data, data.token as string);
		}
		reset(data);
		goToPage(`/admin/salariesAndContracts/employeeSalaryCategories/details/${cscHash}`);
	};

	//Function used when user uses filters in the searchableSelect in career financial that has the job role levels
	const tableFilterSearch = (array, searchValue) => {
		//No filter
		if (searchValue == '') {
			// Check if the current level exists in the array
			const currentLevelFound = array.some(element => element.id == watch('careerJobRoleLevel'));

			// Define a function to handle the filtering logic
			const shouldIncludeRow = row => {
				if (currentLevelFound) {
					// Prevent monthlyNetAmount as null
					if (newEmployeeSalary?.currentCSC?.xphrMonthlyNetAmount != null) {
						return (
							row.id !== watch('careerJobRoleLevel') &&
							row.monthlyNet >= newEmployeeSalary?.currentCSC?.xphrMonthlyNetAmount &&
							row.isEmpty !== true
						);
					}
					return row.id !== watch('careerJobRoleLevel') && row.isEmpty !== true;
				} else {
					// Prevent monthlyNetAmount as null
					if (newEmployeeSalary?.currentCSC?.xphrMonthlyNetAmount != null) {
						return (
							row.monthlyNet >= newEmployeeSalary?.currentCSC?.xphrMonthlyNetAmount &&
							row.isEmpty !== true
						);
					}
					return row;
				}
			};

			// Apply the filter using the defined function
			return array.filter(shouldIncludeRow);
		}
		//Filter Before current career position
		if (searchValue == 'before') {
			//Gets job levels that are after current position
			const filteredArray = array.filter(
				row =>
					row.id !== watch('careerJobRoleLevel') &&
					row.monthlyNet >= newEmployeeSalary?.currentCSC?.xphrMonthlyNetAmount &&
					row.isEmpty !== true
			);
			//Gets a number of job levels that are before current position
			const additionalRows = array
				.filter(
					row =>
						row.id !== watch('careerJobRoleLevel') &&
						row.monthlyNet <= newEmployeeSalary?.currentCSC?.xphrMonthlyNetAmount &&
						row.isEmpty !== true
				)
				.sort((a, b) => {
					// Modify this sort function to fit the criteria you want to sort by
					// Example: sorting by monthlyNet in descending order
					if (a.monthlyNet > b.monthlyNet) return -1;
					if (a.monthlyNet < b.monthlyNet) return 1;
					return 0;
				})
				.slice(0, filtersRows == '' ? 10 : filtersRows); // Take only the first 10 rows that satisfy the condition

			return [
				...additionalRows.sort((a, b) => {
					// Modify this sort function to fit the criteria you want to sort by
					// Example: sorting by monthlyNet in descending order
					if (a.monthlyNet > b.monthlyNet) return 1;
					if (a.monthlyNet < b.monthlyNet) return -1;
					return 0;
				}),
				...filteredArray
			];
		}
		//Favorite (preferable)
		if (searchValue == 'favorite')
			return array.filter(
				row => row.id !== watch('careerJobRoleLevel') && row.preferable && row.isEmpty !== true
			);
		//Show all rows
		if (searchValue == 'all') return array;
	};

	//Filters available in career job role levels table
	const filters = [
		{
			name: 'Show only favorite',
			value: 'favorite'
		},
		{
			name: 'Show rows before current position',
			value: 'before'
		},
		{
			name: 'Show all rows',
			value: 'all'
		}
	];

	// SELECT FIELDS DEPENDENT OF COMPANY - CAREER NAMES
	const careerFields = useMemo(() => {
		const payload = getCareerFields(selectedCompany, selectHelper);

		const levelsListPreFilter = payload.careerJobRoleLevel.filter(
			e => e.id == watch('careerJobRoleLevel') || e.isEmpty === true
		);

		const levelsListPostFilter = payload.careerJobRoleLevel;
		payload.showWarningJobLevels = levelsListPreFilter != levelsListPostFilter;

		if (selectHelper.department && payload.careerPosition?.length === 0 && employeesSalaryActions === null) {
			addNotification('warning', 'There are no Careers for this department', 5);
		}
		return payload;
	}, [newEmployeeSalary, selectedCompany, selectHelper]);

	//Check if the current career of the user is inside of the option available to select in Career info section
	useEffect(() => {
		if (
			(careerFields?.careerPosition?.length > 0 ||
				careerFields?.careerJobRole?.length > 0 ||
				careerFields?.careerJobRoleLevel.length > 0) &&
			!firstChanged
		) {
			if (
				careerFields?.careerPosition?.find(
					e =>
						e.id === newEmployeeSalary?.proposal?.careerPosition ||
						e.id === newEmployeeSalary?.employeeCurrentCareer?.profile
				) === undefined
			)
				setValue('careerPosition', '');

			if (
				careerFields?.careerJobRole?.find(
					e =>
						e.id === newEmployeeSalary?.proposal?.careerJobRole ||
						e.id === newEmployeeSalary?.employeeCurrentCareer?.xphrProfileEligibleRole
				) === undefined
			)
				setValue('careerJobRole', '');

			if (
				careerFields?.careerJobRoleLevel?.find(
					e =>
						e.id === newEmployeeSalary?.proposal?.careerJobRoleLevel ||
						e.id === newEmployeeSalary?.employeeCurrentCareer?.xphrProfileRoleLevel
				) === undefined
			)
				setValue('careerJobRoleLevel', '');
			setIsFirstChanged(true);
		}
	}, [careerFields, firstChanged]);

	// SELECT FIELDS DEPENDENT OF COMPANY
	const selectOptions = useMemo(
		() => ({
			careerManagers:
				selectedCompany?.careerManagers
					?.filter(e => e.xPHRAdOrgBunit === selectHelper.businessUnit)
					.map(e => ({ id: e.id, label: e.name })) || [],
			collaborationProposals:
				selectedCompany?.collaborationProposals
					?.sort(
						(a, b) =>
							new Date(parseDateToManageSaf(a.proposalDate)).getTime() -
							new Date(parseDateToManageSaf(b.proposalDate)).getTime()
					)
					.reverse()
					.map(e => ({
						id: e.id,
						label: createLoadFromOtherUserSelectLabel(classes, e),
						proposal: e
					})) || [],
			customers: selectedCompany?.customers?.map(e => ({ id: e.id, label: e.name })) || [],
			contractPositions: selectedCompany?.contractPositions?.map(e => ({ id: e.id, label: e.name })) || [],
			referralUsers: selectedCompany?.referralUsers?.map(e => ({ id: e.searchKey, label: e.name })) || [],
			allEmployeesCSCs: newEmployeeSalary?.allEmployeeCSCs || []
		}),
		[newEmployeeSalary, selectHelper]
	);

	// FIXED SELECT FIELDS
	const selectFixedOptions = useMemo(
		() => proposalsGetFixedSelectOptions(newEmployeeSalary as unknown as IGetNewProposal),
		[newEmployeeSalary]
	);

	// UPDATE SELECT CHILD'S IN CASCADE WHEN NEEDED
	const handleSelectHelper = (field: string, value: string | null) => {
		updateSelectsInCascade(setSelectHelper, setValue, field, value); // FIXME:
		if (field === 'careerJobRoleLevel') {
			setValue('careerJobRoleLevel', value!, { shouldValidate: true });
		}
	};
	const populateFormWithProposal = useMemo(
		() => (value: number) => {
			if (selectOptions?.collaborationProposals) {
				// find selected proposal in list
				const propSelected = selectOptions.collaborationProposals.find(e => e.id === value);

				if (!propSelected) return;

				const { proposal } = propSelected;

				if (!proposal) return;

				const proposalToCopy = {
					baseValue: proposal.baseValue,
					irsToApply: proposal.irsToApply,
					ihtValue: proposal.ihtValue,
					ihtPercentage: proposal.ihtPercentage,
					hasTwelfths: proposal.hasTwelfths,
					vacationsTwelfth: proposal.vacationsTwelfth,
					christmasTwelfth: proposal.christhmasTwelfth,
					totalTaxableValue: proposal.totalTaxableValue,
					foodAllowanceType: proposal.foodAllowanceType,
					foodAllowance: proposal.foodAllowance,
					foodAllowanceDays: proposal.foodAllowanceDays,
					nationalAllowance: proposal.nationalAllowance,
					nationalAllowanceDays: proposal.nationalAllowanceDays,
					internationalAllowance: proposal.internationalAllowance,
					internationalAllowanceDays: proposal.internationalAllowanceDays,
					travelPass: proposal.travelPass,
					additionalExpenses: proposal.additionalExpenses,
					nurseryCheck: proposal.nurseryCheck,
					lifeInsurance: proposal.lifeInsurance,
					prizeBonus: proposal.prizeBonus,
					prizeBonusGoals: proposal.prizeBonusGoals,
					projects: proposal.projects?.map(({ id, ...e }) => e) || [],
					prizeBonusPercentage: proposal.prizeBonusPercentage,
					monthsApplicable: proposal.monthsApplicable,
					annualPrizeIRS: proposal.annualPrizeIRS,
					totalComponentsPrizePercentage: proposal.totalComponentsPrizePercentage,
					totalComponentsPrizeValue: proposal.totalComponentsPrizeValue
				};

				// update individually each field with the new data
				setValuesFromProposal(proposalToCopy as unknown as ICollaborationProposal, reset, watch, false);

				// update the form selects and remake the calculations API requests
				updateFormAfterLoad();
			}
		},
		[selectOptions.collaborationProposals]
	);

	// PAGE FOOTER ACTIONS
	const footerActions = useMemo(
		() => [
			{
				id: 'cancel',
				label: 'Cancel',
				left: true,
				onClick: () => {
					clearProposalsFormsData();

					goToPage(`/admin/salariesAndContracts/employeeSalaryCategories/details/${cscHash}`);
				},
				variant: 'outlined',
				disabled: false
			},
			...(tabSelected !== 0
				? [
						{
							id: 'previous',
							label: 'Previous',
							// left: true,
							onClick: () => {
								setTabSelected(tabSelected - 1);
								scrollToTopRef.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start'
								});
							},
							variant: 'outlined',
							disabled: false
						}
				  ]
				: []),
			...(tabSelected !== 4
				? [
						{
							id: 'next',
							label: 'Next',
							onClick: async () => {
								setTabSelected(tabSelected + 1);
								/* Checking if the tabSelected is in the formWarnings array. If it is, it is removing it from
								the array. */
								if (formWarnings.includes(tabSelected)) {
									setFormWarnings(formWarnings.filter(e => e !== tabSelected));
								}
								scrollToTopRef.current.scrollIntoView({
									behavior: 'smooth',
									block: 'start'
								});
								await trigger('prizeBonusGoals');
							},
							variant: 'contained',
							disabled: false
						}
				  ]
				: []),
			...(tabSelected === 4
				? [
						{
							id: 'submit',
							label: 'Submit',
							onClick: () => {},
							type: 'submit',
							form: 'form-new-employee-salary',
							variant: 'contained',
							disabled: getLSField('impersonate_userInfo')
						}
				  ]
				: [])
		],
		[tabSelected, cscHash]
	);

	let hasBaseValueChanges = false;
	if (watch().baseValue !== initialBaseValue) {
		hasBaseValueChanges = true;
	}

	if (error) return handleErrorPage(error, clearCscError);
	const isLoading = newEmployeeSalary === null || selectedCompany === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{(loading || loadingProposal) && <LoadingOverlay />}
			<div className={classes.root}>
				<form
					ref={scrollToTopRef}
					id="form-new-employee-salary"
					className={classes.pageForm}
					onSubmit={e => {
						e.preventDefault();
						setErrorCheck(true);
						return handleSubmit(() => onSubmit(watch()))(e);
					}}>
					<FormSteps
						steps={tabs}
						selected={tabSelected}
						errors={formWarnings}
						style={isTablet ? { paddingTop: '30px' } : { paddingTop: '10px' }}
					/>

					<Scrollbars
						ref={scrollbarRef}
						style={{ height: 'calc(90vh - 240px)', overflowX: 'hidden' }}
						renderTrackHorizontal={(props: any) => (
							<div {...props} style={{ display: 'none' }} className="track-horizontal" />
						)}>
						{tabs.map(tab => {
							const commonProps = {
								type: CSC,
								selectOptions: {
									...selectOptions,
									...careerFields,
									...selectFixedOptions
								},
								initialFormData: newEmployeeSalary,
								referenceData,
								getAnnualGrossValue,
								initFinancialValues,
								updateFoodAllowanceValue,
								populateFormWithProposal,
								handleSelectHelper,
								// react-hook-form helpers
								control,
								errors,
								reset,
								resetField,
								getValues,
								setValue,
								watch,
								clearErrors
							};
							return (
								<TabPanel key={`proposal_tab_container_${tab.id}`} value={tabSelected} index={tab.id}>
									{tab.id === 0 && (
										<General
											{...commonProps}
											{...{ updateIRSTax }}
											{...{ isContractor }}
											{...{ setIsContractor }}
											{...{ setTabs }}
											{...{ isCSC }}
											{...{ formFields }}
											{...{ companyDomain }}
										/>
									)}
									{tab.id === 1 && (
										<CareerFinancial
											{...commonProps}
											{...{ updateIRSTax }}
											{...{ newEmployeeSalary }}
											{...{ isContractor }}
											{...{ setIsContractor }}
											{...{ setTabs }}
											{...{ isCSC }}
											{...{ formFields }}
											{...{ tableFilterSearch }}
											{...{ filters }}
											filtersRows={{ rows: filtersRows, setFiltersRows }}
										/>
									)}
									{tab.id === 2 && (
										<OtherBenefits {...commonProps} {...{ updateIRSTax }} {...{ formFields }} />
									)}
									{tab.id === 3 && (
										<ContractCompany
											{...commonProps}
											{...{ isContractor }}
											{...{ setIsContractor }}
											{...{ setTabs }}
											{...{ isCSC }}
											{...{ formFields }}
										/>
									)}
									{tab.id === 4 && (
										<Summary
											additionalErrors={errors}
											handleWarning={(names: unknown, tabsWithWarn: number[]) =>
												setFormWarnings(tabsWithWarn)
											}
											{...commonProps}
											{...{ isContractor }}
											{...{ setIsContractor }}
											{...{ setTabs }}
											{...{ isCSC }}
											{...{ formFields }}
											isCollabType={false}
										/>
									)}
								</TabPanel>
							);
						})}
					</Scrollbars>
				</form>
			</div>
			<ActionFooter actions={footerActions} />
			<Prompt
				when={
					(isTouchedFieldsUsed(touchedFields, getValues) && !isSubmitting) ||
					(hasBaseValueChanges && !isSubmitting)
				}
				message="Are you sure you want to leave without saving?"
			/>
		</>
	);
};

export default withLayout(NewEmployeeSalary);
