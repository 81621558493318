/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';

//* EXTERNAL LIBS --> MUI
import { CheckRounded, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { sizes } from 'xpand-ui/utils/handlers';

//* EXTERNAL LIBS --> XPAND-UI
import { Numeric } from 'xpand-ui/forms';

import { ICompanyInfo } from 'typings/store/admin/proposals';
import { IDepartmentData } from 'typings/store/admin/budget';

import { INodeSelected } from './EditBudget';

interface ITreeData {
	id: string;
	name: ReactNode;
	endLabel?: ReactNode;
	data?: ITreeData[];
}
//* QUARTERS LABEL */
export const createHeader = (classes: any, budgetInfo: any) => (
	<>
		<Grid item {...sizes[2]} />
		<Grid item className={classes.budgetTableLabel} {...sizes[2]}>
			{(budgetInfo && budgetInfo.columnHeaders[0]) || ''}
		</Grid>
		<Grid item className={classes.budgetTableLabel} {...sizes[2]}>
			{(budgetInfo && budgetInfo.columnHeaders[1]) || ''}
		</Grid>
		<Grid item className={classes.budgetTableLabel} {...sizes[2]}>
			{(budgetInfo && budgetInfo.columnHeaders[2]) || ''}
		</Grid>
		<Grid item className={classes.budgetTableLabel} {...sizes[2]}>
			{(budgetInfo && budgetInfo.columnHeaders[3]) || ''}
		</Grid>
		<Grid item className={classes.budgetTableLabel} {...sizes[2]}>
			{(budgetInfo && budgetInfo.columnHeaders[4]) || ''}
		</Grid>
		<span className={classes.divider} />
	</>
);

//* INCOME */
export const createIncomeLine = (
	classes: any,
	tableData: any,
	{ department }: INodeSelected,
	formName: string,
	control: any,
	errors: any
) => (
	<>
		<Grid item className={classes.budgetTableLabel} {...sizes[2]}>
			INCOME
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required name={`${formName}.incomeQ1`} money control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.incomeQ1`}
					className={classes.readOnlyInput}
					value={tableData.incomeQ1 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.incomeQ2`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.incomeQ2`}
					className={classes.readOnlyInput}
					value={tableData.incomeQ2 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.incomeQ3`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.incomeQ3`}
					className={classes.readOnlyInput}
					value={tableData.incomeQ3 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.incomeQ4`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.incomeQ4`}
					className={classes.readOnlyInput}
					value={tableData.incomeQ4 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.incomeQ5`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.incomeQ5`}
					className={classes.readOnlyInput}
					value={tableData.incomeQ5 || '0'}
				/>
			)}
		</Grid>
		<span className={classes.divider} style={{ opacity: 0.6 }} />
	</>
);

//* COSTS */
export const createCostsLine = (classes: any) => (
	<>
		<Grid item className={classes.budgetTableLabel} {...sizes[2]}>
			COSTS
		</Grid>
		<Grid item {...sizes[10]} />
	</>
);

//* EMPLOYEE COSTS */
export const createEmployeeCostsLine = (
	classes: any,
	tableData: any,
	{ department }: INodeSelected,
	formName: string,
	control: any,
	errors: any
) => (
	<>
		<Grid item className={classes.budgetCategoryType} {...sizes[2]}>
			Employee Costs
		</Grid>

		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.employeeCostQ1`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.employeeCostQ1`}
					className={classes.readOnlyInput}
					value={tableData.employeeCostQ1 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.employeeCostQ2`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.employeeCostQ2`}
					className={classes.readOnlyInput}
					value={tableData.employeeCostQ2 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.employeeCostQ3`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.employeeCostQ3`}
					className={classes.readOnlyInput}
					value={tableData.employeeCostQ3 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.employeeCostQ4`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.employeeCostQ4`}
					className={classes.readOnlyInput}
					value={tableData.employeeCostQ4 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.employeeCostQ5`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.employeeCostQ5`}
					className={classes.readOnlyInput}
					value={tableData.employeeCostQ5 || '0'}
				/>
			)}
		</Grid>
	</>
);

//* NON EMPLOYEE COSTS */
export const createNonEmployeeCostsLine = (
	classes: any,
	tableData: any,
	{ department }: INodeSelected,
	formName: string,
	control: any,
	errors: any
) => (
	<>
		<Grid item className={classes.budgetCategoryType} {...sizes[2]}>
			Non Employee Costs
		</Grid>

		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.nonEmployeeCostQ1`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.nonEmployeeCostQ1`}
					className={classes.readOnlyInput}
					value={tableData.nonEmployeeCostQ1 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.nonEmployeeCostQ2`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.nonEmployeeCostQ2`}
					className={classes.readOnlyInput}
					value={tableData.nonEmployeeCostQ2 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.nonEmployeeCostQ3`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.nonEmployeeCostQ3`}
					className={classes.readOnlyInput}
					value={tableData.nonEmployeeCostQ3 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.nonEmployeeCostQ4`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.nonEmployeeCostQ4`}
					className={classes.readOnlyInput}
					value={tableData.nonEmployeeCostQ4 || '0'}
				/>
			)}
		</Grid>
		<Grid item {...sizes[2]}>
			{department ? (
				<Numeric required money name={`${formName}.nonEmployeeCostQ5`} control={control} errors={errors} />
			) : (
				<Numeric
					readOnly
					money
					name={`${formName}.read.nonEmployeeCostQ5`}
					className={classes.readOnlyInput}
					value={tableData.nonEmployeeCostQ5 || '0'}
				/>
			)}
		</Grid>
		<span className={classes.divider} style={{ opacity: 0.6 }} />
	</>
);

//* TOTALS LINE */
export const createTotalsLine = (classes: any, tableData: any) => (
	<>
		<Grid item {...sizes[2]} />

		<Grid item {...sizes[2]}>
			<Numeric readOnly money className={classes.readOnlyInput} value={tableData.totalQ1 || '0'} />
		</Grid>
		<Grid item {...sizes[2]}>
			<Numeric readOnly money className={classes.readOnlyInput} value={tableData.totalQ2 || '0'} />
		</Grid>
		<Grid item {...sizes[2]}>
			<Numeric readOnly money className={classes.readOnlyInput} value={tableData.totalQ3 || '0'} />
		</Grid>
		<Grid item {...sizes[2]}>
			<Numeric readOnly money className={classes.readOnlyInput} value={tableData.totalQ4 || '0'} />
		</Grid>
		<Grid item {...sizes[2]}>
			<Numeric readOnly money className={classes.readOnlyInput} value={tableData.totalQ5 || '0'} />
		</Grid>
	</>
);

export const createTreeFromLines = (
	classes: any,
	lines: any,
	formData: any,
	nodeSelected: any,
	setNodeSelected: (nodeId: INodeSelected) => void
) => {
	if (!lines || Object.keys(lines).length === 0) return [];

	const CheckIcon = <CheckRounded color="primary" />;

	const SelectRow = ({ children, id }: any) => (
		<span className={classes.labelContainer}>
			{children}
			<span className={classes.selectButtonContainer} style={{ pointerEvents: 'auto' }}>
				<Button
					className={classes.selectButton}
					variant="text"
					endIcon={<KeyboardArrowRightOutlined />}
					onClick={() => setNodeSelected(id)}>
					Select
				</Button>
			</span>
		</span>
	);

	const getBadgeNumber = (obj: any) =>
		obj &&
		(JSON.stringify(obj).match(/hasData/g) || []).length > 0 && (
			<div className={classes.circle}>
				<span className={classes.circleContent}>{(JSON.stringify(obj).match(/hasData/g) || []).length}</span>
			</div>
		);

	const hasData = {};
	Object.keys(formData.lines).forEach(c => {
		Object.keys(formData.lines[c].data).forEach(b => {
			Object.keys(formData.lines[c].data[b].data).forEach(d => {
				Object.keys(formData.lines[c].data[b].data[d].data).forEach(dep => {
					const { departmentId, departmentalBudgetFlag, id, projectLines, ...payload } =
						formData.lines[c].data[b].data[d].data[dep].data;

					let noData = true;
					Object.values(payload).forEach(values => {
						if (noData) {
							noData = Number(values) === 0;
						}
					});
					if (!noData) {
						hasData[c] = hasData[c] || {};
						hasData[c][b] = hasData[c][b] || {};
						hasData[c][b][d] = hasData[c][b][d] || {};
						hasData[c][b][d][dep] = 'hasData';
					}
				});
			});
		});
	});

	/**
	 * LOOP TROUGH THE COMPANIES
	 */
	const newTree: ITreeData[] = Object.keys(lines).map(companyId => {
		const companyHasData = hasData[companyId];
		const isSelectedComp =
			nodeSelected.company === companyId &&
			!nodeSelected.businessUnit &&
			!nodeSelected.division &&
			!nodeSelected.department;
		const newCompany: ITreeData = {
			id: companyId,
			name: <span className={(isSelectedComp && classes.selectedLabel) || ''}>{lines[companyId].name}</span>,
			endLabel: (
				<SelectRow
					id={{
						company: companyId,
						businessUnit: null,
						division: null,
						department: null
					}}>
					{getBadgeNumber(companyHasData)}
					{/* {isSelectedComp && CheckIcon} */}
				</SelectRow>
			),
			data: []
		};

		/**
		 * LOOP TROUGH THE BUSSINES UNITS
		 */
		const companyBusinessUnits =
			Object.keys(lines[companyId].data).map(businessUnitId => {
				const businessUnitHasData = companyHasData && hasData[companyId][businessUnitId];
				const isSelectedBu =
					nodeSelected.company &&
					nodeSelected.businessUnit === businessUnitId &&
					!nodeSelected.division &&
					!nodeSelected.department;
				const newBusinessUnit: ITreeData = {
					id: businessUnitId,
					name: (
						<span className={(isSelectedBu && classes.selectedLabel) || ''}>
							{lines[companyId].data[businessUnitId].name}
						</span>
					),
					endLabel: (
						<SelectRow
							id={{
								company: companyId,
								businessUnit: businessUnitId,
								division: null,
								department: null
							}}>
							{getBadgeNumber(businessUnitHasData)}
							{/* {isSelectedBu && CheckIcon} */}
						</SelectRow>
					),
					data: []
				};

				/**
				 * LOOP TROUGH THE DIVISIONS
				 */
				const businessUnitsDivisions =
					Object.keys(lines[companyId].data[businessUnitId].data).map(divisionId => {
						const divisionHasData = businessUnitHasData && hasData[companyId][businessUnitId][divisionId];
						const divName = lines[companyId].data[businessUnitId].data[divisionId].name;
						const isSelectedDiv =
							nodeSelected.company &&
							nodeSelected.businessUnit &&
							nodeSelected.division === divisionId &&
							!nodeSelected.department;
						const newDivision: ITreeData = {
							id: divisionId,
							name: <span className={(isSelectedDiv && classes.selectedLabel) || ''}>{divName}</span>,
							endLabel: (
								<SelectRow
									id={{
										company: companyId,
										businessUnit: businessUnitId,
										division: divisionId,
										department: null
									}}>
									{getBadgeNumber(divisionHasData)}
									{/* {isSelectedDiv && CheckIcon} */}
								</SelectRow>
							),
							data: []
						};

						/**
						 * LOOP TROUGH THE DEPARTMENTS
						 */
						const divisionDepartments: ITreeData[] =
							Object.keys(lines[companyId].data[businessUnitId].data[divisionId].data).map(
								departmentId => {
									const departmentHasData =
										divisionHasData && hasData[companyId][businessUnitId][divisionId][departmentId];
									const isSelectedDep =
										nodeSelected.company &&
										nodeSelected.businessUnit &&
										nodeSelected.division &&
										nodeSelected.department === departmentId;
									const depName =
										lines[companyId].data[businessUnitId].data[divisionId].data[departmentId]
											.name || '';
									const newDepartment: ITreeData = {
										id: departmentId,
										name: (
											<span className={(isSelectedDep && classes.selectedLabel) || ''}>
												{depName.replace(`${divName} - `, '')}
											</span>
										),
										endLabel: (
											<SelectRow
												id={{
													company: companyId,
													businessUnit: businessUnitId,
													division: divisionId,
													department: departmentId
												}}>
												{departmentHasData && CheckIcon}
											</SelectRow>
										)
									};

									return newDepartment;
								}
							) || [];
						newDivision.data = divisionDepartments;
						return newDivision;
					}) || [];
				newBusinessUnit.data = businessUnitsDivisions;
				return newBusinessUnit;
			}) || [];
		newCompany.data = companyBusinessUnits;

		return newCompany;
	});
	return newTree;
};

const getDepartmentDataFromAPI = (departmentId: string, budgetLines: IDepartmentData | null) => ({
	departmentId: departmentId || null,
	id: (budgetLines && budgetLines.id) || undefined,
	departmentalBudgetFlag: false,
	incomeQ1: '0',
	incomeQ2: '0',
	incomeQ3: '0',
	incomeQ4: '0',
	incomeQ5: '0',
	employeeCostQ1: '0',
	employeeCostQ2: '0',
	employeeCostQ3: '0',
	employeeCostQ4: '0',
	employeeCostQ5: '0',
	nonEmployeeCostQ1: '0',
	nonEmployeeCostQ2: '0',
	nonEmployeeCostQ3: '0',
	nonEmployeeCostQ4: '0',
	nonEmployeeCostQ5: '0',
	...(budgetLines && { ...budgetLines })
});

export const createTreeFromCompanyTree = (companies: ICompanyInfo[], budgetLines: IDepartmentData[]) => {
	if (!companies) return [];

	const companyField = {};

	/**
	 * LOOP TROUGH THE COMPANIES
	 */
	companies.forEach(company => {
		const companyId = company.searchKey as string;
		companyField[companyId] = {
			name: company.name,
			data: {}
		};

		/**
		 * LOOP TROUGH THE BUSSINES UNITS
		 */
		company.companyTree?.childNodes.forEach(businessUnit => {
			const businessUnitId = businessUnit.organization;
			companyField[companyId].data[businessUnitId] = {
				name: businessUnit.organization$_identifier,
				data: {}
			};
			/**
			 * LOOP TROUGH THE DIVISIONS
			 */
			businessUnit.childNodes.forEach(division => {
				const divisionId = division.organization;
				companyField[companyId].data[businessUnitId].data[divisionId] = {
					name: division.organization$_identifier,
					data: {}
				};
				/**
				 * LOOP TROUGH THE DEPARTMENTS
				 */
				division.childNodes.forEach(department => {
					const departmentId = department.organization;
					companyField[companyId].data[businessUnitId].data[divisionId].data[departmentId] = {
						name: department.organization$_identifier,
						data: getDepartmentDataFromAPI(
							department.organization,
							budgetLines.find(e => e.departmentId === department.organization) as IDepartmentData
						)
					};
				});
			});
		});

		return companyField;
	});

	return companyField;
};
