/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo, Fragment } from 'react';
import { Control, useFieldArray, UseFormClearErrors, UseFormSetValue } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';

//* EXTERNAL LIBS --> MUI
import { Grid, Button, Checkbox, FormControlLabel, IconButton, FormHelperText, Divider } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import Adicionar from '../../../../../assets/icons/Adicionar.svg';

//* EXTERNAL LIBS --> XPAND-UI
import { Input, Numeric, InfoField } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { IChoosableBaseInfo } from 'typings/store/generalTypes';
import { IPrizeGoal } from 'typings/store/admin/proposals';

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';

const parseValue = (value: string | number) => parseFloat(value as string).toFixed(2);

const getPrizeCheckedValues = (selectOptions: any, prizeBonusGoals: IPrizeGoal[]) => ({
	...(selectOptions.prizeBonusGoalTypes.find((e: any) => e.id === 1) && {
		1: prizeBonusGoals?.some((e: any) => e.goalTypeId === 1)
	}),
	...(selectOptions.prizeBonusGoalTypes.find((e: any) => e.id === 2) && {
		2: prizeBonusGoals?.some((e: any) => e.goalTypeId === 2)
	}),
	...(selectOptions.prizeBonusGoalTypes.find((e: any) => e.id === 3) && {
		3: prizeBonusGoals?.some((e: any) => e.goalTypeId === 3)
	}),
	...(selectOptions.prizeBonusGoalTypes.find((e: any) => e.id === 4) && {
		4: prizeBonusGoals?.some((e: any) => e.goalTypeId === 4)
	})
});

//* COMPONENT INTERFACES
interface IPrizeBonusComponent {
	prizeBonus: number | null;
	control: Control;
	errors: Record<string, Record<string, string>>;
	watch: any;
	clearErrors: UseFormClearErrors<any>;
	setValue: UseFormSetValue<any>;
	selectOptions: any;
}

/**
 * @prizeGoalsComponentActions
 //* 01 - total percentage sum === 100
 //* 02 - each group no more than 3 bonus
 //* 03 - MAXIMUM PRIZE BONUS === 0 -> clear prize bonus fields
 //* 04 - MAXIMUM PRIZE BONUS === 0 -> close checkboxes
 //* 05 - MAXIMUM PRIZE BONUS !== 0 -> checkboxes available
 //* 06 - checkbox off-on -> show add button
 //* 06.5 --------- when turning on, add first bonus row automatically
 //* 07 -	checkbox on-off -> delete all itens of that type
 //* 08 - when changing percentage, update € of item and parent group
 //* 09 - when changing MAXIMUM PRIZE BONUS, update all € from groups and items
 //* 10 - not allow empty fields
 //* 11 - not allow empty percentages
 //* 12 - update totalComponentsPrizePercentage
 //* 13 - update totalComponentsPrizeValue
 //* 14 - when changing MONTHS APPLICABLE, update all € from groups and items
 //* 15 - load previous data from other proposal
 //* 16 - load previous data from old proposal on edit proposal
*/
//* COMPONENT
const PrizeGoals: FC<IPrizeBonusComponent> = ({
	prizeBonus,
	control,
	errors,
	watch,
	clearErrors,
	setValue,
	selectOptions
}) => {
	const classes = useStyles();

	const { fields, append, remove, replace } = useFieldArray({
		control,
		name: 'prizeBonusGoals',
		keyName: 'uniqueId'
	});

	const [percentages, setPercentages] = useState({});
	const disabled = prizeBonus === null;

	useEffect(
		() => () => {
			const formData = watch(['prizeBonus', 'monthsApplicable', 'prizeBonusGoals']);
			const prizeBonusMax =
				(formData[0] && formData[1] && (Number(formData[1]) * Number(formData[0])) / 12) || null;
			let goals = formData[2] as IPrizeGoal[];
			let percent = 0;
			let prizeTotal = 0;

			if (!goals || goals.length === 0 || !prizeBonusMax) {
				setValue('prizeBonusGoals', [], { shouldValidate: true });
				setValue('totalComponentsPrizeValue', 0, { shouldValidate: true });
				setValue('totalComponentsPrizePercentage', 0, { shouldValidate: true });
				return;
			}
			goals = goals.map(g => {
				const value = (Number(g.prizePercentage) * prizeBonusMax) / 100;
				percent += Number(g.prizePercentage);
				prizeTotal += value;
				//prizevalue = value >= 100 ? value : 0 (to use if the previous change wasn't supossed to change)
				return { ...g, prizeValue: value };
			});

			setValue('prizeBonusGoals', goals, { shouldValidate: true });
			setValue('totalComponentsPrizeValue', prizeTotal, { shouldValidate: true });
			setValue('totalComponentsPrizePercentage', percent <= 100 ? percent : 0, { shouldValidate: true });
		},
		[]
	);

	const [prizesChecked, setPrizesChecked] = useState(getPrizeCheckedValues(selectOptions, fields as IPrizeGoal[]));

	const FormDivider = useMemo(
		() => () =>
			(
				<Grid item {...sizes[12]}>
					<Divider />
				</Grid>
			),
		[]
	);

	useEffect(() => {
		if (!prizeBonus) {
			setPrizesChecked({ 1: false, 2: false, 3: false, 4: false });
			setPercentages({});
			replace([]);
		}
	}, [prizeBonus]);

	useEffect(() => {
		const newPercentage = {};
		(fields as IPrizeGoal[]).forEach(e => {
			newPercentage[e.uniqueId as string] = {
				goalType: e.goalTypeId,
				value: Number(e.prizePercentage)
			};
		});
		setPercentages(newPercentage);
	}, []);

	const totalPercentage = useMemo(() => {
		const getTotalFromType = (id: number) =>
			Object.keys(percentages).reduce(
				(total, uniqueId) =>
					percentages[uniqueId].goalType === id ? percentages[uniqueId].value + total : total,
				0
			);

		return {
			1: getTotalFromType(1),
			2: getTotalFromType(2),
			3: getTotalFromType(3),
			4: getTotalFromType(4),
			total: Object.keys(percentages).reduce((total, uniqueId) => percentages[uniqueId].value + total, 0)
		};
	}, [percentages]);

	if (!selectOptions) return null;

	// TABLE CUSTOM FILTER STATUS CHANGER
	const handleStatusFilterPropsChange = (event: React.ChangeEvent<HTMLInputElement>, goalTypeId: number) => {
		setPrizesChecked({ ...prizesChecked, [event.target.name]: event.target.checked });
		if (event.target.checked) {
			append({
				goalTypeId,
				goalDetails: '',
				prizePercentage: '0',
				prizeValue: '0',
				uniqueId: uuidv4()
			});
		} else {
			// get the index to delete
			const idsToDelete = (fields as IPrizeGoal[]).reduce(
				(idList: number[], currentValue: IPrizeGoal, index: number) =>
					currentValue.goalTypeId === goalTypeId ? [...idList, index] : idList,
				[]
			);

			const newPercentage = { ...percentages };
			idsToDelete.forEach((id: number) => {
				const prizeToDelete = fields[id.toString()];
				if (!prizeToDelete) return;

				delete newPercentage[prizeToDelete.uniqueId];
			});

			setPercentages(newPercentage);
			remove(idsToDelete);
		}
	};

	const errorUp100 = totalPercentage.total > 100;

	const { prizeBonusGoalTypes } = selectOptions;

	return (
		<>
			<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
				{errorUp100 && <FormHelperText error>The sum of all prize goals must be 100%</FormHelperText>}
				{errors.prizeBonusGoals?.message && !errorUp100 && (
					<FormHelperText error className={classes.errorPrizeSum}>
						{errors.prizeBonusGoals.message}
					</FormHelperText>
				)}
				{prizeBonusGoalTypes?.map((goalType: IChoosableBaseInfo) => (
					<Fragment key={`prizeGoal_${goalType.id}`}>
						<Grid item {...sizes[12]}>
							{/* CHECKBOX and TOTALS HEADER */}
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								spacing={1}
								alignItems="flex-start"
								className={classes.group}>
								<Grid item {...sizes[8]}>
									<FormControlLabel
										className={classes.textAreaHeader}
										label={goalType.name as string}
										control={
											<Checkbox
												color="primary"
												disabled={
													disabled ||
													(!prizesChecked[goalType.id] && totalPercentage.total >= 100)
												}
												checked={Boolean(prizesChecked[goalType.id])}
												onChange={event =>
													handleStatusFilterPropsChange(event, Number(goalType.id))
												}
												name={`${goalType.id}`}
											/>
										}
									/>
								</Grid>
								<Grid item {...sizes[2]}>
									<InfoField
										className={classes.percentageHeader}
										label={`${totalPercentage[goalType.id] || 0} %`}
										value=""
									/>
								</Grid>
								<Grid item {...sizes[2]}>
									<InfoField
										className={classes.valueHeader}
										label={`${
											(totalPercentage[goalType.id] &&
												parseValue(
													(Number(totalPercentage[goalType.id]) / 100) * Number(prizeBonus)
												)) ||
											0
										} €`}
										value=""
									/>
								</Grid>
							</Grid>
							{/* CHECKBOX and TOTALS COMPONENTS */}
							<Grid
								item
								{...sizes[12]}
								className={clsx(classes.animation, {
									[classes.show]: true
									// [classes.show]: Boolean(prizesChecked[pg.id])
								})}>
								{(fields as IPrizeGoal[])?.map((item, index: number) => {
									if (item.goalTypeId !== goalType.id) return null;

									const name = `prizeBonusGoals.${index}.`;

									return (
										<Grid
											container
											key={item.uniqueId}
											className={classes.inputsGroup}
											direction="row"
											justifyContent="flex-start"
											spacing={2}
											alignItems="flex-start">
											<Grid item {...sizes[7]}>
												<Input
													label=" "
													name={`${name}goalDetails`}
													multiline
													placeholder="Goal description details"
													minRows={2}
													maxRows={4}
													control={control}
													errors={errors}
												/>
											</Grid>
											<Grid item {...sizes[2]} className={classes.percentage}>
												<Numeric
													required
													percentage
													name={`${name}prizePercentage`}
													label=""
													additionalOnBlur={percentage => {
														setPercentages(prev => ({
															...prev,
															[item.uniqueId as string]: {
																goalType: goalType.id,
																value: Number(percentage)
															}
														}));
														if (errors.prizeBonusGoals?.type === 'not-100-percent') {
															clearErrors('prizeBonusGoals');
														}
													}}
													control={control}
													errors={errors}
												/>
											</Grid>
											<Grid item {...sizes[2]} className={classes.value}>
												<Numeric
													readOnly
													money
													value={
														prizeBonus && percentages[item.uniqueId as string]
															? prizeBonus *
															  (percentages[item.uniqueId as string].value / 100)
															: 0
													}
												/>
											</Grid>
											<Grid item {...sizes[1]} className={classes.value}>
												<IconButton
													onClick={() => {
														setPercentages(prev => {
															const aux = { ...prev };
															delete aux[item.uniqueId as string];
															return aux;
														});
														remove(index);
													}}>
													<Delete />
												</IconButton>
											</Grid>
										</Grid>
									);
								})}
							</Grid>
							{/* ADD MORE COMPONENTS */}
							<Grid
								item
								{...sizes[12]}
								className={clsx(classes.group, classes.animation, {
									[classes.show]:
										Boolean(prizesChecked[goalType.id]) &&
										(fields as IPrizeGoal[])?.filter(e => e?.goalTypeId === goalType.id).length <
											3 &&
										totalPercentage.total < 100
								})}>
								<Button
									color="primary"
									variant="text"
									endIcon={<Adicionar />}
									onClick={() =>
										append({
											goalTypeId: goalType.id,
											goalDetails: '',
											prizePercentage: '0',
											prizeValue: '0',
											uniqueId: uuidv4()
										})
									}>
									Add Goal
								</Button>
							</Grid>
						</Grid>
					</Fragment>
				))}
			</Grid>
			{(prizeBonus && <FormDivider />) || null}
			<Grid container spacing={1} justifyContent="center">
				{/* TOTAL PRIZE (%) */}
				<Grid
					item
					{...sizes[6]}
					className={clsx(classes.animation, {
						[classes.show]: prizeBonus
					})}>
					<Numeric
						readOnly
						percentage
						label="TOTAL PRIZE (%)"
						value={(totalPercentage.total && totalPercentage.total <= 100 && totalPercentage.total) || 0}
					/>
				</Grid>
				{/*  TOTAL PRIZE VALUE (€) */}
				<Grid
					item
					{...sizes[6]}
					className={clsx(classes.animation, {
						[classes.show]: prizeBonus
					})}>
					<Numeric
						readOnly
						money
						label="TOTAL PRIZE VALUE (€)"
						value={(Number(totalPercentage.total) * Number(prizeBonus)) / 100 || 0}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default PrizeGoals;
