//* EXTERNAL LIBS
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import Grid from '@mui/material/Grid';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, ActionFooter } from 'xpand-ui/core';
import { Checkbox, Input, Select } from 'xpand-ui/forms';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';
//* LOCAL COMPONENT IMPORTS
import { INewAdmissionPayload } from 'typings/store/admin/admissions';
import { handleErrorPage } from 'lib/hocs/withLayout';
import { createYupSchema, createDefaultValues } from './yupSchema';
import { useStyles } from './styles';
import { NewAdmissionProps } from '.';
import { getLSField } from 'lib/utils/cookies';
import { getFormFieldsConfig } from 'store/administration/formFields/thunks';
import { useDispatch, useSelector } from 'react-redux';

// interface INewAdmission extends NewAdmissionProps {
// 	goToPage: (path: string) => void;
// }

const spacing = 3;

const NewAdmission: FC<NewAdmissionProps> = ({
	admissions,
	getNewAdmission,
	sendNewAdmission,
	clearAdmissionsError
}) => {
	const classes = useStyles();
	const { newAdmission, loading, error } = admissions;
	const { width } = useWindowDimensions();
	const isTablet = useMemo(() => width <= 1000, [width]); // default is 900 tablet
	const dispatch = useDispatch();
	const companyInfo = useSelector(state => state.company);
	const formFields = useSelector(state => state.formFields.formFieldsConfig);

	// Create YupSchema
	const [currentYupSchema, setCurrentYupSchema] = useState(() =>
		createYupSchema(companyInfo?.defaultCompanyKey ? companyInfo?.defaultCompanyKey : 'PT')
	);

	// Create DefaultValues
	const [currentDefaultValues, setCurrentDefaultValues] = useState(() =>
		createDefaultValues(companyInfo?.defaultCompanyKey ? companyInfo?.defaultCompanyKey : 'PT')
	);

	let employeeCategorySelect = newAdmission?.businessPartnerCategory?.map(item => {
		return { id: item.id, label: item.name };
	});
	employeeCategorySelect = employeeCategorySelect?.filter(item => {
		return item.label == 'Contractor' || item.label == 'Employee';
	});

	const {
		handleSubmit,
		control,
		reset,
		resetField,
		watch,
		getValues,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(currentYupSchema),
		reValidateMode: 'onChange',
		defaultValues: currentDefaultValues,
		shouldUnregister: false
	});

	const footerActions = [
		{
			id: 'submit',
			endIcon: 'submit',
			label: 'Submit',
			onClick: () => ({}),
			type: 'submit',
			form: 'form-new-admission',
			variant: 'contained',
			disabled: getLSField('impersonate_userInfo')
		}
	];
	// UseEffect required to get the new formFields, avoiding null errors.
	useEffect(() => {
		if (watch('company') !== null && watch('company') !== '') {
			dispatch(getFormFieldsConfig('Employee', watch('company'), 'New Admission Proposal'));
		}
	}, [watch('company')]);

	// UseEffect required to adjust the schemas and defaultValues whenever the company changes
	useEffect(() => {
		if (formFields) {
			setCurrentYupSchema(createYupSchema(formFields?.formFieldsConfig?.country));
			setCurrentDefaultValues(createDefaultValues(formFields?.formFieldsConfig?.country));
		}
	}, [formFields]);

	// UseEffect required to reset the form fields.
	useEffect(() => {
		resetField('fromEmploymentCenter');
	}, [currentDefaultValues, reset, watch]);

	useEffect(() => {
		if (!error && !newAdmission) {
			getNewAdmission();
		} else {
			reset({ ...getValues(), emails: newAdmission?.emails });
		}
	}, [newAdmission]);

	const onSubmit = (data: unknown) => {
		const payload = { ...(data as INewAdmissionPayload) };
		delete payload.emails;
		sendNewAdmission(payload as INewAdmissionPayload);
		reset();
	};

	if (error) return handleErrorPage(error, clearAdmissionsError);

	const isLoading = newAdmission === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<div className={isTablet ? classes.rootTablet : classes.root}>
				<form id="form-new-admission" onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={spacing}>
						{formFields?.formFieldsConfig?.email && (
							<Grid item xs={12}>
								<Input
									name="email"
									label="Email"
									helperText="Employee personal email address"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
						)}
						{formFields?.formFieldsConfig?.name && (
							<Grid item xs={12}>
								<Input
									name="name"
									label="Name"
									helperText="First and last name, latin characters allowed (ex: Pedro Gonçalves, Rui Silva)"
									required
									control={control}
									errors={errors}
								/>
							</Grid>
						)}
						{formFields?.formFieldsConfig?.fromEmploymentCenter && (
							<Grid item xs={12}>
								<Checkbox
									name="fromEmploymentCenter"
									label="FROM EMPLOYMENT CENTER"
									helperText="States if this new employee will do an Employment Center professional internship."
									control={control}
									errors={errors}
								/>
							</Grid>
						)}
						{formFields?.formFieldsConfig?.company && (
							<Grid item xs={12}>
								<Select
									name="company"
									label="Company"
									options={newAdmission.companies.map(e => ({ id: e.searchKey, label: e.name }))}
									control={control}
									errors={errors}
									required
								/>
							</Grid>
						)}
						{formFields?.formFieldsConfig?.employeeCategory && (
							<Grid item xs={12}>
								<Select
									name="employeeCategory"
									label="Employee Category"
									options={employeeCategorySelect || []}
									control={control}
									errors={errors}
									required
								/>
							</Grid>
						)}
					</Grid>
				</form>
			</div>
			<ActionFooter actions={footerActions} />
		</>
	);
};

export default NewAdmission;
