import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import {
	// new
	getNewProposal,
	getNewProposalWToken,
	// submit new
	submitNewProposal,
	// edit
	getEditProposal,
	// submit edit
	submitUpdateProposal,
	// HELPERS %%%%%%%%%%%%%%%%
	// calculate annual gross
	getProposalAnnualGrossValue,
	// calculate  irs
	getIrsValue,
	// calculate summary
	getProposalSummary,
	// Clear form
	clearProposalsFormsData,
	clearProposalsError
} from 'store/administration/proposals/thunks';

import NewProposal from './NewProposal';

const mapStateToProps = ({ proposals, company }: AppState) => ({ proposals, company });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			// new
			getNewProposal,
			getNewProposalWToken,
			// submit new
			submitNewProposal,
			// edit
			getEditProposal,
			// submit edit
			submitUpdateProposal,
			// HELPERS %%%%%%%%%%%%%%%%
			// calculate annual gross
			getProposalAnnualGrossValue,
			// calculate  irs
			getIrsValue,
			// calculate summary
			getProposalSummary,
			// Clear form
			clearProposalsFormsData,
			clearProposalsError
		},
		dispatch
	);

export type NewProposalProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NewProposal);
