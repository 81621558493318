// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			marginBottom: '90px'
		},
		container: {
			width: '1000px',
			height: '1000px',
			position: 'relative'
		},
		image: {
			display: 'inline',
			margin: '0 auto',
			height: '100%',
			width: 'auto'
		}
	})
);

export { useStyles };
