/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
//* EXTERNAL LIBS
import React, { FC, useMemo, Fragment, useState, useEffect, useCallback } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

//* EXTERNAL LIBS --> MUI
import { ListItem, List, ListItemText, Typography, Divider, IconButton, Avatar, Stack } from '@mui/material';
import { Delete as DeleteIcon, Check as CheckIcon } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-INTERNAL-APPS-LIB
import { Dialog, TabHeader, Tab, LoadingOverlay } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { IDeskCompany } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
// import { useStyles } from './styles';
import { BookingProps } from '.';
import { tabs } from './utils';

//* COMPONENT INTERFACES
interface IReservationModal extends BookingProps {
	selectionInfo: {
		currentUser: string | null;
		company: string | null;
		companyName: string | null;
		office: string | null;
		officeId: number | null;
		officeName: string | null;
	};
	isMobile: boolean;
	companies: IDeskCompany[];
	closePopup: (changes: boolean) => void;
	action: (type: string, id: number) => void;
}

//* COMPONENT
const ReservationModal: FC<IReservationModal> = ({
	selectionInfo,
	closePopup,
	action,
	companies,
	isMobile,
	personalInfo,
	getEmployeeBookings
}) => {
	// const classes = useStyles();
	const { loading, employeeBookings } = personalInfo;
	const [tabSelected, setTabSelected] = useState(0);
	const [submited, setSubmited] = useState({});

	/* Checking if employeeBookings is not null, if it is not null, it will call the getEmployeeBookings
	function. */
	useEffect(() => {
		if (!employeeBookings) {
			getEmployeeBookings(selectionInfo.officeId as number);
		}
	}, [employeeBookings]);

	/* return an Array of all the office seats with the information of the area and its office on the payload */
	const offices = useMemo(() => {
		if (!companies) return [];

		const seats: any = [];
		companies.forEach(comp => {
			const companyKey = comp.searchKey;
			comp.offices.forEach(office => {
				const officeKey = office.searchKey;
				office.zones.forEach(zone => {
					zone.seats.forEach(seat => {
						seats.push({
							id: seat.id,
							name: seat.name,
							type: seat.type,
							companyKey,
							officeKey
						});
					});
				});
			});
		});
		return seats;
	}, []);

	const filtersModalActions = [
		{
			id: 'cancel',
			label: 'Close',
			color: 'secondary',
			variant: 'text',
			onClick: () => closePopup(Object.keys(submited).length !== 0)
		}
	];

	const BookingItem = useCallback(
		(item: any, tab: number) => {
			const startDate = new Date(item.startDate.replace(/-/g, '/'));
			const weekDate = startDate.getDay();

			/**
			 * If the weekDate is equal to the weekDay, then return the color #3F80EF, otherwise return the color
			 * #eee.
			 * @param {number} weekDay - number - this is the day of the week, 0-6, where 0 is Sunday and 6 is
			 * Saturday.
			 */
			const stackItemStyle = (weekDay: number) => ({
				width: 24,
				height: 24,
				fontSize: '0.85rem',
				bgcolor: weekDate === weekDay ? '#3F80EF' : '#eee'
			});

			/* Creating a stack of avatars, each avatar has a letter inside of it. */
			const weekStack = (
				<Stack direction="row" spacing={1} style={{ marginLeft: '25px' }}>
					<Avatar sx={{ ...stackItemStyle(1) }}>M</Avatar>
					<Avatar sx={{ ...stackItemStyle(2) }}>T</Avatar>
					<Avatar sx={{ ...stackItemStyle(3) }}>W</Avatar>
					<Avatar sx={{ ...stackItemStyle(4) }}>T</Avatar>
					<Avatar sx={{ ...stackItemStyle(5) }}>F</Avatar>
					{/* <Avatar sx={{ ...stackItemStyle(6) }}>S</Avatar> */}
					{/* <Avatar sx={{ ...stackItemStyle(0) }}>S</Avatar> */}
				</Stack>
			);

			/* Finding the seat that matches the resourceId of the item. */
			const seat = offices.find((e: any) => e.id === item.resourceId);

			/* A function the item for the bookings list, with the actions field to differentiate if 
			the item will have actions to approve or delete, or if no actions will be displayed  */
			const ItemComponent = (actions: any) => (
				<Fragment key={item.id}>
					<ListItem alignItems="flex-start" secondaryAction={actions}>
						<ListItemText
							primary={
								<span style={{ display: 'flex' }}>
									Booking date: {parseDateToShow(item.startDate)} {weekStack}
								</span>
							}
							secondary={
								<>
									{` ${selectionInfo.company} - ${selectionInfo.officeName} - `}
									<Typography
										sx={{ display: 'inline' }}
										component="span"
										variant="body2"
										color="primary">
										<b>
											{' '}
											{seat?.type} {seat?.name ?? 'N/A'}
										</b>
									</Typography>
								</>
							}
						/>
					</ListItem>
					<Divider variant="inset" component="li" />
				</Fragment>
			);

			// if the tab equals to zero, render the items without actions
			if (tab === 0) {
				return ItemComponent(null);
			}
			// if the tab equals is different than zero, render the items with actions
			return ItemComponent(
				submited[item.id] || (
					<Stack direction="row" spacing={2}>
						<IconButton
							edge="end"
							onClick={() => {
								action('Deleted', item.id);
								setSubmited(prev => ({ ...prev, [item.id]: 'Deleted' }));
							}}>
							<DeleteIcon />
						</IconButton>
						<IconButton
							edge="end"
							onClick={() => {
								action('Confirmed', item.id);
								setSubmited(prev => ({ ...prev, [item.id]: 'Confirmed' }));
							}}>
							<CheckIcon color="primary" />
						</IconButton>
					</Stack>
				)
			);
		},
		[submited]
	);

	return (
		<>
			{loading && <LoadingOverlay />}
			<Dialog
				title="Manage Bookings"
				actions={filtersModalActions}
				scroll="body"
				modal={{
					// Sending Edit or Add mode
					open: true,
					handleClose: () => closePopup(Object.keys(submited).length !== 0),
					content: (
						<>
							<TabHeader
								selected={tabSelected}
								variant="fullWidth"
								scrollButtons="auto"
								handleSelected={(event, newValue) => setTabSelected(newValue)}>
								{tabs.map((tab, index) => (
									<Tab key={`proposal_tab_${tab.id}`} label={tab.label} index={index} />
								))}
							</TabHeader>
							<Scrollbars
								style={{
									height: isMobile ? 'calc(100vh - 220px)' : 'calc(75vh - 200px)',
									overflowX: 'hidden'
								}}
								renderTrackHorizontal={(props: any) => (
									<div {...props} style={{ display: 'none' }} className="track-horizontal" />
								)}>
								{employeeBookings && (
									<List>
										{tabSelected === 0 &&
											employeeBookings.approved.map(e => BookingItem(e, tabSelected))}
										{tabSelected === 1 &&
											employeeBookings.pending.map(e => BookingItem(e, tabSelected))}
									</List>
								)}
							</Scrollbars>
						</>
					)
				}}
			/>
		</>
	);
};

export default ReservationModal;
