/* eslint-disable @typescript-eslint/no-explicit-any */
import { OriginalResponse } from 'lib/handlers/requestTypes';
import { IChoosableBaseInfo } from '../generalTypes';
import { ICompanyUsers } from './admissions';
import { ICompanyInfo } from './proposals';

export enum UserActions {
	SET_LOADING = '@@administration/SET_LOADING',
	SET_ERROR = '@@personalInfo/SET_ERROR',
	GET_USERS_LIST_VPN = '@@administration/users/GET_USERS_LIST_VPN',
	SEND_DELETE_USER = '@@administration/users/SEND_DELETE_USER',
	SEND_DELETE_USER_FROM_DB = '@@administration/users/SEND_DELETE_USER_FROM_DB',
	GET_WELCOME_EMAIL = '@@administration/users/GET_WELCOME_EMAIL',
	SEND_WELCOME_EMAIL = '@@administration/users/SEND_WELCOME_EMAIL',
	POST_USERS_LIST_VPN = '@@administration/users/POST_USERS_LIST_VPN',
	GET_USERS_ADD = '@@administration/users/GET_USERS_ADD',
	POST_USERS_ADD = '@@administration/users/POST_USERS_ADD',
	GET_USERS_LIST = '@@administration/users/GET_USERS_LIST',
	GET_USERS_LIST_FROM_DB = '@@administration/users/GET_USERS_LIST_FROM_DB',
	CLEAR_WELCOME_EMAIL = '@@administration/users/CLEAR_WELCOME_EMAIL',
	GET_RESPONSE_CODE = '@@administration/users/GET_RESPONSE_CODE',
	GET_HEALTH_LOCATIONS = '@@administration/users/GET_HEALTH_LOCATIONS',
	SET_HEALTH_CONVOCATION = '@@administration/users/SET_HEALTH_CONVOCATION',
	SEND_IMPERSONATE_EMAIL = '@@administration/users/SEND_IMPERSONATE_EMAIL',
	SET_IS_CLOSED = '@@administration/users/SET_IS_CLOSED'
}

export type UserState = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly usersListVPN: IUserLDAP[] | null;
	readonly vpnAction: boolean | null;
	readonly welcomeEmail: IWelcomeEmail | null;
	readonly welcomeEmailAction: OriginalResponse | null;
	readonly reducerUsersAdd: INewUserGet | null;
	readonly usersAction: boolean | null;
	readonly usersList: IUserLDAP[] | null;
	readonly responseCode: number | null;
	readonly healthLocations: IHealthLocations[] | null;
	readonly impersonateEmailAction: OriginalResponse | null;
	readonly impersonateEmailClose: boolean | null;
};

//* Thunks interfaces

export interface IUserLDAP {
	username: string;
	email: string;
	firstName: string;
	lastName: string;
	department: string;
	officeName: string;
	phone: string[];
	companyCode: string;
}

export interface IEmployeeVPNList {
	users?: IUserLDAP[];
}

export interface IDeleteEmployee {
	reqPayload: {
		terminateOnXtracker: boolean;
		terminationDate: string;
		erpCollaborationPeriodCloseDate: string;
		erpContractCareerCloseDate: string;
	};
}

export interface IConvocationLocation {
	startDate: string | undefined;
	location: string | undefined;
}

export interface IHealthLocations {
	id: string;
	name: string;
	address: string;
	googleMapsLink: string;
	googleMapsEmbedLink: string;
}

export interface IWelcomeEmail {
	subject: string;
	to: string;
	cc: string;
	mailBody: string;
	newPassword?: string;
}

export interface INewUserGet {
	companies: ICompanyInfo[];
	employeeManagers: IChoosableBaseInfo[];
	holidayTypes: IChoosableBaseInfo[];
	locations: IChoosableBaseInfo[];
	prefixes: IChoosableBaseInfo[];
	timeOffApprovalTypes: IChoosableBaseInfo[];
	timeoffPolicies: IChoosableBaseInfo[];
	users: ICompanyUsers[];
}

export interface INewEmployee {
	userNames?: any;
	employeeManagers?: any;
	username: string;
	company: string;
	businessUnit: string;
	division: string;
	department: string;
	prefix: string;
	firstName: string;
	lastName: string;
	email: string;
	location: string;
	hiredDate: string;
	addToXtracker: boolean;
	holidayType: string | null;
	hasTimeOffPolicy: boolean;
	timeOffApprovalType: string | null;
	timeOffPolicy: string | null;
	showEmployeeManagers: boolean;
	employeeManager: string | null;
	vacationDays: number | string | null;
}

export interface IPeriodFiltersPayload {
	name: string;
	period: string;
	duration: string;
	totalHours: string;
	status: string;
	submittedOn: string;
	approvedOn: string;
	rejectedOn: string;
	link: any;
}
