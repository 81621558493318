/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types, no-param-reassign */
// eslint-disable-next-line import/extensions
import { DateHelper, PresetManager } from '@bryntum/scheduler/scheduler.umd.js';
import { parseDateToShow } from 'xpand-ui/utils/dates';

const newPresets: any = [PresetManager.records[10]];
// day
newPresets[0].data.headers = [
	{
		dateFormat: 'YYYY MMMM DD',
		increment: 1,
		originalDateFormat: 'YYYY MMMM DD',
		unit: 'month',
		renderer: (
			start: Date // start, end, headerConfig, index
		) => `<span class="monthYear">${DateHelper.format(start, 'MMMM YYYY')}</span>`
	},
	{
		dateFormat: '',
		increment: 1,
		originalDateFormat: 'DD MMM',
		unit: 'day',
		renderer: (
			start: Date // start, end, headerConfig, index
		) =>
			`<span style="display: flex; flex-direction: column;">
				<span class="header-week-cell">${DateHelper.format(start, 'ddd')}</span>
				<span class="header-day-cell">${DateHelper.format(start, 'DD')}</span>
			</span>`
	}
];

// Normalizes agenda items on load by converting string dates to actual dates and by calculating start and end offsets
// from the events startDate, to keep a relative position later on drag/resize
export const normalizeAgendaItems = (eventData: any) => {
	eventData.startDate = DateHelper.parse(eventData.startDate, 'YYYY-MM-DD-HH:mm');
};

// Normal User Start Date
const DateAux = new Date();
DateAux.setHours(0, 0, 0, 0);

// Admin Start Date (View 1 year back)
const dateStartAdmin = new Date();
dateStartAdmin.setDate(dateStartAdmin.getDate() - 365);
dateStartAdmin.setHours(0, 0, 0, 0);

// Normal User End Date
const max90DaysFromNow = new Date();
max90DaysFromNow.setMonth(max90DaysFromNow.getMonth() + 3);
max90DaysFromNow.setHours(0, 0, 0, 0);

// Admin End Date
const dateEndAdmin = new Date();
dateEndAdmin.setMonth(dateEndAdmin.getMonth() + 15);
dateEndAdmin.setHours(0, 0, 0, 0);

const schedulerConfig = (
	classes: Record<string, string>,
	toolbarSelected: any,
	handlePendingBooking: (event: unknown, type: string) => void,
	isAdmin: boolean
): unknown => ({
	// resourceImagePath: 'users/',
	// eventStyle: 'rounded',
	eventStyle: 'border',
	enableDeleteKey: false,
	presets: newPresets, // Only 2 zoom levels are available
	viewPreset: newPresets[0].id,
	barMargin: 5,
	fillTicks: true,
	startDate: isAdmin ? dateStartAdmin : DateAux,
	endDate: isAdmin ? dateEndAdmin : max90DaysFromNow,
	editor: false,
	features: {
		// Enable merging cells
		// stripe     : true,// Shade weekends
		nonWorkingTime: true,
		// disableWeekends: true,
		tree: true,
		editor: false,
		regionResize: false,
		scheduleMenu: {
			disabled: true
		},
		eventDrag: {
			disabled: true
		},
		eventCopyPaste: false,
		rowCopyPaste: false,
		scheduleTooltip: {
			generateTipContent({ date }: any) {
				return `
			  	<div class="b-sch-clockwrap b-sch-clock-hour b-sch-tooltip-startdate">
					<div class="b-sch-clock">
						<div class="b-sch-hour-indicator"></div>
						<div class="b-sch-minute-indicator"></div>
						<div class="b-sch-clock-dot"></div>
					</div>
					<span class="b-sch-clock-text">${parseDateToShow(date) || 'N/A'}</span>
				</div>
				`;
			}
		},
		eventTooltip: {
			template: (data: any) =>
				`
				${
					data.eventRecord.data.fullName && data.eventRecord.data.blockedSeat === false
						? `<div class="b-sch-event-title">${data.eventRecord.data.fullName}</div>`
						: ''
				}
				${
					data.eventRecord.data.blockedReason && data.eventRecord.data.blockedSeat == true
						? `<div class="b-sch-event-title">${data.eventRecord.data.blockedReason}</div>`
						: ''
				}
				${
					data.eventRecord.data.licensePlate
						? `<div class="b-sch-event-title">${data.eventRecord.data.licensePlate}</div>`
						: ''
				}
				<div class="b-sch-clockwrap b-sch-clock-hour b-sch-tooltip-startdate">
                    <div class="b-sch-clock">
                        <div class="b-sch-hour-indicator"></div>
                        <div class="b-sch-minute-indicator"></div>
                        <div class="b-sch-clock-dot"></div>
                    </div>
                    <span class="b-sch-clock-text">${parseDateToShow(data.eventRecord.data.startDate) || 'N/A'}</span>
                </div>
				`
		},
		eventMenu: {
			processItems: ({ eventRecord, items }) => {
				const eventDate = new Date(eventRecord.startDate);
				const currentDate = new Date();
				if (
					eventRecord.data.licensePlate == '' &&
					isAdmin &&
					(currentDate.getTime() <= eventDate.getTime() ||
						(currentDate.getDay() == eventDate.getDay() &&
							currentDate.getMonth() == eventDate.getMonth() &&
							currentDate.getFullYear() == eventDate.getFullYear()))
				) {
					items.editEvent = {
						text: 'Edit Booking',
						icon: 'b-fa b-fa-edit',
						onItem: ({ eventRecord }: any) => {
							handlePendingBooking(eventRecord, 'Edited');
						}
					};
				} else {
					items.unblockSeat = false;
					items.editEvent = false;
				}

				if (eventRecord.data.blockedSeat === true) {
					items.unblockSeat = {
						text: 'Unblock Seat',
						icon: 'b-fa b-fa-check',
						onItem: ({ eventRecord }: any) => {
							handlePendingBooking(eventRecord, 'Deleted');
						}
					};
					items.deleteEvent = false;
					items.editEvent = false;
				}
			},
			items: {
				editEvent: isAdmin ? true : false,
				unassignEvent: false,
				validateBooking: {
					text: 'Confirm Booking',
					icon: 'b-fa b-fa-check',
					onItem: ({ eventRecord }: any) => {
						handlePendingBooking(eventRecord, 'Confirmed');
					}
				},
				deleteEvent: {
					text: 'Delete Booking',
					onItem: ({ eventRecord }: any) => {
						handlePendingBooking(eventRecord, 'Deleted');
					}
				}
			}
		},

		timeRanges: {
			showCurrentTimeLine: false
		}
	},
	timeRangesFeature: {
		narrowThreshold: 10
	},
	zoomOnTimeAxisDoubleClick: false,
	autoAdjustTimeAxis: false,
	autoAdjust: false,
	timeAxisHeaderMenu: {
		items: []
	},
	// Columns in scheduler
	columns: [
		{
			type: 'tree',
			text: 'Location',
			width: 235,
			editor: false,
			enableCellContextMenu: false,
			field: 'name'
		}
	]
	// eventRenderer is here used to translate the dates of nested events into pixels, passed on to the eventBodyTemplate
	// eventRenderer({ eventRecord, renderData }) {
	// 	renderData.style = `background:yellow; border-color:white; color:black`;
	// }
});

export { schedulerConfig };
