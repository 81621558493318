import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getEmployeeBookings } from 'store/personalInfo/thunks';
import { AppState } from 'typings/state';

import ReservationModal from './ReservationModal';

const mapStateToProps = ({ personalInfo }: AppState) => ({ personalInfo });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getEmployeeBookings }, dispatch);

export type BookingProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ReservationModal);
