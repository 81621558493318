import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, POST, PUT } from 'lib/handlers/requestTypes';

import {
	IUpdateContacts,
	IUpdateCompanyAllocation,
	IUpdatePersonalInfo,
	IUpdatePrivateData,
	IUpdateBankInfo,
	IUpdateFiscalInfo
} from 'typings/store/personalInfoTypes';

/** Profile */
export const fetchUserProfile = async (username: string): Promise<OriginalResponse | Success> =>
	username ? restCall(GET, `/admin/users/${username}/profile`) : restCall(GET, '/user/profile');

export const fetchPendingHealthConvocation = async (username: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/users/convocations/pending/${username}`);

export const sendUserUpdateProfile = async (
	payload:
		| IUpdateContacts
		| IUpdateCompanyAllocation
		| IUpdatePersonalInfo
		| IUpdatePrivateData
		| IUpdateBankInfo
		| IUpdateFiscalInfo,
	isAdmin: string | undefined
): Promise<OriginalResponse | Success> =>
	isAdmin ? restCall(PUT, `/admin/users/${isAdmin}/profile`, payload) : restCall(PUT, '/user/profile', payload);

export const postNewProfilePic = async (username: string, payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, `/admin/users/${username}/photo`, payload);

export const postAcceptHealthCareConvocation = async (id: number, email: string): Promise<OriginalResponse | Success> =>
	restCall(POST, `/user/profile/${email}/convocation/${id}/accept`);

export const postDeclineHealthCareConvocation = async (id: number, payload: any): Promise<OriginalResponse | Success> =>
	restCall(POST, `/user/profile/convocation/${id}/decline`, payload);
