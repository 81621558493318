import { OriginalResponse } from 'lib/handlers/requestTypes';
import { action } from 'typesafe-actions';
import { PersonalInfoActions as Actions } from 'typings/store/personalInfoTypes';
import { IDispatchType } from 'typings/store/storeTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setError = (payload: unknown | null): IDispatchType => action(Actions.SET_ERROR, payload);
export const setLoadingToFalse = (): IDispatchType => action(Actions.SET_LOADING_FALSE);

export const setApprovalsTimesheet = (payload: any | null): IDispatchType =>
	action(Actions.GET_APPROVALS_TIMESHEET, payload);

export const setApprovalsTimesheetDetails = (payload: any | null): IDispatchType =>
	action(Actions.GET_APPROVALS_TIMESHEET_PERIOD_DETAILS, payload);

export const setUpdateTimesheet = (payload: any | null): IDispatchType => action(Actions.UPDATE_TIMESHEET, payload);

export const setRemoveUpdateTimesheet = (): IDispatchType => action(Actions.REMOVE_UPDATE_TIMESHEET);

export const setApprovalsExpenses = (payload: any | null): IDispatchType =>
	action(Actions.GET_APPROVALS_EXPENSES, payload);

export const setUpdateExpenses = (payload: any | null): IDispatchType => action(Actions.UPDATE_EXPENSES, payload);

export const setRemoveUpdateExpenses = (): IDispatchType => action(Actions.REMOVE_UPDATE_EXPENSES);

export const setApprovalsTimeoff = (payload: any | null): IDispatchType =>
	action(Actions.GET_APPROVALS_TIMEOFF, payload);

export const setUpdateTimeoff = (payload: any | null): IDispatchType => action(Actions.UPDATE_TIMEOFF, payload);

export const setRemoveUpdateTimeoff = (): IDispatchType => action(Actions.REMOVE_UPDATE_TIMEOFF);

export const setApprovalsTimeOffDetails = (payload: any | null): IDispatchType =>
	action(Actions.GET_APPROVALS_TIMEOFF_DETAILS, payload);
