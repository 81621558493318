import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import {
	getEmployeeSalaryCategoriesList,
	submitEmployeeSalaryCategoryClose,
	submitEmployeeSalaryCategoryCancel,
	submitEmployeeSalaryCategoryRequestApproval,
	clearProposalsFormsData,
	clearCscError
} from 'store/administration/employeeSalaryCategories/thunks';
import { setCSCDetailsPageFilter, setCSCDetailsHistoryPageFilter } from 'store/filters/actions';
import EmployeeSalaryCategoriesList from './EmployeeSalaryCategoriesList';
import { setNotificationAsReadProposals, setNewNotificationProposals } from 'store/notifications/thunks';
const mapStateToProps = ({ employeeSalary, filters }: AppState) => ({ employeeSalary, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getEmployeeSalaryCategoriesList,
			submitEmployeeSalaryCategoryClose,
			submitEmployeeSalaryCategoryCancel,
			submitEmployeeSalaryCategoryRequestApproval,
			clearProposalsFormsData,
			clearCscError,
			setCSCDetailsPageFilter,
			setCSCDetailsHistoryPageFilter,
			setNotificationAsReadProposals,
			setNewNotificationProposals
		},
		dispatch
	);

export type EmployeeSalaryCategoriesListProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSalaryCategoriesList);
