/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { Input, Select, InfoField } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { IUpdateBankInfo, IUserProfile } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';

//* LOCAL COMPONENT IMPORTS
import { InfoCards } from '../../../utils';
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';

interface IBankInfo {
	userProfile: IUserProfile;
	handleApiSubmit: any;
}

const BankInfo: FC<IBankInfo> = ({ userProfile, handleApiSubmit }) => {
	const classes = useStyles();
	const [editMode, setEditMode] = useState(false);

	const {
		handleSubmit,
		control,
		reset,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const userERPData = userProfile?.user?.erpData;

	const getInitialData = () => ({
		bankId: userERPData?.bankAccount?.xphrXphrBank,
		iban: userERPData?.bankAccount?.iBAN
	});

	useEffect(() => {
		if (userProfile) {
			reset(getInitialData());
		}
	}, [userProfile]);

	const onSubmit = (data: IUpdateBankInfo) => {
		if (
			JSON.stringify({ bankId: data.bankId, iban: data.iban }) !==
			JSON.stringify({ bankId: getInitialData().bankId, iban: getInitialData().iban })
		) {
			handleApiSubmit([
				{
					type: 'bankInfo',
					data: {
						...getInitialData(),
						bankId: data.bankId,
						iban: data.iban
					}
				}
			]);
		} else {
			addNotification('info', 'Nothing to Update');
		}
		setEditMode(false);
		return null;
	};

	return (
		<InfoCards
			title="Bank Information"
			form="form-profile-bank-info"
			classes={classes}
			reset={reset}
			editMode={editMode}
			setEditMode={setEditMode}>
			<form id="form-profile-bank-info" onSubmit={handleSubmit(() => onSubmit(watch()))}>
				<Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
					{!editMode && (
						<>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="NiB/IBAN"
									value={userERPData?.bankAccount && userERPData?.bankAccount?.iBAN}
								/>
							</Grid>
							<Grid item {...sizes[6]}>
								<InfoField
									fontSize="lg"
									label="Bank Name"
									value={
										userERPData?.bankAccount && userERPData?.bankAccount?.xphrXphrBank$_identifier
									}
								/>
							</Grid>
						</>
					)}
					{editMode && (
						<>
							<Grid item {...sizes[6]}>
								<Input name="iban" label="NiB/IBAN" required control={control} errors={errors} />
							</Grid>
							<Grid item {...sizes[6]}>
								<Select
									name="bankId"
									label="Bank Name"
									options={userProfile?.banks?.map(e => ({ id: e.id, label: e.name })) || []}
									control={control}
									errors={errors}
									required
								/>
							</Grid>
						</>
					)}
				</Grid>
			</form>
		</InfoCards>
	);
};

export default BankInfo;
