import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { clearPersonalInfoError, getUserSkills, getSystemSkills, updateUserSkills } from 'store/personalInfo/thunks';

import MySkills from './Skills';
import { setNotificationAsRead } from 'store/notifications/thunks';

const mapStateToProps = ({ personalInfo, company }: AppState) => ({ personalInfo, company });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{ clearPersonalInfoError, getUserSkills, getSystemSkills, updateUserSkills, setNotificationAsRead },
		dispatch
	);

export type MySkillsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MySkills);
