import React from 'react';
import { Store } from 'react-notifications-component';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const icons: Record<string, unknown> = {
	success: <CheckCircleOutlineIcon className="successIcon" />,
	info: <ErrorOutlineIcon className="infoIcon" />,
	warning: <ReportProblemOutlinedIcon className="warningIcon" />,
	danger: <HighlightOffIcon className="dangerIcon" />
};

export const addNotification = (
	type: 'default' | 'success' | 'danger' | 'info' | 'warning',
	message: string | Element | JSX.Element,
	seconds?: number | null,
	toastConfig: Record<string, unknown> = {}
): void => {
	const timer = seconds ? seconds * 1000 : 5000;

	Store.addNotification !== null &&
		Store.addNotification({
			message: (
				<div style={{ width: '-webkit-fill-available' }}>
					{icons[type] || null} {message}
				</div>
			),
			type,
			insert: 'top',
			container: 'top-right',
			animationIn: ['animate__animated animate__backInRight'], // `animate.css v4` classes
			animationOut: ['animate__animated animate__backOutRight'], // `animate.css v4` classes
			dismiss: {
				duration: timer,
				onScreen: true,
				pauseOnHover: true,
				showIcon: true
			},
			...toastConfig
		});
};
