// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			display: 'flex',
			padding: '50px 50px',
			minWidth: '100%',
			alignItems: 'center',
			flexDirection: 'column',
			margin: '0 auto 90px auto'
		},
		animation: {
			visibility: 'hidden',
			opacity: '0',
			transition: 'all 0.3s ease',
			display: 'none'
		},
		show: {
			visibility: 'visible',
			opacity: 1,
			display: 'block'
		},
		gridDivider: {
			paddingBottom: '20px'
		},
		gridTitles: {
			paddingBottom: '40px',
			fontSize: '16px',
			color: '#3D464D'
		}
	})
);

export { useStyles };
