/* eslint-disable indent */
import { Dispatch } from 'redux';
import {
	fetchNewClient,
	postNewClient,
	fetchNewProjectData,
	postNewProject
} from 'lib/api/administration/projectsAndClients';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import { INewClientInfo, INewProjectInfo, INewClient, INewProject } from 'typings/store/admin/projectsAndClients';
import {
	setLoading,
	setNewClient,
	setPostNewClient,
	setNewProjectData,
	setPostNewProject,
	setProjectsAndClientsError
} from './actions';

const getNewClient =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: INewClientInfo } = await fetchNewClient();
			if (response.data) {
				dispatch(setNewClient(response?.data || null));
			} else {
				dispatch(setProjectsAndClientsError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setProjectsAndClientsError(error as OriginalResponse));
		}
	};

const sendNewClient =
	(payload: INewClient) =>
	async (dispatch: Dispatch): Promise<boolean> => {
		try {
			dispatch(setLoading());
			const response: Success = await postNewClient(payload);
			if (response.code == 200 && response.data) {
				dispatch(setPostNewClient(response.data || null));
				return await new Promise(resolve => {
					resolve(true);
				});
			}
			dispatch(setPostNewClient(null));
			return await new Promise(resolve => {
				resolve(false);
			});
		} catch (error) {
			dispatch(setPostNewClient(null));
			return await new Promise(resolve => {
				resolve(false);
			});
		}
	};

const getNewProjectData =
	(payload = false) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: INewProjectInfo } = await fetchNewProjectData(payload);
			if (response.data) {
				dispatch(setNewProjectData(response.data || null));
			} else {
				dispatch(setProjectsAndClientsError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setProjectsAndClientsError(error as OriginalResponse));
		}
	};

const sendNewProject =
	(payload: INewProject) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: Success = await postNewProject(payload);
			dispatch(setPostNewProject(response.data || null));
		} catch (error) {
			dispatch(setPostNewProject(null));
		}
	};

const clearProjectAndClientsError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setProjectsAndClientsError(null));
	};

export { getNewClient, sendNewClient, getNewProjectData, sendNewProject, clearProjectAndClientsError };
