import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { getCscAuditLog, clearCscAuditLog, clearCscError } from 'store/administration/employeeSalaryCategories/thunks';
import CscAuditLog from './CscAuditLog';

const mapStateToProps = ({ employeeSalary }: AppState) => ({ employeeSalary });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getCscAuditLog, clearCscAuditLog, clearCscError }, dispatch);

export type CscAuditLogProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CscAuditLog);
