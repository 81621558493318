// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		title: {
			padding: '10px 20px 20px 20px'
		},
		divider: {
			margin: '40px 0 20px 0'
		}
	})
);

export { useStyles };
