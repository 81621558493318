/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';

import { stringRequired, stringUnlimitedRequired } from 'lib/utils/formUtils';

const invalidEmailList = (emailString: string) => {
	const emailsArray = emailString?.split(';');
	let invalidEmails = 0;
	emailsArray.forEach((email: string) => {
		if (!email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)) {
			// eslint-disable-next-line no-plusplus
			invalidEmails++;
		}
	});
	return invalidEmails;
};

export const schema = yup.object().shape({
	subject: stringRequired,
	from: stringRequired,
	to: yup
		.string()
		.test(
			'invalid-email-in-list',
			'One or more of the inserted emails are invalid',
			payload => invalidEmailList(payload as string) === 0
		),
	cc: yup
		.string()
		.test(
			'invalid-email-in-list',
			'One or more of the inserted emails are invalid',
			payload => invalidEmailList(payload as string) === 0
		),
	mailBody: stringUnlimitedRequired
});

export const defaultValues = {
	subject: '',
	from: '',
	to: '',
	cc: '',
	mailBody: ''
};
