import * as yup from 'yup';

import { string, stringRequired, boolean } from 'lib/utils/formUtils';
import { INewClient } from 'typings/store/admin/projectsAndClients';

export const schema = yup.object().shape({
	company: stringRequired,
	action: stringRequired,
	name: stringRequired,
	searchKey: stringRequired,
	fiscalCode: stringRequired,
	group: stringRequired,
	salesRepresentative: stringRequired,
	priceList: stringRequired,
	paymentTerm: stringRequired,
	// Address
	addressAction: stringRequired,
	address: stringRequired,
	zipCode: stringRequired,
	city: stringRequired,
	country: stringRequired,
	// contact (invoice)
	invoiceContactAction: string,
	invoiceContactName: string,
	invoiceContactEmail: string.email('Must be a valid email'),
	invoiceContactPhone: string,
	invoiceContactFax: string,
	invoiceContactPosition: string,
	// envelope contact (billing)
	envelopeContactAction: string,
	envelopeContactIsDefault: boolean,
	envelopeContactName: string,
	envelopeContactEmail: string.email('Must be a valid email'),
	envelopeContactPhone: string,
	envelopeContactFax: string
});

export const defaultValues: INewClient = {
	company: '',
	action: '',
	name: '',
	searchKey: '',
	fiscalCode: '',
	group: '',
	salesRepresentative: '',
	priceList: '',
	paymentTerm: '',
	// Address
	addressAction: '',
	address: '',
	zipCode: '',
	city: '',
	country: '',
	// contact (invoice)
	invoiceContactAction: '',
	invoiceContactName: '',
	invoiceContactEmail: '',
	invoiceContactPhone: '',
	invoiceContactFax: '',
	invoiceContactPosition: '',
	// envelope contact (billing)
	envelopeContactAction: '',
	envelopeContactIsDefault: false,
	envelopeContactName: '',
	envelopeContactEmail: '',
	envelopeContactPhone: '',
	envelopeContactFax: ''
};

export const tabs = [
	{
		id: 0,
		label: 'Client'
	},
	{
		id: 1,
		label: 'Address'
	},
	{
		id: 2,
		label: 'Contacts'
	},
	{
		id: 3,
		label: 'Envelope Contacts'
	}
];
