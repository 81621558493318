import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from 'typings/state';
import { connect } from 'react-redux';

import { submitAuthorizationCodeRenewal } from 'store/administration/administration/thunks';

import RenewAuthorizationCode from './RenewAuthorizationCode';

const mapStateToProps = ({ administration }: AppState) => ({ administration });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			submitAuthorizationCodeRenewal
		},
		dispatch
	);

export type RenewAuthorizationCodeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(RenewAuthorizationCode);
