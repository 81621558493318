/* eslint-disable @typescript-eslint/ban-ts-comment, react/button-has-type */
import React from 'react';
import { Route, Switch } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorPage } from 'xpand-ui/lab';

import AdmissionSheet from 'pages/Admin/AdmissionsAndResignations/AdmissionSheet';
import { pages } from 'lib/routes/pages';

const PublicPages: React.FC = () => (
	// @ts-ignore
	<ErrorBoundary FallbackComponent={ErrorPage}>
		<Switch>
			{/* -------------- PUBLIC PAGES -------------- */}
			<Route
				// title={`Control-Panel - ${pages.admissionSheet.label}`}
				path={pages.admissionSheet.path}
				exact
				component={AdmissionSheet}
			/>
		</Switch>
	</ErrorBoundary>
);

export default PublicPages;
