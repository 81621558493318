import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { removeLSField } from 'lib/utils/cookies';
import { useState } from 'react';

// Method to acquire tokenId
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useIdToken = () => {
	const { instance, accounts } = useMsal();
	const [idToken, setIdToken] = useState(null);

	if (accounts.length > 0) {
		const request = {
			scopes: ['openid'],
			account: accounts[0]
		};
		instance
			.acquireTokenSilent(request)
			.then(response => {
				sessionStorage.setItem('auxStorage', JSON.stringify(response));
				setIdToken(response.idToken);
			})
			.catch(error => {
				// acquireTokenSilent can fail for a number of reasons, fallback to interaction
				if (error instanceof InteractionRequiredAuthError) {
					instance.acquireTokenPopup(request).then(response => {
						setIdToken(response.idToken);
					});
				}
			});
	}

	return idToken;
};

// Helper method to clear caches on logout
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const clearCacheLogout = () => {
	removeLSField('userInfo');
	removeLSField('userPermissions');
	removeLSField('table-xptop-searchValueState');
	removeLSField('table-xptop-tableFlags');
	removeLSField('cp-appsList-searchValue');
	removeLSField('impersonate_userInfo');
	removeLSField('impersonate_userActions');
	removeLSField('impersonate_userPermissions');
};
