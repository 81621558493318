import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fontSize } from '@mui/system';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	navBarModal: {
		'& .mbsc-calendar-title': { fontSize: '14px !important' }
	},
	datePickerGrid: {
		padding: '0px !important',
		'& .mbsc-calendar-grid-item': {
			borderBottom: 'none'
		},
		'& .mbsc-calendar-grid': {
			padding: '0'
		}
	},
	gridItemsPaddingTop: {
		paddingTop: '0'
	},
	textAlign: {
		textAlignLast: 'right',
		alignContent: 'center',
		'& .MuiTypography-root': {
			fontSize: '16px'
		}
	},
	clearButton: {
		padding: '0',
		position: 'relative',
		textAlignLast: 'center',
		marginTop: '-40px',
		zIndex: '4'
	},
	asteriskInfoField: {
		paddingTop: '0px !important'
	}
}));

export { useStyles };
