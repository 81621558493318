/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
//* EXTERNAL LIBS
import React, { useEffect, useState, useMemo, FC } from 'react';

//* EXTERNAL LIBS --> MUI
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Typography, Grid, Card, CardContent } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { LoadingOverlay } from 'xpand-ui/core';

//* TYPINGS
import { ISystemSkillsWithUserData } from 'typings/store/admin/skillsAndResumes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addGroupsToStruct, filterOnlyUserSkills } from 'pages/PersonalInfo/MyProfile/Skills/utils';
import { SkillsInfoCard } from 'pages/PersonalInfo/MyProfile/Resume/utils';

//* LOCAL COMPONENT IMPORTS
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { useStyles } from './styles';
import { ViewSkillsDetailsProps } from '.';

interface IViewSkillsDetails extends ViewSkillsDetailsProps {
	userId: number;
	systemSkills: ISystemSkillsWithUserData[];
}

const ViewSkillsDetails: FC<IViewSkillsDetails> = ({
	getUserSkillsEvaluation,
	clearUserSkillsEvaluation,
	skillsAndResumes,
	userId,
	systemSkills,
	clearSkillsAndResumesError
}) => {
	const classes = useStyles();
	const { userSkillsEvaluation, error } = skillsAndResumes;
	const [userSkillsForm, setSkillsFormData] = useState<ISystemSkillsWithUserData | []>([]);
	const [systemSkillsData, setSystemSkillsData] = useState<ISystemSkillsWithUserData | []>([]);

	useEffect(
		() => () => {
			clearUserSkillsEvaluation();
		},
		[]
	);

	useEffect(() => {
		if (!error && !userSkillsEvaluation) {
			getUserSkillsEvaluation(userId);
		} else {
			const payload = {};
			const sysPayload = {};
			systemSkills.map(a => {
				// create area level
				const { toRender } = addGroupsToStruct(a.skillGroups, userSkillsEvaluation, true, true);
				payload[a.id] = { ...toRender };
				sysPayload[a.name] = a;
				return a;
			});

			setSkillsFormData(prev => ({ ...prev, ...payload }));
			setSystemSkillsData(prev => ({ ...prev, ...sysPayload }));
		}
	}, [userSkillsEvaluation]);

	//Get all skills to an object
	let skills = {};
	for (let skill in userSkillsForm) {
		for (let skillName in systemSkillsData) {
			if (systemSkillsData[skillName].id == skill) {
				let skillArea = filterOnlyUserSkills(
					userSkillsForm[skill]!,
					{},
					systemSkillsData[skillName].skillGroups
				);
				skills = {
					...skills,
					[skill]: skillArea
				};
			}
		}
	}

	const isLoading = !userSkillsEvaluation || !skills;

	interface IFieldDescription {
		className?: any;
		label?: string;
		info: string;
		size?: number;
	}

	const FieldDescription: FC<IFieldDescription> = useMemo(
		() =>
			({ label, info, size }) => {
				const pSize = size || 6;
				return (
					<Grid item {...sizes[pSize]}>
						<Typography variant="h6">
							{label} {info}
						</Typography>
					</Grid>
				);
			},
		[]
	);

	if (error) return handleErrorPage(error, clearSkillsAndResumesError);

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			<Card className={classes.root}>
				<Grid>
					<Card className={classes.base}>
						<CardContent>
							<Typography className={classes.title} variant="h1" component="h2">
								{userSkillsEvaluation.name} ( {userSkillsEvaluation.position} )
							</Typography>
							<br />
							<FieldDescription
								label="Career start date:"
								info={
									userSkillsEvaluation && userSkillsEvaluation.careerStartDate
										? parseDateToShow(userSkillsEvaluation.careerStartDate)
										: ''
								}
							/>
						</CardContent>
					</Card>
					<Grid>
						<SkillsInfoCard classes={classes} formData={userSkillsForm} allSkills={skills} />
					</Grid>
				</Grid>
			</Card>
			<br />
			<Card className={classes.feedbackCard}>
				<CardContent>
					<Typography className={classes.title} variant="h1" color="textSecondary">
						<LiveHelpIcon />
						Feedback
					</Typography>
					<br />
					<Grid container className={classes.root} spacing={4} direction="row" alignItems="flex-start">
						{userSkillsEvaluation.feedbacks?.length > 0 &&
							userSkillsEvaluation.feedbacks.map(({ id, feedback, feedbackDate }) => (
								<Grid item xs={6} key={id}>
									<Card className={classes.feedbackContainer}>
										<FieldDescription
											className={classes.feedbackItem}
											info={parseDateToShow(feedbackDate as string)}
										/>
										<Typography className={classes.feedbackItem}>{feedback}</Typography>
									</Card>
								</Grid>
							))}
						{userSkillsEvaluation.feedbacks?.length === 0 && (
							<span className={classes.noFeedback}>No feedback available</span>
						)}
					</Grid>
				</CardContent>
			</Card>
		</>
	);
};

export default withLayout(ViewSkillsDetails);
