import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from 'typings/state';

import { getDeactivateForm, submitDeactivateForm, resetResginAction } from 'store/administration/admissions/thunks';

import Deactivate from './Deactivate';

const mapStateToProps = ({ admissions }: AppState) => ({ admissions });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getDeactivateForm, submitDeactivateForm, resetResginAction }, dispatch);

export type NewAdmissionProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Deactivate);
