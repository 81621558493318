/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, Input } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS

// import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';
import { INewClientTabs } from '../../NewClient';

//* COMPONENT INTERFACES
// interface IProjectTeam extends IProjectTabs {}

//* COMPONENT
const Contacts: FC<INewClientTabs> = ({ control, errors, actions }) => {
	const classes = useStyles();
	return (
		<Grid container spacing={4} className={classes.root}>
			{/* ********* Contacts (invoice) *********** */}
			<Grid item {...sizes[6]}>
				<Select
					name="invoiceContactAction"
					label="Action"
					options={actions}
					helperText="Action to perform over the client invoicing contact"
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="invoiceContactName"
					label="Name"
					helperText="Contact full name"
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="invoiceContactEmail"
					label="Email"
					helperText="Contact email address"
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="invoiceContactPhone"
					label="Phone"
					helperText="Contact phone number"
					onKeyPress={event => {
						if (!/[0-9]/.test(event.key)) {
							event.preventDefault();
						}
					}}
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="invoiceContactFax"
					label="Fax"
					helperText="Contact fax number"
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="invoiceContactPosition"
					label="Position"
					helperText="Contact company position"
					control={control}
					errors={errors}
				/>
			</Grid>
		</Grid>
	);
};

export default Contacts;
