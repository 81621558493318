import * as yup from 'yup';

import { dateYearInput, string, stringRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	customName: string,
	year: dateYearInput,
	type: stringRequired,
	cloneBudgetType: string,
	budgetCompany: stringRequired
});

export const defaultValues = {
	customName: '',
	year: undefined,
	type: '',
	cloneBudgetType: '',
	budgetCompany: ''
};
