import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getUserBenefits, clearPersonalInfoError } from 'store/personalInfo/thunks';
import { AppState } from 'typings/state';

import MyBenefits from './Benefits';

const mapStateToProps = ({ personalInfo }: AppState) => ({ personalInfo });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getUserBenefits, clearPersonalInfoError }, dispatch);

export type MyBenefitsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyBenefits);
