import { IFilters } from './admin/planner';

export enum FiltersActions {
	APPS_FILTER = '@@system/APPS_FILTER',
	MY_TRAININGS_FILTER = '@@system/MY_TRAININGS_FILTER',
	MY_FINANCIAL_DOCUMENTS_FILTER = '@@system/MY_FINANCIAL_DOCUMENTS_FILTER',
	LIST_EMPLOYEES_FILTER = '@@system/LIST_EMPLOYEES_FILTER',
	RESIGNATION_MANAGEMENT_FILTER = '@@system/RESIGNATION_MANAGEMENT_FILTER',
	MANAGE_ADMISSIONS_FILTER = '@@system/MANAGE_ADMISSIONS_FILTER',
	CSC_FILTER = '@@system/CSC_FILTER',
	EC_FILTER = '@@system/EC_FILTER',
	CSC_DETAILS_FILTER = '@@system/CSC_DETAILS_FILTER',
	CSC_DETAILS_HISTORY_FILTER = '@@system/CSC_DETAILS_HISTORY_FILTER',
	CONTRACT_MANAGEMENT_FILTER = '@@system/CONTRACT_MANAGEMENT_FILTER',
	PROPOSALS_FILTER = '@@system/PROPOSALS_FILTER',
	PROPOSALS_DETAILS_FILTER = '@@system/PROPOSALS_DETAILS_FILTER',
	BUDGET_FILTER = '@@system/BUDGET_FILTER',
	SKILLS_REMINDER_FILTER = '@@system/SKILLS_REMINDER_FILTER',
	SEARCH_RESUMES_FILTER = '@@system/SEARCH_RESUMES_FILTER',
	ACCESS_CONTROL_FILTER = '@@system/ACCESS_CONTROL_FILTER',
	ACCESS_CONTROL_ADD_FILTER = '@@system/ACCESS_CONTROL_ADD_FILTER',
	EMAIL_TEMPLATES_FILTER = '@@system/EMAIL_TEMPLATES_FILTER',
	CACHE_MANAGEMENT_FILTER = '@@system/CACHE_MANAGEMENT_FILTER',
	FILE_TEMPLATES_FILTER = '@@system/FILE_TEMPLATES_FILTER',
	RELEASE_HISTORY_FILTER = '@@system/RELEASE_HISTORY_FILTER',
	PLANNER_FILTERS = '@@system/PLANNER_FILTERS',
	ALL_EMPLOYEES_FILTERS = '@@system/ALL_EMPLOYEES_FILTERS',
	USERS_PAGE_FILTERS = '@@system/USERS_PAGE_FILTERS',
	CLEAR_ALL_FILTERS = '@@system/CLEAR_ALL_FILTERS',
	MY_TIMESHEETS_FILTERS = '@@system/MY_TIMESHEETS_FILTERS',
	MY_EXPENSES_FILTERS = '@@system/MY_EXPENSES_FILTERS'
}

export type FiltersState = {
	companyAppsFilter: string;
	myTrainingsFilter: string;
	myFinancialDocumentsFilter: string;
	listEmployeesFilter: string;
	resignationManagementFilter: string;
	manageAdmissionsFilter: string;
	manageAdmissionStatusFilter: unknown;
	cscFilter: string;
	cscStatusFilter: unknown;
	ecStatusFilter: unknown;
	ecFilter: unknown;
	cscCompanyFilter: string;
	contractManagementFilter: string;
	proposalsFilter: string;
	proposalsStatusFilter: unknown;
	budgetFilter: string;
	budgetCompanyFilter: string;
	skillsReminderFilter: string;
	searchResumesFilter: string;
	accessControlFilter: string;
	accessControlRolesFilter: unknown;
	accessControlAddFilter: string;
	emailTemplatesFilter: string;
	cacheManagementFilter: string;
	fileTemplatesFilter: string;
	releaseNotesFilter: string;
	releaseNotesProjectFilter: string;
	plannerFilters: IFilters;
	allEmployeesFilter: string;
	allEmployeesAbcFilter: string;
	allEmployeesExtraFilter: any;
	usersFilter: string;
	myExpensesFilter: string;
	myTimesheetsTypeViewFilter: string;
};

//* Thunks interfaces
