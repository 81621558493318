/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Input } from 'xpand-ui/forms';

//* TYPINGS
import { addNotification } from 'lib/utils/notifications';

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { DeepMap, FieldError } from 'react-hook-form';
import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';

//* COMPONENT INTERFACES
const markRequiredValues = (data: any): any => {
	const checkS = (val: string) =>
		Boolean(data[val] !== null && typeof data[val] !== 'undefined' && data[val].trim() !== '');

	const checkN = (val: string) =>
		data[val] !== null && typeof data[val] !== 'undefined' && (Number(data[val]) === 0 ? 0 : Number(data[val]));

	const requiredValues = {
		// Project Information Tab
		company: checkS('company'),
		projectCode: checkS('projectCode'),
		name: checkS('name'),
		startDate: checkS('startDate'),
		plannedEndDate: checkS('plannedEndDate'),
		type: checkS('type'),
		...(((data.type === 'AFCF2FE02AB84D7FB37A783A22D2DDD9' ||
			data.type === 'E6A945A0742C4DCAB96FEB2E6AEEDCCA' ||
			data.type === '846DE36CB0B14875AC250C04F658321D' ||
			data.type === 'FB4F5B510A454A3185251C756FE67BA8' ||
			data.type === '91658492027E40D68A0F646D65609628') && {
			proposalReference: checkS('proposalReference')
		}) ||
			{}),
		businessUnit: checkS('businessUnit'),
		division: checkS('division'),
		department: checkS('department'),
		lineOfBusiness: checkS('lineOfBusiness'),
		costType: checkS('costType'),
		hubDistributionScheme: checkS('hubDistributionScheme'),
		...((data.costType === 'dir' && {
			salesType: checkS('salesType')
		}) ||
			{}),
		...((data.type === 'AFCF2FE02AB84D7FB37A783A22D2DDD9' && {
			sponsorName: checkS('sponsorName')
		}) ||
			{}),
		...((data.type === 'AFCF2FE02AB84D7FB37A783A22D2DDD9' && {
			sponsorEmail: checkS('sponsorEmail')
		}) ||
			{}),

		applications: checkN('applications') !== 0,
		...((data.isXpAgileCompliant && {
			template: checkS('template')
		}) ||
			{}),

		// Project Team Tab
		teamLead: checkS('teamLead'),
		projectManager: checkS('projectManager'),
		accountManager: checkS('accountManager'),
		timesheetApproval: checkS('timesheetApproval'),
		expenseApproval: checkS('expenseApproval'),
		team: checkN('team') !== 0,
		// External Users Tab
		...((data.externalUsers.length > 0 && {
			hasExternalUsers: true
		}) ||
			{}),
		...((data.budget.length > 0 && {
			hasBudget: true
		}) ||
			{})
	};

	return requiredValues;
};

const getMissingFieldsNames = (
	errors: DeepMap<any, FieldError>
): {
	names: string[];
	tabs: Set<number>;
} => {
	const fields = {
		// Project Information Tab
		company: {
			name: 'Company',
			tab: 0
		},
		createForPT: {
			name: 'Create For PT Checkbox',
			tab: 0
		},
		projectCode: {
			name: 'Project Code',
			tab: 0
		},
		name: {
			name: 'Project Name',
			tab: 0
		},
		startDate: {
			name: 'Project Start Date',
			tab: 0
		},
		plannedEndDate: {
			name: 'Project Planned End Date',
			tab: 0
		},
		type: {
			name: 'Type',
			tab: 0
		},
		proposalReference: {
			name: 'Jira Sales CRM link',
			tab: 0
		},
		businessUnit: {
			name: 'Business Unit',
			tab: 0
		},
		division: {
			name: 'Division',
			tab: 0
		},
		department: {
			name: 'Department',
			tab: 0
		},
		lineOfBusiness: {
			name: 'Line of Business',
			tab: 0
		},
		costType: {
			name: 'Cost Type',
			tab: 0
		},
		hubDistributionScheme: {
			name: 'Hub Distribution Scheme',
			tab: 0
		},
		salesType: {
			name: 'Sales Type',
			tab: 0
		},
		sponsorName: {
			name: 'Sponsor Name',
			tab: 0
		},
		sponsorEmail: {
			name: 'Sponsor Email',
			tab: 0
		},
		applications: {
			name: 'Applications',
			tab: 0
		},
		isXpAgileCompliant: {
			name: 'Is XpAgileCompliant Checkbox',
			tab: 0
		},
		isMultiUnitProject: {
			name: 'Is MultiUnitProject Checkbox',
			tab: 0
		},
		template: {
			name: 'Template',
			tab: 0
		},
		// Project Team Tab
		teamLead: {
			name: 'Team Lead',
			tab: 1
		},
		projectManager: {
			name: 'Project Manager',
			tab: 1
		},
		accountManager: {
			name: 'Account Manager',
			tab: 1
		},
		timesheetApproval: {
			name: 'Timesheet Approval',
			tab: 1
		},
		expenseApproval: {
			name: 'Expenses Approval',
			tab: 1
		},
		team: {
			name: 'Team',
			tab: 1
		},
		// Source Repositories Tab
		repositories: {
			name: 'Repositories',
			tab: 2
		},
		// External Users Tab
		externalUsers: {
			name: 'External Users',
			tab: 3
		},
		// Budgets Tab
		budget: {
			name: 'Budget',
			tab: 4
		}
	};

	const fieldsMissing: { name: string; tab: number }[] = [];
	Object.keys(errors).forEach(name => fieldsMissing.push(fields[name]));

	return {
		names: fieldsMissing.map(e => e?.name),
		tabs: new Set(fieldsMissing.map(e => e?.tab))
	};
};

const getAdditionalErrors = (
	errors: DeepMap<any, FieldError>
): {
	additionalNames: string[];
	additionalTabs: Set<number>;
} => {
	const fields = {
		// Project Information Tab
		company: {
			name: 'Company',
			tab: 0
		},
		createForPT: {
			name: 'Create For PT Checkbox',
			tab: 0
		},
		projectCode: {
			name: 'Project Code',
			tab: 0
		},
		name: {
			name: 'Project Name',
			tab: 0
		},
		startDate: {
			name: 'Project Start Date',
			tab: 0
		},
		plannedEndDate: {
			name: 'Project Planned End Date',
			tab: 0
		},
		type: {
			name: 'Type',
			tab: 0
		},
		proposalReference: {
			name: 'Jira Sales CRM link',
			tab: 0
		},
		businessUnit: {
			name: 'Business Unit',
			tab: 0
		},
		division: {
			name: 'Division',
			tab: 0
		},
		department: {
			name: 'Department',
			tab: 0
		},
		lineOfBusiness: {
			name: 'Line of Business',
			tab: 0
		},
		costType: {
			name: 'Cost Type',
			tab: 0
		},
		hubDistributionScheme: {
			name: 'Hub Distribution Scheme',
			tab: 0
		},
		salesType: {
			name: 'Sales Type',
			tab: 0
		},
		sponsorName: {
			name: 'Sponsor Name',
			tab: 0
		},
		sponsorEmail: {
			name: 'Sponsor Email',
			tab: 0
		},
		applications: {
			name: 'Applications',
			tab: 0
		},
		isXpAgileCompliant: {
			name: 'Is XpAgileCompliant Checkbox',
			tab: 0
		},
		isMultiUnitProject: {
			name: 'Is MultiUnitProject Checkbox',
			tab: 0
		},
		template: {
			name: 'Template',
			tab: 0
		},
		// Project Team Tab
		teamLead: {
			name: 'Team Lead',
			tab: 1
		},
		projectManager: {
			name: 'Project Manager',
			tab: 1
		},
		accountManager: {
			name: 'Account Manager',
			tab: 1
		},
		timesheetApproval: {
			name: 'Timesheet Approval',
			tab: 1
		},
		expenseApproval: {
			name: 'Expenses Approval',
			tab: 1
		},
		team: {
			name: 'Team',
			tab: 1
		},
		// Source Repositories Tab
		repositories: {
			name: 'Repositories',
			tab: 2
		},
		// External Users Tab
		externalUsers: {
			name: 'External Users',
			tab: 3
		},
		// Budgets Tab
		budget: {
			name: 'Budget',
			tab: 4
		}
	};

	const fieldsMissing: { name: string; tab: number }[] = [];
	Object.keys(errors).forEach(name => fieldsMissing.push(fields[name]));

	return {
		additionalNames: fieldsMissing.map(e => e?.name),
		additionalTabs: new Set(fieldsMissing.map(e => e?.tab))
	};
};

//* COMPONENT
const Comments: FC<IProjectTabs> = ({
	control,
	errors,
	watch,
	handleWarning,
	additionalErrors,
	submitClick,
	setSubmitClick
}) => {
	const classes = useStyles();

	useEffect(() => {
		if (submitClick) {
			const formPayload = watch();
			const requiredValues = markRequiredValues(formPayload);

			const warningValues = {};
			Object.keys(requiredValues).forEach(e => {
				if (requiredValues[e] === false) {
					warningValues[e] = '';
				}
			});

			const { names, tabs } = getMissingFieldsNames(warningValues);
			const { additionalNames, additionalTabs } = getAdditionalErrors(additionalErrors);

			let mergedNames = names.concat(additionalNames);

			let mergedTabs = new Set([...tabs, ...additionalTabs]);

			if (mergedNames.length !== 0) {
				handleWarning(mergedNames, [...mergedTabs]);
				addNotification(
					'danger',
					`New Project's form is not completed, the following fields must be valid: ${mergedNames.join(', ')}`
				);
			}
			setSubmitClick(false);
		}
	}, [submitClick]);

	useEffect(() => {
		if (submitClick) {
			if (errors.externalUsers) {
				addNotification('danger', "New Project's External Users tab cannot be empty");
			}
			if (errors.budget) {
				addNotification('danger', "New Project's Budget tab cannot be empty");
			}
			setSubmitClick(false);
		}
	}, [submitClick]);

	return (
		<Grid container spacing={4} className={classes.root}>
			{/* Comments */}
			<Grid item {...sizes[12]}>
				<Input
					label="Comments"
					name="comments"
					multiline
					minRows={5}
					maxRows={8}
					control={control}
					errors={errors}
				/>
			</Grid>
		</Grid>
	);
};

export default Comments;
