export const XTRACKER_ADMIN = 1165;
export const XTRACKER_ETL = 1180;
export const XTRACKER_EXPENSE_SUBMITTER = 1178;
export const XTRACKER_PAYROLL_ADMIN = 1185;
export const XTRACKER_ABSENCE_PROJECTS = [
	378,
	3127,
	3174,
	2898,
	1027,
	3015,
	'378',
	'3127',
	'3174',
	'2898',
	'1027',
	'3015'
];

export const XTRACKER_EXTERNAL_TIMEOFF_TASKS = [
	'PARENTAL LEAVE - CALENDAR DAYS',
	'FAMILY CARE LEAVE (child >7y; sps) (HR)',
	'VACATIONS LEAVE (DE)',
	'VACATIONS LEAVE (HR)',
	'VACATIONS LEAVE',
	'CHILD CARE LEAVE (3y< child <7y) (HR)',
	'WORK INJURY LEAVE',
	'CHILD CARE LEAVE (child <3y) (HR)',
	'PARENTAL LEAVE - WORK DAYS',
	'UNPAID LICENSE LEAVE (HR)',
	'MILITARY LEAVE',
	'COMPENSATORY LEAVE',
	'MATERNITY LEAVE (HR)',
	'HEALTH LEAVE (DE)',
	'HEALTH LEAVE',
	'UNPAID LICENSE LEAVE',
	'COMPENSATORY LEAVE (HR)',
	'SICK LEAVE (DE)',
	'JURY DUTY LEAVE / WITNESS',
	'SICK LEAVE (HR)',
	'STUDENT LEAVE',
	'CHILD CARE LEAVE (PT care/PT work) (HR)',
	'MARRIAGE LEAVE (HR)',
	'MARRIAGE LEAVE',
	'WORK INJURY LEAVE (HR)'
];

// Passes to the next day
export const addDay = (date: Date) => {
	date.setDate(date.getDate() + 1);
	return date;
};
// Passes to the previous day
export const removeDay = (date: Date) => {
	date.setDate(date.getDate() - 1);
	return date;
};
// Passes to the next week
export const addWeeks = (date: Date, weeks: number) => {
	date.setDate(date.getDate() + 7 * weeks);
	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
// Passes to the previous week
export const removeWeeks = (date: Date, weeks: number) => {
	date.setDate(date.getDate() - 7 * weeks);
	return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const addDaysToDate = (startDate: string, numberOfDays: number) => {
	// Convert the start date string to a Date object
	const startDateFormatted = new Date(startDate);

	// Calculate the number of milliseconds in a day
	const millisecondsInDay = 1000 * 60 * 60 * 24;

	// Calculate the total milliseconds to add to the start date
	const totalMillisecondsToAdd = numberOfDays * millisecondsInDay;

	// Create a new Date object by adding the calculated milliseconds to the start date
	const newDate = new Date(startDateFormatted.getTime() + totalMillisecondsToAdd);

	// Return the new date as a string
	return newDate.toISOString();
};

//Convert Decimal Number to hours and minutes
export const decimalToHours = (decimal: number): { hours: number; minutes: number } => {
	// Convert the decimal number to minutes
	const totalMinutes = Math.round(decimal * 60);

	// Calculate the hours and remaining minutes
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;

	return { hours, minutes };
};

// Helper method to get the difference between end and start date, both hours and minutes.
export const dateDifferenceHelper = (startDate: string, endDate: string) => {
	const startDateFormatted = new Date(startDate);
	const endDateFormatted = new Date(endDate);

	const timeDiffMilliseconds = endDateFormatted - startDateFormatted;
	const totalMinutes = timeDiffMilliseconds / (1000 * 60);
	const combinedValue = totalMinutes / 60;
	return combinedValue;
};

export const dateDifferenceHelperInDays = (startDate, endDate) => {
	const startDateFormatted = new Date(startDate);
	startDateFormatted.setHours(0, 0, 0, 0);
	const endDateFormatted = new Date(endDate);
	endDateFormatted.setHours(0, 0, 0, 0);

	const timeDiffMilliseconds = endDateFormatted - startDateFormatted;
	const totalDays = timeDiffMilliseconds / (1000 * 60 * 60 * 24);

	return totalDays;
};

export const getPossibleMinutes = (decimalHours: any) => {
	return Math.round((decimalHours - Math.floor(decimalHours)) * 60);
};

// Function to parse the time in "totalTime" and calculate the "end" time
export const calculateEndTime = (date: any, hours: any, minutes: any) => {
	const startTime = new Date(date);
	const endTime = new Date(startTime.getTime() + hours * 60 * 60 * 1000 + minutes * 60 * 1000);
	return endTime;
};

export const calculateDiferenceHoursMinutes = (startDate, endDate) => {
	const msDifference = Math.abs(endDate - startDate);

	const minutes = Math.floor((msDifference / (1000 * 60)) % 60);
	const hours = Math.floor((msDifference / (1000 * 60 * 60)) % 24);

	return { hours, minutes };
};

export const areDatesInSameWeek = (date1, date2) => {
	const startOfWeek = date => {
		const d = date;
		d.setHours(0, 0, 0, 0);
		d.setDate(date.getDate() - date.getDay());
		return d;
	};
	const isSameWeek = (a, b) =>
		startOfWeek(a) <= b && b <= new Date(startOfWeek(a).setDate(startOfWeek(a).getDate() + 6));

	return isSameWeek(date1, date2);
};

export const formatStringPTToDate = date1 => {
	const [date, time] = date1.split(' ');
	const [day, month, year] = date.split('/');
	return new Date(year, month - 1, day);
};

// Check for Overlaps in a TimeEntries/Events array
export const checkForOverlaps = (entries: any[]): boolean => {
	for (let i = 0; i < entries.length - 1; i++) {
		for (let j = i + 1; j < entries.length; j++) {
			const entryA = entries[i];
			const entryB = entries[j];

			const startA = new Date(entryA.start).getTime();
			const endA = new Date(entryA.end).getTime();
			const startB = new Date(entryB.start).getTime();
			const endB = new Date(entryB.end).getTime();

			// Check for overlap
			if ((startA < endB && endA > startB) || (startB < endA && endB > startA)) {
				return true; // Overlap found
			}
		}
	}
	return false; // No overlaps found
};
//Convert Hour to Decimal
export function convertTimeToDecimal(dateParam: any): number {
	const date = new Date(dateParam);
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const decimalTime = hours + minutes / 60;
	return decimalTime;
}

// Fully refresh the page
export const refreshPage = () => {
	window.location.reload();
};
