import React, { FC, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import EmployeeDataTable from 'pages/Admin/_ProposalsFormUtils/components/EmployeeDataTable';

import { PageTitle, LoadingOverlay } from 'xpand-ui/core';

import { Typography, LinearProgress } from '@mui/material';

import { IAdmission } from 'typings/store/admin/admissions';
import { useStyles } from './styles';
import { DetailsModalProps } from '.';

interface IDetailsModal extends DetailsModalProps {
	proposal: IAdmission | null;
}

import {
	XPAND_EMPLOYEE_ID,
	XPAND_EMPLOYEE_ID_DE,
	XPAND_EMPLOYEE_ID_HR,
	XPAND_EMPLOYEE_ID_SE,
	XPAND_EMPLOYEE_ID_UK,
	BALWURK_EMPLOYEE_ID
} from 'lib/utils/constants';

const DetailsModal: FC<IDetailsModal> = ({
	admissions,
	proposal,
	formFields,
	getEmployeeData,
	clearEmployeeData,
	getFormFieldsConfig
}) => {
	const classes = useStyles();
	const { loading, employeeDataResult, error } = admissions;
	const { formFieldsConfig } = formFields;
	useEffect(() => {
		if (!employeeDataResult && proposal) {
			getEmployeeData(proposal.id);
		}
		if (employeeDataResult) {
			getFormFieldsConfig(
				employeeDataResult?.proposal?.employeeCategory == XPAND_EMPLOYEE_ID ||
					employeeDataResult?.proposal?.employeeCategory == XPAND_EMPLOYEE_ID_DE ||
					employeeDataResult?.proposal?.employeeCategory == XPAND_EMPLOYEE_ID_HR ||
					employeeDataResult?.proposal?.employeeCategory == XPAND_EMPLOYEE_ID_SE ||
					employeeDataResult?.proposal?.employeeCategory == XPAND_EMPLOYEE_ID_UK ||
					employeeDataResult?.proposal?.employeeCategory == BALWURK_EMPLOYEE_ID
					? 'Employee'
					: 'Contractor',
				employeeDataResult?.proposal?.company,
				'New Employee'
			);
		}
	}, [employeeDataResult]);

	useEffect(
		() => () => {
			clearEmployeeData();
		},
		[]
	);

	return (
		<>
			<div className={classes.root}>
				{loading && <LoadingOverlay />}
				<PageTitle
					title={
						<>
							<Typography gutterBottom color="primary" className={classes.name}>
								{proposal?.employeeName || 'N/A'}
							</Typography>
						</>
					}
				/>
				{employeeDataResult === null && (
					<div className={classes.w100}>
						<LinearProgress />
					</div>
				)}

				{employeeDataResult && (
					<Scrollbars
						style={{ height: 'calc(100vh - 220px)', overflowX: 'hidden' }}
						renderTrackHorizontal={(props: any) => (
							<div {...props} style={{ display: 'none' }} className="track-horizontal" />
						)}>
						<EmployeeDataTable employeeData={employeeDataResult} formFields={formFieldsConfig} />
					</Scrollbars>
				)}
			</div>
		</>
	);
};

export default DetailsModal;
