/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { ICompanyUsers } from 'typings/store/admin/admissions';

import {
	string,
	stringRequired,
	boolean,
	number,
	numberRequired,
	dateRequired,
	passwordSecure
} from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	// eslint-disable-next-line func-names
	username: stringRequired.test('duplicatedUsername', 'This username is already in use.', function (payload) {
		return !this.parent.userNames.map((e: ICompanyUsers) => e.username).includes(payload);
	}),
	company: stringRequired,
	businessUnit: stringRequired,
	division: stringRequired,
	department: stringRequired,
	prefix: stringRequired,
	firstName: stringRequired,
	lastName: stringRequired,
	email: stringRequired
		.test('valid-email', 'Must be a valid email', function (newEmail) {
			/* eslint-disable no-useless-escape */
			const emailRegex =
				/^(([^0123456789áÁàÀâÂäÄãÃåÅæÆçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕøØœŒßúÚùÙûÛüÜ/\\[^#$§£%&"«»*¨ºª;,:?+-_€~!=}{'´`<>()\[\]\\.,;:\s@"]+(\.[^0123456789áÁàÀâÂäÄãÃåÅæÆçÇéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕøØœŒßúÚùÙûÛüÜ/\\[^#$§£%&"«»*¨ºª;,:?+-_€~!=}{'´`<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([a-z\-]+\.)+[a-zA-Z]{2,})$/;

			/* eslint-enable no-useless-escape */
			const emailComplete = `${newEmail}`;

			if (!emailRegex.test(emailComplete)) return false; // false -> show erro

			if (this.parent?.emailsList?.includes(emailComplete)) return false; // false -> show error

			return true;
		})
		// eslint-disable-next-line func-names
		.test('duplicatedEmail', 'This email address is already in use.', function (payload) {
			return !this.parent.userNames.map((e: ICompanyUsers) => e.email).includes(payload);
		}),

	location: stringRequired,
	mobilePhone: stringRequired.test('phoneregex', 'Please enter a valid phone number.', payload => {
		if (/^[+]{0,1}[0-9]{0,4}[ ]{0,1}[0-9]{6,12}$/.test(payload as unknown as string)) {
			return true;
		}

		return false;
	}),
	hiredDate: dateRequired,
	addToXtracker: boolean,
	holidayType: string.when('addToXtracker', {
		is: true,
		then: stringRequired,
		otherwise: string
	}),
	hasTimeOffPolicy: boolean,
	timeOffApprovalType: string.when('hasTimeOffPolicy', {
		is: false,
		then: stringRequired,
		otherwise: string
	}),
	timeOffPolicy: string.when('hasTimeOffPolicy', {
		is: false,
		then: stringRequired,
		otherwise: string
	}),
	showEmployeeManagers: boolean,
	employeeManager: string.when('showEmployeeManagers', {
		is: true,
		then: stringRequired,
		otherwise: string
	}),
	vacationDays: number.min(0, 'The minimum number of vacation days is 0.').when('hasTimeOffPolicy', {
		is: false,
		then: numberRequired.transform((cv, ov) => (ov === '' ? undefined : cv)),
		otherwise: number
	}),
	password: passwordSecure
});

export const defaultValues = {
	userNames: [] as ICompanyUsers[],
	employeeManagers: [] as any[],
	username: '',
	company: '',
	businessUnit: '',
	division: '',
	department: '',
	prefix: '',
	firstName: '',
	lastName: '',
	email: '',
	location: '',
	hiredDate: '',
	addToXtracker: false,
	holidayType: '',
	hasTimeOffPolicy: true,
	timeOffApprovalType: '',
	timeOffPolicy: '',
	showEmployeeManagers: false,
	employeeManager: '',
	vacationDays: '0',
	password: ''
};

export const tabs = [
	{
		id: 0,
		label: 'Basic Info'
	},
	{
		id: 1,
		label: 'Where The Employee Belongs'
	},
	{
		id: 2,
		label: 'Employee Company Info'
	}
];
