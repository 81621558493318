/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable indent */
import { Dispatch } from 'redux';
import { compareValues } from 'xpand-ui/utils/sort';
import { removeTimezone, parseDateToManage } from 'xpand-ui/utils/dates';
import moment, { Moment } from 'moment';

import {
	fetchApprovalsTimesheet,
	fetchApprovalsTimesheetPeriodDetails,
	fetchPostApprovalsTimesheetWithoutTimeOffReject,
	fetchPostApprovalsTimesheetWithoutTimeOffApprove,
	fetchPostApprovalsTimesheetWithTimeOffReject,
	fetchPostApprovalsTimesheetWithTimeOffApprove,
	fetchApprovalsExpenses,
	fetchApprovalsExpenseDownloadAttachment,
	fetchOutlookEvents,
	fetchPostApprovalsExpenses,
	fetchApprovalsTimeOff,
	fetchPostApprovalsTimeOff,
	fetchApprovalsTimeOffDetails
} from 'lib/api/general/personalInfo';
import { OriginalResponse } from 'lib/handlers/requestTypes';
import {
	setLoading,
	setError,
	setApprovalsTimesheet,
	setApprovalsTimesheetDetails,
	setUpdateTimesheet,
	setApprovalsExpenses,
	setUpdateExpenses,
	setApprovalsTimeoff,
	setUpdateTimeoff,
	setApprovalsTimeOffDetails,
	setLoadingToFalse
} from './actions';

//!--------------------------------Approvals Timesheet----------------------------------------------//
export const getApprovalsTimesheet =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchApprovalsTimesheet(payload);
			if (response) {
				dispatch(setApprovalsTimesheet(response.data.results));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getApprovalsTimesheetPeriodDetails =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchApprovalsTimesheetPeriodDetails(payload);
			if (response) {
				dispatch(setApprovalsTimesheetDetails(response.data.results));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const postApprovalsTimesheetWithoutTimeOffApprove =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchPostApprovalsTimesheetWithoutTimeOffApprove(payload);
			if (response) {
				dispatch(setUpdateTimesheet(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const postApprovalsTimesheetWithoutTimeOffReject =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchPostApprovalsTimesheetWithoutTimeOffReject(payload);
			if (response) {
				dispatch(setUpdateTimesheet(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const postApprovalsTimesheetWithoutTimeOffRejectWithTimeOff =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchPostApprovalsTimesheetWithTimeOffReject(payload);
			if (response) {
				dispatch(setUpdateTimesheet(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const postApprovalsTimesheetWithoutTimeOffApproveWithTimeOff =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchPostApprovalsTimesheetWithTimeOffApprove(payload);
			if (response) {
				dispatch(setUpdateTimesheet(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

//!--------------------------------Approvals Expenses----------------------------------------------//

export const getApprovalsExpense =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchApprovalsExpenses(payload);
			if (response) {
				dispatch(setApprovalsExpenses(response.data.results));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const downloadAttachmentApprovalsExpenses =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchApprovalsExpenseDownloadAttachment(payload);
			dispatch(setLoadingToFalse());
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const postApprovalsExpenses =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchPostApprovalsExpenses(payload);
			if (response) {
				dispatch(setUpdateExpenses(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

//!--------------------------------Approvals TimeOff----------------------------------------------//

export const getApprovalsTimeOff =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchApprovalsTimeOff(payload);
			if (response) {
				dispatch(setApprovalsTimeoff(response.data.results));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const postApprovalsTimeOff =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response = await fetchPostApprovalsTimeOff(payload);
			if (response) {
				dispatch(setUpdateTimeoff(response));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};

export const getApprovalsTimeOffDetails =
	(payload: unknown) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setApprovalsTimeOffDetails(null));
			dispatch(setLoading());
			const response = await fetchApprovalsTimeOffDetails(payload);
			if (response) {
				dispatch(setApprovalsTimeOffDetails(response.data.results));
			} else {
				dispatch(setError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};
