/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, Input } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS

// import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';
import { INewClientTabs } from '../../NewClient';

//* COMPONENT INTERFACES
// interface IProjectTeam extends IProjectTabs {}

//* COMPONENT
const Address: FC<INewClientTabs> = ({ control, errors, actions }) => {
	const classes = useStyles();
	return (
		<Grid container spacing={4} className={classes.root}>
			{/* ********* Address *********** */}
			<Grid item {...sizes[6]}>
				<Select
					name="addressAction"
					label="Action"
					options={actions}
					helperText="Action to perform over client's address"
					control={control}
					errors={errors}
					required
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="address"
					label="Address"
					helperText="Complete address (street, door, floor...)"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="zipCode"
					label="Zip Code"
					helperText="Address zip code"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="city"
					label="City"
					helperText="City where address is located"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="country"
					label="Country"
					helperText="Country where address city is located"
					required
					control={control}
					errors={errors}
				/>
			</Grid>
		</Grid>
	);
};

export default Address;
