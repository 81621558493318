import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { submitEmployeeSalaryCategoryReject } from 'store/administration/employeeSalaryCategories/thunks';

import RejectCostSalaryCategory from './RejectCostSalaryCategory';
import { setNotificationAsReadProposals } from 'store/notifications/thunks';

const mapStateToProps = ({ employeeSalary }: AppState) => ({ employeeSalary });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			submitEmployeeSalaryCategoryReject,
			setNotificationAsReadProposals
		},
		dispatch
	);

export type RejectCostSalaryCategoryProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(RejectCostSalaryCategory);
