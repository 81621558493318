import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	//* CARDS CONTAINER
	root: {
		flexGrow: 1,
		paddingRight: '15px'
	},

	//* CARD
	// ? main grid item
	cardGridItem: { minWidth: '350px' },
	// ? card style
	cardBase: {
		display: 'block',
		margin: 'auto',
		boxShadow: theme.boxShadow,
		borderRadius: '8px'
	},
	accentCard: {
		border: '1px solid #3F80EF'
	},
	// ? card sections
	firstLine: {
		display: 'flex',
		padding: 15,
		paddingBottom: '0'
	},
	secondLine: {
		display: 'flex',
		padding: 15,
		minHeight: '115px',
		justifyContent: 'space-around',
		alignItems: 'baseline',
		flexDirection: 'column'
	},
	thirdLine: {
		display: 'flex',
		flexDirection: 'column',
		padding: '15px 0 15px 15px'
	},
	// ? card first line items
	avatarContainer: {
		minWidth: '50px',
		minHeight: '50px',
		maxWidth: '50px',
		maxHeight: '50px',
		position: 'relative',
		overflow: 'hidden',
		borderRadius: '50%'
	},
	avatarImage: {
		display: 'inline',
		margin: '0 auto',
		height: '50px',
		width: 'auto'
	},
	nameAndUsernameContainer: {
		display: 'flex',
		paddingTop: '2px'
	},
	jobRole: {
		display: 'flex',
		marginLeft: '15px'
	},
	//* text helpers
	text16Semi: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px'
	},
	text16Reg: {
		fontFamily: 'Open Sans',
		fontSize: '16px'
	},
	text14Semi: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '14px'
	},
	text14Reg: {
		fontFamily: 'Open Sans',
		fontSize: '14px'
	}
}));

export { useStyles };
