import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { setCSCDetailsPageFilter, setCSCDetailsHistoryPageFilter } from 'store/filters/actions';
import CollaborationTypeList from './CollaborationTypeList';
import {
	getCollaborationTypeList,
	submitCollaborationTypeCancel
} from 'store/administration/collaborationTypesManagement/thunks';
import { setNotificationAsReadProposals, setNewNotificationProposals } from 'store/notifications/thunks';

const mapStateToProps = ({ collaborationTypesManagement, filters }: AppState) => ({
	collaborationTypesManagement,
	filters,
	setNotificationAsReadProposals,
	setNewNotificationProposals
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export type CollaborationTypeListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CollaborationTypeList);
