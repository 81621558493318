//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC } from 'react';
import moment from 'moment';

//* EXTERNAL LIBS --> MUI
import { Button } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table } from 'xpand-ui/core';
import { parseDateToManageSaf } from 'xpand-ui/utils/dates';

//* TYPINGS
import { IDocument, IFinancialDocuments } from 'typings/store/personalInfoTypes';
import { ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import DownloadIcon from 'assets/icons/downloadButton.svg';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { MyFinancialDocumentsProps } from '.';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES

//* COMPONENT
const MyFinancialDocuments: FC<MyFinancialDocumentsProps> = ({
	personalInfo,
	filters,
	getUserFinancialDocuments,
	getUserDocumentByName,
	clearPersonalInfoError,
	setMyFinancialDocumentsPageFilter
}) => {
	const classes = useStyles();
	const { loading, error, userFinancialDocuments } = personalInfo;
	const { myFinancialDocumentsFilter } = filters;

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(myFinancialDocumentsFilter);
	const isImpersonate = () => {
		if (getLSField('impersonate_userInfo')) {
			return true;
		} else {
			return false;
		}
	};

	// Update filters whenever necessary
	useEffect(() => {
		setMyFinancialDocumentsPageFilter(searchValue);
	}, [searchValue]);

	useEffect(() => {
		if (!userFinancialDocuments && !error) getUserFinancialDocuments();
	}, [userFinancialDocuments]);

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<IFinancialDocuments>[] = useMemo(
		() => [
			{
				label: 'Date',
				id: 'date',
				type: 'date',
				accentColumn: true,
				format: (row, text) => moment(new Date(parseDateToManageSaf(text))).format('YYYY-MM')
			},
			{ label: 'Type', id: 'documentType', accentColumn: true },
			{ label: 'Document Name', id: 'documentName' },
			{
				label: '',
				id: 'downloadDoc',
				isSortable: false
			}
		],
		[]
	);

	const onDownloadDocumentHandler = (document: IDocument) => {
		getUserDocumentByName(document.documentName);
	};

	const tablePayload = useMemo(
		() =>
			userFinancialDocuments &&
			userFinancialDocuments.documents?.map(finDoc => ({
				...finDoc,
				id: finDoc.documentName,
				date: finDoc.uploadDate,
				downloadDoc: (
					<Button
						className={classes.iconContainer}
						onClick={() => onDownloadDocumentHandler(finDoc)}
						disabled={isImpersonate()}>
						<div className={classes.icon}>
							<DownloadIcon />
						</div>
					</Button>
				)
			})),
		[userFinancialDocuments]
	);

	if (error) return handleErrorPage(error, clearPersonalInfoError);

	const isLoading = userFinancialDocuments === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<Table
				tableData={tablePayload}
				columns={tableColumns}
				defaultSortColumn="date"
				defaultOrder="desc"
				handleSearch={{ searchValue, setSearchValue }}
			/>
		</>
	);
};

export default withLayout(MyFinancialDocuments);
