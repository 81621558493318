import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'typings/state';
import { submitCollaborationTypeApprove } from 'store/administration/collaborationTypesManagement/thunks';

import ApproveCollaborationType from './ApproveCollaborationType';
import { resetEmployee } from 'store/administration/collaborationTypesManagement/actions';
import { setNotificationAsReadProposals } from 'store/notifications/thunks';

const mapStateToProps = ({ collaborationTypesManagement }: AppState) => ({ collaborationTypesManagement });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			submitCollaborationTypeApprove,
			resetEmployee,
			setNotificationAsReadProposals
		},
		dispatch
	);

export type ApproveCollaborationTypeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ApproveCollaborationType);
