import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from 'typings/state';

import { getSystemSkills, clearPersonalInfoError } from 'store/personalInfo/thunks';
import { sendPostNewSkillsTree } from 'store/administration/skillsAndResumes/thunks';
import {setClearSystemSkills} from 'store/personalInfo/actions'

import ManageSkills from './ManageSkills';

const mapStateToProps = ({ personalInfo, skillsAndResumes }: AppState) => ({ personalInfo, skillsAndResumes });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getSystemSkills, sendPostNewSkillsTree, clearPersonalInfoError, setClearSystemSkills }, dispatch);

export type ManageSkillsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ManageSkills);
