/* eslint-disable react/jsx-indent, indent, react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo } from 'react';
import { Moment } from 'moment';

//* EXTERNAL LIBS --> MUI
import { Typography, Grid, Button, FormHelperText } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import Add from '@mui/icons-material/Add';

//* EXTERNAL LIBS --> XPAND-UI
import { useWindowDimensions } from 'xpand-ui/utils/hooks';
import { Dialog, LoadingOverlay, NoData, ActionFooter, TabPanel, TabHeader, PageTitle, Tab } from 'xpand-ui/core';
import { parseDateToShow, parseDateToManage } from 'xpand-ui/utils/dates';
import { InputLabel, DatePicker } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { ISkill, IEmployeeSubGroups } from 'typings/store/personalInfoTypes';
import { Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { Prompt } from 'react-router';
import SkillModalButton from './SkillModalButton';
import {
	/** Components struct */
	GroupCard,
	NormalSkillCardHeader,
	LanguageSkillCardHeader,
	/** FormData helpers */
	addGroupsToStruct,
	filterOnlyUserSkills,
	normalGroup,
	languagesGroup
} from './utils';
import Adicionar from '../../../../assets/icons/Adicionar.svg';
import { useStyles } from './styles';
import { MySkillsProps } from '.';
import { getLSField, removeLSField, setLSField } from 'lib/utils/cookies';

const validateDate = (date?: string | Moment) => {
	if (!date) return null;

	if (typeof date === 'object') return parseDateToManage(date);

	if (typeof date === 'string') {
		return date.trim() === '' || date.trim().toLowerCase() === 'invalid date' ? null : date;
	}

	return date;
};

const notificationPayload = {
	area: 'My Profile',
	section: 'Skills'
};

//* COMPONENT INTERFACES
interface IMySkills extends MySkillsProps {
	match: Match<{
		skillCategory: string;
	}>;
	goToPage: (path: string) => void;
}
const STATUS_SAVED = 0;
const STATUS_SUBMITTED = 1;
const payload = {};
const toAddPayload = {};

let formHasChanged = false;
//Disable Save button when change is saved
let disableSaveButton = false;
let isCreatingFormInitialState = true;

//* COMPONENT

const MySkills: FC<IMySkills> = ({
	match,
	company,
	personalInfo,
	getUserSkills,
	getSystemSkills,
	updateUserSkills,
	clearPersonalInfoError,
	setNotificationAsRead
}) => {
	const classes = useStyles();
	const { params } = match;

	const { loading, error, userSkills, systemSkills } = personalInfo;

	// Skills Form Payload
	const [submitted, setSubmitted] = useState(false);
	const [disableSubmit, setDisableSubmit] = useState(true);
	const [formData, setFormData] = useState({});
	const [toAddForm, setToAddForm] = useState({});
	const [careerStartDate, setCareerStartDate] = useState<string | null>(null);
	const [dateError, setDateError] = useState({});
	// TABS COMPONENT - State
	const [tabSelected, setTabSelected] = useState(0);
	// MODAL COMPONENT
	const [openFeedbackModal, setOpenFeedbackModal] = useState<any>(null);
	const [openModalAddSkill, setOpenModalAddSkill] = useState(false);
	const [openModalRemoveSkill, setOpenModalRemoveSkill] = useState(false);
	const [modalSelectedState, setModalSelectedState] = useState({});

	const [action, setAction] = useState('');
	const { width } = useWindowDimensions();

	const isMobile = useMemo(() => width <= 800, [width]); // default is 600 mobile
	const isTablet = useMemo(() => width <= 1000, [width]); // default is 900 tablet
	const dividedParams = params.skillCategory.split('_');
	const handleCareerStartDate = (newDate: string | null) => {
		newDate && setCareerStartDate(newDate);
		formHasChanged = true;
		disableSaveButton = true;
	};

	const resetComponentOnUnmount = () => {
		// reset default component values
		isCreatingFormInitialState = false;
		setSubmitted(false);
	};

	useEffect(
		() => () => {
			formHasChanged = false;
			isCreatingFormInitialState = true;
		},
		[]
	);

	// fetch user Skills
	useEffect(() => {
		if (isCreatingFormInitialState || (!userSkills && !error) || submitted) {
			getUserSkills();
			resetComponentOnUnmount();
			if (action === 'SAVE') disableSaveButton = false;
			if (action === 'SUBMIT') formHasChanged = false;
			// } else if (userSkills && userSkills.status === STATUS_SAVED) {
			// 	userSkills.careerStartDate && setCareerStartDate(userSkills.careerStartDate);
			// } else if (userSkills && userSkills.status === STATUS_SUBMITTED) {
			// 	userSkills.careerStartDate && setCareerStartDate(userSkills.careerStartDate);
			// 	setCareerStartDate(null);
		}
		if (userSkills) {
			userSkills.careerStartDate && setCareerStartDate(userSkills.careerStartDate);
		}
	}, [userSkills, submitted]);

	// fetch System Skills
	useEffect(() => {
		if (!systemSkills || systemSkills.length === 0) {
			getSystemSkills();
		}
	}, [systemSkills]);

	// helper to update the skills values based on the struct
	const updateFormTree = (
		area: number,
		group: number,
		sub: number,
		skill: number,
		experienceLevel: number | null,
		knowledgeLevel: number | null
	) => {
		const newState = { ...formData };

		formHasChanged = true;
		disableSaveButton = true;
		if (experienceLevel !== null) newState[area][group][sub][skill].experienceLevel = experienceLevel;
		if (knowledgeLevel !== null) newState[area][group][sub][skill].knowledgeLevel = knowledgeLevel;

		// scenario if the skill group is language, force the knowledgeLevel to be "0"
		if (area && group && sub && skill && group === 16) newState[area][group][sub][skill].knowledgeLevel = 0;

		setFormData(newState);
	};

	// when entering the page, populate page with struct coming from the DB
	useEffect(() => {
		if (userSkills && systemSkills) {
			const area = systemSkills.find(
				e =>
					e.id &&
					e.id === parseInt(dividedParams[1]) &&
					e.name &&
					e.name.toLocaleLowerCase().includes(dividedParams[0])
			);
			if (area && area.id && !formData[area.id]) {
				systemSkills.map(a => {
					// create area level
					if (
						a.id &&
						a.name &&
						a.id === parseInt(dividedParams[1]) &&
						a.name.toLocaleLowerCase().includes(dividedParams[0])
					) {
						const { toRender, toAdd } = a.skillGroups
							? addGroupsToStruct(a.skillGroups, userSkills, true, true)
							: { toRender: null, toAdd: null };
						payload[a.id] = { ...toRender };
						toAddPayload[a.id] = { ...toAdd };
					}
					return a;
				});

				resetComponentOnUnmount();

				setFormData({ ...formData, ...payload });
				setToAddForm({ ...toAddForm, ...toAddPayload });
			}
		}
	}, [userSkills, systemSkills, params.skillCategory]);

	useEffect(() => {
		setTabSelected(0);
	}, [params.skillCategory]);

	const feedbackModalFooterActions = [
		{
			id: 'close',
			label: 'Close',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setOpenFeedbackModal(null);
			}
		}
	];

	// TABS COMPONENT - handle method
	const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => setTabSelected(newValue);

	// get current page SKILL AREA information
	const {
		id: areaId,
		name: areaName,
		skillGroups
	} = (systemSkills &&
		systemSkills.find(
			e => e.id === parseInt(dividedParams[1]) && e.name?.toLocaleLowerCase().includes(dividedParams[0])
		)) || {
		id: null,
		name: null,
		skillGroups: null
	};

	// need to filter, because tab component uses the array index to render the tab button and tab container
	// and those values, need to be incremented by 1 (e.g: [0, 1, 2, 3] and not [4, 17, 23, 77]
	const { skillsRender, skillsToAdd } = (systemSkills &&
		areaId &&
		skillGroups &&
		filterOnlyUserSkills(formData[areaId], toAddForm[areaId], skillGroups)) || {
		skillsRender: null,
		skillsToAdd: null
	};

	// Counter to show how many skills are selected
	const selectedSkillsCounter = () => {
		const selectedSkillsAndSubSkills = [];
		Object.keys(modalSelectedState).forEach(skillNumber => {
			// true means that the skill doesn't have subkills and can be counted else we should count the subskills
			if (modalSelectedState[skillNumber] === true) {
				selectedSkillsAndSubSkills.push(skillNumber);
			} else {
				Object.keys(modalSelectedState[skillNumber]).forEach(subskill =>
					selectedSkillsAndSubSkills.push(subskill)
				);
			}
		});
		return selectedSkillsAndSubSkills.length;
	};

	/** START - ADD AND REMOVE SKILLS MODAL SECTION */
	// handler to create the modal area and each skill group card to add/remove
	const addRemoveSkillsContent = (addPayload: any) =>
		addPayload &&
		(openModalAddSkill || openModalRemoveSkill) && (
			<>
				{selectedSkillsCounter() > 0 && (
					<div className={classes.skillCounterLabel}>
						You have selected {selectedSkillsCounter()} {selectedSkillsCounter() === 1 ? 'skill' : 'skills'}
					</div>
				)}
				<Grid container direction="row" alignItems="flex-start" spacing={4}>
					{addPayload.map(
						(e: any, index: number) =>
							e.hasSubGroupsToAdd && (
								<SkillModalButton
									key={`addSkButton_Order:${index + 1}_id:${e.id}`}
									styles={classes}
									order={index + 1} // grid position
									id={e.id} // item index
									skill={e}
									label={e.name}
									subTitle={(e.section && `${e.skillSubGroups.length} Sub Skills`) || null}
									selected={modalSelectedState}
									toggleSelected={setModalSelectedState}
								/>
							)
					)}
				</Grid>
			</>
		);

	// modal content for ADD skills
	const addSkillsContent = addRemoveSkillsContent(skillsToAdd);
	// modal content for REMOVE skills
	const removeSkillsContent = addRemoveSkillsContent(skillsRender);

	// handler to ADD new skills to the screen after the modal submission
	const handleAddNewSkillsGroups = (): void => {
		formHasChanged = true;
		disableSaveButton = true;
		// new payload objects to update state
		const newSkPayload = { ...formData };
		const newToAddPayload = { ...toAddForm };

		if (!areaId) return;

		Object.keys(modalSelectedState).map(newGroup => {
			// add skill GROUP to the screen
			if (modalSelectedState[newGroup] === true) {
				newSkPayload[areaId][newGroup] = newToAddPayload[areaId][newGroup];

				// remove from the TO ADD list
				delete newToAddPayload[areaId][newGroup];
			} else {
				// add skill SUBGROUP to the screen
				Object.keys(modalSelectedState[newGroup]).map(newSubGroup => {
					if (newSkPayload[areaId][newGroup]) {
						newSkPayload[areaId][newGroup][newSubGroup] = {
							...(newSkPayload[areaId][newGroup][newSubGroup] &&
								newSkPayload[areaId][newGroup][newSubGroup]),
							...newToAddPayload[areaId][newGroup][newSubGroup]
						};
					} else {
						newSkPayload[areaId][newGroup] = {
							[newSubGroup]: {
								...newToAddPayload[areaId][newGroup][newSubGroup]
							}
						};
					}

					// remove subgroup from the TO ADD list
					delete newToAddPayload[areaId][newGroup][newSubGroup];

					// if empty, remove group from TO ADD list
					if (Object.keys(newToAddPayload[areaId][newGroup]).length === 0) {
						delete newToAddPayload[areaId][newGroup];
					}

					return null;
				});
			}

			return null;
		});

		// update both formData and ToAddForm states
		setFormData(newSkPayload);
		setToAddForm(newToAddPayload);

		// clear selected state from Add Skill modal selection
		setModalSelectedState({});

		// close modal
		setOpenModalAddSkill(false);
		setTabSelected(0);
	};

	// handler to REMOVE new skills to the screen after the modal submission
	const handleRemoveNewSkillsGroups = () => {
		formHasChanged = true;
		disableSaveButton = true;
		// new payload objects to update state
		const newSkPayload = { ...formData };
		const newToAddPayload = { ...toAddForm };

		if (!areaId) return;

		const clearSkillsValues = (skls: ISkill) => {
			const newData = {};
			Object.keys(skls).map(s => {
				newData[s] = { knowledgeLevel: 0, experienceLevel: 0 };
				return null;
			});

			return newData;
		};
		const clearSubGroupValues = (subGs: IEmployeeSubGroups) => {
			const newData = {};
			Object.keys(subGs).map(s => {
				newData[s] = clearSkillsValues(subGs[s]);

				return null;
			});
			return newData;
		};

		Object.keys(modalSelectedState).map(newGroup => {
			// remove skill GROUP to the screen
			if (modalSelectedState[newGroup] === true) {
				newToAddPayload[areaId][newGroup] = clearSubGroupValues(newSkPayload[areaId][newGroup]);

				delete newSkPayload[areaId][newGroup];
			} else {
				// remove skill SUBGROUP to the screen
				Object.keys(modalSelectedState[newGroup]).map(newSubGroup => {
					if (!newToAddPayload[areaId][newGroup]) newToAddPayload[areaId][newGroup] = {};
					newToAddPayload[areaId][newGroup][newSubGroup] = {
						...(newToAddPayload[areaId][newGroup][newSubGroup] &&
							newToAddPayload[areaId][newGroup][newSubGroup]),
						...clearSkillsValues(newSkPayload[areaId][newGroup][newSubGroup])
					};
					// remove from the TO ADD list
					delete newSkPayload[areaId][newGroup][newSubGroup];

					// if empty, remove group from TO ADD list
					if (Object.keys(newSkPayload[areaId][newGroup]).length === 0) {
						delete newSkPayload[areaId][newGroup];
					}

					return null;
				});
			}

			return null;
		});

		// update both formData and ToAddForm states
		setFormData(newSkPayload);
		setToAddForm(newToAddPayload);

		// clear selected state from Add Skill modal selection
		setModalSelectedState({});

		// close modal
		setOpenModalRemoveSkill(false);
		setTabSelected(0);
	};
	/** END - ADD AND REMOVE SKILLS MODAL SECTION */

	const hasSkillsOnScreen = areaId && formData[areaId] && Object.keys(formData[areaId]).length > 0;
	const hasSkillsToAdd = areaId && toAddForm[areaId] && Object.keys(toAddForm[areaId]).length > 0;

	const submitUpdateSkills = async (type: string) => {
		if (
			((userSkills && userSkills.careerStartDate) || (careerStartDate !== null && careerStartDate !== '')) &&
			Object.keys(dateError).length === 0
		) {
			await updateUserSkills({
				type,
				careerStartDate:
					(userSkills && userSkills.careerStartDate) || careerStartDate
						? careerStartDate || (userSkills && userSkills.careerStartDate)
						: null,
				skills: formData
			});
			setSubmitted(true);
		} else {
			setDateError({
				careerStartDate: {
					type: 'manual',
					message: 'This field is mandatory'
				}
			});
		}
	};

	const pageTitleActions = [
		{
			id: areaName ? `${areaName}addSkill` : 'addSkill',
			onClick: () => setOpenModalAddSkill(true),
			disabled: !hasSkillsToAdd || getLSField('impersonate_userInfo'),
			icon: <Adicionar />,
			label: 'Add Skills'
		},
		{
			id: areaName ? `${areaName}removeSkill` : 'removeSkill',
			onClick: () => setOpenModalRemoveSkill(true),
			disabled: !hasSkillsOnScreen || getLSField('impersonate_userInfo'),
			color: 'secondary',
			icon: <DeleteOutline />,
			label: 'Remove Skills'
		}
	];

	// Code currently not in use.
	//Verify if its possible to submit the skills
	const ableToSubmit = useMemo(() => {
		for (let skillGroup in formData[areaId!]) {
			for (let skills in formData[areaId!][skillGroup]) {
				let noChangedFields = 0;
				for (let skill in formData[areaId!][skillGroup][skills]) {
					if (
						formData[areaId!][skillGroup][skills][skill]?.knowledgeLevel === 0 &&
						formData[areaId!][skillGroup][skills][skill]?.experienceLevel === 0
					) {
						noChangedFields++;
					}
				}
				if (Object.keys(formData[areaId!][skillGroup][skills]).length === noChangedFields) {
					return true;
				}
			}
		}
		if (areaId && formData[areaId] && Object.keys(formData[areaId]).length === 0) {
			return true;
		}
		// no startDate or no changes
		return !formHasChanged;
	}, [areaId, formData, formHasChanged]);

	//Verify if its possible to save the skills
	const ableToSave = useMemo(() => {
		// empty Form
		if (areaId && formData[areaId] && Object.keys(formData[areaId]).length === 0) {
			return true;
		}

		// no startDate or no changes
		return !disableSaveButton;
	}, [areaId, formData, disableSaveButton]);

	//Verify if its possible to submit the skills
	useEffect(() => {
		if (formHasChanged || userSkills?.status == 0) {
			setDisableSubmit(false);
		} else {
			setDisableSubmit(true);
		}
	}, [formData, formHasChanged, userSkills]);

	const footerActions = useMemo(
		() => [
			{
				id: 'save',
				endIcon: 'save',
				label: 'Save',
				onClick: () => {
					submitUpdateSkills('SAVE');
					setAction('SAVE');
				},
				color: 'secondary',
				variant: 'outlined',
				disabled:
					(ableToSave && userSkills?.status === STATUS_SAVED) ||
					Object.keys(dateError).length > 0 ||
					getLSField('impersonate_userInfo')
			},
			{
				id: 'submit',
				endIcon: 'submit',
				label: 'Submit',
				onClick: () => {
					submitUpdateSkills('SUBMIT'), setAction('SUBMIT');
					setNotificationAsRead(userSkills?.employee, notificationPayload);
				},
				color: 'primary',
				variant: 'contained',
				disabled: Object.keys(dateError).length > 0 || getLSField('impersonate_userInfo') || disableSubmit
			}
		],
		[ableToSubmit, submitUpdateSkills, userSkills]
	);

	const isLoading =
		(userSkills && userSkills.status === null) ||
		(isCreatingFormInitialState && !!systemSkills && !!userSkills) ||
		!systemSkills;

	if (error) return handleErrorPage(error, clearPersonalInfoError);

	if (isLoading || userSkills === null || userSkills.status === null) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			{/* STATE LABEL & CAREER START & FEEDBACK */}
			<Grid container direction="row" spacing={2} className={classes.root}>
				{/* STATE LABEL */}
				<Grid item {...sizes[12]} className={classes.headerRows}>
					{userSkills.status === STATUS_SAVED && (
						<Typography className={classes.stateTitle}>
							<span style={{ alignContent: 'center' }}>
								<PriorityHighIcon color="primary" style={{ verticalAlign: 'middle' }} />
								<span style={{ paddingTop: '2px' }}>
									You need to submit your skills for them to be approved.
								</span>
							</span>
						</Typography>
					)}
					{userSkills.status === STATUS_SUBMITTED && (
						<Typography className={classes.stateTitle}>
							<span style={{ alignContent: 'center' }}>
								<CheckIcon color="primary" style={{ verticalAlign: 'middle' }} /> Your skills are
								approved. You can edit them or add new skills.
							</span>
						</Typography>
					)}
					{!userSkills.careerStartDate && (
						<Typography className={classes.stateTitle}>
							<span style={{ alignContent: 'center' }}>
								<PriorityHighIcon color="primary" style={{ verticalAlign: 'bottom' }} />
								<span style={{ paddingTop: '2px' }}>
									Indicate your career start date before submitting your skills.
								</span>
							</span>
						</Typography>
					)}
				</Grid>
				{/* CAREER START */}
				<Grid item {...sizes[7]} className={classes.headerRows}>
					<span className={classes.careerPicker}>
						<DatePicker
							required
							name="careerStartDate"
							label="CAREER START DATE"
							value={careerStartDate}
							errors={dateError}
							helperText={`This field means your all time career, not just at ${company.companyText}`}
							handleChange={handleCareerStartDate}
							additionalOnChange={newDate => {
								const isValid = validateDate(newDate as string);

								if (newDate && newDate !== '' && isValid) {
									setDateError({});
								} else if (!isValid) {
									setDateError({
										careerStartDate: {
											type: 'manual',
											message: 'Must be a valid date'
										}
									});
								}
							}}
						/>
					</span>
				</Grid>
				{/* FEEDBACK */}
				<Grid item {...sizes[4]} className={classes.headerRows}>
					<div className={classes.feedbackContainer}>
						<div style={{ display: 'contents', flexDirection: 'column' }}>
							<InputLabel className={classes.feedbackLabel}>FEEDBACK</InputLabel>
							{userSkills && userSkills.feedbacks && userSkills.feedbacks.length === 1 ? (
								userSkills.feedbacks.map(feed => (
									<>
										<Typography key={`${feed.id}_feed`} variant="subtitle1">
											{parseDateToShow(feed.feedbackDate as string)} - {feed.feedback}
										</Typography>
									</>
								))
							) : userSkills && userSkills.feedbacks && userSkills.feedbacks.length > 1 ? (
								<>
									<Grid item {...sizes[8]}>
										<Typography variant="subtitle1">
											{parseDateToShow(userSkills.feedbacks[0]?.feedbackDate as string)} -{' '}
											{userSkills.feedbacks[0]?.feedback}
										</Typography>
										<Button
											style={{ float: 'right' }}
											color="primary"
											variant="text"
											onClick={() => setOpenFeedbackModal(true)}>
											Read more
										</Button>
									</Grid>

									<Dialog
										maxWidth="xs"
										modal={{
											open: Boolean(openFeedbackModal),
											handleClose: (event: unknown, reason: string) => {
												if (reason !== 'backdropClick') setOpenFeedbackModal(null);
											},
											content: (
												<div>
													{userSkills &&
													userSkills.feedbacks &&
													userSkills.feedbacks.length > 1
														? userSkills.feedbacks.map(feed => (
																<>
																	<InputLabel className={classes.feedbackLabel}>
																		FEEDBACK
																	</InputLabel>
																	<Typography
																		style={{ marginBottom: '20px' }}
																		key={`${feed.id}_feed`}
																		variant="subtitle1">
																		{parseDateToShow(feed.feedbackDate as string)} -{' '}
																		{feed.feedback}
																	</Typography>
																</>
														  ))
														: ''}
												</div>
											)
										}}
										title="Feedbacks"
										actions={feedbackModalFooterActions}
										scroll="body"
									/>
								</>
							) : (
								'No Feedback Available'
							)}
						</div>
					</div>
				</Grid>
			</Grid>
			{/* TITLE and ACTIONS & MODALS & SKILLS CONTAINER */}
			{ableToSubmit && (
				<Typography>
					All groups and subgroups must have at least one skill changed in order to be submitted
				</Typography>
			)}
			<Grid
				container
				direction="column"
				spacing={2}
				justifyContent="space-evenly"
				alignItems="flex-start"
				className={classes.root}>
				{/* CONTAINER TITLE AND ACTIONS */}
				{areaName && <PageTitle actions={areaId ? pageTitleActions : []} />}
				{/* SKILLS CONTAINER */}
				{/* SKILLS TABS AREA */}
				{skillsRender && areaId && skillsRender.length > 0 ? (
					<Grid item {...sizes[12]} className={classes.rows}>
						{/* SKILLS TABS HEADER */}
						<TabHeader
							selected={tabSelected}
							handleSelected={handleTabChange}
							variant="scrollable"
							scrollButtons="auto">
							{skillsRender.map((group, index) => {
								if (
									formData[areaId][group.id] === undefined ||
									(group as unknown as any).hasSubGroupsToAdd === false
								)
									return null;
								return (
									<Tab
										key={`${areaId}_${group.id}_tab_button`}
										label={group.name as string}
										index={index}
									/>
								);
							})}
						</TabHeader>
						{/* SKILLS TABS BODY */}
						{skillsRender.map((group, index) => {
							/** don't render hidden group */
							if (
								formData[areaId][group.id] === undefined ||
								(group as unknown as any).hasSubGroupsToAdd === false
							)
								return null;

							return (
								/* SKILL GROUPS CARD */
								<TabPanel key={`${group.id}_tab_content`} value={tabSelected} index={index}>
									<Grid
										container
										spacing={2}
										direction="row"
										justifyContent="space-evenly"
										alignItems="center">
										{/* SUBGROUP SKILLS GRID */}
										{group.skillSubGroups &&
											group.skillSubGroups?.length > 0 &&
											group.skillSubGroups.map(sub => {
												/** don't render hidden sub group */
												if (formData[areaId][group.id][sub.id] === undefined) return null;

												return (
													<GroupCard
														key={`${group.id}_${sub.id}_skill_group`}
														name={`${group.id}_${sub.id}_skill_group`}
														classes={classes}>
														<Grid
															container
															direction="row"
															alignItems="flex-end"
															alignContent="center"
															spacing={4}>
															{/* GROUP SKILLS GRID */}
															{group.name === 'LANGUAGES' ? (
																<LanguageSkillCardHeader
																	classes={classes}
																	subgroup={sub}
																	isMobile={isMobile}
																	isTablet={isTablet}
																/>
															) : (
																<NormalSkillCardHeader
																	classes={classes}
																	subgroup={sub}
																	isMobile={isMobile}
																	isTablet={isTablet}
																/>
															)}
															{sub.skills &&
																sub.skills?.length > 0 &&
																sub.skills.map(skill => {
																	if (group.name === 'LANGUAGES')
																		return languagesGroup(
																			classes,
																			formData,
																			areaId,
																			updateFormTree,
																			group,
																			sub,
																			skill,
																			false,
																			isMobile,
																			isTablet
																		);

																	return normalGroup(
																		classes,
																		formData,
																		areaId,
																		updateFormTree,
																		group,
																		sub,
																		skill,
																		false,
																		isMobile,
																		isTablet
																	);
																})}
														</Grid>
													</GroupCard>
												);
											})}
									</Grid>
								</TabPanel>
							);
						})}
					</Grid>
				) : (
					<Grid item {...sizes[12]} className={classes.rows}>
						<NoData />
					</Grid>
				)}
			</Grid>

			{/* ADD SKILLS MODAL */}
			<Dialog
				fullScreen
				title={areaName as string}
				subTitle="Choose from the skills available to add to your skills"
				modal={{
					open: openModalAddSkill,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setOpenModalAddSkill(false);
							setModalSelectedState({});
						}
					},
					content: addSkillsContent
				}}
				actions={[
					{
						id: 'addSkills',
						label: 'Add Skills',
						color: 'primary',
						variant: 'contained',
						onClick: handleAddNewSkillsGroups
					}
				]}
			/>
			{/* REMOVE SKILLS MODAL */}
			<Dialog
				fullScreen
				title={areaName as string}
				subTitle="Choose the skills YOU WANT TO REMOVE FROM your skills"
				modal={{
					open: openModalRemoveSkill,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setOpenModalRemoveSkill(false);
							setModalSelectedState({});
						}
					},
					content: removeSkillsContent
				}}
				actions={[
					{
						id: 'removeSkills',
						label: 'Remove Skills',
						color: 'primary',
						variant: 'contained',
						onClick: handleRemoveNewSkillsGroups
					}
				]}
			/>

			{/* FOOTER ACTIONS */}
			<ActionFooter actions={footerActions} />
			<Prompt when={!ableToSubmit} message="Are you sure you want to leave without saving?" />
		</>
	);
};

export default withLayout(MySkills);
