import * as yup from 'yup';

import { dateDefault, dateRequired, string, stringRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	contractStartDate: dateRequired,
	contractType: stringRequired,
	contractPosition: stringRequired,
	customerInContract: string.when('contractType', {
		is: (value: string) =>
			// 20 - fixed term
			value === '20',
		then: stringRequired,
		otherwise: string
	}),
	traineeType: string.when('contractType', {
		is: (value: string) =>
			// 80 - trainee
			value === '80',
		then: stringRequired,
		otherwise: string
	}),
	contractEndDate: dateDefault.when('contractType', {
		is: (value: string) =>
			// 20 - fixed term
			// 80 - trainee
			value === '20' || value === '80',
		then: dateRequired,
		otherwise: dateDefault
	}),
	probationPeriodEndDate: dateDefault.when('contractType', {
		is: (value: string) =>
			// 20 - fixed term
			// 80 - trainee
			value === '10',
		then: dateRequired,
		otherwise: dateDefault
	}),
	daysProbationPeriod: string.when('contractType', {
		is: (value: string) =>
			// 20 - fixed term
			// 80 - trainee
			value === '10',
		then: stringRequired,
		otherwise: string
	})
});

export const defaultValues = {
	contractStartDate: '',
	contractType: '',
	contractPosition: '',
	customerInContract: '',
	traineeType: '',
	contractEndDate: '',
	probationPeriodEndDate: '',
	daysProbationPeriod: '180'
};
