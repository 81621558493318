/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Button } from '@mui/material';
import { Publish, Check } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { InputLabel, Select, Input } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { IAdmissionSheetTabs } from '../../AdmissionSheet';
import { useStyles } from './styles';

//* COMPONENT INTERFACES
interface IBanking extends IAdmissionSheetTabs {
	showUploadIbanProofFile: boolean;
	showIBANDocError: boolean;
	selectedFilesFormData: FormData;
	uploadIBANProof: () => void;
	getFileName: (fileType: string) => string;
}

//* COMPONENT
const Banking: FC<IBanking> = ({
	control,
	errors,
	AdmissionSheetDataOptions,
	FileUploadOptions,
	// this tab
	showUploadIbanProofFile,
	showIBANDocError,
	selectedFilesFormData,
	uploadIBANProof,
	getFileName,
	formFields,
	isEmployee
}) => {
	const classes = useStyles();
	return (
		<Grid container spacing={4} className={classes.root}>
			<Grid item {...sizes[6]}>
				{formFields.formFieldsConfig.bankId && (
					<Select
						name="bankId"
						label="Bank"
						options={AdmissionSheetDataOptions.banks}
						control={control}
						errors={errors}
						required
					/>
				)}
				{showUploadIbanProofFile && formFields.formFieldsConfig.hasBankDoc && (
					<Button
						color="primary"
						className={classes.attachmentButton}
						startIcon={!selectedFilesFormData.get(FileUploadOptions.IBAN) ? <Publish /> : <Check />}
						variant="outlined"
						onClick={uploadIBANProof}>
						{isEmployee ? 'IBAN Proof *' : 'IBAN Proof'}
					</Button>
				)}
				{!!selectedFilesFormData.get(FileUploadOptions.IBAN) && formFields.formFieldsConfig.hasBankDoc && (
					<InputLabel className={classes.buttonInputLabel}>{getFileName(FileUploadOptions.IBAN)}</InputLabel>
				)}
				{showIBANDocError &&
					!selectedFilesFormData.get(FileUploadOptions.IBAN) &&
					formFields.formFieldsConfig.hasBankDoc && (
						<InputLabel className={classes.errorInputLabel}>Please add a valid IBAN document</InputLabel>
					)}
			</Grid>
			{formFields.formFieldsConfig.bankNumber && (
				<Grid item {...sizes[6]}>
					<Input name="bankNib" label="IBAN" required={isEmployee} control={control} errors={errors} />
				</Grid>
			)}
		</Grid>
	);
};

export default Banking;
