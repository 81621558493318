//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, FormControl, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { RadioButtonUnchecked, CheckCircle, List, Publish } from '@mui/icons-material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, PageTitle, Dialog, DualTransferList } from 'xpand-ui/core';
import { Select, InputLabel } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { IEmployeeInfo, IExportCSCsList } from 'typings/store/admin/employeeSalaryCategories';
import { ITableAction, ITableColumn, ITableColumnsFilter } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]
import {
	BALWURK_EMPLOYEE_ID,
	START_API_PATH,
	XPAND_EMPLOYEE_ID,
	XPAND_EMPLOYEE_ID_SE,
	XPAND_EMPLOYEE_ID_DE,
	XPAND_EMPLOYEE_ID_UK,
	XPAND_EMPLOYEE_ID_HR
} from 'lib/utils/constants';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
// *
import { allStatus } from 'pages/Admin/_ProposalsFormUtils/utils';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { EmployeesListProps } from '.';
import { addNotification } from 'lib/utils/notifications';
import { IChoosableBaseInfo } from 'typings/store/generalTypes';

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Employee Salary Categories - XPECP.png';
import { getLSField } from 'lib/utils/cookies';
import { useForm } from 'react-hook-form';
import { schema } from './yupSchema';
import { yupResolver } from '@hookform/resolvers/yup';

//* COMPONENT INTERFACES
interface IEmployeesList extends EmployeesListProps {
	goToPage: (path: string) => void;
}

let firstLoad = true;

//* COMPONENT
const EmployeesList: FC<IEmployeesList> = ({
	employeeSalary,
	filters,
	goToPage,
	getEmployeesList,
	clearCscError,
	exportMultipleCSCs,
	setCSCPageFilter
}) => {
	const classes = useStyles();
	const { loading, employeesList, error } = employeeSalary;
	const { cscFilter, cscStatusFilter, cscCompanyFilter } = filters;

	const [searchValue, setSearchValue] = useState(cscFilter);
	const [companySelected, setCompanySelected] = useState(cscCompanyFilter);
	const [statusFilter, setStatusFilter] = useState(cscStatusFilter);

	// useForm only used form Multiple Select Company
	const {
		control,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema)
	});

	// Update filters whenever necessary
	useEffect(() => {
		setCSCPageFilter({
			searchValue,
			statusFilter,
			companySelected
		});
	}, [searchValue, statusFilter, companySelected]);

	// 0: true, // NEW
	// 1: true,
	// 3: true, // CANCELED
	// 6: true, // CLOSED
	// 7: true,
	// 8: true,
	// 9: true // NONE

	const [exportMultipleCSCsModal, setExportMultipleCSCsModal] = useState<boolean>(false);
	const [selectedCSCs, setSelectedCSCs] = useState<any[]>([] as any[]);
	const [exportCSCsList, setExportCSCsList] = useState<any[]>([]);
	const [exportMethod, setExportMethod] = useState(0);
	const [showWarning, setShowWarning] = useState(false);

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	useEffect(() => {
		if (!employeesList && !error) {
			getEmployeesList();
		}
	}, [employeesList, companySelected, cscCompanyFilter]);

	useEffect(() => {
		if (employeesList && companySelected && companySelected !== '') {
			if (!firstLoad) {
				getEmployeesList(companySelected);
			} else {
				firstLoad = false;
			}
		}
	}, [companySelected, cscCompanyFilter]);

	useEffect(() => {
		if (companySelected) {
			getEmployeesList(companySelected);
		}
	}, []);

	// PROPOSALS STATUS HELPER
	const statusHelper = useMemo(() => allStatus(classes), []);

	// TABLE CUSTOM FILTER STATUS CHANGER
	const handleStatusFilterPropsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStatusFilter({ ...statusFilter, [event.target.name]: event.target.checked });
	};

	//Organizes the status filter with the respective order:
	const customFilters = [
		{ name: 'none', label: 'NONE', id: 9 },
		{ name: 'new', label: 'NEW', id: 0 },
		{ name: 'awaiting', label: 'AWAITING APPROVAL', id: 7 },
		{ name: 'approved', label: 'APPROVED', id: 1 },
		{ name: 'rejected', label: 'REJECTED', id: 8 }
	];

	// TABLE CUSTOM FILTER CONTENT
	const statusFilterComponent = () => (
		<FormControl component="fieldset" style={{ padding: '25px' }}>
			<FormGroup>
				{customFilters.map((e, i) => {
					if (
						e.name === 'none' ||
						e.name === 'new' ||
						e.name === 'awaiting' ||
						e.name === 'approved' ||
						e.name === 'rejected'
					) {
						return (
							<FormControlLabel
								key={e.name}
								control={
									<Checkbox
										color="primary"
										icon={<RadioButtonUnchecked />}
										checkedIcon={<CheckCircle />}
										checked={statusFilter[e.id]}
										onChange={handleStatusFilterPropsChange}
										name={`${e.id}`}
									/>
								}
								label={e.label}
							/>
						);
					}

					return '';
				})}
			</FormGroup>
		</FormControl>
	);

	// TABLE ROW ACTIONS
	const tableActions: ITableAction<IEmployeeInfo>[] = [
		{
			id: 'someAction1',
			type: 'button',
			icon: <List />,
			render: () => true,
			disabled: () => (getLSField('impersonate_userInfo') ? true : false),
			to: row => `/cp/admin/salariesAndContracts/employeeSalaryCategories/details/${row.hash}`,
			onClick: row => goToPage(`/admin/salariesAndContracts/employeeSalaryCategories/details/${row.hash}`)
		}
	];

	// TABLE CUSTOM FILTER
	const tableColumnsFilter: ITableColumnsFilter = useMemo(
		() => ({
			lastCscInProgressStatus: {
				anyToFilter: Object.values(statusFilter).includes(true),
				filterComponent: statusFilterComponent(),
				handleFilter: info => {
					const payloadToSearch = Object.keys(statusFilter)
						.filter(field => statusFilter[field])
						.map(e => +e)
						.map(e => (e === 9 ? 99 : e));

					return info.filter(item => payloadToSearch.includes(item.lastCscInProgressStatus));
				}
			}
		}),
		[statusFilter, employeesList]
	);

	//Return Job Level with first letter with uppercase
	const customJobLevel = (jobLevel: string, careerSubLevel: string): string => {
		const jobLevelName = jobLevel.split(' ');
		return (
			jobLevelName[0].charAt(0).toUpperCase() + jobLevelName[0].slice(1).toLowerCase() + ' - ' + careerSubLevel
		);
	};

	//Return Job Role with first letter with uppercase
	const customJobRole = (jobRole: string): string => {
		const jobRoleName = jobRole.split(' ');
		let finalName = '';
		for (let subName of jobRoleName) {
			//Some initials most be uppercases
			if (
				subName.toUpperCase() === 'UX' ||
				subName.toUpperCase() === 'IT' ||
				subName.toUpperCase() === 'PMO' ||
				subName.toUpperCase() === 'UI' ||
				subName.toUpperCase() === 'CRM'
			)
				finalName += subName.toUpperCase() + ' ';
			else finalName += subName.charAt(0).toUpperCase() + subName.slice(1).toLowerCase() + ' ';
		}
		return finalName;
	};

	// TABLE DATA PARSED
	const tableData = useMemo(
		() =>
			employeesList?.employees
				?.filter(
					e =>
						e.businessPartnerCategory === XPAND_EMPLOYEE_ID ||
						e.businessPartnerCategory === XPAND_EMPLOYEE_ID_HR ||
						e.businessPartnerCategory === XPAND_EMPLOYEE_ID_UK ||
						e.businessPartnerCategory === XPAND_EMPLOYEE_ID_SE ||
						e.businessPartnerCategory === XPAND_EMPLOYEE_ID_DE ||
						e.businessPartnerCategory === BALWURK_EMPLOYEE_ID
				)
				.map(e => ({
					...e,
					jobRole: e?.careerLevel?.xphrProfileEligibleRoleData?.name
						? customJobRole(e.careerLevel.xphrProfileEligibleRoleData.name)
						: '',
					jobLevel:
						e?.careerLevel?.xphrProfileRoleLevelData?.name &&
						e?.careerLevel?.xphrProfileRoleLevelData?.careerSubLevel
							? customJobLevel(
									e.careerLevel.xphrProfileRoleLevelData.name,
									e.careerLevel.xphrProfileRoleLevelData.careerSubLevel
							  )
							: '',
					status: statusHelper[e.lastCscInProgressStatus === 99 ? 9 : e.lastCscInProgressStatus].label,
					company: employeesList?.companies
						?.map(company => {
							return e?.userCompany === company.searchKey ? company.name : '';
						})
						.filter(company => company !== null)
				})) || [],
		[employeesList]
	);
	console.log('employeesList');
	console.log(employeesList);
	// TABLE COLUMNS
	const tableColumns: ITableColumn<IEmployeeInfo>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				format: (row: IEmployeeInfo) => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${row.searchKey}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{
				label: 'User',
				id: 'searchKey',
				accentColumn: true,
				align: 'left',
				width: '2%',
				format: (row, text: string) => (text ? text.toUpperCase() : '')
			},
			{
				label: 'Name',
				id: 'name',
				accentColumn: true,
				align: 'left',
				width: '11%'
			},
			{
				label: 'Company',
				id: 'company',
				align: 'left',
				width: '10%'
			},
			{
				label: 'Business Unit',
				id: 'xPHRAdOrgBunit$_identifier',
				align: 'left',
				width: '11%'
			},
			{
				label: 'Department',
				id: 'xphrAdOrgDepartment$_identifier',
				align: 'left',
				width: '11%'
			},
			{
				label: 'Job Role',
				id: 'jobRole',
				align: 'left',
				width: '11%'
			},
			{
				label: 'Job Role Level',
				id: 'jobLevel',
				align: 'left',
				width: '13%'
			},
			{
				label: 'Status',
				id: 'lastCscInProgressStatus',
				width: '10%',
				format: ({ lastCscInProgressStatus }: IEmployeeInfo) => {
					if (lastCscInProgressStatus === 99)
						return (
							<div className={classes.statusBar}>
								{statusHelper[9].icon} {statusHelper[9].label}
							</div>
						);
					if (statusHelper[lastCscInProgressStatus])
						return (
							<div className={classes.statusBar}>
								{statusHelper[lastCscInProgressStatus].icon}{' '}
								{statusHelper[lastCscInProgressStatus].label}
							</div>
						);

					return (
						<div className={classes.statusBar}>
							{statusHelper[9].icon} {lastCscInProgressStatus}
						</div>
					);
				}
			}
		],
		[employeesList]
	);

	const handleSubmit = () => {
		const payload: IExportCSCsList = {
			userIDs: selectedCSCs.map(e => e.id),
			usernames: selectedCSCs.map(e => e.searchKey),
			exportMethod: exportMethod
		};
		exportMultipleCSCs(payload);
		setExportMultipleCSCsModal(false);
	};

	const pageTitleActions = useMemo(
		() => [
			{
				id: 'exportMultipleCSCs',
				type: 'file',
				onClick: () => {
					setExportMultipleCSCsModal(true);
					setExportMethod(0);
					setSelectedCSCs([]);
				},
				icon: <Publish />,
				label: "Export Multiple CSC's"
			}
		],
		[]
	);

	const isImpersonate = () => {
		if (getLSField('impersonate_userInfo')) {
			return true;
		} else {
			return false;
		}
	};

	const exportMultipleCSCsActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setExportMultipleCSCsModal(false)
		},
		{
			id: 'submit',
			label: 'Export',
			color: 'primary',
			type: 'submit',
			form: 'form-export-multiple-cscs',
			variant: 'contained',
			disabled: selectedCSCs?.length === 0 || isImpersonate(),
			onClick: () => handleSubmit()
		}
	];

	/** handle dual transfer list component changes */
	const handleListChanges = (l: any[], r: any[]) => setSelectedCSCs(r);

	useEffect(() => {
		let amount = 0;
		tableData?.map(e => (e.lastCscInProgressStatus === 0 || e.lastCscInProgressStatus === 7 ? amount++ : null));
		if (amount > 0) {
			setShowWarning(true);
		} else {
			setShowWarning(false);
		}
	}, [tableData]);

	const exportMethodList: IChoosableBaseInfo[] = [
		{
			id: 0,
			label: "Multiple CSC's as ZIP"
		},
		{
			id: 1,
			label: "Multiple CSC's in Excel"
		}
	];

	if (error) return handleErrorPage(error, clearCscError);

	const isLoading = employeesList === null;
	if (isLoading) return <LoadingOverlay />;
	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle actions={pageTitleActions} />
			<Grid container spacing={3}>
				{showWarning === true && (
					<Grid item {...sizes[12]} className={classes.headerRows}>
						<Typography className={classes.stateTitle}>
							<span style={{ alignContent: 'center', backgroundColor: '#FFF8E5' }}>
								<PriorityHighIcon color="warning" style={{ verticalAlign: 'middle' }} />
								<span style={{ paddingTop: '2px' }}>
									Attention: You have pending actions on CSC's. Please check them{' '}
									<span
										className={classes.hereButton}
										onClick={() => {
											setStatusFilter({
												0: true,
												1: false,
												3: false,
												6: false,
												7: true,
												8: false,
												9: false
											});
											setShowWarning(false);
										}}>
										here{' '}
									</span>
								</span>
							</span>
						</Typography>
					</Grid>
				)}
				<Grid item {...sizes[4]}>
					<div className={classes.companyContainer}>
						<InputLabel className={classes.companyLabel}>Company</InputLabel>{' '}
						<Select
							multiple
							name="company"
							label=""
							defaultValue={companySelected}
							onChange={setCompanySelected}
							options={employeesList?.companies?.map(e => ({ id: e.searchKey, label: e.name }))}
							control={control}
							errors={errors}
						/>
					</div>
				</Grid>
			</Grid>
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				tableColumnsFilter={tableColumnsFilter}
				defaultSortColumn="searchKey"
				handleSearch={{ searchValue, setSearchValue }}
				handleDiagramModal={{ diagramModal, setDiagramModal }}
			/>
			<Dialog
				modal={{
					open: Boolean(exportMultipleCSCsModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') {
							setExportMultipleCSCsModal(false);
						}
					},
					content: (
						<Grid>
							<Grid item {...sizes[4]}>
								<DualTransferList
									title="CSC's"
									leftList={employeesList?.employees}
									rightList={[]}
									payloadId="id"
									payloadLabel="name"
									handleChanges={handleListChanges}
								/>
							</Grid>
							<Grid item {...sizes[4]} style={{ maxWidth: '200px', marginLeft: '110px' }}>
								<InputLabel className={classes.exportMethodLabel}>Export Method:</InputLabel>{' '}
								<Select
									name="exportMethod"
									label=""
									defaultValue={exportMethod}
									onChange={setExportMethod}
									options={exportMethodList}
								/>
							</Grid>
						</Grid>
					)
				}}
				title="Export Multiple CSC's"
				maxWidth="lg"
				actions={exportMultipleCSCsActions}
				scroll="body"
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(EmployeesList);
