/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, ReactNode, useMemo, Fragment } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, GridDirection, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { GroupCollapse } from 'xpand-ui/core';
import { InputLabel, DatePicker, Select, Input } from 'xpand-ui/forms';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { parseContactToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { IPersonalInfo, ISkill, ISkillGroup, ISkillSubGroup } from 'typings/store/personalInfoTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { getCookie, setCookie } from 'lib/utils/cookies';

//* LOCAL COMPONENT IMPORTS
import {
	GroupCard as SkillsGroupCard,
	NormalSkillCardHeader,
	normalGroup,
	LanguageSkillCardHeader,
	languagesGroup
} from '../Skills/utils';

/* eslint-disable react/prop-types */
export const tabs = [
	{
		id: 'personalInfo',
		label: 'Personal Information',
		infoMessage: (companyName: string): ReactNode => (
			<>
				{`We ask you to please fill in with your Personal Information. In "Description" input please
					enter a short text about your experience so far. Note that the "Address" will be ${companyName}
					office location and the same goes for "Mobile Phone".`}
			</>
		)
	},
	{
		id: 'education',
		label: 'Education',
		infoMessage: (): ReactNode => <>We will need some information about your education.</>,
		emptyObject: {
			certificationAwarded: '',
			classification: '',
			endDate: '',
			mainSubjects: '',
			major: '',
			minor: '',
			organizationName: '',
			startDate: ''
		}
	},
	{
		id: 'workExperience',
		label: 'Work Experience',
		infoMessage: (): ReactNode => (
			<>
				This section is for your work experience, please fill in if you have any kind of work experience and all
				the details about it.
			</>
		),
		emptyObject: {
			startDate: '',
			endDate: '',
			businessSector: '',
			occupation: '',
			mainActivities: ''
		}
	},
	{
		id: 'workshops',
		label: 'Workshops',
		infoMessage: (): ReactNode => (
			<>
				Here you should enter information about all of the Workshops or even Seminars that you have taken or
				attended.
			</>
		),
		emptyObject: {
			date: '',
			location: '',
			subject: ''
		}
	},
	{
		id: 'otherProjects',
		label: 'Other Projects',
		infoMessage: (): ReactNode => (
			<>If you find important, please let us know any other projects that you feel like sharing.</>
		),
		emptyObject: {
			mainActivities: '',
			name: ''
		}
	},
	{
		id: 'training',
		label: 'Training',
		infoMessage: (): ReactNode => (
			<>This area is for you to fill in with every other Training or Certification that you have/had.</>
		),
		emptyObject: {
			startDate: '',
			endDate: '',
			organizationName: '',
			mainSubjects: '',
			certificationAwarded: '',
			level: ''
		}
	},
	{
		id: 'skills',
		label: 'Skills',
		infoMessage: ({ infoLabel }: any): ReactNode => (
			<>
				<Typography component="span" className={infoLabel}>
					Here you can check all of the skills that you have submitted in &#34;My Skills&#34; page. If
					anything is missing or if you want to edit it, please click{' '}
					<a className={infoLabel} style={{ display: 'contents' }} href="skills/soft">
						here
					</a>
					, which will lead you to &#34;My Skills&#34; page.
				</Typography>
			</>
		)
	}
];

/** Components struct */
export const GroupCard = (props: any) => {
	const { classes } = props;
	return (
		<Grid xs={12} sm={12} md={12} lg={10} xl={9} {...props} classes={{}} className={classes.groupCardResume} item />
	);
};

const getDefaultValueCountry = (payload: IPersonalInfo) => {
	if (payload.nativeLanguage === 'PORTUGUESE') return 'PORTUGUESE (PORTUGAL)';
	if (payload.nativeLanguage === 'FRENCH') return 'FRENCH (FRANCE)';
	if (payload.nativeLanguage === 'SPANISH') return 'SPANISH (SPAIN)';

	return null;
};

export const PersonalInfoCard: FC<any> = ({ classes, payload, selectOptions, control, errors, setValue, index }) => {
	const name = `personalInfo.${index}`;
	const defaultValue = getDefaultValueCountry(payload);
	return (
		<GroupCard classes={classes}>
			<Grid container spacing={4}>
				{/* ADDRESS */}
				<Grid item {...sizes[12]}>
					<InputLabel>Address</InputLabel>
					<Typography variant="body1" className={classes.nonEditableField} gutterBottom>
						{payload.address}
					</Typography>
				</Grid>
				{/* GENDER */}
				<Grid item {...sizes[4]}>
					<InputLabel>Gender</InputLabel>
					<Typography variant="body1" className={classes.nonEditableField} gutterBottom>
						{(payload.gender === 'M' && 'Male') || (payload.gender === 'F' && 'Female') || payload.gender}
					</Typography>
				</Grid>
				{/* MOBILE PHONE */}
				<Grid item {...sizes[4]}>
					<InputLabel>Mobile Phone</InputLabel>
					<Typography variant="body1" className={classes.nonEditableField} gutterBottom>
						{parseContactToShow(payload.phone)}
					</Typography>
				</Grid>
				{/* empty space */}
				<Grid item {...sizes[4]} />
				{/* NATIVE LANGUAGE */}
				<Grid item {...sizes[4]}>
					{/* {payload.nativeLanguage} */}
					<Select
						label="Country of Origin"
						name={`${name}.countryOrigin`}
						control={control}
						defaultValue={defaultValue}
						additionalOnChange={(item: { value: string }) => {
							const selectOption = selectOptions.countries.find((e: any) => e.description === item.value);
							if (selectOption) {
								setValue(`${name}.nativeLanguage`, selectOption.name);
								setValue(`${name}.countryCode`, selectOption.iSOCountryCode);
							}
						}}
						errors={errors}
						options={selectOptions.countries.map((e: any) => ({
							id: e.description,
							label: e.description
						}))}
					/>
				</Grid>
				{/* COUNTRY ORIGIN */}
				<Grid item {...sizes[4]}>
					<Input
						label="Native Language"
						required
						name={`${name}.nativeLanguage`}
						control={control}
						errors={errors}
					/>
				</Grid>
				{/* COUNTRY CODE */}
				<Grid item {...sizes[4]}>
					<Input
						label="Country Code"
						required
						name={`${name}.countryCode`}
						control={control}
						errors={errors}
					/>
				</Grid>
				{/* BIRTHDAY */}
				<Grid item {...sizes[4]}>
					<InputLabel>BirthDay</InputLabel>
					<Typography variant="body1" className={classes.nonEditableField} gutterBottom>
						{parseDateToShow(payload.birthday)}
					</Typography>
				</Grid>
				{/* BIRTHPLACE */}
				<Grid item {...sizes[4]}>
					<Input label="Birthplace" required name={`${name}.birthplace`} control={control} errors={errors} />
				</Grid>
				{/* DESCRIPTION */}
				<Grid item {...sizes[12]}>
					<Input
						required
						label="Description"
						name={`${name}.description`}
						multiline
						minRows={5}
						maxRows={8}
						control={control}
						errors={errors}
					/>
				</Grid>
			</Grid>
		</GroupCard>
	);
};

const manageState = (operation: 1 | 2, name: string, newOpenState?: boolean): boolean => {
	const resumeOpenState = getCookie('resumeOpenState');
	const resumeState: { [name: string]: boolean } = resumeOpenState ? JSON.parse(resumeOpenState) : {};

	if (operation === 1) return resumeState[name] || false;

	setCookie('resumeOpenState', JSON.stringify({ ...resumeState, [name]: newOpenState }));
	return false;
};

export const EducationInfoCard: FC<any> = ({
	classes,
	payload,
	control,
	watch,
	handleRemove,
	errors,
	selectOptions,
	index
}) => {
	const name = `education.${index}`;

	const groupTitle =
		payload.certificationAwarded && payload.certificationAwarded !== ''
			? payload.certificationAwarded
			: `Education ${index + 1}`;

	const groupLabel = watch(`${name}.certificationAwarded`) || groupTitle;

	return (
		<GroupCard classes={classes}>
			<GroupCollapse
				id={name}
				maxWidth="100%"
				showContent={manageState(1, `education.${payload.id}`)}
				handleToggle={(state: boolean) => manageState(2, `education.${payload.id}`, state)}
				label={
					<>
						{groupLabel} {payload.saved && <Check style={{ margin: '0 0 5px 10px' }} color="primary" />}
					</>
				}
				actions={handleRemove}
				customClasses={{ items: classes.items }}
				component={
					<Grid container spacing={4} style={{ padding: '25px 25px 0 25px' }}>
						{/* NATIVE LANGUAGE */}
						<Grid item {...sizes[6]}>
							<Select
								required
								name={`${name}.classification`}
								label="Level in Nation Classification"
								control={control}
								errors={errors}
								options={selectOptions.classification.map((e: any) => ({ id: e.id, label: e.name }))}
							/>
						</Grid>
						<Grid item {...sizes[6]} />
						<Grid item {...sizes[6]}>
							<DatePicker
								required
								disableFuture
								label="Start Date"
								name={`${name}.startDate`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<DatePicker
								label="End Date"
								placeholder="ONGOING"
								name={`${name}.endDate`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<Input
								required
								label="Name and Type of organization"
								name={`${name}.organizationName`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<Input
								required
								label="Main Subjects"
								name={`${name}.mainSubjects`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<Input
								required
								label="Minor degree"
								name={`${name}.minor`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<Input
								required
								label="Major degree"
								name={`${name}.major`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[12]}>
							<Input
								required
								label="Name of certification awarded"
								name={`${name}.certificationAwarded`}
								control={control}
								errors={errors}
							/>
						</Grid>
					</Grid>
				}
			/>
		</GroupCard>
	);
};

export const WorkExperienceInfoCard: FC<any> = ({ classes, payload, control, watch, handleRemove, errors, index }) => {
	const name = `workExperience.${index}`;

	const groupTitle =
		payload.occupation && payload.occupation !== '' ? payload.occupation : `Work Experience ${index + 1}`;

	const groupLabel = watch(`${name}.occupation`) || groupTitle;

	return (
		<GroupCard classes={classes}>
			<GroupCollapse
				id={name}
				maxWidth="100%"
				label={
					<>
						{groupLabel} {payload.saved && <Check style={{ margin: '0 0 5px 10px' }} color="primary" />}
					</>
				}
				showContent={manageState(1, `workExperience.${payload.id}`)}
				handleToggle={(state: boolean) => manageState(2, `workExperience.${payload.id}`, state)}
				actions={handleRemove}
				customClasses={{ items: classes.items }}
				component={
					<Grid container spacing={4} style={{ padding: '25px 25px 0 25px' }}>
						<Grid item {...sizes[6]}>
							<DatePicker
								required
								disableFuture
								label="Start Date"
								name={`${name}.startDate`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<DatePicker
								label="End Date"
								placeholder="ONGOING"
								name={`${name}.endDate`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<Input
								required
								label="Type of Business Sector"
								name={`${name}.businessSector`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]} />
						<Grid item {...sizes[6]}>
							<Input
								required
								label="Occupation or Position Held"
								name={`${name}.occupation`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]} />
						<Grid item {...sizes[12]}>
							<Input
								required
								label="Main Activities, Responsibilities and Technologies used"
								name={`${name}.mainActivities`}
								multiline
								minRows={5}
								maxRows={8}
								control={control}
								errors={errors}
							/>
						</Grid>
					</Grid>
				}
			/>
		</GroupCard>
	);
};

export const WorkshopsInfoCard: FC<any> = ({ classes, payload, control, watch, handleRemove, errors, index }) => {
	const name = `workshops.${index}`;

	const groupTitle = payload.title && payload.title !== '' ? payload.title : `Workshop ${index + 1}`;

	const groupLabel = watch(`${name}.title`) || groupTitle;

	return (
		<GroupCard classes={classes}>
			<GroupCollapse
				id={name}
				maxWidth="100%"
				label={
					<>
						{groupLabel} {payload.saved && <Check style={{ margin: '0 0 5px 10px' }} color="primary" />}
					</>
				}
				showContent={manageState(1, `workshops.${payload.id}`)}
				handleToggle={(state: boolean) => manageState(2, `workshops.${payload.id}`, state)}
				actions={handleRemove}
				customClasses={{ items: classes.items }}
				component={
					<Grid container spacing={4} style={{ padding: '25px 25px 0 25px' }}>
						<Grid item {...sizes[12]}>
							<Input required label="Title" name={`${name}.title`} control={control} errors={errors} />
						</Grid>
						<Grid item {...sizes[6]}>
							<DatePicker
								required
								disableFuture
								label="Date"
								name={`${name}.date`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<Input
								required
								label="Type and Location"
								name={`${name}.location`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[12]}>
							<Input
								required
								label="Subject"
								name={`${name}.subject`}
								multiline
								minRows={5}
								maxRows={8}
								control={control}
								errors={errors}
							/>
						</Grid>
					</Grid>
				}
			/>
		</GroupCard>
	);
};

export const OtherProjectsInfoCard: FC<any> = ({ classes, payload, control, watch, handleRemove, errors, index }) => {
	const name = `otherProjects.${index}`;
	const groupTitle = payload.name && payload.name !== '' ? payload.name : `Project ${index + 1}`;

	const groupLabel = watch(`${name}.name`) || groupTitle;

	return (
		<GroupCard classes={classes}>
			<GroupCollapse
				id={name}
				maxWidth="100%"
				label={
					<>
						{groupLabel} {payload.saved && <Check style={{ margin: '0 0 5px 10px' }} color="primary" />}
					</>
				}
				showContent={manageState(1, `otherProjects.${payload.id}`)}
				handleToggle={(state: boolean) => manageState(2, `otherProjects.${payload.id}`, state)}
				actions={handleRemove}
				customClasses={{ items: classes.items }}
				component={
					<Grid container spacing={4} style={{ padding: '25px 25px 0 25px' }}>
						<Grid item {...sizes[6]}>
							<Input
								required
								label="Project Name"
								name={`${name}.name`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]} />
						<Grid item {...sizes[12]}>
							<Input
								required
								label="Main activities and responsibilities"
								name={`${name}.mainActivities`}
								multiline
								minRows={5}
								maxRows={8}
								control={control}
								errors={errors}
							/>
						</Grid>
					</Grid>
				}
			/>
		</GroupCard>
	);
};

export const TrainingInfoCard: FC<any> = ({ classes, payload, control, watch, handleRemove, errors, index }) => {
	const name = `training.${index}`;
	const groupTitle = payload.title && payload.title !== '' ? payload.title : `Training ${index + 1}`;

	const groupLabel = watch(`${name}.title`) || groupTitle;

	return (
		<GroupCard classes={classes}>
			<GroupCollapse
				id={name}
				maxWidth="100%"
				label={
					<>
						{groupLabel} {payload.saved && <Check style={{ margin: '0 0 5px 10px' }} color="primary" />}
					</>
				}
				showContent={manageState(1, `training.${payload.id}`)}
				handleToggle={(state: boolean) => manageState(2, `training.${payload.id}`, state)}
				actions={handleRemove}
				customClasses={{ items: classes.items }}
				component={
					<Grid container spacing={4} style={{ padding: '25px 25px 0 25px' }}>
						<Grid item {...sizes[12]}>
							<Input required label="Title" name={`${name}.title`} control={control} errors={errors} />
						</Grid>
						<Grid item {...sizes[6]}>
							<DatePicker
								required
								disableFuture
								label="Start Date"
								name={`${name}.startDate`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<DatePicker
								label="End Date"
								placeholder="ONGOING"
								name={`${name}.endDate`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[12]}>
							<Input
								required
								label="Name and Type of organization"
								name={`${name}.organizationName`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[12]}>
							<Input
								required
								label="MAIN SUBJECTS AND OCCUPATIONS SKILLS COVERED"
								name={`${name}.mainSubjects`}
								multiline
								minRows={5}
								maxRows={8}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<Input
								required
								label="NAME OF CERTIFICATION / TRAINING AWARDED"
								name={`${name}.certificationAwarded`}
								control={control}
								errors={errors}
							/>
						</Grid>
						<Grid item {...sizes[6]}>
							<Input
								required
								label="Level Obtained"
								name={`${name}.level`}
								control={control}
								errors={errors}
							/>
						</Grid>
					</Grid>
				}
			/>
		</GroupCard>
	);
};

export const SkillsInfoCard: FC<any> = ({ classes, formData, allSkills }) => {
	const { width } = useWindowDimensions();

	const isMobile = useMemo(() => width <= 800, [width]); // default is 600 mobile
	const isTablet = useMemo(() => width <= 1000, [width]); // default is 900 tablet
	const payload = [];
	for (let skill in allSkills) {
		payload.push({
			areaId: Number(skill),
			skillsRender: allSkills[skill].skillsRender
		});
	}

	const checkSubGroupSkillsValidation = (skillEvalutation: any): boolean => {
		// At least one skill with valid (experienceLevel or knowledgeLevel) values is enough
		let isValidSkillCounter = 0;

		const values = Object.keys(skillEvalutation).map(key => skillEvalutation[key]);
		values.map(skev => {
			if (
				(!!skev.experienceLevel && skev.experienceLevel > 0) ||
				(!!skev.knowledgeLevel && skev.knowledgeLevel > 0)
			) {
				isValidSkillCounter += 1;
			}
			return skev;
		});
		return isValidSkillCounter > 0;
	};

	// Method to check if group section should be created (ate least one subgroup must hava one or more skills with valid levels)
	const checkGroupValidation = (areaId: number, group: ISkillGroup): boolean => {
		const validationResults: boolean[] = [];
		if (group && group.skillSubGroups && group.skillSubGroups?.length > 0) {
			group.skillSubGroups.map(sub => {
				validationResults.push(
					checkSubGroupSkillsValidation(formData[areaId][group.id as number][sub.id as number])
				);
				return sub;
			});
		} else {
			return false;
		}
		return validationResults.includes(true);
	};

	return (
		<>
			{payload.map(e =>
				e.skillsRender.map((group: ISkillGroup) => {
					/** don't render hidden group */
					if (formData[e.areaId][group.id as number] === undefined) return null;
					let directionCard = '';
					if (group.name === 'LANGUAGES') {
						directionCard = 'row';
					} else {
						directionCard = 'column';
					}

					return (
						checkGroupValidation(e.areaId, group) && (
							<Fragment key={`${e.areaId}_${group.id}_skill_area`}>
								<Typography className={classes.skillsGroupTitle}>{group.name}</Typography>
								<Grid
									key={`${group.id}_skill_group`}
									container
									direction={directionCard as GridDirection}
									justifyContent="space-evenly"
									spacing={2}
									className={classes.skillsSubGroupsContainer}
									alignItems="baseline">
									{/* SUBGROUP SKILLS GRID */}
									{group &&
										group.skillSubGroups &&
										group.skillSubGroups?.length > 0 &&
										group.skillSubGroups.map(sub => {
											/** don't render hidden sub group */
											if (
												formData[e.areaId][group.id as number][sub.id as number] ===
													undefined ||
												!checkSubGroupSkillsValidation(
													formData[e.areaId][group.id as number][sub.id as number]
												)
											)
												return null;

											return (
												<SkillsGroupCard
													key={`${group.id}_${sub.id}_skill_sub_group`}
													name={`${group.id}_${sub.id}_skill_sub_group`}
													{...sizes[
														(group?.skillSubGroups as ISkillSubGroup[])?.length > 1 &&
														group.name === 'LANGUAGES'
															? 6
															: 12
													]}
													classes={classes}>
													<Grid
														container
														direction="row"
														alignItems="flex-start"
														className={classes.skillsGroupCard}
														spacing={4}>
														{/* GROUP SKILLS GRID */}
														{group.name === 'LANGUAGES' ? (
															<LanguageSkillCardHeader
																classes={classes}
																subgroup={sub}
																isMobile={isMobile}
																isTablet={isTablet}
															/>
														) : (
															<NormalSkillCardHeader
																classes={classes}
																subgroup={sub}
																isMobile={isMobile}
																isTablet={isTablet}
															/>
														)}
														{(sub.skills as ISkill[])?.length > 0 &&
															(sub.skills as ISkill[]).map(skill => {
																if (group.name === 'LANGUAGES')
																	return languagesGroup(
																		classes,
																		formData,
																		e.areaId,
																		() => ({}),
																		group,
																		sub,
																		skill,
																		true,
																		isMobile,
																		isTablet
																	);

																return normalGroup(
																	classes,
																	formData,
																	e.areaId,
																	() => ({}),
																	group,
																	sub,
																	skill,
																	true,
																	isMobile,
																	isTablet
																);
															})}
													</Grid>
												</SkillsGroupCard>
											);
										})}
								</Grid>
							</Fragment>
						)
					);
				})
			)}
		</>
	);
};
