export const getStatusIdFromString = (newStatus: string): number | null => {
	switch (newStatus) {
		case 'NEW':
			return 0;
		case 'APPROVED':
			return 1;
		case 'SENT':
			return 2;
		case 'CANCELED':
			return 3;
		case 'REFUSED':
			return 4;
		case 'ACCEPTED':
			return 5;
		case 'CLOSED':
			return 6;
		case 'AWAITING APPROVAL':
			return 7;
		case 'REJECTED':
			return 8;
		case 'EDITED':
			return 10;
		case 'EDITED_START_DATE':
			return 11;
		default:
			return null;
	}
};
