// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		name: {
			fontFamily: 'Open Sans SemiBold',
			textTransform: 'uppercase',
			fontSize: '16px'
		}
	})
);

export { useStyles };
