import * as yup from 'yup';
import moment from 'moment';

import { stringRequired, string, numberRequired, dateRequired, booleanRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	projectName: stringRequired,
	projectTask: stringRequired,
	costCenter: stringRequired,
	workType: stringRequired,
	description: string.max(2000),
	hours: numberRequired,
	minutes: numberRequired
});

export const defaultValues = {
	projectName: '',
	projectTask: '',
	costCenter: '',
	workType: '',
	description: '',
	hours: 0,
	minutes: 0
};
