import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AppState } from 'typings/state';

import { getProposalAuditLog, clearProposalAuditLog } from 'store/administration/proposals/thunks';

import ProposalAuditLog from './ProposalAuditLog';

const mapStateToProps = ({ proposals }: AppState) => ({ proposals });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getProposalAuditLog, clearProposalAuditLog }, dispatch);

export type ProposalAuditLogProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ProposalAuditLog);
