import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getUserSkillsEvaluation,
	clearUserSkillsEvaluation,
	clearSkillsAndResumesError
} from 'store/administration/skillsAndResumes/thunks';
import { AppState } from 'typings/state';

import ViewSkillsDetails from './ViewSkillsDetails';

const mapStateToProps = ({ skillsAndResumes }: AppState) => ({ skillsAndResumes });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getUserSkillsEvaluation, clearUserSkillsEvaluation, clearSkillsAndResumesError }, dispatch);

export type ViewSkillsDetailsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ViewSkillsDetails);
