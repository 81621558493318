/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';
import clsx from 'clsx';

//* EXTERNAL LIBS --> MUI
import { Button, Grid } from '@mui/material';
import { Publish, Check } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, Input, InputLabel, DatePicker, Checkbox } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { IAdmissionSheetTabs } from '../../AdmissionSheet';
import { useStyles } from './styles';

//* COMPONENT INTERFACES
interface IIDInfo extends IAdmissionSheetTabs {
	showUploadIdFile: boolean;
	showIdDocError: boolean;
	selectedFilesFormData: FormData;
	getFileName: (fileType: string) => string;
	uploadIDDocs: () => void;
	socialSecurityNumberRequired: boolean;
	setSocialSecurityNumberRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

//* COMPONENT
const IDInfo: FC<IIDInfo> = ({
	watch,
	control,
	errors,
	AdmissionSheetDataOptions,
	FileUploadOptions,
	// this tab
	showUploadIdFile,
	showIdDocError,
	selectedFilesFormData,
	getFileName,
	uploadIDDocs,
	socialSecurityNumberRequired,
	setSocialSecurityNumberRequired,
	formFields,
	isEmployee
}) => {
	const classes = useStyles();

	return (
		<Grid container spacing={4} className={classes.root}>
			{formFields?.formFieldsConfig?.idDocumentType && (
				<Grid item {...sizes[6]}>
					<Select
						name="docType"
						label="ID Document Type"
						options={AdmissionSheetDataOptions.idDocumentTypes}
						additionalOnChange={(item: { value: string }) => {
							setSocialSecurityNumberRequired(item.value === 'CC');
						}}
						control={control}
						errors={errors}
						required
					/>
				</Grid>
			)}
			{formFields?.formFieldsConfig?.fiscalNumber && (
				<Grid item {...sizes[6]}>
					<Input name="fiscalNumber" label="Fiscal Number" required control={control} errors={errors} />
				</Grid>
			)}
			{formFields?.formFieldsConfig?.citizenCardNumber && (
				<Grid item {...sizes[6]}>
					<Input
						name="citizenCardNumber"
						label="ID Document Number"
						required
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields?.formFieldsConfig?.socialSecurityNumber && (
				<Grid item {...sizes[6]}>
					<Input
						name="socialSecurityNumber"
						label="Social Security Number"
						required={socialSecurityNumberRequired && isEmployee}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields?.formFieldsConfig?.pensionFund && (
				<Grid item {...sizes[6]}>
					<Input
						name="pensionFundNumber"
						label="Pension Fund Number"
						required
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{(formFields?.formFieldsConfig?.hasHadWithoutTermContract && isEmployee) ||
				(formFields?.formFieldsConfig?.hasHadWithoutTermContract &&
					formFields?.formFieldsConfig?.country == 'HR' && (
						<Grid
							item
							{...sizes[6]}
							className={clsx(classes.animation, {
								[classes.show]: watch('showContractCheckbox')
							})}>
							<Checkbox
								name="hasHadWithoutTermContract"
								label="Have you ever had a without-term employment contract before?"
								control={control}
								errors={errors}
								required
							/>
						</Grid>
					))}
			<Grid item {...sizes[6]}>
				{formFields?.formFieldsConfig?.citizenCardValidityDate && (
					<DatePicker
						name="citizenCardValidityDate"
						label="ID Document Validity Date"
						control={control}
						errors={errors}
						required
					/>
				)}
				{showUploadIdFile && formFields?.formFieldsConfig?.hasIdDoc && (
					<Button
						color="primary"
						className={classes.attachmentButton}
						startIcon={!selectedFilesFormData.get(FileUploadOptions.IDDOC) ? <Publish /> : <Check />}
						variant="outlined"
						onClick={uploadIDDocs}>
						{isEmployee || formFields?.formFieldsConfig?.country == 'HR' ? 'ID Document *' : 'ID Document'}
					</Button>
				)}
				{!!selectedFilesFormData.get(FileUploadOptions.IDDOC) && formFields?.formFieldsConfig?.hasIdDoc && (
					<InputLabel className={classes.buttonInputLabel}>
						{/* {selectedFilesFormData && selectedFilesFormData.get(FileUploadOptions.IDDOC)?.name} */}
						{getFileName(FileUploadOptions.IDDOC)}
					</InputLabel>
				)}
				{showIdDocError &&
					!selectedFilesFormData.get(FileUploadOptions.IDDOC) &&
					formFields?.formFieldsConfig?.hasIdDoc && (
						<InputLabel className={classes.errorInputLabel}>Please add a valid ID document</InputLabel>
					)}
			</Grid>
		</Grid>
	);
};

export default IDInfo;
