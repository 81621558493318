/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, Input, Checkbox } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { addNotification } from 'lib/utils/notifications';

// import { IProjectTabs } from '../../NewProject';
import { DeepMap, FieldError } from 'react-hook-form';
import { useStyles } from './styles';
import { INewClientTabs } from '../../NewClient';

//* COMPONENT INTERFACES
// interface IProjectTeam extends IProjectTabs {}

const markRequiredValues = (data: any): any => {
	const checkS = (val: string) =>
		Boolean(data[val] !== null && typeof data[val] !== 'undefined' && data[val].trim() !== '');

	const checkN = (val: string) =>
		data[val] !== null && typeof data[val] !== 'undefined' && (Number(data[val]) === 0 ? 0 : Number(data[val]));

	const requiredValues = {
		// Client Tab
		company: checkS('company'),
		action: checkS('action'),
		name: checkS('name'),
		searchKey: checkS('searchKey'),
		fiscalCode: checkS('fiscalCode'),
		group: checkS('group'),
		salesRepresentative: checkS('salesRepresentative'),
		priceList: checkS('priceList'),
		paymentTerm: checkS('paymentTerm'),
		// Address Tab
		addressAction: checkS('addressAction'),
		address: checkS('address'),
		zipCode: checkS('zipCode'),
		city: checkS('city'),
		country: checkS('country')
	};

	return requiredValues;
};

const getMissingFieldsNames = (
	errors: DeepMap<any, FieldError>
): {
	names: string[];
	tabs: Set<number>;
} => {
	const fields = {
		// Client Tab
		company: {
			name: 'Company',
			tab: 0
		},
		action: {
			name: 'Action ',
			tab: 0
		},
		name: {
			name: 'Name',
			tab: 0
		},
		searchKey: {
			name: 'Search Key',
			tab: 0
		},
		group: {
			name: 'Group',
			tab: 0
		},
		salesRepresentative: {
			name: 'Sales Representative',
			tab: 0
		},
		priceList: {
			name: 'Price List',
			tab: 0
		},
		paymentTerm: {
			name: 'Payment Term',
			tab: 0
		},
		// Address Tab

		addressAction: {
			name: 'Address Action',
			tab: 1
		},
		address: {
			name: 'Address',
			tab: 1
		},
		zipCode: {
			name: 'Zip Code',
			tab: 1
		},
		city: {
			name: 'City',
			tab: 1
		},
		country: {
			name: 'Country',
			tab: 1
		},

		// Contacts Tab
		invoiceContactAction: {
			name: 'Invoice Contact Action',
			tab: 2
		},
		invoiceContactName: {
			name: 'Invoice Contact Name',
			tab: 2
		},
		invoiceContactEmail: {
			name: 'Invoice Contact Email',
			tab: 2
		},
		invoiceContactPhone: {
			name: 'Invoice Contact Phone',
			tab: 2
		},
		invoiceContactFax: {
			name: 'Invoice Contact Fax',
			tab: 2
		},
		invoiceContactPosition: {
			name: 'Invoice Contact Position',
			tab: 2
		},
		// Envelope Contacts Tab
		envelopeContactAction: {
			name: 'Envelope Contact Action',
			tab: 3
		},
		envelopeContactIsDefault: {
			name: 'Envelope Contact Default Checkbox',
			tab: 3
		},
		envelopeContactName: {
			name: 'Envelope Contact Name',
			tab: 3
		},
		envelopeContactEmail: {
			name: 'Envelope Contact Email',
			tab: 3
		},
		envelopeContactPhone: {
			name: 'Envelope Contact Phone',
			tab: 3
		},
		envelopeContactFax: {
			name: 'Envelope Contact Fax',
			tab: 3
		}
	};

	const fieldsMissing: { name: string; tab: number }[] = [];
	Object.keys(errors).forEach(name => fieldsMissing.push(fields[name]));

	return {
		names: fieldsMissing.map(e => e?.name),
		tabs: new Set(fieldsMissing.map(e => e?.tab))
	};
};

const getAdditionalErrors = (
	errors: DeepMap<any, FieldError>
): {
	additionalNames: string[];
	additionalTabs: Set<number>;
} => {
	const fields = {
		// Client Tab
		company: {
			name: 'Company',
			tab: 0
		},
		action: {
			name: 'Action ',
			tab: 0
		},
		name: {
			name: 'Name',
			tab: 0
		},
		searchKey: {
			name: 'Search Key',
			tab: 0
		},
		group: {
			name: 'Group',
			tab: 0
		},
		salesRepresentative: {
			name: 'Sales Representative',
			tab: 0
		},
		priceList: {
			name: 'Price List',
			tab: 0
		},
		paymentTerm: {
			name: 'Payment Term',
			tab: 0
		},
		// Address Tab

		addressAction: {
			name: 'Address Action',
			tab: 1
		},
		address: {
			name: 'Address',
			tab: 1
		},
		zipCode: {
			name: 'Zip Code',
			tab: 1
		},
		city: {
			name: 'City',
			tab: 1
		},
		country: {
			name: 'Country',
			tab: 1
		},

		// Contacts Tab
		invoiceContactAction: {
			name: 'Invoice Contact Action',
			tab: 2
		},
		invoiceContactName: {
			name: 'Invoice Contact Name',
			tab: 2
		},
		invoiceContactEmail: {
			name: 'Invoice Contact Email',
			tab: 2
		},
		invoiceContactPhone: {
			name: 'Invoice Contact Phone',
			tab: 2
		},
		invoiceContactFax: {
			name: 'Invoice Contact Fax',
			tab: 2
		},
		invoiceContactPosition: {
			name: 'Invoice Contact Position',
			tab: 2
		},
		// Envelope Contacts Tab
		envelopeContactAction: {
			name: 'Envelope Contact Action',
			tab: 3
		},
		envelopeContactIsDefault: {
			name: 'Envelope Contact Default Checkbox',
			tab: 3
		},
		envelopeContactName: {
			name: 'Envelope Contact Name',
			tab: 3
		},
		envelopeContactEmail: {
			name: 'Envelope Contact Email',
			tab: 3
		},
		envelopeContactPhone: {
			name: 'Envelope Contact Phone',
			tab: 3
		},
		envelopeContactFax: {
			name: 'Envelope Contact Fax',
			tab: 3
		}
	};

	const fieldsMissing: { name: string; tab: number }[] = [];
	Object.keys(errors).forEach(name => fieldsMissing.push(fields[name]));

	return {
		additionalNames: fieldsMissing.map(e => e?.name),
		additionalTabs: new Set(fieldsMissing.map(e => e?.tab))
	};
};

//* COMPONENT
const EnvelopeContacts: FC<INewClientTabs> = ({
	control,
	errors,
	watch,
	actions,
	handleWarning,
	additionalErrors,
	submitClick,
	setSubmitClick
}) => {
	const classes = useStyles();

	useEffect(() => {
		if (submitClick) {
			const formPayload = watch();
			const requiredValues = markRequiredValues(formPayload);

			const warningValues = {};
			Object.keys(requiredValues).forEach(e => {
				if (requiredValues[e] === false) {
					warningValues[e] = '';
				}
			});

			const { names, tabs } = getMissingFieldsNames(warningValues);
			const { additionalNames, additionalTabs } = getAdditionalErrors(additionalErrors);

			let mergedNames = names.concat(additionalNames);

			let mergedTabs = new Set([...tabs, ...additionalTabs]);

			if (mergedNames.length !== 0) {
				handleWarning(mergedNames, [...mergedTabs]);
				addNotification(
					'danger',
					`New Client's form is not completed, the following fields must be valid: ${mergedNames.join(', ')}`
				);
			}
			setSubmitClick(false);
		}
	}, [submitClick]);

	return (
		<Grid container spacing={4} className={classes.root}>
			{/* ********* Envelope Contacts (Billing) *********** */}
			<Grid item {...sizes[6]}>
				<Select
					name="envelopeContactAction"
					label="Action"
					options={actions}
					helperText="Action to perform over the client envelope contact"
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Checkbox
					name="envelopeContactIsDefault"
					label="DEFAULT"
					helperText="States if this contact should be considered the client's default"
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="envelopeContactName"
					label="Name"
					helperText="Contact full name"
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="envelopeContactEmail"
					label="Email"
					helperText="Contact email address"
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="envelopeContactPhone"
					label="Phone"
					helperText="Contact phone number"
					onKeyPress={event => {
						if (!/[0-9]/.test(event.key)) {
							event.preventDefault();
						}
					}}
					control={control}
					errors={errors}
				/>
			</Grid>
			<Grid item {...sizes[6]}>
				<Input
					name="envelopeContactFax"
					label="Fax"
					helperText="Contact fax number"
					control={control}
					errors={errors}
				/>
			</Grid>
		</Grid>
	);
};

export default EnvelopeContacts;
