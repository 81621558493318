import * as yup from 'yup';

import { boolean } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	deactivateInErp: boolean,
	deactivateInXtracker: boolean
});

export const defaultValues = {
	deactivateInErp: false,
	deactivateInXtracker: false
};
