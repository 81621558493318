import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		display: 'flex'
	},
	title: {
		marginTop: '75px',
		marginBottom: '30px'
	},
	bigTitle: {
		fontSize: '28px',
		color: theme.typographyColor,
		fontFamily: 'Open Sans SemiBold',
		textTransform: 'uppercase'
	}
}));

export { useStyles };
