import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
	getAccessControlList,
	sendRemoveAllAccesses,
	getAccessControlEditUser,
	clearEditUser,
	clearAdministrationError
} from 'store/administration/administration/thunks';
import { setAccessControlPageFilter } from 'store/filters/actions';
import { AppState } from 'typings/state';

import AccessControl from './AccessControl';

const mapStateToProps = ({ administration, filters }: AppState) => ({ administration, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getAccessControlList,
			sendRemoveAllAccesses,
			getAccessControlEditUser,
			clearEditUser,
			clearAdministrationError,
			setAccessControlPageFilter
		},
		dispatch
	);

export type AccessControlProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AccessControl);
