/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography, Button, FormHelperText } from '@mui/material';
import { Add } from '@mui/icons-material';
import Adicionar from '../../../../../../../assets/icons/Adicionar.svg';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { SourceReposLine, SourceReposLineTitle } from '../../utils';
import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';

//* COMPONENT INTERFACES
interface IProjectRepositories extends IProjectTabs {
	repositories: {
		type: string;
		name: string;
	}[];
	addSourceRepos: () => void;
	deleteSourceRepos: (field: string, index: number) => void;
}

//* COMPONENT
const ProjectRepositories: FC<IProjectRepositories> = ({
	control,
	errors,
	repositories,
	addSourceRepos,
	deleteSourceRepos
}) => {
	const classes = useStyles();
	return (
		<Grid container spacing={4} className={classes.root}>
			{/* **************** Source Repositories ****************** */}
			<Grid item {...sizes[12]}>
				<Typography variant="h2">Source Repositories</Typography>
			</Grid>

			<Grid item {...sizes[9]}>
				<Typography component="span" className={classes.infoLabel}>
					List of source repositories to create for this project.{' '}
				</Typography>
				{errors.repositories && <FormHelperText error>Empty fields are not allowed</FormHelperText>}
			</Grid>
			<Grid item {...sizes[3]}>
				<Button color="primary" variant="text" endIcon={<Adicionar />} onClick={addSourceRepos}>
					Add Repository
				</Button>
			</Grid>

			<Grid item {...sizes[12]}>
				{repositories.length > 0 && (
					<Grid container direction="row" justifyContent="space-around" alignItems="center" spacing={3}>
						<SourceReposLineTitle classes={classes} />
						{repositories.map(
							(e, index) =>
								e !== null && (
									<SourceReposLine
										// eslint-disable-next-line react/no-array-index-key
										key={index}
										index={index}
										handleDelete={deleteSourceRepos}
										control={control}
										errors={errors}
										classes={classes}
									/>
								)
						)}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default ProjectRepositories;
