import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, PUT, POST } from 'lib/handlers/requestTypes';

export const fetchGuide = async (guideName: String): Promise<OriginalResponse | Success> =>
	restCall(GET, `/guides/${guideName}`);

export const fetchUserGuide = async (username: String, guideName: String): Promise<OriginalResponse | Success> =>
	restCall(GET, `/guides/${username}/${guideName}`);

export const setMarkUserGuideAsRead = async (
	username: string,
	guideName: string
): Promise<OriginalResponse | Success> =>
	restCall(PUT, `/guides/${username}/${guideName}/markAsRead`, '', '', false, true);
