// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			display: 'flex',
			padding: '0px 175px 50px 175px',
			minWidth: '100%',
			flexDirection: 'column',
			margin: 'auto'
		},
		rootTablet: {
			display: 'flex',
			padding: '0px 50px 50px 50px',
			minWidth: '100%',
			flexDirection: 'column',
			margin: 'auto'
		},
		statusBar: {
			// direction: 'row',
			justify: 'center',
			alignItems: 'center',
			display: 'flex'
		},
		statusIcon: {
			marginRight: '10px'
		},
		/** end skills tab */
		nonEditableField: {
			color: '#3D464D ',
			fontWeight: 'bold'
		},
		groupCardAdmissions: {
			minWidth: '1200px',
			width: '100%',
			marginTop: '60px'
		},
		infoLabel: {
			fontSize: '14px',
			display: 'flex',
			color: '#3D464D ',
			fontWeight: 'bold'
		}
	})
);

export { useStyles };
