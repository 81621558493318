import { createReducer } from 'typesafe-actions';
import { BudgetActions as Actions, BudgetState } from 'typings/store/admin/budget';

const INITIAL_STATE: BudgetState = {
	loading: false,
	error: null,
	budgetList: null,
	budgetAudit: null,
	newBudget: null,
	budgetAction: null,
	budgetInfo: null,
	budgetProjects: null,
	deleteBudget: null,
	exportBudget: null,
	importBudget: null,
	budgetCompanies: null
};

const stateAndErrors = (state: BudgetState) => ({ ...state, loading: false, error: null });

const BudgetReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({
		...stateAndErrors(state),
		error: payload
	}),
	[Actions.GET_BUDGET_TABLE]: (state, { payload }) => ({
		...stateAndErrors(state),
		budgetList: payload
	}),
	[Actions.SET_NEW_BUDGET]: (state, { payload }) => ({
		...stateAndErrors(state),
		newBudget: payload
	}),
	[Actions.SEND_NEW_BUDGET]: (state, { payload }) => ({
		...stateAndErrors(state),
		budgetAction: payload
	}),
	[Actions.SET_BUDGET_BI_REFRESH]: (state, { payload }) => ({
		...stateAndErrors(state),
		budgetAction: payload
	}),
	[Actions.GET_BUDGET_INFO]: (state, { payload }) => ({
		...stateAndErrors(state),
		budgetInfo: payload
	}),
	[Actions.FETCH_BUDGET_DEPARTMENT_PROJECTS]: (state, { payload }) => ({
		...stateAndErrors(state),
		budgetProjects: payload
	}),
	[Actions.SEND_UPDATE_BUDGET]: (state, { payload }) => ({
		...stateAndErrors(state),
		budgetAction: payload
	}),
	[Actions.GET_BUDGET_AUDIT]: (state, { payload }) => ({
		...stateAndErrors(state),
		budgetAudit: payload
	}),
	[Actions.SEND_DELETE_BUDGET]: (state, { payload }) => ({
		...stateAndErrors(state),
		deleteBudget: payload
	}),
	[Actions.SEND_EXPORT_BUDGET]: (state, { payload }) => ({
		...stateAndErrors(state),
		exportBudget: payload
	}),
	[Actions.POST_IMPORT_BUDGET]: (state, { payload }) => ({
		...stateAndErrors(state),
		importBudget: payload.payload
	}),
	[Actions.CLEAR_BUDGET_ACTION]: state => ({
		...stateAndErrors(state),
		budgetAction: null,
		budgetAudit: null
	}),
	[Actions.CLEAR_BUDGET_INFO]: state => ({
		...stateAndErrors(state),
		budgetList: null,
		budgetInfo: null
	}),
	[Actions.SET_BUDGET_COMPANY]: (state, { payload }) => ({
		...stateAndErrors(state),
		budgetCompanies: payload
	})
});

export default BudgetReducer;
