/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';
import { Add, Label, Subject } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, BackPage, Table, PageTitle, TabPanel, TabHeader, Dialog, Tab } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';
import { ErrorPage } from 'xpand-ui/lab';
import { Numeric } from 'xpand-ui/forms';

//* TYPINGS
import { ICollaborationProposal, IProposalSummaryPayload } from 'typings/store/admin/proposals';
import { Match, IUserToken } from 'typings/store/generalTypes';
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { IPageTitle } from 'components/App/TitleProvider';
import {
	getCollaborationTypeList,
	submitCollaborationTypeCancel,
	submitCollaborationTypeClose,
	submitCollaborationTypeRequestApproval
} from 'store/administration/collaborationTypesManagement/thunks';

//* PROJECT IMPORTS [LIB / PAGES ]
import { Roles, actionPermission } from 'lib/roles';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { allStatus } from 'pages/Admin/_ProposalsFormUtils/utils';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { CollaborationTypeListProps } from './index';

//* LOCAL ASSETS
import imageURL from '../../../../../assets/images/status diagrams/Diagram - Employee Salary Categories - XPECP.png';
import Adicionar from '../../../../../assets/icons/Adicionar.svg';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import CscAuditLog from '../../EmployeeSalaryCategoriesList/CscAuditLog';
import DetailsModalHistory from '../../EmployeeCategoriesManagement/DetailsModalHistory';
import DetailsModal from '../../EmployeeSalaryCategoriesList/DetailsModal';
import { BALWURK_EMPLOYEE_ID, XPAND_EMPLOYEE_ID } from 'lib/utils/constants';
import { resetEmployeeActions } from 'store/administration/collaborationTypesManagement/actions';
//* COMPONENT INTERFACES
interface ICollaborationTypeList extends CollaborationTypeListProps, IPageTitle {
	match: Match<{
		hash: string;
	}>;
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
}

const STATUS_NEW = 0;
const STATUS_APPROVED = 1;
// const STATUS_SENT = 2;
const STATUS_CANCELED = 3;
// const STATUS_REFUSED = 4;
// const STATUS_ACCEPTED = 5;
const STATUS_CLOSED = 6;
const STATUS_WAITING_APPROVAL = 7;
const STATUS_REJECTED = 8;

const tabs = [
	{
		id: 'IN_PROGRESS',
		label: 'IN PROGRESS'
	},
	{
		id: 'HISTORY',
		label: 'HISTORY'
	}
];
interface Action {
	id: number;
	label: string;
}

export const AVAILABLE_ACTIONS = {
	REQUEST_APPROVAL: { id: 0, label: 'Request Approval' },
	CLOSE: { id: 1, label: 'Close Collaboration Type' },
	CANCEL: { id: 2, label: 'Cancel Collaboration Type' },
	APPROVE: { id: 3, label: 'Approve Collaboration Type' },
	REJECT: { id: 4, label: 'Reject Collaboration Type' }
};

const notificationPayload = {
	area: 'Salaries & Contracts',
	section: 'Collaboration Types'
};
//* COMPONENT
const CollaborationTypeList: FC<ICollaborationTypeList> = ({
	match,
	filters,
	goToPage,
	title,
	setTitle,
	loggedUser,
	setNotificationAsReadProposals,
	setNewNotificationProposals
}) => {
	const classes = useStyles();
	const { params } = match;
	const hash = params?.hash;
	const { width } = useWindowDimensions();
	const isTablet = useMemo(() => width <= 1457, [width]); // default is 900 tablet
	const [tabSelected, setTabSelected] = useState(0);
	const [detailsModal, setDetailsModal] = useState<ICollaborationProposal | null>(null);
	const [detailsModalHistory, setDetailsModalHistory] = useState<ICollaborationProposal | null>(null);
	const [auditModal, setAuditModalModal] = useState<ICollaborationProposal | null>(null);
	const employeeCategoryList = useSelector(state => state.collaborationTypesManagement.employeeCategoryList);
	const error = useSelector(state => state.collaborationTypesManagement.error);
	const loading = useSelector(state => state.collaborationTypesManagement.loading);
	const dispatch = useDispatch();
	const [searchValue, setSearchValue] = useState('');
	const [cscHistorySearchValue, setCscHistorySearchValue] = useState('');
	const [firstLoading, setFirstLoading] = useState(false);
	const [isFinishingAction, setIsFinishingAction] = useState(false);

	// CONFIRMATION DIALOG HELPER
	const [openConfirmationModal, setOpenConfirmationModal] = useState<{
		row: ICollaborationProposal | null;
		isOpen: boolean;
		action: Action | null;
	}>({ action: null, isOpen: false, row: null });

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	// TABS COMPONENT - handle method
	const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => setTabSelected(newValue);
	useEffect(() => {
		if (!employeeCategoryList && firstLoading) dispatch(getCollaborationTypeList(hash));
	}, [employeeCategoryList]);

	useEffect(() => {
		dispatch(getCollaborationTypeList(hash));
		setFirstLoading(true);
	}, []);

	useEffect(() => {
		if (employeeCategoryList && employeeCategoryList) {
			const newTitleString = !employeeCategoryList?.userInfo?.username
				? 'Collaboration Types'
				: `Collaboration Types - ${`${employeeCategoryList?.userInfo?.username}`.toUpperCase()} : ${
						employeeCategoryList?.employeeInfo?.name
				  }`;

			newTitleString !== title.title &&
				setTitle({
					...title,
					title: newTitleString
				});
		}
	}, [employeeCategoryList, hash, title]);

	const hasApprovePermission = useMemo(
		() =>
			employeeCategoryList &&
			employeeCategoryList[hash] &&
			(actionPermission(Roles.CP_ROLE_PAYROLL_AND_BENEFITS) ||
				actionPermission(Roles.CP_ROLE_BUSINESS_UNIT_MANAGERS)),
		[title]
	);
	const hasRejectPermission = useMemo(
		() =>
			employeeCategoryList &&
			employeeCategoryList[hash] &&
			(actionPermission(Roles.CP_ROLE_PAYROLL_AND_BENEFITS) ||
				actionPermission(Roles.CP_ROLE_BUSINESS_UNIT_MANAGERS)),
		[title]
	);

	const statusHelper = useMemo(() => allStatus(classes), []);

	// PAGE HEADER ACTIONS
	const pageTitleActions = useMemo(
		() => [
			{
				id: 'addNewCsc',
				onClick: () => goToPage(`/admin/editEmployee/${hash}`),
				icon: <Adicionar />,
				label: 'Add new Collaboration Type'
			}
		],
		[]
	);

	/** CSCs IN PROGRESS TABLE */
	// TABLE IN PROGRESS ROW ACTIONS
	const tableActions: ITableAction<ICollaborationProposal>[] = useMemo(
		() => [
			{
				id: 'detailsAction',
				type: 'button',
				icon: <Subject />,
				render: () => true,
				disabled: () => false,
				onClick: row => setDetailsModal(row)
			},
			{
				id: 'proposalActions',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'edit',
						label: 'Edit',
						render: row => row.proposalStatus === STATUS_NEW || row.proposalStatus === STATUS_REJECTED,
						onClick: row => {
							goToPage(
								`/admin/salariesAndContracts/editCollaborationType/${hash}/${row.token}/${row.id}`
							);
						}
					},
					{
						id: 'auditLog',
						label: 'Audit Log',
						render: () => true,
						onClick: row => setAuditModalModal(row)
					},
					{
						id: 'requestApproval',
						label: 'Request Approval',
						render: row => row.proposalStatus === STATUS_NEW || row.proposalStatus === STATUS_REJECTED,
						onClick: row =>
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.REQUEST_APPROVAL, isOpen: true, row })
					},
					{
						id: 'approve',
						label: 'Approve',
						render: row => row.proposalStatus === STATUS_WAITING_APPROVAL,
						//disabled: () => !hasApprovePermission, // if has permission, then disable is !(hasPermission)
						onClick: row =>
							!hasApprovePermission &&
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.APPROVE, isOpen: true, row })
					},
					{
						id: 'reject',
						label: 'Reject',
						render: row => row.proposalStatus === STATUS_WAITING_APPROVAL,
						//disabled: () => !hasRejectPermission, // if has permission, then disable is !(hasPermission)
						onClick: row =>
							!hasRejectPermission &&
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.REJECT, isOpen: true, row })
					},
					{
						id: 'close',
						label: 'Close',
						render: row => row.proposalStatus === STATUS_APPROVED,
						onClick: row => setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.CLOSE, isOpen: true, row }) // submitEmployeeSalaryCategoryClose(row.token, row.id)
					},
					{
						id: 'cancel',
						label: 'Cancel',
						render: row => row.proposalStatus !== STATUS_CANCELED && row.proposalStatus !== STATUS_CLOSED,
						onClick: row =>
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.CANCEL, isOpen: true, row }) // submitEmployeeSalaryCategoryCancel(row.token, row.id)
					}
				]
			}
		],
		[hasApprovePermission, hasRejectPermission]
	);
	// TABLE IN PROGRESS DATA PARSED
	const tableData = useMemo(() => {
		return employeeCategoryList?.proposalsPerToken?.map(
			(e: IProposalSummaryPayload) =>
				({
					...e,
					monthlyGrossValue: e.employeeCategory !== 'Contractor' ? e.summary?.monthlyGrossValue : '',
					monthlyNetValue: e.employeeCategory !== 'Contractor' ? e.summary?.monthlyNetValue : '',
					annualGrossCostWithPrize:
						e.employeeCategory !== 'Contractor' ? e.summary?.annualGrossCostWithPrize : '',
					startDateParsed: e.startDate ? parseDateToShow(e.startDate) : '',
					endDateParsed: e.endDate ? parseDateToShow(e.endDate) : '',
					status: e.proposalStatus || '',
					diffMonthlyNet:
						e.summary?.monthlyNetValue + ' ' + Number(e.diffPercentageMontlyNet).toFixed(2) + '%',
					diffTotalCost: Number(e.diffPercentageTotalCost).toFixed(2) + '%',
					employeeCategory: e.employeeCategory
				} || [])
		);
	}, [employeeCategoryList]);

	// TABLe IN PROGRESS COLUMNS
	const tableColumns: ITableColumn<IProposalSummaryPayload>[] = useMemo(
		() => [
			{
				label: 'Start Date',
				id: 'startDate',
				type: 'date',
				width: '10%',
				format: (row, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'End Date',
				id: 'endDate',
				type: 'date',
				width: '10%',
				format: (row, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Employee Category',
				id: 'employeeCategory',
				align: 'left',
				width: '20%'
			},
			{
				label: 'Monthly Gross',
				id: 'monthlyGrossValue',
				align: 'left',
				width: '20%',
				format: row => <Numeric readOnly money value={row.monthlyGrossValue} />
			},
			{
				label: 'Monthly Net Amount',
				id: 'monthlyNetValue',
				align: 'left',
				width: '20%',
				format: row => {
					return (
						<>
							{!isTablet ? (
								<Grid container direction="row" justifyContent="flex-start" alignItems="center">
									<Grid item xs={6}>
										<Numeric readOnly money value={row.monthlyNetValue} />
									</Grid>
								</Grid>
							) : (
								<>
									<Numeric readOnly money value={row.monthlyNetValue} />
								</>
							)}
						</>
					);
				}
			},

			{
				label: 'Total Cost (with Prize)',
				id: 'annualGrossCostWithPrize',
				align: 'left',
				width: '20%',
				format: row => {
					return (
						<>
							{!isTablet ? (
								<Grid container direction="row" justifyContent="flex-start" alignItems="center">
									<Grid item xs={6}>
										<Numeric readOnly money value={row.annualGrossCostWithPrize} />{' '}
									</Grid>
								</Grid>
							) : (
								<>
									<Numeric readOnly money value={row.annualGrossCostWithPrize} />
								</>
							)}
						</>
					);
				}
			},
			{
				label: 'Status',
				id: 'status',
				width: '15%',
				format: ({ proposalStatus }) => {
					if (proposalStatus === 99)
						return (
							<div className={classes.statusBar}>
								{statusHelper[9].icon} {statusHelper[9].label}
							</div>
						);
					if (statusHelper[proposalStatus])
						return (
							<div className={classes.statusBar}>
								{statusHelper[proposalStatus].icon} {statusHelper[proposalStatus].label}
							</div>
						);

					return <div className={classes.statusBar}>{proposalStatus}</div>;
				}
			}
		],
		[employeeCategoryList]
	);

	/** CSCs HISTORY TABLE */
	// TABLE HISTORY ROW ACTIONS
	const tableCSCHistoryActions = useMemo(
		() => [
			{
				id: 'historyDetailsAction',
				type: 'button',
				icon: <Subject />,
				render: row => (row.employeeCategory === 'Contractor' ? false : true),
				disabled: () => false,
				onClick: (row: ICollaborationProposal) => {
					setDetailsModalHistory({ ...employeeCategoryList.employeeInfo, ...row });
				}
			}
		],
		[employeeCategoryList]
	);

	// TABLE HISTORY DATA PARSED
	const tableCSCHistoryData = useMemo(() => {
		if (!employeeCategoryList) return [];

		return employeeCategoryList?.collabPeriods?.map((e: any) => ({
			...e,
			startingDateParsed: e.startDate ? parseDateToShow(e.startDate) : '',
			endDateParsed: e.finishDate ? parseDateToShow(e.finishDate) : '',
			startingDate: e?.startDate ? e.startDate : '',
			endDate: e?.finishDate ? e.finishDate : '',
			employeeCategory: e?.roleType?.charAt(0).toUpperCase() + e.roleType?.slice(1).toLowerCase(),
			budget: Number(e?.budget)
		}));
	}, [employeeCategoryList]);

	// TABLE HISTORY COLUMNS
	const tableCSCHistoryColumns: ITableColumn<ICollaborationProposal>[] = useMemo(
		() => [
			{
				label: 'Start Date',
				id: 'startingDate',
				type: 'date',
				format: (row: ICollaborationProposal, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'End Date',
				id: 'endDate',
				type: 'date',
				format: (row: ICollaborationProposal, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Employee Category',
				id: 'employeeCategory',
				accentColumn: true,
				align: 'left'
			},
			{
				label: 'Budget',
				id: 'budget',
				align: 'left',
				width: '20%',
				format: row => (row.budget !== '0' ? <Numeric readOnly money value={row.budget} /> : '')
			}
		],
		[employeeCategoryList]
	);

	const requestAction = (action: Action, setIsFinishingAction: React.Dispatch<React.SetStateAction<boolean>>) => {
		if (!action || !action.label) {
			return;
		}
		const { row } = openConfirmationModal;

		switch (action.id) {
			case AVAILABLE_ACTIONS.REQUEST_APPROVAL.id:
				row &&
					dispatch(
						submitCollaborationTypeRequestApproval(hash, row.token as string, row.id, setIsFinishingAction)
					);
				dispatch(resetEmployeeActions());
				break;
			case AVAILABLE_ACTIONS.CLOSE.id:
				row && dispatch(submitCollaborationTypeClose(hash, row.token as string, row.id, setIsFinishingAction));
				dispatch(resetEmployeeActions());
				dispatch(setNotificationAsReadProposals(row.id.toString(), notificationPayload));
				break;
			case AVAILABLE_ACTIONS.APPROVE.id:
				row &&
					goToPage(
						`/admin/salariesAndContracts/collaborationTypes/approve/${row.token}/${row.id}/${loggedUser.username}`
					);
				setIsFinishingAction(false);
				break;
			case AVAILABLE_ACTIONS.REJECT.id:
				row &&
					goToPage(
						`/admin/salariesAndContracts/collaborationTypes/reject/${row.token}/${row.id}/${loggedUser.username}`
					);
				setIsFinishingAction(false);
				break;
			case AVAILABLE_ACTIONS.CANCEL.id:
				row && dispatch(submitCollaborationTypeCancel(hash, row.token as string, row.id, setIsFinishingAction));
				resetEmployeeActions();
				dispatch(setNotificationAsReadProposals(row.id.toString(), notificationPayload));
				break;
			default:
				break;
		}
	};

	const getModalMessage = (action: Action) =>
		action ? `Are you sure you want to execute the following action: ${action.label} ` : '';

	const confirmActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenConfirmationModal({ action: openConfirmationModal.action, isOpen: false, row: null })
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				setOpenConfirmationModal({ action: openConfirmationModal.action, isOpen: false, row: null });
				setIsFinishingAction(true);
				requestAction(openConfirmationModal.action as Action, setIsFinishingAction);
			}
		}
	];

	//if (error) return handleErrorPage(error, clearCscError);
	const isLoading = employeeCategoryList === null || isFinishingAction;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}

			<BackPage path="/admin/salariesAndContracts/collaborationTypes" action={goToPage} />
			<Grid
				container
				direction="row"
				justifyContent="space-evenly"
				alignItems="flex-start"
				spacing={5}
				className={classes.root}>
				<PageTitle
					asItem
					title={
						<TabHeader
							selected={tabSelected}
							handleSelected={handleTabChange}
							variant="scrollable"
							scrollButtons="auto">
							{tabs.map((tab, index) => (
								<Tab
									key={`EmployeeCategoryList_${tab.id}_tab_button`}
									label={tab.label}
									index={index}
								/>
							))}
						</TabHeader>
					}
					actions={pageTitleActions}
				/>
				<Grid item {...sizes[12]}>
					{tabs.map((tab, index) => (
						<TabPanel
							key={`EmployeeCategoryList_${tab.id}_tab_content`}
							value={tabSelected}
							index={index}
							className={classes.tabsRoot}>
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								alignItems="baseline"
								spacing={2}>
								{tabSelected === 0 && (
									<Table
										tableData={tableData?.length > 0 ? tableData : []}
										columns={tableColumns}
										actions={tableActions}
										defaultSortColumn="startDate"
										defaultOrder="desc"
										handleSearch={{ searchValue, setSearchValue }}
										handleDiagramModal={{ diagramModal, setDiagramModal }}
									/>
								)}
								{tabSelected === 1 && (
									<>
										<Table
											tableData={tableCSCHistoryData?.length > 0 ? tableCSCHistoryData : []}
											columns={tableCSCHistoryColumns}
											defaultSortColumn="startingDate"
											defaultOrder="desc"
											handleSearch={{
												searchValue: cscHistorySearchValue,
												setSearchValue: setCscHistorySearchValue
											}}
										/>
									</>
								)}
							</Grid>
						</TabPanel>
					))}
				</Grid>
			</Grid>
			<Dialog
				fullScreen
				// customClasses={{ fullScreenContent: classes.fullScreenContent }}
				title=""
				scroll="body"
				modal={{
					open: Boolean(detailsModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setDetailsModal(null);
					},
					content:
						(Boolean(detailsModal) && (
							<DetailsModal
								proposal={detailsModal as ICollaborationProposal}
								hash={hash}
								handleClose={setDetailsModal}
							/>
						)) ||
						''
				}}
			/>
			<Dialog
				fullScreen
				// customClasses={{ fullScreenContent: classes.fullScreenContent }}
				title=""
				scroll="body"
				modal={{
					open: Boolean(auditModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setAuditModalModal(null);
					},
					content:
						(auditModal && (
							<CscAuditLog
								id={auditModal.id}
								hash={hash}
								ESCName={`Collaboration Type - ${`${employeeCategoryList?.userInfo?.username}`.toUpperCase()} : ${
									employeeCategoryList?.employeeInfo?.name
								} - Audit Log`}
								token={auditModal.token as string}
								handleClose={setAuditModalModal}
							/>
						)) ||
						''
				}}
			/>
			<Dialog
				modal={{
					open: Boolean(openConfirmationModal.isOpen),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick')
							setOpenConfirmationModal({
								action: openConfirmationModal.action,
								isOpen: false,
								row: null
							});
					},
					content: (
						<>
							<Typography gutterBottom>
								{getModalMessage(openConfirmationModal.action as Action)}
							</Typography>
						</>
					)
				}}
				title="Confirm action"
				actions={confirmActions}
				scroll="body"
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(CollaborationTypeList);
