import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	name: {
		fontFamily: 'Open Sans SemiBold',
		textTransform: 'uppercase',
		fontSize: '16px'
	},
	w100: {
		width: '100%'
	},
	dateLabel: {
		fontFamily: 'Open Sans',
		fontSize: '12px',
		color: theme.grayedColor,
		marginRight: '5px'
	},
	date: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px',
		color: theme.palette.secondary.main
	},
	downloadButtonText: {
		marginLeft: '7px',
		color: theme.typographyColor,
		fontWeight: 600,
		fontSize: '14px'
	},
	summaryArea: {
		marginTop: '10px',
		maxHeight: 'calc(100vh - 320px)',
		overflowX: 'hidden'
	}
}));

export { useStyles };
