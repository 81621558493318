import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import { stringRequired, dateRequired, numberRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	employee: yup.string(),
	startDate: yup.string(),
	endDate: yup.string(),
	typeTimeoff: yup.string()
});

export const defaultValues = {
	employee: 'all',
	startDate: '',
	endDate: '',
	typeTimeoff: 'all'
};
