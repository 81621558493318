/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Typography } from '@mui/material';
import { Delete, Refresh } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, Dialog, PageTitle } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';

//* LOCAL COMPONENT IMPORTS
import { CacheManagementProps } from '.';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES

//* COMPONENT
const CacheManagement: FC<CacheManagementProps> = ({
	administration,
	filters,
	getCachesList,
	sendDeleteSingleCache,
	sendDeleteAllUserCaches,
	sendDeleteAllCompaniesCaches,
	sendDeleteAllCaches,
	clearAdministrationError,
	setCacheManagementPageFilter
}) => {
	const { listCaches, error, loading } = administration;
	const [confirmModal, setConfirmModal] = useState<number | false>(false);
	const { cacheManagementFilter } = filters;

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(cacheManagementFilter);

	useEffect(() => {
		setCacheManagementPageFilter(searchValue);
	}, [searchValue]);

	useEffect(() => {
		if (!error) {
			getCachesList();
		}
	}, []);

	// Changes the status of the email Template to it's opposite(Enable/Disable)
	const confirmDeleteAllCaches = () => {
		sendDeleteAllCaches([]);
		setConfirmModal(false);
		window.location.reload();
	};
	// Changes the status of the email Template to it's opposite(Enable/Disable)
	const confirmDeleteAllUserCaches = () => {
		sendDeleteAllUserCaches();
		setConfirmModal(false);
	};

	// Changes the status of the email Template to it's opposite(Enable/Disable)
	const confirmDeleteAllCompanyCaches = () => {
		sendDeleteAllCompaniesCaches();
		setConfirmModal(false);
	};

	const confirmDeleteCache = (id: number) => {
		sendDeleteSingleCache(id);
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = useMemo(
		() => [
			{ label: 'Source', id: 'source', accentColumn: true },
			{
				label: 'Name',
				id: 'name'
			},
			{
				label: 'Last Update Date',
				id: 'lastUpdateDate',
				type: 'date',
				format: (row: unknown, text: string) => (text ? parseDateToShow(text) + ' ' + text.split(' ')[1] : '')
			}
		],
		[]
	);

	const tableActions: ITableAction<any>[] = useMemo(
		() => [
			{
				id: 'cacheManagementMenu',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'cacheManagementMenuDeleteCache',
						label: 'Delete Cache',
						onClick: (row: { id: number }) => {
							confirmDeleteCache(row.id);
						},
						disabled: () => getLSField('impersonate_userInfo')
					}
				]
			}
		],
		[listCaches]
	);

	const pageTitleActions = useMemo(
		() => [
			{
				id: 'deleteAllUsersCaches',
				onClick: () => {
					setConfirmModal(1);
				},
				icon: <Refresh />,
				label: 'Force Users Cache Refresh'
			},
			{
				id: 'deleteAllCompaniesCaches',
				onClick: () => {
					setConfirmModal(3);
				},
				icon: <Refresh />,
				label: 'Force Companies Cache Refresh'
			},
			{
				id: 'deleteAllCaches',
				onClick: () => {
					setConfirmModal(2);
				},
				icon: <Delete />,
				label: 'Delete All Caches'
			}
		],
		[]
	);

	const tableData = useMemo(
		() =>
			listCaches?.caches?.map((e: { lastUpdateDate: string }) => ({
				...e,
				lastUpdateDateParsed: e.lastUpdateDate ? parseDateToShow(e.lastUpdateDate) : ''
			})),
		[listCaches]
	);

	if (error) return handleErrorPage(error, clearAdministrationError);

	if (loading) return <LoadingOverlay />;

	let content = '';
	if (confirmModal === 1) content = 'force reload all users ';
	if (confirmModal === 2) content = 'delete all ';
	if (confirmModal === 3) content = 'force reload all companies ';

	let title = '';
	if (confirmModal === 1) title = 'Force Reload Users Cache';
	if (confirmModal === 2) title = 'Delete All Cache';
	if (confirmModal === 3) title = 'Force Reload Companies Cache';
	return (
		<>
			<PageTitle title="" actions={pageTitleActions} />
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="name"
				handleSearch={{ searchValue, setSearchValue }}
			/>
			<Dialog
				modal={{
					open: confirmModal !== false,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setConfirmModal(false);
					},
					content: (
						<Typography gutterBottom>
							Are you sure you want to {content}
							caches?
						</Typography>
					)
				}}
				title={title}
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setConfirmModal(false)
					},
					{
						id: 'confirmDeleteAllCaches',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							if (confirmModal === 1) confirmDeleteAllUserCaches();

							if (confirmModal === 2) confirmDeleteAllCaches();

							if (confirmModal === 3) confirmDeleteAllCompanyCaches();
						},
						disabled: getLSField('impersonate_userInfo')
					}
				]}
				scroll="body"
			/>
		</>
	);
};

export default withLayout(CacheManagement);
