import { createReducer } from 'typesafe-actions';
import { PlannerActions as Actions, PlannerState } from 'typings/store/admin/planner';

const INITIAL_STATE: PlannerState = {
	loading: false,
	error: null,
	plannerData: null,
	plannerFilteredData: null,
	filtersData: null,
	postDataResult: false,
	plannerEventDelete: null,
	plannerActions: ''
};

const PlannerReducer = createReducer(INITIAL_STATE, {
	[Actions.SET_LOADING]: state => ({ ...state, loading: true }),
	[Actions.SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	[Actions.GET_PLANNER_DATA]: (state, { payload }) => ({
		...state,
		loading: false,
		plannerData: payload
	}),
	[Actions.GET_FILTERS_DATA]: (state, { payload }) => ({
		...state,
		filtersData: payload
	}),
	[Actions.POST_PLANNER_DATA]: (state, { payload }) => ({
		...state,
		postDataResult: payload
	}),
	[Actions.POST_DELETE_PLANNER_EVENT]: (state, { payload }) => ({
		...state,
		plannerEventDelete: payload
	}),
	[Actions.POST_FILTERS]: (state, { payload }) => ({
		...state,
		loading: false,
		plannerFilteredData: payload
	}),
	[Actions.CLEAR_PLANNER_DATA]: state => ({
		...state,
		plannerData: null
	}),
	[Actions.CLEAR_ALL_DATA]: state => ({
		...state,
		...INITIAL_STATE
	}),
	[Actions.SET_PLANNER_ACTION]: (state, { payload }) => ({
		...state,
		plannerActions: payload
	}),
	[Actions.POST_PLANNER_DATA_CREATE]: (state, { payload }) => ({
		...state,
		postDataResult: payload
	})
});

export default PlannerReducer;
