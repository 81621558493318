import { createReducer } from 'typesafe-actions';
import { FiltersActions as Actions, FiltersState } from 'typings/store/filtersTypes';
import { legacy_createStore as createStore } from 'redux';

const INITIAL_STATE: FiltersState = {
	//RESPONSABLE KEYS TO DYNAMIZE LOGOS AND TEXT OF DIFFERENT COMPANIES
	companyAppsFilter: '',
	myTrainingsFilter: '',
	myFinancialDocumentsFilter: '',
	listEmployeesFilter: '',
	resignationManagementFilter: '',
	manageAdmissionsFilter: '',
	manageAdmissionStatusFilter: { 0: false, 1: false, 2: false, 3: false, 4: false, 6: false, 7: false },
	cscFilter: '',
	cscStatusFilter: { 0: true, 1: true, 3: true, 6: true, 7: true, 8: true, 9: true },
	cscCompanyFilter: '',
	ecStatusFilter: { 0: true, 1: true, 3: true, 6: true, 7: true, 8: true, 9: true },
	ecFilter: '',
	proposalsFilter: '',
	proposalsStatusFilter: { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false },
	budgetFilter: '',
	budgetCompanyFilter: 'PT',
	skillsReminderFilter: '',
	searchResumesFilter: '',
	accessControlFilter: '',
	accessControlRolesFilter: {
		0: false,
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
		6: false,
		7: false,
		8: false,
		9: false,
		10: false,
		11: false,
		12: false,
		13: false,
		14: false,
		15: false,
		16: false,
		17: false
	},
	accessControlAddFilter: '',
	emailTemplatesFilter: '',
	cacheManagementFilter: '',
	fileTemplatesFilter: '',
	releaseNotesFilter: '',
	releaseNotesProjectFilter: 'XPECP',
	contractManagementFilter: '',
	plannerFilters: {
		endDate: '',
		offices: [],
		projects: [],
		showAvailableOnly: false,
		showTimeOff: true,
		skills: [],
		startDate: '',
		users: []
	},
	allEmployeesFilter: '',
	allEmployeesAbcFilter: 'All',
	allEmployeesExtraFilter: {
		company: '*',
		businessUnit: null,
		division: null,
		department: null,
		location: null
	},
	usersFilter: '',
	myTimesheetsTypeViewFilter: 'workWeek'
};

const FiltersReducer = createReducer(INITIAL_STATE, {
	// Actions responsible for the filters in each page
	[Actions.APPS_FILTER]: (state, { payload }) => ({
		...state,
		companyAppsFilter: payload
	}),
	[Actions.MY_TRAININGS_FILTER]: (state, { payload }) => ({
		...state,
		myTrainingsFilter: payload
	}),
	[Actions.MY_FINANCIAL_DOCUMENTS_FILTER]: (state, { payload }) => ({
		...state,
		myFinancialDocumentsFilter: payload
	}),
	[Actions.LIST_EMPLOYEES_FILTER]: (state, { payload }) => ({
		...state,
		listEmployeesFilter: payload
	}),
	[Actions.RESIGNATION_MANAGEMENT_FILTER]: (state, { payload }) => ({
		...state,
		resignationManagementFilter: payload
	}),
	[Actions.MANAGE_ADMISSIONS_FILTER]: (state, { payload }) => ({
		...state,
		manageAdmissionsFilter: payload.searchValue,
		manageAdmissionStatusFilter: payload.statusFilter
	}),
	[Actions.CSC_FILTER]: (state, { payload }) => ({
		...state,
		cscFilter: payload.searchValue,
		cscStatusFilter: payload.statusFilter,
		cscCompanyFilter: payload.companySelected
	}),
	[Actions.EC_FILTER]: (state, { payload }) => ({
		...state,
		ecFilter: payload.searchValue,
		ecStatusFilter: payload.statusFilter
	}),
	[Actions.CONTRACT_MANAGEMENT_FILTER]: (state, { payload }) => ({
		...state,
		contractManagementFilter: payload
	}),
	[Actions.PROPOSALS_FILTER]: (state, { payload }) => ({
		...state,
		proposalsFilter: payload.searchValue,
		proposalsStatusFilter: payload.statusFilter
	}),
	[Actions.BUDGET_FILTER]: (state, { payload }) => ({
		...state,
		budgetFilter: payload.searchValue,
		budgetCompanyFilter: payload.selectedCompany
	}),
	[Actions.SKILLS_REMINDER_FILTER]: (state, { payload }) => ({
		...state,
		skillsReminderFilter: payload
	}),
	[Actions.SEARCH_RESUMES_FILTER]: (state, { payload }) => ({
		...state,
		searchResumesFilter: payload
	}),
	[Actions.ACCESS_CONTROL_FILTER]: (state, { payload }) => ({
		...state,
		accessControlFilter: payload.searchValue,
		accessControlRolesFilter: payload.rolesFilter
	}),
	[Actions.ACCESS_CONTROL_ADD_FILTER]: (state, { payload }) => ({
		...state,
		accessControlAddFilter: payload
	}),
	[Actions.EMAIL_TEMPLATES_FILTER]: (state, { payload }) => ({
		...state,
		emailTemplatesFilter: payload
	}),
	[Actions.CACHE_MANAGEMENT_FILTER]: (state, { payload }) => ({
		...state,
		cacheManagementFilter: payload
	}),
	[Actions.FILE_TEMPLATES_FILTER]: (state, { payload }) => ({
		...state,
		fileTemplatesFilter: payload
	}),
	[Actions.RELEASE_HISTORY_FILTER]: (state, { payload }) => ({
		...state,
		releaseNotesFilter: payload.searchValue,
		releaseNotesProjectFilter: payload.currentSelectedProject
	}),
	[Actions.PLANNER_FILTERS]: (state, { payload }) => ({
		...state,
		plannerFilters: payload
	}),
	[Actions.ALL_EMPLOYEES_FILTERS]: (state, { payload }) => ({
		...state,
		allEmployeesFilter: payload.searchValue,
		allEmployeesAbcFilter: payload.currentLetter,
		allEmployeesExtraFilter: payload.filter
	}),
	[Actions.MY_TIMESHEETS_FILTERS]: (state, { payload }) => ({
		...state,
		myTimesheetsTypeViewFilter: payload
	}),
	[Actions.CLEAR_ALL_FILTERS]: () => ({
		...INITIAL_STATE
	}),
	[Actions.MY_EXPENSES_FILTERS]: (state, { payload }) => ({
		...state,
		myExpensesFilter: payload
	})
});

export const store = createStore(FiltersReducer);

export default FiltersReducer;
