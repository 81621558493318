//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
//* EXTERNAL LIBS --> XPAND-UI
//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

import Chart from 'assets/images/myCompany/orgChart.png';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { OrgChartProps } from '.';

//* COMPONENT INTERFACES
const OrgChart: FC<OrgChartProps> = ({company}) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<img src={company.companyChart} alt="" className={classes.image} />
		</div>
	);
};

export default withLayout(OrgChart);
