import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { AppState } from 'typings/state';
import {
	submitEmployeeSalaryCategoryApprove,
	clearProposalsFormsData
} from 'store/administration/employeeSalaryCategories/thunks';

import ApproveCostSalaryCategory from './ApproveCostSalaryCategory';
import { setNotificationAsReadProposals } from 'store/notifications/thunks';

const mapStateToProps = ({ employeeSalary }: AppState) => ({ employeeSalary });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			submitEmployeeSalaryCategoryApprove,
			clearProposalsFormsData,
			setNotificationAsReadProposals
		},
		dispatch
	);

export type ApproveCostSalaryCategoryProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ApproveCostSalaryCategory);
