/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useState, useEffect } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { Select, Input } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { IAdmissionSheetTabs } from '../../AdmissionSheet';
import { useStyles } from './styles';

//* COMPONENT INTERFACES

//* COMPONENT
const FamilyInfo: FC<IAdmissionSheetTabs> = ({
	control,
	errors,
	AdmissionSheetDataOptions,
	watch,
	setValue,
	formFields
}) => {
	const classes = useStyles();
	const [holderStatusOptions, setHolderStatusOptions] = useState([
		{ id: '1', label: 'Single Holder' },
		{ id: '2', label: 'Two Holders' }
	]);

	const [holderStatusHelperText, setHolderStatusHelperText] = useState(
		'Select the correct option, if only you or both you and your spouse are currently employed'
	);

	const taxZoneSelect = [
		{ id: 'PT', label: 'PT' },
		{ id: 'PT-AC', label: 'PT-AC' },
		{ id: 'PT-MA', label: 'PT-MA' }
	];

	// This useEffect checks the maritalStatus and defines the numbers of holders as 1 if the maritalStatus is equal to "single, divorced or widower"
	// If the maritalStatus is equal to "Married" the user can choose between 1 or 2 holders
	useEffect(() => {
		// In this form, the first value of "maritalStatus" is empty. Thats why the condition isn´t just (watch('maritalStatus')!=='Married') or vice-versa
		if (
			watch('maritalStatus') === 'Single' ||
			watch('maritalStatus') === 'Divorced' ||
			watch('maritalStatus') === 'Widower'
		) {
			setHolderStatusOptions([{ id: '1', label: 'Single Holder' }]);
			setHolderStatusHelperText('');
			setValue('holdersNumber', '1');
		} else {
			setHolderStatusOptions([
				{ id: '1', label: 'Single Holder' },
				{ id: '2', label: 'Two Holders' }
			]);
			setHolderStatusHelperText(
				'Select the correct option, if only you or both you and your spouse are currently employed'
			);
		}
	}, [watch('maritalStatus')]);
	return (
		<Grid container spacing={4} className={classes.root}>
			{formFields.formFieldsConfig.maritalStatus && (
				<Grid item xs={6}>
					<Select
						name="maritalStatus"
						label="marital Status"
						options={AdmissionSheetDataOptions.maritalStatus}
						control={control}
						errors={errors}
						required
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.holdersNumber && (
				<Grid item xs={6}>
					<Select
						row
						required
						name="holdersNumber"
						label="Holder Status"
						helperText={holderStatusHelperText}
						options={holderStatusOptions}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.numberDependents && (
				<Grid item xs={6}>
					<Input
						name="dependentsNumber"
						label="Number of Dependents"
						required
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.taxZone && (
				<Grid item xs={6}>
					<Select
						required
						name="taxZone"
						label="TAX ZONE"
						options={taxZoneSelect}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default FamilyInfo;
