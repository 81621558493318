import * as yup from 'yup';

import { IAdmissionSheetPayload } from 'typings/store/admin/admissions';
import {
	string,
	number,
	stringRequired,
	stringLongRequired,
	dateRequired,
	dateDefault,
	phoneNumberRequired,
	zipCodeRequired,
	booleanRequired,
	carLicensePlateRequired,
	ccRequired,
	nifRequired,
	nissRequired,
	ibanRequired,
	numberRequired
} from 'lib/utils/formUtils';

export const createYupSchema = (formFieldsConfig: any, isEmployee: boolean) => {
	const digitsOnly = value => /^\d+$/.test(value);

	if (formFieldsConfig?.country == 'HR') {
		return yup.object().shape({
			fullName: formFieldsConfig?.fullName ? stringRequired : string,
			placeOfBirth: formFieldsConfig?.placeOfBirth ? stringRequired : string,
			gender: formFieldsConfig?.gender ? stringRequired : string,
			dateOfBirth: formFieldsConfig?.dateOfBirth ? dateRequired : yup.date(),
			validatePlate: yup.bool(),
			// eslint-disable-next-line func-names
			licensePlate: formFieldsConfig?.licensePlate
				? yup.string().when('validatePlate', {
						is: true,
						then: carLicensePlateRequired,
						otherwise: yup.string()
				  })
				: string,
			address: formFieldsConfig?.address ? stringRequired : string,
			mobilePhone: formFieldsConfig?.mobilePhone ? phoneNumberRequired : string,
			city: formFieldsConfig?.city ? stringRequired : string,
			zipCode: formFieldsConfig?.zipCode ? zipCodeRequired : string,
			emergencyContact: formFieldsConfig?.emergencyContact ? phoneNumberRequired : string,
			emergencyContactName: formFieldsConfig?.emergencyContactName ? stringRequired : string,
			email: formFieldsConfig?.email ? stringRequired.email('Must be a valid email') : string,
			nationality: formFieldsConfig?.nationality ? stringRequired : string,

			docType: formFieldsConfig?.idDocumentType ? stringRequired : string,
			fiscalNumber: formFieldsConfig?.fiscalNumber
				? stringRequired
						.when('docType', {
							is: 'CC',
							then: nifRequired.test(
								'duplicatedFiscalNumber',
								'This fiscal number is already in use.',
								function (payload) {
									return !this.parent.allFiscalNumber?.includes(payload);
								}
							),
							otherwise: stringRequired.test(
								'duplicatedFiscalNumber',
								'This fiscal number is already in use.',
								function (payload) {
									return !this.parent.allFiscalNumber?.includes(payload);
								}
							)
						}) // eslint-disable-next-line func-names
						.test('duplicatedFiscalNumber', 'This fiscal number is already in use.', function (payload) {
							return !this.parent.allFiscalNumber?.includes(payload);
						})
				: string,
			citizenCardNumber: formFieldsConfig?.citizenCardNumber
				? stringRequired
						.when('docType', {
							is: 'CC',
							then: string
								.max(14, 'This field exceeds the maximum limit (14)')
								.test(
									'duplicatedCitizenCardNumber',
									'This citizen card number is already in use.',
									function (payload) {
										return !this.parent.allCitizenCardNumber?.includes(payload);
									}
								)
						}) // eslint-disable-next-line func-names
						.test(
							'duplicatedCitizenCardNumber',
							'This citizen card number is already in use.',
							function (payload) {
								return !this.parent.allCitizenCardNumber?.includes(payload);
							}
						)
				: string,
			pensionFundNumber: formFieldsConfig?.pensionFund
				? stringRequired
						.test('Digits only', 'The field should have digits only', digitsOnly)
						.max(11, 'This field exceeds the maximum limit (11)')
						.min(11, 'This field must have (11) digits')
						.test(
							'duplicatedPensionFundNumber',
							'This pension fund number is already in use.',
							function (payload) {
								return !this.parent.allPensionFundNumber?.includes(payload);
							}
						)
				: string,
			hasIDDoc: formFieldsConfig?.hasIdDoc ? stringRequired : string,
			hasHadWithoutTermContract: yup.bool(),
			ageOnContractStartDate: formFieldsConfig?.ageOnContractStartDate ? stringRequired : string,
			showContractCheckbox: yup.bool(),
			citizenCardValidityDate: formFieldsConfig?.citizenCardValidityDate ? dateRequired : string,
			maritalStatus: formFieldsConfig?.maritalStatus ? stringRequired : string,
			holdersNumber: formFieldsConfig?.holdersNumber ? stringRequired : string,
			dependentsNumber: formFieldsConfig?.numberDependents ? numberRequired : number,
			taxZone: formFieldsConfig?.taxZone ? stringRequired : string,
			courseQualificationId: formFieldsConfig?.courseQualificationId ? stringRequired : string,
			courseInstitution: formFieldsConfig?.courseInstitution ? stringRequired : string,
			educationCode: formFieldsConfig?.educationCode ? stringRequired : string,
			courseStartDate: formFieldsConfig?.courseStartDate ? dateRequired : string,
			educationStatus: formFieldsConfig?.educationStatus ? stringRequired : string,
			courseEndDate: formFieldsConfig?.courseEndDate
				? dateDefault.when('educationStatus', {
						is: 'Ongoing',
						then: dateDefault,
						otherwise: dateRequired
				  })
				: string,
			courseName: formFieldsConfig?.courseName
				? stringRequired.max(100, 'This field exceeds the maximum limit (100)')
				: string,
			careerStartDate: formFieldsConfig?.careerStartDate ? dateRequired : string,
			coursePostBologna: formFieldsConfig?.coursePostBologna ? booleanRequired : string,
			bankId: formFieldsConfig?.bankId ? stringRequired : string,
			bankNib: formFieldsConfig?.bankNumber ? ibanRequired : string,
			hasBankDoc: formFieldsConfig?.hasBankDoc ? stringRequired : string,
			professionalExperience: formFieldsConfig?.professionalExperience ? stringLongRequired : string,
			biometricSystemsConsent: booleanRequired,
			intranetSystemsConsent: booleanRequired,
			promotionalMediaConsent: booleanRequired
		});
	}

	return yup.object().shape({
		fullName: formFieldsConfig?.fullName ? stringRequired : string,
		placeOfBirth: formFieldsConfig?.placeOfBirth ? stringRequired : string,
		gender: formFieldsConfig?.gender ? stringRequired : string,
		dateOfBirth: formFieldsConfig?.dateOfBirth ? dateRequired : yup.date(),
		validatePlate: yup.bool(),
		// eslint-disable-next-line func-names
		licensePlate: formFieldsConfig?.licensePlate
			? yup.string().when('validatePlate', {
					is: true,
					then: carLicensePlateRequired,
					otherwise: yup.string()
			  })
			: string,
		address: formFieldsConfig?.address ? (!isEmployee ? string : stringRequired) : string,
		mobilePhone: formFieldsConfig?.mobilePhone ? phoneNumberRequired : string,
		city: formFieldsConfig?.city ? (!isEmployee ? string : stringRequired) : string,
		zipCode: formFieldsConfig?.zipCode ? (!isEmployee ? string : zipCodeRequired) : string,
		emergencyContact: formFieldsConfig?.emergencyContact ? (!isEmployee ? string : phoneNumberRequired) : string,
		emergencyContactName: formFieldsConfig?.emergencyContactName ? (!isEmployee ? string : stringRequired) : string,
		email: formFieldsConfig?.email ? stringRequired.email('Must be a valid email') : string,
		nationality: formFieldsConfig?.nationality ? stringRequired : string,

		docType: formFieldsConfig?.idDocumentType ? stringRequired : string,
		fiscalNumber: formFieldsConfig?.fiscalNumber
			? stringRequired
					.when('docType', {
						is: 'CC',
						then: nifRequired.test(
							'duplicatedFiscalNumber',
							'This fiscal number is already in use.',
							function (payload) {
								return !this.parent.allFiscalNumber?.includes(payload);
							}
						),
						otherwise: stringRequired.test(
							'duplicatedFiscalNumber',
							'This fiscal number is already in use.',
							function (payload) {
								return !this.parent.allFiscalNumber?.includes(payload);
							}
						)
					}) // eslint-disable-next-line func-names
					.test('duplicatedFiscalNumber', 'This fiscal number is already in use.', function (payload) {
						return !this.parent.allFiscalNumber?.includes(payload);
					})
			: string,
		citizenCardNumber: formFieldsConfig?.citizenCardNumber
			? stringRequired
					.when('docType', {
						is: 'CC',
						then: ccRequired.test(
							'duplicatedCitizenCardNumber',
							'This citizen card number is already in use.',
							function (payload) {
								return !this.parent.allCitizenCardNumber?.includes(payload);
							}
						),
						otherwise: stringRequired
							.max(14, 'This field exceeds the maximum limit (14)')
							.test(
								'duplicatedCitizenCardNumber',
								'This citizen card number is already in use.',
								function (payload) {
									return !this.parent.allCitizenCardNumber?.includes(payload);
								}
							)
					}) // eslint-disable-next-line func-names
					.test(
						'duplicatedCitizenCardNumber',
						'This citizen card number is already in use.',
						function (payload) {
							return !this.parent.allCitizenCardNumber?.includes(payload);
						}
					)
			: string,

		socialSecurityNumber: formFieldsConfig?.socialSecurityNumber
			? !isEmployee
				? string
				: string
						.when('docType', {
							is: 'CC',
							then: nissRequired.test(
								'duplicatedFiscalNumber',
								'This social security number is already in use.',
								function (payload) {
									return !this.parent.allSocialSecurityNumber?.includes(payload);
								}
							),
							otherwise: string
								.max(11, 'This field exceeds the maximum limit (11)')
								.test(
									'duplicatedFiscalNumber',
									'This social security number is already in use.',
									function (payload) {
										return !this.parent?.allSocialSecurityNumber?.includes(payload);
									}
								)
						}) // eslint-disable-next-line func-names
						.test(
							'duplicatedFiscalNumber',
							'This social security number is already in use.',
							function (payload) {
								return !this.parent.allSocialSecurityNumber?.includes(payload);
							}
						)
			: string,
		hasIDDoc: formFieldsConfig?.hasIdDoc ? (!isEmployee ? string : stringRequired) : string,
		hasHadWithoutTermContract: yup.bool(),
		ageOnContractStartDate: formFieldsConfig?.ageOnContractStartDate ? stringRequired : string,
		showContractCheckbox: yup.bool(),
		citizenCardValidityDate: formFieldsConfig?.citizenCardValidityDate ? dateRequired : string,
		maritalStatus: formFieldsConfig?.maritalStatus ? stringRequired : string,
		holdersNumber: formFieldsConfig?.holdersNumber ? stringRequired : string,
		dependentsNumber: formFieldsConfig?.numberDependents ? numberRequired : number,
		taxZone: formFieldsConfig?.taxZone ? stringRequired : string,
		courseQualificationId: formFieldsConfig?.courseQualificationId
			? !isEmployee
				? string
				: stringRequired
			: string,
		courseInstitution: formFieldsConfig?.courseInstitution ? (!isEmployee ? string : stringRequired) : string,
		educationCode: formFieldsConfig?.educationCode ? (!isEmployee ? string : stringRequired) : string,
		courseStartDate: formFieldsConfig?.courseStartDate ? (!isEmployee ? string : dateRequired) : string,
		educationStatus: formFieldsConfig?.educationStatus ? (!isEmployee ? string : stringRequired) : string,
		courseEndDate: formFieldsConfig?.courseEndDate
			? !isEmployee
				? string
				: dateDefault.when('educationStatus', {
						is: 'Ongoing',
						then: dateDefault,
						otherwise: dateRequired
				  })
			: string,
		courseName: formFieldsConfig?.courseName
			? !isEmployee
				? string
				: stringRequired.max(100, 'This field exceeds the maximum limit (100)')
			: string,
		careerStartDate: formFieldsConfig?.careerStartDate ? (!isEmployee ? string : dateRequired) : string,
		coursePostBologna: formFieldsConfig?.coursePostBologna ? (!isEmployee ? string : booleanRequired) : string,
		bankId: formFieldsConfig?.bankId ? stringRequired : string,
		bankNib: formFieldsConfig?.bankNumber ? (!isEmployee ? string : ibanRequired) : string,
		hasBankDoc: formFieldsConfig?.hasBankDoc ? (!isEmployee ? string : stringRequired) : string,
		professionalExperience: formFieldsConfig?.professionalExperience
			? !isEmployee
				? string
				: stringLongRequired
			: string,
		biometricSystemsConsent: booleanRequired,
		intranetSystemsConsent: booleanRequired,
		promotionalMediaConsent: booleanRequired
	});
};

export const defaultValues: IAdmissionSheetPayload = {
	disabled: false,
	admissionProcessId: null,
	company: '',
	bankIdName: '',
	genderName: '',
	id: null,
	idDocumentType: '',
	idDocumentTypeName: '',
	nationalityName: '',
	bankName: '',
	courseQualificationIdName: '',
	educationCodeName: '',

	fullName: '',
	placeOfBirth: '',
	gender: '',
	dateOfBirth: '',
	licensePlate: '',
	validatePlate: false,
	address: '',
	mobilePhone: '',
	city: '',
	zipCode: '',
	emergencyContact: '',
	emergencyContactName: '',
	email: '',
	nationality: '',
	docType: '',
	allFiscalNumber: [''],
	fiscalNumber: '',
	allCitizenCardNumber: [''],
	citizenCardNumber: '',
	allSocialSecurityNumber: [''],
	socialSecurityNumber: '',
	pensionFundNumber: '',
	allPensionFundNumber: [''],
	citizenCardValidityDate: '',
	hasIDDoc: '',
	hasHadWithoutTermContract: false,
	ageOnContractStartDate: 'N/A',
	showContractCheckbox: false,
	maritalStatus: '',
	holdersNumber: '',
	dependentsNumber: '0',
	taxZone: '',
	courseQualificationId: '',
	courseInstitution: '',
	educationCode: '',
	courseStartDate: '',
	educationStatus: '',
	courseEndDate: '',
	courseName: '',
	careerStartDate: '',
	coursePostBologna: false,
	bankId: '',
	bankNib: '',
	hasBankDoc: '',
	professionalExperience: '',
	biometricSystemsConsent: true,
	intranetSystemsConsent: true,
	promotionalMediaConsent: true
};

export const tabs = [
	{
		id: 0,
		label: 'General info'
	},
	{
		id: 1,
		label: 'Contact info'
	},
	{
		id: 2,
		label: 'ID info'
	},
	{
		id: 3,
		label: 'Family aggregate info'
	},
	{
		id: 4,
		label: 'Qualification info'
	},
	{
		id: 5,
		label: 'Banking info'
	},
	{
		id: 6,
		label: 'GDPR Consents'
	}
];
