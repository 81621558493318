import { OriginalResponse } from 'lib/handlers/requestTypes';

export enum AppPageActions {
	SET_LOADING = '@@general/SET_LOADING',
	SET_ERROR = '@@general/SET_ERROR',
	GET_APPS_LIST = '@@general/GET_APPS_LIST'
}

export interface IApp {
	id: number;
	searchKey: string;
	title: string;
	url: string;
}

export type AppPageState = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly appsList: IApp[] | null;
};
