import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { clearPersonalInfoError, getUserCareer } from 'store/personalInfo/thunks';
import { AppState } from 'typings/state';

import MyCareer from './Career';

const mapStateToProps = ({ personalInfo, company }: AppState) => ({ personalInfo, company });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ clearPersonalInfoError, getUserCareer }, dispatch);

export type MyCareerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyCareer);
