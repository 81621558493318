/* eslint-disable camelcase */
import { OriginalResponse } from 'lib/handlers/requestTypes';
import { IChoosableBaseInfo } from '../generalTypes';
import { ICompanyInfo } from './proposals';

export enum ProjectsAndClientsActions {
	SET_LOADING = '@@administration/projectsAndClients/SET_LOADING',
	SET_ERROR = '@@personalInfo/SET_ERROR',
	GET_NEW_CLIENT = '@@administration/projectsAndClients/GET_NEW_CLIENT',
	POST_NEW_CLIENT = '@@administration/admissions/POST_NEW_CLIENT',
	GET_NEW_PROJECT = '@@administration/projectsAndClients/GET_NEW_PROJECT',
	POST_NEW_PROJECT = '@@administration/admissions/POST_NEW_PROJECT'
}

export type ProjectsAndClientsState = {
	readonly loading: boolean;
	readonly error: OriginalResponse | null;
	readonly newClient: INewClientInfo | null;
	readonly newClientAction: OriginalResponse | null;
	readonly fetchedNewProjectData: INewProjectInfo | null;
	readonly newProjectAction: OriginalResponse | null;
};

export interface INewClientInfo {
	companies: ICompanyInfo[];
	clientGroups: IClientGroup[];
}

export interface ISalesRepresentativesInfo {
	id: string;
	searchKey: string;
	name: string;
	xhprDisable: boolean;
	lastCscInProgressStatus: number;
}

export interface IClientGroup {
	searchKey: string;
	name: string;
	id: string;
}

export interface INewClient {
	company: string;
	action: string;
	name: string;
	searchKey: string;
	fiscalCode: string;
	group: string;
	salesRepresentative: string;
	priceList: string;
	addressAction: string;
	address: string;
	paymentTerm: string;
	zipCode: string;
	city: string;
	country: string;
	invoiceContactAction: string | null;
	invoiceContactName: string | null;
	invoiceContactEmail: string | null;
	invoiceContactPhone: string | null;
	invoiceContactFax: string | null;
	invoiceContactPosition: string | null;
	envelopeContactAction: string | null;
	envelopeContactIsDefault: boolean;
	envelopeContactName: string | null;
	envelopeContactEmail: string | null;
	envelopeContactPhone: string | null;
	envelopeContactFax: string | null;
}

export interface INewProjectInfo {
	timesheetApprovalTypes: IChoosableBaseInfo[];
	companies: ICompanyInfo[];
	hubDistributionSchemes: IChoosableBaseInfo[];
	costTypes: IChoosableBaseInfo[];
	projectSalesTypes: IChoosableBaseInfo[];
	users: IUsers[];
}
export interface ICareerProfiles {
	id: string;
	active: boolean;
	startDate: string;
	endDate: string;
	name: string;
	eligibleDepartments?: IEligibleDepartments[];
}

export interface IEligibleDepartments {
	id: string;
	active: boolean;
	xphrProfile: string;
	orgBunit: string;
	orgDivision: string;
	orgDepartment: string;
	eligibleJobRoles?: IEligibleJobRoles[];
}
export interface IEligibleJobRoles {
	id: string;
	active: boolean;
	name: string;
	xphrProfile: string;
	xphrJobrole: string;
	xphrProfileEligibleDep: string;
	levels: IJobRoleLevels[];
}

export interface IJobRoleLevels {
	id: string;
	active: boolean;
	xphrProfileEligibleRole: string;
	name: string;
	careerLevel: string;
	careerSubLevel: string;
	place: string;
	units: number;
	displayOrder: number;
	xphrSalaryBand: string;
	availableOnProjRequest: boolean;
}

export interface ICompanyTree {
	node: string;
	reportSet: string;
	organization: string;
	childNodes?: ICompanyTree[];
	organization$_identifier: string;
}

export interface IUsers {
	username: string;
	email: string;
	firstName: string;
	lastName: string;
	department: string;
	officeName: string;
	phone: string[];
	companyCode: string;
}

export interface INewProject {
	company: string;
	createForPT: boolean;
	projectCode: string;
	name: string;
	startDate: string;
	plannedEndDate: string;
	type: string;
	proposalReference: string | null;
	ownership?: string;
	businessUnit?: string;
	division?: string;
	department: string;
	lineOfBusiness: string;
	costType: string;
	hubDistributionScheme: string;
	salesType: string | null;
	sponsorName: string | null;
	sponsorEmail: string | null;
	isTimeAndMaterials: boolean;
	applications: string[];
	isXpAgileCompliant: boolean;
	isMultiUnitProject: boolean;
	template: string | null;
	teamLead: string;
	projectManager: string;
	accountManager: string;
	timesheetApproval: string;
	expenseApproval: string;
	team: string[];
	teamCompanies: string[];
	repositories: IProjectRepositories[] | null;
	hasExternalUsers: boolean;
	externalUsers: IProjectExternalUsers[];
	hasBudget: boolean;
	budget: IProjectBudgets[];
	comments: string | null;
	isPtOwnership?: boolean;
	isUkOwnership?: boolean;
}
export interface IProjectRepositories {
	type: string;
	name: string;
}
export interface IProjectExternalUsers {
	name: string;
	company: string;
	email: string;
}

export interface IProjectBudgets {
	profile: string;
	days: string;
	dailyRate: string;
	role: string;
	level: string;
}
