import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, POST, PUT, DELETE, EXPORTFILE } from 'lib/handlers/requestTypes';
import { IBudgetPostPayload } from 'typings/store/admin/budget';

//* TABLE - GET LIST
export const fetchBudgetTable = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/budget/list');

//* TABLE - GET AUDIT
export const fetchBudgetAudit = async (id: number): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/budget/audit/${id}`);

//* TABLE - GET NEW BUDGET
export const fetchPOSTNewBudget = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/budget/new');

//* TABLE - POST NEW BUDGET
export const sendPOSTNewBudget = async (payload: IBudgetPostPayload): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/budget/new', payload);

//* TABLE - DELETE
export const sendBudgetDelete = async (id: number): Promise<OriginalResponse | Success> =>
	restCall(DELETE, `/admin/budget/delete/${id}`);

//* TABLE - PUT - BI REFRESH
export const sendPUTBudgetBiRefresh = async (): Promise<OriginalResponse> =>
	restCall(PUT, '/admin/budget/biRefresh', {}) as unknown as OriginalResponse;

//* TABLE - EXPORT
export const sendExportBudget = async (id: number): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILE, `/admin/budget/export/${id}`);

//*-----------------------------------------------

//* EDIT BUDGET - GET
export const fetchBudgetInfo = async (id: number): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/budget/edit/${id}`);

//* EDIT BUDGET - PUT
export const sendPUTNewBudget = async (id: number, payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(PUT, `/admin/budget/edit/${id}`, payload);

//* EDIT BUDGET - GET PROJECT LIST
export const fetchBudgetDepartmentProjects = async (department: string): Promise<OriginalResponse | Success> =>
	restCall(GET, `/admin/budget/loadProjects/${department}`);

//* IMPORT BUDGET
export const postImportBudget = async (payload: Document | FormData | null): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/budget/import', payload);
