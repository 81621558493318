/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uuidv4 } from 'uuid';

//* EXTERNAL LIBS --> MUI
import { Typography, Grid, Button } from '@mui/material';
import { PriorityHigh, Clear, Add } from '@mui/icons-material';
import Adicionar from '../../../../assets/icons/Adicionar.svg';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, NoData, ActionFooter, TabPanel, TabHeader, PageTitle, Tab, Dialog } from 'xpand-ui/core';
import { compareValues } from 'xpand-ui/utils/sort';

//* TYPINGS
import {
	IResume,
	IPersonalInfo,
	IResumeEducation,
	IResumeWorkExperience,
	IResumeWorkshop,
	IResumeTraining,
	IResumeOtherProjects,
	IPostResumePayload,
	ISkillGroup,
	IUserResume
} from 'typings/store/personalInfoTypes';
import { IUserToken } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { getCookie, getLSField, setCookie } from 'lib/utils/cookies';
import { addNotification } from 'lib/utils/notifications';

//* LOCAL COMPONENT IMPORTS
import { Prompt } from 'react-router';
import { addGroupsToStruct, filterOnlyUserSkills } from '../Skills/utils';
import {
	tabs,
	PersonalInfoCard,
	EducationInfoCard,
	WorkExperienceInfoCard,
	WorkshopsInfoCard,
	OtherProjectsInfoCard,
	TrainingInfoCard,
	SkillsInfoCard
} from './utils';
import { schema } from './yupSchema';
import { useStyles } from './styles';
import { MyResumeProps } from '.';
import { Scrollbars } from 'react-custom-scrollbars-2';

//* COMPONENT INTERFACES
interface IMyResume extends MyResumeProps {
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
}

let isCreatingFormInitialState = true;
const initForm: IPostResumePayload = {
	personalInfo: [] as IPersonalInfo[],
	education: [] as IResumeEducation[],
	workExperience: [] as IResumeWorkExperience[],
	workshops: [] as IResumeWorkshop[],
	otherProjects: [] as IResumeOtherProjects[],
	training: [] as IResumeTraining[]
};

const removeOpenEntry = (name: string, id: number | string) => {
	const itemName = `${name}.${id}`;

	const resumeOpenState = getCookie('resumeOpenState');
	const resumeState: { [name: string]: boolean } = resumeOpenState ? JSON.parse(resumeOpenState) : {};

	delete resumeState[itemName];

	setCookie('resumeOpenState', JSON.stringify({ ...resumeState }));
};

const notificationPayload = {
	area: 'My Profile',
	section: 'Resume'
};

//* COMPONENT
const MyResume: FC<IMyResume> = ({
	goToPage,
	loggedUser,
	personalInfo,
	company,
	getUserResume,
	sendUserResume,
	clearPersonalInfoError,
	setNotificationAsRead
}) => {
	const classes = useStyles();
	const { loading, error, userResume } = personalInfo;
	const username = loggedUser && loggedUser.username;

	const [submitted, setSubmitted] = useState(false);
	// TABS COMPONENT - State
	const [tabSelected, setTabSelected] = useState(0);
	const scrollbarRef = React.createRef();
	const [userSkillsForm, setSkillsFormData] = useState<any>({});
	const [systemSkillsData, setSystemSkillsData] = useState<any>(null);

	// CONFIRMATION DIALOG HELPER
	const [openConfirmationModal, setOpenConfirmationModal] = useState<{
		isOpen: boolean;
		name?: string;
		index?: number;
		id?: string | number;
	}>({ isOpen: false });

	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	}, [tabSelected]);

	const {
		handleSubmit,
		control,
		formState: { errors, isDirty },
		watch,
		setValue,
		reset
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues: initForm,
		shouldUnregister: false
	});

	const { fields: PersonalInfoFields } = useFieldArray({ control, name: 'personalInfo' });

	const {
		fields: educationFields,
		append: educationAppend,
		remove: educationRemove
	} = useFieldArray({ control, name: 'education' });

	const {
		fields: workExperienceFields,
		append: workExperienceAppend,
		remove: workExperienceRemove
	} = useFieldArray({ control, name: 'workExperience' });
	const {
		fields: workshopsFields,
		append: workshopsAppend,
		remove: workshopsRemove
	} = useFieldArray({ control, name: 'workshops' });
	const {
		fields: otherProjectsFields,
		append: otherProjectsAppend,
		remove: otherProjectsRemove
	} = useFieldArray({ control, name: 'otherProjects' });
	const {
		fields: trainingFields,
		append: trainingAppend,
		remove: trainingRemove
	} = useFieldArray({ control, name: 'training' });

	const resetComponentOnUnmount = () => {
		// reset default component values
		isCreatingFormInitialState = false;
		submitted && setSubmitted(false);
	};

	// fetch user Resume
	useEffect(() => {
		if ((!userResume && !error) || isCreatingFormInitialState) {
			getUserResume();
			resetComponentOnUnmount();
		}
	}, [userResume, submitted]);

	const getDefaultValueCountry = useMemo(
		() => (payload: IResume) => {
			if (payload.nativeLanguage === 'PORTUGUESE') return 'PORTUGUESE (PORTUGAL)';
			if (payload.nativeLanguage === 'FRENCH') return 'FRENCH (FRANCE)';
			if (payload.nativeLanguage === 'SPANISH') return 'SPANISH (SPAIN)';

			return payload.nativeLanguage;
		},
		[]
	);

	const prepareInitialInfo = useMemo(
		() => (resumePayload: IUserResume) =>
			(resumePayload.initialData && {
				personalInfo: [
					{
						address: resumePayload.initialData.location,
						gender: resumePayload.initialData.gender,
						phone: resumePayload.initialData.phone,
						birthday: resumePayload.initialData.birthday,
						nativeLanguage: resumePayload.initialData.nationality === 'PT' ? 'PORTUGUESE (PORTUGAL)' : '',
						countryOrigin: resumePayload.initialData.nationality === 'PT' ? 'Portugal' : '',
						countryCode: resumePayload.initialData.nationality,
						birthplace: '',
						description: ''
					}
				],
				education: [],
				workExperience: [],
				workshops: [],
				otherProjects: [],
				training: []
			}) || {
				personalInfo: [
					{
						...(({ education, otherProjects, training, workExperience, workshops, ...o }) => o)(
							resumePayload.resume
						),
						nativeLanguage: getDefaultValueCountry(resumePayload.resume)
					}
				],
				education: resumePayload.resume.education
					.sort(compareValues('order'))
					.map(e => ({ ...e, saved: true })),
				workExperience: resumePayload.resume.workExperience
					.sort(compareValues('order'))
					.map(e => ({ ...e, saved: true })),
				workshops: resumePayload.resume.workshops
					.sort(compareValues('order'))
					.map(e => ({ ...e, saved: true })),
				otherProjects: resumePayload.resume.otherProjects
					.sort(compareValues('order'))
					.map(e => ({ ...e, saved: true })),
				training: resumePayload.resume.training.sort(compareValues('order')).map(e => ({ ...e, saved: true }))
			},
		[]
	);

	useEffect(() => {
		if (userResume) {
			const newFormData = prepareInitialInfo(userResume);

			if (userResume.systemSkills) {
				const payload = {};
				const sysPayload = {};
				userResume.systemSkills.map(a => {
					// create area level
					const { toRender } = addGroupsToStruct(
						a.skillGroups as ISkillGroup[],
						userResume.userSkills,
						true,
						true
					);
					if (a.id) payload[a.id] = { ...toRender };
					if (a.name) sysPayload[a.name] = a;
					return a;
				});

				setSkillsFormData((prev: any) => ({ ...prev, ...payload }));
				setSystemSkillsData((prev: any) => ({ ...prev, ...sysPayload }));
			}

			const updateForm = { ...initForm, ...newFormData };

			setTimeout(() => {
				reset(updateForm);
				resetComponentOnUnmount();
			}, 1000);
			reset(updateForm);
		}
	}, [userResume, submitted]);

	//Creates an object with all skills changed by the user.
	let skills = {};
	for (let skill in userSkillsForm) {
		for (let skillName in systemSkillsData) {
			if (systemSkillsData[skillName].id == skill) {
				let skillArea = filterOnlyUserSkills(
					userSkillsForm[skill]!,
					{},
					systemSkillsData[skillName].skillGroups
				);
				skills = {
					...skills,
					[skill]: skillArea
				};
			}
		}
	}

	const addFormItem = () => {
		if (tabSelected !== 6) {
			const newPayload = { ...tabs[tabSelected].emptyObject, id: uuidv4() };
			tabs[tabSelected].id === 'education' && educationAppend(newPayload);
			tabs[tabSelected].id === 'workExperience' && workExperienceAppend(newPayload);
			tabs[tabSelected].id === 'workshops' && workshopsAppend(newPayload);
			tabs[tabSelected].id === 'otherProjects' && otherProjectsAppend(newPayload);
			tabs[tabSelected].id === 'training' && trainingAppend(newPayload);
		} else {
			goToPage('/skills/soft_2');
		}
	};
	const removeFormItem = (name: string, index: number, id: number | string) => {
		name === 'education' && educationRemove(index);
		name === 'workExperience' && workExperienceRemove(index);
		name === 'workshops' && workshopsRemove(index);
		name === 'otherProjects' && otherProjectsRemove(index);
		name === 'training' && trainingRemove(index);

		removeOpenEntry(name, id);
	};

	const onSubmitModalForm = handleSubmit(async () => {
		const data = watch();
		if (data.personalInfo.length > 0 && data.education.length > 0) {
			reset({});
			setSubmitted(true);
			// remove all null items from the final submit object
			const submitPayload = {
				personalInfo: data.personalInfo.map((e: any, i: number) => ({ ...e, order: i })),
				education: data.education.map(({ id, ...e }: any, i: number) => ({
					...(typeof id === 'string' ? {} : { id }),
					...e,
					order: i
				})),
				workExperience: data.workExperience.map(({ id, ...e }: any, i: number) => ({
					...(typeof id === 'string' ? {} : { id }),
					...e,
					order: i
				})),
				workshops: data.workshops.map(({ id, ...e }: any, i: number) => ({
					...(typeof id === 'string' ? {} : { id }),
					...e,
					order: i
				})),
				otherProjects: data.otherProjects.map(({ id, ...e }: any, i: number) => ({
					...(typeof id === 'string' ? {} : { id }),
					...e,
					order: i
				})),
				training: data.training.map(({ id, ...e }: any, i: number) => ({
					...(typeof id === 'string' ? {} : { id }),
					...e,
					order: i
				}))
			};
			sendUserResume(submitPayload);
			setNotificationAsRead(username, notificationPayload);
		} else addNotification('warning', 'You need to have at least one education entry', 15);
	});

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			tabs.map(e => {
				if (errors[e.id]) addNotification('danger', `Fix error on ${e.label} Tab before Submit.\n`, 10);
				return null;
			});
		}
	}, [errors]);

	const footerActions = [
		{
			id: 'submit',
			endIcon: 'submit',
			label: !userResume?.resume ? 'Submit' : 'Update',
			onClick: () => ({}),
			type: 'submit',
			form: 'form-resume',
			variant: 'contained',
			disabled: getLSField('impersonate_userInfo')
		}
	];

	const addButton = (
		<>
			{tabSelected !== 6 ? (
				<Button color="primary" endIcon={<Adicionar />} onClick={addFormItem}>
					Add {tabs[tabSelected].label}
				</Button>
			) : (
				''
			)}
		</>
	);

	const showTopAddButton = () => {
		if (tabSelected === 0) return false;
		if (tabSelected === 1) return educationFields.length > 0;
		if (tabSelected === 2) return workExperienceFields.length > 0;
		if (tabSelected === 3) return workshopsFields.length > 0;
		if (tabSelected === 4) return otherProjectsFields.length > 0;
		if (tabSelected === 5) return trainingFields.length > 0;
		if (tabSelected === 6) return true;

		return false;
	};

	// Cards Remove Button Action
	const removeChildAction = (name: string, index: number, id: string | number) => [
		{
			id: `${name}.${index}_remove_action`,
			label: '',
			icon: <Clear />,
			onClick: () => setOpenConfirmationModal({ isOpen: true, name, index, id }) // removeFormItem(name, index)
		}
	];

	const confirmActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenConfirmationModal({ isOpen: false })
		},
		{
			id: 'delete',
			label: 'Delete',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				removeFormItem(
					openConfirmationModal.name as string,
					openConfirmationModal.index as number,
					openConfirmationModal.id as number | string
				);
				setOpenConfirmationModal({ isOpen: false });
			}
		}
	];

	const countriesList = useMemo(
		() =>
			userResume &&
			userResume.countries
				.filter(e => e.language$_identifier !== null)
				.sort(compareValues('language$_identifier'))
				.filter((v, i, a) => a.findIndex(t => t.language$_identifier === v.language$_identifier) === i)
				.map(e => ({ ...e, name: e.language$_identifier?.toUpperCase() })),
		[userResume]
	);

	if (error) return handleErrorPage(error, clearPersonalInfoError);

	const isLoading = userResume === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{(loading || submitted) && <LoadingOverlay />}
			<form id="form-resume" onSubmit={onSubmitModalForm} style={{ width: '100%' }}>
				<Grid
					container
					direction="column"
					justifyContent="space-evenly"
					alignItems="flex-start"
					overflow="hidden"
					spacing={2}
					className={classes.root}>
					{/* STATE LABEL */}
					<Grid item xs={12} className={classes.rows} style={{ minHeight: '65px' }}>
						{tabSelected < 2 && (
							<Typography component="span" className={classes.infoLabel}>
								<PriorityHigh color="primary" />
								The tabs Personal Information and Education are mandatory
							</Typography>
						)}
					</Grid>

					{/* CONTAINER TITLE AND ACTIONS */}
					<Grid item xs={12} className={classes.rows}>
						<PageTitle
							asItem={false}
							title={
								<Typography
									gutterBottom
									style={{ fontSize: '18px', fontWeight: 'bold', color: 'grey' }}>
									CV REF.-{company.companyAbbreviation}.{username.toUpperCase()}
								</Typography>
							}
						/>
					</Grid>

					<Grid item xs={12} className={classes.rows}>
						<TabHeader
							selected={tabSelected}
							className={classes.letterSpacing}
							handleSelected={(event: React.SyntheticEvent<Element, Event>, newValue: number) =>
								setTabSelected(newValue)
							}
							variant="scrollable"
							scrollButtons="auto">
							{tabs.map((tab, index) => (
								<Tab key={`Resume_${tab.id}_tab_button`} label={tab.label} index={index} />
							))}
						</TabHeader>
						<Scrollbars
							ref={scrollbarRef}
							style={{ height: 'calc(100vh - 375px)', overflowX: 'hidden' }}
							// eslint-disable-next-line
							renderTrackHorizontal={(props: any) => (
								<div {...props} style={{ display: 'none' }} className="track-horizontal" />
							)}>
							{tabs.map((tab, index) => (
								<TabPanel key={`Resume_${tab.id}_tab_content`} value={tabSelected} index={index}>
									<Grid container spacing={2} direction="row" style={{ minHeight: '80px' }}>
										<Grid item xs={9}>
											<Typography
												component="span"
												className={classes.infoLabel}
												style={{ marginBottom: '50px' }}>
												{index != 0 && tab.infoMessage(classes)}
												{index == 0 && tab.infoMessage(company.companyText)}
											</Typography>
										</Grid>
										<Grid item xs={3}>
											{showTopAddButton() && (
												<div className={classes.navButtons}>{addButton}</div>
											)}
										</Grid>
									</Grid>
									<Grid
										container
										direction="column"
										justifyContent="space-evenly"
										alignItems="center"
										spacing={5}>
										{tabSelected === 0 &&
											PersonalInfoFields.map((payload, i) => (
												<PersonalInfoCard
													key={`personalInfo_${payload.id}`}
													classes={classes}
													index={i}
													selectOptions={{
														countries: countriesList
													}}
													payload={payload}
													setValue={setValue}
													control={control}
													errors={errors}
												/>
											))}
										{tabSelected === 1 && (
											<>
												{educationFields.length === 0 && <NoData action={addButton} />}
												{educationFields.map(
													(item, i) =>
														item && (
															<EducationInfoCard
																key={`education_${item.id || i}`}
																classes={classes}
																index={i}
																handleRemove={removeChildAction(
																	'education',
																	i,
																	item.id
																)}
																selectOptions={{
																	classification: userResume.courseQualifications
																}}
																payload={item}
																watch={watch}
																control={control}
																errors={errors}
															/>
														)
												)}
											</>
										)}
										{tabSelected === 2 && (
											<>
												{workExperienceFields.length === 0 && <NoData action={addButton} />}
												{workExperienceFields.map(
													(item, i) =>
														item && (
															<WorkExperienceInfoCard
																key={`workExperience_${item.id || i}`}
																classes={classes}
																index={i}
																handleRemove={removeChildAction(
																	'workExperience',
																	i,
																	item.id
																)}
																payload={item}
																watch={watch}
																control={control}
																errors={errors}
															/>
														)
												)}
											</>
										)}
										{tabSelected === 3 && (
											<>
												{workshopsFields.length === 0 && <NoData action={addButton} />}
												{workshopsFields.map(
													(item, i) =>
														item && (
															<WorkshopsInfoCard
																key={`workshops_${item.id || i}`}
																classes={classes}
																index={i}
																handleRemove={removeChildAction(
																	'workshops',
																	i,
																	item.id
																)}
																payload={item}
																watch={watch}
																control={control}
																errors={errors}
															/>
														)
												)}
											</>
										)}
										{tabSelected === 4 && (
											<>
												{otherProjectsFields.length === 0 && <NoData action={addButton} />}
												{otherProjectsFields.map(
													(item, i) =>
														item && (
															<OtherProjectsInfoCard
																key={`otherProjects_${item.id || i}`}
																classes={classes}
																index={i}
																handleRemove={removeChildAction(
																	'otherProjects',
																	i,
																	item.id
																)}
																payload={item}
																watch={watch}
																control={control}
																errors={errors}
															/>
														)
												)}
											</>
										)}
										{tabSelected === 5 && (
											<>
												{trainingFields.length === 0 && <NoData action={addButton} />}
												{trainingFields.map(
													(item, i) =>
														item && (
															<TrainingInfoCard
																key={`training_${item.id || i}`}
																classes={classes}
																index={i}
																handleRemove={removeChildAction('training', i, item.id)}
																payload={item}
																watch={watch}
																control={control}
																errors={errors}
															/>
														)
												)}
											</>
										)}
										{tabSelected === 6 && (
											<SkillsInfoCard
												classes={classes}
												formData={userSkillsForm}
												allSkills={skills}
											/>
										)}
									</Grid>
								</TabPanel>
							))}
						</Scrollbars>
					</Grid>
				</Grid>
			</form>
			<ActionFooter actions={footerActions} />
			<Dialog
				modal={{
					open: Boolean(openConfirmationModal.isOpen),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenConfirmationModal({ isOpen: false });
					},
					content: (
						<>
							<Typography gutterBottom>Are you sure you want to delete this item?</Typography>
						</>
					)
				}}
				title="Confirm action"
				actions={confirmActions}
				scroll="body"
			/>
			<Prompt when={isDirty} message="Are you sure you want to leave without saving?" />
		</>
	);
};

export default withLayout(MyResume);
