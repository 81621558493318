/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { useState, FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//* EXTERNAL LIBS --> MUI
import { Button, Typography } from '@mui/material';
import { PriorityHigh, CalendarMonth, LocationOn } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { Dialog } from 'xpand-ui/core';
import { Input } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { schema, defaultValues } from './yupSchema';
import { IUserProfile } from 'typings/store/personalInfoTypes';
import { AnyAction, Dispatch } from 'redux';
import { string } from 'prop-types';
import { getLSField } from 'lib/utils/cookies';
import { useDispatch } from 'react-redux';

interface IHealthCareConvocation {
	userProfile: IUserProfile;
	openHealthConfirmationModal: Boolean;
	setOpenHealthConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
	acceptHealthCareConvocation: (id: number, email: string) => (dispatch: Dispatch<AnyAction>) => Promise<void>;
	declineHealthCareConvocation: (id: number, payload: any) => (dispatch: Dispatch) => Promise<void>;
	setNotificationAsRead: unknown;
}

const notificationPayload = {
	area: 'My Profile',
	section: 'Personal & Fiscal Information'
};

const HealthCareConvocation: FC<IHealthCareConvocation> = ({
	userProfile,
	openHealthConfirmationModal,
	setOpenHealthConfirmationModal,
	acceptHealthCareConvocation,
	declineHealthCareConvocation,
	setNotificationAsRead
}) => {
	const classes = useStyles();
	const [openAcceptConfirmationModal, setOpenAcceptConfirmationModal] = useState(false);
	const [openDeclineConfirmationModal, setOpenDeclineConfirmationModal] = useState(false);
	const [openLocationModal, setOpenLocationModal] = useState(false);
	const [currentLocation, setCurrentLocation] = useState('');
	const [currentLocationLink, setCurrentLocationLink] = useState('');
	const dispatch = useDispatch();

	const {
		handleSubmit,
		control,
		reset,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	const openMap = (location: string, embedLink: string) => {
		setCurrentLocation(location);
		setCurrentLocationLink(embedLink);
		setOpenLocationModal(true);
	};

	const formatDate = (date: string) => {
		const [dateComponents, timeComponents] = date.split(' ');

		const [year, month, day] = dateComponents.split('-');
		const [hours, minutes, seconds] = timeComponents.split(':');
		return day + '-' + month + '-' + year + ' ' + hours + ':' + minutes;
	};

	const formatHour = (date: string) => {
		const [dateComponents, timeComponents] = date.split(' ');

		const [hours, minutes, seconds] = timeComponents.split(':');
		return hours + ':' + minutes;
	};

	const onSubmitDecline = () => {
		const comment: string = watch('declineComent');
		dispatch(setNotificationAsRead(userProfile?.user.ldapData.username, notificationPayload));
		if (comment.trim().length === 0 || comment === null) {
			addNotification(
				'danger',
				'You must send a justification to Decline this Occupational Healthcare Convocation!'
			);
		} else {
			dispatch(declineHealthCareConvocation(userProfile?.pendingConvocation?.id, { comment: comment }));

			reset();
			setOpenDeclineConfirmationModal(false);
			setOpenHealthConfirmationModal(false);
		}
	};

	const onSubmitAccept = () => {
		dispatch(acceptHealthCareConvocation(userProfile?.pendingConvocation?.id, userProfile?.user.ldapData.email));
		dispatch(setNotificationAsRead(userProfile?.user.ldapData.username, notificationPayload));
		setOpenAcceptConfirmationModal(false);
		setOpenHealthConfirmationModal(false);
	};
	return (
		<>
			<Button
				color="warning"
				onClick={() => {
					setOpenHealthConfirmationModal(true);
				}}
				className={classes.paddingAjust}>
				<PriorityHigh color="warning" />
				<Typography component="span" className={classes.fontSizeAjust}>
					You have a pending Occupational HealthCare Convocation. Please, click here to answer the
					Convocation!
				</Typography>
			</Button>
			<Dialog
				title="Occupation HealthCare Convocation"
				modal={{
					open: openHealthConfirmationModal,
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenHealthConfirmationModal(false);
					},
					content: (
						<>
							{userProfile?.pendingConvocation?.locations.map(loc => (
								<>
									<Typography component="span" variant="body1" className={classes.calendar}>
										<CalendarMonth className={classes.iconGrayed} style={{ marginRight: '10px' }} />
										{formatDate(loc.startDate) + ' - ' + formatHour(loc.endDate)}
									</Typography>
									<Button
										onClick={() => {
											openMap(loc.location, loc.linkEmbed);
										}}
										className={classes.contentLeft}>
										<Typography component="span" variant="body1" className={classes.location}>
											<LocationOn
												className={classes.iconGrayed}
												style={{ marginRight: '10px' }}
											/>
											{loc.location}
										</Typography>
									</Button>
								</>
							))}
						</>
					)
				}}
				actions={[
					{
						id: 'close',
						label: 'Close',
						color: 'secondary',
						variant: 'text',
						onClick: () => setOpenHealthConfirmationModal(false)
					},
					{
						id: 'declineHealthConfirmationModal',
						label: 'Decline',
						color: 'error',
						variant: 'contained',
						onClick: () => {
							setOpenDeclineConfirmationModal(true);
						},
						disabled: getLSField('impersonate_userInfo')
					},
					{
						id: 'acceptHealthConfirmationModal',
						label: 'Accept',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							setOpenAcceptConfirmationModal(true);
						},
						disabled: getLSField('impersonate_userInfo')
					}
				]}
			/>
			<Dialog
				title={currentLocation}
				modal={{
					open: Boolean(openLocationModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenLocationModal(false);
					},
					content: <iframe src={currentLocationLink} width="100%" height="400px"></iframe>
				}}
				actions={[
					{
						id: 'close',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setOpenLocationModal(false)
					}
				]}
				scroll="body"
			/>
			<Dialog
				modal={{
					open: Boolean(openAcceptConfirmationModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenAcceptConfirmationModal(false);
					},
					content: (
						<Typography gutterBottom>
							Are you sure you want to accept this Occupational Healthcare Convocation?
						</Typography>
					)
				}}
				title="Accept Occupational Healthcare Convocation"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setOpenAcceptConfirmationModal(false)
					},
					{
						id: 'confirm',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							onSubmitAccept();
						}
					}
				]}
				scroll="body"
			/>
			<Dialog
				modal={{
					open: Boolean(openDeclineConfirmationModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setOpenDeclineConfirmationModal(false);
					},
					content: (
						<Input
							label="Please specify a new date for reschedule!"
							name="declineComent"
							multiline
							minRows={5}
							maxRows={8}
							control={control}
							errors={errors}
							required
						/>
					)
				}}
				title="Decline Occupational Healthcare Convocation"
				actions={[
					{
						id: 'cancel',
						label: 'Cancel',
						color: 'secondary',
						variant: 'text',
						onClick: () => setOpenDeclineConfirmationModal(false)
					},
					{
						id: 'confirm',
						label: 'Confirm',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							onSubmitDecline();
						}
					}
				]}
				scroll="body"
			/>
		</>
	);
};

HealthCareConvocation.defaultProps = {
	userProfile: undefined,
	openHealthConfirmationModal: undefined,
	setOpenHealthConfirmationModal: undefined
};

export default HealthCareConvocation;
