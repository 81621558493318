import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { clearPersonalInfoError, getUserFinancialDocuments, getUserDocumentByName } from 'store/personalInfo/thunks';
import { setMyFinancialDocumentsPageFilter } from 'store/filters/actions';
import { AppState } from 'typings/state';

import MyFinancialDocuments from './Documents';

const mapStateToProps = ({ personalInfo, filters }: AppState) => ({ personalInfo, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{ clearPersonalInfoError, getUserFinancialDocuments, getUserDocumentByName, setMyFinancialDocumentsPageFilter },
		dispatch
	);

export type MyFinancialDocumentsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyFinancialDocuments);
