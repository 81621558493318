import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		display: 'flex',
		padding: '50px 175px',
		minWidth: '100%',
		alignItems: 'center',
		flexDirection: 'column',
		margin: '0 auto 90px auto'
	},
	animation: {
		visibility: 'hidden',
		opacity: '0',
		transition: 'all 0.3s ease'
	},
	show: {
		visibility: 'visible',
		opacity: 1,
		display: 'block'
	},
	infoLabel: {
		fontSize: '16px',
		display: 'flex',
		color: theme.grayedColor
	},
	infoField: {
		fontSize: '12px',
		color: '#718FA2'
	},
	link: {
		fontSize: '10px'
	},
	gridItemNoSpacing: {
		paddingBottom: '0px !important'
	}
}));

export { useStyles };
