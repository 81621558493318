import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { clearPersonalInfoError, getUserTrainings } from 'store/personalInfo/thunks';
import { setMyTrainingsPageFilter } from 'store/filters/actions';

import { AppState } from 'typings/state';

import MyTraining from './Training';

const mapStateToProps = ({ personalInfo, filters }: AppState) => ({ personalInfo, filters });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ clearPersonalInfoError, getUserTrainings, setMyTrainingsPageFilter }, dispatch);

export type MyTrainingProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyTraining);
