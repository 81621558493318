// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			padding: '0 !important',
			height: '100%',
			paddingBottom: '50px'
		},
		rows: {
			width: '100%'
		},
		gridForm: {
			width: '100%',
			height: '100%'
		},
		tabsContainer: {
			padding: '30px 30px 0 30px',
			height: 'calc(100vh - 160px)',
			minHeight: '500px'
		},
		tabSummaryContainer: {
			padding: '30px 15px 0 15px',
			height: 'calc(100vh - 245px)',
			minHeight: '500px'
		},
		loadFromUserStatus: {
			minWidth: '150px',
			display: 'flex'
		},
		loadFromUser: {
			display: 'flex'
		},
		pageForm: {
			height: '100%',
			width: '100%'
		},
		tabsRoot: {
			height: '100%',
			overflowX: 'hidden'
		}
	})
);

export { useStyles };
