import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { AppState } from 'typings/state';

import UserGuides from './UserGuides';
import { getGuidesData, postUploadGuidesData } from 'store/administration/administration/thunks';

const mapStateToProps = ({ administration }: AppState) => ({ administration });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ getGuidesData, postUploadGuidesData }, dispatch);

export type UserGuidesProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UserGuides);
