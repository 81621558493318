// import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
	// (theme: XpandUiTheme) => ({
	() => ({
		root: {
			display: 'flex',
			width: '100%',
			flexDirection: 'column',
			alignItems: 'center'
		}
	})
);

export { useStyles };
