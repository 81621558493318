import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import {
	stringRequired,
	string,
	numberPositive,
	numberPositiveRequired,
	dateRequired,
	numberRequired,
	boolean,
	number
} from 'lib/utils/formUtils';

export const createYupSchema = (
	isEditingOfRateDisabled: boolean,
	limitSheetDate: string,
	reimburse: boolean,
	billable: boolean
) => {
	const limitSheetDateFormat = new Date(limitSheetDate);

	const currentDate = new Date();
	const lastDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

	// Function to calculate the difference in months between two dates
	const getMonthDifference = (date1, date2) => {
		const totalMonths1 = date1.year * 12 + date1.month;
		const totalMonths2 = date2.year * 12 + date2.month;
		return Math.abs(totalMonths1 - totalMonths2);
	};

	const extractYearMonth = date => {
		return {
			year: date.getFullYear(),
			month: date.getMonth() + 1 // getMonth() returns 0-11, so we add 1
		};
	};

	const limitSheetDate2 = extractYearMonth(limitSheetDateFormat);
	const current = extractYearMonth(currentDate);

	// Check if the difference is at least 3 months before submitting
	const monthDifference = getMonthDifference(current, limitSheetDate2);

	// Determine the date to use for validation
	let validationDate = monthDifference > 1 ? lastDayOfCurrentMonth : limitSheetDateFormat;
	validationDate = new Date(validationDate.getFullYear(), validationDate.getMonth() + 1, 1);
	let schema;
	const general = {
		projectName: yup.number().typeError('Must be a valid Project Name'),
		expenseName: numberRequired,
		description: string.max(200),
		expenseEntryDate: yup
			.date()
			.nullable()
			.required('Expense entry date is required')
			.test(
				'is-not-later-than',
				() => `Expense entry date must be before ${moment(validationDate).format('DD/MM/YYYY')}`,
				function (value) {
					// If the value is null, skip this test
					if (value === null) {
						return true;
					}
					// Ensure value is before or equal to maxDate
					return moment(value).isBefore(moment(validationDate), 'day');
				}
			)
			.typeError('Must be a valid date'),
		reimburse: boolean,
		billable: boolean,
		paymentMethod: numberRequired,
		currency: numberRequired,
		tripQuantity: isEditingOfRateDisabled ? numberPositiveRequired : number,
		rate: isEditingOfRateDisabled ? numberPositiveRequired : number,
		amount: numberPositiveRequired,
		destinationCountry: stringRequired
	};

	schema = yup.object().shape({
		...general
	});
	return schema;
};

export const createDefaultValues = (
	isEditingOfRateDisabled: boolean,
	limitSheetDate: string,
	reimburse: boolean,
	billable: boolean
) => {
	return {
		projectName: null,
		expenseName: 2929,
		description: '',
		expenseEntryDate: new Date().toISOString(),
		reimburse: reimburse,
		billable: billable,
		paymentMethod: 9999,
		currency: 310,
		tripQuantity: 0,
		rate: 0,
		amount: null,
		destinationCountry: 'PT - Portugal'
	};
};
