/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';

import { string, stringRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	businessUnit: stringRequired,
	division: stringRequired,
	department: stringRequired
});

export const defaultValues = {
	company: '',
	businessUnit: '',
	division: '',
	department: ''
};

export const passwordSchema = yup.object().shape({
	isAdmin: string,
	oldPassword: string.when('isAdmin', {
		is: (password: string) => password === null,
		then: stringRequired,
		otherwise: string
	}),
	newPassword: stringRequired
		.matches(
			// eslint-disable-next-line no-useless-escape
			/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=\-\?;,./{}|\":<>\[\]\\\' ~_]).{8,16}/,
			'Must Contain 8~16 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
		)
		.test('different-password', 'New password cannot be equal as old password', function (payload) {
			if (this.parent.isAdmin) {
				return true;
			}
			return this.parent.oldPassword !== payload;
		}),
	confirmNewPassword: stringRequired.oneOf([yup.ref('newPassword'), null], "Passwords don't match!")
});

export const passwordDefaultValues = {
	isAdmin: null,
	oldPassword: null,
	newPassword: null,
	confirmNewPassword: null
};
