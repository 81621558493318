import * as yup from 'yup';
import moment from 'moment';

import { parseDateToShow } from 'xpand-ui/utils/dates';

import { string, booleanRequired, dateDefault, stringRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	withError: booleanRequired,
	jobToExecute: stringRequired
});

export const defaultValues = {
	withError: false,
	jobToExecute: undefined
};
