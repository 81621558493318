import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { sendDeleteSingleCacheByName } from 'store/administration/administration/thunks';
import { setUsersList } from 'store/administration/users/actions';
import { submitHealthConvocation, getHealthLocations } from 'store/administration/users/thunks';
import { setNewNotification } from 'store/notifications/thunks';
import { getHealthConvocationMessage } from 'store/personalInfo/thunks';

import { getUsersList, clearUsersError } from 'store/administration/users/thunks';
import { setListEmployeesPageFilter } from 'store/filters/actions';
import { clearCache } from 'store/system/thunks';
import { AppState } from 'typings/state';

import ListEmployees from './ListEmployees';

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getUsersList,
			clearUsersError,
			setListEmployeesPageFilter,
			sendDeleteSingleCacheByName,
			setUsersList,
			clearCache,
			submitHealthConvocation,
			setNewNotification,
			getHealthLocations,
			getHealthConvocationMessage
		},
		dispatch
	);

const mapStateToProps = ({ users, administration, filters, personalInfo }: AppState) => ({
	users,
	administration,
	filters,
	personalInfo
});

export type ListEmployeesProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ListEmployees);
