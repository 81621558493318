import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {},
	rows: {
		width: '100%'
	},
	infoLabel: {
		fontSize: '16px',
		display: 'flex',
		color: theme.grayedColor
	},
	groupCardResume: {
		maxWidth: '1100px !important',
		width: '100%'
	},
	skillsGroupCard: {
		marginBottom: '25px'
	},
	items: {
		marginLeft: 'auto'
	},
	navButtonsPadding: {
		width: '100%',
		textAlign: 'right',
		padding: '50px'
	},
	navButtons: {
		width: '100%',
		textAlign: 'right',
		padding: '0 50px'
	},
	/** skills tab */
	skillsGroupTitle: {
		fontWeight: 600,
		fontSize: 16,
		width: '100%',
		padding: '20px 5%'
	},
	skillsSubGroupsContainer: {
		padding: '0 12% 50px'
	},
	skillHeaderLabel: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px',
		color: theme.palette.secondary.main,
		textAlign: 'initial'
	},
	skillHeaderInputs: {
		fontFamily: 'Open Sans Bold',
		fontSize: '12px',
		color: theme.typographyColor,
		textAlign: 'initial',
		minWidth: '330px'
	},
	skillHeaderLabelLanguages: {
		fontFamily: 'Open Sans SemiBold',
		fontSize: '16px',
		color: theme.palette.secondary.main,
		textAlign: 'initial'
	},
	skillHeaderInputsLanguages: {
		fontFamily: 'Open Sans Bold',
		fontSize: '12px',
		color: theme.typographyColor,
		textAlign: 'initial'
	},
	skillHeaderLine: {
		borderBottom: '1px solid #E4E6E8',
		padding: '0 !important'
	},
	skillLabel: {
		fontSize: '18px',
		textAlign: 'initial',
		color: theme.grayedColor
	},
	//* TABLET
	[theme.breakpoints.down('md')]: {
		skillLabel: {
			fontSize: '16px',
			display: 'grid',
			gap: '6px'
		}
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		skillLabel: {
			fontSize: '14px',
			gap: '8px'
		}
	},
	/** end skills tab */
	nonEditableField: {
		color: '#3D464D ',
		fontWeight: 'bold'
	},
	letterSpacing: {
		letterSpacing: '5px '
	}
}));

export { useStyles };
