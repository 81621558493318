import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	paddingAjust: {
		paddingBottom: '11px',
		paddingRight: '13px',
		paddingLeft: '13px',
		paddingTop: '11px',
		marginBottom: '10px'
	},
	fontSizeAjust: {
		fontSize: '1.3rem'
	},
	calendar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingBottom: '15px',
		paddingLeft: '8px'
	},
	location: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingBottom: '15px'
	},
	iconGrayed: {
		color: theme.inactiveText
	},
	spacementTop: {
		marginTop: '10px'
	},
	center: {
		margin: 'auto'
	},
	contentLeft: {
		justifyContent: 'left',
		width: '100%'
	}
}));

export { useStyles };
