/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { sizes } from 'xpand-ui/utils/handlers';
import { Select, Input } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS

// import { IProjectTabs } from '../../NewProject';
import { useStyles } from './styles';
import { IAdmissionSheetTabs } from '../../AdmissionSheet';

//* COMPONENT INTERFACES
// interface IProjectTeam extends IProjectTabs {}

//* COMPONENT
const ContactInfo: FC<IAdmissionSheetTabs> = ({
	control,
	errors,
	AdmissionSheetDataOptions,
	formFields,
	isEmployee
}) => {
	const classes = useStyles();
	return (
		<Grid container spacing={4} className={classes.root}>
			{formFields.formFieldsConfig.address && (
				<Grid item {...sizes[6]}>
					<Input
						name="address"
						label="Address"
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.mobilePhone && (
				<Grid item {...sizes[6]}>
					<Input
						name="mobilePhone"
						label="Mobile Phone"
						onKeyPress={event => {
							if (!/[0-9]/.test(event.key)) {
								event.preventDefault();
							}
						}}
						required
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.city && (
				<Grid item {...sizes[6]}>
					<Input
						name="city"
						label="City"
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.emergencyContact && (
				<Grid item {...sizes[6]}>
					<Input
						name="emergencyContact"
						label="Emergency Contact"
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.zipCode && (
				<Grid item {...sizes[6]}>
					<Input
						name="zipCode"
						label="Zip Code"
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.emergencyContactName && (
				<Grid item {...sizes[6]}>
					<Input
						name="emergencyContactName"
						label="Emergency Contact Name"
						required={isEmployee || formFields.formFieldsConfig.country == 'HR'}
						control={control}
						errors={errors}
					/>
				</Grid>
			)}
			{formFields.formFieldsConfig.email && (
				<Grid item {...sizes[6]}>
					<Input name="email" label="Email" required control={control} errors={errors} />
				</Grid>
			)}
			{formFields.formFieldsConfig.nationality && (
				<Grid item {...sizes[6]}>
					<Select
						name="nationality"
						label="nationality"
						options={AdmissionSheetDataOptions.nationalities}
						control={control}
						errors={errors}
						required
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default ContactInfo;
