export enum GuidesActions {
	GET_GUIDE = '@@system/GET_GUIDE',
	GET_USER_GUIDE = '@@system/GET_USER_GUIDE',
	MARK_USER_GUIDE_AS_READ = '@@system/MARK_USER_GUIDE_AS_READ',
	CLEAN_GUIDES = '@@system/CLEAN_GUIDES'
}

export interface GuideStep {
	id: string;
	guideId: string;
	content: string;
	placement: string;
	position: string;
	title: string;
}

export interface Guide {
	id: string;
	guideName: string;
	guideSteps: GuideStep[];
}

export interface UserGuide {
	id: string;
	guideId: string;
	active: boolean;
}

export type GuidesState = {
	readonly guide: Guide | null;
	readonly userGuide: UserGuide | null;
};
