import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, POST, EXPORTFILE } from 'lib/handlers/requestTypes';
import { ISkillsUpdate, IPostResumePayload } from 'typings/store/personalInfoTypes';

//* Skills */
export const fetchSystemSkills = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/skills/tree');

export const fetchUserSkills = async (username: string): Promise<OriginalResponse | Success> =>
	username
		? restCall(GET, `/user/skills`, { headers: { Cookies: `impersonateUsername=${username}` } })
		: restCall(GET, '/user/skills');

export const sendUpdateUserSkills = async (payload: ISkillsUpdate): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/skills', payload);

//* Resume */
export const fetchUserResume = async (username: string): Promise<OriginalResponse | Success> =>
	username
		? restCall(GET, `/user/resume`, { headers: { Cookies: `impersonateUsername=${username}` } })
		: restCall(GET, '/user/resume');
export const postUserResume = async (payload: IPostResumePayload): Promise<OriginalResponse> =>
	restCall(POST, '/user/resume', payload) as unknown as OriginalResponse;

//* Trainings */
export const fetchUserTrainings = async (username: string): Promise<OriginalResponse | Success> =>
	username
		? restCall(GET, `/user/training`, { headers: { Cookies: `impersonateUsername=${username}` } })
		: restCall(GET, '/user/training');

//* Benefits */
export const fetchUserBenefits = async (username: string): Promise<OriginalResponse | Success> =>
	username
		? restCall(GET, `/user/benefits`, { headers: { Cookies: `impersonateUsername=${username}` } })
		: restCall(GET, '/user/benefits');

//* Career */
export const fetchUserCareer = async (username: string): Promise<OriginalResponse | Success> =>
	username
		? restCall(GET, `/user/career`, { headers: { Cookies: `impersonateUsername=${username}` } })
		: restCall(GET, '/user/career');

//* Financial Documents */
export const fetchUserFinancialDocuments = async (username: string): Promise<OriginalResponse | Success> =>
	username
		? restCall(GET, `/user/documents`, { headers: { Cookies: `impersonateUsername=${username}` } })
		: restCall(GET, '/user/documents');

export const downloadUserFinancialDocument = async (
	documentName: string,
	username: string
): Promise<OriginalResponse | Success> =>
	username
		? restCall(EXPORTFILE, `/user/documents/download/${documentName}`, {
				headers: { Cookies: `impersonateUsername=${username}` }
		  })
		: restCall(EXPORTFILE, `/user/documents/download/${documentName}`);
