import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: 'calc(100vh - 115px)'
	},
	recordItem: {
		display: 'flex',
		width: '200px',
		alignItems: 'center'
	},
	recordItemName: {
		display: 'flex',
		fontFamily: 'Open Sans SemiBold',
		fontSize: '14px',
		margin: '10px 15px 2px 10px'
	},
	recordItemUsername: {
		display: 'flex',
		fontFamily: 'Open Sans',
		fontSize: '14px',
		margin: '0 15px 5px 10px'
	},
	avatarContainer: {
		width: '40px',
		height: '40px',
		position: 'relative',
		overflow: 'hidden',
		borderRadius: '50%'
	},
	avatarImage: {
		display: 'inline',
		margin: '0 auto',
		height: '40px',
		width: 'auto'
	},

	actionsHeader: {
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '20px',
		paddingBottom: '20px',
		backgroundColor: 'white',
		justifyContent: 'space-between'
	},
	leftActions: {
		display: 'flex'
	},
	filterButton: {
		borderRadius: 10,
		padding: '5 5 0 0',
		marginLeft: '20px',
		marginRight: '20px'
	},
	filterButtonSelected: {
		borderRadius: 10,
		padding: '5 5 0 0',
		marginLeft: '20px',
		marginRight: '20px',
		background: theme.accentColor,
		color: 'white',
		'&:hover': {
			color: theme.accentColor
		}
	},
	saveButton: {
		borderRadius: 10,
		justifyContent: 'flex-end',
		background: theme.accentColor,
		color: 'white',
		'&:hover': {
			color: theme.accentColor
		}
	},
	disabledSaveButton: {
		borderRadius: 10,
		justifyContent: 'flex-end',
		borderColor: 'gray',
		background: 'white'
	},
	switchContainer: {
		display: 'flex',
		paddingLeft: '8px',
		marginTop: '10px'
	},
	switchLabel: {
		fontSize: '10px',
		marginRight: '8px',
		alignSelf: 'center',
		paddingTop: '10px'
	},
	careerLabel: {
		fontSize: '12px',
		marginRight: '15px',
		marginBottom: '0'
	},
	dateContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center'
	},
	dateTitleLabel: {
		fontSize: '12px',
		marginRight: '15px',
		marginBottom: '0',
		alignSelf: 'center'
	},
	dateLabel: {
		fontSize: '12px',
		marginRight: '20px',
		marginBottom: '0',
		alignSelf: 'center'
	},
	separator: {
		height: '20px',
		width: '1px',
		background: theme.grayedColor,
		alignSelf: 'center',
		marginLeft: '20px',
		marginRight: '20px'
	},
	fullscreenButton: {
		border: 'none',
		'&:hover': {
			background: 'none'
		},
		'& .MuiButton-label': {
			fontSize: '12px',
			textTransform: 'none',
			color: theme.grayedColor
		}
	},
	// Tooltip styles
	mdsTooltip: {
		backgroundColor: '#4e4e4e',
		color: '#fff',
		borderRadius: '4px',
		padding: '8px',
		fontFamily: 'Arial, sans-serif',
		fontSize: '14px',
		maxWidth: '350px'
	},
	mdsTooltipHeader: {
		fontWeight: 'bold',
		paddingBottom: '5px',
		backgroundColor: '#4e4e4e'
	},
	mdsTooltipContent: {
		display: 'flex',
		flexDirection: 'column'
	},
	mdsTooltipContentItem: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		marginTop: '4px'
	},
	mdsTooltipIcon: {
		width: '18px',
		height: '18px',
		color: '#87cefa'
	}
}));

export { useStyles };
