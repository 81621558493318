//* EXTERNAL LIBS
import React, { FC, useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react';

//* EXTERNAL LIBS --> MUI
import { Typography } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, PageTitle, Table } from 'xpand-ui/core';

//* TYPINGS
import { IAuditLogEntry } from 'typings/store/admin/proposals';
import { ITableColumn } from 'typings/store/ComponentLib';

//* PROJECT IMPORTS [LIB / PAGES ]

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { ExpenseSheetAuditProps } from '.';
import { convertBinaryToYesNo, convertDateFormat } from './utils';

//* COMPONENT INTERFACES
interface IExpenseSheetAudit extends ExpenseSheetAuditProps {
	id: string;
	handleClose: Dispatch<SetStateAction<string | null>>;
}

//* COMPONENT
const ExpenseSheetAudit: FC<IExpenseSheetAudit> = ({ personalInfo, id, getExpensesAuditData }) => {
	const classes = useStyles();
	const { loading, expensesAuditData } = personalInfo;

	useEffect(() => {
		getExpensesAuditData({ expenseSheetId: id });
	}, [id, getExpensesAuditData]);

	// TABLE SEARCH HELPERS
	const [sheetSearchValue, setSheetSearchValue] = useState('');
	const [entrySearchValue, setEntrySearchValue] = useState('');

	// TABLE COLUMNS FOR EXPENSE SHEET
	const tableSheetColumns: ITableColumn<IAuditLogEntry>[] = useMemo(
		() => [
			{
				label: 'Update Date',
				id: 'updateDate',
				accentColumn: true,
				align: 'left',
				width: '20%'
			},
			{
				label: 'Field Name',
				id: 'fieldName',
				accentColumn: true,
				align: 'left',
				width: '20%'
			},
			{
				label: 'Old Value',
				id: 'oldValue',
				accentColumn: true,
				align: 'left',
				width: '20%'
			},
			{
				label: 'New Value',
				id: 'newValue',
				accentColumn: true,
				align: 'left',
				width: '20%'
			},
			{
				label: 'Updated By',
				id: 'updateBy',
				accentColumn: true,
				align: 'left',
				width: '20%'
			}
		],
		[]
	);

	// TABLE DATA PARSED FOR EXPENSE SHEET
	const tableSheetData = useMemo(
		() =>
			expensesAuditData?.results?.accountEmployeeExpenseSheetAudit?.map(e => ({
				...e,
				updateDate: convertDateFormat(e.updateDate.toString()),
				fieldName: e.fieldName,
				oldValue:
					e.fieldName == 'Approved' ||
					e.fieldName == 'Submitted' ||
					e.fieldName == 'IsBillable' ||
					e.fieldName == 'Reimburse'
						? convertBinaryToYesNo(e.oldValue)
						: e.oldValue,
				newValue:
					e.fieldName == 'Approved' ||
					e.fieldName == 'Submitted' ||
					e.fieldName == 'IsBillable' ||
					e.fieldName == 'Reimburse'
						? convertBinaryToYesNo(e.newValue)
						: e.newValue,
				updateBy: e.updateBy
			})),
		[expensesAuditData]
	);

	// TABLE COLUMNS FOR EXPENSE ENTRY
	const tableEntryColumns: ITableColumn<IAuditLogEntry>[] = useMemo(
		() => [
			{
				label: 'Update Date',
				id: 'updateDate',
				accentColumn: true,
				align: 'left',
				width: '10%'
			},
			{
				label: 'Project Name',
				id: 'projectName',
				accentColumn: true,
				align: 'left',
				width: '15%'
			},
			{
				label: 'Expense Name',
				id: 'accountExpenseName',
				accentColumn: true,
				align: 'left',
				width: '15%'
			},
			{
				label: 'Amount',
				id: 'amount',
				accentColumn: true,
				align: 'right',
				width: '10%',
				format: (row, text: number) => text.toFixed(2) + ' €'
			},
			{
				label: 'Field Name',
				id: 'fieldName',
				accentColumn: true,
				align: 'left',
				width: '10%'
			},
			{
				label: 'Old Value',
				id: 'oldValue',
				accentColumn: true,
				align: 'left',
				width: '10%',
				format: (row, text: string) => text.replace('  ', ' ')
			},
			{
				label: 'New Value',
				id: 'newValue',
				accentColumn: true,
				align: 'left',
				width: '10%',
				format: (row, text: string) => text.replace('  ', ' ')
			},
			{
				label: 'Updated By',
				id: 'updatedBy',
				accentColumn: true,
				align: 'left',
				width: '10%'
			}
		],
		[]
	);

	// TABLE DATA PARSED FOR EXPENSE ENTRY
	const tableEntryData = useMemo(
		() =>
			expensesAuditData?.results?.accountExpenseEntryAudit?.map(e => ({
				...e,
				updateDate: convertDateFormat(e.updateDate.toString()),
				projectName: e.projectName,
				accountExpenseName: e.accountExpenseName,
				amount: Number(e.amount),
				fieldName: e.fieldName,
				oldValue:
					e.fieldName == 'Approved' ||
					e.fieldName == 'Submitted' ||
					e.fieldName == 'IsBillable' ||
					e.fieldName == 'Reimburse'
						? convertBinaryToYesNo(e.oldValue)
						: e.oldValue,
				newValue:
					e.fieldName == 'Approved' ||
					e.fieldName == 'Submitted' ||
					e.fieldName == 'IsBillable' ||
					e.fieldName == 'Reimburse'
						? convertBinaryToYesNo(e.newValue)
						: e.newValue,
				updatedBy: e.updatedBy
			})),
		[expensesAuditData]
	);

	const isLoading = !expensesAuditData;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle
				title={
					<>
						<Typography gutterBottom color="primary" className={classes.name}>
							Expense Sheet Audit
						</Typography>
					</>
				}
				actions={[]}
			/>
			<Table
				tableData={tableSheetData}
				columns={tableSheetColumns}
				defaultSortColumn="updateDate"
				sortDateWithMinSecMil={true}
				defaultOrder="desc"
				handleSearch={{ searchValue: sheetSearchValue, setSearchValue: setSheetSearchValue }}
			/>
			<PageTitle
				title={
					<>
						<Typography gutterBottom color="primary" className={classes.name}>
							Expense Entry Audit
						</Typography>
					</>
				}
				actions={[]}
			/>
			<Table
				tableData={tableEntryData}
				columns={tableEntryColumns}
				defaultSortColumn="updateDate"
				sortDateWithMinSecMil={true}
				defaultOrder="desc"
				handleSearch={{ searchValue: entrySearchValue, setSearchValue: setEntrySearchValue }}
			/>
		</>
	);
};

export default ExpenseSheetAudit;
