/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, FC } from 'react';
import { TimeEntryMover } from 'xpand-ui/core';
import { IconButton, Dialog as MuiDialog, DialogTitle, DialogContent } from '@mui/material';
import { useStyles } from './styles';
import { Close } from '@mui/icons-material';
import { decimalToHours } from '../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { defaultValues, schema } from './yupSchema';

const TimeEntryCopyModal: FC<any> = ({
	setOpenModalTimeEntryCopy,
	openModalTimeEntryCopy,
	event,
	onClose,
	onCopy,
	selectedDatesOnCopy,
	setSelectedDatesOnCopy,
	selectedDate
}) => {
	const classes = useStyles();
	const [projectName, setProjectName] = useState(null);
	const [projectTaskName, setProjectTaskName] = useState(null);
	const [hours, setHours] = useState('');
	const [eventFromDate, setEventFromDate] = useState(new Date());

	// for MultipleSelect
	const {
		control,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues
	});

	useEffect(() => {
		if (event) {
			setProjectName(event.title ? event.title : '');
			setProjectTaskName(event.projectTaskName ? event.projectTaskName : '');
			//Copy/ Move from date
			const dateAux = new Date(event.start);
			dateAux.setHours(0, 0, 0, 0);
			setEventFromDate(dateAux);
			if (event.hours && event.minutes) {
				setHours(event.hours + 'h:' + event.minutes + 'm');
			} else if (event.hours && !event.minutes) {
				const hoursMinutes = decimalToHours(event.hours);
				setHours(
					hoursMinutes.hours.toString().padStart(2, '0') +
						'h:' +
						hoursMinutes.minutes.toString().padStart(2, '0') +
						'm'
				);
			}
		}
	}, [event]);
	return (
		<>
			{/* FILTERS MODAL */}
			<MuiDialog
				fullWidth
				maxWidth="md"
				onClose={() => {
					setOpenModalTimeEntryCopy(false);
				}}
				open={openModalTimeEntryCopy}>
				<DialogContent sx={{ padding: '0px' }}>
					<TimeEntryMover
						titleLabel={'Project Name'}
						titleValue={projectName}
						secondTitleLabel={'Project Task'}
						secondTitleValue={projectTaskName}
						hours={hours}
						onClose={onClose}
						onCopy={onCopy}
						isMultipleSelect={true}
						selectedDates={selectedDatesOnCopy}
						setSelectedDates={setSelectedDatesOnCopy}
						mainTitle={'Copy Time'}
						mainButton="Copy"
						fromDate={eventFromDate}
						control={control}
						errors={errors}
						selectedDate={selectedDate}
					/>
				</DialogContent>
			</MuiDialog>
		</>
	);
};
export default TimeEntryCopyModal;
