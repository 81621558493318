/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';

//* EXTERNAL LIBS --> MUI
import { Grid, Button, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import Adicionar from '../../../../../assets/icons/Adicionar.svg';

//* EXTERNAL LIBS --> XPAND-UI
import { Input, Numeric } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { IOtherBenefit } from 'typings/store/admin/proposals';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';

//* COMPONENT INTERFACES
interface ICustomBenefits {
	control: Control;
	errors: Record<string, Record<string, string>>;
	setValue: any;
}

//* COMPONENT
const CustomBenefits: FC<ICustomBenefits> = ({ control, errors, setValue, isCollabType }) => {
	const classes = useStyles();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'otherBenefitsList',
		keyName: 'uniqueId'
	});

	return (
		<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
			{/* CHECKBOX and TOTALS COMPONENTS */}
			<Grid item {...sizes[12]}>
				{(fields as IOtherBenefit[]).map((benefit, index) => {
					const name = `otherBenefitsList.${index}.`;

					return (
						<Grid
							container
							key={benefit.uniqueId}
							className={classes.inputsGroup}
							direction="row"
							justifyContent="flex-start"
							spacing={2}
							alignItems="flex-start">
							<Grid item {...sizes[8]}>
								<Input
									disabled={isCollabType}
									label=" "
									placeholder="Benefit Description"
									name={`${name}goalDetails`}
									multiline
									minRows={2}
									maxRows={4}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[3]} className={classes.value}>
								<Numeric
									disabled={isCollabType}
									money
									name={`${name}prizeValue`}
									label=""
									additionalOnBlur={value =>
										setValue(`${name}prizeValue`, Number(value) || 0, { shouldValidate: true })
									}
									control={control}
									errors={errors}
								/>
							</Grid>
							<Grid item {...sizes[1]}>
								<IconButton disabled={isCollabType} onClick={() => remove(index)}>
									<Delete />
								</IconButton>
							</Grid>
						</Grid>
					);
				})}
			</Grid>
			{/* ADD MORE COMPONENTS */}
			<Grid
				item
				{...sizes[12]}
				className={clsx(classes.group, classes.animation, {
					[classes.show]: fields.length < 3
				})}>
				<Button
					disabled={isCollabType}
					color="primary"
					variant="text"
					endIcon={<Adicionar />}
					onClick={() =>
						append({
							uniqueId: uuidv4(),
							goalDetails: '',
							prizeValue: '0'
						})
					}>
					Add Benefit
				</Button>
			</Grid>
		</Grid>
	);
};

export default withLayout(CustomBenefits);
