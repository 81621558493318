//* EXTERNAL LIBS
import React, { useState, useEffect, useMemo, FC } from 'react';

//* EXTERNAL LIBS --> MUI
import { Grid, Typography } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, PageTitle, TabHeader, TabPanel, Tab } from 'xpand-ui/core';
import { sizes } from 'xpand-ui/utils/handlers';
import { InputLabel } from 'xpand-ui/forms';

//* TYPINGS

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import { MyCareerProps } from '.';
import Scrollbars from 'react-custom-scrollbars-2';

const tabs = [
	{
		id: 'JOB_ROLE_DESCRIPTION',
		label: 'JOB ROLE DESCRIPTION'
	},
	{
		id: 'LEVEL_DESCRIPTION',
		label: 'LEVEL DESCRIPTION'
	}
];

//* COMPONENT INTERFACES
// interface IMyCareer extends MyCareerProps {
// 	// sizes: (path: string) => void;
// }

//* COMPONENT
const MyCareer: FC<MyCareerProps> = ({ personalInfo, company, getUserCareer, clearPersonalInfoError }) => {
	const classes = useStyles();
	const { error, userCareer } = personalInfo;
	const scrollbarRef = React.createRef();

	const [tabSelected, setTabSelected] = useState(0);

	useEffect(() => {
		if (scrollbarRef.current) {
			scrollbarRef.current.scrollToTop();
		}
	});

	// TABS COMPONENT - handle method
	const handleTabChange = (event: unknown, newValue: React.SetStateAction<number>) => setTabSelected(newValue);

	useEffect(() => {
		if (!userCareer && !error) getUserCareer();
	}, [userCareer]);

	const responsabilities = useMemo(() => {
		if (userCareer?.xphrProfileEligibleRoleData?.xphrJobroleData?.responsabilities) {
			const payload = userCareer.xphrProfileEligibleRoleData.xphrJobroleData.responsabilities.split(';');
			if (payload.length === 0) return '';
			if (payload.length === 1)
				return (
					<Typography variant="body1" className={classes.nonEditableField} gutterBottom>
						{payload}
					</Typography>
				);

			return payload.map((e, i) => (
				<Typography
					key={`career_responsability_${i + 1}`}
					variant="body1"
					className={classes.nonEditableField}
					gutterBottom>
					{e}
					{i !== payload.length - 1 && ';'}
				</Typography>
			));
		}
		return '';
	}, [userCareer]);

	if (error) return handleErrorPage(error, clearPersonalInfoError);

	const isLoading = userCareer === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			<Grid
				container
				direction="row"
				justifyContent="space-evenly"
				alignItems="flex-start"
				spacing={4}
				className={classes.root}>
				{/* CONTAINER TITLE AND ACTIONS */}

				<Grid item {...sizes[12]} style={{ display: 'flex' }}>
					{company.urlConfluenceCareer && company.urlServiceDeskCareer && (
						<>
							<PriorityHigh color="primary" style={{ marginTop: '15px', marginRight: '10px' }} />
							<Typography gutterBottom className={classes.infoLabel} style={{ display: 'block' }}>
								For more information about our Career and Development plan, please access this&nbsp;
								<a
									style={{ fontSize: '16px' }}
									href={company.urlConfluenceCareer}
									rel="noreferrer"
									target="_blank">
									link
								</a>{' '}
								.
								<br />
								If you have any questions or concerns, feel free to open a ticket on&nbsp;
								<a
									style={{ fontSize: '16px' }}
									href={company.urlServiceDeskCareer}
									rel="noreferrer"
									target="_blank">
									Jira Service Desk
								</a>
								, and we'll reply to you as soon as possible.
							</Typography>
						</>
					)}
				</Grid>
				<Grid item {...sizes[12]}>
					<PageTitle
						title={
							<Typography gutterBottom style={{ fontSize: '18px', fontWeight: 'bold', color: 'grey' }}>
								Where am I?
							</Typography>
						}
					/>
				</Grid>
				<Grid item {...sizes[12]}>
					<TabHeader
						selected={tabSelected}
						handleSelected={handleTabChange}
						variant="scrollable"
						scrollButtons="auto">
						{tabs.map((tab, index) => (
							<Tab key={`Resume_${tab.id}_tab_button`} label={tab.label} index={index} />
						))}
					</TabHeader>
					<Scrollbars
						ref={scrollbarRef}
						style={{ height: 'calc(100vh - 350px)', overflowX: 'hidden' }}
						// eslint-disable-next-line
						renderTrackHorizontal={(props: any) => (
							<div {...props} style={{ display: 'none' }} className="track-horizontal" />
						)}>
						{tabs.map((tab, index) => (
							<TabPanel
								key={`MyCareer_${tab.id}_tab_content`}
								value={tabSelected}
								index={index}
								className={classes.tabsRoot}>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="baseline"
									spacing={5}>
									{tabSelected === 0 && (
										<>
											<Grid item xl={2}>
												<InputLabel>ROLE</InputLabel>
												<Typography variant="h6" gutterBottom>
													{userCareer?.xphrProfileEligibleRoleData &&
														userCareer?.xphrProfileEligibleRoleData?.name}
												</Typography>
											</Grid>
											<Grid item sm={8}>
												<InputLabel>CAREER LEVEL</InputLabel>
												<Typography variant="h6" gutterBottom>
													{userCareer?.xphrProfileRoleLevelData &&
														userCareer?.xphrProfileRoleLevelData?.name.split(' >')[0]}
												</Typography>
											</Grid>
											<Grid item {...sizes[12]}>
												<InputLabel>OVERVIEW</InputLabel>
												<Typography
													variant="body1"
													className={classes.nonEditableField}
													gutterBottom>
													{userCareer?.xphrProfileEligibleRoleData &&
														userCareer?.xphrProfileEligibleRoleData?.xphrJobroleData &&
														userCareer?.xphrProfileEligibleRoleData?.xphrJobroleData
															?.overview}
												</Typography>
											</Grid>
											<Grid item {...sizes[12]}>
												<InputLabel>RESPONSIBILITIES</InputLabel>
												{responsabilities}
											</Grid>
										</>
									)}
									{tabSelected === 1 && (
										<>
											<Grid item {...sizes[12]}>
												<InputLabel>MATURITY</InputLabel>
												<Typography
													variant="body1"
													className={classes.nonEditableField}
													gutterBottom>
													{userCareer?.xphrProfileRoleLevelData &&
														userCareer?.xphrProfileRoleLevelData?.roleMaturity}
												</Typography>
											</Grid>
											<Grid item {...sizes[12]}>
												<InputLabel>RESPONSIBILITIES</InputLabel>
												<Typography
													variant="body1"
													className={classes.nonEditableField}
													gutterBottom>
													{userCareer?.xphrProfileRoleLevelData &&
														userCareer?.xphrProfileRoleLevelData?.roleResponsability}
												</Typography>
											</Grid>
											<Grid item {...sizes[12]}>
												<InputLabel>AUTONOMY</InputLabel>
												<Typography
													variant="body1"
													className={classes.nonEditableField}
													gutterBottom>
													{userCareer?.xphrProfileRoleLevelData &&
														userCareer?.xphrProfileRoleLevelData?.roleAutonomy}
												</Typography>
											</Grid>
										</>
									)}
								</Grid>
							</TabPanel>
						))}
					</Scrollbars>
				</Grid>
			</Grid>
		</>
	);
};

export default withLayout(MyCareer);
