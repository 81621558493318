/* eslint-disable no-empty-pattern */
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { clearPersonalInfoError, getAllEmployees } from 'store/personalInfo/thunks';

import { AppState } from 'typings/state';
import { setAllEmployeesPageFilter } from 'store/filters/actions';

import AllEmployees from './AllEmployees';

const mapStateToProps = ({ personalInfo, filters }: AppState) => ({ personalInfo, filters });
const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ clearPersonalInfoError, getAllEmployees, setAllEmployeesPageFilter }, dispatch);

export type AllEmployeesProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(AllEmployees);
