/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';

import { stringRequired, ibanNumberRequired } from 'lib/utils/formUtils';

export const schema = yup.object().shape({
	bankId: stringRequired,
	iban: ibanNumberRequired
});
export const defaultValues = {
	bankId: '',
	iban: ''
};
