import { XpandUiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: XpandUiTheme) => ({
	tabsRoot: {
		padding: '10px 30px',
		flexGrow: 1,
		backgroundColor: 'transparent'
	},
	benefitsContainer: {
		padding: '30px 0'
	},
	benefitsItem: {
		boxShadow: theme.boxShadow,
		maxWidth: 'calc(45vw)',
		borderRadius: '5px',
		margin: 'auto',
		padding: '15px',
		color: '#3D464D'
	},
	//* WEB
	[theme.breakpoints.up('md')]: {
		//
	},
	//* TABLET
	[theme.breakpoints.down('md')]: {
		tabsRoot: {
			padding: '10px 5px'
		},
		benefitsContainer: {
			padding: '15px 0'
		},

		benefitsItem: {
			maxWidth: 'calc(63vw)'
		}
	},
	//* MOBILE
	[theme.breakpoints.down('sm')]: {
		benefitsItem: {
			maxWidth: 'calc(70vw)'
		}
	},
	nonEditableField: {
		color: '#3D464D ',
		fontWeight: 'bold',
		wordWrap: 'break-word',
		maxWidth: 'fit-content'
	}
}));

export { useStyles };
